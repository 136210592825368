import { Form, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import SelectAccess from '@/components/SearchFormComponents/SelectAccess';
import SelectAccessV2 from '@/components/SearchFormComponents/SelectAccessV2';
import { SearchRequestReferenceInformationValuesFragment } from '@/generated/graphql';

const { Title } = Typography;

interface AccessDatesProps {
  access?: SearchRequestReferenceInformationValuesFragment['access'];
  accessV2?: SearchRequestReferenceInformationValuesFragment['accessV2'];
}

const AccessDate = ({ access, accessV2 }: AccessDatesProps): JSX.Element => {
  const { t } = useTranslation();
  if (access) {
    return (
      <Typography.Text>
        {access.quarter} {access.year}
      </Typography.Text>
    );
  } else if (accessV2) {
    return <Typography.Text>{t(accessV2)}</Typography.Text>;
  }

  return null;
};

interface Props extends AccessDatesProps {
  editing: boolean;
}

function Access({ editing, access, accessV2 }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box>
      <Title level={5} style={{ fontWeight: 500 }}>
        {t('Desired access date')}
      </Title>
      <>
        {access ? (
          <>
            {editing ? (
              <Form.Item
                name={'access'}
                initialValue={
                  access ? [access.year, access.quarter] : undefined
                }
              >
                <SelectAccess label={false} />
              </Form.Item>
            ) : (
              <AccessDate access={access} />
            )}
          </>
        ) : (
          <>
            {editing ? (
              <Form.Item name={'accessV2'} initialValue={accessV2}>
                <SelectAccessV2 label={false} />
              </Form.Item>
            ) : (
              <AccessDate accessV2={accessV2} />
            )}
          </>
        )}
      </>
    </Box>
  );
}

export default Access;
