import { useEffect } from 'react';

import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { setLeaseAgreementMarkers } from '@/state/customer/actions';
import { filterByBounds, MapObject } from '@/utils/misc';

const useMapItems = <T extends MapObject>(items: T[], name = 'item'): T[] => {
  const bounds = useAppSelector(state => state.customer.bounds);
  const dispatch = useAppDispatch();

  const mapIsInit = {}.hasOwnProperty.call(bounds, 'north');

  useEffect(() => {
    dispatch(setLeaseAgreementMarkers(name, items));
  }, [name, items, dispatch]);

  // Unmount
  useEffect(
    () => () => {
      dispatch(setLeaseAgreementMarkers(name, []));
    },
    [name, dispatch],
  );

  return mapIsInit ? filterByBounds(items, bounds) : items;
};

export default useMapItems;
