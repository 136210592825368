import { ArrowUpOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { UploadFile } from 'antd/lib';
import clsx from 'clsx';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowPrevious } from 'styled-icons/fluentui-system-filled';

import Box from '@/components/Box';
import ChatTextArea from '@/components/ChatTextArea';
import SelectLeaseAgreements, {
  SelectedLeaseAgreement,
} from '@/components/SelectLeaseAgreements';
import UploadToConversation from '@/components/UploadFile/UploadToConversation';
import {
  DocumentValuesFragment,
  SearchRequestReferenceMatchValuesFragment,
} from '@/generated/graphql';
import useUser from '@/hooks/useUser';

const { Title, Text } = Typography;

interface Props {
  visible: boolean;
  searchRequestReference: SearchRequestReferenceMatchValuesFragment;
  selectedLeaseAgreements: SelectedLeaseAgreement[];
  setSelectedLeaseAgreements: Dispatch<
    SetStateAction<SelectedLeaseAgreement[]>
  >;
  setSelectedAttachments: Dispatch<
    SetStateAction<UploadFile<DocumentValuesFragment>[]>
  >;
  selectedAttachments: UploadFile<DocumentValuesFragment>[];
  message: string;
  setMessage: (message: string) => void;
  sharedSearchRequest?: boolean;
  scrollRef?: HTMLElement;
}

function PromoteAndAnswer({
  visible,
  searchRequestReference,
  selectedLeaseAgreements,
  setSelectedLeaseAgreements,
  message,
  setMessage,
  selectedAttachments,
  setSelectedAttachments,
  sharedSearchRequest,
  scrollRef,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();
  const [fileList, setFileList] = useState<
    UploadFile<DocumentValuesFragment>[]
  >([]);

  return (
    <div
      className={clsx('h-full flex flex-col overflow-y-auto', {
        hidden: !visible,
      })}
    >
      <div className="grid">
        <div className="justify-self-center mobile:hidden">
          {scrollRef && (
            <ArrowPrevious
              className="rotate-90 cursor-pointer w-8 h-8"
              onClick={() => scrollRef.scrollIntoView({ behavior: 'smooth' })}
            />
          )}
        </div>
        <Title level={5}>{t('Answer inquiry')}</Title>
        <ChatTextArea
          selectedAttachments={selectedAttachments}
          setSelectedAttachments={setSelectedAttachments}
          setMessage={message => setMessage(message)}
          message={message}
          selectedLeaseAgreements={selectedLeaseAgreements}
          setSelectedLeaseAgreements={setSelectedLeaseAgreements}
          maxRows={33}
          minRows={5}
          onChange={attachmentIds => setSelectedAttachments(attachmentIds)}
          fileList={fileList}
          setFileList={setFileList}
        />
        <Box mt={2} verticalSpacing={1}>
          <UploadToConversation
            onChange={attachmentIds => setSelectedAttachments(attachmentIds)}
            values={selectedAttachments}
            fileList={fileList}
            setFileList={setFileList}
          />
        </Box>
      </div>
      {searchRequestReference && !user?.isPartner && (
        <>
          <h4 className={'font-semibold mt-5 mb-3'}>{t('Suggest spaces')}</h4>
          <SelectLeaseAgreements
            className={clsx({ 'max-h-96': sharedSearchRequest })}
            searchRequestReference={searchRequestReference}
            setSelectedLeaseAgreements={setSelectedLeaseAgreements}
            selectedLeaseAgreements={selectedLeaseAgreements}
          />
        </>
      )}
    </div>
  );
}

export default PromoteAndAnswer;
