import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Spin, Tooltip, Typography } from 'antd';
import React from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Box from '@/components/Box';
import InfiniteScroll from '@/components/InfiniteScroll';
import LeaseAgreementCard, {
  LeaseAgreementCardProps,
} from '@/components/LeaseAgreementList/LeaseAgreementCard';
import EditLeaseAgreement from '@/components/LeaseAgreementModals/EditLeaseAgreement';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import theme from '@/main/theme';
import DeleteLeaseAgreement from '@/views/LeaseAgreement/Form/DeleteLeaseAgreement';

import PortallyEmpty from '../PortallyEmpty';

interface Props {
  loadMore: () => void;
  hasMore: boolean;
  leaseAgreements: LeaseAgreementCardProps[];
  onClick: (id: string) => void;
  emptyText: React.ReactNode;
  showRate?: boolean;
  queryLoading: boolean;
  remaining: number;
  useWindow?: boolean;
  showResults?: boolean;
  isDashboard?: boolean;
  withActions?: boolean;
  threshold?: number;
  seeOnMap?: boolean;
}

const Title = styled(Typography.Title)`
  display: flex;
  justify-content: center;
`;

export const LeaseAgreementGrid = styled(Box)<{ $withMap?: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 0.5fr));
  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    ${({ $withMap }) =>
      $withMap &&
      'grid-template-columns: repeat(auto-fit, minmax(260px, 1fr))'};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }

  grid-gap: 16px;
  width: 100%;
`;

function LeaseAgreementList({
  hasMore,
  loadMore,
  onClick,
  leaseAgreements,
  emptyText,
  showRate = true,
  queryLoading,
  useWindow = false,
  showResults = true,
  isDashboard = false,
  withActions = false,
  threshold,
  seeOnMap = false,
}: Props): JSX.Element {
  const apollo = useApolloClient();
  const { isMobile } = useWidthAndHeight();
  const i18n = getI18n();

  const { t } = useTranslation();
  const language = i18n.language;

  return (
    <InfiniteScroll
      key={'Unique'}
      loadMore={() => loadMore()}
      hasMore={hasMore}
      loader={null}
      threshold={threshold ? threshold : isMobile ? 1300 : 800}
      loading={queryLoading}
      useWindow={useWindow}
    >
      <div>
        {!showResults && (
          <Title style={{ color: theme.colors.websitePurple }} level={4}>
            {t('noResultsText')}
          </Title>
        )}
        {leaseAgreements.length === 0 && (
          <Box flex justifyContent={'center'}>
            <PortallyEmpty description={emptyText} />
          </Box>
        )}

        <LeaseAgreementGrid $withMap={!isDashboard}>
          {leaseAgreements.map((leaseAgreement, index) => (
            <LeaseAgreementCard
              key={leaseAgreement.id}
              onClick={() => onClick(leaseAgreement.id)}
              language={language}
              leaseAgreement={leaseAgreement}
              isMobile={isMobile}
              showRate={showRate}
              seeOnMap={seeOnMap}
              actions={
                withActions
                  ? [
                      <Tooltip title={t('Edit')} key={'edit'}>
                        <EditLeaseAgreement
                          id={leaseAgreement.id}
                          refetch
                          key={'edit'}
                          type={'text'}
                          icon={<EditOutlined />}
                        />
                      </Tooltip>,

                      <Tooltip title={t('Delete')} key={'edit'}>
                        <DeleteLeaseAgreement
                          danger
                          integrationProvider={
                            leaseAgreement.integrationProvider
                          }
                          id={leaseAgreement.id}
                          icon={<DeleteOutlined />}
                          onCompleted={async () => {
                            apollo.reFetchObservableQueries();
                          }}
                          type={'text'}
                        />
                      </Tooltip>,
                    ]
                  : null
              }
            />
          ))}
          {!hasMore && (
            <>
              <div />
              <div />
            </>
          )}
          {hasMore && (
            <Box flex alignItems={'center'} justifyContent={'center'}>
              <Spin />
            </Box>
          )}
        </LeaseAgreementGrid>
      </div>
    </InfiniteScroll>
  );
}

export default LeaseAgreementList;
