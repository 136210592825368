import { HomeOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Input, Tag, Upload } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { UploadFile } from 'antd/lib';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Box from '@/components/Box';
import { SelectedLeaseAgreement } from '@/components/SelectLeaseAgreements';
import { checkMaxUploadSize, uploadFile } from '@/components/UploadFile/util';
import {
  DocumentValuesFragment,
  useUploadFileMutation,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';

const TextAreaContainer = styled(Box)`
  transition: all 0.3s;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  border: 1px solid ${p => p.theme.colors.inputBorder};
  :hover {
    border: 1px solid ${p => p.theme.colors.inputBorderHover};
  }
  :focus-within {
    border: 1px solid ${p => p.theme.colors.inputBorderHover};
    box-shadow: ${p => p.theme.colors.inputFocusBoxShadow};
  }
`;

interface Props {
  message: string;
  setMessage: (string) => void;
  selectedLeaseAgreements: SelectedLeaseAgreement[];
  setSelectedLeaseAgreements: Dispatch<
    SetStateAction<SelectedLeaseAgreement[]>
  >;
  selectedAttachments: UploadFile<DocumentValuesFragment>[];
  setSelectedAttachments: Dispatch<
    SetStateAction<UploadFile<DocumentValuesFragment>[]>
  >;
  maxRows?: number;
  minRows: number;
  maxWidth?: number;
  size?: SizeType;
  onChange: (values: UploadFile<DocumentValuesFragment>[]) => void;
  fileList: UploadFile<DocumentValuesFragment>[];
  setFileList: (fileList: UploadFile<DocumentValuesFragment>[]) => void;
}

function ChatTextArea({
  message,
  setMessage,
  selectedLeaseAgreements,
  setSelectedLeaseAgreements,
  maxRows,
  minRows,
  maxWidth,
  selectedAttachments,
  setSelectedAttachments,
  size = 'middle',
  onChange,
  fileList,
  setFileList,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { isMobile } = useWidthAndHeight();

  const { Dragger } = Upload;

  const [uploadFileMutation] = useUploadFileMutation();

  useEffect(() => {
    onChange(fileList.filter(v => v.status === 'done'));
  }, [fileList]);

  return (
    <Dragger
      style={{ border: 'none', background: 'transparent', cursor: 'auto' }}
      customRequest={args => {
        const { file, onError, onSuccess, onProgress } = args;

        uploadFile(file, uploadFileMutation, percent =>
          onProgress({ percent } as any),
        )
          .then(upload => {
            onSuccess(
              {
                id: upload.data.file.id,
                url: upload.data.file.url,
              },
              file as any,
            );
          })
          .catch(onError);
      }}
      onChange={values => {
        setFileList(values.fileList);
      }}
      fileList={fileList.filter(
        v =>
          v.status !== 'done' ||
          selectedAttachments.map(v => v.uid).includes(v.uid),
      )}
      beforeUpload={file => checkMaxUploadSize(file, t)}
      multiple
      openFileDialogOnClick={false}
      showUploadList={false}
    >
      <TextAreaContainer
        flex
        flexDirection={'column'}
        verticalSpacing={1}
        w={'100%'}
        maxW={maxWidth}
      >
        <Input.TextArea
          bordered={false}
          key={'conversationId'}
          onChange={e => setMessage(e.target.value)}
          value={message}
          autoSize={{ minRows, maxRows }}
          size={size}
          placeholder={t('Write a message')}
          autoFocus={!isMobile}
        />
        {(selectedLeaseAgreements.length > 0 ||
          selectedAttachments.length > 0) && (
          <div className={'flex flex-wrap gap-1 px-2 pb-2'}>
            {selectedLeaseAgreements.map(lease => (
              <Tag
                key={lease.id}
                closable
                icon={<HomeOutlined />}
                onClose={() =>
                  setSelectedLeaseAgreements(
                    selectedLeaseAgreements.filter(l => l.id !== lease.id),
                  )
                }
              >
                {lease.name}
              </Tag>
            ))}
            {selectedAttachments?.map(attachment => (
              <Tag
                key={attachment.uid}
                icon={<PaperClipOutlined />}
                closable
                onClose={() =>
                  setSelectedAttachments(
                    selectedAttachments.filter(a => a.uid !== attachment.uid),
                  )
                }
              >
                {attachment.name}
              </Tag>
            ))}
          </div>
        )}
      </TextAreaContainer>
    </Dragger>
  );
}

export default ChatTextArea;
