import { Dictionary } from 'lodash';

import { GetFilterCountiesQuery, RegionTypeEnum } from '@/generated/graphql';
import { Unpacked } from '@/utils/types';

export const innerCity = [
  { id: '5d99f4c95d244c5e3b49a82c', name: 'Kungsholmen' },
  { id: '5d99f4c95d244c5e3b49a84d', name: 'Norrmalm' },
  { id: '607701c7421e6664e4820f32', name: 'Stockholm CBD' },
  { id: '5d99f4c65d244c5e3b49a4d5', name: 'Södermalm' },
  { id: '627e06592d8124afd63f1a26', name: 'Gamla stan' },
  { id: '5d99f4c65d244c5e3b49a42d', name: 'Vasastaden' },
  { id: '5d99f4c95d244c5e3b49a84f', name: 'Östermalm' },
];

export const outerCity = [
  {
    id: '61228ea161789c5c4d8b246d',
    name: 'Botkyrka',
  },
  {
    id: '5d99f4c95d244c5e3b49a822',
    name: 'Bromma',
  },
  {
    id: '61228ea161789c5c4d8b24fe',
    name: 'Danderyd',
  },
  {
    id: '61228ea161789c5c4d8b2443',
    name: 'Ekerö',
  },
  {
    id: '5d99f4c95d244c5e3b49a857',
    name: 'Enskede-Årsta',
  },
  {
    id: '5d99f4c95d244c5e3b49a859',
    name: 'Farsta',
  },
  {
    id: '61228ea161789c5c4d8b24a5',
    name: 'Haninge',
  },
  {
    id: '61228ea161789c5c4d8b24cc',
    name: 'Huddinge',
  },
  {
    id: '5d99f4c95d244c5e3b49a839',
    name: 'Hägersten-Liljeholmen',
  },
  {
    id: '5d99f4c95d244c5e3b49a833',
    name: 'Hässelby-Vällingby',
  },
  {
    id: '61228ea161789c5c4d8b24af',
    name: 'Järfälla',
  },
  {
    id: '61228ea161789c5c4d8b242a',
    name: 'Lidingö',
  },
  {
    id: '61228ea161789c5c4d8b2508',
    name: 'Nacka',
  },
  {
    id: '61228ea161789c5c4d8b2480',
    name: 'Norrtälje',
  },
  {
    id: '61228ea161789c5c4d8b245a',
    name: 'Nykvarn',
  },
  {
    id: '61228ea161789c5c4d8b2482',
    name: 'Nynäshamn',
  },
  {
    id: '5d99f4c95d244c5e3b49a864',
    name: 'Kista',
  },
  {
    id: '61228ea161789c5c4d8b240f',
    name: 'Salem',
  },
  {
    id: '61228ea161789c5c4d8b2481',
    name: 'Sigtuna',
  },
  {
    id: '5d99f4c95d244c5e3b49a855',
    name: 'Skarpnäck',
  },
  {
    id: '5d99f4c95d244c5e3b49a83d',
    name: 'Skärholmen',
  },
  {
    id: '61228ea161789c5c4d8b24f2',
    name: 'Sollentuna',
  },
  {
    id: '61228ea161789c5c4d8b249e',
    name: 'Solna',
  },
  {
    id: '5d99f4c95d244c5e3b49a835',
    name: 'Spånga-Tensta',
  },
  {
    id: '61228ea161789c5c4d8b24d9',
    name: 'Sundbyberg',
  },
  {
    id: '61228ea161789c5c4d8b24bc',
    name: 'Södertälje',
  },
  {
    id: '61228ea161789c5c4d8b24bb',
    name: 'Tyresö',
  },
  {
    id: '61228ea161789c5c4d8b2512',
    name: 'Täby',
  },
  {
    id: '61228ea161789c5c4d8b24e6',
    name: 'Upplands Väsby',
  },
  {
    id: '61228ea161789c5c4d8b241e',
    name: 'Upplands-Bro',
  },
  {
    id: '61228ea161789c5c4d8b246b',
    name: 'Vallentuna',
  },
  {
    id: '61228ea161789c5c4d8b24c4',
    name: 'Vaxholm',
  },
  {
    id: '61228ea161789c5c4d8b24d8',
    name: 'Värmdö',
  },
  {
    id: '5d99f4c95d244c5e3b49a83b',
    name: 'Älvsjö',
  },
  {
    id: '61228ea161789c5c4d8b246c',
    name: 'Österåker',
  },
];
const selectedRegions = [
  ...outerCity.map(({ id }) => id),
  ...innerCity.map(({ id }) => id),
];

export const isSelectedRegion = (regionId: string): boolean =>
  selectedRegions.includes(regionId);

export const isStockholm = (regionId: string): boolean =>
  regionId === regionIdStockholm;

export const containsSelectedRegions = (regionIds: string[]): boolean =>
  regionIds.some(regionId => isSelectedRegion(regionId));

export const regionIdStockholm = '5d99f4bf5d244c5e3b49a20c';
export const regionIdStockholmInner = '6218aa107ddbe018462a3174';

export const regionIdVastragotaland = '6133cfea7bdebdc5ba6798e7';
export const regionIdGbg = '61228ea161789c5c4d8b248e';

export const regionIdSkane = '6133cfea7bdebdc5ba6798e4';
export const regionIdMalmo = '61228ea161789c5c4d8b248b';

export const getCityId = (regionId: string): string => {
  if (regionId === regionIdStockholm) {
    return regionIdStockholmInner;
  }
  if (regionId === regionIdVastragotaland) {
    return regionIdGbg;
  }
  if (regionId === regionIdSkane) {
    return regionIdMalmo;
  }
  return '';
};

type Region = {
  __typename?: 'Region';
  id: string;
  regionType?: RegionTypeEnum;
  name: string;
  geometry?: any;
};

export const nextRegions = (
  checked: boolean,
  key: string,
  parentId: string,
  cityId: string,
  groupedRegions: Dictionary<Region[]>,
  regions: Unpacked<
    GetFilterCountiesQuery['getRegions']['regions']
  >['children'],
  regionIds: string[],
) => {
  let next: string[];

  if (checked) {
    next =
      key === 'municipality'
        ? [
            ...regionIds.filter(id => id !== parentId),
            ...groupedRegions[key]
              .map(region => region.id)
              .filter(id => !regionIds.includes(id)),
          ]
        : [
            ...regionIds.filter(
              id =>
                !groupedRegions[key].some(
                  region => region.id === id || id === parentId,
                ),
            ),
            cityId,
          ];
  } else {
    next = regionIds.filter(id => {
      if (key === 'municipality') {
        return !groupedRegions[key].some(region => region.id === id);
      } else {
        return id !== cityId;
      }
    });
  }

  if (!regions.map(region => region.id).some(id => next.includes(id))) {
    next = [...next, parentId];
  }
  return next;
};
