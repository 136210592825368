import { ZoomInOutlined } from '@ant-design/icons';
import { Link } from '@tanstack/react-location';
import {
  Button,
  Drawer,
  Result,
  Select,
  Skeleton,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import {
  Container,
  Ellipsis,
  Header,
  Name,
} from '@/components/Chat/index.styled';
import Messages from '@/components/Chat/Messages';
import ArchiveConversation from '@/components/Chat/Messages/ArchiveConversation';
import Mobile from '@/components/Chat/Mobile';
import { GoBackArrowButton } from '@/components/GoBackArrow';
import StatusMessage from '@/components/Message';
import SearchRequestInformationModal from '@/components/SearchRequestInformationModal';
import Spinner from '@/components/Spinner';
import {
  DisableSearchRequestReasonEnum,
  GetConversationQueryResult,
  SearchRequestStatusEnum,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import { toLeaseAgreement } from '@/views/LeaseAgreement/routes';
import LeaseAgreement from '@/views/LeaseAgreement/View';
import Page403 from '@/views/Page403';
import Page404 from '@/views/Page404';

const { Text, Title } = Typography;

export interface FlatProps {
  height: string;
  centeredTabs?: boolean;
  withTitle?: boolean;
  ignoreMobile?: boolean;
  onGoBack: () => void;
  isSearchRequestTenant?: boolean;
}

interface Props {
  loading: boolean;
  error: GetConversationQueryResult['error'];
  data: GetConversationQueryResult['data'];
  previousData: GetConversationQueryResult['previousData'];
  chatProps: FlatProps;
}

function Flat({
  loading,
  data,
  previousData,
  error,
  chatProps,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useWidthAndHeight();
  const [openLeaseAgreementDrawer, setOpenLeaseAgreementDrawer] = useState({
    open: false,
    id: '',
  });
  const {
    withTitle = true,
    ignoreMobile = false,
    height,
    isSearchRequestTenant = false,
    onGoBack,
  } = chatProps;

  if (error) {
    return <StatusMessage error={error} />;
  }

  if (loading && !data && !previousData) {
    if (isMobile && !ignoreMobile) {
      return <Spinner expand />;
    }
    return (
      <>
        <Container $height={height}>
          <Box
            p={3}
            flex
            alignItems={'center'}
            justifyContent={'center'}
            style={{ flexGrow: 1 }}
          >
            <Spin />
          </Box>
        </Container>
      </>
    );
  }

  if (!(data ?? previousData).conversation) {
    return (
      <Result status="404" title="404" subTitle={t(`conversationNotFound`)} />
    );
  }

  const { conversation } = data ?? previousData;
  const { leaseAgreement, searchRequestReference } = conversation;

  if (isMobile && !ignoreMobile) {
    return <Mobile conversation={conversation} onGoBack={onGoBack} />;
  }

  return (
    <>
      {isSearchRequestTenant && (
        <div
          className={
            'h-[46px] mb-auto flex-shrink-0 flex items-center justify-between'
          }
        >
          <div className={'flex flex-row items-center'}>
            <GoBackArrowButton size={'small'} onClick={() => onGoBack()}>
              {t('goBack')}
            </GoBackArrowButton>
            <div
              className={
                'h-4 border border-solid border-l-0 border-zinc-200 mr-2'
              }
            />
            <div className="flex flex-row items-center">
              {leaseAgreement ? (
                <Link
                  to={toLeaseAgreement(leaseAgreement.id)}
                  className={'text-sm'}
                >
                  {leaseAgreement.name}
                </Link>
              ) : (
                conversation.opponents.map(op => (
                  <div key={op.name} className={'text-sm'}>
                    {op.name}
                  </div>
                ))
              )}
            </div>
          </div>

          {!withTitle && (
            <ArchiveConversation
              id={conversation.id}
              archived={conversation.archived}
              iconOnly={isTablet}
            />
          )}
        </div>
      )}
      {withTitle && (
        <Header>
          <div style={{ width: '100%' }}>
            <Name
              className={
                'border-t-0 border-l-0 border-r-0 pb-2 border-solid border-zinc-100'
              }
            >
              <Box flex flexDirection={'column'} style={{ overflow: 'hidden' }}>
                {leaseAgreement ? (
                  <div className="flex items-center  flex-wrap">
                    <div className="text-base mr-2">
                      {t('expression of interest at')}
                    </div>
                    <Link
                      onClick={() =>
                        setOpenLeaseAgreementDrawer({
                          open: true,
                          id: leaseAgreement.id,
                        })
                      }
                      className={'text-base'}
                    >
                      {leaseAgreement.name}
                    </Link>
                  </div>
                ) : (
                  <Tooltip
                    overlayStyle={{ maxWidth: '300px' }}
                    title={
                      <span style={{ whiteSpace: 'pre-line' }}>
                        {searchRequestReference?.status ===
                        SearchRequestStatusEnum.Disabled
                          ? DisableSearchRequestReasonEnum.Stationary ===
                            searchRequestReference.disabledReason.reason
                            ? `${t('disabled_closed')}\n${t(
                                'disabled_stationary',
                              )}`
                            : DisableSearchRequestReasonEnum.AdminNeverLoggedIn ===
                                searchRequestReference.disabledReason.reason ||
                              DisableSearchRequestReasonEnum.AdminNoActivity ===
                                searchRequestReference.disabledReason.reason ||
                              DisableSearchRequestReasonEnum.Dislike ===
                                searchRequestReference.disabledReason.reason
                            ? `${t('disabled_closed')}\n${t(
                                'disabled_unknown',
                              )}`
                            : `${t('disabled_closed')}\n${t('disabled_found')}`
                          : searchRequestReference?.title}
                      </span>
                    }
                  >
                    <Ellipsis mr={1}>
                      <Title
                        type={
                          searchRequestReference?.status ===
                          SearchRequestStatusEnum.Disabled
                            ? 'danger'
                            : undefined
                        }
                        style={{ marginBottom: 0 }}
                        level={5}
                        className={'text-ellipsis'}
                      >
                        {searchRequestReference?.title}{' '}
                      </Title>
                    </Ellipsis>
                  </Tooltip>
                )}
                {conversation.opponents.map(op => (
                  <Ellipsis
                    key={op.name}
                    style={{
                      fontSize:
                        !leaseAgreement && !searchRequestReference
                          ? '16px'
                          : '14px',
                    }}
                  >
                    <Text strong={!leaseAgreement && !searchRequestReference}>
                      {op.name}{' '}
                    </Text>
                  </Ellipsis>
                ))}
              </Box>
              <Box flex horizontalSpacing={1}>
                {searchRequestReference && (
                  <SearchRequestInformationModal
                    id={searchRequestReference.id}
                    disabled={
                      searchRequestReference.status ===
                      SearchRequestStatusEnum.Disabled
                    }
                    button={({ onClick }) => (
                      <Button
                        icon={<ZoomInOutlined />}
                        onClick={onClick}
                        type={'text'}
                      >
                        {t('Show inquiry')}
                      </Button>
                    )}
                    inline
                  />
                )}
                <ArchiveConversation
                  id={conversation.id}
                  archived={conversation.archived}
                  iconOnly={isTablet}
                />
              </Box>
            </Name>
          </div>
          <Drawer
            open={openLeaseAgreementDrawer.open}
            placement={'right'}
            size={'large'}
            onClose={() => setOpenLeaseAgreementDrawer({ open: false, id: '' })}
            rootClassName={'no-padding-ant-drawer mobile:max-w-[100vw]'}
          >
            <LeaseAgreement preview id={openLeaseAgreementDrawer.id} />
          </Drawer>
        </Header>
      )}
      <div
        className={'flex flex-col justify-between overflow-y-auto'}
        style={{
          height: isMobile
            ? 'calc(100vh - 110px)'
            : isSearchRequestTenant
            ? `calc(${height} - 46px)`
            : height,
        }}
      >
        <Messages conversation={conversation} />
      </div>
    </>
  );
}

export default Flat;
