import { Form, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const accessOptions = [
  'immediately',
  'within_three_months',
  'within_six_months',
  'within_twelve_months',
  'more_than_twelve_months',
];

interface Props {
  label?: boolean;
}

function SelectAccessV2({ label = true }: Props): JSX.Element {
  const { t } = useTranslation();

  const options = accessOptions.map(option => ({
    value: option,
    label: t(option),
  }));

  return (
    <Form.Item name={'accessV2'} label={label && t('Desired access date')}>
      <Select
        onChange={value => console.log(value)}
        options={options}
        placeholder={t('Select access date')}
      />
    </Form.Item>
  );
}

export default SelectAccessV2;
