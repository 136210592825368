import styled from 'styled-components';

import Box from '@/components/Box';

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: 100%;
    min-height: calc(
      100vh - ${({ theme }) => theme.dimensions.mobileNavOffset}
    );
  }
`;

export const Content = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1;
`;

export const Wrapper = styled.div`
  flex-grow: 1;
  position: relative;
  overflow: hidden;
`;

export const SearchPanel = styled(Box)`
  margin-left: 0;
  position: relative;
  overflow-x: hidden;

  flex-basis: 700px;
  width: 100%;
  overflow-y: auto;
  @media (max-width: ${() => '1180px'}) {
    box-shadow: 2px 0 5px -2px #888;
    z-index: 1;
    flex-basis: 400px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-basis: 100%;
  }
`;
