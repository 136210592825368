import { Skeleton } from 'antd';
import React from 'react';

interface Props {
  title?: boolean;
  rows?: number;
}

function SearchLoader({ title = true, rows = 15 }: Props): JSX.Element {
  return (
    <div style={{ flexBasis: '900px' }}>
      <Skeleton active avatar={false} title={title} paragraph={{ rows }} />
    </div>
  );
}

export default SearchLoader;
