import { useMatch } from '@tanstack/react-location';
import { LabeledValue } from 'antd/es/select';
import React from 'react';

import SearchLoader from '@/components/Loaders/SearchLoader';
import Message from '@/components/Message';
import { useGetManagementLeaseAgreementsQuery } from '@/generated/graphql';
import useAppSelector from '@/hooks/useAppSelector';
import useMapItems from '@/hooks/useMapItems';
import useUser from '@/hooks/useUser';
import { MyLeaseAgreementsRouteProps } from '@/views/Management/routes';

import { useManagementFilterStore } from '.';
import LeaseAgreements from './LeaseAgreements';

function List(): JSX.Element {
  const user = useUser();
  const { filter } = useManagementFilterStore(state => ({
    filter: state,
  }));
  const { params } = useMatch<MyLeaseAgreementsRouteProps>();
  const type = params?.type;
  const zoom = useAppSelector(state => state.customer.zoom['management']);
  const bounds = useAppSelector(state => state.customer.bounds);
  const [limit, setLimit] = React.useState(23);
  const menu = type ?? 'all';

  const { data, loading, error, previousData } =
    useGetManagementLeaseAgreementsQuery({
      variables: {
        personalized: filter.personalizedRentals,
        input: {
          clientId: user?.activeClientId,
          ...(menu !== 'all' && { status: menu }),

          regionIds:
            filter.streets?.length > 0
              ? (filter.streets.map(street => street.value) as string[])
              : undefined,
        },
        zoom,
        limit,
        offset: 0,
        ...(bounds?.north && { bounds: bounds }),
      },
    });

  useMapItems((data ?? previousData)?.leaseAgreements?.cluster || []);

  if (loading && !data && !previousData) {
    return <SearchLoader />;
  }

  if (error) {
    return <Message error={error} />;
  }

  const { leaseAgreements } = data ?? previousData;

  return (
    <LeaseAgreements
      add
      leaseAgreements={leaseAgreements.items}
      pagination={{
        count: leaseAgreements.count,
        limit,
        setLimit,
      }}
      loading={loading}
    />
  );
}

export default List;
