/**
 *  Only edit the version of this file located in the server/src/shared folder
 */

function strEnum<T extends string>(o: T[]): { [K in T]: K } {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
}

export const usageCategories = [
  'shop',
  'industry',
  'shopPopup',
  'warehouse',
  'office',
  'coWork',
  'healthcare',
  'plotLand',
  'cafe',
  'restaurant',
  'gym',
  'other',
];

const UsageCategories = strEnum(usageCategories);
// eslint-disable-next-line no-redeclare
export type UsageCategories = keyof typeof UsageCategories;

export const services = [
  'retailManagement',
  'analysisAndStrategy',
  'establishment',
  'discontinuation',
  'lawRental',
  'workplace',
  'contractManagement',
  'premiumCustomerPlatform',
  'RAAS-X',
  'constructionProjectManagement',
  'academy',
];

export const shoppingDestinationOptions = [
  'cityMall',
  'communityCentre',
  'neighborhoodCentre',
  'outletCentre',
  'regionalMall',
  'regionalRetailPark',
  'superRegionalMall',
  'communicationHub',
];

const ShoppingDestinations = strEnum(shoppingDestinationOptions);

// eslint-disable-next-line no-redeclare
export type ShoppingDestinations = keyof typeof ShoppingDestinations;

export const searchRequestLimit = 2500;
