import { User } from '@/hooks/useUser';

export interface Bounds {
  north: number;
  south: number;
  west: number;
  east: number;
}

export interface MapObject {
  location?: {
    coordinates: [number, number];
  };
  id: string;
  count?: number;
}

export function apAccess(user: User): boolean {
  return ['admin', 'consultant'].includes(user.role);
}

export function filterByBounds<T extends MapObject>(
  items: T[],
  bounds: Bounds,
): T[] {
  return items.filter(obj => {
    if (!obj.location) {
      return true;
    }
    const [lng, lat] = obj.location.coordinates;

    return (
      lat > bounds.south &&
      lat < bounds.north &&
      lng > bounds.west &&
      lng < bounds.east
    );
  });
}
