import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { useNavigate } from '@tanstack/react-location';
import { Button, Tooltip } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { getI18n, useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import InfiniteScroll from '@/components/InfiniteScroll';
import { LeaseAgreementGrid } from '@/components/LeaseAgreementList';
import LeaseAgreementCard from '@/components/LeaseAgreementList/LeaseAgreementCard';
import AddLeaseAgreementCard from '@/components/LeaseAgreementModals/AddLeaseAgreementModal';
import EditLeaseAgreement from '@/components/LeaseAgreementModals/EditLeaseAgreement';
import InfiniteScrollLoader from '@/components/Loaders/InfiniteScrollLoader';
import PortallyEmpty from '@/components/PortallyEmpty';
import { LeaseAgreementManagementValuesFragment } from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import DeleteLeaseAgreement from '@/views/LeaseAgreement/Form/DeleteLeaseAgreement';
import { toLeaseAgreement } from '@/views/LeaseAgreement/routes';

interface Props {
  leaseAgreements: LeaseAgreementManagementValuesFragment[];
  pagination?: {
    count: number;
    limit: number;
    setLimit?: Dispatch<SetStateAction<number>>;
  };
  loading?: boolean;
  city?: boolean;
  emptyText?: string;
  add?: boolean;
}

function LeaseAgreements({
  leaseAgreements,
  pagination,
  loading,
  emptyText,
  add = true,
}: Props): JSX.Element {
  const apollo = useApolloClient();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { limit, setLimit, count } = pagination || {};
  const { isMobile } = useWidthAndHeight();
  const i18n = getI18n();

  const language = i18n.language;

  return (
    <InfiniteScroll
      loadMore={() => setLimit(limit + 24)}
      hasMore={pagination && count > leaseAgreements.length && limit <= 250}
      loader={<InfiniteScrollLoader />}
      loading={loading}
      threshold={1600}
    >
      {leaseAgreements.length === 0 && !add && (
        <Box w={'100%'} flex justifyContent={'center'}>
          <PortallyEmpty
            description={emptyText ? emptyText : t('emptyTextManagement')}
          />
        </Box>
      )}
      <LeaseAgreementGrid $withMap>
        {add && <AddLeaseAgreementCard />}
        {leaseAgreements.map(leaseAgreement => (
          <LeaseAgreementCard
            key={leaseAgreement.id}
            onClick={() =>
              navigate({
                to: toLeaseAgreement(leaseAgreement.id),
              })
            }
            showIntegrationProvider
            leaseAgreement={leaseAgreement}
            language={language}
            isMobile={isMobile}
            seeOnMap
            actions={[
              <Tooltip title={t('Edit')} key={'edit'}>
                <EditLeaseAgreement
                  id={leaseAgreement.id}
                  refetch
                  key={'edit'}
                  type={'text'}
                  icon={<EditOutlined />}
                />
              </Tooltip>,

              <Tooltip title={t('Delete')} key={'edit'}>
                <DeleteLeaseAgreement
                  integrationProvider={
                    leaseAgreement.integrationProvider ?? null
                  }
                  danger
                  id={leaseAgreement.id}
                  icon={<DeleteOutlined />}
                  onCompleted={async () => {
                    apollo.reFetchObservableQueries();
                  }}
                  type={'text'}
                />
              </Tooltip>,
            ]}
          />
        ))}
      </LeaseAgreementGrid>
    </InfiniteScroll>
  );
}

export default LeaseAgreements;
