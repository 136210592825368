import { useApolloClient } from '@apollo/client';
import { Divider, Switch } from 'antd';
import { LabeledValue } from 'antd/es/select';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import create from 'zustand';

import { AutoCompleteField } from '@/components/AutoCompleteField';
import Map from '@/components/Map';
import Markers from '@/components/Markers';
import { StreetAutocompleteDocument } from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useAppDispatch from '@/hooks/useAppDispatch';
import useUser from '@/hooks/useUser';
import { setSelectedMarker } from '@/state/customer/actions';

import List from './List';
import { Content, Flex, SearchPanel, Wrapper } from './ViewComponents';

export interface SearchFilter {
  personalizedRentals: boolean;
  personalizedProspects: boolean;
  streets: LabeledValue[];
  setFilter: (filter: Partial<SearchFilter>) => void;
}

export const useManagementFilterStore = create<SearchFilter>()(set => ({
  personalizedRentals: true,
  personalizedProspects: true,
  streets: [],
  setFilter: filter => set(filter),
}));

function Management(): JSX.Element {
  const initialCenter = {
    lng: 16.811799,
    lat: 62.641262,
  };

  const { t } = useTranslation();
  const user = useUser();
  const { filter, setFilter } = useManagementFilterStore(state => ({
    filter: state,
    setFilter: state.setFilter,
  }));

  const dispatch = useAppDispatch();

  useEffect(() => () => {
    dispatch(setSelectedMarker(undefined));
  });

  const { isMobile } = useWidthAndHeight();

  return (
    <Flex>
      <Content>
        <SearchPanel p={3} className={'tour-my-facilities'}>
          <div className={'flex justify-between gap-4 flex-wrap'}>
            <AutoCompleteField
              typeTag
              label={`${t('search')} ${t('Area or street').toLowerCase()}`}
              className="flex-1"
              query={StreetAutocompleteDocument}
              onChange={valueObject => {
                const val = valueObject as LabeledValue[];
                if (!val) {
                  setFilter({ ...filter, streets: [] });
                } else {
                  setFilter({ ...filter, streets: val });
                }
              }}
              value={filter.streets}
              returnName={'streets'}
              clientId={user?.activeClientId}
            />
            <div className="text-sm flex items-center">
              <Switch
                size="small"
                checked={filter.personalizedRentals}
                onChange={checked => {
                  setFilter({ ...filter, personalizedRentals: checked });
                }}
              />
              <span className="ml-3">{t('My facilities')}</span>
            </div>
          </div>
          <Divider />
          <List />
        </SearchPanel>
        {!isMobile && (
          <Wrapper>
            <Map
              key={'management'}
              mapKey={'management'}
              height={'100vh'}
              initialCenter={initialCenter}
              style={{
                width: '100%',
              }}
              updateBounds
              panner
            >
              <Markers />
            </Map>
          </Wrapper>
        )}
      </Content>
    </Flex>
  );
}

export default Management;
