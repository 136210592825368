import {
  CloseOutlined,
  CoffeeOutlined,
  CreditCardOutlined,
  EditOutlined,
  MailOutlined,
  PhoneOutlined,
  SaveOutlined,
  TeamOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Drawer,
  Form,
  message,
  Modal,
  Popover,
  Progress,
  Tooltip,
  Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Box from '@/components/Box';
import StaticMap from '@/components/Map/StaticMap';
import { getPolygonValues } from '@/components/Map/StaticMap/getPolygonValues';
import EditableInputField from '@/components/SearchRequest/shared/Information/EditableInputField';
import EditableNumberField from '@/components/SearchRequest/shared/Information/EditableNumberField';
import { formatSpan } from '@/components/SearchRequestListComponents/SearchRequestListItem';
import {
  AnsweredByValuesFragment,
  ContactInformationInput,
  DisableSearchRequestReasonEnum,
  Quarter,
  SearchRequestInformationInput,
  SearchRequestReferenceInformationValuesFragment,
  SearchRequestReferencePreviewValuesFragment,
  SearchRequestStatusEnum,
  useEditSearchRequestReferenceMutation,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useUser from '@/hooks/useUser';
import theme from '@/main/theme';
import { formatNumber } from '@/utils';
import ChangeContactPerson from '@/views/SearchRequestTenant/SearchRequest/ChangeContactPerson';

import Access from './Access';
import AnsweredBy from './AnsweredBy';
import BudgetSpan from './BudgetSpan';
import Documents from './Documents';
import EditableTextField from './EditableTextField';
import EmployeesSpan from './EmployeesSpan';
import Regions from './Regions';
import Size from './Size';
import SearchRequestTitle from './Title';
import UsageCategory from './UsageCategory';

const { Title } = Typography;
const ebieLogo =
  'https://res.cloudinary.com/portally/image/upload/v1692004820/ebie_logo_s5nf4u.svg';
interface Props {
  searchRequestReference:
    | SearchRequestReferenceInformationValuesFragment
    | SearchRequestReferencePreviewValuesFragment;
  editable?: boolean;
  withTitle?: boolean;
  type: 'flat' | 'modal' | 'drawer';
  open?: boolean;
  onCancel?: () => void;
  answeredBy?: AnsweredByValuesFragment;
  isAdmin?: boolean;
}

export const InformationGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 16px;
`;

function Information({
  searchRequestReference,
  editable: isEditable = false,
  withTitle = true,
  type,
  open,
  onCancel,
  answeredBy,
  isAdmin = false,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();
  const { isMobile } = useWidthAndHeight();
  const [editing, setEditing] = useState(false);
  const [editSearchRequest, { loading }] =
    useEditSearchRequestReferenceMutation({
      onCompleted: async () => {
        message.success(t('Saved'));

        setEditing(!editing);
      },
      onError: () => {
        message.error(t('error'));
      },
    });

  const [form] = useForm<
    Omit<SearchRequestInformationInput, 'access'> & {
      access: string[];
      contactInformation?: ContactInformationInput;
    }
  >();

  const createPolygonValues = useCallback(
    () =>
      getPolygonValues(
        searchRequestReference.searchParameters.regions,
        searchRequestReference.searchParameters.geometry,
      ),
    [
      searchRequestReference.searchParameters,
      searchRequestReference?.searchParameters?.geometry,
    ],
  );

  const polygonValues = createPolygonValues();

  const searchParameters = searchRequestReference.searchParameters;

  const toggle = (): void => {
    form.resetFields();
    setEditing(!editing);
  };

  const CloseButton = (): JSX.Element => (
    <Tooltip title={t('Close')}>
      <Button
        icon={!isMobile && <CloseOutlined />}
        onClick={toggle}
        disabled={loading}
      >
        {isMobile && t('Cancel')}
      </Button>
    </Tooltip>
  );

  const SaveButton = (): JSX.Element => (
    <Tooltip title={t('Save')}>
      <Button
        loading={loading}
        onClick={() => form.submit()}
        style={{ marginLeft: '8px' }}
        type={'primary'}
        icon={<SaveOutlined />}
      >
        {isMobile && t('Save')}
      </Button>
    </Tooltip>
  );

  const editable =
    isEditable &&
    searchRequestReference.status !== SearchRequestStatusEnum.Disabled;
  const isOwner = user?.activeClientId == searchRequestReference.clientId;

  const content = (
    <Form
      form={form}
      initialValues={{
        budgetSpan: searchRequestReference.budgetSpan,
        employeesSpan: searchRequestReference.employeesSpan,
        size: searchRequestReference.searchParameters?.size,
      }}
      onFinish={async values => {
        console.log(values);
        const {
          access,
          accessV2,
          contactInformation,
          title,
          budgetSpan,
          employeesSpan,
          ...rest
        } = values;

        const information = {
          title: title ?? '',
          ...rest,
          employeesSpan:
            employeesSpan?.min && employeesSpan?.max ? employeesSpan : null,
          budgetSpan: budgetSpan?.min && budgetSpan?.max ? budgetSpan : null,
          accessV2,
          access:
            access && access.length == 2
              ? {
                  quarter: access[1] as Quarter,
                  year: access[0],
                }
              : null,
          ...rest,
        };

        await editSearchRequest({
          variables: {
            information,
            contactInformation,
            id: searchRequestReference.id,
          },
        });
      }}
    >
      <>
        {searchRequestReference.status === SearchRequestStatusEnum.Disabled && (
          <Box flex horizontalSpacing={1} alignItems={'baseline'}>
            <Tooltip
              overlayStyle={{ maxWidth: '300px' }}
              title={
                <span style={{ whiteSpace: 'pre-line' }}>
                  {DisableSearchRequestReasonEnum.Stationary ===
                  searchRequestReference.disabledReason.reason
                    ? `${t('disabled_closed')}\n${t('disabled_stationary')}`
                    : DisableSearchRequestReasonEnum.AdminNeverLoggedIn ===
                        searchRequestReference.disabledReason.reason ||
                      DisableSearchRequestReasonEnum.AdminNoActivity ===
                        searchRequestReference.disabledReason.reason ||
                      DisableSearchRequestReasonEnum.Dislike ===
                        searchRequestReference.disabledReason.reason
                    ? `${t('disabled_closed')}\n${t('disabled_unknown')}`
                    : `${t('disabled_closed')}\n${t('disabled_found')}`}
                </span>
              }
            >
              <Title level={3} style={{ color: theme.colors.red }}>
                {isOwner || !searchRequestReference.client
                  ? t('The tenant has ended their search')
                  : t('has ended their search', {
                      company: searchRequestReference.client.name,
                    })}
              </Title>
            </Tooltip>
          </Box>
        )}
        {answeredBy && (
          <AnsweredBy searchRequests={answeredBy.searchRequests} />
        )}
        {(isOwner || isAdmin) &&
          editable &&
          !!searchRequestReference.contactPerson?.id && (
            <ChangeContactPerson
              searchRequestReferenceId={searchRequestReference.id}
              clientId={
                isAdmin ? searchRequestReference.clientId : user.activeClientId
              }
              contactPersonId={searchRequestReference.contactPerson.id}
            />
          )}
        <Box flex justifyContent={'space-between'} w={'100%'}>
          <div className="flex flex-col align-baseline">
            {withTitle && (
              <SearchRequestTitle
                title={searchRequestReference.title}
                editing={editing}
              />
            )}
          </div>
          {editable && (
            <Box>
              {!editing && type !== 'drawer' && (
                <Tooltip title={t('Edit')}>
                  <Button icon={<EditOutlined />} onClick={toggle} />
                </Tooltip>
              )}
              {editing && !isMobile && type !== 'drawer' && (
                <>
                  <CloseButton />
                  <SaveButton />
                </>
              )}
            </Box>
          )}
        </Box>
      </>
      {isAdmin &&
        'contactInformation' in searchRequestReference &&
        searchRequestReference.contactInformation && (
          <Box
            p={2}
            style={{
              border: `1px solid ${theme.colors.inputBorder}`,
              borderRadius: theme.dimensions.borderRadiusCard,
            }}
            my={2}
            w={'100%'}
          >
            <Box mb={editing ? 0 : 2}>
              <EditableInputField
                name={['contactInformation', 'email']}
                value={searchRequestReference.contactInformation.email}
                title={'Email'}
                editing={editing}
                icon={<MailOutlined />}
              />
            </Box>
            <InformationGrid>
              <EditableInputField
                name={['contactInformation', 'phone']}
                value={searchRequestReference.contactInformation.phone}
                title={'Telefon'}
                editing={editing}
                icon={<PhoneOutlined />}
              />
              <EditableInputField
                name={['contactInformation', 'company']}
                value={searchRequestReference.contactInformation.company}
                title={'Företag'}
                editing={editing}
                icon={<CoffeeOutlined />}
              />
            </InformationGrid>
          </Box>
        )}
      <div className="flex justify-between mt-4 pl-0 p-2">
        <div>
          <div>
            <div className="text-xl flex items-center">
              {searchRequestReference.client.name}
            </div>
          </div>
        </div>
        {searchRequestReference.client.ebieScore?.rating &&
          searchRequestReference.client.ebieScore.tier && (
            <div>
              <Popover
                className="prevent-request-drawer-close"
                content={
                  <div>
                    <div className="max-w-[280px]">
                      Vår bedömning av ett bolags kreditvärdighet är avgörande
                      för att förstå dess finansiella hälsa och förmåga att möta
                      sina ekonomiska åtaganden. Vi använder en
                      kreditvärdighetsskala som sträcker sig över bokstäverna C
                      - B - BB - BBB och A, där varje bokstav representerar en
                      specifik nivå av kreditvärdighet.
                    </div>
                    <a
                      type="link"
                      className="prevent-request-drawer-close"
                      href="https://ebie.se/"
                      rel="no opener no referrer noreferrer"
                      target="_blank"
                    >
                      Läs mer
                    </a>
                  </div>
                }
              >
                <div className=" bg-gray-white border border-solid border-gray-200 rounded-md p-2">
                  <div className="text-xs font-semibold text-gray-400">
                    Rating
                  </div>
                  <div className={`text-2xl text-[#195A8B]  font-semibold`}>
                    {searchRequestReference.client.ebieScore?.tier}
                  </div>

                  <div>
                    <div className="flex text-xs text-gray-500">
                      Powered by
                      <a
                        href="https://ebie.se"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="w-12 ml-2" src={ebieLogo}></img>
                      </a>
                    </div>
                  </div>
                </div>
              </Popover>
            </div>
          )}
      </div>
      <Divider />
      <InformationGrid style={{ fontSize: '16px' }}>
        <Size
          min={searchParameters?.size?.min}
          max={searchParameters?.size?.max}
          editing={editing}
        />
        <UsageCategory
          usageCategories={searchParameters.usageCategories}
          editing={editing}
        />
        <Access
          editing={editing}
          access={searchRequestReference.access}
          accessV2={searchRequestReference.accessV2}
        />
      </InformationGrid>

      {isAdmin && editing && (
        <InformationGrid>
          <BudgetSpan
            min={searchRequestReference?.budgetSpan?.min}
            max={searchRequestReference?.budgetSpan?.max}
            editing={editing}
          />
          <EmployeesSpan
            min={searchRequestReference?.employeesSpan?.min}
            max={searchRequestReference?.employeesSpan?.max}
            editing={editing}
          />
        </InformationGrid>
      )}

      <Box flex horizontalSpacing={2} mt={3} style={{ fontSize: '16px' }}>
        <EditableNumberField
          title={'Budget'}
          name={'budget'}
          value={searchRequestReference.budget}
          editing={editing}
          icon={<CreditCardOutlined />}
          props={{
            formatter: value => formatNumber(value),
            addonAfter: t('pricePerMonth'),
          }}
          suffix={t('pricePerMonth')}
        />
        {typeof searchRequestReference.budgetSpan?.min === 'number' &&
          !editing &&
          !searchRequestReference.budget && (
            <div className="flex space-x-1 items-center">
              <WalletOutlined className="text-gray-500" />
              <Box>
                {formatSpan(
                  searchRequestReference.budgetSpan?.min,
                  searchRequestReference.budgetSpan?.max,
                )}{' '}
                {t('pricePerMonth')}
              </Box>
            </div>
          )}
        <Form.Item noStyle dependencies={['usageCategories']}>
          {({ getFieldValue }) => {
            const usageCategories = editing
              ? getFieldValue('usageCategories') ?? []
              : searchParameters.usageCategories;
            if (
              !isAdmin &&
              !usageCategories.some(category =>
                ['office', 'coWork'].includes(category),
              )
            ) {
              return null;
            }

            if (editing) {
              return (
                <div>
                  <EditableNumberField
                    title={t('employees')}
                    name={'employees'}
                    value={searchRequestReference.employees}
                    editing={editing}
                    suffix={
                      searchRequestReference.employees == 1
                        ? t('employees_one')
                        : t('employees_other')
                    }
                    icon={<TeamOutlined />}
                    props={{
                      formatter: value => formatNumber(value),
                      style: {
                        minWidth: '170px',
                      },
                    }}
                  />
                </div>
              );
            }

            if (
              typeof searchRequestReference.employeesSpan?.min === 'number' &&
              !searchRequestReference.employees
            ) {
              return (
                <div className="flex space-x-1 items-center">
                  <TeamOutlined className="text-gray-500" />
                  <Box>
                    {formatSpan(
                      searchRequestReference.employeesSpan?.min,
                      searchRequestReference.employeesSpan?.max,
                    )}{' '}
                    {t('employees_other')}
                  </Box>
                </div>
              );
            }
            return (
              <EditableNumberField
                title={t('employees')}
                name={'employees'}
                value={searchRequestReference.employees}
                editing={editing}
                suffix={
                  searchRequestReference.employees == 1
                    ? t('employees_one')
                    : t('employees_other')
                }
                icon={<TeamOutlined />}
                props={{
                  formatter: value => formatNumber(value),
                  style: {
                    minWidth: '170px',
                  },
                }}
              />
            );
          }}
        </Form.Item>
      </Box>
      <Divider />
      <Box verticalSpacing={3} mb={2} style={{ fontSize: '16px' }}>
        <EditableTextField
          fieldName={'description'}
          value={searchRequestReference.description}
          title={t('description')}
          editing={editing}
        />
        <EditableTextField
          fieldName={'specificNeedsInformation'}
          value={searchRequestReference.specificNeedsInformation}
          title={t('special requirements')}
          editing={editing}
        />
        <EditableTextField
          fieldName={'businessInformation'}
          value={searchRequestReference.businessInformation}
          title={t('Additional information')}
          editing={editing}
        />
        <EditableTextField
          fieldName={'areaInformation'}
          value={searchRequestReference.areaInformation}
          title={t('search area')}
          editing={editing}
        />
      </Box>
      <Box>
        <Regions regions={searchRequestReference.searchParameters.regions} />

        {polygonValues.length > 0 && (
          <StaticMap
            key={searchRequestReference.id}
            polygons={polygonValues}
            bounds={{
              north: searchRequestReference.searchParameters.bounds.north,
              south: searchRequestReference.searchParameters.bounds.south,
              east: searchRequestReference.searchParameters.bounds.east,
              west: searchRequestReference.searchParameters.bounds.west,
            }}
            allowReload
            height={'300px'}
            style={{
              width: '100%',
              borderRadius: '6px',
            }}
          />
        )}
      </Box>
      <Documents
        documents={searchRequestReference.documents}
        editing={editing}
      />
      {editable && editing && isMobile && type !== 'drawer' && (
        <Box horizontalSpacing={1} w={'100%'} justifyContent={'flex-end'}>
          <SaveButton />
          <CloseButton />
        </Box>
      )}
    </Form>
  );
  if (type === 'flat') {
    return content;
  }
  if (type === 'modal') {
    return (
      <Modal
        width={700}
        open={open}
        onCancel={onCancel}
        footer={null}
        closable={false}
      >
        {content}
      </Modal>
    );
  }
  if (type === 'drawer') {
    return (
      <Drawer
        placement={isMobile ? 'bottom' : 'left'}
        height={'100%'}
        open={open}
        footer={
          <Box flex justifyContent={'flex-end'} horizontalSpacing={1}>
            {editing ? (
              <>
                <CloseButton />
                <SaveButton />
              </>
            ) : (
              <Box flex horizontalSpacing={1}>
                <Button onClick={() => onCancel()}>{t('Close')}</Button>
                {editable && (
                  <Button
                    icon={<EditOutlined />}
                    type={'primary'}
                    onClick={() => setEditing(true)}
                  >
                    {t('Edit')}
                  </Button>
                )}
              </Box>
            )}
          </Box>
        }
        closable={!isMobile}
        destroyOnClose
        onClose={() => onCancel()}
      >
        {content}
      </Drawer>
    );
  }
}

export default Information;
