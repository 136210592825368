import { useApolloClient } from '@apollo/client';
import { Button, Drawer, Form, message, Modal, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import Message from '@/components/Message';
import Area from '@/components/SearchRequest/SearchRequestModal/Area';
import StyledSteps from '@/components/StyledSteps';
import {
  Quarter,
  SearchRequestAreaInput,
  SearchRequestInformationInput,
  useAddSearchRequestMutation,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useScrollPrevention from '@/hooks/useScrollPrevention';
import useUser from '@/hooks/useUser';

import Description from './Description';
import InquiryInfo from './InquiryInfo';

const { Step } = Steps;

const { useForm } = Form;

interface Props {
  visible: boolean;
  onCancel: (submit: boolean) => void;
}
function SearchRequestModal({ visible, onCancel }: Props): JSX.Element {
  const user = useUser();
  const { t } = useTranslation();
  useScrollPrevention(visible);
  const [requestForm] = useForm<
    Omit<
      SearchRequestInformationInput & SearchRequestAreaInput,
      'access' | 'geometry' | 'regionIds'
    > & {
      access?: string[];
      regionIds: { key: string; label: string }[];
    }
  >();

  const { width, isMobile } = useWidthAndHeight();
  const [polygons, setPolygons] = useState<google.maps.LatLngLiteral[][]>([]);
  const [regionPolygons, setRegionPolygons] = useState<
    google.maps.LatLngLiteral[][]
  >([]);
  const [addSearchRequest, { loading, error }] = useAddSearchRequestMutation({
    onCompleted: async () => {
      onCancel(true);
      message.success(t('searchRequestSuccessMessage'), 2.5);
      requestForm.resetFields();
      setRegionPolygons([]);
      setPolygons([]);
      await apollo.reFetchObservableQueries();
    },
    onError: () => {
      setStep(step => step - 1);
    },
  });

  const apollo = useApolloClient();
  const [searchValueExists, setSearchValueExists] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [modalVisible, setVisible] = useState<boolean>(false);
  const [render, setRender] = React.useState(false);

  const steps = [
    {
      title: t('search area'),
    },
    {
      title: t('description'),
    },
    {
      title: t('Send'),
    },
  ];

  useEffect(() => {
    setStep(0);
    setVisible(visible);
    if (
      (requestForm.getFieldValue('regionIds') &&
        requestForm.getFieldValue('regionIds').length > 0) ||
      polygons.length > 0
    ) {
      setSearchValueExists(true);
    } else {
      setSearchValueExists(false);
    }
  }, [
    requestForm.getFieldValue('regionIds'),
    requestForm,
    user?.activeClient?.name,
    visible,
    polygons.length,
  ]);

  const firstStep = 0;
  const finalStep = 2;

  const goBackButtonContent = (): string =>
    step === firstStep ? t('Cancel') : t('goBack');

  const goForwardButtonContent = (): string => {
    if (step === finalStep) {
      return t('Send');
    }
    if (step > finalStep) {
      return t('Sending');
    }
    return t('Next');
  };

  const handleSubmit = async (): Promise<void> => {
    if (step === finalStep) {
      /* Submit the search request */
      const formValues = requestForm.getFieldsValue();

      const geometry =
        polygons.length > 0
          ? {
              type: 'MultiPolygon',
              coordinates: polygons.map(polygon => {
                const coordinates = polygon.map(elem => [elem.lng, elem.lat]);
                // MongoDB require the loop to be closed with the initial value
                return [[...coordinates, coordinates[0]]];
              }),
            }
          : null;

      const { access, regionIds, ...rest } = formValues;

      setStep(step + 1);

      await addSearchRequest({
        variables: {
          area: {
            regionIds: regionIds?.map(region => region.key) ?? [],
            geometry,
          },
          information: {
            ...rest,
            access:
              access && access.length == 2
                ? {
                    year: access[0],
                    quarter: access[1] as Quarter,
                  }
                : undefined,
          },
        },
      });
    } else if (step === 1) {
      requestForm
        .validateFields()
        .then(() => setStep(step + 1))
        .catch(({ errorFields }) => {
          errorFields.flatMap(err => err.errors).map(err => message.error(err));
        });
    } else {
      setStep(step + 1);
    }
  };

  const footer = [
    <Button
      key="back"
      disabled={step > finalStep}
      onClick={() => {
        if (step === 0) {
          onCancel(false);
        } else {
          setStep(step - 1);
        }
      }}
    >
      {goBackButtonContent()}
    </Button>,
    <Button
      key="submit"
      type="primary"
      disabled={!searchValueExists}
      onClick={() => handleSubmit()}
      loading={loading}
    >
      {goForwardButtonContent()}
    </Button>,
  ];

  const searchSteps = (
    <StyledSteps>
      <Steps
        current={step}
        responsive={false}
        size={isMobile ? 'small' : undefined}
      >
        {steps.map(item => (
          <Step key={item.title} title={width > 400 ? item.title : ''} />
        ))}
      </Steps>
    </StyledSteps>
  );

  const content = (
    <>
      <Form
        layout="vertical"
        form={requestForm}
        onFieldsChange={field => {
          if (
            field &&
            field[0] &&
            Array.isArray(field[0].name) &&
            field[0].name.includes('regionIds')
          ) {
            setRender(!render);
          }
        }}
      >
        <Area
          isMobile={isMobile}
          visible={step === firstStep}
          regionPolygons={regionPolygons}
          setRegionPolygons={polygons => {
            setRegionPolygons(polygons);
          }}
          polygons={polygons}
          setPolygons={polygons => {
            setPolygons(polygons);
          }}
          form={requestForm}
        />
        <Description visible={step === 1} />
        <InquiryInfo visible={step === finalStep} />

        {error && <Message error={error} />}
      </Form>
    </>
  );
  return isMobile ? (
    <Drawer
      width={700}
      height={'100%'}
      placement="bottom"
      closable={false}
      title={
        <Box flex flexDirection={'column'} verticalSpacing={2}>
          <div>{t('New search')}</div>
          <div>{searchSteps}</div>
        </Box>
      }
      open={modalVisible}
      footer={
        <Box flex justifyContent="flex-end" gap={1}>
          {footer}
        </Box>
      }
      onClose={() => onCancel(false)}
    >
      {content}
    </Drawer>
  ) : (
    <Modal
      width={700}
      centered={step !== 1}
      title={t('New search')}
      open={modalVisible}
      footer={footer}
      onCancel={() => onCancel(false)}
    >
      <Box w={'100%'} my={3}>
        {searchSteps}
      </Box>
      {content}
    </Modal>
  );
}

export default SearchRequestModal;
