import { PhoneOutlined } from '@ant-design/icons';
import { PersonFill } from '@styled-icons/bootstrap/PersonFill';
import { useNavigate } from '@tanstack/react-location';
import {
  Avatar,
  Badge,
  Button,
  Card,
  Drawer,
  List,
  message,
  Modal,
  Popover,
  Switch,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoOutline } from 'styled-icons/evaicons-outline';

import Chat from '@/components/Chat';
import ListItem from '@/components/ClickableListItem';
import CardError from '@/components/Errors/CardError';
import MatchIcon from '@/components/MatchIcon';
import MessagePreview from '@/components/MessagePreview';
import PortallyIcon from '@/components/PortallyLogo/PortallyIcon';
import {
  LeaseAgreementProspectStatus,
  useEditLeaseAgreementProspectStatusMutation,
  useGetConversationsQuery,
  useGetMyLeaseAgreementProspectsQuery,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useUser from '@/hooks/useUser';
import theme from '@/main/theme';
import ProspectInformation from '@/views/LeaseAgreementProspects/ProspectInformation';
import { toLeaseAgreementsProspects } from '@/views/LeaseAgreementProspects/routes';
import { useManagementFilterStore } from '@/views/Management';
import { getTitleAndSubtitleFromConversation } from '@/views/Messages/Inbox/InboxTitle';

const { Text } = Typography;

function cutString(cutAt: number, str: string) {
  let newStr = str;
  if (str.length > cutAt) {
    newStr = `${str.slice(0, cutAt)} ...`;
  }

  return newStr;
}

export function LeaseAgreementProspectTitle(): JSX.Element {
  const { t } = useTranslation();

  const { filter, setFilter } = useManagementFilterStore(state => ({
    filter: state,
    personalizedProspects: state.personalizedProspects,
    setFilter: state.setFilter,
  }));

  return (
    <div className="flex justify-between items-center">
      <Avatar
        shape="square"
        icon={
          <MatchIcon
            style={{ fontSize: '20px', color: theme.colors.primaryPurple }}
          />
        }
        style={{ background: theme.colors.light }}
      />
      <div className="flex flex-row justify-between w-full">
        <div>{t('Expressions of interest')}</div>
        {/*<div>
          <Text type={'secondary'} style={{ fontSize: '14px' }}>
            {t('My prospects')}
          </Text>{' '}
          <Switch
            size="small"
            checked={filter.personalizedProspects}
            onChange={checked => {
              setFilter({ ...filter, personalizedProspects: checked });
            }}
          />
          </div>*/}
      </div>
      <div className="pl-2">
        <Popover
          overlayStyle={{
            maxWidth: '300px',
          }}
          placement="leftTop"
          title={'Här samlar vi alla dina intresseanmälningar.'}
          content={
            <div className="flex flex-col justify-center ">
              <div className="mb-2">
                <PortallyIcon size={'16'} color={theme.colors.primaryPurple} />
                <span className="ml-1">
                  Intresseanmälningar från inloggade verifierade hyresgäster
                  kommunicerar du med direkt i Portallys chatt.
                </span>
              </div>
              <div className="mb-2">
                <PhoneOutlined
                  style={{
                    color: theme.colors.coral,
                  }}
                />
                <span className="ml-1">
                  Intresseanmälningar från besökare på vår hemsida kontaktar du
                  på de kontaktuppgifter de lämnat i formuläret, mail eller
                  telefon.
                </span>
              </div>
            </div>
          }
        >
          <div>
            <InfoOutline size={18} />
          </div>
        </Popover>
      </div>
    </div>
  );
}

const MyLeaseAgreementProspects = (): JSX.Element => {
  const { t } = useTranslation();
  const user = useUser();
  const navigate = useNavigate();
  const { isMobile } = useWidthAndHeight();
  const [selectedProspect, setSelectedProspect] = useState<
    | {
        conversationId: string;
        archived: boolean;
        leaseAgreementName: string;
      }
    | undefined
  >(undefined);
  const [openModal, setOpenModal] = useState({
    open: false,
    prospect: undefined,
  });

  const { data, loading, error, previousData } =
    useGetMyLeaseAgreementProspectsQuery({
      variables: {
        filter: {
          clientId: user?.activeClientId,
          status: [
            LeaseAgreementProspectStatus.Accepted,
            LeaseAgreementProspectStatus.Opened,
            LeaseAgreementProspectStatus.Unopened,
          ],
          adminStatus: [LeaseAgreementProspectStatus.Accepted],
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const {
    data: dataA,
    loading: loadingA,
    error: errorA,

    previousData: previousDataA,
  } = useGetConversationsQuery({
    variables: {
      filter: {
        limit: 8,
        leaseAgreementProspect: true,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const [editLeaseAgreementProspectStatus] =
    useEditLeaseAgreementProspectStatusMutation({
      refetchQueries: ['GetMyLeaseAgreementProspects'],
      onError: () => {
        message.error(t('error'));
      },
    });

  if (error) {
    return (
      <Card title={<LeaseAgreementProspectTitle />}>
        <CardError />
      </Card>
    );
  }

  if (
    (loading && !data && !previousData) ||
    (loadingA && !previousDataA && !dataA)
  ) {
    return <Card title={<LeaseAgreementProspectTitle />} loading />;
  }
  if (errorA) {
    return (
      <Card title={<LeaseAgreementProspectTitle />}>
        <CardError />
      </Card>
    );
  }

  const { prospects } = (data ?? previousData).leaseAgreementProspects;

  const { conversations } = (dataA ?? previousDataA).conversations;
  const mergedProspects = [...prospects, ...conversations];

  const sortedProspects = mergedProspects.sort((a, b) => {
    if (a.created < b.created) {
      return 1;
    }
    if (a.created > b.created) {
      return -1;
    }
    return 0;
  });
  return (
    <Card
      title={<LeaseAgreementProspectTitle />}
      actions={[
        <Button
          key={'show-all-messages'}
          type="dashed"
          icon={<MatchIcon />}
          onClick={() =>
            navigate({
              to: toLeaseAgreementsProspects(),
            })
          }
        >
          {t('showAll')}
        </Button>,
      ]}
    >
      <List
        itemLayout="horizontal"
        dataSource={sortedProspects}
        locale={{
          emptyText: (
            <Typography.Title level={4}>
              {t('dashboard expressions of interest')}
            </Typography.Title>
          ),
        }}
        renderItem={item => {
          if (item.__typename === 'LeaseAgreementProspect') {
            return (
              <ListItem
                onClick={() => {
                  setOpenModal({ open: true, prospect: item });
                  if (
                    item.status !== LeaseAgreementProspectStatus.Accepted &&
                    item.status !== LeaseAgreementProspectStatus.Declined
                  ) {
                    editLeaseAgreementProspectStatus({
                      variables: {
                        id: item.id,
                        status: LeaseAgreementProspectStatus.Opened,
                      },
                    });
                  }
                }}
                $unread={item.status === LeaseAgreementProspectStatus.Unopened}
              >
                <List.Item.Meta
                  className="p-4 relative"
                  avatar={
                    <Avatar
                      shape="square"
                      size={40}
                      icon={<PersonFill />}
                      src={item.leaseAgreement?.image?.url}
                    />
                  }
                  title={
                    <div className="flex flex-col ">
                      <div className="flex space-x-1 items-center">
                        <div>
                          <PhoneOutlined
                            style={{
                              color: theme.colors.coral,
                              marginRight: 4,
                            }}
                          />
                          {item.leaseAgreement.name}
                        </div>
                        <div>
                          {!!item.leaseAgreement.landlordContactPersons
                            .length &&
                            !item.leaseAgreement.landlordContactPersons
                              .map(contactPerson => contactPerson.id)
                              .includes(user?.id) && (
                              <div className="text-sm text-zinc-600 font-normal">
                                |{' '}
                                {item.leaseAgreement.landlordContactPersons
                                  .map(user => user.name)
                                  .join(', ')}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="flex">
                        <div>{item.company}</div>
                        <Badge
                          className="ml-2"
                          size="default"
                          color={
                            item.status ===
                            LeaseAgreementProspectStatus.Unopened
                              ? 'red'
                              : item.status ===
                                LeaseAgreementProspectStatus.Opened
                              ? 'orange'
                              : 'green'
                          }
                        />
                        <div className="flex space-x-1"></div>
                      </div>
                    </div>
                  }
                  description={<div>{cutString(150, item.message)}</div>}
                />
              </ListItem>
            );
          }
          if (item.__typename === 'Message') {
            const { title, subTitle, responsibleUser } =
              getTitleAndSubtitleFromConversation(
                item.conversation,
                user?.activeClientId,
                t,
                false,
              );

            return (
              <ListItem
                onClick={() =>
                  setSelectedProspect({
                    conversationId: item.conversation.id,
                    archived: item.conversation.archived,
                    leaseAgreementName: item.conversation.leaseAgreement.name,
                  })
                }
                $unread={item.conversation.unread.count !== 0}
                extra={
                  item.conversation.unread.count ? (
                    <Badge count={item.conversation.unread.count} />
                  ) : undefined
                }
              >
                <List.Item.Meta
                  className="p-4"
                  avatar={
                    <Avatar
                      shape="square"
                      size={40}
                      icon={<PersonFill />}
                      src={item.conversation.leaseAgreement?.image?.url}
                    />
                  }
                  title={
                    <div>
                      <span style={{ fontSize: '14px' }}>
                        <div className="flex space-x-1">
                          {title}{' '}
                          <div>
                            {!!item.conversation.leaseAgreement
                              .landlordContactPersons.length &&
                              !item.conversation.leaseAgreement.landlordContactPersons
                                .map(contactPerson => contactPerson.id)
                                .includes(user?.id) && (
                                <div className="text-sm text-zinc-600 font-normal">
                                  |{' '}
                                  {item.conversation.leaseAgreement.landlordContactPersons
                                    .map(user => user.name)
                                    .join(', ')}
                                </div>
                              )}
                          </div>
                        </div>
                        {subTitle && (
                          <div className="font-normal flex space-x-2">
                            <span>{subTitle}</span>
                          </div>
                        )}
                      </span>
                    </div>
                  }
                  description={
                    <MessagePreview
                      author={
                        !item.senderUser
                          ? t('Deleted user')
                          : item.senderUser?.id === user?.id
                          ? t('You')
                          : item.senderUser.name
                      }
                      message={item.message}
                      created={item.created}
                      avatarUrl={item.senderUser?.image?.url}
                    />
                  }
                />
              </ListItem>
            );
          }
        }}
      />
      <Modal
        title={t('Expression of interest')}
        footer={null}
        open={openModal.open}
        onCancel={() => setOpenModal({ open: false, prospect: undefined })}
      >
        <ProspectInformation
          id={openModal?.prospect?.id}
          onClose={() => setOpenModal({ open: false, prospect: undefined })}
        />
      </Modal>

      <Chat
        type={{
          type: 'drawer',
          props: {
            placement: 'left',
            width: isMobile ? '100%' : 700,
            open: !!selectedProspect?.conversationId,
            onClose: () => {
              setSelectedProspect(undefined);
            },
          },
        }}
        conversationId={selectedProspect?.conversationId}
      />
    </Card>
  );
};

export default MyLeaseAgreementProspects;
