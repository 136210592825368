import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import {
  FieldPolicy,
  FieldReadFunction,
  TypePolicies,
  TypePolicy,
} from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Upload: any;
  geoMultiPolygon: any;
  geoPoint: any;
  geoPolygon: any;
};

export type Access = {
  __typename?: 'Access';
  quarter: Quarter;
  year: Scalars['String'];
};

export enum AccessEnum {
  AccordingToAgreement = 'according_to_agreement',
  Immediately = 'immediately',
  WithinSixMonths = 'within_six_months',
}

export enum AccessEnumV2 {
  Immediately = 'immediately',
  MoreThanTwelveMonths = 'more_than_twelve_months',
  WithinSixMonths = 'within_six_months',
  WithinThreeMonths = 'within_three_months',
  WithinTwelveMonths = 'within_twelve_months',
}

export type AccessInput = {
  quarter: Quarter;
  year: Scalars['String'];
};

export enum AccountCreationType {
  CreateAccount = 'createAccount',
  NewUser = 'newUser',
  SharedSearchRequest = 'sharedSearchRequest',
  VerifyAccount = 'verifyAccount',
}

export type ActionCount = {
  __typename?: 'ActionCount';
  count: Scalars['Int'];
  date: Scalars['Date'];
};

export type ActionFilter = {
  clientId?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['Date']>;
  usageCategory?: InputMaybe<Scalars['String']>;
};

export type ActionStats = {
  __typename?: 'ActionStats';
  total?: Maybe<Scalars['Int']>;
  unanswered?: Maybe<Scalars['Int']>;
};

export enum ActionTypeEnum {
  Added = 'added',
  Updated = 'updated',
}

export type ActiveSearchRequestData = {
  __typename?: 'ActiveSearchRequestData';
  all: SearchRequestData;
  archived: SearchRequestData;
  inProgress: SearchRequestData;
  interesting: SearchRequestData;
  negotiation: SearchRequestData;
  notifications: Scalars['Int'];
  success: SearchRequestData;
  todo: SearchRequestData;
};

export type ActiveSearchRequestDataAllArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type ActiveSearchRequestDataInterestingArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type ActiveSearchRequestDataTodoArgs = {
  limit: Scalars['Int'];
};

export type ActiveSearchRequestFilter = {
  responsibleUserIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
};

export type ActiveUserStats = {
  __typename?: 'ActiveUserStats';
  active?: Maybe<Array<Maybe<User>>>;
  activeOverTime: Array<DateStat>;
  lost?: Maybe<Array<Maybe<User>>>;
};

export type ActiveUserStatsActiveArgs = {
  activeLimit: Scalars['Int'];
};

export type ActiveUserStatsActiveOverTimeArgs = {
  from?: InputMaybe<Scalars['Date']>;
};

export type ActiveUserStatsLostArgs = {
  lostLimit: Scalars['Int'];
};

export type ActivityData = {
  __typename?: 'ActivityData';
  activities: Array<Maybe<ActivityLog>>;
  count: Scalars['Int'];
  statistics: ActivityLogStatistics;
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  client?: Maybe<Client>;
  conversation?: Maybe<Conversation>;
  created: Scalars['Date'];
  id: Scalars['ID'];
  leaseAgreement?: Maybe<LeaseAgreement>;
  searchRequest?: Maybe<SearchRequest>;
  statusUpdate?: Maybe<StatusUpdate>;
  type: ActivityLogEnum;
  user: User;
};

export enum ActivityLogEnum {
  AcceptSearchRequest = 'accept_search_request',
  AddLeaseAgreement = 'add_lease_agreement',
  AddMessage = 'add_message',
  DeclineSearchRequest = 'decline_search_request',
  EditLeaseAgreement = 'edit_lease_agreement',
  NewMessage = 'new_message',
  PromoteLeaseAgreement = 'promote_lease_agreement',
  SendSearchRequest = 'send_search_request',
  StatusUpdate = 'status_update',
}

export type ActivityLogFilterInput = {
  from?: InputMaybe<Scalars['Date']>;
  limit: Scalars['Int'];
  to?: InputMaybe<Scalars['Date']>;
  type?: InputMaybe<Array<ActivityLogEnum>>;
};

export type ActivityLogStatistics = {
  __typename?: 'ActivityLogStatistics';
  ATH: Scalars['Int'];
  average: Scalars['Int'];
  today: Scalars['Int'];
  yesterday: Scalars['Int'];
};

export enum ActivityStatusEnum {
  Active = 'active',
  Inactive = 'inactive',
  NeverLoggedIn = 'never_logged_in',
  PassiveActive = 'passive_active',
  Skilled = 'skilled',
  Superuser = 'superuser',
}

export type AdFilter = {
  partnerId?: InputMaybe<Scalars['ID']>;
  placement?: InputMaybe<AdPlacement>;
  regionIds?: InputMaybe<Array<Scalars['String']>>;
  usageCategories?: InputMaybe<Array<UsageCategory>>;
};

export type AdInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  mobileImageId?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['ID']>;
  placement?: InputMaybe<AdPlacement>;
  regionIds?: InputMaybe<Array<Scalars['String']>>;
  usageCategories?: InputMaybe<Array<UsageCategory>>;
};

export enum AdPlacement {
  DashboardLandlord = 'dashboardLandlord',
  DashboardTenant = 'dashboardTenant',
  SearchRequestView = 'searchRequestView',
  Website = 'website',
  WebsiteLeaseAgreementSearch = 'websiteLeaseAgreementSearch',
  WebsiteLeaseAgreementView = 'websiteLeaseAgreementView',
}

export type AddExternalLeaseAgreementInput = {
  access: AccessEnum;
  address: RequiredAddressInput;
  areaDescription: Scalars['String'];
  contactPersonEmail: Scalars['String'];
  description: Scalars['String'];
  documents: Array<ExternalFileInput>;
  fee?: InputMaybe<FeeInput>;
  images: Array<ExternalFileInput>;
  links: Array<LeaseAgreementLinkInput>;
  nearbyServices?: InputMaybe<NearbyServicesInput>;
  size?: InputMaybe<Scalars['Int']>;
  sizeSpan?: InputMaybe<SizeSpanInput>;
  title: Scalars['String'];
  usageCategory: Array<UsageCategory>;
};

export type AddFileInput = {
  cloudinaryPublicId: Scalars['String'];
  mimetype: Scalars['String'];
  name: Scalars['String'];
};

export type AddLeaseAgreementInput = {
  access?: InputMaybe<AccessEnum>;
  address: RequiredAddressInput;
  areaDescription?: InputMaybe<Scalars['String']>;
  documentIds: Array<Scalars['String']>;
  estateId?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<FeeInput>;
  imageIds: Array<Scalars['String']>;
  landlordContactPersonIds: Array<Scalars['String']>;
  landlordDescription?: InputMaybe<Scalars['String']>;
  landlordId?: InputMaybe<Scalars['String']>;
  links: Array<LeaseAgreementLinkInput>;
  location?: InputMaybe<Scalars['geoPoint']>;
  nearbyServices?: InputMaybe<NearbyServicesInput>;
  projectId?: InputMaybe<Scalars['String']>;
  rent?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  sizeSpan?: InputMaybe<SizeSpanInput>;
  statusList?: InputMaybe<Array<Scalars['String']>>;
  tenantContactPersonIds?: InputMaybe<Array<Scalars['String']>>;
  tenantDescription?: InputMaybe<Scalars['String']>;
  tenantId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  usageCategory: Array<UsageCategory>;
  video?: InputMaybe<VideoInput>;
};

export type AddPortallyPartnerInput = {
  clientId: Scalars['String'];
  contactPerson: ContactPersonInput;
  name: Scalars['String'];
  sections: Array<InputMaybe<SectionInput>>;
  slug: Scalars['String'];
};

export type AddSubscriptionRequestInput = {
  clientId: Scalars['String'];
  version: SubscriptionVersion;
};

export type Address = {
  __typename?: 'Address';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export enum AdminSearchRequestReferenceStatus {
  ActiveConversations = 'activeConversations',
  BookedViewing = 'bookedViewing',
  ContractProposal = 'contractProposal',
  ContractSigned = 'contractSigned',
}

export type AdminStatusCount = {
  __typename?: 'AdminStatusCount';
  accepted: Scalars['Int'];
  answer_requested: Scalars['Int'];
  declined: Scalars['Int'];
  opened: Scalars['Int'];
};

export type AllProspectData = {
  __typename?: 'AllProspectData';
  averagesData?: Maybe<Array<GraphData>>;
  divisionData?: Maybe<Array<DivisionValues>>;
  overviewData?: Maybe<Array<OverviewData>>;
  processData?: Maybe<Array<GraphData>>;
  processValuesData: TotalProcessValues;
  prospectData?: Maybe<Prospects>;
  prospectStockCountData?: Maybe<Array<GraphData>>;
  prospectStockData?: Maybe<Array<GraphData>>;
  prospectStockDataOld?: Maybe<Array<GraphData>>;
  timeAverageInStepData?: Maybe<Array<TimeAverageInStepData>>;
  timeAverageToOrderData?: Maybe<TimeAverageToOrderData>;
};

export type AllProspectDataAveragesDataArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  period?: InputMaybe<Scalars['String']>;
};

export type AllProspectDataProcessDataArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  period?: InputMaybe<Scalars['String']>;
};

export type AllProspectDataProcessValuesDataArgs = {
  onlyCrossBorder?: InputMaybe<Scalars['Boolean']>;
  weightedValues?: InputMaybe<Scalars['Boolean']>;
  withCrossBorder?: InputMaybe<Scalars['Boolean']>;
};

export type AllProspectDataProspectStockCountDataArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  period?: InputMaybe<Scalars['String']>;
};

export type AllProspectDataProspectStockDataArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  period?: InputMaybe<Scalars['String']>;
  weightedValues?: InputMaybe<Scalars['Boolean']>;
  withCrossBorder?: InputMaybe<Scalars['Boolean']>;
};

export type AllProspectDataProspectStockDataOldArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  period?: InputMaybe<Scalars['String']>;
  weightedValues?: InputMaybe<Scalars['Boolean']>;
};

export type AllProspectDataTimeAverageInStepDataArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type AllProspectDataTimeAverageToOrderDataArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type Analysis = {
  __typename?: 'Analysis';
  cohort: Array<CohortAnalysis>;
  searchRequests?: Maybe<SearchRequestAnalysis>;
};

export type AnalysisCohortArgs = {
  clientType?: InputMaybe<ClientType>;
};

export type AnalysisSearchRequestsArgs = {
  filter: SearchRequestFilter;
};

export type ApiCredentials = {
  __typename?: 'ApiCredentials';
  key: Scalars['String'];
  providerId: Scalars['String'];
};

export type Attachment = {
  __typename?: 'Attachment';
  attachment?: Maybe<File>;
  attachmentId?: Maybe<Scalars['String']>;
  conversation?: Maybe<Conversation>;
  conversationId: Scalars['String'];
  created?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type AttachmentData = {
  __typename?: 'AttachmentData';
  attachments: Array<Attachment>;
  count?: Maybe<Scalars['Int']>;
};

export type AttachmentInput = {
  attachmentId: Scalars['String'];
  conversationId: Scalars['String'];
};

export type AutoCompleteClient = {
  __typename?: 'AutoCompleteClient';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type AutoCompleteUser = {
  __typename?: 'AutoCompleteUser';
  client: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type AutocompleteRegions = {
  __typename?: 'AutocompleteRegions';
  id: Scalars['String'];
  type: Scalars['String'];
  urlSlug?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type AutocompleteResult = {
  __typename?: 'AutocompleteResult';
  id: Scalars['String'];
  value: Scalars['String'];
};

export enum Background {
  Auto = 'auto',
}

export type BillingAddress = {
  __typename?: 'BillingAddress';
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type BillingAdress = {
  __typename?: 'BillingAdress';
  city: Scalars['String'];
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  zipCode: Scalars['String'];
};

export type Bounds = {
  east: Scalars['Float'];
  north: Scalars['Float'];
  south: Scalars['Float'];
  west: Scalars['Float'];
};

export type CategorizedPromotedData = {
  __typename?: 'CategorizedPromotedData';
  accepted?: Maybe<PromotedData>;
  declined?: Maybe<PromotedData>;
  unanswered?: Maybe<PromotedData>;
};

export type CategorizedPromotedDataAcceptedArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type CategorizedPromotedDataDeclinedArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type CategorizedPromotedDataUnansweredArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type Changelog = {
  __typename?: 'Changelog';
  clientId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  leaseAgreementId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChangelogFilter = {
  clientId?: InputMaybe<Scalars['String']>;
  leaseAgreementId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ChangelogInput = {
  clientId?: InputMaybe<Scalars['String']>;
  leaseAgreementId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type Client = {
  __typename?: 'Client';
  address?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  brandCategory?: Maybe<Scalars['String']>;
  brandSubCategory?: Maybe<Array<Scalars['String']>>;
  /** @deprecated Not used anymore */
  cancelableTime?: Maybe<Scalars['Int']>;
  /** @deprecated Not used anymore */
  clientManager?: Maybe<Scalars['String']>;
  /** @deprecated Not used anymore */
  collaborationPartners?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Contact>>;
  conversationStatistics: ConversationStatistics;
  created?: Maybe<Scalars['String']>;
  currentPromotions?: Maybe<ActionStats>;
  currentRequests?: Maybe<ActionStats>;
  currentSaleStair?: Maybe<Scalars['String']>;
  deals: ClientDeals;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Not used anymore */
  eCommerce?: Maybe<Scalars['Boolean']>;
  /** @deprecated Not used anymore */
  eCommerceTurnover?: Maybe<Scalars['Int']>;
  ebieScore?: Maybe<EbieScore>;
  email?: Maybe<Scalars['String']>;
  estateCount?: Maybe<Scalars['Int']>;
  estimatedSize?: Maybe<Range>;
  /** @deprecated Not used anymore */
  facebook?: Maybe<Scalars['Boolean']>;
  /** @deprecated Not used anymore */
  facebookFollowers?: Maybe<Scalars['Int']>;
  gateCustomer?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Scalars['String']>;
  hasComment?: Maybe<Scalars['Boolean']>;
  hasCommission?: Maybe<Scalars['Boolean']>;
  hasContacts?: Maybe<Scalars['Boolean']>;
  hasDescription?: Maybe<Scalars['Boolean']>;
  /** @deprecated Not used anymore */
  hasSearchProfile?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  image?: Maybe<File>;
  imageId?: Maybe<Scalars['String']>;
  includeObjektvisionEmails: Array<Scalars['String']>;
  incomeGroup?: Maybe<Scalars['String']>;
  industryCategory?: Maybe<Scalars['String']>;
  industryCode?: Maybe<Scalars['String']>;
  /** @deprecated Not used anymore */
  instagram?: Maybe<Scalars['Boolean']>;
  /** @deprecated Not used anymore */
  instagramFollowers?: Maybe<Scalars['Int']>;
  invoiceDetails?: Maybe<InvoiceDetails>;
  /** @deprecated Not used anymore */
  isCustomer?: Maybe<Scalars['Boolean']>;
  isPartner?: Maybe<Scalars['Boolean']>;
  /** @deprecated Not used anymore */
  keyValues?: Maybe<Array<Maybe<Scalars['String']>>>;
  leaseAgreementCount?: Maybe<Scalars['Int']>;
  levelOfEducation?: Maybe<Scalars['String']>;
  lifeStyle?: Maybe<Scalars['String']>;
  limeApiKey?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  lokalguidenId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** @deprecated Not used anymore */
  neighbourIds?: Maybe<Array<Scalars['String']>>;
  /** @deprecated Not used anymore */
  neighbours?: Maybe<Array<Client>>;
  notifySupport?: Maybe<Scalars['Boolean']>;
  numberOfEmployees?: Maybe<Scalars['Int']>;
  numberOfUnits?: Maybe<Scalars['Int']>;
  objektvisionId?: Maybe<Scalars['String']>;
  objektvisionIds: Array<Scalars['String']>;
  /** @deprecated Not used anymore */
  operatingProfit?: Maybe<Scalars['String']>;
  orgNumber?: Maybe<Scalars['String']>;
  parentCompany?: Maybe<Scalars['String']>;
  parentCompanyOrgNumber?: Maybe<Scalars['String']>;
  /** @deprecated Not used anymore */
  physicalStore?: Maybe<Scalars['Boolean']>;
  postAddress?: Maybe<Address>;
  priceRange?: Maybe<Scalars['String']>;
  prospects?: Maybe<Array<Prospect>>;
  rating?: Maybe<Scalars['Float']>;
  responsibleConsultant?: Maybe<User>;
  responsibleConsultantId?: Maybe<Scalars['String']>;
  searchProfileDescription?: Maybe<Scalars['String']>;
  searchRequestReferences: Array<SearchRequestReference>;
  showOnWebsite?: Maybe<Scalars['Boolean']>;
  showPageOnWebsite?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  /** @deprecated Not used anymore */
  stores?: Maybe<Array<StoreQuantity>>;
  subscriptions: Array<SubscriptionRequest>;
  supportStatus?: Maybe<Scalars['String']>;
  /** @deprecated Not used anymore */
  targetGroupChild?: Maybe<Scalars['Boolean']>;
  /** @deprecated Not used anymore */
  targetGroupChildAge?: Maybe<Array<Scalars['Int']>>;
  targetGroupDescription?: Maybe<Scalars['String']>;
  /** @deprecated Not used anymore */
  targetGroupMan?: Maybe<Scalars['Boolean']>;
  /** @deprecated Not used anymore */
  targetGroupManAge?: Maybe<Array<Scalars['Int']>>;
  /** @deprecated Not used anymore */
  targetGroupWoman?: Maybe<Scalars['Boolean']>;
  /** @deprecated Not used anymore */
  targetGroupWomanAge?: Maybe<Array<Scalars['Int']>>;
  telephone?: Maybe<Scalars['String']>;
  termsOfService?: Maybe<Terms>;
  /** @deprecated Not used anymore */
  turnover?: Maybe<Scalars['Int']>;
  type?: Maybe<ClientType>;
  typeOfBusiness?: Maybe<TypeOfBusiness>;
  /** @deprecated Not used anymore */
  typesOfPhysicalStores?: Maybe<Array<Scalars['String']>>;
  /** @deprecated Not used anymore */
  typesOfProperty?: Maybe<Array<Scalars['String']>>;
  updated?: Maybe<Scalars['String']>;
  users: Array<User>;
  visitingAddress?: Maybe<Address>;
  vitecLicenceIds: Array<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  websiteDescription?: Maybe<Scalars['String']>;
  websiteImage?: Maybe<File>;
  websiteName?: Maybe<Scalars['String']>;
};

export type ClientConversationStatisticsArgs = {
  includeDeclined?: InputMaybe<Scalars['Boolean']>;
  searchRequestReferenceId?: InputMaybe<Scalars['String']>;
};

export type ClientCsvInput = {
  estateCount?: InputMaybe<Scalars['Int']>;
  leaseAgreementCount?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ClientData = {
  __typename?: 'ClientData';
  clients: Array<Client>;
  count: Scalars['Int'];
};

export type ClientDeals = {
  __typename?: 'ClientDeals';
  count?: Maybe<Scalars['Int']>;
  portallysFee?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

export type ClientFilter = {
  brandCategory?: InputMaybe<Scalars['String']>;
  brandSubCategory?: InputMaybe<Array<Scalars['String']>>;
  gateCustomer?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  priceRange?: InputMaybe<Scalars['String']>;
  turnover?: InputMaybe<RangeInput>;
  type?: InputMaybe<ClientType>;
};

export type ClientFilterInput = {
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  searchValues?: InputMaybe<ClientSearchFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ClientType>;
};

export type ClientInput = {
  address?: InputMaybe<Scalars['String']>;
  ageGroup?: InputMaybe<Scalars['String']>;
  brandCategory?: InputMaybe<Scalars['String']>;
  brandSubCategory?: InputMaybe<Array<Scalars['String']>>;
  cancelableTime?: InputMaybe<Scalars['Int']>;
  clientManager?: InputMaybe<Scalars['String']>;
  collaborationPartners?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  eCommerce?: InputMaybe<Scalars['Boolean']>;
  eCommerceTurnover?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  estimatedSize?: InputMaybe<RangeInput>;
  facebook?: InputMaybe<Scalars['Boolean']>;
  facebookFollowers?: InputMaybe<Scalars['Int']>;
  gateCustomer?: InputMaybe<Scalars['Boolean']>;
  group?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  includeObjektvisionEmails?: InputMaybe<Array<Scalars['String']>>;
  incomeGroup?: InputMaybe<Scalars['String']>;
  industryCategory?: InputMaybe<Scalars['String']>;
  industryCode?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['Boolean']>;
  instagramFollowers?: InputMaybe<Scalars['Int']>;
  invoiceDetails?: InputMaybe<InvoiceDetailsInput>;
  isCustomer?: InputMaybe<Scalars['Boolean']>;
  isPartner?: InputMaybe<Scalars['Boolean']>;
  keyValues?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  levelOfEducation?: InputMaybe<Scalars['String']>;
  lifeStyle?: InputMaybe<Scalars['String']>;
  limeApiKey?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  lokalguidenId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  neighbourIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notifySupport?: InputMaybe<Scalars['Boolean']>;
  numberOfEmployees?: InputMaybe<Scalars['Int']>;
  numberOfUnits?: InputMaybe<Scalars['Int']>;
  objektvisionId?: InputMaybe<Scalars['String']>;
  objektvisionIds?: InputMaybe<Array<Scalars['String']>>;
  operatingProfit?: InputMaybe<Scalars['String']>;
  orgNumber?: InputMaybe<Scalars['String']>;
  parentCompany?: InputMaybe<Scalars['String']>;
  parentCompanyOrgNumber?: InputMaybe<Scalars['String']>;
  physicalStore?: InputMaybe<Scalars['Boolean']>;
  postAddress?: InputMaybe<AddressInput>;
  priceRange?: InputMaybe<Scalars['String']>;
  prospects?: InputMaybe<Array<InputMaybe<ProspectInput>>>;
  responsibleConsultantId?: InputMaybe<Scalars['String']>;
  searchProfileDescription?: InputMaybe<Scalars['String']>;
  searchRequestReferenceId?: InputMaybe<Scalars['String']>;
  services?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  showOnWebsite?: InputMaybe<Scalars['Boolean']>;
  showPageOnWebsite?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  stores?: InputMaybe<Array<StoreQuantityInput>>;
  supportStatus?: InputMaybe<Scalars['String']>;
  targetGroupChild?: InputMaybe<Scalars['Boolean']>;
  targetGroupChildAge?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  targetGroupDescription?: InputMaybe<Scalars['String']>;
  targetGroupMan?: InputMaybe<Scalars['Boolean']>;
  targetGroupManAge?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  targetGroupWoman?: InputMaybe<Scalars['Boolean']>;
  targetGroupWomanAge?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  telephone?: InputMaybe<Scalars['String']>;
  turnover?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ClientType>;
  typeOfBusiness?: InputMaybe<TypeOfBusiness>;
  typesOfPhysicalStores?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  typesOfProperty?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  updated?: InputMaybe<Scalars['String']>;
  visitingAddress?: InputMaybe<AddressInput>;
  vitecLicenceIds?: InputMaybe<Array<Scalars['String']>>;
  website?: InputMaybe<Scalars['String']>;
  websiteDescription?: InputMaybe<Scalars['String']>;
  websiteImageId?: InputMaybe<Scalars['String']>;
  websiteName?: InputMaybe<Scalars['String']>;
};

export type ClientSearchFilters = {
  brandCategories?: InputMaybe<Array<Scalars['String']>>;
  clientId?: InputMaybe<Scalars['String']>;
  clientName?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Scalars['Int']>;
  estateCount?: InputMaybe<Scalars['Int']>;
  estateDataHit?: InputMaybe<Scalars['Boolean']>;
  excludeIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  geometry?: InputMaybe<Scalars['geoMultiPolygon']>;
  /** @deprecated Not used anymore */
  hasBrandCategory?: InputMaybe<Scalars['Boolean']>;
  hasComment?: InputMaybe<Scalars['Boolean']>;
  hasCommission?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Not used anymore */
  hasContacts?: InputMaybe<Scalars['Boolean']>;
  hasDescription?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Not used anymore */
  hasEstimatedSize?: InputMaybe<Scalars['Boolean']>;
  hasNoUsers?: InputMaybe<Scalars['Boolean']>;
  hasSearchProfile?: InputMaybe<Scalars['Boolean']>;
  hasUsers?: InputMaybe<Scalars['Boolean']>;
  hasVitec?: InputMaybe<Scalars['Boolean']>;
  isPartner?: InputMaybe<Scalars['Boolean']>;
  lastActiveUserDate?: InputMaybe<Scalars['Date']>;
  leaseAgreementCount?: InputMaybe<Scalars['Int']>;
  /** @deprecated Not used anymore */
  missingBrandCategory?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Not used anymore */
  missingContacts?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Not used anymore */
  missingEstimatedSize?: InputMaybe<Scalars['Boolean']>;
  notifySupport?: InputMaybe<Scalars['Boolean']>;
  regionIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  showPageOnWebsite?: InputMaybe<Scalars['Boolean']>;
  supportStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  types?: InputMaybe<Array<ClientType>>;
};

export enum ClientType {
  Consultant = 'consultant',
  Landlord = 'landlord',
  Other = 'other',
  Temporary = 'temporary',
  Tenant = 'tenant',
}

export type Cluster = {
  __typename?: 'Cluster';
  count?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  location: Scalars['geoPoint'];
};

export type CohortAnalysis = {
  __typename?: 'CohortAnalysis';
  converted?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  registered?: Maybe<Scalars['Int']>;
};

export type ColleaguesData = {
  __typename?: 'ColleaguesData';
  colleagues: Array<User>;
  count: Scalars['Int'];
};

export type Contact = {
  __typename?: 'Contact';
  client: Client;
  clientId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  emailsEnabled?: Maybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userExists?: Maybe<Scalars['Boolean']>;
};

export type ContactFilters = {
  clientId?: InputMaybe<Scalars['String']>;
};

export type ContactInformation = {
  __typename?: 'ContactInformation';
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ContactInformationInput = {
  company?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type ContactInput = {
  clientId: Scalars['String'];
  email: Scalars['String'];
  emailsEnabled?: InputMaybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ContactPerson = {
  __typename?: 'ContactPerson';
  email: Scalars['String'];
  firstName: Scalars['String'];
  image: File;
  lastName: Scalars['String'];
  professionalTitle: Scalars['String'];
};

export type ContactPersonInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  imageId: Scalars['String'];
  lastName: Scalars['String'];
  professionalTitle: Scalars['String'];
};

export type Conversation = {
  __typename?: 'Conversation';
  archived?: Maybe<Scalars['Boolean']>;
  /** @deprecated Attachments are found in messages */
  attachmentData?: Maybe<AttachmentData>;
  fromPortally?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isPartner?: Maybe<Scalars['Boolean']>;
  leaseAgreement?: Maybe<LeaseAgreement>;
  leaseAgreementId?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  opponents: Array<Client>;
  participants: Array<Client>;
  pending?: Maybe<Scalars['Boolean']>;
  searchRequest?: Maybe<SearchRequest>;
  searchRequestId?: Maybe<Scalars['String']>;
  searchRequestReference?: Maybe<SearchRequestReference>;
  searchRequestReferenceId?: Maybe<Scalars['String']>;
  unanswered?: Maybe<Scalars['Boolean']>;
  unread?: Maybe<Unread>;
};

export type ConversationData = {
  __typename?: 'ConversationData';
  conversations: Array<Maybe<Message>>;
  count?: Maybe<Scalars['Int']>;
  unread?: Maybe<Scalars['Int']>;
};

export type ConversationFilter = {
  archived?: InputMaybe<Scalars['Boolean']>;
  isPartner?: InputMaybe<Scalars['Boolean']>;
  leaseAgreementProspect?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  personalized?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<ConversationSearchFilter>;
  searchRequestFilter?: InputMaybe<ConversationSearchRequestFilter>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ConversationFilterAutocomplete = {
  __typename?: 'ConversationFilterAutocomplete';
  clients: Array<AutoCompleteClient>;
  users: Array<AutoCompleteUser>;
};

export enum ConversationFilterType {
  Client = 'client',
  User = 'user',
}

export type ConversationSearchFilter = {
  key: Scalars['String'];
  type: ConversationSearchType;
};

export type ConversationSearchRequestFilter = {
  searchRequestReferenceId: Scalars['String'];
  status?: InputMaybe<StatusEnum>;
};

export enum ConversationSearchType {
  Client = 'client',
  Message = 'message',
  User = 'user',
}

export type ConversationStatistics = {
  __typename?: 'ConversationStatistics';
  conversations: Array<Conversation>;
  count: Scalars['Int'];
  unanswered: Scalars['Int'];
};

export type ConversationStatisticsConversationsArgs = {
  limit: Scalars['Int'];
};

export type ConversionRateData = {
  __typename?: 'ConversionRateData';
  averageSent: Scalars['Float'];
  incoming: Scalars['Int'];
  outgoing: Scalars['Int'];
  removed: Scalars['Int'];
};

export type CountByRegion = {
  __typename?: 'CountByRegion';
  count: Scalars['Int'];
  region: Region;
  regionId: Scalars['String'];
};

export type CountBySize = {
  __typename?: 'CountBySize';
  count: Scalars['Int'];
  size: SizeFilter;
};

export type CountByUsageCategory = {
  __typename?: 'CountByUsageCategory';
  count: Scalars['Int'];
  usageCategory: Scalars['String'];
};

export enum CountryEnum {
  Finland = 'finland',
  Sweden = 'sweden',
}

export type CreateAccountInfo = {
  __typename?: 'CreateAccountInfo';
  client: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
};

export enum Crop {
  Fill = 'fill',
  Lpad = 'lpad',
  Thumb = 'thumb',
}

export type DateStat = {
  __typename?: 'DateStat';
  count: Scalars['Int'];
  date: Scalars['Date'];
};

export type DaysInStep = {
  __typename?: 'DaysInStep';
  bookedMeeting?: Maybe<Scalars['Int']>;
  finalNegotiation?: Maybe<Scalars['Int']>;
  heldMeeting?: Maybe<Scalars['Int']>;
  lead?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  sentOffer?: Maybe<Scalars['Int']>;
};

export type Deal = {
  __typename?: 'Deal';
  annualRent: Scalars['Int'];
  billing: Scalars['String'];
  /** @deprecated billing is used instead */
  billingAdress: BillingAdress;
  client?: Maybe<Client>;
  clientId: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  created: Scalars['Date'];
  email: Scalars['String'];
  id: Scalars['ID'];
  madeWith: Scalars['String'];
  marking?: Maybe<Scalars['String']>;
  portallyFee: Scalars['Int'];
  reference: Scalars['String'];
  subscription?: Maybe<DealSubscription>;
  treated: Scalars['Boolean'];
  user?: Maybe<User>;
  userId: Scalars['String'];
};

export type DealClient = {
  __typename?: 'DealClient';
  client: Client;
  dealsCount?: Maybe<Scalars['Int']>;
  dealsValue?: Maybe<Scalars['Int']>;
  userCount?: Maybe<Scalars['Int']>;
};

export type DealClientData = {
  __typename?: 'DealClientData';
  clients: Array<DealClient>;
  count: Scalars['Int'];
};

export type DealData = {
  __typename?: 'DealData';
  count: Scalars['Int'];
  deals: Array<Deal>;
  value: Scalars['Int'];
};

export type DealFilter = {
  clientId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  treated?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DealInput = {
  annualRent: Scalars['Int'];
  billing: Scalars['String'];
  clientId: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  company: Scalars['String'];
  email: Scalars['String'];
  madeWith: Scalars['String'];
  marking?: InputMaybe<Scalars['String']>;
  reference: Scalars['String'];
  userId: Scalars['String'];
};

export type DealSubscription = PortallySubscription & {
  __typename?: 'DealSubscription';
  type?: Maybe<SubscriptionType>;
  version: SubscriptionVersion;
};

export enum Direction {
  Down = 'DOWN',
  Up = 'UP',
}

export type DisableSearchRequestInput = {
  clientId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  contactViaPortally?: InputMaybe<Scalars['Boolean']>;
  providedClient?: InputMaybe<Scalars['String']>;
  reason: DisableSearchRequestReasonEnum;
};

export type DisableSearchRequestReason = {
  __typename?: 'DisableSearchRequestReason';
  client?: Maybe<Client>;
  comment?: Maybe<Scalars['String']>;
  contactViaPortally?: Maybe<Scalars['Boolean']>;
  providedClient?: Maybe<Scalars['String']>;
  reason: DisableSearchRequestReasonEnum;
};

export enum DisableSearchRequestReasonEnum {
  AdminNeverLoggedIn = 'adminNeverLoggedIn',
  AdminNoActivity = 'adminNoActivity',
  Dislike = 'dislike',
  Elsewhere = 'elsewhere',
  Elsewhereother = 'elsewhereother',
  Elsewhereothercontact = 'elsewhereothercontact',
  Elsewhereotherplatform = 'elsewhereotherplatform',
  Elsewhereportallycontact = 'elsewhereportallycontact',
  Elsewhererealestateagent = 'elsewhererealestateagent',
  Notrelevant = 'notrelevant',
  Stationary = 'stationary',
  Success = 'success',
}

export type DisabledSearchRequestAnalysis = {
  __typename?: 'DisabledSearchRequestAnalysis';
  adminNeverLoggedIn: Scalars['Int'];
  adminNoActivity: Scalars['Int'];
  count: Scalars['Int'];
  dislike: Scalars['Int'];
  elsewhere: ElsewhereReasons;
  notrelevant: Scalars['Int'];
  stationary: Scalars['Int'];
  success: Scalars['Int'];
};

export type DivisionValues = {
  __typename?: 'DivisionValues';
  items: Array<Maybe<Prospect>>;
  title: Scalars['String'];
};

export enum DocModel {
  PreliminarySearchRequest = 'PreliminarySearchRequest',
  SearchRequestReference = 'SearchRequestReference',
}

export type EbieScore = {
  __typename?: 'EbieScore';
  rating: Scalars['Float'];
  tier: Scalars['String'];
};

export type EditLeaseAgreementProspectInformationInput = {
  email: Scalars['String'];
  message: Scalars['String'];
};

export type EditPortallyPartnerInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  contactPerson?: InputMaybe<ContactPersonInput>;
  name?: InputMaybe<Scalars['String']>;
  sections?: InputMaybe<Array<SectionInput>>;
  slug?: InputMaybe<Scalars['String']>;
};

export type ElsewhereReasons = {
  __typename?: 'ElsewhereReasons';
  elsewhere: Scalars['Int'];
  elsewhereother: Scalars['Int'];
  elsewhereothercontact: Scalars['Int'];
  elsewhereotherplatform: Scalars['Int'];
  elsewhereportallycontact: Scalars['Int'];
  elsewhererealestateagent: Scalars['Int'];
};

export type EmailContent = {
  body: Scalars['String'];
  subject: Scalars['String'];
};

export type EmailInput = {
  message?: InputMaybe<Scalars['String']>;
  personal: Scalars['Boolean'];
};

export type EmailLog = {
  __typename?: 'EmailLog';
  body: Scalars['String'];
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  id: Scalars['ID'];
  mailWaiting?: Maybe<Scalars['Boolean']>;
  recipient: Scalars['String'];
  searchRequest?: Maybe<SearchRequest>;
  subject: Scalars['String'];
  template?: Maybe<Scalars['String']>;
};

export type EmailLogData = {
  __typename?: 'EmailLogData';
  count: Scalars['Int'];
  emailLogs: Array<EmailLog>;
};

export type EmailLogFilter = {
  delivered?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['Date']>;
  limit?: InputMaybe<Scalars['Int']>;
  mailWaiting?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  subject?: InputMaybe<Scalars['String']>;
  template?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['Date']>;
};

export type EmailSettings = {
  __typename?: 'EmailSettings';
  enable: Scalars['Boolean'];
  onIntresting: Scalars['Boolean'];
  onMatch: Scalars['Boolean'];
  onMessage: Scalars['Boolean'];
  onPromoted: Scalars['Boolean'];
  onSearchHit: Scalars['Boolean'];
  onSearchRequest: Scalars['Boolean'];
  reminders: Scalars['Boolean'];
};

export type EmailSettingsInput = {
  enable?: InputMaybe<Scalars['Boolean']>;
  onIntresting?: InputMaybe<Scalars['Boolean']>;
  onMatch?: InputMaybe<Scalars['Boolean']>;
  onMessage?: InputMaybe<Scalars['Boolean']>;
  onPromoted?: InputMaybe<Scalars['Boolean']>;
  onSearchHit?: InputMaybe<Scalars['Boolean']>;
  onSearchRequest?: InputMaybe<Scalars['Boolean']>;
  reminders?: InputMaybe<Scalars['Boolean']>;
};

export type Episode = {
  __typename?: 'Episode';
  description: Scalars['String'];
  duration?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image: Scalars['String'];
  link: Scalars['String'];
  published: Scalars['Date'];
  title: Scalars['String'];
};

export type Estate = {
  __typename?: 'Estate';
  address?: Maybe<Array<Maybe<Address>>>;
  contactPersonIds?: Maybe<Array<Scalars['String']>>;
  contactPersons?: Maybe<Array<User>>;
  estateDesignation?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  landlord?: Maybe<Client>;
  landlordId?: Maybe<Scalars['String']>;
  leaseAgreements?: Maybe<Array<Maybe<LeaseAgreement>>>;
  location?: Maybe<Scalars['geoPoint']>;
  name?: Maybe<Scalars['String']>;
};

export type EstateData = {
  __typename?: 'EstateData';
  count?: Maybe<Scalars['Int']>;
  estates?: Maybe<Array<Maybe<Estate>>>;
};

export type EstateFilter = {
  bounds?: InputMaybe<Bounds>;
  city?: InputMaybe<Scalars['String']>;
  estateDesignation?: InputMaybe<Scalars['String']>;
  landlordId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  promoted?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

export type EstateInput = {
  address?: InputMaybe<Array<InputMaybe<AddressInput>>>;
  contactPersonIds?: InputMaybe<Array<Scalars['String']>>;
  estateDesignation?: InputMaybe<Scalars['String']>;
  landlordId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  done?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  responsibleConsultant: User;
  responsibleConsultantId: Scalars['String'];
  type: Scalars['String'];
};

export type EventFilter = {
  clientId?: InputMaybe<Scalars['String']>;
  done?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['Date']>;
  missedEvents?: InputMaybe<Scalars['Boolean']>;
  responsibleConsultantId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EventType>;
};

export type EventInput = {
  clientId?: InputMaybe<Scalars['String']>;
  date: Scalars['Date'];
  description?: InputMaybe<Scalars['String']>;
  responsibleConsultantId: Scalars['String'];
  type: EventType;
};

export enum EventType {
  Action = 'action',
  Demo = 'demo',
  FirstContact = 'firstContact',
  FollowUp = 'followUp',
}

export type ExternalFileInput = {
  externalId: Scalars['String'];
  mimetype?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  source: Scalars['String'];
};

export type Fee = {
  __typename?: 'Fee';
  amount: Scalars['Int'];
  feeType: FeeType;
};

export type FeeInput = {
  amount: Scalars['Int'];
  feeType: FeeType;
};

export enum FeeType {
  Monthly = 'monthly',
  PerSqm = 'perSqm',
}

export type File = {
  __typename?: 'File';
  created: Scalars['Date'];
  encoding: Scalars['String'];
  id: Scalars['ID'];
  mimetype: Scalars['String'];
  name: Scalars['String'];
  path: Scalars['String'];
  size: Scalars['Int'];
  url: Scalars['String'];
};

export type FileUrlArgs = {
  processing?: InputMaybe<ImageProcessing>;
};

export type FileData = {
  __typename?: 'FileData';
  files?: Maybe<Array<Maybe<File>>>;
};

export type FileFilterInput = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type Forecast = {
  __typename?: 'Forecast';
  averagePerDay?: Maybe<Scalars['Float']>;
  averagePerDayNeeded?: Maybe<Scalars['Float']>;
  goal?: Maybe<Scalars['Int']>;
  willReachGoal?: Maybe<Scalars['Boolean']>;
};

export type From = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type GateGoal = {
  __typename?: 'GateGoal';
  monthly: Array<MonthGoal>;
  yearly: Yearly;
};

export type GateGoalInput = {
  expectedIncome?: InputMaybe<Scalars['Float']>;
  month?: InputMaybe<Scalars['String']>;
  producedIncome?: InputMaybe<Scalars['Float']>;
  year?: InputMaybe<Scalars['String']>;
};

export type GraphData = {
  __typename?: 'GraphData';
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export enum Gravity {
  Auto = 'auto',
  Face = 'face',
}

export type ImageProcessing = {
  background?: InputMaybe<Background>;
  crop?: InputMaybe<Crop>;
  gravity?: InputMaybe<Gravity>;
  height?: InputMaybe<Scalars['Int']>;
  quality?: InputMaybe<Quality>;
  width?: InputMaybe<Scalars['Int']>;
};

export type IntegrationProvider = {
  __typename?: 'IntegrationProvider';
  clients: Array<Client>;
  contactPersons: Array<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<File>;
  name: Scalars['String'];
};

export type IntegrationProviderData = {
  __typename?: 'IntegrationProviderData';
  count: Scalars['Int'];
  integrationProviders: Array<IntegrationProvider>;
};

export type IntegrationProviderInput = {
  clientIds: Array<Scalars['ID']>;
  contactPersons: Array<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
};

export type InvoiceDetails = {
  __typename?: 'InvoiceDetails';
  billing?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  marking?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type InvoiceDetailsInput = {
  billing?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  marking?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
};

export type LeaseAgreement = {
  __typename?: 'LeaseAgreement';
  access?: Maybe<Scalars['String']>;
  address: Address;
  areaDescription?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  conversations: Array<Maybe<Conversation>>;
  created?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  documentIds: Array<Scalars['String']>;
  documents: Array<File>;
  estate?: Maybe<Estate>;
  estateId?: Maybe<Scalars['String']>;
  exposures: LeaseAgreementExposureData;
  fee?: Maybe<Fee>;
  fromVitec: Scalars['Boolean'];
  hasInterestedClients?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  image?: Maybe<File>;
  imageIds: Array<Scalars['String']>;
  images: Array<File>;
  initiatedRentalStatus?: Maybe<Scalars['String']>;
  integrationProvider?: Maybe<IntegrationProvider>;
  /** @deprecated Does not exist anymore */
  interestedCount?: Maybe<Scalars['Int']>;
  interestedUsers: Array<User>;
  /** @deprecated Does not exist anymore */
  isPromoted?: Maybe<Scalars['Boolean']>;
  landlord?: Maybe<Client>;
  landlordContactPersonIds: Array<Scalars['String']>;
  landlordContactPersons: Array<User>;
  landlordId?: Maybe<Scalars['String']>;
  links: Array<Maybe<LeaseAgreementLink>>;
  location?: Maybe<Scalars['geoPoint']>;
  matchingSearchRequests?: Maybe<MatchingSearchRequests>;
  myStatus?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** @deprecated use nearbyServices instead */
  nearbyPlaces?: Maybe<Array<Maybe<NearbyPlaces>>>;
  nearbyServices?: Maybe<NearbyServices>;
  prioritized?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['String']>;
  rent?: Maybe<Scalars['Int']>;
  similarLeaseAgreements: SimilarLeaseAgreements;
  size?: Maybe<Scalars['Int']>;
  sizeSpan?: Maybe<SizeSpan>;
  slug?: Maybe<Scalars['String']>;
  /** @deprecated Does not exist anymore */
  stats: Stats;
  /** @deprecated Does not exist anymore */
  statusList: Array<Scalars['String']>;
  tenant?: Maybe<Client>;
  tenantContactPersonIds?: Maybe<Array<Scalars['String']>>;
  tenantContactPersons?: Maybe<Array<User>>;
  tenantId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  usageCategory?: Maybe<Array<UsageCategory>>;
  video?: Maybe<Video>;
  views: LeaseAgreementViewData;
  vitecGuid?: Maybe<Scalars['String']>;
};

export type LeaseAgreementContactInformationInput = {
  company: Scalars['String'];
  email: Scalars['String'];
  message: Scalars['String'];
  phone: Scalars['String'];
};

export type LeaseAgreementExposure = {
  __typename?: 'LeaseAgreementExposure';
  created: Scalars['Date'];
  fromWebsite?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  leaseAgreement?: Maybe<LeaseAgreement>;
  updated: Scalars['Date'];
};

export type LeaseAgreementExposureData = {
  __typename?: 'LeaseAgreementExposureData';
  count: Scalars['Int'];
  exposures: Array<LeaseAgreementExposure>;
};

export type LeaseAgreementFilter = {
  client?: InputMaybe<ClientFilter>;
  clientId?: InputMaybe<Scalars['String']>;
  geometry?: InputMaybe<Scalars['geoMultiPolygon']>;
  landlordId?: InputMaybe<Scalars['String']>;
  noEstate?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Does not exist anymore */
  onlyAvailable?: InputMaybe<Scalars['Boolean']>;
  regionIds?: InputMaybe<Array<Scalars['String']>>;
  rentType?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<RangeInput>;
  status?: InputMaybe<Scalars['String']>;
  tenantId?: InputMaybe<Scalars['String']>;
  usageCategories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LeaseAgreementInput = {
  access?: InputMaybe<AccessEnum>;
  address?: InputMaybe<AddressInput>;
  areaDescription?: InputMaybe<Scalars['String']>;
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  estateId?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<FeeInput>;
  imageIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  landlordContactPersonIds?: InputMaybe<Array<Scalars['String']>>;
  landlordDescription?: InputMaybe<Scalars['String']>;
  landlordId?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Array<LeaseAgreementLinkInput>>;
  location?: InputMaybe<Scalars['geoPoint']>;
  nearbyServices?: InputMaybe<NearbyServicesInput>;
  prioritized?: InputMaybe<Scalars['Boolean']>;
  projectId?: InputMaybe<Scalars['String']>;
  rent?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  sizeSpan?: InputMaybe<SizeSpanInput>;
  statusList?: InputMaybe<Array<Scalars['String']>>;
  tenantContactPersonIds?: InputMaybe<Array<Scalars['String']>>;
  tenantDescription?: InputMaybe<Scalars['String']>;
  tenantId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  usageCategory?: InputMaybe<Array<UsageCategory>>;
  video?: InputMaybe<VideoInput>;
};

export type LeaseAgreementLink = {
  __typename?: 'LeaseAgreementLink';
  category?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type LeaseAgreementLinkInput = {
  category?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type LeaseAgreementNumbers = {
  __typename?: 'LeaseAgreementNumbers';
  app: Scalars['Int'];
  total: Scalars['Int'];
  website: Scalars['Int'];
  websitePercentage: Scalars['Float'];
};

export type LeaseAgreementProspect = {
  __typename?: 'LeaseAgreementProspect';
  adminStatus: LeaseAgreementProspectStatus;
  company: Scalars['String'];
  created: Scalars['Date'];
  email: Scalars['String'];
  id: Scalars['ID'];
  landlord: Client;
  leaseAgreement: LeaseAgreement;
  message: Scalars['String'];
  phone: Scalars['String'];
  readBy: Array<User>;
  showContactInfo: Scalars['Boolean'];
  status: LeaseAgreementProspectStatus;
  updated: Scalars['Date'];
};

export type LeaseAgreementProspectData = {
  __typename?: 'LeaseAgreementProspectData';
  count: Scalars['Int'];
  prospects: Array<LeaseAgreementProspect>;
  unread: Scalars['Int'];
};

export type LeaseAgreementProspectFilter = {
  adminStatus?: InputMaybe<Array<LeaseAgreementProspectStatus>>;
  clientId?: InputMaybe<Scalars['String']>;
  personalized?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Array<LeaseAgreementProspectStatus>>;
};

export enum LeaseAgreementProspectStatus {
  Accepted = 'accepted',
  AcceptedAndDeclined = 'acceptedAndDeclined',
  Declined = 'declined',
  Opened = 'opened',
  Unopened = 'unopened',
}

export type LeaseAgreementSiteMap = {
  __typename?: 'LeaseAgreementSiteMap';
  id: Scalars['ID'];
  lastModified: Scalars['Date'];
  name: Scalars['String'];
  priority: Scalars['Float'];
  slug: Scalars['String'];
};

export type LeaseAgreementSiteMapResult = {
  __typename?: 'LeaseAgreementSiteMapResult';
  count: Scalars['Int'];
  items: Array<LeaseAgreementSiteMap>;
};

export type LeaseAgreementSort = {
  direction: SortDirection;
  field: LeaseAgreementSortField;
};

export enum LeaseAgreementSortField {
  Address = 'address',
  AvgSize = 'avgSize',
  Created = 'created',
  Fee = 'fee',
  Size = 'size',
}

export type LeaseAgreementStats = {
  __typename?: 'LeaseAgreementStats';
  count: Scalars['Int'];
  interestsOverTime: Array<DateStat>;
};

export type LeaseAgreementStatsInterestsOverTimeArgs = {
  from?: InputMaybe<Scalars['Date']>;
};

export type LeaseAgreementView = {
  __typename?: 'LeaseAgreementView';
  created: Scalars['Date'];
  fromWebsite?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  leaseAgreement?: Maybe<LeaseAgreement>;
  user?: Maybe<User>;
};

export type LeaseAgreementViewData = {
  __typename?: 'LeaseAgreementViewData';
  count: Scalars['Int'];
  views: Array<LeaseAgreementView>;
};

export type LeaseAgreementViewStats = {
  __typename?: 'LeaseAgreementViewStats';
  mostViewedLeaseAgreements: Array<MostViewedStats>;
  numbers: LeaseAgreementNumbers;
  overTime: Array<OverTimeStats>;
};

export type LeaseAgreementViewStatsNumbersArgs = {
  from?: InputMaybe<Scalars['Int']>;
  leaseAgreementId?: InputMaybe<Scalars['ID']>;
};

export type LeaseAgreementViewStatsOverTimeArgs = {
  from?: InputMaybe<Scalars['Int']>;
  fromWebsite?: InputMaybe<Scalars['Boolean']>;
  leaseAgreementId?: InputMaybe<Scalars['ID']>;
};

export type LeaseAgreementsResult = {
  __typename?: 'LeaseAgreementsResult';
  clients: Array<Client>;
  cluster: Array<Cluster>;
  commerce?: Maybe<Array<Maybe<Region>>>;
  count: Scalars['Int'];
  countClients: Scalars['Int'];
  items: Array<LeaseAgreement>;
  regionBounds?: Maybe<ResultBounds>;
  regionPolygons: Array<Maybe<Scalars['geoMultiPolygon']>>;
  showResults?: Maybe<Scalars['Boolean']>;
  summary: Summary;
};

export type LeaseAgreementsResultClientsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type LeaseAgreementsResultItemsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<LeaseAgreementSort>;
};

export type LinkPreview = {
  __typename?: 'LinkPreview';
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type LoginWithTokenResult = {
  __typename?: 'LoginWithTokenResult';
  searchRequestReferenceId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type LostOrderData = {
  __typename?: 'LostOrderData';
  comment?: Maybe<Scalars['String']>;
  reason?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LostOrderDataInput = {
  comment?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MatchResults = {
  __typename?: 'MatchResults';
  count: Scalars['Int'];
  items: Array<LeaseAgreement>;
};

export type MatchingSearchRequests = {
  __typename?: 'MatchingSearchRequests';
  count: Scalars['Int'];
  searchRequests: Array<SearchRequestReference>;
};

export type MatchingSearchRequestsSearchRequestsArgs = {
  leaseAgreementId: Scalars['String'];
};

export type MaxNullRangeInput = {
  max?: InputMaybe<Scalars['Int']>;
  min: Scalars['Int'];
};

export type Message = {
  __typename?: 'Message';
  attachments: Array<File>;
  conversation: Conversation;
  conversationId: Scalars['String'];
  created?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  leaseAgreements: Array<LeaseAgreement>;
  message?: Maybe<Scalars['String']>;
  reactions?: Maybe<Array<Maybe<Reaction>>>;
  read?: Maybe<Scalars['Boolean']>;
  readTimestamp?: Maybe<Scalars['Date']>;
  sender: Client;
  senderId: Scalars['String'];
  senderUser?: Maybe<User>;
  senderUserId?: Maybe<Scalars['String']>;
};

export type MessageInput = {
  attachmentIds?: InputMaybe<Array<Scalars['String']>>;
  conversationId: Scalars['String'];
  leaseAgreementIds?: InputMaybe<Array<Scalars['String']>>;
  message: Scalars['String'];
};

export type MessageStats = {
  __typename?: 'MessageStats';
  count: Scalars['Int'];
  messagesOverTime: Array<DateStat>;
};

export type MessageStatsMessagesOverTimeArgs = {
  from?: InputMaybe<Scalars['Date']>;
};

export type MessageToPartnerInput = {
  from?: InputMaybe<From>;
  message: Scalars['String'];
  slug: Scalars['String'];
};

export enum MessageType {
  OnMessage = 'onMessage',
  OnNewMessage = 'onNewMessage',
}

export enum ModalEnum {
  Dealrequest = 'dealrequest',
  None = 'none',
  Subscriptionrequest = 'subscriptionrequest',
  Terms = 'terms',
}

export type Month = {
  __typename?: 'Month';
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type MonthGoal = {
  __typename?: 'MonthGoal';
  expectedIncome?: Maybe<Scalars['Float']>;
  fromPreviousMonth?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['String']>;
  numberOfMonth?: Maybe<Scalars['Int']>;
  producedIncome?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['String']>;
};

export type MoreInformationMail = {
  from: Scalars['String'];
  id: Scalars['String'];
  message: Scalars['String'];
  subject: Scalars['String'];
  to: Scalars['String'];
};

export type MostViewedStats = {
  __typename?: 'MostViewedStats';
  _id: Scalars['String'];
  address: Address;
  count: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptSubscriptionRequest?: Maybe<SubscriptionRequest>;
  acceptTermsOfService: Scalars['String'];
  addAd: PortallyAd;
  addAttachments?: Maybe<Conversation>;
  addClient: Client;
  addContact: Contact;
  addDeal: Deal;
  addEstate: Estate;
  addEvent?: Maybe<Event>;
  addExternalLeaseAgreement: ActionTypeEnum;
  addGateGoal: Month;
  addLeaseAgreement: LeaseAgreement;
  addMessage: Message;
  addPartner: PortallyPartner;
  addPartnerSection: PortallyPartner;
  addPresetAnswer: PresetAnswer;
  addProject: Project;
  addProspect: Prospect;
  addRegion: Region;
  addRegionInformation: Region;
  addSaleClient: SaleClient;
  addSearchProfile: SearchProfile;
  addSearchRequestComment: SearchRequestComment;
  addSearchRequestCommentLandlord: SearchRequestComment;
  addSubscriptionRequest?: Maybe<SubscriptionRequest>;
  addTermsOfService?: Maybe<TermsOfService>;
  addUser: User;
  addUserMyColleagues?: Maybe<User>;
  answerSearchRequest: SearchRequest;
  apiKey: ApiCredentials;
  archiveSearchRequest: SearchRequest;
  authenticateIntegrationProvider: ProviderAuthentication;
  changeSearchRequestStatus: SearchRequestReference;
  changeStatus?: Maybe<Status>;
  changeStatusPromotion?: Maybe<Promoted>;
  clickAd: Scalars['Boolean'];
  completeTour: User;
  contactLeaseAgreement?: Maybe<Scalars['Boolean']>;
  createUserFromContact: User;
  deleteAd: PortallyAd;
  deleteAll?: Maybe<Scalars['String']>;
  deleteAllPromoted?: Maybe<Promoted>;
  deleteClient: Client;
  deleteContact: Contact;
  deleteEmailLog: EmailLog;
  deleteEstate: Estate;
  deleteEvent?: Maybe<Event>;
  deleteExternalLeaseAgreement: Scalars['Boolean'];
  deleteIntegrationProvider: IntegrationProvider;
  deleteLeaseAgreement: Scalars['String'];
  deletePartner: PortallyPartner;
  deletePresetAnswer: Scalars['Boolean'];
  deletePromoted?: Maybe<Promoted>;
  deleteProspect: Prospect;
  deleteRegion: Region;
  deleteRegionInformation: Region;
  deleteSaleClient: SaleClient;
  deleteSearchProfile: SearchProfile;
  deleteSearchRequest?: Maybe<Array<SearchRequest>>;
  deleteSearchRequestComment: SearchRequestComment;
  deleteSearchRequestCommentLandlord: SearchRequestComment;
  deleteSearchRequestReference: SearchRequestReference;
  deleteSubscriptionRequest: SubscriptionRequest;
  deleteTermsOfService?: Maybe<TermsOfService>;
  deleteUser?: Maybe<User>;
  disableInterestingPopconfirm?: Maybe<User>;
  disableLeaseAgreement: LeaseAgreement;
  disableSearchRequest?: Maybe<Scalars['Boolean']>;
  editAd: PortallyAd;
  editClient: Client;
  editContact: Contact;
  editContactPerson: SearchRequestReference;
  editDisplayResource: PortallyPartner;
  editEstate: Estate;
  editEvent?: Maybe<Event>;
  editGateGoal: Month;
  editIntegrationProvider: IntegrationProvider;
  editLeaseAgreement: LeaseAgreement;
  editLeaseAgreementProspectAdminStatus: LeaseAgreementProspect;
  editLeaseAgreementProspectInformation: LeaseAgreementProspect;
  editLeaseAgreementProspectStatus: LeaseAgreementProspect;
  editPortallyPartner: PortallyPartner;
  editProject: Project;
  editProspect: Prospect;
  editRegion: Region;
  editRegionInformation: Region;
  editSaleClient: SaleClient;
  editSearchProfile: SearchProfile;
  editSearchRequestAdmin: SearchRequestReference;
  editSearchRequestCommentLandlord: SearchRequestComment;
  editSearchRequestReference: SearchRequestReference;
  editTermsOfService?: Maybe<TermsOfService>;
  editUser?: Maybe<User>;
  file: File;
  getNewLink: Scalars['Boolean'];
  integrationProvider: IntegrationProvider;
  inviteFriendEmail?: Maybe<Scalars['String']>;
  loginUser: Scalars['String'];
  loginWithToken: LoginWithTokenResult;
  markAsAnswered: Conversation;
  moveConversation?: Maybe<Conversation>;
  newMessage?: Maybe<Message>;
  promote?: Maybe<Array<Maybe<Promoted>>>;
  reactOnMessage: Message;
  readNotification?: Maybe<Notification>;
  refresh: Scalars['String'];
  register: User;
  removeDeal: User;
  removeDealFromUser: User;
  removeDealsFromClient: Array<User>;
  removeFromAll: Array<User>;
  removePartnerSection: PortallyPartner;
  resetPassword: Scalars['String'];
  resetPasswordLink: Scalars['String'];
  searchRequestReference: SearchResult;
  sendApiKeyLink: Scalars['Boolean'];
  sendImproveSearchMail: SearchRequestReference;
  sendMessageToPartner?: Maybe<Scalars['Boolean']>;
  sendNewsMessage: Scalars['Boolean'];
  sendSearchRequest: SearchRequestReference;
  sendSearchRequestToUser: Scalars['Boolean'];
  sendWaitingEmail?: Maybe<Scalars['Boolean']>;
  sendWelcomeEmail: User;
  setArchived: Conversation;
  setDealToUser: User;
  setDealsToClient: Array<User>;
  setEventDone?: Maybe<Event>;
  setToAll: Array<User>;
  shareSearchRequest: Scalars['String'];
  signUp: Scalars['Boolean'];
  testNewsMessage: Scalars['Boolean'];
  toggleActivePartner: PortallyPartner;
  treatDeal: Deal;
  unsubscribeEmail?: Maybe<Scalars['String']>;
  updateAdminResponsibleUser: SearchRequestReference;
  updateAdminStatus: SearchRequestReference;
  updateResponsibleUser: SearchRequest;
  updateSearchRequestStep: SearchRequest;
  verifyAccount: User;
  viewAd: Scalars['Boolean'];
  viewAttachments?: Maybe<Conversation>;
  viewConversation?: Maybe<Conversation>;
  viewLeaseAgreement?: Maybe<Scalars['Boolean']>;
  viewPartner: Scalars['Boolean'];
  viewSubscriptionRequest?: Maybe<SubscriptionRequest>;
};

export type MutationAcceptSubscriptionRequestArgs = {
  id: Scalars['String'];
  type?: InputMaybe<SubscriptionType>;
};

export type MutationAddAdArgs = {
  input: AdInput;
};

export type MutationAddAttachmentsArgs = {
  attachmentIds: Array<Scalars['String']>;
  conversationId: Scalars['String'];
};

export type MutationAddClientArgs = {
  input: ClientInput;
};

export type MutationAddContactArgs = {
  input: ContactInput;
};

export type MutationAddDealArgs = {
  input: DealInput;
};

export type MutationAddEstateArgs = {
  input: EstateInput;
};

export type MutationAddEventArgs = {
  event: EventInput;
};

export type MutationAddExternalLeaseAgreementArgs = {
  clientId: Scalars['String'];
  externalId: Scalars['String'];
  leaseAgreement: AddExternalLeaseAgreementInput;
};

export type MutationAddGateGoalArgs = {
  input: GateGoalInput;
};

export type MutationAddLeaseAgreementArgs = {
  input: AddLeaseAgreementInput;
};

export type MutationAddMessageArgs = {
  input: MessageInput;
};

export type MutationAddPartnerArgs = {
  input: AddPortallyPartnerInput;
};

export type MutationAddPartnerSectionArgs = {
  sectionInput: SectionInput;
  slug: Scalars['String'];
};

export type MutationAddPresetAnswerArgs = {
  message: Scalars['String'];
  name: Scalars['String'];
};

export type MutationAddProjectArgs = {
  input: ProjectInput;
};

export type MutationAddProspectArgs = {
  input?: InputMaybe<ProspectInput>;
};

export type MutationAddRegionArgs = {
  input: RegionsInput;
};

export type MutationAddRegionInformationArgs = {
  information: RegionInformationInput;
  regionId: Scalars['String'];
};

export type MutationAddSaleClientArgs = {
  clientId?: InputMaybe<Scalars['String']>;
};

export type MutationAddSearchProfileArgs = {
  input: SearchProfileInput;
};

export type MutationAddSearchRequestCommentArgs = {
  id: Scalars['String'];
  input: SearchRequestCommentInput;
};

export type MutationAddSearchRequestCommentLandlordArgs = {
  input: SearchRequestCommentInput;
  searchRequestId: Scalars['String'];
};

export type MutationAddSubscriptionRequestArgs = {
  input: AddSubscriptionRequestInput;
};

export type MutationAddTermsOfServiceArgs = {
  input?: InputMaybe<TermsOfServiceInput>;
};

export type MutationAddUserArgs = {
  input: UserInput;
};

export type MutationAddUserMyColleaguesArgs = {
  input: UserInput;
};

export type MutationAnswerSearchRequestArgs = {
  attachmentIds?: InputMaybe<Array<Scalars['String']>>;
  leaseAgreementIds?: InputMaybe<Array<Scalars['String']>>;
  message: Scalars['String'];
  searchRequestReferenceId: Scalars['String'];
  status?: InputMaybe<StatusEnum>;
};

export type MutationApiKeyArgs = {
  token: Scalars['String'];
};

export type MutationArchiveSearchRequestArgs = {
  id: Scalars['String'];
};

export type MutationAuthenticateIntegrationProviderArgs = {
  apiKey: Scalars['String'];
  providerId: Scalars['String'];
};

export type MutationChangeSearchRequestStatusArgs = {
  id: Scalars['String'];
  status: SearchRequestStatusEnum;
};

export type MutationChangeStatusArgs = {
  input: StatusInput;
};

export type MutationChangeStatusPromotionArgs = {
  input: PromotionStatus;
};

export type MutationClickAdArgs = {
  id: Scalars['ID'];
  websiteSearchQuery?: InputMaybe<WebsiteSearchQueryInput>;
};

export type MutationCompleteTourArgs = {
  tour: Scalars['String'];
};

export type MutationContactLeaseAgreementArgs = {
  contactInformation: LeaseAgreementContactInformationInput;
  leaseAgreementId: Scalars['String'];
};

export type MutationCreateUserFromContactArgs = {
  id: Scalars['String'];
};

export type MutationDeleteAdArgs = {
  id: Scalars['String'];
};

export type MutationDeleteAllArgs = {
  input?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteAllPromotedArgs = {
  clientId: Scalars['String'];
  leaseAgreementId: Scalars['String'];
};

export type MutationDeleteClientArgs = {
  id: Scalars['String'];
};

export type MutationDeleteContactArgs = {
  id: Scalars['String'];
};

export type MutationDeleteEmailLogArgs = {
  id: Scalars['String'];
};

export type MutationDeleteEstateArgs = {
  id: Scalars['String'];
};

export type MutationDeleteEventArgs = {
  id: Scalars['String'];
};

export type MutationDeleteExternalLeaseAgreementArgs = {
  clientId: Scalars['String'];
  externalId: Scalars['String'];
};

export type MutationDeleteIntegrationProviderArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteLeaseAgreementArgs = {
  id: Scalars['String'];
};

export type MutationDeletePartnerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationDeletePresetAnswerArgs = {
  id: Scalars['String'];
};

export type MutationDeletePromotedArgs = {
  leaseAgreementId: Scalars['String'];
  selectedClient: Scalars['String'];
};

export type MutationDeleteProspectArgs = {
  id: Scalars['String'];
};

export type MutationDeleteRegionArgs = {
  id: Scalars['String'];
};

export type MutationDeleteRegionInformationArgs = {
  informationId: Scalars['String'];
};

export type MutationDeleteSaleClientArgs = {
  id: Scalars['String'];
};

export type MutationDeleteSearchProfileArgs = {
  id: Scalars['String'];
};

export type MutationDeleteSearchRequestArgs = {
  groupId: Scalars['String'];
};

export type MutationDeleteSearchRequestCommentArgs = {
  id: Scalars['String'];
};

export type MutationDeleteSearchRequestCommentLandlordArgs = {
  id: Scalars['String'];
};

export type MutationDeleteSearchRequestReferenceArgs = {
  id: Scalars['String'];
};

export type MutationDeleteSubscriptionRequestArgs = {
  id: Scalars['String'];
};

export type MutationDeleteTermsOfServiceArgs = {
  id: Scalars['String'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};

export type MutationDisableLeaseAgreementArgs = {
  blockFromIntegrationProvider?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

export type MutationDisableSearchRequestArgs = {
  reason: DisableSearchRequestInput;
  searchRequestReferenceId: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
};

export type MutationEditAdArgs = {
  id: Scalars['ID'];
  input: AdInput;
};

export type MutationEditClientArgs = {
  id: Scalars['String'];
  input: ClientInput;
};

export type MutationEditContactArgs = {
  id: Scalars['String'];
  input: ContactInput;
};

export type MutationEditContactPersonArgs = {
  searchRequestReferenceId: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationEditDisplayResourceArgs = {
  displayResourceId?: InputMaybe<Scalars['ID']>;
  slug: Scalars['String'];
};

export type MutationEditEstateArgs = {
  id: Scalars['String'];
  input: EstateInput;
};

export type MutationEditEventArgs = {
  event: EventInput;
  id: Scalars['String'];
};

export type MutationEditGateGoalArgs = {
  id: Scalars['String'];
  input: GateGoalInput;
};

export type MutationEditIntegrationProviderArgs = {
  id: Scalars['ID'];
  provider: IntegrationProviderInput;
};

export type MutationEditLeaseAgreementArgs = {
  id: Scalars['String'];
  input: LeaseAgreementInput;
};

export type MutationEditLeaseAgreementProspectAdminStatusArgs = {
  id: Scalars['String'];
  status: LeaseAgreementProspectStatus;
};

export type MutationEditLeaseAgreementProspectInformationArgs = {
  id: Scalars['ID'];
  input: EditLeaseAgreementProspectInformationInput;
};

export type MutationEditLeaseAgreementProspectStatusArgs = {
  id: Scalars['ID'];
  status: LeaseAgreementProspectStatus;
};

export type MutationEditPortallyPartnerArgs = {
  input: EditPortallyPartnerInput;
  slug: Scalars['String'];
};

export type MutationEditProjectArgs = {
  id: Scalars['ID'];
  input: ProjectInput;
};

export type MutationEditProspectArgs = {
  id?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<ProspectInput>;
};

export type MutationEditRegionArgs = {
  id: Scalars['String'];
  input: RegionsInput;
};

export type MutationEditRegionInformationArgs = {
  information: RegionInformationInput;
  informationId: Scalars['String'];
};

export type MutationEditSaleClientArgs = {
  id: Scalars['String'];
  input?: InputMaybe<SaleClientInput>;
};

export type MutationEditSearchProfileArgs = {
  id: Scalars['String'];
  input: SearchProfileInput;
};

export type MutationEditSearchRequestAdminArgs = {
  id: Scalars['String'];
  input: SearchRequestAdminInput;
};

export type MutationEditSearchRequestCommentLandlordArgs = {
  id: Scalars['String'];
  input: SearchRequestCommentInput;
};

export type MutationEditSearchRequestReferenceArgs = {
  contactInformation?: InputMaybe<ContactInformationInput>;
  id: Scalars['String'];
  information: SearchRequestInformationInput;
};

export type MutationEditTermsOfServiceArgs = {
  id?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<TermsOfServiceInput>;
};

export type MutationEditUserArgs = {
  id: Scalars['String'];
  input: UserInput;
};

export type MutationFileArgs = {
  input: AddFileInput;
};

export type MutationGetNewLinkArgs = {
  searchRequestReferenceId?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  type: AccountCreationType;
};

export type MutationIntegrationProviderArgs = {
  name: Scalars['String'];
  provider: IntegrationProviderInput;
};

export type MutationInviteFriendEmailArgs = {
  clientId: Scalars['String'];
  email: Scalars['String'];
};

export type MutationLoginUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationLoginWithTokenArgs = {
  token: Scalars['String'];
};

export type MutationMarkAsAnsweredArgs = {
  id: Scalars['String'];
};

export type MutationMoveConversationArgs = {
  conversationId: Scalars['String'];
  newParticipantId: Scalars['String'];
  oldParticipantId: Scalars['String'];
};

export type MutationNewMessageArgs = {
  input: NewMessageInput;
  messageType?: InputMaybe<MessageType>;
};

export type MutationPromoteArgs = {
  leaseAgreementId: Scalars['String'];
  selectedClients: Array<Scalars['String']>;
};

export type MutationReactOnMessageArgs = {
  input: ReactOnMessageInput;
};

export type MutationReadNotificationArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type MutationRegisterArgs = {
  input?: InputMaybe<UserInput>;
};

export type MutationRemoveDealFromUserArgs = {
  clientId: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationRemoveDealsFromClientArgs = {
  clientId: Scalars['String'];
};

export type MutationRemovePartnerSectionArgs = {
  sectionId: Scalars['ID'];
  slug: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  acceptTerms?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationResetPasswordLinkArgs = {
  email: Scalars['String'];
};

export type MutationSearchRequestReferenceArgs = {
  area: SearchRequestAreaInput;
  contactInformation?: InputMaybe<ContactInformationInput>;
  information: SearchRequestInformationInput;
};

export type MutationSendApiKeyLinkArgs = {
  email: Scalars['String'];
};

export type MutationSendImproveSearchMailArgs = {
  input: MoreInformationMail;
};

export type MutationSendMessageToPartnerArgs = {
  messageToPartnerInput: MessageToPartnerInput;
};

export type MutationSendNewsMessageArgs = {
  input: SendNewsMessageInput;
};

export type MutationSendSearchRequestArgs = {
  id: Scalars['String'];
  sendInput: SendSearchRequestInput;
};

export type MutationSendSearchRequestToUserArgs = {
  input: SendSearchRequestToUserInput;
};

export type MutationSendWaitingEmailArgs = {
  id: Scalars['String'];
  recipients: Array<ContactInput>;
};

export type MutationSendWelcomeEmailArgs = {
  userId: Scalars['String'];
};

export type MutationSetArchivedArgs = {
  archived: Scalars['Boolean'];
  id: Scalars['String'];
};

export type MutationSetDealToUserArgs = {
  clientId: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationSetDealsToClientArgs = {
  clientId: Scalars['String'];
};

export type MutationSetEventDoneArgs = {
  done?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

export type MutationSetToAllArgs = {
  filter: Scalars['String'];
};

export type MutationShareSearchRequestArgs = {
  input: ShareSearchRequestInput;
  sendEmail: Scalars['Boolean'];
};

export type MutationSignUpArgs = {
  email: Scalars['String'];
};

export type MutationTestNewsMessageArgs = {
  email: EmailContent;
  recipient: Scalars['String'];
};

export type MutationToggleActivePartnerArgs = {
  active: Scalars['Boolean'];
};

export type MutationTreatDealArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type MutationUnsubscribeEmailArgs = {
  email: Scalars['String'];
};

export type MutationUpdateAdminResponsibleUserArgs = {
  id: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateAdminStatusArgs = {
  id: Scalars['String'];
  status?: InputMaybe<AdminSearchRequestReferenceStatus>;
};

export type MutationUpdateResponsibleUserArgs = {
  id: Scalars['String'];
  responsibleUserId: Scalars['String'];
};

export type MutationUpdateSearchRequestStepArgs = {
  id: Scalars['String'];
  update: UpdateSearchRequestStepInput;
};

export type MutationVerifyAccountArgs = {
  company: Scalars['String'];
  email: Scalars['String'];
};

export type MutationViewAdArgs = {
  id: Scalars['ID'];
  websiteSearchQuery?: InputMaybe<WebsiteSearchQueryInput>;
};

export type MutationViewAttachmentsArgs = {
  conversationId: Scalars['String'];
};

export type MutationViewConversationArgs = {
  conversationId: Scalars['String'];
};

export type MutationViewLeaseAgreementArgs = {
  fromWebsite?: InputMaybe<Scalars['Boolean']>;
  leaseAgreementId: Scalars['String'];
};

export type MutationViewPartnerArgs = {
  slug: Scalars['String'];
};

export type MutationViewSubscriptionRequestArgs = {
  id: Scalars['String'];
};

export type NameContainer = {
  name?: InputMaybe<Scalars['String']>;
};

export type NearbyPlaces = {
  __typename?: 'NearbyPlaces';
  coordinates?: Maybe<Scalars['geoPoint']>;
  distance?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  vicinity?: Maybe<Scalars['String']>;
};

export type NearbyService = {
  __typename?: 'NearbyService';
  distance?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type NearbyServiceInput = {
  distance?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type NearbyServices = {
  __typename?: 'NearbyServices';
  bus_station?: Maybe<NearbyService>;
  gym?: Maybe<NearbyService>;
  parking?: Maybe<NearbyService>;
  subway_station?: Maybe<NearbyService>;
  supermarket?: Maybe<NearbyService>;
  train_station?: Maybe<NearbyService>;
};

export type NearbyServicesInput = {
  bus_station?: InputMaybe<NearbyServiceInput>;
  gym?: InputMaybe<NearbyServiceInput>;
  parking?: InputMaybe<NearbyServiceInput>;
  subway_station?: InputMaybe<NearbyServiceInput>;
  supermarket?: InputMaybe<NearbyServiceInput>;
  train_station?: InputMaybe<NearbyServiceInput>;
};

export type NewMessageInput = {
  attachmentIds?: InputMaybe<Array<Scalars['String']>>;
  leaseAgreementId?: InputMaybe<Scalars['String']>;
  leaseAgreementIds?: InputMaybe<Array<Scalars['String']>>;
  message: Scalars['String'];
  reciverId: Scalars['String'];
  searchRequestId?: InputMaybe<Scalars['String']>;
  searchRequestReferenceId?: InputMaybe<Scalars['String']>;
  senderId: Scalars['String'];
};

export type News = {
  __typename?: 'News';
  avatar: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  link: Scalars['String'];
  published: Scalars['Date'];
  title: Scalars['String'];
};

export type NewsData = {
  __typename?: 'NewsData';
  count?: Maybe<Scalars['Int']>;
  news?: Maybe<Array<Maybe<News>>>;
};

export type NewsDataNewsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type NonNullRange = {
  __typename?: 'NonNullRange';
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type NonNullRangeInput = {
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  leaseAgreementId?: Maybe<Scalars['String']>;
  link: Scalars['String'];
  message: NotificationMessage;
  read?: Maybe<Scalars['Boolean']>;
  searchRequestReferenceId?: Maybe<Scalars['String']>;
  timestamp: Scalars['Date'];
  type: Scalars['String'];
  user: User;
  userId: Scalars['String'];
};

export type NotificationData = {
  __typename?: 'NotificationData';
  count: Scalars['Int'];
  notifications: Array<Notification>;
};

export type NotificationFilter = {
  limit: Scalars['Int'];
  skip: Scalars['Int'];
};

export type NotificationMessage = {
  __typename?: 'NotificationMessage';
  en: Scalars['String'];
  sv: Scalars['String'];
};

export type OnTopSearchResult = {
  __typename?: 'OnTopSearchResult';
  bounds: ResultBounds;
  landlords: Scalars['Int'];
  regionName: Scalars['String'];
  regionPolygons: Array<Maybe<Scalars['geoMultiPolygon']>>;
  regionType: Scalars['String'];
};

export type OrderData = {
  __typename?: 'OrderData';
  blikkNumber?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  orderValue?: Maybe<Scalars['Int']>;
  reason?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderDataInput = {
  blikkNumber?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  orderValue?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type OverTimeStats = {
  __typename?: 'OverTimeStats';
  count: Scalars['Int'];
  date: Scalars['String'];
};

export type OverviewData = {
  __typename?: 'OverviewData';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type Period = {
  __typename?: 'Period';
  max?: Maybe<Scalars['Date']>;
  min?: Maybe<Scalars['Date']>;
};

export type PeriodInput = {
  max?: InputMaybe<Scalars['Date']>;
  min?: InputMaybe<Scalars['Date']>;
};

export type Podcast = {
  __typename?: 'Podcast';
  author: Scalars['String'];
  description: Scalars['String'];
  episodes?: Maybe<Array<Maybe<Episode>>>;
  id: Scalars['ID'];
  image: Scalars['String'];
  link: Scalars['String'];
  name: Scalars['String'];
  numberOfEpisodes?: Maybe<Scalars['Int']>;
};

export type PodcastEpisodesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type PortallyAd = {
  __typename?: 'PortallyAd';
  active: Scalars['Boolean'];
  clickedBy: Array<User>;
  clicks: Array<ActionCount>;
  id: Scalars['ID'];
  image: File;
  link?: Maybe<Scalars['String']>;
  mobileImage?: Maybe<File>;
  partner: PortallyPartner;
  placement: AdPlacement;
  regionIds: Array<Scalars['String']>;
  regions: Array<Region>;
  usageCategories: Array<UsageCategory>;
  viewedBy: Array<User>;
  views: Array<ActionCount>;
  websiteSearchQueryClick?: Maybe<Array<Maybe<WebsiteSearchQuery>>>;
  websiteSearchQueryView?: Maybe<Array<Maybe<WebsiteSearchQuery>>>;
};

export type PortallyPartner = {
  __typename?: 'PortallyPartner';
  active?: Maybe<Scalars['Boolean']>;
  ads?: Maybe<Array<PortallyAd>>;
  client: Client;
  clientId: Scalars['String'];
  contactPerson: ContactPerson;
  displayResource?: Maybe<File>;
  id: Scalars['ID'];
  landlordViews?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  sections?: Maybe<Array<Sections>>;
  slug: Scalars['String'];
  tenantViews?: Maybe<Scalars['Int']>;
  viewedBy: Array<User>;
  views: Array<ActionCount>;
};

export type PortallySubscription = {
  type?: Maybe<SubscriptionType>;
  version: SubscriptionVersion;
};

export type PreliminarySearchRequestStats = {
  __typename?: 'PreliminarySearchRequestStats';
  count: Scalars['Int'];
  searchRequestsOverTime: Array<DateStat>;
};

export type PreliminarySearchRequestStatsSearchRequestsOverTimeArgs = {
  from?: InputMaybe<Scalars['Date']>;
};

export type PresetAnswer = {
  __typename?: 'PresetAnswer';
  id: Scalars['ID'];
  message: Scalars['String'];
  name: Scalars['String'];
};

export type Probability = {
  all?: InputMaybe<Scalars['Int']>;
  bookedMeeting?: InputMaybe<Scalars['Int']>;
  construction?: InputMaybe<Scalars['Int']>;
  depreciated?: InputMaybe<Scalars['Int']>;
  finalNegotiation?: InputMaybe<Scalars['Int']>;
  firstMeeting?: InputMaybe<Scalars['Int']>;
  heldMeeting?: InputMaybe<Scalars['Int']>;
  lead?: InputMaybe<Scalars['Int']>;
  lostBusiness?: InputMaybe<Scalars['Int']>;
  now?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  sentOffer?: InputMaybe<Scalars['Int']>;
};

export type ProcessValuesData = {
  __typename?: 'ProcessValuesData';
  label?: Maybe<Scalars['String']>;
  percentageOfTotal?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Int']>;
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['ID'];
  landlordIds: Array<Scalars['String']>;
  landlords: Array<Client>;
  leaseAgreements: Array<LeaseAgreement>;
  name: Scalars['String'];
};

export type ProjectFilter = {
  clientIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ProjectInput = {
  landlordIds?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
};

export type Promoted = {
  __typename?: 'Promoted';
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['String']>;
  conversation?: Maybe<Conversation>;
  created: Scalars['Date'];
  dueDate: Scalars['Date'];
  id: Scalars['ID'];
  image?: Maybe<File>;
  leaseAgreement: LeaseAgreement;
  leaseAgreementId: Scalars['String'];
  opened: Scalars['Boolean'];
  owner?: Maybe<Client>;
  ownerId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type PromotedData = {
  __typename?: 'PromotedData';
  count?: Maybe<Scalars['Int']>;
  promoted?: Maybe<Array<Maybe<Promoted>>>;
};

export type PromotedStat = {
  __typename?: 'PromotedStat';
  accepted: Scalars['Int'];
  declined: Scalars['Int'];
  opened: Scalars['Int'];
};

export type PromotedStats = {
  __typename?: 'PromotedStats';
  count: Scalars['Int'];
  promotionsOverTime: Array<DateStat>;
};

export type PromotedStatsPromotionsOverTimeArgs = {
  from?: InputMaybe<Scalars['Date']>;
};

export type Promotion = {
  __typename?: 'Promotion';
  leaseAgreement: LeaseAgreement;
  leaseAgreementId: Scalars['String'];
  opened: Scalars['Int'];
  reach: Scalars['Int'];
  status?: Maybe<Scalars['String']>;
};

export type PromotionSearchValues = {
  brandCategories?: InputMaybe<Array<Scalars['String']>>;
  clientName?: InputMaybe<Scalars['String']>;
  excludeIds?: InputMaybe<Array<Scalars['String']>>;
  leaseAgreementId?: InputMaybe<Scalars['String']>;
};

export type PromotionStatus = {
  clientId: Scalars['String'];
  leaseAgreementId: Scalars['String'];
  status: Scalars['String'];
};

export type Prospect = {
  __typename?: 'Prospect';
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['String']>;
  clientManager?: Maybe<User>;
  clientManagerId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Date']>;
  crossBorder?: Maybe<Scalars['Boolean']>;
  daysInCurrentStep?: Maybe<Scalars['Int']>;
  division?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lostOrderData?: Maybe<LostOrderData>;
  name?: Maybe<Scalars['String']>;
  orderData?: Maybe<OrderData>;
  potentialValue?: Maybe<Scalars['Int']>;
  service?: Maybe<Scalars['String']>;
  source?: Maybe<Array<Scalars['String']>>;
  sourceComment?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  step?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Date']>;
  weightedValue?: Maybe<Scalars['Int']>;
};

export type ProspectCsvInput = {
  client: Scalars['String'];
  clientManager: Scalars['String'];
  division: Scalars['String'];
  name: Scalars['String'];
  potentialValue: Scalars['Int'];
  source: Array<Scalars['String']>;
  sourceComment?: InputMaybe<Scalars['String']>;
  startDate: Scalars['String'];
  step?: InputMaybe<Scalars['String']>;
  translatedStep?: InputMaybe<Scalars['String']>;
};

export type ProspectFilterInput = {
  clientId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  step?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ProspectInput = {
  clientId?: InputMaybe<Scalars['String']>;
  clientManagerId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  crossBorder?: InputMaybe<Scalars['Boolean']>;
  division?: InputMaybe<Scalars['String']>;
  lostOrderData?: InputMaybe<LostOrderDataInput>;
  name?: InputMaybe<Scalars['String']>;
  orderData?: InputMaybe<OrderDataInput>;
  potentialValue?: InputMaybe<Scalars['Int']>;
  probability?: InputMaybe<Scalars['Int']>;
  service?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Array<Scalars['String']>>;
  sourceComment?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  step?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type Prospects = {
  __typename?: 'Prospects';
  count: Scalars['Int'];
  prospects: Array<Prospect>;
};

export type ProviderAuthentication = {
  __typename?: 'ProviderAuthentication';
  clients: Array<ProviderClient>;
  token: Scalars['String'];
};

export type ProviderClient = {
  __typename?: 'ProviderClient';
  id: Scalars['String'];
  name: Scalars['String'];
};

export enum Quality {
  Auto = 'auto',
  Max = 'max',
}

export enum Quarter {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
}

export type Query = {
  __typename?: 'Query';
  activityData: ActivityData;
  analysis?: Maybe<Analysis>;
  changelog?: Maybe<Changelog>;
  changelogs?: Maybe<Array<Maybe<Changelog>>>;
  checkToken: TokenInfo;
  client: Client;
  clientAutocomplete?: Maybe<Array<AutocompleteResult>>;
  clientBySlug: Client;
  clients: ClientData;
  contact: Contact;
  contacts: Array<Contact>;
  conversation?: Maybe<Conversation>;
  conversationFilterAutocomplete: ConversationFilterAutocomplete;
  conversations?: Maybe<ConversationData>;
  conversionRate: ConversionRateData;
  dailyReports: Array<Report>;
  deal: Deal;
  dealClients: DealClientData;
  deals: DealData;
  emailLog: EmailLog;
  emailLogs: EmailLogData;
  estate: Estate;
  estateAutocomplete?: Maybe<Array<AutocompleteResult>>;
  estates: EstateData;
  events?: Maybe<Array<Maybe<Event>>>;
  fetchPlaces: Array<AutocompleteResult>;
  files?: Maybe<FileData>;
  gateGoals?: Maybe<GateGoal>;
  generateNearbyServices: NearbyServices;
  getClientCsv?: Maybe<Scalars['String']>;
  getCsv?: Maybe<Scalars['String']>;
  getCurrentTermsOfService?: Maybe<TermsOfService>;
  getPartnerStats?: Maybe<Scalars['String']>;
  getProspectCsv?: Maybe<Scalars['String']>;
  getRegion: Region;
  getRegions: RegionData;
  getSearchResultWebsite: OnTopSearchResult;
  getSearchSiteMapWebsite: Array<SiteMapResult>;
  getSiteMapWebsite: SiteMapResult;
  getSpaceSiteMapWebsite: Array<SiteMapResult>;
  goals: SubscriptionGoals;
  integrationProviderByToken: Scalars['String'];
  integrationProviders: IntegrationProviderData;
  leaseAgreement: LeaseAgreement;
  leaseAgreementBySlug: LeaseAgreement;
  leaseAgreementProspect: LeaseAgreementProspect;
  leaseAgreementProspects: LeaseAgreementProspectData;
  leaseAgreements: LeaseAgreementsResult;
  leaseAgreementsByIds: Array<LeaseAgreement>;
  leaseAgreementsSitemap: LeaseAgreementSiteMapResult;
  linkPreview?: Maybe<LinkPreview>;
  me?: Maybe<User>;
  mostPopular: Array<LeaseAgreement>;
  myActiveSearchRequests: ActiveSearchRequestData;
  myColleagues: ColleaguesData;
  myDeals: Array<Deal>;
  myEstates?: Maybe<Array<Estate>>;
  myOffers?: Maybe<CategorizedPromotedData>;
  myPresetAnswers: Array<PresetAnswer>;
  myProjects: Array<Project>;
  myPromotions?: Maybe<Array<Maybe<Promotion>>>;
  myRating?: Maybe<Scalars['Float']>;
  mySearchProfiles: Array<SearchProfile>;
  mySearchRequestByReferenceId?: Maybe<SearchRequest>;
  mySearchRequestReferences: SearchRequestReferenceData;
  mySearchRequestsLandlord?: Maybe<SearchRequestLandlordData>;
  mySubscriptionRequest?: Maybe<SubscriptionRequest>;
  news?: Maybe<NewsData>;
  notifications: NotificationData;
  onDisplay: Array<LeaseAgreement>;
  partners: Array<Client>;
  podcast?: Maybe<Podcast>;
  podcasts?: Maybe<Array<Maybe<Podcast>>>;
  portallyAd?: Maybe<PortallyAd>;
  portallyAds: Array<PortallyAd>;
  portallyPartner: PortallyPartner;
  portallyPartners: Array<PortallyPartner>;
  potentialClients: ClientData;
  project: Project;
  projects: Array<Project>;
  promoted?: Maybe<PromotedData>;
  promotionClients: ClientData;
  prospect: Prospect;
  prospects: AllProspectData;
  reasonForLostOrderData: Array<Maybe<Reasons>>;
  reasonForOrderData: Array<Maybe<Reasons>>;
  receivers: SearchRequestReceiverData;
  regionByUrl?: Maybe<Region>;
  regions: Array<AutocompleteRegions>;
  regionsAndStreets: Array<AutocompleteRegions>;
  saleClient: SaleClient;
  saleClients: SaleClients;
  searchProfile: SearchProfile;
  searchProfileCount: SearchProfileCount;
  searchProfiles: SearchProfileData;
  searchRequestComments: Array<SearchRequestComment>;
  searchRequestCommentsLandlord: Array<SearchRequestComment>;
  searchRequestReference: SearchRequestReference;
  searchRequestReferenceByToken: SearchRequestReference;
  searchRequestStatistics: SearchRequestAdminStatistics;
  searchRequestsAdmin: SearchRequestAdminData;
  showcase: Array<Client>;
  singlePromoted?: Maybe<Promoted>;
  /** @deprecated Use searchRequestReference(id: String!) instead */
  singleSearchRequest?: Maybe<SearchRequest>;
  singleStatus?: Maybe<Status>;
  status?: Maybe<Array<Maybe<Status>>>;
  statusUpdates: StatusUpdateData;
  streets: Array<AutocompleteRegions>;
  subscriptionRequests: Array<SubscriptionRequest>;
  systemStats: SystemStatistics;
  termsOfService?: Maybe<TermsOfService>;
  termsOfServices?: Maybe<TermsOfServiceData>;
  user?: Maybe<User>;
  userAutocomplete: Array<AutocompleteResult>;
  users: UserData;
  viewStats: LeaseAgreementViewStats;
  views: LeaseAgreementViewData;
  websiteClients: Array<Client>;
  weeklyReports: Array<Maybe<Report>>;
};

export type QueryActivityDataArgs = {
  filter: ActivityLogFilterInput;
};

export type QueryChangelogArgs = {
  id: Scalars['String'];
};

export type QueryChangelogsArgs = {
  filter?: InputMaybe<ChangelogFilter>;
};

export type QueryCheckTokenArgs = {
  token: Scalars['String'];
  type: AccountCreationType;
};

export type QueryClientArgs = {
  id: Scalars['String'];
};

export type QueryClientAutocompleteArgs = {
  clientType?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  searchRequest?: InputMaybe<Scalars['Boolean']>;
};

export type QueryClientBySlugArgs = {
  slug: Scalars['String'];
};

export type QueryClientsArgs = {
  filter?: InputMaybe<ClientFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type QueryContactArgs = {
  id: Scalars['String'];
};

export type QueryContactsArgs = {
  filter?: InputMaybe<ContactFilters>;
};

export type QueryConversationArgs = {
  id: Scalars['String'];
};

export type QueryConversationFilterAutocompleteArgs = {
  key?: InputMaybe<Scalars['String']>;
};

export type QueryConversationsArgs = {
  filter?: InputMaybe<ConversationFilter>;
};

export type QueryConversionRateArgs = {
  from?: InputMaybe<Scalars['Date']>;
  to?: InputMaybe<Scalars['Date']>;
};

export type QueryDailyReportsArgs = {
  limit: Scalars['Int'];
};

export type QueryDealArgs = {
  id: Scalars['String'];
};

export type QueryDealsArgs = {
  filter: DealFilter;
};

export type QueryEmailLogArgs = {
  id: Scalars['String'];
};

export type QueryEmailLogsArgs = {
  filter: EmailLogFilter;
};

export type QueryEstateArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type QueryEstateAutocompleteArgs = {
  key: Scalars['String'];
};

export type QueryEstatesArgs = {
  filter?: InputMaybe<EstateFilter>;
  personalized?: InputMaybe<Scalars['Boolean']>;
};

export type QueryEventsArgs = {
  filter?: InputMaybe<EventFilter>;
};

export type QueryFetchPlacesArgs = {
  key: Scalars['String'];
};

export type QueryFilesArgs = {
  filters?: InputMaybe<FileFilterInput>;
};

export type QueryGenerateNearbyServicesArgs = {
  address: AddressInput;
};

export type QueryGetClientCsvArgs = {
  filter?: InputMaybe<ClientFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type QueryGetCsvArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPartnerStatsArgs = {
  endDate: Scalars['Date'];
  partnerSlug: Scalars['String'];
  startDate: Scalars['Date'];
};

export type QueryGetProspectCsvArgs = {
  probability: Probability;
  prospects: Array<ProspectCsvInput>;
};

export type QueryGetRegionArgs = {
  id: Scalars['String'];
};

export type QueryGetRegionsArgs = {
  filter?: InputMaybe<RegionFilter>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryGetSearchResultWebsiteArgs = {
  regionIds: Array<Scalars['String']>;
};

export type QueryGetSpaceSiteMapWebsiteArgs = {
  usageCategory?: InputMaybe<UsageCategory>;
};

export type QueryIntegrationProviderByTokenArgs = {
  token: Scalars['String'];
};

export type QueryLeaseAgreementArgs = {
  id: Scalars['String'];
};

export type QueryLeaseAgreementBySlugArgs = {
  slug: Scalars['String'];
};

export type QueryLeaseAgreementProspectArgs = {
  id: Scalars['ID'];
};

export type QueryLeaseAgreementProspectsArgs = {
  filter?: InputMaybe<LeaseAgreementProspectFilter>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryLeaseAgreementsArgs = {
  activeClientId?: InputMaybe<Scalars['String']>;
  bounds?: InputMaybe<Bounds>;
  cluster?: InputMaybe<Scalars['Boolean']>;
  input?: InputMaybe<LeaseAgreementFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personalized?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<SearchProfileInput>;
  zoom?: InputMaybe<Scalars['Int']>;
};

export type QueryLeaseAgreementsByIdsArgs = {
  ids: Array<Scalars['String']>;
};

export type QueryLinkPreviewArgs = {
  url: Scalars['String'];
};

export type QueryMostPopularArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryMyActiveSearchRequestsArgs = {
  filter: ActiveSearchRequestFilter;
};

export type QueryMyColleaguesArgs = {
  clientId: Scalars['String'];
};

export type QueryMySearchProfilesArgs = {
  clientId?: InputMaybe<Scalars['String']>;
};

export type QueryMySearchRequestByReferenceIdArgs = {
  id: Scalars['String'];
};

export type QueryMySearchRequestReferencesArgs = {
  limit: Scalars['Int'];
};

export type QueryMySearchRequestsLandlordArgs = {
  regionIds: Array<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
  size: Array<SizeFilter>;
  sort?: InputMaybe<Sort>;
  usageCategories: Array<Scalars['String']>;
};

export type QueryNotificationsArgs = {
  filter: NotificationFilter;
};

export type QueryPodcastArgs = {
  id: Scalars['String'];
};

export type QueryPortallyAdArgs = {
  filter: AdFilter;
};

export type QueryPortallyAdsArgs = {
  slug?: InputMaybe<Scalars['String']>;
};

export type QueryPortallyPartnerArgs = {
  slug: Scalars['String'];
};

export type QueryPotentialClientsArgs = {
  clientId: Scalars['String'];
  searchRequestReferenceId?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type QueryProjectArgs = {
  id: Scalars['ID'];
};

export type QueryProjectsArgs = {
  filter?: InputMaybe<ProjectFilter>;
};

export type QueryPromotedArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  leaseAgreementId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  ownerId?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

export type QueryPromotionClientsArgs = {
  searchValues: PromotionSearchValues;
};

export type QueryProspectArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type QueryProspectsArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  clientManagerId?: InputMaybe<Array<Scalars['String']>>;
  division?: InputMaybe<Array<Scalars['String']>>;
  endDate?: InputMaybe<Scalars['Date']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Array<Scalars['String']>>;
  startDate?: InputMaybe<Scalars['Date']>;
  step?: InputMaybe<Array<Step>>;
};

export type QueryReceiversArgs = {
  id: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<StatusEnum>>;
};

export type QueryRegionByUrlArgs = {
  urlSlug: Scalars['String'];
};

export type QueryRegionsArgs = {
  country?: InputMaybe<CountryEnum>;
  key: Scalars['String'];
};

export type QueryRegionsAndStreetsArgs = {
  country?: InputMaybe<CountryEnum>;
  key: Scalars['String'];
};

export type QuerySaleClientArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type QuerySaleClientsArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  clientManagerId?: InputMaybe<Scalars['String']>;
  fetchAwaiting?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  oneOfHundred?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  stair?: InputMaybe<Scalars['String']>;
  subscription?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};

export type QuerySearchProfileArgs = {
  id: Scalars['String'];
};

export type QuerySearchProfilesArgs = {
  filter: SearchProfileFilterInput;
};

export type QuerySearchRequestCommentsArgs = {
  id: Scalars['String'];
};

export type QuerySearchRequestCommentsLandlordArgs = {
  searchRequestId: Scalars['String'];
};

export type QuerySearchRequestReferenceArgs = {
  id: Scalars['String'];
  triggerView?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySearchRequestReferenceByTokenArgs = {
  searchRequestReferenceId: Scalars['String'];
  token: Scalars['String'];
};

export type QuerySearchRequestStatisticsArgs = {
  filter: SearchRequestAdminFilter;
};

export type QuerySearchRequestsAdminArgs = {
  filter: SearchRequestAdminFilter;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  sort?: InputMaybe<Sort>;
};

export type QuerySinglePromotedArgs = {
  clientId: Scalars['String'];
  leaseAgreementId: Scalars['String'];
  ownerId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type QuerySingleSearchRequestArgs = {
  id: Scalars['String'];
};

export type QuerySingleStatusArgs = {
  leaseAgreementId: Scalars['String'];
};

export type QueryStatusArgs = {
  leaseAgreementId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type QueryStatusUpdatesArgs = {
  direction?: InputMaybe<Direction>;
  limit: Scalars['Int'];
};

export type QueryStreetsArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
};

export type QuerySubscriptionRequestsArgs = {
  filter: SubscriptionFilter;
};

export type QueryTermsOfServiceArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type QueryUserArgs = {
  id: Scalars['String'];
};

export type QueryUserAutocompleteArgs = {
  key: Scalars['String'];
};

export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type QueryViewsArgs = {
  limit: Scalars['Int'];
};

export type Range = {
  __typename?: 'Range';
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

export type RangeInput = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type ReactOnMessageInput = {
  messageId: Scalars['String'];
  reaction: ReactionEnum;
};

export type Reaction = {
  __typename?: 'Reaction';
  reaction: ReactionEnum;
  user: User;
  userId: Scalars['String'];
};

export enum ReactionEnum {
  Heart = 'heart',
  Like = 'like',
  Sad = 'sad',
  Smile = 'smile',
  Surprised = 'surprised',
}

export type Reasons = {
  __typename?: 'Reasons';
  label?: Maybe<Scalars['String']>;
  percentageOfTotal?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Int']>;
};

export type ReceivedEmailsFilter = {
  onlySearchRequest?: InputMaybe<Scalars['Boolean']>;
};

export type Region = {
  __typename?: 'Region';
  bounds?: Maybe<RegionBounds>;
  center?: Maybe<Scalars['geoPoint']>;
  children: Array<Region>;
  country?: Maybe<CountryEnum>;
  filter?: Maybe<Scalars['Boolean']>;
  geometry?: Maybe<Scalars['geoMultiPolygon']>;
  id: Scalars['String'];
  information: Array<RegionInformation>;
  lastModified?: Maybe<Scalars['Date']>;
  level?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  parents: Array<Region>;
  prioritized?: Maybe<Scalars['Boolean']>;
  regionType?: Maybe<RegionTypeEnum>;
  urlSlug: Scalars['String'];
};

export type RegionBounds = {
  __typename?: 'RegionBounds';
  east: Scalars['Float'];
  north: Scalars['Float'];
  south: Scalars['Float'];
  west: Scalars['Float'];
};

export type RegionData = {
  __typename?: 'RegionData';
  bounds?: Maybe<RegionBounds>;
  count: Scalars['Int'];
  regions: Array<Region>;
};

export type RegionFilter = {
  childrenRegionTypes?: InputMaybe<Array<InputMaybe<RegionTypeEnum>>>;
  country?: InputMaybe<CountryEnum>;
  regionType?: InputMaybe<RegionTypeEnum>;
  searchParameter?: InputMaybe<Scalars['String']>;
};

export type RegionInformation = {
  __typename?: 'RegionInformation';
  description: Scalars['String'];
  id: Scalars['String'];
  image?: Maybe<File>;
  imageId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  usageCategories: Array<UsageCategory>;
};

export type RegionInformationInput = {
  description: Scalars['String'];
  imageId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  usageCategories: Array<UsageCategory>;
};

export enum RegionTypeEnum {
  Commerce = 'commerce',
  County = 'county',
  District = 'district',
  DistrictArea = 'district_area',
  Municipality = 'municipality',
  MyRegions = 'my_regions',
  Other = 'other',
  Street = 'street',
}

export type RegionsInput = {
  country: CountryEnum;
  filter: Scalars['Boolean'];
  geometry: Scalars['geoMultiPolygon'];
  name: Scalars['String'];
  parentIds: Array<Scalars['String']>;
  prioritized: Scalars['Boolean'];
  regionType: RegionTypeEnum;
};

export type Report = {
  __typename?: 'Report';
  date: Scalars['Date'];
  id: Scalars['ID'];
  statistics: Statistics;
  type: Scalars['String'];
  week: Scalars['String'];
  year: Scalars['Date'];
};

export type RequiredAddressInput = {
  city: Scalars['String'];
  street: Scalars['String'];
  streetNumber?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type ResultBounds = {
  __typename?: 'ResultBounds';
  east: Scalars['Float'];
  north: Scalars['Float'];
  south: Scalars['Float'];
  west: Scalars['Float'];
};

export enum Role {
  Admin = 'admin',
  Any = 'any',
  Client = 'client',
  Consultant = 'consultant',
  Landlord = 'landlord',
  Owner = 'owner',
  Tenant = 'tenant',
}

export type SaleClient = {
  __typename?: 'SaleClient';
  client?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientManager?: Maybe<Scalars['String']>;
  clientManagerId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Date']>;
  daysLeftSubscription?: Maybe<Scalars['Int']>;
  daysToNextPayment?: Maybe<Scalars['Int']>;
  files?: Maybe<Array<Maybe<File>>>;
  generatedIncome?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  inStairSince?: Maybe<Scalars['Date']>;
  income?: Maybe<Scalars['Int']>;
  nextPayment?: Maybe<Scalars['Date']>;
  oneOfHundred?: Maybe<Scalars['Boolean']>;
  stair?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionEnd?: Maybe<Scalars['Date']>;
  subscriptionStart?: Maybe<Scalars['Date']>;
  tag?: Maybe<Scalars['String']>;
};

export type SaleClientFilterInput = {
  clientId?: InputMaybe<Scalars['String']>;
  clientManagerId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  stair?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};

export type SaleClientInput = {
  clientManagerId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  income?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nextPayment?: InputMaybe<Scalars['Date']>;
  oneOfHundred?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  stair?: InputMaybe<Scalars['String']>;
  subscription?: InputMaybe<Scalars['String']>;
  subscriptionEnd?: InputMaybe<Scalars['Date']>;
  subscriptionStart?: InputMaybe<Scalars['Date']>;
  tag?: InputMaybe<Scalars['String']>;
};

export type SaleClients = {
  __typename?: 'SaleClients';
  clients: Array<SaleClient>;
  count: Scalars['Int'];
  dollarCount: Scalars['Int'];
  totalIncome?: Maybe<Scalars['Int']>;
};

export type SearchFloor = {
  __typename?: 'SearchFloor';
  floorNumber: Scalars['Int'];
  size: Range;
  usageCategory?: Maybe<Scalars['String']>;
};

export type SearchFloorInput = {
  floorNumber: Scalars['Int'];
  size?: InputMaybe<RangeInput>;
  usageCategory?: InputMaybe<Scalars['String']>;
};

export type SearchParameters = {
  __typename?: 'SearchParameters';
  bounds?: Maybe<RegionBounds>;
  geometry?: Maybe<Scalars['geoMultiPolygon']>;
  regionIds?: Maybe<Array<Scalars['String']>>;
  regions: Array<Region>;
  size?: Maybe<Range>;
  usageCategories: Array<Scalars['String']>;
};

export type SearchProfile = {
  __typename?: 'SearchProfile';
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['String']>;
  cluster: Array<Cluster>;
  concealed?: Maybe<Scalars['Boolean']>;
  floors?: Maybe<Array<SearchFloor>>;
  geometry?: Maybe<Scalars['geoMultiPolygon']>;
  id: Scalars['ID'];
  matches: MatchResults;
  name: Scalars['String'];
  period?: Maybe<Period>;
  regionIds?: Maybe<Array<Scalars['String']>>;
  regions?: Maybe<Array<Region>>;
  rentType?: Maybe<Scalars['String']>;
  sendToLandlords?: Maybe<Scalars['Boolean']>;
  size?: Maybe<Range>;
  standardAgreement?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Array<Scalars['String']>>;
  tooManyHits?: Maybe<Scalars['Boolean']>;
  usageCategories?: Maybe<Array<Scalars['String']>>;
};

export type SearchProfileClusterArgs = {
  skipStatus?: InputMaybe<Scalars['Boolean']>;
  zoom: Scalars['Int'];
};

export type SearchProfileMatchesArgs = {
  bounds?: InputMaybe<Bounds>;
  offset?: InputMaybe<Scalars['Int']>;
  skipStatus?: InputMaybe<Scalars['Boolean']>;
};

export type SearchProfileCount = {
  __typename?: 'SearchProfileCount';
  count?: Maybe<Scalars['Int']>;
};

export type SearchProfileData = {
  __typename?: 'SearchProfileData';
  count: Scalars['Int'];
  searchProfiles: Array<SearchProfile>;
};

export type SearchProfileFilterInput = {
  clientId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type SearchProfileInput = {
  clientId?: InputMaybe<Scalars['String']>;
  concealed?: InputMaybe<Scalars['Boolean']>;
  geometry?: InputMaybe<Scalars['geoMultiPolygon']>;
  hasInterestedClients?: InputMaybe<Scalars['Boolean']>;
  hasNoContactPerson?: InputMaybe<Scalars['Boolean']>;
  landlordId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  noticeOfTermination?: InputMaybe<Scalars['Boolean']>;
  onlyAvailable?: InputMaybe<Scalars['Boolean']>;
  onlyNotAvailable?: InputMaybe<Scalars['Boolean']>;
  onlyPrioritized?: InputMaybe<Scalars['Boolean']>;
  onlyVitec?: InputMaybe<Scalars['Boolean']>;
  regionIds?: InputMaybe<Array<Scalars['String']>>;
  rentType?: InputMaybe<Scalars['String']>;
  sendToLandlords?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<RangeInput>;
  standardAgreement?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Array<Scalars['String']>>;
  usageCategories?: InputMaybe<Array<Scalars['String']>>;
};

export type SearchRequest = SearchRequestInformation & {
  __typename?: 'SearchRequest';
  /** @deprecated Use search request reference for information */
  access?: Maybe<Access>;
  /** @deprecated Use access */
  accessEarliest?: Maybe<Scalars['Date']>;
  /** @deprecated Use access */
  accessLatest?: Maybe<Scalars['Date']>;
  /** @deprecated Use search request reference for information */
  accessV2?: Maybe<AccessEnumV2>;
  /** @deprecated Use description instead */
  additionalInformation?: Maybe<Scalars['String']>;
  answeredDate?: Maybe<Scalars['Date']>;
  /** @deprecated Use search request reference for information */
  areaInformation?: Maybe<Scalars['String']>;
  /** @deprecated Use search request reference for information */
  budget?: Maybe<Scalars['Int']>;
  /** @deprecated Use search request reference for information */
  businessInformation?: Maybe<Scalars['String']>;
  client: Client;
  clientId: Scalars['String'];
  comments: Scalars['Int'];
  /** @deprecated Use search request reference for information */
  contactPerson?: Maybe<User>;
  contactPersonId?: Maybe<Scalars['String']>;
  conversation?: Maybe<Conversation>;
  conversationId?: Maybe<Scalars['String']>;
  county?: Maybe<Region>;
  created: Scalars['Date'];
  /** @deprecated Use description instead */
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use search request reference for information */
  documentIds: Array<Scalars['String']>;
  /** @deprecated Use search request reference for information */
  documents?: Maybe<Array<Maybe<File>>>;
  /** @deprecated Use search request reference for information */
  employees?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  receiver: Client;
  receiverId: Scalars['String'];
  regionBounds?: Maybe<ResultBounds>;
  regions?: Maybe<Array<Region>>;
  /** @deprecated Does not do anything */
  renewable?: Maybe<Scalars['Boolean']>;
  /** @deprecated Does not do anything */
  renewedDate?: Maybe<Scalars['Date']>;
  /** @deprecated Use searchRequestReferenceId */
  requestGroupId: Scalars['String'];
  responsibleUser?: Maybe<User>;
  searchParameters?: Maybe<SearchParameters>;
  searchRequestReference: SearchRequestReference;
  searchRequestReferenceId: Scalars['String'];
  sentDate?: Maybe<Scalars['Date']>;
  /** @deprecated Use search request reference for information */
  specificNeedsInformation?: Maybe<Scalars['String']>;
  status: StatusEnum;
  step: SearchRequestStep;
  stepWeight: Scalars['Float'];
  /** @deprecated Use search request reference for information */
  title: Scalars['String'];
};

export type SearchRequestAdminData = {
  __typename?: 'SearchRequestAdminData';
  count: Scalars['Int'];
  searchRequests: Array<SearchRequestReference>;
};

export type SearchRequestAdminFilter = {
  adminResponsibleUserIds?: InputMaybe<Array<Scalars['String']>>;
  adminStatus?: InputMaybe<Array<AdminSearchRequestReferenceStatus>>;
  clientId?: InputMaybe<Scalars['String']>;
  disabledReason?: InputMaybe<Array<DisableSearchRequestReasonEnum>>;
  from?: InputMaybe<Scalars['Date']>;
  onlyFavourites?: InputMaybe<Scalars['Boolean']>;
  regionIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  size: RangeInput;
  status?: InputMaybe<Array<SearchRequestStatusEnum>>;
  to?: InputMaybe<Scalars['Date']>;
  usageCategory?: InputMaybe<Scalars['String']>;
};

export type SearchRequestAdminInput = {
  favourite: Scalars['Boolean'];
};

export type SearchRequestAdminStatistics = {
  __typename?: 'SearchRequestAdminStatistics';
  count: Scalars['Int'];
  potentialSize: Range;
  regions: Array<CountByRegion>;
  sizes: Array<CountBySize>;
  usageCategories: Array<CountByUsageCategory>;
};

export type SearchRequestAnalysis = {
  __typename?: 'SearchRequestAnalysis';
  accepted?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  declined?: Maybe<Scalars['Int']>;
  disabled: DisabledSearchRequestAnalysis;
  opened?: Maybe<Scalars['Int']>;
  recipients?: Maybe<Scalars['Int']>;
  unopened?: Maybe<Scalars['Int']>;
};

export type SearchRequestAreaInput = {
  geometry?: InputMaybe<Scalars['geoMultiPolygon']>;
  regionIds: Array<Scalars['String']>;
};

export type SearchRequestClientInput = {
  excludeBrf?: InputMaybe<Scalars['Boolean']>;
  geometry?: InputMaybe<Scalars['geoMultiPolygon']>;
  regionIds?: InputMaybe<Array<Scalars['String']>>;
};

export type SearchRequestComment = {
  __typename?: 'SearchRequestComment';
  comment: Scalars['String'];
  created?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  updated?: Maybe<Scalars['Date']>;
  user: User;
};

export type SearchRequestCommentInput = {
  comment: Scalars['String'];
  docModel?: InputMaybe<DocModel>;
};

export type SearchRequestData = {
  __typename?: 'SearchRequestData';
  count?: Maybe<Scalars['Int']>;
  searchRequests: Array<SearchRequest>;
};

export type SearchRequestFilter = {
  filterCreated?: InputMaybe<Scalars['Boolean']>;
  from: Scalars['Date'];
  regionIds: Array<Scalars['String']>;
  size: Array<SizeFilter>;
  to?: InputMaybe<Scalars['Date']>;
  usageCategories: Array<Scalars['String']>;
};

export type SearchRequestInformation = {
  access?: Maybe<Access>;
  accessV2?: Maybe<AccessEnumV2>;
  areaInformation?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Int']>;
  businessInformation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employees?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  specificNeedsInformation?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SearchRequestInformationInput = {
  access?: InputMaybe<AccessInput>;
  accessEarliest?: InputMaybe<Scalars['Date']>;
  accessLatest?: InputMaybe<Scalars['Date']>;
  accessV2?: InputMaybe<AccessEnumV2>;
  areaInformation?: InputMaybe<Scalars['String']>;
  budget?: InputMaybe<Scalars['Int']>;
  budgetSpan?: InputMaybe<MaxNullRangeInput>;
  businessInformation?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  documentIds: Array<Scalars['String']>;
  employees?: InputMaybe<Scalars['Int']>;
  employeesSpan?: InputMaybe<MaxNullRangeInput>;
  size: NonNullRangeInput;
  specificNeedsInformation?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  usageCategories: Array<Scalars['String']>;
};

export type SearchRequestLandlordData = {
  __typename?: 'SearchRequestLandlordData';
  accepted: SearchRequestData;
  declined: SearchRequestData;
  disabled: SearchRequestReferenceData;
  unanswered: SearchRequestReferenceData;
};

export type SearchRequestLandlordDataAcceptedArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type SearchRequestLandlordDataDeclinedArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type SearchRequestLandlordDataDisabledArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type SearchRequestLandlordDataUnansweredArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type SearchRequestReceiver = {
  __typename?: 'SearchRequestReceiver';
  client: Client;
  conversation?: Maybe<Conversation>;
  conversationId?: Maybe<Scalars['String']>;
  status: StatusEnum;
  user: User;
};

export type SearchRequestReceiverData = {
  __typename?: 'SearchRequestReceiverData';
  count: Scalars['Int'];
  receivers: Array<SearchRequestReceiver>;
};

export type SearchRequestReference = SearchRequestInformation & {
  __typename?: 'SearchRequestReference';
  access?: Maybe<Access>;
  accessV2?: Maybe<AccessEnumV2>;
  adminResponsibleUser?: Maybe<User>;
  adminStatus?: Maybe<AdminSearchRequestReferenceStatus>;
  areaInformation?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Int']>;
  budgetSpan?: Maybe<Range>;
  businessInformation?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['String']>;
  contactInformation?: Maybe<ContactInformation>;
  contactPerson?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use status instead */
  disabled?: Maybe<Scalars['Boolean']>;
  disabledDate?: Maybe<Scalars['Date']>;
  disabledReason?: Maybe<DisableSearchRequestReason>;
  documents: Array<File>;
  employees?: Maybe<Scalars['Int']>;
  employeesSpan?: Maybe<Range>;
  favourite?: Maybe<Scalars['Boolean']>;
  fromWebsite: Scalars['Boolean'];
  hasComment?: Maybe<Scalars['Boolean']>;
  hasLoggedIn?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  improveSearchMailSent?: Maybe<Scalars['Boolean']>;
  myStatus: StatusEnum;
  receivers: UserData;
  regions: Array<Region>;
  renewedDate?: Maybe<Scalars['Date']>;
  requestGroupId?: Maybe<Scalars['String']>;
  searchParameters: SearchParameters;
  searchRequests: SearchRequestData;
  sentDate: Scalars['Date'];
  specificNeedsInformation?: Maybe<Scalars['String']>;
  stats: StatusCount;
  status: SearchRequestStatusEnum;
  title?: Maybe<Scalars['String']>;
  unreadMessages: Scalars['Int'];
};

export type SearchRequestReferenceReceiversArgs = {
  limit: Scalars['Int'];
  showAll?: InputMaybe<Scalars['Boolean']>;
};

export type SearchRequestReferenceSearchRequestsArgs = {
  limit: Scalars['Int'];
  status?: InputMaybe<Array<StatusEnum>>;
};

export type SearchRequestReferenceData = {
  __typename?: 'SearchRequestReferenceData';
  count: Scalars['Int'];
  searchRequests: Array<SearchRequestReference>;
};

export type SearchRequestSettings = {
  __typename?: 'SearchRequestSettings';
  filterEmails?: Maybe<Scalars['Boolean']>;
  regionIds: Array<Scalars['String']>;
  regions: Array<Region>;
  size: Array<SizeFilter>;
  usageCategories: Array<Scalars['String']>;
};

export type SearchRequestSettingsInput = {
  filterEmails?: InputMaybe<Scalars['Boolean']>;
  regionIds: Array<Scalars['String']>;
  size: Array<SizeFilter>;
  usageCategories: Array<Scalars['String']>;
};

export type SearchRequestStats = {
  __typename?: 'SearchRequestStats';
  count: Scalars['Int'];
  searchRequestsOverTime: Array<DateStat>;
};

export type SearchRequestStatsSearchRequestsOverTimeArgs = {
  from?: InputMaybe<Scalars['Date']>;
};

export enum SearchRequestStatusEnum {
  Active = 'active',
  Disabled = 'disabled',
  Passive = 'passive',
  Pending = 'pending',
  Removed = 'removed',
}

export enum SearchRequestStep {
  Archived = 'archived',
  InProgress = 'inProgress',
  Interesting = 'interesting',
  Negotiation = 'negotiation',
  Success = 'success',
  Todo = 'todo',
}

export type SearchRequestsInfo = {
  __typename?: 'SearchRequestsInfo';
  id?: Maybe<Scalars['ID']>;
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
  searchRequestId?: Maybe<Scalars['String']>;
  size?: Maybe<Range>;
  usageCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  loginToken?: Maybe<Scalars['String']>;
  searchRequestReference: SearchRequestReference;
};

export type SectionInput = {
  displayResourceId: Scalars['ID'];
  targetGroup: Array<Scalars['String']>;
  text: Scalars['String'];
  title: Scalars['String'];
};

export type Sections = {
  __typename?: 'Sections';
  displayResource: File;
  id: Scalars['ID'];
  targetGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  text: Scalars['String'];
  title: Scalars['String'];
};

export type SendNewsMessageInput = {
  emailSubject?: InputMaybe<Scalars['String']>;
  filter: UserFilterInput;
  message: Scalars['String'];
  sendEmails: Scalars['Boolean'];
};

export type SendSearchRequestInput = {
  clientId: Scalars['String'];
  skipSpamFilter: Scalars['Boolean'];
  title: Scalars['String'];
};

export enum SendSearchRequestToUserEnum {
  AnswerRequested = 'answerRequested',
  Personal = 'personal',
  Tip = 'tip',
}

export type SendSearchRequestToUserInput = {
  message?: InputMaybe<Scalars['String']>;
  searchRequestReferenceId: Scalars['String'];
  type: Scalars['String'];
  userId: Scalars['String'];
};

export type ShareSearchRequestInput = {
  email?: InputMaybe<Scalars['String']>;
  searchRequestReferenceId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type SimilarLeaseAgreements = {
  __typename?: 'SimilarLeaseAgreements';
  moreFromClient: Array<LeaseAgreement>;
  similar: Array<LeaseAgreement>;
};

export type SiteMapLinks = {
  __typename?: 'SiteMapLinks';
  priority: Scalars['Float'];
  url: Scalars['String'];
};

export type SiteMapResult = {
  __typename?: 'SiteMapResult';
  count: Scalars['Int'];
  name: Scalars['String'];
  siteMapLinks: Array<SiteMapLinks>;
  urlSlug: Scalars['String'];
};

export enum SizeFilter {
  Lg = 'lg',
  Md = 'md',
  Sm = 'sm',
  Xl = 'xl',
  Xs = 'xs',
}

export type SizeSpan = {
  __typename?: 'SizeSpan';
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export type SizeSpanInput = {
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export type Sort = {
  direction: Scalars['String'];
  field: Scalars['String'];
};

export enum SortDirection {
  Ascend = 'ascend',
  Descend = 'descend',
}

export type Statistics = {
  __typename?: 'Statistics';
  nrOfActiveLandlordUsers?: Maybe<Scalars['Int']>;
  nrOfActiveTenantUsers?: Maybe<Scalars['Int']>;
  nrOfActiveUsers?: Maybe<Scalars['Int']>;
  nrOfEmailSent?: Maybe<Scalars['Int']>;
  nrOfInterests?: Maybe<Scalars['Int']>;
  nrOfLandlordUserSignups?: Maybe<Scalars['Int']>;
  nrOfLeaseAgreements?: Maybe<Scalars['Int']>;
  nrOfMessages?: Maybe<Scalars['Int']>;
  nrOfNewSearchRequests?: Maybe<Scalars['Int']>;
  nrOfPromotions?: Maybe<Scalars['Int']>;
  nrOfSignups?: Maybe<Scalars['Int']>;
  nrOfTenantUserSignups?: Maybe<Scalars['Int']>;
};

export type Stats = {
  __typename?: 'Stats';
  interested: Scalars['Int'];
  matches: Scalars['Int'];
  matchesNP: Scalars['Int'];
  promoted?: Maybe<PromotedStat>;
};

export type Status = {
  __typename?: 'Status';
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  leaseAgreement?: Maybe<LeaseAgreement>;
  leaseAgreementId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type StatusCount = {
  __typename?: 'StatusCount';
  accepted: Scalars['Int'];
  answer_requested: Scalars['Int'];
  declined: Scalars['Int'];
  opened: Scalars['Int'];
};

export enum StatusEnum {
  Accepted = 'accepted',
  AnswerRequested = 'answer_requested',
  Declined = 'declined',
  Opened = 'opened',
  Unopened = 'unopened',
}

export type StatusInput = {
  clientId: Scalars['String'];
  leaseAgreementId: Scalars['String'];
  status: Scalars['String'];
};

export type StatusUpdate = {
  __typename?: 'StatusUpdate';
  direction: Direction;
  nextStatus: ActivityStatusEnum;
  previousStatus: ActivityStatusEnum;
};

export type StatusUpdateData = {
  __typename?: 'StatusUpdateData';
  count: Scalars['Int'];
  statusUpdates: Array<ActivityLog>;
};

export enum Step {
  All = 'all',
  BookedMeeting = 'bookedMeeting',
  Construction = 'construction',
  Depreciated = 'depreciated',
  FinalNegotiation = 'finalNegotiation',
  FirstMeeting = 'firstMeeting',
  HeldMeeting = 'heldMeeting',
  Lead = 'lead',
  LostBusiness = 'lostBusiness',
  Now = 'now',
  Order = 'order',
  SentOffer = 'sentOffer',
}

export type StoreQuantity = {
  __typename?: 'StoreQuantity';
  amount?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
};

export type StoreQuantityInput = {
  amount?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  conversation: Message;
  conversationAttachment: Attachment;
  newAttachment: Attachment;
  newMessage: Message;
  newNotification?: Maybe<Notification>;
  newReaction: Message;
};

export type SubscriptionConversationArgs = {
  conversationId: Scalars['String'];
};

export type SubscriptionConversationAttachmentArgs = {
  conversationId: Scalars['String'];
};

export type SubscriptionFilter = {
  client?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

export type SubscriptionGoals = {
  __typename?: 'SubscriptionGoals';
  december?: Maybe<Forecast>;
  october?: Maybe<Forecast>;
  subscribersCount?: Maybe<Scalars['Int']>;
};

export type SubscriptionRequest = PortallySubscription & {
  __typename?: 'SubscriptionRequest';
  acceptedByUser?: Maybe<User>;
  acceptedByUserId?: Maybe<Scalars['String']>;
  acceptedDate?: Maybe<Scalars['Date']>;
  client: Client;
  clientId: Scalars['String'];
  created: Scalars['Date'];
  id: Scalars['ID'];
  openedByUserIds: Array<Scalars['String']>;
  openedByUsers: Array<User>;
  readByUserIds: Array<Scalars['String']>;
  readByUsers: Array<User>;
  status: Scalars['String'];
  type?: Maybe<SubscriptionType>;
  updated: Scalars['Date'];
  version: SubscriptionVersion;
};

export enum SubscriptionType {
  Commission = 'commission',
  Mix = 'mix',
  Subscription = 'subscription',
}

export enum SubscriptionVersion {
  V1 = 'v1',
  V2 = 'v2',
}

export type Summary = {
  __typename?: 'Summary';
  promoted: Scalars['Int'];
  rental: Scalars['Int'];
  settlement: Scalars['Int'];
  total: Scalars['Int'];
};

export type SystemStatistics = {
  __typename?: 'SystemStatistics';
  clients: Scalars['Int'];
  contacts: Scalars['Int'];
  conversations: Scalars['Int'];
  estates: Scalars['Int'];
  interesting: Scalars['Int'];
  landlords: Scalars['Int'];
  leaseAgreements: LeaseAgreementStats;
  matches: Scalars['Int'];
  messages: MessageStats;
  notifications: Scalars['Int'];
  preliminarySearchRequests: PreliminarySearchRequestStats;
  promotions: PromotedStats;
  searchProfiles: Scalars['Int'];
  searchRequests: SearchRequestStats;
  tenants: Scalars['Int'];
  users: UserStats;
  verified: Scalars['Int'];
};

export type Terms = {
  __typename?: 'Terms';
  confirmed?: Maybe<Scalars['Boolean']>;
  confirmedDate?: Maybe<Scalars['Date']>;
  confirmedUser?: Maybe<User>;
  confirmedUserId?: Maybe<Scalars['String']>;
};

export type TermsOfService = {
  __typename?: 'TermsOfService';
  content?: Maybe<TranslatedContent>;
  cookiePolicy?: Maybe<TranslatedContent>;
  id: Scalars['ID'];
  privacy?: Maybe<TranslatedContent>;
  published?: Maybe<Scalars['Boolean']>;
  publishedDate?: Maybe<Scalars['Date']>;
  version?: Maybe<Scalars['String']>;
};

export type TermsOfServiceData = {
  __typename?: 'TermsOfServiceData';
  count?: Maybe<Scalars['Int']>;
  termsOfServices?: Maybe<Array<Maybe<TermsOfService>>>;
};

export type TermsOfServiceDataTermsOfServicesArgs = {
  filter?: InputMaybe<TermsOfServiceFilter>;
};

export type TermsOfServiceFilter = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type TermsOfServiceInput = {
  content?: InputMaybe<TranslatedContentInput>;
  cookiePolicy?: InputMaybe<TranslatedContentInput>;
  privacy?: InputMaybe<TranslatedContentInput>;
  published?: InputMaybe<Scalars['Boolean']>;
  publishedDate?: InputMaybe<Scalars['Date']>;
  version?: InputMaybe<Scalars['String']>;
};

export type TimeAverageInStepData = {
  __typename?: 'TimeAverageInStepData';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type TimeAverageToOrderData = {
  __typename?: 'TimeAverageToOrderData';
  bookedMeetingToOrder?: Maybe<Scalars['Int']>;
  leadToOrder?: Maybe<Scalars['Int']>;
};

export type TokenInfo = {
  __typename?: 'TokenInfo';
  createAccountInfo?: Maybe<CreateAccountInfo>;
  exists: Scalars['Boolean'];
  valid: Scalars['Boolean'];
};

export type TotalProcessValues = {
  __typename?: 'TotalProcessValues';
  processValuesData?: Maybe<Array<ProcessValuesData>>;
  totalValue?: Maybe<Scalars['Int']>;
};

export type TranslatedContent = {
  __typename?: 'TranslatedContent';
  en?: Maybe<Scalars['String']>;
  sv?: Maybe<Scalars['String']>;
};

export type TranslatedContentInput = {
  en?: InputMaybe<Scalars['String']>;
  sv?: InputMaybe<Scalars['String']>;
};

export type Trial = {
  __typename?: 'Trial';
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TrialInput = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export enum TypeOfBusiness {
  Landlord = 'landlord',
  Tenant = 'tenant',
  Tenrep = 'tenrep',
  Trustee = 'trustee',
}

export type Unread = {
  __typename?: 'Unread';
  /** @deprecated Attachments are found in messages */
  attachments?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  messages?: Maybe<Scalars['Int']>;
};

export type UpdateSearchRequestStepInput = {
  step: SearchRequestStep;
  stepWeight?: InputMaybe<Scalars['Float']>;
};

export enum UsageCategory {
  Cafe = 'cafe',
  CoWork = 'coWork',
  Gym = 'gym',
  Healthcare = 'healthcare',
  Industry = 'industry',
  Office = 'office',
  Other = 'other',
  PlotLand = 'plotLand',
  ProjectSpace = 'projectSpace',
  Restaurant = 'restaurant',
  Shop = 'shop',
  ShopPopup = 'shopPopup',
  Showroom = 'showroom',
  Warehouse = 'warehouse',
}

export type User = {
  __typename?: 'User';
  access?: Maybe<Scalars['Date']>;
  activeClient?: Maybe<Client>;
  activeClientId?: Maybe<Scalars['String']>;
  activeModal?: Maybe<ModalEnum>;
  activeSubscription?: Maybe<SubscriptionRequest>;
  activity: ActivityData;
  activityStatus?: Maybe<ActivityStatusEnum>;
  city?: Maybe<Scalars['String']>;
  clientIds?: Maybe<Array<Scalars['String']>>;
  clients?: Maybe<Array<Client>>;
  companyName?: Maybe<Scalars['String']>;
  completedTours: Array<Scalars['String']>;
  created?: Maybe<Scalars['Date']>;
  deals: UserDeals;
  disabled?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  emailSettings: EmailSettings;
  estateIds?: Maybe<Array<Scalars['String']>>;
  estates?: Maybe<Array<Estate>>;
  failedLogins?: Maybe<Scalars['Int']>;
  firstName: Scalars['String'];
  fromWebsite?: Maybe<Scalars['Boolean']>;
  hasDealRequest: Scalars['Boolean'];
  hasPassword?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  image?: Maybe<File>;
  imageId?: Maybe<Scalars['String']>;
  interestingPopconfirm?: Maybe<Scalars['Boolean']>;
  isPartner: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  leaseAgreementViews: LeaseAgreementViewData;
  /** @deprecated Does not exist anymore */
  limited?: Maybe<Scalars['Boolean']>;
  logins?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  onBoarded?: Maybe<Scalars['Boolean']>;
  orgNumber?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  prospect?: Maybe<Scalars['Boolean']>;
  receivedEmails: Array<EmailLog>;
  receivedWelcomeEmail?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  searchRequestSettings?: Maybe<SearchRequestSettings>;
  searchRequests: Array<SearchRequestReference>;
  /** @deprecated Does not exist anymore */
  showMine?: Maybe<Scalars['Boolean']>;
  subscription?: Maybe<SubscriptionRequest>;
  trial?: Maybe<Trial>;
  updated?: Maybe<Scalars['Date']>;
  userCategory: Array<UserCategoryEnum>;
  verified: Scalars['Boolean'];
};

export type UserActivityArgs = {
  filter: ActivityLogFilterInput;
};

export type UserDealsArgs = {
  clientId?: InputMaybe<Scalars['String']>;
};

export type UserHasDealRequestArgs = {
  clientId?: InputMaybe<Scalars['String']>;
};

export type UserReceivedEmailsArgs = {
  filter: ReceivedEmailsFilter;
};

export enum UserCategoryEnum {
  Ceo = 'CEO',
  BusinessDeveloper = 'businessDeveloper',
  Consultant = 'consultant',
  Lessor = 'lessor',
  LessorOffice = 'lessorOffice',
  LessorRetail = 'lessorRetail',
  LessorWarehouse = 'lessorWarehouse',
  Other = 'other',
  PropertyManager = 'propertyManager',
  PropertyTrustee = 'propertyTrustee',
  RegionalManager = 'regionalManager',
  RentalManager = 'rentalManager',
}

export type UserData = {
  __typename?: 'UserData';
  count: Scalars['Int'];
  users: Array<User>;
};

export type UserDeals = {
  __typename?: 'UserDeals';
  count?: Maybe<Scalars['Int']>;
  portallysFee?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

export type UserFilterInput = {
  activityStatus?: InputMaybe<Array<ActivityStatusEnum>>;
  clientId?: InputMaybe<Scalars['String']>;
  clientType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  createdFrom?: InputMaybe<Scalars['Date']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  hasNotAccessed?: InputMaybe<Scalars['Boolean']>;
  isPortally?: InputMaybe<Scalars['Boolean']>;
  isSalesTeam?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  prospect?: InputMaybe<Scalars['Boolean']>;
  regionIds?: InputMaybe<Array<Scalars['String']>>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  size?: InputMaybe<Array<SizeFilter>>;
  skip?: InputMaybe<Scalars['Int']>;
  usageCategories?: InputMaybe<Array<Scalars['String']>>;
  userCategory?: InputMaybe<Array<UserCategoryEnum>>;
};

export type UserInput = {
  activeClientId?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clientIds?: InputMaybe<Array<Scalars['String']>>;
  clientType?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  emailSettings?: InputMaybe<EmailSettingsInput>;
  estateIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  failedLogins?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  limited?: InputMaybe<Scalars['Boolean']>;
  onBoarded?: InputMaybe<Scalars['Boolean']>;
  orgNumber?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  prospect?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Scalars['String']>;
  searchRequestSettings?: InputMaybe<SearchRequestSettingsInput>;
  showMine?: InputMaybe<Scalars['Boolean']>;
  trial?: InputMaybe<TrialInput>;
  userCategory?: InputMaybe<Array<UserCategoryEnum>>;
};

export type UserStats = {
  __typename?: 'UserStats';
  activeUserStats?: Maybe<ActiveUserStats>;
  count: Scalars['Int'];
  forecast: Forecast;
  lastMonthTot: Scalars['Int'];
  lastWeekTot: Scalars['Int'];
  loginStats: Array<DateStat>;
  registeredPerDay: Array<DateStat>;
  thisMonthTot: Scalars['Int'];
  thisWeekTot: Scalars['Int'];
  totalLoginStats: Array<DateStat>;
};

export type UserStatsActiveUserStatsArgs = {
  clientType?: InputMaybe<ClientType>;
};

export type UserStatsLoginStatsArgs = {
  clientType?: InputMaybe<ClientType>;
};

export type UserStatsTotalLoginStatsArgs = {
  clientType?: InputMaybe<ClientType>;
};

export type Video = {
  __typename?: 'Video';
  thumbnail?: Maybe<File>;
  thumbnailId?: Maybe<Scalars['String']>;
  video?: Maybe<File>;
  videoId?: Maybe<Scalars['String']>;
};

export type VideoInput = {
  thumbnailId?: InputMaybe<Scalars['String']>;
  videoId: Scalars['String'];
};

export type WaitingEmail = {
  __typename?: 'WaitingEmail';
  globalMergeVars?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
};

export type WebsiteSearchQuery = {
  __typename?: 'WebsiteSearchQuery';
  city?: Maybe<Scalars['String']>;
  max?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['String']>;
  regionIds?: Maybe<Scalars['String']>;
  usageCategories?: Maybe<Scalars['String']>;
};

export type WebsiteSearchQueryInput = {
  city: Scalars['String'];
  max?: InputMaybe<Scalars['String']>;
  min?: InputMaybe<Scalars['String']>;
  regionIds: Scalars['String'];
  usageCategories: Scalars['String'];
};

export type Yearly = {
  __typename?: 'Yearly';
  expectedIncome?: Maybe<Scalars['Float']>;
  producedIncome?: Maybe<Scalars['Float']>;
};

export type UpdateActiveClientMutationVariables = Exact<{
  id: Scalars['String'];
  input: UserInput;
}>;

export type UpdateActiveClientMutation = {
  __typename?: 'Mutation';
  editUser?: { __typename?: 'User'; id: string } | null;
};

export type MyEstatesQueryVariables = Exact<{ [key: string]: never }>;

export type MyEstatesQuery = {
  __typename?: 'Query';
  myEstates?: Array<{
    __typename?: 'Estate';
    name?: string | null;
    estateDesignation?: string | null;
    id: string;
    address?: Array<{
      __typename?: 'Address';
      city?: string | null;
    } | null> | null;
  }> | null;
};

export type AddAttachmentMutationVariables = Exact<{
  conversationId: Scalars['String'];
  attachmentIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type AddAttachmentMutation = {
  __typename?: 'Mutation';
  addAttachments?: {
    __typename?: 'Conversation';
    id: string;
    attachmentData?: {
      __typename?: 'AttachmentData';
      count?: number | null;
      attachments: Array<{
        __typename?: 'Attachment';
        id: string;
        attachmentId?: string | null;
        attachment?: {
          __typename?: 'File';
          id: string;
          name: string;
          size: number;
          url: string;
          mimetype: string;
          uid: string;
        } | null;
      }>;
    } | null;
  } | null;
};

export type AddMessageMutationVariables = Exact<{
  input: MessageInput;
}>;

export type AddMessageMutation = {
  __typename?: 'Mutation';
  addMessage: {
    __typename?: 'Message';
    id: string;
    message?: string | null;
    created?: any | null;
    read?: boolean | null;
    readTimestamp?: any | null;
    conversationId: string;
    conversation: {
      __typename?: 'Conversation';
      id: string;
      pending?: boolean | null;
    };
    sender: { __typename?: 'Client'; id: string; name: string };
    senderUser?: {
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
    reactions?: Array<{
      __typename?: 'Reaction';
      userId: string;
      reaction: ReactionEnum;
      user: { __typename?: 'User'; name: string };
    } | null> | null;
    attachments: Array<{
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    }>;
    leaseAgreements: Array<{
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      description?: string | null;
      title?: string | null;
      size?: number | null;
      usageCategory?: Array<UsageCategory> | null;
      image?: { __typename?: 'File'; id: string; url: string } | null;
      sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
      fee?: { __typename?: 'Fee'; amount: number; feeType: FeeType } | null;
    }>;
  };
};

export type ArchiveConversationMutationVariables = Exact<{
  id: Scalars['String'];
  archived: Scalars['Boolean'];
}>;

export type ArchiveConversationMutation = {
  __typename?: 'Mutation';
  setArchived: {
    __typename?: 'Conversation';
    id: string;
    archived?: boolean | null;
  };
};

export type GetConversationQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetConversationQuery = {
  __typename?: 'Query';
  conversation?: {
    __typename?: 'Conversation';
    id: string;
    archived?: boolean | null;
    unanswered?: boolean | null;
    pending?: boolean | null;
    opponents: Array<{
      __typename?: 'Client';
      id: string;
      name: string;
      rating?: number | null;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    }>;
    leaseAgreement?: {
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
    searchRequestReference?: {
      __typename?: 'SearchRequestReference';
      id: string;
      title?: string | null;
      status: SearchRequestStatusEnum;
      clientId?: string | null;
      disabledReason?: {
        __typename?: 'DisableSearchRequestReason';
        reason: DisableSearchRequestReasonEnum;
        comment?: string | null;
      } | null;
      searchParameters: {
        __typename?: 'SearchParameters';
        regionIds?: Array<string> | null;
        usageCategories: Array<string>;
        size?: {
          __typename?: 'Range';
          min?: number | null;
          max?: number | null;
        } | null;
      };
    } | null;
    unread?: {
      __typename?: 'Unread';
      messages?: number | null;
      attachments?: number | null;
    } | null;
    messages: Array<{
      __typename?: 'Message';
      id: string;
      message?: string | null;
      created?: any | null;
      read?: boolean | null;
      readTimestamp?: any | null;
      conversationId: string;
      sender: { __typename?: 'Client'; id: string; name: string };
      senderUser?: {
        __typename?: 'User';
        id: string;
        name: string;
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
      reactions?: Array<{
        __typename?: 'Reaction';
        userId: string;
        reaction: ReactionEnum;
        user: { __typename?: 'User'; name: string };
      } | null> | null;
      attachments: Array<{
        __typename?: 'File';
        id: string;
        name: string;
        size: number;
        url: string;
        mimetype: string;
        uid: string;
      }>;
      leaseAgreements: Array<{
        __typename?: 'LeaseAgreement';
        id: string;
        name: string;
        description?: string | null;
        title?: string | null;
        size?: number | null;
        usageCategory?: Array<UsageCategory> | null;
        image?: { __typename?: 'File'; id: string; url: string } | null;
        sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
        fee?: { __typename?: 'Fee'; amount: number; feeType: FeeType } | null;
      }>;
    }>;
  } | null;
};

export type MarkConversationAsAnsweredMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type MarkConversationAsAnsweredMutation = {
  __typename?: 'Mutation';
  markAsAnswered: {
    __typename?: 'Conversation';
    id: string;
    unanswered?: boolean | null;
  };
};

export type SubscribeToConversationSubscriptionVariables = Exact<{
  conversationId: Scalars['String'];
}>;

export type SubscribeToConversationSubscription = {
  __typename?: 'Subscription';
  conversation: {
    __typename?: 'Message';
    id: string;
    message?: string | null;
    created?: any | null;
    read?: boolean | null;
    readTimestamp?: any | null;
    conversationId: string;
    conversation: { __typename?: 'Conversation'; id: string };
    sender: { __typename?: 'Client'; id: string; name: string };
    senderUser?: {
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
    reactions?: Array<{
      __typename?: 'Reaction';
      userId: string;
      reaction: ReactionEnum;
      user: { __typename?: 'User'; name: string };
    } | null> | null;
    attachments: Array<{
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    }>;
    leaseAgreements: Array<{
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      description?: string | null;
      title?: string | null;
      size?: number | null;
      usageCategory?: Array<UsageCategory> | null;
      image?: { __typename?: 'File'; id: string; url: string } | null;
      sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
      fee?: { __typename?: 'Fee'; amount: number; feeType: FeeType } | null;
    }>;
  };
};

export type SubscribeToConversationAttachmentsSubscriptionVariables = Exact<{
  conversationId: Scalars['String'];
}>;

export type SubscribeToConversationAttachmentsSubscription = {
  __typename?: 'Subscription';
  conversationAttachment: {
    __typename?: 'Attachment';
    id: string;
    attachmentId?: string | null;
    conversation?: {
      __typename?: 'Conversation';
      id: string;
      unread?: {
        __typename?: 'Unread';
        attachments?: number | null;
        messages?: number | null;
        count?: number | null;
      } | null;
    } | null;
    attachment?: {
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    } | null;
  };
};

export type SubscribeToReactionsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type SubscribeToReactionsSubscription = {
  __typename?: 'Subscription';
  newReaction: {
    __typename?: 'Message';
    id: string;
    reactions?: Array<{
      __typename?: 'Reaction';
      userId: string;
      reaction: ReactionEnum;
    } | null> | null;
  };
};

export type ViewAttachmentsMutationVariables = Exact<{
  conversationId: Scalars['String'];
}>;

export type ViewAttachmentsMutation = {
  __typename?: 'Mutation';
  viewAttachments?: {
    __typename?: 'Conversation';
    id: string;
    unread?: {
      __typename?: 'Unread';
      count?: number | null;
      attachments?: number | null;
      messages?: number | null;
    } | null;
  } | null;
};

export type ViewConversationMutationVariables = Exact<{
  conversationId: Scalars['String'];
}>;

export type ViewConversationMutation = {
  __typename?: 'Mutation';
  viewConversation?: {
    __typename?: 'Conversation';
    id: string;
    unread?: {
      __typename?: 'Unread';
      count?: number | null;
      attachments?: number | null;
      messages?: number | null;
    } | null;
  } | null;
};

export type ReactOnMessageMutationVariables = Exact<{
  input: ReactOnMessageInput;
}>;

export type ReactOnMessageMutation = {
  __typename?: 'Mutation';
  reactOnMessage: {
    __typename?: 'Message';
    id: string;
    reactions?: Array<{
      __typename?: 'Reaction';
      userId: string;
      reaction: ReactionEnum;
      user: { __typename?: 'User'; name: string };
    } | null> | null;
  };
};

export type DisableInterestingPopconfirmMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DisableInterestingPopconfirmMutation = {
  __typename?: 'Mutation';
  disableInterestingPopconfirm?: {
    __typename?: 'User';
    id: string;
    interestingPopconfirm?: boolean | null;
  } | null;
};

export type AddContactMutationVariables = Exact<{
  input: ContactInput;
}>;

export type AddContactMutation = {
  __typename?: 'Mutation';
  addContact: {
    __typename?: 'Contact';
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    email?: string | null;
    phone?: string | null;
    title?: string | null;
    clientId: string;
    emailsEnabled?: boolean | null;
    userExists?: boolean | null;
  };
};

export type ContactValuesFragment = {
  __typename?: 'Contact';
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email?: string | null;
  phone?: string | null;
  title?: string | null;
  clientId: string;
  emailsEnabled?: boolean | null;
  userExists?: boolean | null;
};

export type CreateUserFromContactMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type CreateUserFromContactMutation = {
  __typename?: 'Mutation';
  createUserFromContact: { __typename?: 'User'; id: string };
};

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteContactMutation = {
  __typename?: 'Mutation';
  deleteContact: {
    __typename?: 'Contact';
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    email?: string | null;
    phone?: string | null;
    title?: string | null;
    clientId: string;
    emailsEnabled?: boolean | null;
    userExists?: boolean | null;
  };
};

export type EditContactMutationVariables = Exact<{
  id: Scalars['String'];
  input: ContactInput;
}>;

export type EditContactMutation = {
  __typename?: 'Mutation';
  editContact: {
    __typename?: 'Contact';
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    email?: string | null;
    phone?: string | null;
    title?: string | null;
    clientId: string;
    emailsEnabled?: boolean | null;
    userExists?: boolean | null;
  };
};

export type GetContactsQueryVariables = Exact<{
  clientId: Scalars['String'];
}>;

export type GetContactsQuery = {
  __typename?: 'Query';
  contacts: Array<{
    __typename?: 'Contact';
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    email?: string | null;
    phone?: string | null;
    title?: string | null;
    clientId: string;
    emailsEnabled?: boolean | null;
    userExists?: boolean | null;
  }>;
};

export type GetMessagesByConversationQueryVariables = Exact<{
  conversationId: Scalars['String'];
}>;

export type GetMessagesByConversationQuery = {
  __typename?: 'Query';
  conversation?: {
    __typename?: 'Conversation';
    id: string;
    messages: Array<{
      __typename?: 'Message';
      id: string;
      message?: string | null;
      created?: any | null;
      read?: boolean | null;
      readTimestamp?: any | null;
      conversationId: string;
      sender: { __typename?: 'Client'; id: string; name: string };
      senderUser?: {
        __typename?: 'User';
        id: string;
        name: string;
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
      reactions?: Array<{
        __typename?: 'Reaction';
        userId: string;
        reaction: ReactionEnum;
        user: { __typename?: 'User'; name: string };
      } | null> | null;
      attachments: Array<{
        __typename?: 'File';
        id: string;
        name: string;
        size: number;
        url: string;
        mimetype: string;
        uid: string;
      }>;
      leaseAgreements: Array<{
        __typename?: 'LeaseAgreement';
        id: string;
        name: string;
        description?: string | null;
        title?: string | null;
        size?: number | null;
        usageCategory?: Array<UsageCategory> | null;
        image?: { __typename?: 'File'; id: string; url: string } | null;
        sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
        fee?: { __typename?: 'Fee'; amount: number; feeType: FeeType } | null;
      }>;
    }>;
  } | null;
};

export type GetRatingQueryVariables = Exact<{ [key: string]: never }>;

export type GetRatingQuery = { __typename?: 'Query'; myRating?: number | null };

export type AddDealMutationVariables = Exact<{
  input: DealInput;
}>;

export type AddDealMutation = {
  __typename?: 'Mutation';
  addDeal: {
    __typename?: 'Deal';
    id: string;
    userId: string;
    clientId: string;
    madeWith: string;
    annualRent: number;
    created: any;
    user?: { __typename?: 'User'; name: string } | null;
    client?: { __typename?: 'Client'; name: string } | null;
  };
};

export type GetInvoiceDetailsQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetInvoiceDetailsQuery = {
  __typename?: 'Query';
  client: {
    __typename?: 'Client';
    invoiceDetails?: {
      __typename?: 'InvoiceDetails';
      billing?: string | null;
      company?: string | null;
      email?: string | null;
      marking?: string | null;
      reference?: string | null;
    } | null;
  };
};

export type GetMyDealQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetMyDealQuery = {
  __typename?: 'Query';
  deal: {
    __typename?: 'Deal';
    id: string;
    billing: string;
    madeWith: string;
    annualRent: number;
    portallyFee: number;
    email: string;
    reference: string;
    marking?: string | null;
    company?: string | null;
    comment?: string | null;
  };
};

export type GetMyDealsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyDealsQuery = {
  __typename?: 'Query';
  myDeals: Array<{
    __typename?: 'Deal';
    id: string;
    madeWith: string;
    created: any;
    user?: { __typename?: 'User'; name: string } | null;
  }>;
};

export type RemoveDealMutationVariables = Exact<{ [key: string]: never }>;

export type RemoveDealMutation = {
  __typename?: 'Mutation';
  removeDeal: {
    __typename?: 'User';
    id: string;
    activeClientId?: string | null;
    hasDealRequest: boolean;
    clients?: Array<{ __typename?: 'Client'; id: string; name: string }> | null;
  };
};

export type AddEventMutationVariables = Exact<{
  event: EventInput;
}>;

export type AddEventMutation = {
  __typename?: 'Mutation';
  addEvent?: {
    __typename?: 'Event';
    id: string;
    description?: string | null;
    responsibleConsultantId: string;
    date: any;
    done?: boolean | null;
    type: string;
    client?: { __typename?: 'Client'; id: string; name: string } | null;
    responsibleConsultant: { __typename?: 'User'; id: string; name: string };
  } | null;
};

export type DeleteEventMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteEventMutation = {
  __typename?: 'Mutation';
  deleteEvent?: {
    __typename?: 'Event';
    id: string;
    description?: string | null;
    responsibleConsultantId: string;
    date: any;
    done?: boolean | null;
    type: string;
    client?: { __typename?: 'Client'; id: string; name: string } | null;
    responsibleConsultant: { __typename?: 'User'; id: string; name: string };
  } | null;
};

export type EditEventMutationVariables = Exact<{
  id: Scalars['String'];
  event: EventInput;
}>;

export type EditEventMutation = {
  __typename?: 'Mutation';
  editEvent?: {
    __typename?: 'Event';
    id: string;
    description?: string | null;
    responsibleConsultantId: string;
    date: any;
    done?: boolean | null;
    type: string;
    client?: { __typename?: 'Client'; id: string; name: string } | null;
    responsibleConsultant: { __typename?: 'User'; id: string; name: string };
  } | null;
};

export type AddStatusMutationVariables = Exact<{
  input: StatusInput;
}>;

export type AddStatusMutation = {
  __typename?: 'Mutation';
  changeStatus?: {
    __typename?: 'Status';
    status?: string | null;
    id?: string | null;
    leaseAgreement?: {
      __typename?: 'LeaseAgreement';
      name: string;
      id: string;
      myStatus?: string | null;
    } | null;
  } | null;
};

export type SingleStatusQueryVariables = Exact<{
  leaseAgreementId: Scalars['String'];
}>;

export type SingleStatusQuery = {
  __typename?: 'Query';
  singleStatus?: {
    __typename?: 'Status';
    status?: string | null;
    id?: string | null;
    leaseAgreement?: {
      __typename?: 'LeaseAgreement';
      name: string;
      id: string;
    } | null;
  } | null;
};

export type GetMatchingSearchRequestsQueryVariables = Exact<{
  leaseAgreementId: Scalars['String'];
}>;

export type GetMatchingSearchRequestsQuery = {
  __typename?: 'Query';
  leaseAgreement: {
    __typename?: 'LeaseAgreement';
    matchingSearchRequests?: {
      __typename?: 'MatchingSearchRequests';
      searchRequests: Array<{
        __typename?: 'SearchRequestReference';
        id: string;
        title?: string | null;
        myStatus: StatusEnum;
        disabledDate?: any | null;
        status: SearchRequestStatusEnum;
        budget?: number | null;
        employees?: number | null;
        created: any;
        searchRequestReferenceId: string;
        client?: { __typename?: 'Client'; id: string; name: string } | null;
        disabledReason?: {
          __typename?: 'DisableSearchRequestReason';
          reason: DisableSearchRequestReasonEnum;
        } | null;
        searchRequests: {
          __typename?: 'SearchRequestData';
          searchRequests: Array<{
            __typename?: 'SearchRequest';
            status: StatusEnum;
            conversationId?: string | null;
            responsibleUser?: {
              __typename?: 'User';
              id: string;
              name: string;
              image?: { __typename?: 'File'; id: string; url: string } | null;
            } | null;
          }>;
        };
        searchParameters: {
          __typename?: 'SearchParameters';
          usageCategories: Array<string>;
          regionIds?: Array<string> | null;
          regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
          size?: {
            __typename?: 'Range';
            min?: number | null;
            max?: number | null;
          } | null;
        };
        budgetSpan?: {
          __typename?: 'Range';
          min?: number | null;
          max?: number | null;
        } | null;
        employeesSpan?: {
          __typename?: 'Range';
          min?: number | null;
          max?: number | null;
        } | null;
      }>;
    } | null;
  };
};

export type LeaseAgreementListValuesFragment = {
  __typename?: 'LeaseAgreement';
  id: string;
  name: string;
  title?: string | null;
  usageCategory?: Array<UsageCategory> | null;
  size?: number | null;
  myStatus?: string | null;
  location?: any | null;
  address: { __typename?: 'Address'; city?: string | null };
  sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
  image?: { __typename?: 'File'; url: string; name: string; id: string } | null;
  landlordContactPersons: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    image?: {
      __typename?: 'File';
      url: string;
      id: string;
      name: string;
    } | null;
  }>;
  landlord?: {
    __typename?: 'Client';
    id: string;
    name: string;
    image?: { __typename?: 'File'; url: string; name: string } | null;
  } | null;
  integrationProvider?: {
    __typename?: 'IntegrationProvider';
    id: string;
    name: string;
    image?: { __typename?: 'File'; id: string; url: string } | null;
  } | null;
  matchingSearchRequests?: {
    __typename?: 'MatchingSearchRequests';
    count: number;
  } | null;
};

export type GetLinkPreviewQueryVariables = Exact<{
  url: Scalars['String'];
}>;

export type GetLinkPreviewQuery = {
  __typename?: 'Query';
  linkPreview?: {
    __typename?: 'LinkPreview';
    title?: string | null;
    description?: string | null;
    image?: string | null;
  } | null;
};

export type RefreshUserTokenMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshUserTokenMutation = {
  __typename?: 'Mutation';
  refresh: string;
};

export type GetLeaseAgreementViewByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetLeaseAgreementViewByIdQuery = {
  __typename?: 'Query';
  leaseAgreement: {
    __typename?: 'LeaseAgreement';
    id: string;
    name: string;
    title?: string | null;
    usageCategory?: Array<UsageCategory> | null;
    size?: number | null;
    myStatus?: string | null;
    location?: any | null;
    address: { __typename?: 'Address'; city?: string | null };
    sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
    image?: {
      __typename?: 'File';
      url: string;
      name: string;
      id: string;
    } | null;
    landlordContactPersons: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      image?: {
        __typename?: 'File';
        url: string;
        id: string;
        name: string;
      } | null;
    }>;
    landlord?: {
      __typename?: 'Client';
      id: string;
      name: string;
      image?: { __typename?: 'File'; url: string; name: string } | null;
    } | null;
    integrationProvider?: {
      __typename?: 'IntegrationProvider';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
    matchingSearchRequests?: {
      __typename?: 'MatchingSearchRequests';
      count: number;
    } | null;
  };
};

export type GetLeaseAgreementDetailsQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetLeaseAgreementDetailsQuery = {
  __typename?: 'Query';
  leaseAgreement: {
    __typename?: 'LeaseAgreement';
    id: string;
    name: string;
    size?: number | null;
    usageCategory?: Array<UsageCategory> | null;
    title?: string | null;
    image?: { __typename?: 'File'; id: string; url: string } | null;
  };
};

export type GetLeaseAgreementsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetLeaseAgreementsByIdsQuery = {
  __typename?: 'Query';
  leaseAgreementsByIds: Array<{
    __typename?: 'LeaseAgreement';
    id: string;
    name: string;
    title?: string | null;
    usageCategory?: Array<UsageCategory> | null;
    size?: number | null;
    myStatus?: string | null;
    location?: any | null;
    address: { __typename?: 'Address'; city?: string | null };
    sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
    image?: {
      __typename?: 'File';
      url: string;
      name: string;
      id: string;
    } | null;
    landlordContactPersons: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      image?: {
        __typename?: 'File';
        url: string;
        id: string;
        name: string;
      } | null;
    }>;
    landlord?: {
      __typename?: 'Client';
      id: string;
      name: string;
      image?: { __typename?: 'File'; url: string; name: string } | null;
    } | null;
    integrationProvider?: {
      __typename?: 'IntegrationProvider';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
    matchingSearchRequests?: {
      __typename?: 'MatchingSearchRequests';
      count: number;
    } | null;
  }>;
};

export type GetConversationsMessageLinkQueryVariables = Exact<{
  filter?: InputMaybe<ConversationFilter>;
}>;

export type GetConversationsMessageLinkQuery = {
  __typename?: 'Query';
  conversations?: {
    __typename?: 'ConversationData';
    unread?: number | null;
    count?: number | null;
    conversations: Array<{
      __typename?: 'Message';
      conversation: {
        __typename?: 'Conversation';
        isPartner?: boolean | null;
        id: string;
        unread?: {
          __typename?: 'Unread';
          count?: number | null;
          attachments?: number | null;
          messages?: number | null;
        } | null;
      };
    } | null>;
  } | null;
};

export type GetMySearchRequestByReferenceIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetMySearchRequestByReferenceIdQuery = {
  __typename?: 'Query';
  mySearchRequestByReferenceId?: {
    __typename?: 'SearchRequest';
    status: StatusEnum;
    id: string;
    created: any;
    disabled?: boolean | null;
    step: SearchRequestStep;
    answeredDate?: any | null;
    stepWeight: number;
    conversationId?: string | null;
    comments: number;
    conversation?: {
      __typename?: 'Conversation';
      id: string;
      unread?: { __typename?: 'Unread'; count?: number | null } | null;
    } | null;
    searchRequestReference: {
      __typename?: 'SearchRequestReference';
      id: string;
      title?: string | null;
      status: SearchRequestStatusEnum;
      disabledDate?: any | null;
      disabledReason?: {
        __typename?: 'DisableSearchRequestReason';
        reason: DisableSearchRequestReasonEnum;
      } | null;
      client?: { __typename?: 'Client'; id: string; name: string } | null;
      searchParameters: {
        __typename?: 'SearchParameters';
        usageCategories: Array<string>;
        size?: {
          __typename?: 'Range';
          min?: number | null;
          max?: number | null;
        } | null;
      };
    };
    county?: { __typename?: 'Region'; id: string; name: string } | null;
    responsibleUser?: { __typename?: 'User'; id: string; name: string } | null;
  } | null;
};

export type GetNotificationsQueryVariables = Exact<{
  limit: Scalars['Int'];
  skip: Scalars['Int'];
}>;

export type GetNotificationsQuery = {
  __typename?: 'Query';
  notifications: {
    __typename?: 'NotificationData';
    count: number;
    notifications: Array<{
      __typename?: 'Notification';
      id: string;
      searchRequestReferenceId?: string | null;
      leaseAgreementId?: string | null;
      timestamp: any;
      read?: boolean | null;
      link: string;
      type: string;
      message: { __typename?: 'NotificationMessage'; en: string; sv: string };
    }>;
  };
};

export type NotificationValuesFragment = {
  __typename?: 'Notification';
  id: string;
  searchRequestReferenceId?: string | null;
  leaseAgreementId?: string | null;
  timestamp: any;
  read?: boolean | null;
  link: string;
  type: string;
  message: { __typename?: 'NotificationMessage'; en: string; sv: string };
};

export type SubscribeToNotificationsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type SubscribeToNotificationsSubscription = {
  __typename?: 'Subscription';
  newNotification?: {
    __typename?: 'Notification';
    id: string;
    searchRequestReferenceId?: string | null;
    leaseAgreementId?: string | null;
    timestamp: any;
    read?: boolean | null;
    link: string;
    type: string;
    message: { __typename?: 'NotificationMessage'; en: string; sv: string };
  } | null;
};

export type ReadNotificationMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type ReadNotificationMutation = {
  __typename?: 'Mutation';
  readNotification?: {
    __typename?: 'Notification';
    id: string;
    searchRequestReferenceId?: string | null;
    leaseAgreementId?: string | null;
    timestamp: any;
    read?: boolean | null;
    link: string;
    type: string;
    message: { __typename?: 'NotificationMessage'; en: string; sv: string };
  } | null;
};

export type GetPartnersQueryVariables = Exact<{ [key: string]: never }>;

export type GetPartnersQuery = {
  __typename?: 'Query';
  partners: Array<{
    __typename?: 'Client';
    id: string;
    slug?: string | null;
    name: string;
    image?: { __typename?: 'File'; url: string; id: string } | null;
  }>;
};

export type ClickAdMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ClickAdMutation = { __typename?: 'Mutation'; clickAd: boolean };

export type GetAdQueryVariables = Exact<{
  width: Scalars['Int'];
  height: Scalars['Int'];
  filter: AdFilter;
}>;

export type GetAdQuery = {
  __typename?: 'Query';
  portallyAd?: {
    __typename?: 'PortallyAd';
    id: string;
    placement: AdPlacement;
    link?: string | null;
    image: { __typename?: 'File'; url: string };
    partner: { __typename?: 'PortallyPartner'; slug: string };
  } | null;
};

export type ViewAdMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ViewAdMutation = { __typename?: 'Mutation'; viewAd: boolean };

export type ChangeStatusPromotionMutationVariables = Exact<{
  input: PromotionStatus;
}>;

export type ChangeStatusPromotionMutation = {
  __typename?: 'Mutation';
  changeStatusPromotion?: {
    __typename?: 'Promoted';
    id: string;
    opened: boolean;
    status?: string | null;
    client?: { __typename?: 'Client'; id: string; name: string } | null;
    leaseAgreement: {
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      address: { __typename?: 'Address'; city?: string | null };
    };
  } | null;
};

export type GetOfferQueryVariables = Exact<{
  clientId: Scalars['String'];
  leaseAgreementId: Scalars['String'];
}>;

export type GetOfferQuery = {
  __typename?: 'Query';
  singlePromoted?: {
    __typename?: 'Promoted';
    id: string;
    opened: boolean;
    status?: string | null;
    client?: { __typename?: 'Client'; id: string; name: string } | null;
    owner?: { __typename?: 'Client'; id: string; name: string } | null;
    leaseAgreement: {
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      address: { __typename?: 'Address'; city?: string | null };
    };
  } | null;
};

export type AddProspectMutationVariables = Exact<{
  input: ProspectInput;
}>;

export type AddProspectMutation = {
  __typename?: 'Mutation';
  addProspect: {
    __typename?: 'Prospect';
    id: string;
    name?: string | null;
    clientId?: string | null;
    contactId?: string | null;
    potentialValue?: number | null;
    startDate?: any | null;
    service?: string | null;
    division?: string | null;
    created?: any | null;
    step?: string | null;
    comment?: string | null;
    sourceComment?: string | null;
    source?: Array<string> | null;
    clientManager?: { __typename?: 'User'; name: string; id: string } | null;
  };
};

export type DeleteProspectMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteProspectMutation = {
  __typename?: 'Mutation';
  deleteProspect: { __typename?: 'Prospect'; id: string };
};

export type EditProspectMutationVariables = Exact<{
  id: Scalars['String'];
  input?: InputMaybe<ProspectInput>;
}>;

export type EditProspectMutation = {
  __typename?: 'Mutation';
  editProspect: {
    __typename?: 'Prospect';
    id: string;
    name?: string | null;
    clientId?: string | null;
    contactId?: string | null;
    division?: string | null;
    potentialValue?: number | null;
    startDate?: any | null;
    service?: string | null;
    step?: string | null;
    comment?: string | null;
    source?: Array<string> | null;
    sourceComment?: string | null;
    clientManager?: { __typename?: 'User'; name: string; id: string } | null;
  };
};

export type RefetchStatusQueryVariables = Exact<{ [key: string]: never }>;

export type RefetchStatusQuery = {
  __typename?: 'Query';
  status?: Array<{
    __typename?: 'Status';
    status?: string | null;
    id?: string | null;
    leaseAgreement?: {
      __typename?: 'LeaseAgreement';
      id: string;
      myStatus?: string | null;
    } | null;
  } | null> | null;
};

export type GetSearchProfilesClientQueryVariables = Exact<{
  filter: SearchProfileFilterInput;
}>;

export type GetSearchProfilesClientQuery = {
  __typename?: 'Query';
  searchProfiles: {
    __typename?: 'SearchProfileData';
    count: number;
    searchProfiles: Array<{
      __typename?: 'SearchProfile';
      id: string;
      name: string;
      clientId?: string | null;
      size?: {
        __typename?: 'Range';
        min?: number | null;
        max?: number | null;
      } | null;
    }>;
  };
};

export type GetPotentialSearchRequestReceiversQueryVariables = Exact<{
  id: Scalars['String'];
  showAll: Scalars['Boolean'];
}>;

export type GetPotentialSearchRequestReceiversQuery = {
  __typename?: 'Query';
  searchRequestReference: {
    __typename?: 'SearchRequestReference';
    receivers: {
      __typename?: 'UserData';
      count: number;
      users: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        firstName: string;
        access?: any | null;
        activeClient?: {
          __typename?: 'Client';
          id: string;
          name: string;
        } | null;
        emailSettings: {
          __typename?: 'EmailSettings';
          enable: boolean;
          onSearchRequest: boolean;
        };
        searchRequestSettings?: {
          __typename?: 'SearchRequestSettings';
          size: Array<SizeFilter>;
          usageCategories: Array<string>;
          regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
        } | null;
        receivedEmails: Array<{
          __typename?: 'EmailLog';
          date: any;
          template?: string | null;
          searchRequest?: {
            __typename?: 'SearchRequest';
            id: string;
            searchRequestReference: {
              __typename?: 'SearchRequestReference';
              id: string;
              title?: string | null;
            };
          } | null;
        }>;
      }>;
    };
  };
};

export type GetSearchRequestReceiversAdminQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetSearchRequestReceiversAdminQuery = {
  __typename?: 'Query';
  searchRequestReference: {
    __typename?: 'SearchRequestReference';
    searchRequests: {
      __typename?: 'SearchRequestData';
      count?: number | null;
      searchRequests: Array<{
        __typename?: 'SearchRequest';
        id: string;
        searchRequestReferenceId: string;
        status: StatusEnum;
        receiver: { __typename?: 'Client'; id: string; name: string };
        responsibleUser?: {
          __typename?: 'User';
          id: string;
          name: string;
          firstName: string;
          access?: any | null;
          emailSettings: {
            __typename?: 'EmailSettings';
            enable: boolean;
            onSearchRequest: boolean;
          };
          searchRequestSettings?: {
            __typename?: 'SearchRequestSettings';
            size: Array<SizeFilter>;
            usageCategories: Array<string>;
            regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
          } | null;
          receivedEmails: Array<{
            __typename?: 'EmailLog';
            date: any;
            template?: string | null;
            searchRequest?: {
              __typename?: 'SearchRequest';
              id: string;
              searchRequestReference: {
                __typename?: 'SearchRequestReference';
                id: string;
                title?: string | null;
              };
            } | null;
          }>;
        } | null;
      }>;
    };
  };
};

export type GetSearchRequestReferenceAdminQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetSearchRequestReferenceAdminQuery = {
  __typename?: 'Query';
  searchRequestReference: {
    __typename?: 'SearchRequestReference';
    id: string;
    sentDate: any;
    adminStatus?: AdminSearchRequestReferenceStatus | null;
    title?: string | null;
    description?: string | null;
    areaInformation?: string | null;
    specificNeedsInformation?: string | null;
    businessInformation?: string | null;
    budget?: number | null;
    employees?: number | null;
    accessV2?: AccessEnumV2 | null;
    clientId?: string | null;
    status: SearchRequestStatusEnum;
    key: string;
    adminResponsibleUser?: {
      __typename?: 'User';
      id: string;
      name: string;
    } | null;
    budgetSpan?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
    employeesSpan?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
    access?: { __typename?: 'Access'; quarter: Quarter; year: string } | null;
    contactPerson?: { __typename?: 'User'; id: string; name: string } | null;
    searchParameters: {
      __typename?: 'SearchParameters';
      geometry?: any | null;
      regionIds?: Array<string> | null;
      usageCategories: Array<string>;
      bounds?: {
        __typename?: 'RegionBounds';
        north: number;
        south: number;
        east: number;
        west: number;
      } | null;
      regions: Array<{
        __typename?: 'Region';
        id: string;
        name: string;
        geometry?: any | null;
        regionType?: RegionTypeEnum | null;
        center?: any | null;
      }>;
      size?: {
        __typename?: 'Range';
        max?: number | null;
        min?: number | null;
      } | null;
    };
    client?: {
      __typename?: 'Client';
      id: string;
      name: string;
      ebieScore?: {
        __typename?: 'EbieScore';
        rating: number;
        tier: string;
      } | null;
    } | null;
    documents: Array<{
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    }>;
    disabledReason?: {
      __typename?: 'DisableSearchRequestReason';
      reason: DisableSearchRequestReasonEnum;
    } | null;
  };
};

export type SendSearchRequestToUserMutationVariables = Exact<{
  input: SendSearchRequestToUserInput;
}>;

export type SendSearchRequestToUserMutation = {
  __typename?: 'Mutation';
  sendSearchRequestToUser: boolean;
};

export type UpdateAdminResponsibleUserIdMutationVariables = Exact<{
  id: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
}>;

export type UpdateAdminResponsibleUserIdMutation = {
  __typename?: 'Mutation';
  updateAdminResponsibleUser: {
    __typename?: 'SearchRequestReference';
    id: string;
    adminResponsibleUser?: {
      __typename?: 'User';
      id: string;
      name: string;
    } | null;
  };
};

export type UpdateAdminStatusMutationVariables = Exact<{
  id: Scalars['String'];
  status?: InputMaybe<AdminSearchRequestReferenceStatus>;
}>;

export type UpdateAdminStatusMutation = {
  __typename?: 'Mutation';
  updateAdminStatus: {
    __typename?: 'SearchRequestReference';
    id: string;
    adminStatus?: AdminSearchRequestReferenceStatus | null;
  };
};

export type UserReceiverValuesFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  firstName: string;
  access?: any | null;
  emailSettings: {
    __typename?: 'EmailSettings';
    enable: boolean;
    onSearchRequest: boolean;
  };
  searchRequestSettings?: {
    __typename?: 'SearchRequestSettings';
    size: Array<SizeFilter>;
    usageCategories: Array<string>;
    regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
  } | null;
  receivedEmails: Array<{
    __typename?: 'EmailLog';
    date: any;
    template?: string | null;
    searchRequest?: {
      __typename?: 'SearchRequest';
      id: string;
      searchRequestReference: {
        __typename?: 'SearchRequestReference';
        id: string;
        title?: string | null;
      };
    } | null;
  }>;
};

export type AnswerSearchRequestMutationVariables = Exact<{
  searchRequestReferenceId: Scalars['String'];
  message: Scalars['String'];
  status: StatusEnum;
  leaseAgreementIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  attachmentIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type AnswerSearchRequestMutation = {
  __typename?: 'Mutation';
  answerSearchRequest: {
    __typename?: 'SearchRequest';
    id: string;
    status: StatusEnum;
    conversationId?: string | null;
  };
};

export type AnsweredByValuesFragment = {
  __typename?: 'SearchRequestData';
  searchRequests: Array<{
    __typename?: 'SearchRequest';
    id: string;
    conversationId?: string | null;
    status: StatusEnum;
    responsibleUser?: {
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
  }>;
};

export type GetSearchRequestLandlordQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetSearchRequestLandlordQuery = {
  __typename?: 'Query';
  searchRequestReference: {
    __typename?: 'SearchRequestReference';
    myStatus: StatusEnum;
    id: string;
    title?: string | null;
    description?: string | null;
    areaInformation?: string | null;
    specificNeedsInformation?: string | null;
    businessInformation?: string | null;
    budget?: number | null;
    employees?: number | null;
    accessV2?: AccessEnumV2 | null;
    sentDate: any;
    clientId?: string | null;
    status: SearchRequestStatusEnum;
    key: string;
    searchRequests: {
      __typename?: 'SearchRequestData';
      searchRequests: Array<{
        __typename?: 'SearchRequest';
        id: string;
        conversationId?: string | null;
        status: StatusEnum;
        responsibleUser?: {
          __typename?: 'User';
          id: string;
          name: string;
          image?: { __typename?: 'File'; id: string; url: string } | null;
        } | null;
      }>;
    };
    budgetSpan?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
    employeesSpan?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
    access?: { __typename?: 'Access'; quarter: Quarter; year: string } | null;
    contactPerson?: { __typename?: 'User'; id: string; name: string } | null;
    searchParameters: {
      __typename?: 'SearchParameters';
      geometry?: any | null;
      regionIds?: Array<string> | null;
      usageCategories: Array<string>;
      bounds?: {
        __typename?: 'RegionBounds';
        north: number;
        south: number;
        east: number;
        west: number;
      } | null;
      regions: Array<{
        __typename?: 'Region';
        id: string;
        name: string;
        geometry?: any | null;
        regionType?: RegionTypeEnum | null;
        center?: any | null;
      }>;
      size?: {
        __typename?: 'Range';
        max?: number | null;
        min?: number | null;
      } | null;
    };
    client?: {
      __typename?: 'Client';
      id: string;
      name: string;
      ebieScore?: {
        __typename?: 'EbieScore';
        rating: number;
        tier: string;
      } | null;
    } | null;
    documents: Array<{
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    }>;
    disabledReason?: {
      __typename?: 'DisableSearchRequestReason';
      reason: DisableSearchRequestReasonEnum;
    } | null;
  };
};

export type AddSearchRequestMutationVariables = Exact<{
  information: SearchRequestInformationInput;
  area: SearchRequestAreaInput;
}>;

export type AddSearchRequestMutation = {
  __typename?: 'Mutation';
  searchRequestReference: {
    __typename?: 'SearchResult';
    searchRequestReference: {
      __typename?: 'SearchRequestReference';
      id: string;
    };
  };
};

export type DeleteSearchRequestMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteSearchRequestMutation = {
  __typename?: 'Mutation';
  deleteSearchRequestReference: {
    __typename?: 'SearchRequestReference';
    id: string;
  };
};

export type EditSearchRequestReferenceMutationVariables = Exact<{
  id: Scalars['String'];
  information: SearchRequestInformationInput;
  contactInformation?: InputMaybe<ContactInformationInput>;
}>;

export type EditSearchRequestReferenceMutation = {
  __typename?: 'Mutation';
  editSearchRequestReference: {
    __typename?: 'SearchRequestReference';
    id: string;
    title?: string | null;
    description?: string | null;
    areaInformation?: string | null;
    businessInformation?: string | null;
    specificNeedsInformation?: string | null;
    sentDate: any;
    budget?: number | null;
    employees?: number | null;
    accessV2?: AccessEnumV2 | null;
    status: SearchRequestStatusEnum;
    improveSearchMailSent?: boolean | null;
    fromWebsite: boolean;
    clientId?: string | null;
    key: string;
    searchParameters: {
      __typename?: 'SearchParameters';
      usageCategories: Array<string>;
      geometry?: any | null;
      regionIds?: Array<string> | null;
      size?: {
        __typename?: 'Range';
        min?: number | null;
        max?: number | null;
      } | null;
      bounds?: {
        __typename?: 'RegionBounds';
        north: number;
        south: number;
        east: number;
        west: number;
      } | null;
      regions: Array<{
        __typename?: 'Region';
        id: string;
        name: string;
        geometry?: any | null;
        regionType?: RegionTypeEnum | null;
        center?: any | null;
      }>;
    };
    client?: {
      __typename?: 'Client';
      id: string;
      name: string;
      type?: ClientType | null;
      group?: string | null;
      email?: string | null;
      telephone?: string | null;
      orgNumber?: string | null;
      imageId?: string | null;
      companyName?: string | null;
      address?: string | null;
      location?: string | null;
      website?: string | null;
      parentCompany?: string | null;
      parentCompanyOrgNumber?: string | null;
      responsibleConsultantId?: string | null;
      comment?: string | null;
      description?: string | null;
      numberOfEmployees?: number | null;
      numberOfUnits?: number | null;
      industryCategory?: string | null;
      industryCode?: string | null;
      typeOfBusiness?: TypeOfBusiness | null;
      showOnWebsite?: boolean | null;
      notifySupport?: boolean | null;
      supportStatus?: string | null;
      vitecLicenceIds: Array<string>;
      objektvisionId?: string | null;
      objektvisionIds: Array<string>;
      includeObjektvisionEmails: Array<string>;
      lokalguidenId?: string | null;
      showPageOnWebsite?: boolean | null;
      slug?: string | null;
      websiteName?: string | null;
      limeApiKey?: string | null;
      isPartner?: boolean | null;
      websiteDescription?: string | null;
      ebieScore?: {
        __typename?: 'EbieScore';
        rating: number;
        tier: string;
      } | null;
      image?: {
        __typename?: 'File';
        id: string;
        name: string;
        url: string;
        size: number;
      } | null;
      postAddress?: {
        __typename?: 'Address';
        address?: string | null;
        street?: string | null;
        streetNumber?: string | null;
        zipCode?: string | null;
        city?: string | null;
      } | null;
      visitingAddress?: {
        __typename?: 'Address';
        address?: string | null;
        street?: string | null;
        streetNumber?: string | null;
        zipCode?: string | null;
        city?: string | null;
      } | null;
      responsibleConsultant?: { __typename?: 'User'; id: string } | null;
      estimatedSize?: {
        __typename?: 'Range';
        min?: number | null;
        max?: number | null;
      } | null;
      invoiceDetails?: {
        __typename?: 'InvoiceDetails';
        billing?: string | null;
        company?: string | null;
        marking?: string | null;
        reference?: string | null;
        email?: string | null;
      } | null;
      websiteImage?: {
        __typename?: 'File';
        id: string;
        name: string;
        url: string;
        size: number;
      } | null;
    } | null;
    contactPerson?: {
      __typename?: 'User';
      id: string;
      email: string;
      verified: boolean;
      access?: any | null;
      name: string;
      leaseAgreementViews: {
        __typename?: 'LeaseAgreementViewData';
        count: number;
        views: Array<{
          __typename?: 'LeaseAgreementView';
          id: string;
          created: any;
          fromWebsite?: boolean | null;
          user?: {
            __typename?: 'User';
            id: string;
            name: string;
            activeClient?: {
              __typename?: 'Client';
              id: string;
              name: string;
            } | null;
          } | null;
          leaseAgreement?: {
            __typename?: 'LeaseAgreement';
            id: string;
            name: string;
            size?: number | null;
            title?: string | null;
            usageCategory?: Array<UsageCategory> | null;
            landlord?: {
              __typename?: 'Client';
              id: string;
              name: string;
            } | null;
            address: {
              __typename?: 'Address';
              street?: string | null;
              streetNumber?: string | null;
              city?: string | null;
              zipCode?: string | null;
            };
            sizeSpan?: {
              __typename?: 'SizeSpan';
              from: number;
              to: number;
            } | null;
            image?: {
              __typename?: 'File';
              id: string;
              url: string;
              size: number;
              uid: string;
            } | null;
          } | null;
        }>;
      };
    } | null;
    budgetSpan?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
    employeesSpan?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
    regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
    access?: { __typename?: 'Access'; quarter: Quarter; year: string } | null;
    contactInformation?: {
      __typename?: 'ContactInformation';
      email?: string | null;
      phone?: string | null;
      company?: string | null;
    } | null;
    documents: Array<{
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    }>;
    disabledReason?: {
      __typename?: 'DisableSearchRequestReason';
      reason: DisableSearchRequestReasonEnum;
    } | null;
  };
};

export type GetBoundsAndPolygonsQueryVariables = Exact<{
  search?: InputMaybe<SearchProfileInput>;
  bounds?: InputMaybe<Bounds>;
  zoom?: InputMaybe<Scalars['Int']>;
}>;

export type GetBoundsAndPolygonsQuery = {
  __typename?: 'Query';
  leaseAgreements: {
    __typename?: 'LeaseAgreementsResult';
    count: number;
    regionPolygons: Array<any | null>;
    regionBounds?: {
      __typename?: 'ResultBounds';
      south: number;
      north: number;
      east: number;
      west: number;
    } | null;
  };
};

export type GetMyLeaseAgreementsQueryVariables = Exact<{
  input?: InputMaybe<LeaseAgreementFilter>;
  search?: InputMaybe<SearchProfileInput>;
  zoom: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  bounds?: InputMaybe<Bounds>;
}>;

export type GetMyLeaseAgreementsQuery = {
  __typename?: 'Query';
  leaseAgreements: {
    __typename?: 'LeaseAgreementsResult';
    count: number;
    items: Array<{
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      myStatus?: string | null;
      location?: any | null;
      size?: number | null;
      title?: string | null;
      description?: string | null;
      usageCategory?: Array<UsageCategory> | null;
      landlordId?: string | null;
      tenantId?: string | null;
      estateId?: string | null;
      imageIds: Array<string>;
      documentIds: Array<string>;
      landlordContactPersonIds: Array<string>;
      tenantContactPersonIds?: Array<string> | null;
      vitecGuid?: string | null;
      areaDescription?: string | null;
      rent?: number | null;
      access?: string | null;
      projectId?: string | null;
      prioritized?: boolean | null;
      address: {
        __typename?: 'Address';
        street?: string | null;
        streetNumber?: string | null;
        city?: string | null;
        zipCode?: string | null;
      };
      sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
      landlord?: {
        __typename?: 'Client';
        name: string;
        id: string;
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
      tenant?: { __typename?: 'Client'; name: string; id: string } | null;
      estate?: {
        __typename?: 'Estate';
        id: string;
        name?: string | null;
      } | null;
      video?: {
        __typename?: 'Video';
        videoId?: string | null;
        thumbnailId?: string | null;
        video?: {
          __typename?: 'File';
          id: string;
          url: string;
          name: string;
          size: number;
          uid: string;
        } | null;
        thumbnail?: {
          __typename?: 'File';
          id: string;
          url: string;
          name: string;
          size: number;
          uid: string;
        } | null;
      } | null;
      images: Array<{
        __typename?: 'File';
        url: string;
        name: string;
        id: string;
        size: number;
        uid: string;
      }>;
      documents: Array<{
        __typename?: 'File';
        id: string;
        name: string;
        size: number;
        url: string;
        mimetype: string;
        uid: string;
      }>;
      landlordContactPersons: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        image?: { __typename?: 'File'; id: string; url: string } | null;
        activeClient?: {
          __typename?: 'Client';
          image?: { __typename?: 'File'; id: string; url: string } | null;
        } | null;
      }>;
      tenantContactPersons?: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        image?: { __typename?: 'File'; id: string; url: string } | null;
        activeClient?: {
          __typename?: 'Client';
          image?: { __typename?: 'File'; id: string; url: string } | null;
        } | null;
      }> | null;
      links: Array<{
        __typename?: 'LeaseAgreementLink';
        title?: string | null;
        url?: string | null;
        category?: string | null;
      } | null>;
      nearbyServices?: {
        __typename?: 'NearbyServices';
        bus_station?: {
          __typename?: 'NearbyService';
          name?: string | null;
          distance?: number | null;
        } | null;
        train_station?: {
          __typename?: 'NearbyService';
          name?: string | null;
          distance?: number | null;
        } | null;
        subway_station?: {
          __typename?: 'NearbyService';
          name?: string | null;
          distance?: number | null;
        } | null;
        parking?: {
          __typename?: 'NearbyService';
          name?: string | null;
          distance?: number | null;
        } | null;
        gym?: {
          __typename?: 'NearbyService';
          name?: string | null;
          distance?: number | null;
        } | null;
        supermarket?: {
          __typename?: 'NearbyService';
          name?: string | null;
          distance?: number | null;
        } | null;
      } | null;
      fee?: { __typename?: 'Fee'; feeType: FeeType; amount: number } | null;
      integrationProvider?: {
        __typename?: 'IntegrationProvider';
        id: string;
        name: string;
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
    }>;
  };
};

export type PromotedValuesFragment = {
  __typename?: 'Promoted';
  id: string;
  status?: string | null;
  key: string;
  owner?: { __typename?: 'Client'; id: string; name: string } | null;
  leaseAgreement: { __typename?: 'LeaseAgreement'; id: string; name: string };
};

export type AddPresetAnswerMutationVariables = Exact<{
  name: Scalars['String'];
  message: Scalars['String'];
}>;

export type AddPresetAnswerMutation = {
  __typename?: 'Mutation';
  addPresetAnswer: {
    __typename?: 'PresetAnswer';
    id: string;
    message: string;
    name: string;
  };
};

export type DeletePresetAnswerMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeletePresetAnswerMutation = {
  __typename?: 'Mutation';
  deletePresetAnswer: boolean;
};

export type GetMyPresetAnswersQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyPresetAnswersQuery = {
  __typename?: 'Query';
  myPresetAnswers: Array<{
    __typename?: 'PresetAnswer';
    id: string;
    message: string;
    name: string;
  }>;
};

export type DisableSearchRequestMutationVariables = Exact<{
  searchRequestReferenceId: Scalars['String'];
  reason: DisableSearchRequestInput;
  token?: InputMaybe<Scalars['String']>;
}>;

export type DisableSearchRequestMutation = {
  __typename?: 'Mutation';
  disableSearchRequest?: boolean | null;
};

export type GetPotentialClientsQueryVariables = Exact<{
  clientId: Scalars['String'];
  searchRequestReferenceId?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
}>;

export type GetPotentialClientsQuery = {
  __typename?: 'Query';
  potentialClients: {
    __typename?: 'ClientData';
    count: number;
    clients: Array<{ __typename?: 'Client'; id: string; name: string }>;
  };
};

export type AddSearchRequestCommentMutationVariables = Exact<{
  id: Scalars['String'];
  input: SearchRequestCommentInput;
}>;

export type AddSearchRequestCommentMutation = {
  __typename?: 'Mutation';
  addSearchRequestComment: {
    __typename?: 'SearchRequestComment';
    id: string;
    comment: string;
    created?: any | null;
    user: {
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    };
  };
};

export type DeleteSearchRequestCommentMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteSearchRequestCommentMutation = {
  __typename?: 'Mutation';
  deleteSearchRequestComment: {
    __typename?: 'SearchRequestComment';
    id: string;
    comment: string;
    created?: any | null;
    user: {
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    };
  };
};

export type GetSearchRequestCommentsQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetSearchRequestCommentsQuery = {
  __typename?: 'Query';
  searchRequestComments: Array<{
    __typename?: 'SearchRequestComment';
    id: string;
    comment: string;
    created?: any | null;
    user: {
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    };
  }>;
};

export type SearchRequestCommentValuesFragment = {
  __typename?: 'SearchRequestComment';
  id: string;
  comment: string;
  created?: any | null;
  user: {
    __typename?: 'User';
    id: string;
    name: string;
    image?: { __typename?: 'File'; id: string; url: string } | null;
  };
};

export type GetFilterCountiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetFilterCountiesQuery = {
  __typename?: 'Query';
  getRegions: {
    __typename?: 'RegionData';
    count: number;
    regions: Array<{
      __typename?: 'Region';
      id: string;
      name: string;
      geometry?: any | null;
      children: Array<{
        __typename?: 'Region';
        id: string;
        regionType?: RegionTypeEnum | null;
        name: string;
        geometry?: any | null;
      }>;
    }>;
  };
};

export type GetSearchRequestInformationQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetSearchRequestInformationQuery = {
  __typename?: 'Query';
  searchRequestReference: {
    __typename?: 'SearchRequestReference';
    id: string;
    title?: string | null;
    description?: string | null;
    areaInformation?: string | null;
    specificNeedsInformation?: string | null;
    businessInformation?: string | null;
    budget?: number | null;
    employees?: number | null;
    accessV2?: AccessEnumV2 | null;
    sentDate: any;
    clientId?: string | null;
    status: SearchRequestStatusEnum;
    key: string;
    budgetSpan?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
    employeesSpan?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
    access?: { __typename?: 'Access'; quarter: Quarter; year: string } | null;
    contactPerson?: { __typename?: 'User'; id: string; name: string } | null;
    searchParameters: {
      __typename?: 'SearchParameters';
      geometry?: any | null;
      regionIds?: Array<string> | null;
      usageCategories: Array<string>;
      bounds?: {
        __typename?: 'RegionBounds';
        north: number;
        south: number;
        east: number;
        west: number;
      } | null;
      regions: Array<{
        __typename?: 'Region';
        id: string;
        name: string;
        geometry?: any | null;
        regionType?: RegionTypeEnum | null;
        center?: any | null;
      }>;
      size?: {
        __typename?: 'Range';
        max?: number | null;
        min?: number | null;
      } | null;
    };
    client?: {
      __typename?: 'Client';
      id: string;
      name: string;
      ebieScore?: {
        __typename?: 'EbieScore';
        rating: number;
        tier: string;
      } | null;
    } | null;
    documents: Array<{
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    }>;
    disabledReason?: {
      __typename?: 'DisableSearchRequestReason';
      reason: DisableSearchRequestReasonEnum;
    } | null;
  };
};

export type GetCountiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountiesQuery = {
  __typename?: 'Query';
  getRegions: {
    __typename?: 'RegionData';
    count: number;
    regions: Array<{
      __typename?: 'Region';
      id: string;
      name: string;
      geometry?: any | null;
    }>;
  };
};

export type GetSearchRequestStatsQueryVariables = Exact<{
  usageCategories: Array<Scalars['String']> | Scalars['String'];
  regionIds: Array<Scalars['String']> | Scalars['String'];
  size: Array<SizeFilter> | SizeFilter;
}>;

export type GetSearchRequestStatsQuery = {
  __typename?: 'Query';
  mySearchRequestsLandlord?: {
    __typename?: 'SearchRequestLandlordData';
    accepted: { __typename?: 'SearchRequestData'; count?: number | null };
    declined: { __typename?: 'SearchRequestData'; count?: number | null };
    unanswered: { __typename?: 'SearchRequestReferenceData'; count: number };
  } | null;
};

export type GetConsultantsQueryVariables = Exact<{
  roles?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  isPortally?: InputMaybe<Scalars['Boolean']>;
  isSalesTeam?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetConsultantsQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserData';
    users: Array<{ __typename?: 'User'; id: string; name: string }>;
  };
};

export type GetUsersByClientQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['String']>;
}>;

export type GetUsersByClientQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserData';
    users: Array<{ __typename?: 'User'; id: string; name: string }>;
  };
};

export type GetMySelectLeaseAgreementsQueryVariables = Exact<{
  input?: InputMaybe<LeaseAgreementFilter>;
  zoom: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  bounds?: InputMaybe<Bounds>;
}>;

export type GetMySelectLeaseAgreementsQuery = {
  __typename?: 'Query';
  leaseAgreements: {
    __typename?: 'LeaseAgreementsResult';
    count: number;
    items: Array<{
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      size?: number | null;
      title?: string | null;
      usageCategory?: Array<UsageCategory> | null;
      address: {
        __typename?: 'Address';
        street?: string | null;
        streetNumber?: string | null;
        city?: string | null;
        zipCode?: string | null;
      };
      sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
      image?: {
        __typename?: 'File';
        id: string;
        url: string;
        size: number;
        uid: string;
      } | null;
    }>;
  };
};

export type LeaseAgreementListPreviewValuesFragment = {
  __typename?: 'LeaseAgreement';
  id: string;
  name: string;
  size?: number | null;
  title?: string | null;
  usageCategory?: Array<UsageCategory> | null;
  address: {
    __typename?: 'Address';
    street?: string | null;
    streetNumber?: string | null;
    city?: string | null;
    zipCode?: string | null;
  };
  sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
  image?: {
    __typename?: 'File';
    id: string;
    url: string;
    size: number;
    uid: string;
  } | null;
};

export type SearchRequestMatchValuesFragment = {
  __typename?: 'SearchRequest';
  searchParameters?: {
    __typename?: 'SearchParameters';
    regionIds?: Array<string> | null;
    usageCategories: Array<string>;
    size?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
  } | null;
};

export type SearchRequestReferenceMatchValuesFragment = {
  __typename?: 'SearchRequestReference';
  searchParameters: {
    __typename?: 'SearchParameters';
    regionIds?: Array<string> | null;
    usageCategories: Array<string>;
    size?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
  };
};

export type StreetAutocompleteQueryVariables = Exact<{
  key: Scalars['String'];
  clientId?: InputMaybe<Scalars['String']>;
}>;

export type StreetAutocompleteQuery = {
  __typename?: 'Query';
  streets: Array<{
    __typename?: 'AutocompleteRegions';
    id: string;
    value: string;
    type: string;
  }>;
};

export type ToggleShowMineMutationVariables = Exact<{
  id: Scalars['String'];
  showMine: Scalars['Boolean'];
}>;

export type ToggleShowMineMutation = {
  __typename?: 'Mutation';
  editUser?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName?: string | null;
    activeClientId?: string | null;
    email: string;
    role?: string | null;
    imageId?: string | null;
    limited?: boolean | null;
    showMine?: boolean | null;
    prospect?: boolean | null;
    companyName?: string | null;
    orgNumber?: string | null;
    phone?: string | null;
    disabled?: boolean | null;
    failedLogins?: number | null;
    onBoarded?: boolean | null;
    activeModal?: ModalEnum | null;
    jobTitle?: string | null;
    userCategory: Array<UserCategoryEnum>;
    activeClient?: {
      __typename?: 'Client';
      name: string;
      id: string;
      type?: ClientType | null;
    } | null;
    clients?: Array<{ __typename?: 'Client'; name: string; id: string }> | null;
    image?: {
      __typename?: 'File';
      id: string;
      name: string;
      url: string;
      size: number;
    } | null;
    trial?: {
      __typename?: 'Trial';
      startDate?: any | null;
      endDate?: any | null;
    } | null;
    emailSettings: {
      __typename?: 'EmailSettings';
      enable: boolean;
      onMessage: boolean;
      onMatch: boolean;
      onSearchHit: boolean;
      onIntresting: boolean;
      onSearchRequest: boolean;
      reminders: boolean;
    };
    searchRequestSettings?: {
      __typename?: 'SearchRequestSettings';
      regionIds: Array<string>;
      usageCategories: Array<string>;
      filterEmails?: boolean | null;
      size: Array<SizeFilter>;
    } | null;
  } | null;
};

export type AcceptSubscriptionRequestMutationVariables = Exact<{
  id: Scalars['String'];
  type?: InputMaybe<SubscriptionType>;
}>;

export type AcceptSubscriptionRequestMutation = {
  __typename?: 'Mutation';
  acceptSubscriptionRequest?: {
    __typename?: 'SubscriptionRequest';
    id: string;
    status: string;
    readByUserIds: Array<string>;
  } | null;
};

export type GetMySubscriptionRequestQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMySubscriptionRequestQuery = {
  __typename?: 'Query';
  mySubscriptionRequest?: {
    __typename?: 'SubscriptionRequest';
    id: string;
    status: string;
    type?: SubscriptionType | null;
    readByUserIds: Array<string>;
    openedByUserIds: Array<string>;
    acceptedDate?: any | null;
    version: SubscriptionVersion;
    acceptedByUser?: { __typename?: 'User'; id: string; name: string } | null;
  } | null;
};

export type ViewSubscriptionRequestMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type ViewSubscriptionRequestMutation = {
  __typename?: 'Mutation';
  viewSubscriptionRequest?: {
    __typename?: 'SubscriptionRequest';
    id: string;
    status: string;
  } | null;
};

export type AcceptTermsMutationVariables = Exact<{ [key: string]: never }>;

export type AcceptTermsMutation = {
  __typename?: 'Mutation';
  acceptTermsOfService: string;
};

export type GetCurrentTermsOfServiceQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCurrentTermsOfServiceQuery = {
  __typename?: 'Query';
  getCurrentTermsOfService?: {
    __typename?: 'TermsOfService';
    id: string;
    version?: string | null;
    published?: boolean | null;
    publishedDate?: any | null;
    content?: {
      __typename?: 'TranslatedContent';
      sv?: string | null;
      en?: string | null;
    } | null;
    privacy?: {
      __typename?: 'TranslatedContent';
      sv?: string | null;
      en?: string | null;
    } | null;
    cookiePolicy?: {
      __typename?: 'TranslatedContent';
      sv?: string | null;
      en?: string | null;
    } | null;
  } | null;
};

export type CompleteTourMutationVariables = Exact<{
  tour: Scalars['String'];
}>;

export type CompleteTourMutation = {
  __typename?: 'Mutation';
  completeTour: {
    __typename?: 'User';
    id: string;
    completedTours: Array<string>;
  };
};

export type DocumentValuesFragment = {
  __typename?: 'File';
  id: string;
  name: string;
  size: number;
  url: string;
  mimetype: string;
  uid: string;
};

export type UploadFileMutationVariables = Exact<{
  file: AddFileInput;
}>;

export type UploadFileMutation = {
  __typename?: 'Mutation';
  file: {
    __typename?: 'File';
    id: string;
    name: string;
    path: string;
    url: string;
    size: number;
  };
};

export type VerifyAccountMutationVariables = Exact<{
  email: Scalars['String'];
  company: Scalars['String'];
}>;

export type VerifyAccountMutation = {
  __typename?: 'Mutation';
  verifyAccount: {
    __typename?: 'User';
    id: string;
    name: string;
    email: string;
    verified: boolean;
    activeClient?: { __typename?: 'Client'; id: string; name: string } | null;
  };
};

export type GetLoggedInUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetLoggedInUserQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName?: string | null;
    name: string;
    email: string;
    isPartner: boolean;
    activeClientId?: string | null;
    clientIds?: Array<string> | null;
    role?: string | null;
    city?: string | null;
    showMine?: boolean | null;
    fromWebsite?: boolean | null;
    interestingPopconfirm?: boolean | null;
    completedTours: Array<string>;
    hasDealRequest: boolean;
    onBoarded?: boolean | null;
    activeModal?: ModalEnum | null;
    verified: boolean;
    activeClient?: {
      __typename?: 'Client';
      id: string;
      hasCommission?: boolean | null;
      name: string;
      type?: ClientType | null;
      termsOfService?: {
        __typename?: 'Terms';
        confirmed?: boolean | null;
        confirmedDate?: any | null;
      } | null;
    } | null;
    clients?: Array<{ __typename?: 'Client'; id: string; name: string }> | null;
    image?: { __typename?: 'File'; id: string; url: string } | null;
    estates?: Array<{
      __typename?: 'Estate';
      id: string;
      name?: string | null;
    }> | null;
    trial?: {
      __typename?: 'Trial';
      startDate?: any | null;
      endDate?: any | null;
    } | null;
    searchRequestSettings?: {
      __typename?: 'SearchRequestSettings';
      regionIds: Array<string>;
      usageCategories: Array<string>;
      filterEmails?: boolean | null;
      size: Array<SizeFilter>;
    } | null;
    activeSubscription?: {
      __typename?: 'SubscriptionRequest';
      id: string;
      version: SubscriptionVersion;
      type?: SubscriptionType | null;
    } | null;
  } | null;
};

export type GetActiveSearchRequestNotificationsQueryVariables = Exact<{
  filter: ActiveSearchRequestFilter;
}>;

export type GetActiveSearchRequestNotificationsQuery = {
  __typename?: 'Query';
  myActiveSearchRequests: {
    __typename?: 'ActiveSearchRequestData';
    notifications: number;
  };
};

export type GetUnreadProspectsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUnreadProspectsQuery = {
  __typename?: 'Query';
  leaseAgreementProspects: {
    __typename?: 'LeaseAgreementProspectData';
    unread: number;
  };
};

export type AddSearchRequestCommentLandlordMutationVariables = Exact<{
  searchRequestId: Scalars['String'];
  input: SearchRequestCommentInput;
}>;

export type AddSearchRequestCommentLandlordMutation = {
  __typename?: 'Mutation';
  addSearchRequestCommentLandlord: {
    __typename?: 'SearchRequestComment';
    id: string;
    comment: string;
    created?: any | null;
    user: {
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    };
  };
};

export type ArchiveSearchRequestMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type ArchiveSearchRequestMutation = {
  __typename?: 'Mutation';
  archiveSearchRequest: { __typename?: 'SearchRequest'; id: string };
};

export type DeleteSearchRequestCommentLandlordMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteSearchRequestCommentLandlordMutation = {
  __typename?: 'Mutation';
  deleteSearchRequestCommentLandlord: {
    __typename?: 'SearchRequestComment';
    id: string;
    comment: string;
    created?: any | null;
    user: {
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    };
  };
};

export type GetMyActiveSearchRequestsQueryVariables = Exact<{
  showArchived: Scalars['Boolean'];
  filter: ActiveSearchRequestFilter;
  todoLimit: Scalars['Int'];
  interestingLimit?: InputMaybe<Scalars['Int']>;
}>;

export type GetMyActiveSearchRequestsQuery = {
  __typename?: 'Query';
  myActiveSearchRequests: {
    __typename?: 'ActiveSearchRequestData';
    todo: {
      __typename?: 'SearchRequestData';
      count?: number | null;
      searchRequests: Array<{
        __typename?: 'SearchRequest';
        id: string;
        created: any;
        disabled?: boolean | null;
        step: SearchRequestStep;
        answeredDate?: any | null;
        stepWeight: number;
        conversationId?: string | null;
        comments: number;
        conversation?: {
          __typename?: 'Conversation';
          id: string;
          unread?: { __typename?: 'Unread'; count?: number | null } | null;
        } | null;
        searchRequestReference: {
          __typename?: 'SearchRequestReference';
          id: string;
          title?: string | null;
          status: SearchRequestStatusEnum;
          disabledDate?: any | null;
          disabledReason?: {
            __typename?: 'DisableSearchRequestReason';
            reason: DisableSearchRequestReasonEnum;
          } | null;
          client?: { __typename?: 'Client'; id: string; name: string } | null;
          searchParameters: {
            __typename?: 'SearchParameters';
            usageCategories: Array<string>;
            size?: {
              __typename?: 'Range';
              min?: number | null;
              max?: number | null;
            } | null;
          };
        };
        county?: { __typename?: 'Region'; id: string; name: string } | null;
        responsibleUser?: {
          __typename?: 'User';
          id: string;
          name: string;
        } | null;
      }>;
    };
    interesting: {
      __typename?: 'SearchRequestData';
      count?: number | null;
      searchRequests: Array<{
        __typename?: 'SearchRequest';
        id: string;
        created: any;
        disabled?: boolean | null;
        step: SearchRequestStep;
        answeredDate?: any | null;
        stepWeight: number;
        conversationId?: string | null;
        comments: number;
        conversation?: {
          __typename?: 'Conversation';
          id: string;
          unread?: { __typename?: 'Unread'; count?: number | null } | null;
        } | null;
        searchRequestReference: {
          __typename?: 'SearchRequestReference';
          id: string;
          title?: string | null;
          status: SearchRequestStatusEnum;
          disabledDate?: any | null;
          disabledReason?: {
            __typename?: 'DisableSearchRequestReason';
            reason: DisableSearchRequestReasonEnum;
          } | null;
          client?: { __typename?: 'Client'; id: string; name: string } | null;
          searchParameters: {
            __typename?: 'SearchParameters';
            usageCategories: Array<string>;
            size?: {
              __typename?: 'Range';
              min?: number | null;
              max?: number | null;
            } | null;
          };
        };
        county?: { __typename?: 'Region'; id: string; name: string } | null;
        responsibleUser?: {
          __typename?: 'User';
          id: string;
          name: string;
        } | null;
      }>;
    };
    inProgress: {
      __typename?: 'SearchRequestData';
      searchRequests: Array<{
        __typename?: 'SearchRequest';
        id: string;
        created: any;
        disabled?: boolean | null;
        step: SearchRequestStep;
        answeredDate?: any | null;
        stepWeight: number;
        conversationId?: string | null;
        comments: number;
        conversation?: {
          __typename?: 'Conversation';
          id: string;
          unread?: { __typename?: 'Unread'; count?: number | null } | null;
        } | null;
        searchRequestReference: {
          __typename?: 'SearchRequestReference';
          id: string;
          title?: string | null;
          status: SearchRequestStatusEnum;
          disabledDate?: any | null;
          disabledReason?: {
            __typename?: 'DisableSearchRequestReason';
            reason: DisableSearchRequestReasonEnum;
          } | null;
          client?: { __typename?: 'Client'; id: string; name: string } | null;
          searchParameters: {
            __typename?: 'SearchParameters';
            usageCategories: Array<string>;
            size?: {
              __typename?: 'Range';
              min?: number | null;
              max?: number | null;
            } | null;
          };
        };
        county?: { __typename?: 'Region'; id: string; name: string } | null;
        responsibleUser?: {
          __typename?: 'User';
          id: string;
          name: string;
        } | null;
      }>;
    };
    negotiation: {
      __typename?: 'SearchRequestData';
      searchRequests: Array<{
        __typename?: 'SearchRequest';
        id: string;
        created: any;
        disabled?: boolean | null;
        step: SearchRequestStep;
        answeredDate?: any | null;
        stepWeight: number;
        conversationId?: string | null;
        comments: number;
        conversation?: {
          __typename?: 'Conversation';
          id: string;
          unread?: { __typename?: 'Unread'; count?: number | null } | null;
        } | null;
        searchRequestReference: {
          __typename?: 'SearchRequestReference';
          id: string;
          title?: string | null;
          status: SearchRequestStatusEnum;
          disabledDate?: any | null;
          disabledReason?: {
            __typename?: 'DisableSearchRequestReason';
            reason: DisableSearchRequestReasonEnum;
          } | null;
          client?: { __typename?: 'Client'; id: string; name: string } | null;
          searchParameters: {
            __typename?: 'SearchParameters';
            usageCategories: Array<string>;
            size?: {
              __typename?: 'Range';
              min?: number | null;
              max?: number | null;
            } | null;
          };
        };
        county?: { __typename?: 'Region'; id: string; name: string } | null;
        responsibleUser?: {
          __typename?: 'User';
          id: string;
          name: string;
        } | null;
      }>;
    };
    success: {
      __typename?: 'SearchRequestData';
      searchRequests: Array<{
        __typename?: 'SearchRequest';
        id: string;
        created: any;
        disabled?: boolean | null;
        step: SearchRequestStep;
        answeredDate?: any | null;
        stepWeight: number;
        conversationId?: string | null;
        comments: number;
        conversation?: {
          __typename?: 'Conversation';
          id: string;
          unread?: { __typename?: 'Unread'; count?: number | null } | null;
        } | null;
        searchRequestReference: {
          __typename?: 'SearchRequestReference';
          id: string;
          title?: string | null;
          status: SearchRequestStatusEnum;
          disabledDate?: any | null;
          disabledReason?: {
            __typename?: 'DisableSearchRequestReason';
            reason: DisableSearchRequestReasonEnum;
          } | null;
          client?: { __typename?: 'Client'; id: string; name: string } | null;
          searchParameters: {
            __typename?: 'SearchParameters';
            usageCategories: Array<string>;
            size?: {
              __typename?: 'Range';
              min?: number | null;
              max?: number | null;
            } | null;
          };
        };
        county?: { __typename?: 'Region'; id: string; name: string } | null;
        responsibleUser?: {
          __typename?: 'User';
          id: string;
          name: string;
        } | null;
      }>;
    };
    archived?: {
      __typename?: 'SearchRequestData';
      searchRequests: Array<{
        __typename?: 'SearchRequest';
        id: string;
        created: any;
        disabled?: boolean | null;
        step: SearchRequestStep;
        answeredDate?: any | null;
        stepWeight: number;
        conversationId?: string | null;
        comments: number;
        conversation?: {
          __typename?: 'Conversation';
          id: string;
          unread?: { __typename?: 'Unread'; count?: number | null } | null;
        } | null;
        searchRequestReference: {
          __typename?: 'SearchRequestReference';
          id: string;
          title?: string | null;
          status: SearchRequestStatusEnum;
          disabledDate?: any | null;
          disabledReason?: {
            __typename?: 'DisableSearchRequestReason';
            reason: DisableSearchRequestReasonEnum;
          } | null;
          client?: { __typename?: 'Client'; id: string; name: string } | null;
          searchParameters: {
            __typename?: 'SearchParameters';
            usageCategories: Array<string>;
            size?: {
              __typename?: 'Range';
              min?: number | null;
              max?: number | null;
            } | null;
          };
        };
        county?: { __typename?: 'Region'; id: string; name: string } | null;
        responsibleUser?: {
          __typename?: 'User';
          id: string;
          name: string;
        } | null;
      }>;
    };
  };
};

export type GetSearchRequestCommentsLandlordQueryVariables = Exact<{
  searchRequestId: Scalars['String'];
}>;

export type GetSearchRequestCommentsLandlordQuery = {
  __typename?: 'Query';
  searchRequestCommentsLandlord: Array<{
    __typename?: 'SearchRequestComment';
    id: string;
    comment: string;
    created?: any | null;
    user: {
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    };
  }>;
};

export type SearchRequestCardValuesFragment = {
  __typename?: 'SearchRequest';
  id: string;
  created: any;
  disabled?: boolean | null;
  step: SearchRequestStep;
  answeredDate?: any | null;
  stepWeight: number;
  conversationId?: string | null;
  comments: number;
  conversation?: {
    __typename?: 'Conversation';
    id: string;
    unread?: { __typename?: 'Unread'; count?: number | null } | null;
  } | null;
  searchRequestReference: {
    __typename?: 'SearchRequestReference';
    id: string;
    title?: string | null;
    status: SearchRequestStatusEnum;
    disabledDate?: any | null;
    disabledReason?: {
      __typename?: 'DisableSearchRequestReason';
      reason: DisableSearchRequestReasonEnum;
    } | null;
    client?: { __typename?: 'Client'; id: string; name: string } | null;
    searchParameters: {
      __typename?: 'SearchParameters';
      usageCategories: Array<string>;
      size?: {
        __typename?: 'Range';
        min?: number | null;
        max?: number | null;
      } | null;
    };
  };
  county?: { __typename?: 'Region'; id: string; name: string } | null;
  responsibleUser?: { __typename?: 'User'; id: string; name: string } | null;
};

export type UpdateSearchRequestStepMutationVariables = Exact<{
  id: Scalars['String'];
  update: UpdateSearchRequestStepInput;
}>;

export type UpdateSearchRequestStepMutation = {
  __typename?: 'Mutation';
  updateSearchRequestStep: {
    __typename?: 'SearchRequest';
    id: string;
    step: SearchRequestStep;
  };
};

export type GetActivityNumbersQueryVariables = Exact<{
  limit: Scalars['Int'];
  type?: InputMaybe<Array<ActivityLogEnum> | ActivityLogEnum>;
}>;

export type GetActivityNumbersQuery = {
  __typename?: 'Query';
  activityData: {
    __typename?: 'ActivityData';
    statistics: {
      __typename?: 'ActivityLogStatistics';
      today: number;
      yesterday: number;
      ATH: number;
      average: number;
    };
  };
};

export type GetSystemActivityQueryVariables = Exact<{
  type?: InputMaybe<Array<ActivityLogEnum> | ActivityLogEnum>;
  from?: InputMaybe<Scalars['Date']>;
  to?: InputMaybe<Scalars['Date']>;
  limit: Scalars['Int'];
}>;

export type GetSystemActivityQuery = {
  __typename?: 'Query';
  activityData: {
    __typename?: 'ActivityData';
    count: number;
    activities: Array<{
      __typename?: 'ActivityLog';
      id: string;
      created: any;
      type: ActivityLogEnum;
      user: {
        __typename?: 'User';
        id: string;
        name: string;
        searchRequestSettings?: {
          __typename?: 'SearchRequestSettings';
          regions: Array<{ __typename?: 'Region'; name: string }>;
        } | null;
      };
      searchRequest?: {
        __typename?: 'SearchRequest';
        id: string;
        searchRequestReference: {
          __typename?: 'SearchRequestReference';
          id: string;
          title?: string | null;
        };
      } | null;
      leaseAgreement?: {
        __typename?: 'LeaseAgreement';
        id: string;
        name: string;
      } | null;
      statusUpdate?: {
        __typename?: 'StatusUpdate';
        direction: Direction;
        previousStatus: ActivityStatusEnum;
        nextStatus: ActivityStatusEnum;
      } | null;
      conversation?: {
        __typename?: 'Conversation';
        id: string;
        participants: Array<{
          __typename?: 'Client';
          id: string;
          name: string;
        }>;
        searchRequestReference?: {
          __typename?: 'SearchRequestReference';
          id: string;
          title?: string | null;
        } | null;
        leaseAgreement?: {
          __typename?: 'LeaseAgreement';
          id: string;
          name: string;
        } | null;
        messages: Array<{
          __typename?: 'Message';
          id: string;
          created?: any | null;
          readTimestamp?: any | null;
          sender: { __typename?: 'Client'; id: string; name: string };
          senderUser?: { __typename?: 'User'; id: string; name: string } | null;
          reactions?: Array<{
            __typename?: 'Reaction';
            userId: string;
            reaction: ReactionEnum;
            user: { __typename?: 'User'; name: string };
          } | null> | null;
          leaseAgreements: Array<{
            __typename?: 'LeaseAgreement';
            id: string;
            name: string;
            size?: number | null;
            usageCategory?: Array<UsageCategory> | null;
            image?: { __typename?: 'File'; id: string; url: string } | null;
          }>;
        }>;
      } | null;
      client?: { __typename?: 'Client'; id: string; name: string } | null;
    } | null>;
  };
};

export type GetInactiveUsersQueryVariables = Exact<{
  clientType?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  createdFrom?: InputMaybe<Scalars['Date']>;
}>;

export type GetInactiveUsersQuery = {
  __typename?: 'Query';
  userData: {
    __typename?: 'UserData';
    count: number;
    users: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      created?: any | null;
      activeClient?: { __typename?: 'Client'; id: string; name: string } | null;
      searchRequests: Array<{
        __typename?: 'SearchRequestReference';
        id: string;
        title?: string | null;
        stats: {
          __typename?: 'StatusCount';
          accepted: number;
          declined: number;
          opened: number;
        };
      }>;
    }>;
  };
};

export type GetStatusUpdatesQueryVariables = Exact<{
  limit: Scalars['Int'];
  direction?: InputMaybe<Direction>;
}>;

export type GetStatusUpdatesQuery = {
  __typename?: 'Query';
  statusUpdateData: {
    __typename?: 'StatusUpdateData';
    count: number;
    statusUpdates: Array<{
      __typename?: 'ActivityLog';
      id: string;
      created: any;
      user: {
        __typename?: 'User';
        id: string;
        name: string;
        searchRequestSettings?: {
          __typename?: 'SearchRequestSettings';
          regions: Array<{ __typename?: 'Region'; name: string }>;
        } | null;
      };
      client?: {
        __typename?: 'Client';
        id: string;
        name: string;
        type?: ClientType | null;
      } | null;
      statusUpdate?: {
        __typename?: 'StatusUpdate';
        direction: Direction;
        nextStatus: ActivityStatusEnum;
        previousStatus: ActivityStatusEnum;
      } | null;
    }>;
  };
};

export type LoginWithTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type LoginWithTokenMutation = {
  __typename?: 'Mutation';
  loginWithToken: {
    __typename?: 'LoginWithTokenResult';
    token: string;
    searchRequestReferenceId?: string | null;
  };
};

export type GetMyColleaguesQueryVariables = Exact<{
  clientId: Scalars['String'];
}>;

export type GetMyColleaguesQuery = {
  __typename?: 'Query';
  myColleagues: {
    __typename?: 'ColleaguesData';
    count: number;
    colleagues: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      hasPassword?: boolean | null;
      logins?: number | null;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    }>;
  };
};

export type AddUserMyColleaguesMutationVariables = Exact<{
  input: UserInput;
}>;

export type AddUserMyColleaguesMutation = {
  __typename?: 'Mutation';
  addUserMyColleagues?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName?: string | null;
    activeClientId?: string | null;
    email: string;
    role?: string | null;
    imageId?: string | null;
    limited?: boolean | null;
    showMine?: boolean | null;
    prospect?: boolean | null;
    companyName?: string | null;
    orgNumber?: string | null;
    phone?: string | null;
    disabled?: boolean | null;
    failedLogins?: number | null;
    onBoarded?: boolean | null;
    activeModal?: ModalEnum | null;
    jobTitle?: string | null;
    userCategory: Array<UserCategoryEnum>;
    activeClient?: {
      __typename?: 'Client';
      name: string;
      id: string;
      type?: ClientType | null;
    } | null;
    clients?: Array<{ __typename?: 'Client'; name: string; id: string }> | null;
    image?: {
      __typename?: 'File';
      id: string;
      name: string;
      url: string;
      size: number;
    } | null;
    trial?: {
      __typename?: 'Trial';
      startDate?: any | null;
      endDate?: any | null;
    } | null;
    emailSettings: {
      __typename?: 'EmailSettings';
      enable: boolean;
      onMessage: boolean;
      onMatch: boolean;
      onSearchHit: boolean;
      onIntresting: boolean;
      onSearchRequest: boolean;
      reminders: boolean;
    };
    searchRequestSettings?: {
      __typename?: 'SearchRequestSettings';
      regionIds: Array<string>;
      usageCategories: Array<string>;
      filterEmails?: boolean | null;
      size: Array<SizeFilter>;
    } | null;
  } | null;
};

export type AddClientMutationVariables = Exact<{
  input: ClientInput;
}>;

export type AddClientMutation = {
  __typename?: 'Mutation';
  addClient: {
    __typename?: 'Client';
    id: string;
    name: string;
    type?: ClientType | null;
    group?: string | null;
    email?: string | null;
    telephone?: string | null;
    orgNumber?: string | null;
    imageId?: string | null;
    companyName?: string | null;
    address?: string | null;
    location?: string | null;
    website?: string | null;
    parentCompany?: string | null;
    parentCompanyOrgNumber?: string | null;
    responsibleConsultantId?: string | null;
    comment?: string | null;
    description?: string | null;
    numberOfEmployees?: number | null;
    numberOfUnits?: number | null;
    industryCategory?: string | null;
    industryCode?: string | null;
    typeOfBusiness?: TypeOfBusiness | null;
    showOnWebsite?: boolean | null;
    notifySupport?: boolean | null;
    supportStatus?: string | null;
    vitecLicenceIds: Array<string>;
    objektvisionId?: string | null;
    objektvisionIds: Array<string>;
    includeObjektvisionEmails: Array<string>;
    lokalguidenId?: string | null;
    showPageOnWebsite?: boolean | null;
    slug?: string | null;
    websiteName?: string | null;
    limeApiKey?: string | null;
    isPartner?: boolean | null;
    websiteDescription?: string | null;
    image?: {
      __typename?: 'File';
      id: string;
      name: string;
      url: string;
      size: number;
    } | null;
    postAddress?: {
      __typename?: 'Address';
      address?: string | null;
      street?: string | null;
      streetNumber?: string | null;
      zipCode?: string | null;
      city?: string | null;
    } | null;
    visitingAddress?: {
      __typename?: 'Address';
      address?: string | null;
      street?: string | null;
      streetNumber?: string | null;
      zipCode?: string | null;
      city?: string | null;
    } | null;
    responsibleConsultant?: { __typename?: 'User'; id: string } | null;
    estimatedSize?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
    invoiceDetails?: {
      __typename?: 'InvoiceDetails';
      billing?: string | null;
      company?: string | null;
      marking?: string | null;
      reference?: string | null;
      email?: string | null;
    } | null;
    websiteImage?: {
      __typename?: 'File';
      id: string;
      name: string;
      url: string;
      size: number;
    } | null;
  };
};

export type ClientLeaseAgreementsQueryVariables = Exact<{
  input?: InputMaybe<LeaseAgreementFilter>;
  offset: Scalars['Int'];
}>;

export type ClientLeaseAgreementsQuery = {
  __typename?: 'Query';
  leaseAgreements: {
    __typename?: 'LeaseAgreementsResult';
    count: number;
    items: Array<{
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      key: string;
      address: { __typename?: 'Address'; city?: string | null };
    }>;
  };
};

export type ClientValuesFragment = {
  __typename?: 'Client';
  id: string;
  name: string;
  type?: ClientType | null;
  group?: string | null;
  email?: string | null;
  telephone?: string | null;
  orgNumber?: string | null;
  imageId?: string | null;
  companyName?: string | null;
  address?: string | null;
  location?: string | null;
  website?: string | null;
  parentCompany?: string | null;
  parentCompanyOrgNumber?: string | null;
  responsibleConsultantId?: string | null;
  comment?: string | null;
  description?: string | null;
  numberOfEmployees?: number | null;
  numberOfUnits?: number | null;
  industryCategory?: string | null;
  industryCode?: string | null;
  typeOfBusiness?: TypeOfBusiness | null;
  showOnWebsite?: boolean | null;
  notifySupport?: boolean | null;
  supportStatus?: string | null;
  vitecLicenceIds: Array<string>;
  objektvisionId?: string | null;
  objektvisionIds: Array<string>;
  includeObjektvisionEmails: Array<string>;
  lokalguidenId?: string | null;
  showPageOnWebsite?: boolean | null;
  slug?: string | null;
  websiteName?: string | null;
  limeApiKey?: string | null;
  isPartner?: boolean | null;
  websiteDescription?: string | null;
  image?: {
    __typename?: 'File';
    id: string;
    name: string;
    url: string;
    size: number;
  } | null;
  postAddress?: {
    __typename?: 'Address';
    address?: string | null;
    street?: string | null;
    streetNumber?: string | null;
    zipCode?: string | null;
    city?: string | null;
  } | null;
  visitingAddress?: {
    __typename?: 'Address';
    address?: string | null;
    street?: string | null;
    streetNumber?: string | null;
    zipCode?: string | null;
    city?: string | null;
  } | null;
  responsibleConsultant?: { __typename?: 'User'; id: string } | null;
  estimatedSize?: {
    __typename?: 'Range';
    min?: number | null;
    max?: number | null;
  } | null;
  invoiceDetails?: {
    __typename?: 'InvoiceDetails';
    billing?: string | null;
    company?: string | null;
    marking?: string | null;
    reference?: string | null;
    email?: string | null;
  } | null;
  websiteImage?: {
    __typename?: 'File';
    id: string;
    name: string;
    url: string;
    size: number;
  } | null;
};

export type DeleteClientMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteClientMutation = {
  __typename?: 'Mutation';
  deleteClient: { __typename?: 'Client'; id: string };
};

export type EditClientMutationVariables = Exact<{
  id: Scalars['String'];
  input: ClientInput;
}>;

export type EditClientMutation = {
  __typename?: 'Mutation';
  editClient: {
    __typename?: 'Client';
    id: string;
    name: string;
    type?: ClientType | null;
    group?: string | null;
    email?: string | null;
    telephone?: string | null;
    orgNumber?: string | null;
    imageId?: string | null;
    companyName?: string | null;
    address?: string | null;
    location?: string | null;
    website?: string | null;
    parentCompany?: string | null;
    parentCompanyOrgNumber?: string | null;
    responsibleConsultantId?: string | null;
    comment?: string | null;
    description?: string | null;
    numberOfEmployees?: number | null;
    numberOfUnits?: number | null;
    industryCategory?: string | null;
    industryCode?: string | null;
    typeOfBusiness?: TypeOfBusiness | null;
    showOnWebsite?: boolean | null;
    notifySupport?: boolean | null;
    supportStatus?: string | null;
    vitecLicenceIds: Array<string>;
    objektvisionId?: string | null;
    objektvisionIds: Array<string>;
    includeObjektvisionEmails: Array<string>;
    lokalguidenId?: string | null;
    showPageOnWebsite?: boolean | null;
    slug?: string | null;
    websiteName?: string | null;
    limeApiKey?: string | null;
    isPartner?: boolean | null;
    websiteDescription?: string | null;
    image?: {
      __typename?: 'File';
      id: string;
      name: string;
      url: string;
      size: number;
    } | null;
    postAddress?: {
      __typename?: 'Address';
      address?: string | null;
      street?: string | null;
      streetNumber?: string | null;
      zipCode?: string | null;
      city?: string | null;
    } | null;
    visitingAddress?: {
      __typename?: 'Address';
      address?: string | null;
      street?: string | null;
      streetNumber?: string | null;
      zipCode?: string | null;
      city?: string | null;
    } | null;
    responsibleConsultant?: { __typename?: 'User'; id: string } | null;
    estimatedSize?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
    invoiceDetails?: {
      __typename?: 'InvoiceDetails';
      billing?: string | null;
      company?: string | null;
      marking?: string | null;
      reference?: string | null;
      email?: string | null;
    } | null;
    websiteImage?: {
      __typename?: 'File';
      id: string;
      name: string;
      url: string;
      size: number;
    } | null;
  };
};

export type GetClientQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetClientQuery = {
  __typename?: 'Query';
  client: {
    __typename?: 'Client';
    id: string;
    name: string;
    type?: ClientType | null;
    group?: string | null;
    email?: string | null;
    telephone?: string | null;
    orgNumber?: string | null;
    imageId?: string | null;
    companyName?: string | null;
    address?: string | null;
    location?: string | null;
    website?: string | null;
    parentCompany?: string | null;
    parentCompanyOrgNumber?: string | null;
    responsibleConsultantId?: string | null;
    comment?: string | null;
    description?: string | null;
    numberOfEmployees?: number | null;
    numberOfUnits?: number | null;
    industryCategory?: string | null;
    industryCode?: string | null;
    typeOfBusiness?: TypeOfBusiness | null;
    showOnWebsite?: boolean | null;
    notifySupport?: boolean | null;
    supportStatus?: string | null;
    vitecLicenceIds: Array<string>;
    objektvisionId?: string | null;
    objektvisionIds: Array<string>;
    includeObjektvisionEmails: Array<string>;
    lokalguidenId?: string | null;
    showPageOnWebsite?: boolean | null;
    slug?: string | null;
    websiteName?: string | null;
    limeApiKey?: string | null;
    isPartner?: boolean | null;
    websiteDescription?: string | null;
    image?: {
      __typename?: 'File';
      id: string;
      name: string;
      url: string;
      size: number;
    } | null;
    postAddress?: {
      __typename?: 'Address';
      address?: string | null;
      street?: string | null;
      streetNumber?: string | null;
      zipCode?: string | null;
      city?: string | null;
    } | null;
    visitingAddress?: {
      __typename?: 'Address';
      address?: string | null;
      street?: string | null;
      streetNumber?: string | null;
      zipCode?: string | null;
      city?: string | null;
    } | null;
    responsibleConsultant?: { __typename?: 'User'; id: string } | null;
    estimatedSize?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
    invoiceDetails?: {
      __typename?: 'InvoiceDetails';
      billing?: string | null;
      company?: string | null;
      marking?: string | null;
      reference?: string | null;
      email?: string | null;
    } | null;
    websiteImage?: {
      __typename?: 'File';
      id: string;
      name: string;
      url: string;
      size: number;
    } | null;
  };
};

export type GetClientAdminQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetClientAdminQuery = {
  __typename?: 'Query';
  client: {
    __typename?: 'Client';
    gateCustomer?: boolean | null;
    id: string;
    name: string;
    type?: ClientType | null;
    group?: string | null;
    email?: string | null;
    telephone?: string | null;
    orgNumber?: string | null;
    imageId?: string | null;
    companyName?: string | null;
    address?: string | null;
    location?: string | null;
    website?: string | null;
    parentCompany?: string | null;
    parentCompanyOrgNumber?: string | null;
    responsibleConsultantId?: string | null;
    comment?: string | null;
    description?: string | null;
    numberOfEmployees?: number | null;
    numberOfUnits?: number | null;
    industryCategory?: string | null;
    industryCode?: string | null;
    typeOfBusiness?: TypeOfBusiness | null;
    showOnWebsite?: boolean | null;
    notifySupport?: boolean | null;
    supportStatus?: string | null;
    vitecLicenceIds: Array<string>;
    objektvisionId?: string | null;
    objektvisionIds: Array<string>;
    includeObjektvisionEmails: Array<string>;
    lokalguidenId?: string | null;
    showPageOnWebsite?: boolean | null;
    slug?: string | null;
    websiteName?: string | null;
    limeApiKey?: string | null;
    isPartner?: boolean | null;
    websiteDescription?: string | null;
    users: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      email: string;
      phone?: string | null;
    }>;
    image?: {
      __typename?: 'File';
      id: string;
      name: string;
      url: string;
      size: number;
    } | null;
    postAddress?: {
      __typename?: 'Address';
      address?: string | null;
      street?: string | null;
      streetNumber?: string | null;
      zipCode?: string | null;
      city?: string | null;
    } | null;
    visitingAddress?: {
      __typename?: 'Address';
      address?: string | null;
      street?: string | null;
      streetNumber?: string | null;
      zipCode?: string | null;
      city?: string | null;
    } | null;
    responsibleConsultant?: { __typename?: 'User'; id: string } | null;
    estimatedSize?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
    invoiceDetails?: {
      __typename?: 'InvoiceDetails';
      billing?: string | null;
      company?: string | null;
      marking?: string | null;
      reference?: string | null;
      email?: string | null;
    } | null;
    websiteImage?: {
      __typename?: 'File';
      id: string;
      name: string;
      url: string;
      size: number;
    } | null;
  };
};

export type GetClientCsvQueryVariables = Exact<{
  order?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  searchValues?: InputMaybe<ClientSearchFilters>;
}>;

export type GetClientCsvQuery = {
  __typename?: 'Query';
  getClientCsv?: string | null;
};

export type GetClientsQueryVariables = Exact<{
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  order?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  searchValues?: InputMaybe<ClientSearchFilters>;
}>;

export type GetClientsQuery = {
  __typename?: 'Query';
  clients: {
    __typename?: 'ClientData';
    count: number;
    clients: Array<{
      __typename?: 'Client';
      id: string;
      name: string;
      type?: ClientType | null;
      leaseAgreementCount?: number | null;
      estateCount?: number | null;
      hasDescription?: boolean | null;
      notifySupport?: boolean | null;
      supportStatus?: string | null;
      brandCategory?: string | null;
      currentSaleStair?: string | null;
      gateCustomer?: boolean | null;
      currentRequests?: {
        __typename?: 'ActionStats';
        total?: number | null;
        unanswered?: number | null;
      } | null;
      currentPromotions?: {
        __typename?: 'ActionStats';
        total?: number | null;
        unanswered?: number | null;
      } | null;
    }>;
  };
};

export type GetDealClientsQueryVariables = Exact<{
  client?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetDealClientsQuery = {
  __typename?: 'Query';
  subscriptionRequests: Array<{
    __typename?: 'SubscriptionRequest';
    id: string;
    status: string;
    clientId: string;
    client: {
      __typename?: 'Client';
      id: string;
      name: string;
      deals: {
        __typename?: 'ClientDeals';
        count?: number | null;
        value?: number | null;
        portallysFee?: number | null;
      };
    };
  }>;
};

export type GetDealUsersQueryVariables = Exact<{
  clientId: Scalars['String'];
  limit: Scalars['Int'];
}>;

export type GetDealUsersQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserData';
    count: number;
    users: Array<{
      __typename?: 'User';
      firstName: string;
      lastName?: string | null;
      id: string;
      hasDealRequest: boolean;
      deals: {
        __typename?: 'UserDeals';
        count?: number | null;
        value?: number | null;
        portallysFee?: number | null;
      };
    }>;
  };
};

export type GetDealsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  treated?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetDealsQuery = {
  __typename?: 'Query';
  deals: {
    __typename?: 'DealData';
    count: number;
    deals: Array<{
      __typename?: 'Deal';
      id: string;
      userId: string;
      clientId: string;
      madeWith: string;
      annualRent: number;
      portallyFee: number;
      created: any;
      treated: boolean;
      billing: string;
      email: string;
      reference: string;
      marking?: string | null;
      company?: string | null;
      comment?: string | null;
      user?: { __typename?: 'User'; name: string } | null;
      subscription?: {
        __typename?: 'DealSubscription';
        version: SubscriptionVersion;
        type?: SubscriptionType | null;
      } | null;
      client?: { __typename?: 'Client'; name: string } | null;
    }>;
  };
};

export type RemoveDealFromUserMutationVariables = Exact<{
  userId: Scalars['String'];
  clientId: Scalars['String'];
}>;

export type RemoveDealFromUserMutation = {
  __typename?: 'Mutation';
  removeDealFromUser: {
    __typename?: 'User';
    id: string;
    activeClientId?: string | null;
    hasDealRequest: boolean;
    clients?: Array<{ __typename?: 'Client'; id: string; name: string }> | null;
  };
};

export type RemoveDealsFromClientMutationVariables = Exact<{
  clientId: Scalars['String'];
}>;

export type RemoveDealsFromClientMutation = {
  __typename?: 'Mutation';
  removeDealsFromClient: Array<{
    __typename?: 'User';
    id: string;
    activeClientId?: string | null;
    hasDealRequest: boolean;
    clients?: Array<{ __typename?: 'Client'; id: string; name: string }> | null;
  }>;
};

export type RemoveFromAllMutationVariables = Exact<{ [key: string]: never }>;

export type RemoveFromAllMutation = {
  __typename?: 'Mutation';
  removeFromAll: Array<{
    __typename?: 'User';
    id: string;
    activeClientId?: string | null;
    hasDealRequest: boolean;
    clients?: Array<{ __typename?: 'Client'; id: string; name: string }> | null;
  }>;
};

export type SetDealsToClientMutationVariables = Exact<{
  clientId: Scalars['String'];
}>;

export type SetDealsToClientMutation = {
  __typename?: 'Mutation';
  setDealsToClient: Array<{
    __typename?: 'User';
    id: string;
    hasDealRequest: boolean;
    activeClient?: { __typename?: 'Client'; name: string } | null;
  }>;
};

export type SetDealToUserMutationVariables = Exact<{
  userId: Scalars['String'];
  clientId: Scalars['String'];
}>;

export type SetDealToUserMutation = {
  __typename?: 'Mutation';
  setDealToUser: {
    __typename?: 'User';
    id: string;
    activeClientId?: string | null;
    hasDealRequest: boolean;
    clients?: Array<{ __typename?: 'Client'; id: string; name: string }> | null;
  };
};

export type SetToAllMutationVariables = Exact<{
  filter: Scalars['String'];
}>;

export type SetToAllMutation = {
  __typename?: 'Mutation';
  setToAll: Array<{
    __typename?: 'User';
    id: string;
    activeClientId?: string | null;
    hasDealRequest: boolean;
    clients?: Array<{ __typename?: 'Client'; id: string; name: string }> | null;
  }>;
};

export type TreatDealMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type TreatDealMutation = {
  __typename?: 'Mutation';
  treatDeal: {
    __typename?: 'Deal';
    id: string;
    userId: string;
    clientId: string;
    madeWith: string;
    annualRent: number;
    portallyFee: number;
    created: any;
    treated: boolean;
    user?: { __typename?: 'User'; id: string; name: string } | null;
    client?: { __typename?: 'Client'; name: string; id: string } | null;
  };
};

export type DeleteEmailLogMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteEmailLogMutation = {
  __typename?: 'Mutation';
  deleteEmailLog: {
    __typename?: 'EmailLog';
    id: string;
    subject: string;
    body: string;
    recipient: string;
    template?: string | null;
    date: any;
    mailWaiting?: boolean | null;
    client?: { __typename?: 'Client'; id: string; name: string } | null;
  };
};

export type EmailLogValuesFragment = {
  __typename?: 'EmailLog';
  id: string;
  subject: string;
  body: string;
  recipient: string;
  template?: string | null;
  date: any;
  mailWaiting?: boolean | null;
  client?: { __typename?: 'Client'; id: string; name: string } | null;
};

export type GetEmailLogQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetEmailLogQuery = {
  __typename?: 'Query';
  emailLog: {
    __typename?: 'EmailLog';
    id: string;
    subject: string;
    body: string;
    recipient: string;
    template?: string | null;
    date: any;
    mailWaiting?: boolean | null;
    client?: { __typename?: 'Client'; id: string; name: string } | null;
  };
};

export type GetEmailLogsQueryVariables = Exact<{
  filter: EmailLogFilter;
}>;

export type GetEmailLogsQuery = {
  __typename?: 'Query';
  emailLogs: {
    __typename?: 'EmailLogData';
    count: number;
    emailLogs: Array<{
      __typename?: 'EmailLog';
      id: string;
      subject: string;
      body: string;
      recipient: string;
      template?: string | null;
      date: any;
      mailWaiting?: boolean | null;
      client?: { __typename?: 'Client'; id: string; name: string } | null;
    }>;
  };
};

export type SendWaitingEmailMutationVariables = Exact<{
  id: Scalars['String'];
  recipients: Array<ContactInput> | ContactInput;
}>;

export type SendWaitingEmailMutation = {
  __typename?: 'Mutation';
  sendWaitingEmail?: boolean | null;
};

export type GetEstablishmentDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetEstablishmentDataQuery = {
  __typename?: 'Query';
  status?: Array<{
    __typename?: 'Status';
    status?: string | null;
    id?: string | null;
  } | null> | null;
};

export type GetEstablishmentListDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetEstablishmentListDataQuery = {
  __typename?: 'Query';
  status?: Array<{
    __typename?: 'Status';
    status?: string | null;
    id?: string | null;
    leaseAgreement?: {
      __typename?: 'LeaseAgreement';
      location?: any | null;
      id: string;
      name: string;
      title?: string | null;
      usageCategory?: Array<UsageCategory> | null;
      size?: number | null;
      myStatus?: string | null;
      address: { __typename?: 'Address'; city?: string | null };
      sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
      image?: {
        __typename?: 'File';
        url: string;
        name: string;
        id: string;
      } | null;
      landlordContactPersons: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        email: string;
        phone?: string | null;
        image?: {
          __typename?: 'File';
          url: string;
          id: string;
          name: string;
        } | null;
      }>;
      landlord?: {
        __typename?: 'Client';
        id: string;
        name: string;
        image?: { __typename?: 'File'; url: string; name: string } | null;
      } | null;
      integrationProvider?: {
        __typename?: 'IntegrationProvider';
        id: string;
        name: string;
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
      matchingSearchRequests?: {
        __typename?: 'MatchingSearchRequests';
        count: number;
      } | null;
    } | null;
  } | null> | null;
};

export type AddEstateMutationVariables = Exact<{
  input: EstateInput;
}>;

export type AddEstateMutation = {
  __typename?: 'Mutation';
  addEstate: {
    __typename?: 'Estate';
    id: string;
    estateDesignation?: string | null;
    name?: string | null;
    location?: any | null;
    landlordId?: string | null;
    contactPersonIds?: Array<string> | null;
    address?: Array<{
      __typename?: 'Address';
      street?: string | null;
      streetNumber?: string | null;
      zipCode?: string | null;
      city?: string | null;
    } | null> | null;
    contactPersons?: Array<{ __typename?: 'User'; name: string }> | null;
    leaseAgreements?: Array<{
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      usageCategory?: Array<UsageCategory> | null;
      size?: number | null;
      key: string;
      tenant?: { __typename?: 'Client'; id: string; name: string } | null;
    } | null> | null;
  };
};

export type EditEstateCustomerMutationVariables = Exact<{
  id: Scalars['String'];
  input: EstateInput;
}>;

export type EditEstateCustomerMutation = {
  __typename?: 'Mutation';
  editEstate: {
    __typename?: 'Estate';
    id: string;
    estateDesignation?: string | null;
    name?: string | null;
    location?: any | null;
    landlordId?: string | null;
    contactPersonIds?: Array<string> | null;
    address?: Array<{
      __typename?: 'Address';
      street?: string | null;
      streetNumber?: string | null;
      zipCode?: string | null;
      city?: string | null;
    } | null> | null;
    contactPersons?: Array<{ __typename?: 'User'; name: string }> | null;
    leaseAgreements?: Array<{
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      usageCategory?: Array<UsageCategory> | null;
      size?: number | null;
      key: string;
      tenant?: { __typename?: 'Client'; id: string; name: string } | null;
    } | null> | null;
  };
};

export type EstateCustomerValuesFragment = {
  __typename?: 'Estate';
  id: string;
  estateDesignation?: string | null;
  name?: string | null;
  location?: any | null;
  landlordId?: string | null;
  contactPersonIds?: Array<string> | null;
  address?: Array<{
    __typename?: 'Address';
    street?: string | null;
    streetNumber?: string | null;
    zipCode?: string | null;
    city?: string | null;
  } | null> | null;
  contactPersons?: Array<{ __typename?: 'User'; name: string }> | null;
  leaseAgreements?: Array<{
    __typename?: 'LeaseAgreement';
    id: string;
    name: string;
    usageCategory?: Array<UsageCategory> | null;
    size?: number | null;
    key: string;
    tenant?: { __typename?: 'Client'; id: string; name: string } | null;
  } | null> | null;
};

export type GetEstateViewQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetEstateViewQuery = {
  __typename?: 'Query';
  estate: {
    __typename?: 'Estate';
    id: string;
    estateDesignation?: string | null;
    name?: string | null;
    location?: any | null;
    landlordId?: string | null;
    contactPersonIds?: Array<string> | null;
    address?: Array<{
      __typename?: 'Address';
      street?: string | null;
      streetNumber?: string | null;
      zipCode?: string | null;
      city?: string | null;
    } | null> | null;
    contactPersons?: Array<{ __typename?: 'User'; name: string }> | null;
    leaseAgreements?: Array<{
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      usageCategory?: Array<UsageCategory> | null;
      size?: number | null;
      key: string;
      tenant?: { __typename?: 'Client'; id: string; name: string } | null;
    } | null> | null;
  };
};

export type DeleteEstateMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteEstateMutation = {
  __typename?: 'Mutation';
  deleteEstate: { __typename?: 'Estate'; id: string };
};

export type EditEstateMutationVariables = Exact<{
  id: Scalars['String'];
  input: EstateInput;
}>;

export type EditEstateMutation = {
  __typename?: 'Mutation';
  editEstate: {
    __typename?: 'Estate';
    id: string;
    name?: string | null;
    estateDesignation?: string | null;
    key: string;
    address?: Array<{
      __typename?: 'Address';
      street?: string | null;
      streetNumber?: string | null;
      zipCode?: string | null;
      city?: string | null;
      address?: string | null;
    } | null> | null;
    landlord?: { __typename?: 'Client'; name: string } | null;
  };
};

export type EstateValuesFragment = {
  __typename?: 'Estate';
  id: string;
  name?: string | null;
  estateDesignation?: string | null;
  key: string;
  address?: Array<{
    __typename?: 'Address';
    street?: string | null;
    streetNumber?: string | null;
    zipCode?: string | null;
    city?: string | null;
    address?: string | null;
  } | null> | null;
  landlord?: { __typename?: 'Client'; name: string } | null;
};

export type GetEstatesQueryVariables = Exact<{
  filter: EstateFilter;
}>;

export type GetEstatesQuery = {
  __typename?: 'Query';
  estates: {
    __typename?: 'EstateData';
    count?: number | null;
    estates?: Array<{
      __typename?: 'Estate';
      id: string;
      name?: string | null;
      estateDesignation?: string | null;
      key: string;
      address?: Array<{
        __typename?: 'Address';
        street?: string | null;
        streetNumber?: string | null;
        zipCode?: string | null;
        city?: string | null;
        address?: string | null;
      } | null> | null;
      landlord?: { __typename?: 'Client'; name: string } | null;
    } | null> | null;
  };
};

export type GetGateGoalsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGateGoalsQuery = {
  __typename?: 'Query';
  gateGoals?: {
    __typename?: 'GateGoal';
    monthly: Array<{
      __typename?: 'MonthGoal';
      expectedIncome?: number | null;
      producedIncome?: number | null;
      numberOfMonth?: number | null;
      month?: string | null;
      fromPreviousMonth?: number | null;
    }>;
    yearly: {
      __typename?: 'Yearly';
      expectedIncome?: number | null;
      producedIncome?: number | null;
    };
  } | null;
};

export type GetActiveSearchRequestStatsQueryVariables = Exact<{
  filter: ActiveSearchRequestFilter;
}>;

export type GetActiveSearchRequestStatsQuery = {
  __typename?: 'Query';
  myActiveSearchRequests: {
    __typename?: 'ActiveSearchRequestData';
    todo: { __typename?: 'SearchRequestData'; count?: number | null };
    interesting: { __typename?: 'SearchRequestData'; count?: number | null };
    inProgress: { __typename?: 'SearchRequestData'; count?: number | null };
    negotiation: { __typename?: 'SearchRequestData'; count?: number | null };
    success: { __typename?: 'SearchRequestData'; count?: number | null };
  };
};

export type GetMyActiveSearchRequestsDashboardQueryVariables = Exact<{
  limit: Scalars['Int'];
  filter: ActiveSearchRequestFilter;
}>;

export type GetMyActiveSearchRequestsDashboardQuery = {
  __typename?: 'Query';
  myActiveSearchRequests: {
    __typename?: 'ActiveSearchRequestData';
    all: {
      __typename?: 'SearchRequestData';
      count?: number | null;
      searchRequests: Array<{
        __typename?: 'SearchRequest';
        id: string;
        created: any;
        disabled?: boolean | null;
        step: SearchRequestStep;
        answeredDate?: any | null;
        stepWeight: number;
        conversationId?: string | null;
        comments: number;
        conversation?: {
          __typename?: 'Conversation';
          id: string;
          unread?: { __typename?: 'Unread'; count?: number | null } | null;
        } | null;
        searchRequestReference: {
          __typename?: 'SearchRequestReference';
          id: string;
          title?: string | null;
          status: SearchRequestStatusEnum;
          disabledDate?: any | null;
          disabledReason?: {
            __typename?: 'DisableSearchRequestReason';
            reason: DisableSearchRequestReasonEnum;
          } | null;
          client?: { __typename?: 'Client'; id: string; name: string } | null;
          searchParameters: {
            __typename?: 'SearchParameters';
            usageCategories: Array<string>;
            size?: {
              __typename?: 'Range';
              min?: number | null;
              max?: number | null;
            } | null;
          };
        };
        county?: { __typename?: 'Region'; id: string; name: string } | null;
        responsibleUser?: {
          __typename?: 'User';
          id: string;
          name: string;
        } | null;
      }>;
    };
  };
};

export type GetMySearchRequestsQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;

export type GetMySearchRequestsQuery = {
  __typename?: 'Query';
  mySearchRequestReferences: {
    __typename?: 'SearchRequestReferenceData';
    count: number;
    searchRequests: Array<{
      __typename?: 'SearchRequestReference';
      id: string;
      budget?: number | null;
      status: SearchRequestStatusEnum;
      unreadMessages: number;
      title?: string | null;
      searchParameters: {
        __typename?: 'SearchParameters';
        usageCategories: Array<string>;
        geometry?: any | null;
        regionIds?: Array<string> | null;
        bounds?: {
          __typename?: 'RegionBounds';
          north: number;
          south: number;
          east: number;
          west: number;
        } | null;
        regions: Array<{
          __typename?: 'Region';
          id: string;
          name: string;
          geometry?: any | null;
        }>;
        size?: {
          __typename?: 'Range';
          min?: number | null;
          max?: number | null;
        } | null;
      };
      access?: { __typename?: 'Access'; quarter: Quarter; year: string } | null;
      contactPerson?: { __typename?: 'User'; name: string } | null;
    }>;
  };
};

export type GetActiveSearchProfileQueryVariables = Exact<{
  id: Scalars['String'];
  skipStatus?: InputMaybe<Scalars['Boolean']>;
  zoom: Scalars['Int'];
  bounds?: InputMaybe<Bounds>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetActiveSearchProfileQuery = {
  __typename?: 'Query';
  searchProfile: {
    __typename?: 'SearchProfile';
    id: string;
    name: string;
    tooManyHits?: boolean | null;
    matches: {
      __typename?: 'MatchResults';
      count: number;
      items: Array<{
        __typename?: 'LeaseAgreement';
        id: string;
        name: string;
        location?: any | null;
        myStatus?: string | null;
      }>;
    };
    cluster: Array<{
      __typename?: 'Cluster';
      id: string;
      location: any;
      count?: number | null;
    }>;
  };
};

export type GetMySearchProfilesQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['String']>;
}>;

export type GetMySearchProfilesQuery = {
  __typename?: 'Query';
  mySearchProfiles: Array<{
    __typename?: 'SearchProfile';
    id: string;
    name: string;
    matches: { __typename?: 'MatchResults'; count: number };
  }>;
};

export type GetSearchProfilesCountQueryVariables = Exact<{
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  clientId: Scalars['String'];
}>;

export type GetSearchProfilesCountQuery = {
  __typename?: 'Query';
  searchProfiles: { __typename?: 'SearchProfileData'; count: number };
};

export type InviteFriendEmailMutationVariables = Exact<{
  email: Scalars['String'];
  clientId: Scalars['String'];
}>;

export type InviteFriendEmailMutation = {
  __typename?: 'Mutation';
  inviteFriendEmail?: string | null;
};

export type MyCancelationsQueryVariables = Exact<{
  input?: InputMaybe<LeaseAgreementFilter>;
}>;

export type MyCancelationsQuery = {
  __typename?: 'Query';
  leaseAgreements: {
    __typename?: 'LeaseAgreementsResult';
    items: Array<{
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      location?: any | null;
      conversations: Array<{
        __typename?: 'Conversation';
        id: string;
        participants: Array<{
          __typename?: 'Client';
          id: string;
          name: string;
        }>;
      } | null>;
      address: { __typename?: 'Address'; city?: string | null };
      image?: { __typename?: 'File'; id: string; url: string } | null;
    }>;
  };
};

export type MyRentalsQueryVariables = Exact<{
  input?: InputMaybe<LeaseAgreementFilter>;
  sort?: InputMaybe<LeaseAgreementSort>;
  personalized?: InputMaybe<Scalars['Boolean']>;
}>;

export type MyRentalsQuery = {
  __typename?: 'Query';
  leaseAgreements: {
    __typename?: 'LeaseAgreementsResult';
    items: Array<{
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      location?: any | null;
      conversations: Array<{
        __typename?: 'Conversation';
        id: string;
        participants: Array<{
          __typename?: 'Client';
          id: string;
          name: string;
        }>;
      } | null>;
      address: { __typename?: 'Address'; city?: string | null };
      image?: { __typename?: 'File'; id: string; url: string } | null;
      views: { __typename?: 'LeaseAgreementViewData'; count: number };
      interestedUsers: Array<{ __typename?: 'User'; id: string }>;
      exposures: { __typename?: 'LeaseAgreementExposureData'; count: number };
    }>;
  };
};

export type AddIntegrationProviderMutationVariables = Exact<{
  name: Scalars['String'];
  provider: IntegrationProviderInput;
}>;

export type AddIntegrationProviderMutation = {
  __typename?: 'Mutation';
  integrationProvider: {
    __typename?: 'IntegrationProvider';
    id: string;
    name: string;
    contactPersons: Array<string>;
    clients: Array<{ __typename?: 'Client'; id: string; name: string }>;
    image?: {
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    } | null;
  };
};

export type DeleteIntegrationProviderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteIntegrationProviderMutation = {
  __typename?: 'Mutation';
  deleteIntegrationProvider: { __typename?: 'IntegrationProvider'; id: string };
};

export type EditIntegrationProviderMutationVariables = Exact<{
  id: Scalars['ID'];
  provider: IntegrationProviderInput;
}>;

export type EditIntegrationProviderMutation = {
  __typename?: 'Mutation';
  editIntegrationProvider: {
    __typename?: 'IntegrationProvider';
    id: string;
    name: string;
    contactPersons: Array<string>;
    clients: Array<{ __typename?: 'Client'; id: string; name: string }>;
    image?: {
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    } | null;
  };
};

export type GetApiKeyMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetApiKeyMutation = {
  __typename?: 'Mutation';
  apiKey: { __typename?: 'ApiCredentials'; key: string; providerId: string };
};

export type GetIntegrationProviderByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetIntegrationProviderByTokenQuery = {
  __typename?: 'Query';
  integrationProviderByToken: string;
};

export type GetIntegrationProvidersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetIntegrationProvidersQuery = {
  __typename?: 'Query';
  integrationProviders: {
    __typename?: 'IntegrationProviderData';
    count: number;
    integrationProviders: Array<{
      __typename?: 'IntegrationProvider';
      id: string;
      name: string;
      contactPersons: Array<string>;
      clients: Array<{ __typename?: 'Client'; id: string; name: string }>;
      image?: {
        __typename?: 'File';
        id: string;
        name: string;
        size: number;
        url: string;
        mimetype: string;
        uid: string;
      } | null;
    }>;
  };
};

export type IntegrationProviderValuesFragment = {
  __typename?: 'IntegrationProvider';
  id: string;
  name: string;
  contactPersons: Array<string>;
  clients: Array<{ __typename?: 'Client'; id: string; name: string }>;
  image?: {
    __typename?: 'File';
    id: string;
    name: string;
    size: number;
    url: string;
    mimetype: string;
    uid: string;
  } | null;
};

export type SendApiKeyLinkMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type SendApiKeyLinkMutation = {
  __typename?: 'Mutation';
  sendApiKeyLink: boolean;
};

export type AddLeaseAgreementMutationVariables = Exact<{
  input: AddLeaseAgreementInput;
}>;

export type AddLeaseAgreementMutation = {
  __typename?: 'Mutation';
  addLeaseAgreement: {
    __typename?: 'LeaseAgreement';
    id: string;
    name: string;
    myStatus?: string | null;
    location?: any | null;
    size?: number | null;
    title?: string | null;
    description?: string | null;
    usageCategory?: Array<UsageCategory> | null;
    landlordId?: string | null;
    tenantId?: string | null;
    estateId?: string | null;
    imageIds: Array<string>;
    documentIds: Array<string>;
    landlordContactPersonIds: Array<string>;
    tenantContactPersonIds?: Array<string> | null;
    vitecGuid?: string | null;
    areaDescription?: string | null;
    rent?: number | null;
    access?: string | null;
    projectId?: string | null;
    prioritized?: boolean | null;
    address: {
      __typename?: 'Address';
      street?: string | null;
      streetNumber?: string | null;
      city?: string | null;
      zipCode?: string | null;
    };
    sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
    landlord?: {
      __typename?: 'Client';
      name: string;
      id: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
    tenant?: { __typename?: 'Client'; name: string; id: string } | null;
    estate?: { __typename?: 'Estate'; id: string; name?: string | null } | null;
    video?: {
      __typename?: 'Video';
      videoId?: string | null;
      thumbnailId?: string | null;
      video?: {
        __typename?: 'File';
        id: string;
        url: string;
        name: string;
        size: number;
        uid: string;
      } | null;
      thumbnail?: {
        __typename?: 'File';
        id: string;
        url: string;
        name: string;
        size: number;
        uid: string;
      } | null;
    } | null;
    images: Array<{
      __typename?: 'File';
      url: string;
      name: string;
      id: string;
      size: number;
      uid: string;
    }>;
    documents: Array<{
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    }>;
    landlordContactPersons: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
      activeClient?: {
        __typename?: 'Client';
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
    }>;
    tenantContactPersons?: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
      activeClient?: {
        __typename?: 'Client';
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
    }> | null;
    links: Array<{
      __typename?: 'LeaseAgreementLink';
      title?: string | null;
      url?: string | null;
      category?: string | null;
    } | null>;
    nearbyServices?: {
      __typename?: 'NearbyServices';
      bus_station?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      train_station?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      subway_station?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      parking?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      gym?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      supermarket?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
    } | null;
    fee?: { __typename?: 'Fee'; feeType: FeeType; amount: number } | null;
    integrationProvider?: {
      __typename?: 'IntegrationProvider';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
  };
};

export type ClientAutocompleteQueryVariables = Exact<{
  key: Scalars['String'];
  clientType?: InputMaybe<Scalars['String']>;
}>;

export type ClientAutocompleteQuery = {
  __typename?: 'Query';
  clientAutocomplete?: Array<{
    __typename?: 'AutocompleteResult';
    id: string;
    value: string;
  }> | null;
};

export type ContactPersonValuesFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  image?: { __typename?: 'File'; id: string; url: string } | null;
  activeClient?: {
    __typename?: 'Client';
    image?: { __typename?: 'File'; id: string; url: string } | null;
  } | null;
};

export type CreateEstateMutationVariables = Exact<{
  input: EstateInput;
}>;

export type CreateEstateMutation = {
  __typename?: 'Mutation';
  addEstate: {
    __typename?: 'Estate';
    id: string;
    estateDesignation?: string | null;
    name?: string | null;
  };
};

export type DeleteLeaseAgreementMutationVariables = Exact<{
  id: Scalars['String'];
  blockFromIntegrationProvider?: InputMaybe<Scalars['Boolean']>;
}>;

export type DeleteLeaseAgreementMutation = {
  __typename?: 'Mutation';
  disableLeaseAgreement: { __typename?: 'LeaseAgreement'; id: string };
};

export type DeleteLeaseAgreementIntegrationProviderValuesFragment = {
  __typename?: 'IntegrationProvider';
  id: string;
  name: string;
  image?: { __typename?: 'File'; id: string; url: string } | null;
};

export type DisableLeaseAgreementMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DisableLeaseAgreementMutation = {
  __typename?: 'Mutation';
  disableLeaseAgreement: {
    __typename?: 'LeaseAgreement';
    id: string;
    name: string;
    myStatus?: string | null;
    location?: any | null;
    size?: number | null;
    title?: string | null;
    description?: string | null;
    usageCategory?: Array<UsageCategory> | null;
    landlordId?: string | null;
    tenantId?: string | null;
    estateId?: string | null;
    imageIds: Array<string>;
    documentIds: Array<string>;
    landlordContactPersonIds: Array<string>;
    tenantContactPersonIds?: Array<string> | null;
    vitecGuid?: string | null;
    areaDescription?: string | null;
    rent?: number | null;
    access?: string | null;
    projectId?: string | null;
    prioritized?: boolean | null;
    address: {
      __typename?: 'Address';
      street?: string | null;
      streetNumber?: string | null;
      city?: string | null;
      zipCode?: string | null;
    };
    sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
    landlord?: {
      __typename?: 'Client';
      name: string;
      id: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
    tenant?: { __typename?: 'Client'; name: string; id: string } | null;
    estate?: { __typename?: 'Estate'; id: string; name?: string | null } | null;
    video?: {
      __typename?: 'Video';
      videoId?: string | null;
      thumbnailId?: string | null;
      video?: {
        __typename?: 'File';
        id: string;
        url: string;
        name: string;
        size: number;
        uid: string;
      } | null;
      thumbnail?: {
        __typename?: 'File';
        id: string;
        url: string;
        name: string;
        size: number;
        uid: string;
      } | null;
    } | null;
    images: Array<{
      __typename?: 'File';
      url: string;
      name: string;
      id: string;
      size: number;
      uid: string;
    }>;
    documents: Array<{
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    }>;
    landlordContactPersons: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
      activeClient?: {
        __typename?: 'Client';
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
    }>;
    tenantContactPersons?: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
      activeClient?: {
        __typename?: 'Client';
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
    }> | null;
    links: Array<{
      __typename?: 'LeaseAgreementLink';
      title?: string | null;
      url?: string | null;
      category?: string | null;
    } | null>;
    nearbyServices?: {
      __typename?: 'NearbyServices';
      bus_station?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      train_station?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      subway_station?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      parking?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      gym?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      supermarket?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
    } | null;
    fee?: { __typename?: 'Fee'; feeType: FeeType; amount: number } | null;
    integrationProvider?: {
      __typename?: 'IntegrationProvider';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
  };
};

export type EditLeaseAgreementMutationVariables = Exact<{
  id: Scalars['String'];
  input: LeaseAgreementInput;
}>;

export type EditLeaseAgreementMutation = {
  __typename?: 'Mutation';
  editLeaseAgreement: {
    __typename?: 'LeaseAgreement';
    id: string;
    name: string;
    myStatus?: string | null;
    location?: any | null;
    size?: number | null;
    title?: string | null;
    description?: string | null;
    usageCategory?: Array<UsageCategory> | null;
    landlordId?: string | null;
    tenantId?: string | null;
    estateId?: string | null;
    imageIds: Array<string>;
    documentIds: Array<string>;
    landlordContactPersonIds: Array<string>;
    tenantContactPersonIds?: Array<string> | null;
    vitecGuid?: string | null;
    areaDescription?: string | null;
    rent?: number | null;
    access?: string | null;
    projectId?: string | null;
    prioritized?: boolean | null;
    address: {
      __typename?: 'Address';
      street?: string | null;
      streetNumber?: string | null;
      city?: string | null;
      zipCode?: string | null;
    };
    sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
    landlord?: {
      __typename?: 'Client';
      name: string;
      id: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
    tenant?: { __typename?: 'Client'; name: string; id: string } | null;
    estate?: { __typename?: 'Estate'; id: string; name?: string | null } | null;
    video?: {
      __typename?: 'Video';
      videoId?: string | null;
      thumbnailId?: string | null;
      video?: {
        __typename?: 'File';
        id: string;
        url: string;
        name: string;
        size: number;
        uid: string;
      } | null;
      thumbnail?: {
        __typename?: 'File';
        id: string;
        url: string;
        name: string;
        size: number;
        uid: string;
      } | null;
    } | null;
    images: Array<{
      __typename?: 'File';
      url: string;
      name: string;
      id: string;
      size: number;
      uid: string;
    }>;
    documents: Array<{
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    }>;
    landlordContactPersons: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
      activeClient?: {
        __typename?: 'Client';
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
    }>;
    tenantContactPersons?: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
      activeClient?: {
        __typename?: 'Client';
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
    }> | null;
    links: Array<{
      __typename?: 'LeaseAgreementLink';
      title?: string | null;
      url?: string | null;
      category?: string | null;
    } | null>;
    nearbyServices?: {
      __typename?: 'NearbyServices';
      bus_station?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      train_station?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      subway_station?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      parking?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      gym?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      supermarket?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
    } | null;
    fee?: { __typename?: 'Fee'; feeType: FeeType; amount: number } | null;
    integrationProvider?: {
      __typename?: 'IntegrationProvider';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
  };
};

export type GenerateNearbyServicesQueryVariables = Exact<{
  address: AddressInput;
}>;

export type GenerateNearbyServicesQuery = {
  __typename?: 'Query';
  generateNearbyServices: {
    __typename?: 'NearbyServices';
    bus_station?: {
      __typename?: 'NearbyService';
      name?: string | null;
      distance?: number | null;
    } | null;
    train_station?: {
      __typename?: 'NearbyService';
      name?: string | null;
      distance?: number | null;
    } | null;
    subway_station?: {
      __typename?: 'NearbyService';
      name?: string | null;
      distance?: number | null;
    } | null;
    gym?: {
      __typename?: 'NearbyService';
      name?: string | null;
      distance?: number | null;
    } | null;
    supermarket?: {
      __typename?: 'NearbyService';
      name?: string | null;
      distance?: number | null;
    } | null;
    parking?: {
      __typename?: 'NearbyService';
      name?: string | null;
      distance?: number | null;
    } | null;
  };
};

export type GetLeaseAgreementEditQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetLeaseAgreementEditQuery = {
  __typename?: 'Query';
  leaseAgreement: {
    __typename?: 'LeaseAgreement';
    id: string;
    name: string;
    myStatus?: string | null;
    location?: any | null;
    size?: number | null;
    title?: string | null;
    description?: string | null;
    usageCategory?: Array<UsageCategory> | null;
    landlordId?: string | null;
    tenantId?: string | null;
    estateId?: string | null;
    imageIds: Array<string>;
    documentIds: Array<string>;
    landlordContactPersonIds: Array<string>;
    tenantContactPersonIds?: Array<string> | null;
    vitecGuid?: string | null;
    areaDescription?: string | null;
    rent?: number | null;
    access?: string | null;
    projectId?: string | null;
    prioritized?: boolean | null;
    address: {
      __typename?: 'Address';
      street?: string | null;
      streetNumber?: string | null;
      city?: string | null;
      zipCode?: string | null;
    };
    sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
    landlord?: {
      __typename?: 'Client';
      name: string;
      id: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
    tenant?: { __typename?: 'Client'; name: string; id: string } | null;
    estate?: { __typename?: 'Estate'; id: string; name?: string | null } | null;
    video?: {
      __typename?: 'Video';
      videoId?: string | null;
      thumbnailId?: string | null;
      video?: {
        __typename?: 'File';
        id: string;
        url: string;
        name: string;
        size: number;
        uid: string;
      } | null;
      thumbnail?: {
        __typename?: 'File';
        id: string;
        url: string;
        name: string;
        size: number;
        uid: string;
      } | null;
    } | null;
    images: Array<{
      __typename?: 'File';
      url: string;
      name: string;
      id: string;
      size: number;
      uid: string;
    }>;
    documents: Array<{
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    }>;
    landlordContactPersons: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
      activeClient?: {
        __typename?: 'Client';
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
    }>;
    tenantContactPersons?: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
      activeClient?: {
        __typename?: 'Client';
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
    }> | null;
    links: Array<{
      __typename?: 'LeaseAgreementLink';
      title?: string | null;
      url?: string | null;
      category?: string | null;
    } | null>;
    nearbyServices?: {
      __typename?: 'NearbyServices';
      bus_station?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      train_station?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      subway_station?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      parking?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      gym?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      supermarket?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
    } | null;
    fee?: { __typename?: 'Fee'; feeType: FeeType; amount: number } | null;
    integrationProvider?: {
      __typename?: 'IntegrationProvider';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
  };
};

export type GetLeaseAgreementViewQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetLeaseAgreementViewQuery = {
  __typename?: 'Query';
  leaseAgreement: {
    __typename?: 'LeaseAgreement';
    id: string;
    name: string;
    myStatus?: string | null;
    location?: any | null;
    size?: number | null;
    title?: string | null;
    description?: string | null;
    usageCategory?: Array<UsageCategory> | null;
    landlordId?: string | null;
    tenantId?: string | null;
    estateId?: string | null;
    imageIds: Array<string>;
    documentIds: Array<string>;
    landlordContactPersonIds: Array<string>;
    tenantContactPersonIds?: Array<string> | null;
    vitecGuid?: string | null;
    areaDescription?: string | null;
    rent?: number | null;
    access?: string | null;
    projectId?: string | null;
    prioritized?: boolean | null;
    matchingSearchRequests?: {
      __typename?: 'MatchingSearchRequests';
      count: number;
      searchRequests: Array<{
        __typename?: 'SearchRequestReference';
        id: string;
      }>;
    } | null;
    conversations: Array<{
      __typename?: 'Conversation';
      id: string;
      participants: Array<{
        __typename?: 'Client';
        id: string;
        name: string;
        image?: { __typename?: 'File'; id: string; url: string } | null;
      }>;
    } | null>;
    address: {
      __typename?: 'Address';
      street?: string | null;
      streetNumber?: string | null;
      city?: string | null;
      zipCode?: string | null;
    };
    sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
    landlord?: {
      __typename?: 'Client';
      name: string;
      id: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
    tenant?: { __typename?: 'Client'; name: string; id: string } | null;
    estate?: { __typename?: 'Estate'; id: string; name?: string | null } | null;
    video?: {
      __typename?: 'Video';
      videoId?: string | null;
      thumbnailId?: string | null;
      video?: {
        __typename?: 'File';
        id: string;
        url: string;
        name: string;
        size: number;
        uid: string;
      } | null;
      thumbnail?: {
        __typename?: 'File';
        id: string;
        url: string;
        name: string;
        size: number;
        uid: string;
      } | null;
    } | null;
    images: Array<{
      __typename?: 'File';
      url: string;
      name: string;
      id: string;
      size: number;
      uid: string;
    }>;
    documents: Array<{
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    }>;
    landlordContactPersons: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
      activeClient?: {
        __typename?: 'Client';
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
    }>;
    tenantContactPersons?: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
      activeClient?: {
        __typename?: 'Client';
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
    }> | null;
    links: Array<{
      __typename?: 'LeaseAgreementLink';
      title?: string | null;
      url?: string | null;
      category?: string | null;
    } | null>;
    nearbyServices?: {
      __typename?: 'NearbyServices';
      bus_station?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      train_station?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      subway_station?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      parking?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      gym?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
      supermarket?: {
        __typename?: 'NearbyService';
        name?: string | null;
        distance?: number | null;
      } | null;
    } | null;
    fee?: { __typename?: 'Fee'; feeType: FeeType; amount: number } | null;
    integrationProvider?: {
      __typename?: 'IntegrationProvider';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
  };
};

export type GetProjectsByClientQueryVariables = Exact<{
  clientId: Scalars['String'];
}>;

export type GetProjectsByClientQuery = {
  __typename?: 'Query';
  projects: Array<{ __typename?: 'Project'; id: string; name: string }>;
};

export type LandlordEstatesQueryVariables = Exact<{
  landlordId: Scalars['String'];
  city?: InputMaybe<Scalars['String']>;
}>;

export type LandlordEstatesQuery = {
  __typename?: 'Query';
  estates: {
    __typename?: 'EstateData';
    estates?: Array<{
      __typename?: 'Estate';
      name?: string | null;
      id: string;
      address?: Array<{
        __typename?: 'Address';
        city?: string | null;
      } | null> | null;
    } | null> | null;
  };
};

export type LeaseAgreementFieldsFragment = {
  __typename?: 'LeaseAgreement';
  id: string;
  name: string;
  myStatus?: string | null;
  location?: any | null;
  size?: number | null;
  title?: string | null;
  description?: string | null;
  usageCategory?: Array<UsageCategory> | null;
  landlordId?: string | null;
  tenantId?: string | null;
  estateId?: string | null;
  imageIds: Array<string>;
  documentIds: Array<string>;
  landlordContactPersonIds: Array<string>;
  tenantContactPersonIds?: Array<string> | null;
  vitecGuid?: string | null;
  areaDescription?: string | null;
  rent?: number | null;
  access?: string | null;
  projectId?: string | null;
  prioritized?: boolean | null;
  address: {
    __typename?: 'Address';
    street?: string | null;
    streetNumber?: string | null;
    city?: string | null;
    zipCode?: string | null;
  };
  sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
  landlord?: {
    __typename?: 'Client';
    name: string;
    id: string;
    image?: { __typename?: 'File'; id: string; url: string } | null;
  } | null;
  tenant?: { __typename?: 'Client'; name: string; id: string } | null;
  estate?: { __typename?: 'Estate'; id: string; name?: string | null } | null;
  video?: {
    __typename?: 'Video';
    videoId?: string | null;
    thumbnailId?: string | null;
    video?: {
      __typename?: 'File';
      id: string;
      url: string;
      name: string;
      size: number;
      uid: string;
    } | null;
    thumbnail?: {
      __typename?: 'File';
      id: string;
      url: string;
      name: string;
      size: number;
      uid: string;
    } | null;
  } | null;
  images: Array<{
    __typename?: 'File';
    url: string;
    name: string;
    id: string;
    size: number;
    uid: string;
  }>;
  documents: Array<{
    __typename?: 'File';
    id: string;
    name: string;
    size: number;
    url: string;
    mimetype: string;
    uid: string;
  }>;
  landlordContactPersons: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    image?: { __typename?: 'File'; id: string; url: string } | null;
    activeClient?: {
      __typename?: 'Client';
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
  }>;
  tenantContactPersons?: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    image?: { __typename?: 'File'; id: string; url: string } | null;
    activeClient?: {
      __typename?: 'Client';
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
  }> | null;
  links: Array<{
    __typename?: 'LeaseAgreementLink';
    title?: string | null;
    url?: string | null;
    category?: string | null;
  } | null>;
  nearbyServices?: {
    __typename?: 'NearbyServices';
    bus_station?: {
      __typename?: 'NearbyService';
      name?: string | null;
      distance?: number | null;
    } | null;
    train_station?: {
      __typename?: 'NearbyService';
      name?: string | null;
      distance?: number | null;
    } | null;
    subway_station?: {
      __typename?: 'NearbyService';
      name?: string | null;
      distance?: number | null;
    } | null;
    parking?: {
      __typename?: 'NearbyService';
      name?: string | null;
      distance?: number | null;
    } | null;
    gym?: {
      __typename?: 'NearbyService';
      name?: string | null;
      distance?: number | null;
    } | null;
    supermarket?: {
      __typename?: 'NearbyService';
      name?: string | null;
      distance?: number | null;
    } | null;
  } | null;
  fee?: { __typename?: 'Fee'; feeType: FeeType; amount: number } | null;
  integrationProvider?: {
    __typename?: 'IntegrationProvider';
    id: string;
    name: string;
    image?: { __typename?: 'File'; id: string; url: string } | null;
  } | null;
};

export type RegionAndStreetAutocompleteQueryVariables = Exact<{
  key: Scalars['String'];
  country?: InputMaybe<CountryEnum>;
}>;

export type RegionAndStreetAutocompleteQuery = {
  __typename?: 'Query';
  regionsAndStreets: Array<{
    __typename?: 'AutocompleteRegions';
    value: string;
    id: string;
    type: string;
  }>;
};

export type RegionAutocompleteQueryVariables = Exact<{
  key: Scalars['String'];
  country?: InputMaybe<CountryEnum>;
}>;

export type RegionAutocompleteQuery = {
  __typename?: 'Query';
  regions: Array<{
    __typename?: 'AutocompleteRegions';
    value: string;
    id: string;
  }>;
};

export type UserAutocompleteQueryVariables = Exact<{
  key: Scalars['String'];
}>;

export type UserAutocompleteQuery = {
  __typename?: 'Query';
  userAutocomplete: Array<{
    __typename?: 'AutocompleteResult';
    id: string;
    value: string;
  }>;
};

export type ViewLeaseAgreementMutationVariables = Exact<{
  leaseAgreementId: Scalars['String'];
}>;

export type ViewLeaseAgreementMutation = {
  __typename?: 'Mutation';
  viewLeaseAgreement?: boolean | null;
};

export type EditLeaseAgreementProspectStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  status: LeaseAgreementProspectStatus;
}>;

export type EditLeaseAgreementProspectStatusMutation = {
  __typename?: 'Mutation';
  editLeaseAgreementProspectStatus: {
    __typename?: 'LeaseAgreementProspect';
    id: string;
    status: LeaseAgreementProspectStatus;
    showContactInfo: boolean;
  };
};

export type GetMyLeaseAgreementProspectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMyLeaseAgreementProspectQuery = {
  __typename?: 'Query';
  leaseAgreementProspect: {
    __typename?: 'LeaseAgreementProspect';
    company: string;
    created: any;
    email: string;
    id: string;
    message: string;
    showContactInfo: boolean;
    phone: string;
    status: LeaseAgreementProspectStatus;
    updated: any;
    leaseAgreement: {
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      size?: number | null;
      title?: string | null;
      usageCategory?: Array<UsageCategory> | null;
      address: {
        __typename?: 'Address';
        street?: string | null;
        streetNumber?: string | null;
        city?: string | null;
        zipCode?: string | null;
      };
      sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
      image?: {
        __typename?: 'File';
        id: string;
        url: string;
        size: number;
        uid: string;
      } | null;
    };
  };
};

export type GetMyLeaseAgreementProspectsQueryVariables = Exact<{
  filter?: InputMaybe<LeaseAgreementProspectFilter>;
}>;

export type GetMyLeaseAgreementProspectsQuery = {
  __typename?: 'Query';
  leaseAgreementProspects: {
    __typename?: 'LeaseAgreementProspectData';
    count: number;
    prospects: Array<{
      __typename?: 'LeaseAgreementProspect';
      company: string;
      created: any;
      email: string;
      id: string;
      message: string;
      showContactInfo: boolean;
      phone: string;
      status: LeaseAgreementProspectStatus;
      updated: any;
      leaseAgreement: {
        __typename?: 'LeaseAgreement';
        id: string;
        name: string;
        size?: number | null;
        title?: string | null;
        usageCategory?: Array<UsageCategory> | null;
        landlordContactPersons: Array<{
          __typename?: 'User';
          id: string;
          name: string;
        }>;
        address: {
          __typename?: 'Address';
          street?: string | null;
          streetNumber?: string | null;
          city?: string | null;
          zipCode?: string | null;
        };
        sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
        image?: {
          __typename?: 'File';
          id: string;
          url: string;
          size: number;
          uid: string;
        } | null;
      };
    }>;
  };
};

export type EditLeaseAgreementProspectAdminStatusMutationVariables = Exact<{
  id: Scalars['String'];
  status: LeaseAgreementProspectStatus;
}>;

export type EditLeaseAgreementProspectAdminStatusMutation = {
  __typename?: 'Mutation';
  editLeaseAgreementProspectAdminStatus: {
    __typename?: 'LeaseAgreementProspect';
    id: string;
    adminStatus: LeaseAgreementProspectStatus;
  };
};

export type EditLeaseAgreementProspectInformationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: EditLeaseAgreementProspectInformationInput;
}>;

export type EditLeaseAgreementProspectInformationMutation = {
  __typename?: 'Mutation';
  editLeaseAgreementProspectInformation: {
    __typename?: 'LeaseAgreementProspect';
    id: string;
    email: string;
    message: string;
  };
};

export type GetLeaseAgreementProspectsAdminQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetLeaseAgreementProspectsAdminQuery = {
  __typename?: 'Query';
  unopened: {
    __typename?: 'LeaseAgreementProspectData';
    count: number;
    prospects: Array<{
      __typename?: 'LeaseAgreementProspect';
      id: string;
      status: LeaseAgreementProspectStatus;
      adminStatus: LeaseAgreementProspectStatus;
      email: string;
      phone: string;
      company: string;
      message: string;
      created: any;
      landlord: { __typename?: 'Client'; id: string; name: string };
      leaseAgreement: {
        __typename?: 'LeaseAgreement';
        id: string;
        name: string;
        disabled?: boolean | null;
        landlordContactPersons: Array<{
          __typename?: 'User';
          id: string;
          name: string;
        }>;
        image?: { __typename?: 'File'; id: string; url: string } | null;
      };
      readBy: Array<{ __typename?: 'User'; name: string; id: string }>;
    }>;
  };
  declined: {
    __typename?: 'LeaseAgreementProspectData';
    count: number;
    prospects: Array<{
      __typename?: 'LeaseAgreementProspect';
      id: string;
      status: LeaseAgreementProspectStatus;
      adminStatus: LeaseAgreementProspectStatus;
      email: string;
      phone: string;
      company: string;
      message: string;
      created: any;
      landlord: { __typename?: 'Client'; id: string; name: string };
      leaseAgreement: {
        __typename?: 'LeaseAgreement';
        id: string;
        name: string;
        disabled?: boolean | null;
        landlordContactPersons: Array<{
          __typename?: 'User';
          id: string;
          name: string;
        }>;
        image?: { __typename?: 'File'; id: string; url: string } | null;
      };
      readBy: Array<{ __typename?: 'User'; name: string; id: string }>;
    }>;
  };
  accepted: {
    __typename?: 'LeaseAgreementProspectData';
    count: number;
    prospects: Array<{
      __typename?: 'LeaseAgreementProspect';
      id: string;
      status: LeaseAgreementProspectStatus;
      adminStatus: LeaseAgreementProspectStatus;
      email: string;
      phone: string;
      company: string;
      message: string;
      created: any;
      landlord: { __typename?: 'Client'; id: string; name: string };
      leaseAgreement: {
        __typename?: 'LeaseAgreement';
        id: string;
        name: string;
        disabled?: boolean | null;
        landlordContactPersons: Array<{
          __typename?: 'User';
          id: string;
          name: string;
        }>;
        image?: { __typename?: 'File'; id: string; url: string } | null;
      };
      readBy: Array<{ __typename?: 'User'; name: string; id: string }>;
    }>;
  };
};

export type LeaseAgreementAdminProspectValuesFragment = {
  __typename?: 'LeaseAgreementProspect';
  id: string;
  status: LeaseAgreementProspectStatus;
  adminStatus: LeaseAgreementProspectStatus;
  email: string;
  phone: string;
  company: string;
  message: string;
  created: any;
  landlord: { __typename?: 'Client'; id: string; name: string };
  leaseAgreement: {
    __typename?: 'LeaseAgreement';
    id: string;
    name: string;
    disabled?: boolean | null;
    landlordContactPersons: Array<{
      __typename?: 'User';
      id: string;
      name: string;
    }>;
    image?: { __typename?: 'File'; id: string; url: string } | null;
  };
  readBy: Array<{ __typename?: 'User'; name: string; id: string }>;
};

export type GetViewsQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;

export type GetViewsQuery = {
  __typename?: 'Query';
  views: {
    __typename?: 'LeaseAgreementViewData';
    count: number;
    views: Array<{
      __typename?: 'LeaseAgreementView';
      id: string;
      created: any;
      fromWebsite?: boolean | null;
      user?: {
        __typename?: 'User';
        id: string;
        name: string;
        activeClient?: {
          __typename?: 'Client';
          id: string;
          name: string;
        } | null;
      } | null;
      leaseAgreement?: {
        __typename?: 'LeaseAgreement';
        id: string;
        name: string;
        size?: number | null;
        title?: string | null;
        usageCategory?: Array<UsageCategory> | null;
        landlord?: { __typename?: 'Client'; id: string; name: string } | null;
        address: {
          __typename?: 'Address';
          street?: string | null;
          streetNumber?: string | null;
          city?: string | null;
          zipCode?: string | null;
        };
        sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
        image?: {
          __typename?: 'File';
          id: string;
          url: string;
          size: number;
          uid: string;
        } | null;
      } | null;
    }>;
  };
};

export type LeaseAgreementViewValuesFragment = {
  __typename?: 'LeaseAgreementView';
  id: string;
  created: any;
  fromWebsite?: boolean | null;
  user?: {
    __typename?: 'User';
    id: string;
    name: string;
    activeClient?: { __typename?: 'Client'; id: string; name: string } | null;
  } | null;
  leaseAgreement?: {
    __typename?: 'LeaseAgreement';
    id: string;
    name: string;
    size?: number | null;
    title?: string | null;
    usageCategory?: Array<UsageCategory> | null;
    landlord?: { __typename?: 'Client'; id: string; name: string } | null;
    address: {
      __typename?: 'Address';
      street?: string | null;
      streetNumber?: string | null;
      city?: string | null;
      zipCode?: string | null;
    };
    sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
    image?: {
      __typename?: 'File';
      id: string;
      url: string;
      size: number;
      uid: string;
    } | null;
  } | null;
};

export type AdminLeaseAgreementListValuesFragment = {
  __typename?: 'LeaseAgreement';
  fromVitec: boolean;
  created?: any | null;
  landlord?: { __typename?: 'Client'; id: string; name: string } | null;
  interestedUsers: Array<{
    __typename?: 'User';
    name: string;
    activeClient?: { __typename?: 'Client'; id: string; name: string } | null;
  }>;
  views: {
    __typename?: 'LeaseAgreementViewData';
    count: number;
    views: Array<{
      __typename?: 'LeaseAgreementView';
      id: string;
      user?: {
        __typename?: 'User';
        id: string;
        name: string;
        activeClient?: {
          __typename?: 'Client';
          id: string;
          name: string;
        } | null;
      } | null;
    }>;
  };
  matchingSearchRequests?: {
    __typename?: 'MatchingSearchRequests';
    count: number;
  } | null;
};

export type GetLeaseAgreementsQueryVariables = Exact<{
  input?: InputMaybe<LeaseAgreementFilter>;
  search?: InputMaybe<SearchProfileInput>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;

export type GetLeaseAgreementsQuery = {
  __typename?: 'Query';
  leaseAgreements: {
    __typename?: 'LeaseAgreementsResult';
    count: number;
    items: Array<{
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      title?: string | null;
      usageCategory?: Array<UsageCategory> | null;
      size?: number | null;
      myStatus?: string | null;
      location?: any | null;
      fromVitec: boolean;
      created?: any | null;
      address: { __typename?: 'Address'; city?: string | null };
      sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
      image?: {
        __typename?: 'File';
        url: string;
        name: string;
        id: string;
      } | null;
      landlordContactPersons: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        email: string;
        phone?: string | null;
        image?: {
          __typename?: 'File';
          url: string;
          id: string;
          name: string;
        } | null;
      }>;
      landlord?: {
        __typename?: 'Client';
        id: string;
        name: string;
        image?: { __typename?: 'File'; url: string; name: string } | null;
      } | null;
      integrationProvider?: {
        __typename?: 'IntegrationProvider';
        id: string;
        name: string;
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
      matchingSearchRequests?: {
        __typename?: 'MatchingSearchRequests';
        count: number;
      } | null;
      interestedUsers: Array<{
        __typename?: 'User';
        name: string;
        activeClient?: {
          __typename?: 'Client';
          id: string;
          name: string;
        } | null;
      }>;
      views: {
        __typename?: 'LeaseAgreementViewData';
        count: number;
        views: Array<{
          __typename?: 'LeaseAgreementView';
          id: string;
          user?: {
            __typename?: 'User';
            id: string;
            name: string;
            activeClient?: {
              __typename?: 'Client';
              id: string;
              name: string;
            } | null;
          } | null;
        }>;
      };
    }>;
  };
};

export type GetManagementLeaseAgreementsQueryVariables = Exact<{
  input?: InputMaybe<LeaseAgreementFilter>;
  zoom: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  bounds?: InputMaybe<Bounds>;
  personalized?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetManagementLeaseAgreementsQuery = {
  __typename?: 'Query';
  leaseAgreements: {
    __typename?: 'LeaseAgreementsResult';
    count: number;
    cluster: Array<{
      __typename?: 'Cluster';
      count?: number | null;
      id: string;
      location: any;
    }>;
    items: Array<{
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      title?: string | null;
      location?: any | null;
      usageCategory?: Array<UsageCategory> | null;
      estateId?: string | null;
      size?: number | null;
      landlord?: { __typename?: 'Client'; id: string } | null;
      landlordContactPersons: Array<{
        __typename?: 'User';
        id: string;
        name: string;
      }>;
      address: {
        __typename?: 'Address';
        city?: string | null;
        zipCode?: string | null;
      };
      sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
      image?: { __typename?: 'File'; id: string; url: string } | null;
      integrationProvider?: {
        __typename?: 'IntegrationProvider';
        id: string;
        name: string;
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
      matchingSearchRequests?: {
        __typename?: 'MatchingSearchRequests';
        count: number;
      } | null;
    }>;
  };
};

export type GetManagementPromotedQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetManagementPromotedQuery = {
  __typename?: 'Query';
  myPromotions?: Array<{
    __typename?: 'Promotion';
    reach: number;
    opened: number;
    leaseAgreement: {
      __typename?: 'LeaseAgreement';
      estateId?: string | null;
      id: string;
      name: string;
      title?: string | null;
      location?: any | null;
      usageCategory?: Array<UsageCategory> | null;
      size?: number | null;
      landlord?: { __typename?: 'Client'; id: string } | null;
      landlordContactPersons: Array<{
        __typename?: 'User';
        id: string;
        name: string;
      }>;
      address: {
        __typename?: 'Address';
        city?: string | null;
        zipCode?: string | null;
      };
      sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
      image?: { __typename?: 'File'; id: string; url: string } | null;
      integrationProvider?: {
        __typename?: 'IntegrationProvider';
        id: string;
        name: string;
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
      matchingSearchRequests?: {
        __typename?: 'MatchingSearchRequests';
        count: number;
      } | null;
    };
  } | null> | null;
};

export type LeaseAgreementManagementValuesFragment = {
  __typename?: 'LeaseAgreement';
  id: string;
  name: string;
  title?: string | null;
  location?: any | null;
  usageCategory?: Array<UsageCategory> | null;
  estateId?: string | null;
  size?: number | null;
  landlord?: { __typename?: 'Client'; id: string } | null;
  landlordContactPersons: Array<{
    __typename?: 'User';
    id: string;
    name: string;
  }>;
  address: {
    __typename?: 'Address';
    city?: string | null;
    zipCode?: string | null;
  };
  sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
  image?: { __typename?: 'File'; id: string; url: string } | null;
  integrationProvider?: {
    __typename?: 'IntegrationProvider';
    id: string;
    name: string;
    image?: { __typename?: 'File'; id: string; url: string } | null;
  } | null;
  matchingSearchRequests?: {
    __typename?: 'MatchingSearchRequests';
    count: number;
  } | null;
};

export type GetConversationFilterAutocompleteQueryVariables = Exact<{
  key: Scalars['String'];
}>;

export type GetConversationFilterAutocompleteQuery = {
  __typename?: 'Query';
  conversationFilterAutocomplete: {
    __typename?: 'ConversationFilterAutocomplete';
    clients: Array<{
      __typename?: 'AutoCompleteClient';
      label: string;
      value: string;
    }>;
    users: Array<{
      __typename?: 'AutoCompleteUser';
      label: string;
      value: string;
      client: string;
    }>;
  };
};

export type AddNewMessageMutationVariables = Exact<{
  input: NewMessageInput;
  messageType?: InputMaybe<MessageType>;
}>;

export type AddNewMessageMutation = {
  __typename?: 'Mutation';
  newMessage?: {
    __typename?: 'Message';
    id: string;
    conversationId: string;
    conversation: {
      __typename?: 'Conversation';
      id: string;
      messages: Array<{
        __typename?: 'Message';
        id: string;
        message?: string | null;
      }>;
    };
  } | null;
};

export type AttachmentValuesFragment = {
  __typename?: 'Attachment';
  id: string;
  attachmentId?: string | null;
  attachment?: {
    __typename?: 'File';
    id: string;
    name: string;
    size: number;
    url: string;
    mimetype: string;
    uid: string;
  } | null;
};

export type GetConversationsQueryVariables = Exact<{
  filter?: InputMaybe<ConversationFilter>;
}>;

export type GetConversationsQuery = {
  __typename?: 'Query';
  conversations?: {
    __typename?: 'ConversationData';
    count?: number | null;
    unread?: number | null;
    conversations: Array<{
      __typename?: 'Message';
      created?: any | null;
      message?: string | null;
      conversation: {
        __typename?: 'Conversation';
        id: string;
        archived?: boolean | null;
        unanswered?: boolean | null;
        participants: Array<{
          __typename?: 'Client';
          id: string;
          name: string;
          image?: { __typename?: 'File'; id: string; url: string } | null;
        }>;
        unread?: {
          __typename?: 'Unread';
          count?: number | null;
          attachments?: number | null;
          messages?: number | null;
        } | null;
        leaseAgreement?: {
          __typename?: 'LeaseAgreement';
          id: string;
          name: string;
          image?: { __typename?: 'File'; id: string; url: string } | null;
          landlordContactPersons: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            image?: { __typename?: 'File'; id: string; url: string } | null;
          }>;
        } | null;
        searchRequestReference?: {
          __typename?: 'SearchRequestReference';
          id: string;
          title?: string | null;
          status: SearchRequestStatusEnum;
          clientId?: string | null;
        } | null;
        searchRequest?: {
          __typename?: 'SearchRequest';
          responsibleUser?: {
            __typename?: 'User';
            id: string;
            name: string;
          } | null;
        } | null;
        opponents: Array<{
          __typename?: 'Client';
          id: string;
          name: string;
          image?: { __typename?: 'File'; id: string; url: string } | null;
        }>;
      };
      senderUser?: {
        __typename?: 'User';
        id: string;
        name: string;
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
      sender: { __typename?: 'Client'; id: string; name: string };
    } | null>;
  } | null;
};

export type ConversationTitleValuesFragment = {
  __typename?: 'Conversation';
  leaseAgreement?: {
    __typename?: 'LeaseAgreement';
    id: string;
    name: string;
    image?: { __typename?: 'File'; id: string; url: string } | null;
  } | null;
  searchRequestReference?: {
    __typename?: 'SearchRequestReference';
    id: string;
    title?: string | null;
    status: SearchRequestStatusEnum;
    clientId?: string | null;
  } | null;
  searchRequest?: {
    __typename?: 'SearchRequest';
    responsibleUser?: { __typename?: 'User'; id: string; name: string } | null;
  } | null;
  opponents: Array<{
    __typename?: 'Client';
    id: string;
    name: string;
    image?: { __typename?: 'File'; id: string; url: string } | null;
  }>;
};

export type MessageValuesFragment = {
  __typename?: 'Message';
  id: string;
  message?: string | null;
  created?: any | null;
  read?: boolean | null;
  readTimestamp?: any | null;
  conversationId: string;
  sender: { __typename?: 'Client'; id: string; name: string };
  senderUser?: {
    __typename?: 'User';
    id: string;
    name: string;
    image?: { __typename?: 'File'; id: string; url: string } | null;
  } | null;
  reactions?: Array<{
    __typename?: 'Reaction';
    userId: string;
    reaction: ReactionEnum;
    user: { __typename?: 'User'; name: string };
  } | null> | null;
  attachments: Array<{
    __typename?: 'File';
    id: string;
    name: string;
    size: number;
    url: string;
    mimetype: string;
    uid: string;
  }>;
  leaseAgreements: Array<{
    __typename?: 'LeaseAgreement';
    id: string;
    name: string;
    description?: string | null;
    title?: string | null;
    size?: number | null;
    usageCategory?: Array<UsageCategory> | null;
    image?: { __typename?: 'File'; id: string; url: string } | null;
    sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
    fee?: { __typename?: 'Fee'; amount: number; feeType: FeeType } | null;
  }>;
};

export type SubscribeToMessagesSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type SubscribeToMessagesSubscription = {
  __typename?: 'Subscription';
  newMessage: {
    __typename?: 'Message';
    id: string;
    message?: string | null;
    created?: any | null;
    read?: boolean | null;
    readTimestamp?: any | null;
    conversationId: string;
    conversation: {
      __typename?: 'Conversation';
      id: string;
      unread?: {
        __typename?: 'Unread';
        count?: number | null;
        messages?: number | null;
        attachments?: number | null;
      } | null;
    };
    sender: { __typename?: 'Client'; id: string; name: string };
    senderUser?: {
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
    reactions?: Array<{
      __typename?: 'Reaction';
      userId: string;
      reaction: ReactionEnum;
      user: { __typename?: 'User'; name: string };
    } | null> | null;
    attachments: Array<{
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    }>;
    leaseAgreements: Array<{
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      description?: string | null;
      title?: string | null;
      size?: number | null;
      usageCategory?: Array<UsageCategory> | null;
      image?: { __typename?: 'File'; id: string; url: string } | null;
      sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
      fee?: { __typename?: 'Fee'; amount: number; feeType: FeeType } | null;
    }>;
  };
};

export type GetNewsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;

export type GetNewsQuery = {
  __typename?: 'Query';
  news?: {
    __typename?: 'NewsData';
    count?: number | null;
    news?: Array<{
      __typename?: 'News';
      id: string;
      title: string;
      link: string;
      published: any;
      description: string;
      avatar: string;
      image?: string | null;
    } | null> | null;
  } | null;
};

export type GetEpisodesQueryVariables = Exact<{
  id: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetEpisodesQuery = {
  __typename?: 'Query';
  podcast?: {
    __typename?: 'Podcast';
    name: string;
    description: string;
    image: string;
    author: string;
    numberOfEpisodes?: number | null;
    episodes?: Array<{
      __typename?: 'Episode';
      id: string;
      title: string;
      description: string;
      published: any;
      link: string;
      image: string;
      duration?: string | null;
    } | null> | null;
  } | null;
};

export type EpisodeValuesFragment = {
  __typename?: 'Episode';
  id: string;
  title: string;
  description: string;
  published: any;
  link: string;
  image: string;
  duration?: string | null;
};

export type GetPodcastsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPodcastsQuery = {
  __typename?: 'Query';
  podcasts?: Array<{
    __typename?: 'Podcast';
    id: string;
    name: string;
    image: string;
    author: string;
    numberOfEpisodes?: number | null;
  } | null> | null;
};

export type PodcastValuesFragment = {
  __typename?: 'Podcast';
  id: string;
  name: string;
  image: string;
  author: string;
  numberOfEpisodes?: number | null;
};

export type EventFieldsFragment = {
  __typename?: 'Event';
  id: string;
  description?: string | null;
  responsibleConsultantId: string;
  date: any;
  done?: boolean | null;
  type: string;
  client?: { __typename?: 'Client'; id: string; name: string } | null;
  responsibleConsultant: { __typename?: 'User'; id: string; name: string };
};

export type GetEventsQueryVariables = Exact<{
  filter?: InputMaybe<EventFilter>;
}>;

export type GetEventsQuery = {
  __typename?: 'Query';
  events?: Array<{
    __typename?: 'Event';
    id: string;
    description?: string | null;
    responsibleConsultantId: string;
    date: any;
    done?: boolean | null;
    type: string;
    client?: { __typename?: 'Client'; id: string; name: string } | null;
    responsibleConsultant: { __typename?: 'User'; id: string; name: string };
  } | null> | null;
};

export type SetEventDoneMutationVariables = Exact<{
  id: Scalars['String'];
  done: Scalars['Boolean'];
}>;

export type SetEventDoneMutation = {
  __typename?: 'Mutation';
  setEventDone?: {
    __typename?: 'Event';
    id: string;
    description?: string | null;
    responsibleConsultantId: string;
    date: any;
    done?: boolean | null;
    type: string;
    client?: { __typename?: 'Client'; id: string; name: string } | null;
    responsibleConsultant: { __typename?: 'User'; id: string; name: string };
  } | null;
};

export type AdValuesFragment = {
  __typename?: 'PortallyAd';
  id: string;
  active: boolean;
  placement: AdPlacement;
  link?: string | null;
  usageCategories: Array<UsageCategory>;
  regionIds: Array<string>;
  image: {
    __typename?: 'File';
    id: string;
    name: string;
    size: number;
    url: string;
    mimetype: string;
    uid: string;
  };
  regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
  mobileImage?: {
    __typename?: 'File';
    id: string;
    name: string;
    size: number;
    url: string;
    mimetype: string;
    uid: string;
  } | null;
};

export type AddAdMutationVariables = Exact<{
  input: AdInput;
}>;

export type AddAdMutation = {
  __typename?: 'Mutation';
  addAd: {
    __typename?: 'PortallyAd';
    id: string;
    active: boolean;
    placement: AdPlacement;
    link?: string | null;
    usageCategories: Array<UsageCategory>;
    regionIds: Array<string>;
    image: {
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    };
    regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
    mobileImage?: {
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    } | null;
  };
};

export type AddPartnerMutationVariables = Exact<{
  input: AddPortallyPartnerInput;
}>;

export type AddPartnerMutation = {
  __typename?: 'Mutation';
  addPartner: { __typename?: 'PortallyPartner'; id: string; slug: string };
};

export type AddPartnerSectionMutationVariables = Exact<{
  slug: Scalars['String'];
  sectionInput: SectionInput;
  width?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
}>;

export type AddPartnerSectionMutation = {
  __typename?: 'Mutation';
  addPartnerSection: {
    __typename?: 'PortallyPartner';
    id: string;
    sections?: Array<{
      __typename?: 'Sections';
      title: string;
      text: string;
      displayResource: {
        __typename?: 'File';
        id: string;
        size: number;
        path: string;
        name: string;
        mimetype: string;
        url: string;
      };
    }> | null;
  };
};

export type DeleteAdMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteAdMutation = {
  __typename?: 'Mutation';
  deleteAd: { __typename?: 'PortallyAd'; id: string };
};

export type EditAdMutationVariables = Exact<{
  id: Scalars['ID'];
  input: AdInput;
}>;

export type EditAdMutation = {
  __typename?: 'Mutation';
  editAd: {
    __typename?: 'PortallyAd';
    id: string;
    active: boolean;
    placement: AdPlacement;
    link?: string | null;
    usageCategories: Array<UsageCategory>;
    regionIds: Array<string>;
    image: {
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    };
    regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
    mobileImage?: {
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    } | null;
  };
};

export type EditDisplayResourceMutationVariables = Exact<{
  slug: Scalars['String'];
  displayResourceId?: InputMaybe<Scalars['ID']>;
}>;

export type EditDisplayResourceMutation = {
  __typename?: 'Mutation';
  editDisplayResource: {
    __typename?: 'PortallyPartner';
    displayResource?: {
      __typename?: 'File';
      id: string;
      size: number;
      path: string;
      name: string;
      mimetype: string;
      url: string;
    } | null;
  };
};

export type GetPartnerStatisticsQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetPartnerStatisticsQuery = {
  __typename?: 'Query';
  portallyPartner: {
    __typename?: 'PortallyPartner';
    views: Array<{ __typename?: 'ActionCount'; count: number; date: any }>;
    ads?: Array<{
      __typename?: 'PortallyAd';
      placement: AdPlacement;
      websiteSearchQueryView?: Array<{
        __typename?: 'WebsiteSearchQuery';
        regionIds?: string | null;
        city?: string | null;
        min?: string | null;
        max?: string | null;
        usageCategories?: string | null;
      } | null> | null;
      websiteSearchQueryClick?: Array<{
        __typename?: 'WebsiteSearchQuery';
        regionIds?: string | null;
        city?: string | null;
        min?: string | null;
        max?: string | null;
        usageCategories?: string | null;
      } | null> | null;
      viewedBy: Array<{
        __typename?: 'User';
        firstName: string;
        lastName?: string | null;
        activeClient?: {
          __typename?: 'Client';
          type?: ClientType | null;
        } | null;
      }>;
      clickedBy: Array<{
        __typename?: 'User';
        firstName: string;
        lastName?: string | null;
        activeClient?: {
          __typename?: 'Client';
          type?: ClientType | null;
        } | null;
      }>;
      views: Array<{ __typename?: 'ActionCount'; count: number; date: any }>;
      clicks: Array<{ __typename?: 'ActionCount'; count: number; date: any }>;
    }> | null;
  };
};

export type GetPortallyPartnerQueryVariables = Exact<{
  slug: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
}>;

export type GetPortallyPartnerQuery = {
  __typename?: 'Query';
  portallyPartner: {
    __typename?: 'PortallyPartner';
    id: string;
    active?: boolean | null;
    name: string;
    slug: string;
    contactPerson: {
      __typename?: 'ContactPerson';
      firstName: string;
      lastName: string;
      professionalTitle: string;
      email: string;
      image: {
        __typename?: 'File';
        id: string;
        name: string;
        path: string;
        url: string;
        mimetype: string;
        size: number;
      };
    };
    client: {
      __typename?: 'Client';
      id: string;
      name: string;
      image?: {
        __typename?: 'File';
        id: string;
        size: number;
        path: string;
        name: string;
        mimetype: string;
        url: string;
      } | null;
    };
    displayResource?: {
      __typename?: 'File';
      id: string;
      size: number;
      path: string;
      name: string;
      mimetype: string;
      url: string;
    } | null;
    sections?: Array<{
      __typename?: 'Sections';
      id: string;
      title: string;
      text: string;
      targetGroup?: Array<string | null> | null;
      displayResource: {
        __typename?: 'File';
        id: string;
        size: number;
        path: string;
        name: string;
        mimetype: string;
        url: string;
      };
    }> | null;
    ads?: Array<{
      __typename?: 'PortallyAd';
      id: string;
      active: boolean;
      placement: AdPlacement;
      link?: string | null;
      usageCategories: Array<UsageCategory>;
      regionIds: Array<string>;
      image: {
        __typename?: 'File';
        id: string;
        name: string;
        size: number;
        url: string;
        mimetype: string;
        uid: string;
      };
      regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
      mobileImage?: {
        __typename?: 'File';
        id: string;
        name: string;
        size: number;
        url: string;
        mimetype: string;
        uid: string;
      } | null;
    }> | null;
  };
};

export type RemovePartnerSectionMutationVariables = Exact<{
  slug: Scalars['String'];
  sectionId: Scalars['ID'];
}>;

export type RemovePartnerSectionMutation = {
  __typename?: 'Mutation';
  removePartnerSection: { __typename?: 'PortallyPartner'; id: string };
};

export type SendMessageToPartnerMutationVariables = Exact<{
  messageToPartnerInput: MessageToPartnerInput;
}>;

export type SendMessageToPartnerMutation = {
  __typename?: 'Mutation';
  sendMessageToPartner?: boolean | null;
};

export type ToggleActiveAdMutationVariables = Exact<{
  id: Scalars['ID'];
  active: Scalars['Boolean'];
}>;

export type ToggleActiveAdMutation = {
  __typename?: 'Mutation';
  editAd: { __typename?: 'PortallyAd'; id: string; active: boolean };
};

export type ToggleActivePartnerMutationVariables = Exact<{
  active: Scalars['Boolean'];
  slug: Scalars['String'];
}>;

export type ToggleActivePartnerMutation = {
  __typename?: 'Mutation';
  editPortallyPartner: {
    __typename?: 'PortallyPartner';
    id: string;
    active?: boolean | null;
  };
};

export type ViewPartnerMutationVariables = Exact<{
  slug: Scalars['String'];
}>;

export type ViewPartnerMutation = {
  __typename?: 'Mutation';
  viewPartner: boolean;
};

export type EditPartnerMutationVariables = Exact<{
  slug: Scalars['String'];
  input: EditPortallyPartnerInput;
}>;

export type EditPartnerMutation = {
  __typename?: 'Mutation';
  editPortallyPartner: { __typename?: 'PortallyPartner'; id: string };
};

export type GetAdsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdsQuery = {
  __typename?: 'Query';
  portallyAds: Array<{
    __typename?: 'PortallyAd';
    id: string;
    active: boolean;
    placement: AdPlacement;
    link?: string | null;
    usageCategories: Array<UsageCategory>;
    regionIds: Array<string>;
    image: {
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    };
    regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
    mobileImage?: {
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    } | null;
  }>;
};

export type GetPartnerStatsQueryVariables = Exact<{
  partnerSlug: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
}>;

export type GetPartnerStatsQuery = {
  __typename?: 'Query';
  getPartnerStats?: string | null;
};

export type GetPortallyPartnersQueryVariables = Exact<{ [key: string]: never }>;

export type GetPortallyPartnersQuery = {
  __typename?: 'Query';
  portallyPartners: Array<{
    __typename?: 'PortallyPartner';
    id: string;
    clientId: string;
    name: string;
    slug: string;
    active?: boolean | null;
    contactPerson: {
      __typename?: 'ContactPerson';
      firstName: string;
      lastName: string;
      email: string;
      professionalTitle: string;
      image: {
        __typename?: 'File';
        id: string;
        name: string;
        path: string;
        url: string;
        mimetype: string;
        size: number;
      };
    };
    client: { __typename?: 'Client'; name: string };
  }>;
};

export type DeletePartnerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeletePartnerMutation = {
  __typename?: 'Mutation';
  deletePartner: { __typename?: 'PortallyPartner'; id: string };
};

export type GetConversionRateQueryVariables = Exact<{
  from?: InputMaybe<Scalars['Date']>;
  to?: InputMaybe<Scalars['Date']>;
}>;

export type GetConversionRateQuery = {
  __typename?: 'Query';
  conversionRate: {
    __typename?: 'ConversionRateData';
    averageSent: number;
    incoming: number;
    outgoing: number;
    removed: number;
  };
};

export type GetPreliminarySearchRequestsQueryVariables = Exact<{
  unhandledLimit: Scalars['Int'];
  unhandledSkip: Scalars['Int'];
  removedLimit: Scalars['Int'];
  removedSkip: Scalars['Int'];
  unhandledFilter: SearchRequestAdminFilter;
  removedFilter: SearchRequestAdminFilter;
}>;

export type GetPreliminarySearchRequestsQuery = {
  __typename?: 'Query';
  unhandled: {
    __typename?: 'SearchRequestAdminData';
    count: number;
    searchRequests: Array<{
      __typename?: 'SearchRequestReference';
      id: string;
      title?: string | null;
      description?: string | null;
      areaInformation?: string | null;
      businessInformation?: string | null;
      specificNeedsInformation?: string | null;
      sentDate: any;
      budget?: number | null;
      employees?: number | null;
      accessV2?: AccessEnumV2 | null;
      status: SearchRequestStatusEnum;
      improveSearchMailSent?: boolean | null;
      fromWebsite: boolean;
      clientId?: string | null;
      key: string;
      searchParameters: {
        __typename?: 'SearchParameters';
        usageCategories: Array<string>;
        geometry?: any | null;
        regionIds?: Array<string> | null;
        size?: {
          __typename?: 'Range';
          min?: number | null;
          max?: number | null;
        } | null;
        bounds?: {
          __typename?: 'RegionBounds';
          north: number;
          south: number;
          east: number;
          west: number;
        } | null;
        regions: Array<{
          __typename?: 'Region';
          id: string;
          name: string;
          geometry?: any | null;
          regionType?: RegionTypeEnum | null;
          center?: any | null;
        }>;
      };
      client?: {
        __typename?: 'Client';
        id: string;
        name: string;
        type?: ClientType | null;
        group?: string | null;
        email?: string | null;
        telephone?: string | null;
        orgNumber?: string | null;
        imageId?: string | null;
        companyName?: string | null;
        address?: string | null;
        location?: string | null;
        website?: string | null;
        parentCompany?: string | null;
        parentCompanyOrgNumber?: string | null;
        responsibleConsultantId?: string | null;
        comment?: string | null;
        description?: string | null;
        numberOfEmployees?: number | null;
        numberOfUnits?: number | null;
        industryCategory?: string | null;
        industryCode?: string | null;
        typeOfBusiness?: TypeOfBusiness | null;
        showOnWebsite?: boolean | null;
        notifySupport?: boolean | null;
        supportStatus?: string | null;
        vitecLicenceIds: Array<string>;
        objektvisionId?: string | null;
        objektvisionIds: Array<string>;
        includeObjektvisionEmails: Array<string>;
        lokalguidenId?: string | null;
        showPageOnWebsite?: boolean | null;
        slug?: string | null;
        websiteName?: string | null;
        limeApiKey?: string | null;
        isPartner?: boolean | null;
        websiteDescription?: string | null;
        ebieScore?: {
          __typename?: 'EbieScore';
          rating: number;
          tier: string;
        } | null;
        image?: {
          __typename?: 'File';
          id: string;
          name: string;
          url: string;
          size: number;
        } | null;
        postAddress?: {
          __typename?: 'Address';
          address?: string | null;
          street?: string | null;
          streetNumber?: string | null;
          zipCode?: string | null;
          city?: string | null;
        } | null;
        visitingAddress?: {
          __typename?: 'Address';
          address?: string | null;
          street?: string | null;
          streetNumber?: string | null;
          zipCode?: string | null;
          city?: string | null;
        } | null;
        responsibleConsultant?: { __typename?: 'User'; id: string } | null;
        estimatedSize?: {
          __typename?: 'Range';
          min?: number | null;
          max?: number | null;
        } | null;
        invoiceDetails?: {
          __typename?: 'InvoiceDetails';
          billing?: string | null;
          company?: string | null;
          marking?: string | null;
          reference?: string | null;
          email?: string | null;
        } | null;
        websiteImage?: {
          __typename?: 'File';
          id: string;
          name: string;
          url: string;
          size: number;
        } | null;
      } | null;
      contactPerson?: {
        __typename?: 'User';
        id: string;
        email: string;
        verified: boolean;
        access?: any | null;
        name: string;
        leaseAgreementViews: {
          __typename?: 'LeaseAgreementViewData';
          count: number;
          views: Array<{
            __typename?: 'LeaseAgreementView';
            id: string;
            created: any;
            fromWebsite?: boolean | null;
            user?: {
              __typename?: 'User';
              id: string;
              name: string;
              activeClient?: {
                __typename?: 'Client';
                id: string;
                name: string;
              } | null;
            } | null;
            leaseAgreement?: {
              __typename?: 'LeaseAgreement';
              id: string;
              name: string;
              size?: number | null;
              title?: string | null;
              usageCategory?: Array<UsageCategory> | null;
              landlord?: {
                __typename?: 'Client';
                id: string;
                name: string;
              } | null;
              address: {
                __typename?: 'Address';
                street?: string | null;
                streetNumber?: string | null;
                city?: string | null;
                zipCode?: string | null;
              };
              sizeSpan?: {
                __typename?: 'SizeSpan';
                from: number;
                to: number;
              } | null;
              image?: {
                __typename?: 'File';
                id: string;
                url: string;
                size: number;
                uid: string;
              } | null;
            } | null;
          }>;
        };
      } | null;
      budgetSpan?: {
        __typename?: 'Range';
        min?: number | null;
        max?: number | null;
      } | null;
      employeesSpan?: {
        __typename?: 'Range';
        min?: number | null;
        max?: number | null;
      } | null;
      regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
      access?: { __typename?: 'Access'; quarter: Quarter; year: string } | null;
      contactInformation?: {
        __typename?: 'ContactInformation';
        email?: string | null;
        phone?: string | null;
        company?: string | null;
      } | null;
      documents: Array<{
        __typename?: 'File';
        id: string;
        name: string;
        size: number;
        url: string;
        mimetype: string;
        uid: string;
      }>;
      disabledReason?: {
        __typename?: 'DisableSearchRequestReason';
        reason: DisableSearchRequestReasonEnum;
      } | null;
    }>;
  };
  removed: {
    __typename?: 'SearchRequestAdminData';
    count: number;
    searchRequests: Array<{
      __typename?: 'SearchRequestReference';
      id: string;
      title?: string | null;
      description?: string | null;
      areaInformation?: string | null;
      businessInformation?: string | null;
      specificNeedsInformation?: string | null;
      sentDate: any;
      budget?: number | null;
      employees?: number | null;
      accessV2?: AccessEnumV2 | null;
      status: SearchRequestStatusEnum;
      improveSearchMailSent?: boolean | null;
      fromWebsite: boolean;
      clientId?: string | null;
      key: string;
      searchParameters: {
        __typename?: 'SearchParameters';
        usageCategories: Array<string>;
        geometry?: any | null;
        regionIds?: Array<string> | null;
        size?: {
          __typename?: 'Range';
          min?: number | null;
          max?: number | null;
        } | null;
        bounds?: {
          __typename?: 'RegionBounds';
          north: number;
          south: number;
          east: number;
          west: number;
        } | null;
        regions: Array<{
          __typename?: 'Region';
          id: string;
          name: string;
          geometry?: any | null;
          regionType?: RegionTypeEnum | null;
          center?: any | null;
        }>;
      };
      client?: {
        __typename?: 'Client';
        id: string;
        name: string;
        type?: ClientType | null;
        group?: string | null;
        email?: string | null;
        telephone?: string | null;
        orgNumber?: string | null;
        imageId?: string | null;
        companyName?: string | null;
        address?: string | null;
        location?: string | null;
        website?: string | null;
        parentCompany?: string | null;
        parentCompanyOrgNumber?: string | null;
        responsibleConsultantId?: string | null;
        comment?: string | null;
        description?: string | null;
        numberOfEmployees?: number | null;
        numberOfUnits?: number | null;
        industryCategory?: string | null;
        industryCode?: string | null;
        typeOfBusiness?: TypeOfBusiness | null;
        showOnWebsite?: boolean | null;
        notifySupport?: boolean | null;
        supportStatus?: string | null;
        vitecLicenceIds: Array<string>;
        objektvisionId?: string | null;
        objektvisionIds: Array<string>;
        includeObjektvisionEmails: Array<string>;
        lokalguidenId?: string | null;
        showPageOnWebsite?: boolean | null;
        slug?: string | null;
        websiteName?: string | null;
        limeApiKey?: string | null;
        isPartner?: boolean | null;
        websiteDescription?: string | null;
        ebieScore?: {
          __typename?: 'EbieScore';
          rating: number;
          tier: string;
        } | null;
        image?: {
          __typename?: 'File';
          id: string;
          name: string;
          url: string;
          size: number;
        } | null;
        postAddress?: {
          __typename?: 'Address';
          address?: string | null;
          street?: string | null;
          streetNumber?: string | null;
          zipCode?: string | null;
          city?: string | null;
        } | null;
        visitingAddress?: {
          __typename?: 'Address';
          address?: string | null;
          street?: string | null;
          streetNumber?: string | null;
          zipCode?: string | null;
          city?: string | null;
        } | null;
        responsibleConsultant?: { __typename?: 'User'; id: string } | null;
        estimatedSize?: {
          __typename?: 'Range';
          min?: number | null;
          max?: number | null;
        } | null;
        invoiceDetails?: {
          __typename?: 'InvoiceDetails';
          billing?: string | null;
          company?: string | null;
          marking?: string | null;
          reference?: string | null;
          email?: string | null;
        } | null;
        websiteImage?: {
          __typename?: 'File';
          id: string;
          name: string;
          url: string;
          size: number;
        } | null;
      } | null;
      contactPerson?: {
        __typename?: 'User';
        id: string;
        email: string;
        verified: boolean;
        access?: any | null;
        name: string;
        leaseAgreementViews: {
          __typename?: 'LeaseAgreementViewData';
          count: number;
          views: Array<{
            __typename?: 'LeaseAgreementView';
            id: string;
            created: any;
            fromWebsite?: boolean | null;
            user?: {
              __typename?: 'User';
              id: string;
              name: string;
              activeClient?: {
                __typename?: 'Client';
                id: string;
                name: string;
              } | null;
            } | null;
            leaseAgreement?: {
              __typename?: 'LeaseAgreement';
              id: string;
              name: string;
              size?: number | null;
              title?: string | null;
              usageCategory?: Array<UsageCategory> | null;
              landlord?: {
                __typename?: 'Client';
                id: string;
                name: string;
              } | null;
              address: {
                __typename?: 'Address';
                street?: string | null;
                streetNumber?: string | null;
                city?: string | null;
                zipCode?: string | null;
              };
              sizeSpan?: {
                __typename?: 'SizeSpan';
                from: number;
                to: number;
              } | null;
              image?: {
                __typename?: 'File';
                id: string;
                url: string;
                size: number;
                uid: string;
              } | null;
            } | null;
          }>;
        };
      } | null;
      budgetSpan?: {
        __typename?: 'Range';
        min?: number | null;
        max?: number | null;
      } | null;
      employeesSpan?: {
        __typename?: 'Range';
        min?: number | null;
        max?: number | null;
      } | null;
      regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
      access?: { __typename?: 'Access'; quarter: Quarter; year: string } | null;
      contactInformation?: {
        __typename?: 'ContactInformation';
        email?: string | null;
        phone?: string | null;
        company?: string | null;
      } | null;
      documents: Array<{
        __typename?: 'File';
        id: string;
        name: string;
        size: number;
        url: string;
        mimetype: string;
        uid: string;
      }>;
      disabledReason?: {
        __typename?: 'DisableSearchRequestReason';
        reason: DisableSearchRequestReasonEnum;
      } | null;
    }>;
  };
};

export type SearchRequestReferencePreviewValuesFragment = {
  __typename?: 'SearchRequestReference';
  id: string;
  title?: string | null;
  description?: string | null;
  areaInformation?: string | null;
  businessInformation?: string | null;
  specificNeedsInformation?: string | null;
  sentDate: any;
  budget?: number | null;
  employees?: number | null;
  accessV2?: AccessEnumV2 | null;
  status: SearchRequestStatusEnum;
  improveSearchMailSent?: boolean | null;
  fromWebsite: boolean;
  clientId?: string | null;
  key: string;
  searchParameters: {
    __typename?: 'SearchParameters';
    usageCategories: Array<string>;
    geometry?: any | null;
    regionIds?: Array<string> | null;
    size?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
    bounds?: {
      __typename?: 'RegionBounds';
      north: number;
      south: number;
      east: number;
      west: number;
    } | null;
    regions: Array<{
      __typename?: 'Region';
      id: string;
      name: string;
      geometry?: any | null;
      regionType?: RegionTypeEnum | null;
      center?: any | null;
    }>;
  };
  client?: {
    __typename?: 'Client';
    id: string;
    name: string;
    type?: ClientType | null;
    group?: string | null;
    email?: string | null;
    telephone?: string | null;
    orgNumber?: string | null;
    imageId?: string | null;
    companyName?: string | null;
    address?: string | null;
    location?: string | null;
    website?: string | null;
    parentCompany?: string | null;
    parentCompanyOrgNumber?: string | null;
    responsibleConsultantId?: string | null;
    comment?: string | null;
    description?: string | null;
    numberOfEmployees?: number | null;
    numberOfUnits?: number | null;
    industryCategory?: string | null;
    industryCode?: string | null;
    typeOfBusiness?: TypeOfBusiness | null;
    showOnWebsite?: boolean | null;
    notifySupport?: boolean | null;
    supportStatus?: string | null;
    vitecLicenceIds: Array<string>;
    objektvisionId?: string | null;
    objektvisionIds: Array<string>;
    includeObjektvisionEmails: Array<string>;
    lokalguidenId?: string | null;
    showPageOnWebsite?: boolean | null;
    slug?: string | null;
    websiteName?: string | null;
    limeApiKey?: string | null;
    isPartner?: boolean | null;
    websiteDescription?: string | null;
    ebieScore?: {
      __typename?: 'EbieScore';
      rating: number;
      tier: string;
    } | null;
    image?: {
      __typename?: 'File';
      id: string;
      name: string;
      url: string;
      size: number;
    } | null;
    postAddress?: {
      __typename?: 'Address';
      address?: string | null;
      street?: string | null;
      streetNumber?: string | null;
      zipCode?: string | null;
      city?: string | null;
    } | null;
    visitingAddress?: {
      __typename?: 'Address';
      address?: string | null;
      street?: string | null;
      streetNumber?: string | null;
      zipCode?: string | null;
      city?: string | null;
    } | null;
    responsibleConsultant?: { __typename?: 'User'; id: string } | null;
    estimatedSize?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
    invoiceDetails?: {
      __typename?: 'InvoiceDetails';
      billing?: string | null;
      company?: string | null;
      marking?: string | null;
      reference?: string | null;
      email?: string | null;
    } | null;
    websiteImage?: {
      __typename?: 'File';
      id: string;
      name: string;
      url: string;
      size: number;
    } | null;
  } | null;
  contactPerson?: {
    __typename?: 'User';
    id: string;
    email: string;
    verified: boolean;
    access?: any | null;
    name: string;
    leaseAgreementViews: {
      __typename?: 'LeaseAgreementViewData';
      count: number;
      views: Array<{
        __typename?: 'LeaseAgreementView';
        id: string;
        created: any;
        fromWebsite?: boolean | null;
        user?: {
          __typename?: 'User';
          id: string;
          name: string;
          activeClient?: {
            __typename?: 'Client';
            id: string;
            name: string;
          } | null;
        } | null;
        leaseAgreement?: {
          __typename?: 'LeaseAgreement';
          id: string;
          name: string;
          size?: number | null;
          title?: string | null;
          usageCategory?: Array<UsageCategory> | null;
          landlord?: { __typename?: 'Client'; id: string; name: string } | null;
          address: {
            __typename?: 'Address';
            street?: string | null;
            streetNumber?: string | null;
            city?: string | null;
            zipCode?: string | null;
          };
          sizeSpan?: {
            __typename?: 'SizeSpan';
            from: number;
            to: number;
          } | null;
          image?: {
            __typename?: 'File';
            id: string;
            url: string;
            size: number;
            uid: string;
          } | null;
        } | null;
      }>;
    };
  } | null;
  budgetSpan?: {
    __typename?: 'Range';
    min?: number | null;
    max?: number | null;
  } | null;
  employeesSpan?: {
    __typename?: 'Range';
    min?: number | null;
    max?: number | null;
  } | null;
  regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
  access?: { __typename?: 'Access'; quarter: Quarter; year: string } | null;
  contactInformation?: {
    __typename?: 'ContactInformation';
    email?: string | null;
    phone?: string | null;
    company?: string | null;
  } | null;
  documents: Array<{
    __typename?: 'File';
    id: string;
    name: string;
    size: number;
    url: string;
    mimetype: string;
    uid: string;
  }>;
  disabledReason?: {
    __typename?: 'DisableSearchRequestReason';
    reason: DisableSearchRequestReasonEnum;
  } | null;
};

export type RemoveSearchRequestReferenceMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type RemoveSearchRequestReferenceMutation = {
  __typename?: 'Mutation';
  changeSearchRequestStatus: {
    __typename?: 'SearchRequestReference';
    id: string;
    status: SearchRequestStatusEnum;
  };
};

export type ResetSearchRequestReferenceMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type ResetSearchRequestReferenceMutation = {
  __typename?: 'Mutation';
  changeSearchRequestStatus: {
    __typename?: 'SearchRequestReference';
    id: string;
    status: SearchRequestStatusEnum;
  };
};

export type SendImproveSearchMailMutationVariables = Exact<{
  input: MoreInformationMail;
}>;

export type SendImproveSearchMailMutation = {
  __typename?: 'Mutation';
  sendImproveSearchMail: {
    __typename?: 'SearchRequestReference';
    id: string;
    improveSearchMailSent?: boolean | null;
  };
};

export type SendPassiveSearchRequestMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type SendPassiveSearchRequestMutation = {
  __typename?: 'Mutation';
  changeSearchRequestStatus: {
    __typename?: 'SearchRequestReference';
    id: string;
    status: SearchRequestStatusEnum;
  };
};

export type SendSearchRequestMutationVariables = Exact<{
  id: Scalars['String'];
  sendInput: SendSearchRequestInput;
}>;

export type SendSearchRequestMutation = {
  __typename?: 'Mutation';
  sendSearchRequest: { __typename?: 'SearchRequestReference'; id: string };
};

export type GetProjectsQueryVariables = Exact<{ [key: string]: never }>;

export type GetProjectsQuery = {
  __typename?: 'Query';
  projects: Array<{
    __typename?: 'Project';
    id: string;
    name: string;
    landlords: Array<{ __typename?: 'Client'; id: string; name: string }>;
    leaseAgreements: Array<{ __typename?: 'LeaseAgreement'; id: string }>;
  }>;
};

export type AddProjectMutationVariables = Exact<{
  input: ProjectInput;
}>;

export type AddProjectMutation = {
  __typename?: 'Mutation';
  addProject: {
    __typename?: 'Project';
    id: string;
    landlordIds: Array<string>;
    leaseAgreements: Array<{ __typename?: 'LeaseAgreement'; id: string }>;
  };
};

export type EditProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ProjectInput;
}>;

export type EditProjectMutation = {
  __typename?: 'Mutation';
  editProject: {
    __typename?: 'Project';
    id: string;
    landlordIds: Array<string>;
    leaseAgreements: Array<{ __typename?: 'LeaseAgreement'; id: string }>;
  };
};

export type GetProspectsQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  clientManagerId?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  division?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  step?: InputMaybe<Array<Step> | Step>;
}>;

export type GetProspectsQuery = {
  __typename?: 'Query';
  prospects: {
    __typename?: 'AllProspectData';
    prospectData?: {
      __typename?: 'Prospects';
      prospects: Array<{
        __typename?: 'Prospect';
        id: string;
        name?: string | null;
        created?: any | null;
        clientId?: string | null;
        source?: Array<string> | null;
        sourceComment?: string | null;
        contactId?: string | null;
        potentialValue?: number | null;
        startDate?: any | null;
        service?: string | null;
        division?: string | null;
        step?: string | null;
        comment?: string | null;
        weightedValue?: number | null;
        crossBorder?: boolean | null;
        daysInCurrentStep?: number | null;
        key: string;
        contact?: { __typename?: 'Contact'; id: string; name: string } | null;
        client?: { __typename?: 'Client'; name: string; id: string } | null;
        clientManager?: {
          __typename?: 'User';
          name: string;
          id: string;
        } | null;
        orderData?: {
          __typename?: 'OrderData';
          reason?: Array<string | null> | null;
          comment?: string | null;
          blikkNumber?: number | null;
          orderValue?: number | null;
        } | null;
        lostOrderData?: {
          __typename?: 'LostOrderData';
          reason?: Array<string | null> | null;
          comment?: string | null;
        } | null;
      }>;
    } | null;
    overviewData?: Array<{
      __typename?: 'OverviewData';
      label?: string | null;
      value?: number | null;
    }> | null;
  };
};

export type GetAveragesTimeInStepDataQueryVariables = Exact<{
  period?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
}>;

export type GetAveragesTimeInStepDataQuery = {
  __typename?: 'Query';
  prospects: {
    __typename?: 'AllProspectData';
    averagesData?: Array<{
      __typename?: 'GraphData';
      title?: string | null;
      labels?: Array<string | null> | null;
      values?: Array<number | null> | null;
    }> | null;
    timeAverageInStepData?: Array<{
      __typename?: 'TimeAverageInStepData';
      label?: string | null;
      value?: number | null;
    }> | null;
    timeAverageToOrderData?: {
      __typename?: 'TimeAverageToOrderData';
      leadToOrder?: number | null;
      bookedMeetingToOrder?: number | null;
    } | null;
  };
};

export type GetDivisionBarQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  clientManagerId?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  division?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  step?: InputMaybe<Array<Step> | Step>;
}>;

export type GetDivisionBarQuery = {
  __typename?: 'Query';
  prospects: {
    __typename?: 'AllProspectData';
    prospectData?: {
      __typename?: 'Prospects';
      prospects: Array<{
        __typename?: 'Prospect';
        id: string;
        name?: string | null;
        created?: any | null;
        clientId?: string | null;
        source?: Array<string> | null;
        sourceComment?: string | null;
        contactId?: string | null;
        potentialValue?: number | null;
        startDate?: any | null;
        service?: string | null;
        division?: string | null;
        step?: string | null;
        comment?: string | null;
        weightedValue?: number | null;
        crossBorder?: boolean | null;
        daysInCurrentStep?: number | null;
        key: string;
        contact?: { __typename?: 'Contact'; id: string; name: string } | null;
        client?: { __typename?: 'Client'; name: string; id: string } | null;
        clientManager?: {
          __typename?: 'User';
          name: string;
          id: string;
        } | null;
        orderData?: {
          __typename?: 'OrderData';
          reason?: Array<string | null> | null;
          comment?: string | null;
          blikkNumber?: number | null;
          orderValue?: number | null;
        } | null;
        lostOrderData?: {
          __typename?: 'LostOrderData';
          reason?: Array<string | null> | null;
          comment?: string | null;
        } | null;
      }>;
    } | null;
    divisionData?: Array<{
      __typename?: 'DivisionValues';
      title: string;
      items: Array<{
        __typename?: 'Prospect';
        name?: string | null;
        potentialValue?: number | null;
        weightedValue?: number | null;
      } | null>;
    }> | null;
  };
};

export type GetProcessBarQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  clientManagerId?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  division?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  step?: InputMaybe<Array<Step> | Step>;
  weightedValues?: InputMaybe<Scalars['Boolean']>;
  withCrossBorder?: InputMaybe<Scalars['Boolean']>;
  onlyCrossBorder?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetProcessBarQuery = {
  __typename?: 'Query';
  prospects: {
    __typename?: 'AllProspectData';
    prospectData?: {
      __typename?: 'Prospects';
      prospects: Array<{
        __typename?: 'Prospect';
        id: string;
        name?: string | null;
        created?: any | null;
        clientId?: string | null;
        source?: Array<string> | null;
        sourceComment?: string | null;
        contactId?: string | null;
        potentialValue?: number | null;
        startDate?: any | null;
        service?: string | null;
        division?: string | null;
        step?: string | null;
        comment?: string | null;
        weightedValue?: number | null;
        crossBorder?: boolean | null;
        daysInCurrentStep?: number | null;
        key: string;
        contact?: { __typename?: 'Contact'; id: string; name: string } | null;
        client?: { __typename?: 'Client'; name: string; id: string } | null;
        clientManager?: {
          __typename?: 'User';
          name: string;
          id: string;
        } | null;
        orderData?: {
          __typename?: 'OrderData';
          reason?: Array<string | null> | null;
          comment?: string | null;
          blikkNumber?: number | null;
          orderValue?: number | null;
        } | null;
        lostOrderData?: {
          __typename?: 'LostOrderData';
          reason?: Array<string | null> | null;
          comment?: string | null;
        } | null;
      }>;
    } | null;
    processValuesData: {
      __typename?: 'TotalProcessValues';
      totalValue?: number | null;
      processValuesData?: Array<{
        __typename?: 'ProcessValuesData';
        label?: string | null;
        value?: number | null;
        percentageOfTotal?: number | null;
      }> | null;
    };
  };
};

export type GetProcessOverTimeQueryVariables = Exact<{
  period?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetProcessOverTimeQuery = {
  __typename?: 'Query';
  prospects: {
    __typename?: 'AllProspectData';
    processData?: Array<{
      __typename?: 'GraphData';
      labels?: Array<string | null> | null;
      values?: Array<number | null> | null;
      title?: string | null;
    }> | null;
  };
};

export type GetProspectCsvQueryVariables = Exact<{
  prospects: Array<ProspectCsvInput> | ProspectCsvInput;
  probability: Probability;
}>;

export type GetProspectCsvQuery = {
  __typename?: 'Query';
  getProspectCsv?: string | null;
};

export type GetProspectListQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['String']>;
  clientManagerId?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  division?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  step?: InputMaybe<Array<Step> | Step>;
  source?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetProspectListQuery = {
  __typename?: 'Query';
  prospects: {
    __typename?: 'AllProspectData';
    prospectData?: {
      __typename?: 'Prospects';
      prospects: Array<{
        __typename?: 'Prospect';
        id: string;
        name?: string | null;
        created?: any | null;
        clientId?: string | null;
        source?: Array<string> | null;
        sourceComment?: string | null;
        contactId?: string | null;
        potentialValue?: number | null;
        startDate?: any | null;
        service?: string | null;
        division?: string | null;
        step?: string | null;
        comment?: string | null;
        weightedValue?: number | null;
        crossBorder?: boolean | null;
        daysInCurrentStep?: number | null;
        key: string;
        contact?: { __typename?: 'Contact'; id: string; name: string } | null;
        client?: { __typename?: 'Client'; name: string; id: string } | null;
        clientManager?: {
          __typename?: 'User';
          name: string;
          id: string;
        } | null;
        orderData?: {
          __typename?: 'OrderData';
          reason?: Array<string | null> | null;
          comment?: string | null;
          blikkNumber?: number | null;
          orderValue?: number | null;
        } | null;
        lostOrderData?: {
          __typename?: 'LostOrderData';
          reason?: Array<string | null> | null;
          comment?: string | null;
        } | null;
      }>;
    } | null;
  };
};

export type GetProspectStockCountDataQueryVariables = Exact<{
  period?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetProspectStockCountDataQuery = {
  __typename?: 'Query';
  prospects: {
    __typename?: 'AllProspectData';
    prospectStockCountData?: Array<{
      __typename?: 'GraphData';
      title?: string | null;
      labels?: Array<string | null> | null;
      values?: Array<number | null> | null;
    }> | null;
  };
};

export type GetProspectStockDataQueryVariables = Exact<{
  period?: InputMaybe<Scalars['String']>;
  weightedValues?: InputMaybe<Scalars['Boolean']>;
  withCrossBorder?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetProspectStockDataQuery = {
  __typename?: 'Query';
  prospects: {
    __typename?: 'AllProspectData';
    prospectStockData?: Array<{
      __typename?: 'GraphData';
      title?: string | null;
      labels?: Array<string | null> | null;
      values?: Array<number | null> | null;
    }> | null;
  };
};

export type GetProspectStockDataOldQueryVariables = Exact<{
  period?: InputMaybe<Scalars['String']>;
  weightedValues?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetProspectStockDataOldQuery = {
  __typename?: 'Query';
  prospects: {
    __typename?: 'AllProspectData';
    prospectStockDataOld?: Array<{
      __typename?: 'GraphData';
      title?: string | null;
      labels?: Array<string | null> | null;
      values?: Array<number | null> | null;
    }> | null;
  };
};

export type GetReasonForLostOrderDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetReasonForLostOrderDataQuery = {
  __typename?: 'Query';
  reasonForLostOrderData: Array<{
    __typename?: 'Reasons';
    label?: string | null;
    value?: number | null;
    percentageOfTotal?: number | null;
  } | null>;
};

export type GetReasonForOrderDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetReasonForOrderDataQuery = {
  __typename?: 'Query';
  reasonForOrderData: Array<{
    __typename?: 'Reasons';
    label?: string | null;
    value?: number | null;
    percentageOfTotal?: number | null;
  } | null>;
};

export type ProspectValuesFragment = {
  __typename?: 'Prospect';
  id: string;
  name?: string | null;
  created?: any | null;
  clientId?: string | null;
  source?: Array<string> | null;
  sourceComment?: string | null;
  contactId?: string | null;
  potentialValue?: number | null;
  startDate?: any | null;
  service?: string | null;
  division?: string | null;
  step?: string | null;
  comment?: string | null;
  weightedValue?: number | null;
  crossBorder?: boolean | null;
  daysInCurrentStep?: number | null;
  key: string;
  contact?: { __typename?: 'Contact'; id: string; name: string } | null;
  client?: { __typename?: 'Client'; name: string; id: string } | null;
  clientManager?: { __typename?: 'User'; name: string; id: string } | null;
  orderData?: {
    __typename?: 'OrderData';
    reason?: Array<string | null> | null;
    comment?: string | null;
    blikkNumber?: number | null;
    orderValue?: number | null;
  } | null;
  lostOrderData?: {
    __typename?: 'LostOrderData';
    reason?: Array<string | null> | null;
    comment?: string | null;
  } | null;
};

export type AddRegionMutationVariables = Exact<{
  input: RegionsInput;
}>;

export type AddRegionMutation = {
  __typename?: 'Mutation';
  addRegion: {
    __typename?: 'Region';
    name: string;
    level?: number | null;
    geometry?: any | null;
    country?: CountryEnum | null;
    id: string;
    regionType?: RegionTypeEnum | null;
    prioritized?: boolean | null;
    filter?: boolean | null;
    bounds?: {
      __typename?: 'RegionBounds';
      north: number;
      west: number;
      south: number;
      east: number;
    } | null;
    parents: Array<{ __typename?: 'Region'; id: string; name: string }>;
    information: Array<{
      __typename?: 'RegionInformation';
      id: string;
      title: string;
      description: string;
      usageCategories: Array<UsageCategory>;
      image?: {
        __typename?: 'File';
        id: string;
        name: string;
        size: number;
        url: string;
        mimetype: string;
        uid: string;
      } | null;
    }>;
  };
};

export type AddRegionInformationMutationVariables = Exact<{
  regionId: Scalars['String'];
  information: RegionInformationInput;
}>;

export type AddRegionInformationMutation = {
  __typename?: 'Mutation';
  addRegionInformation: {
    __typename?: 'Region';
    name: string;
    level?: number | null;
    geometry?: any | null;
    country?: CountryEnum | null;
    id: string;
    regionType?: RegionTypeEnum | null;
    prioritized?: boolean | null;
    filter?: boolean | null;
    bounds?: {
      __typename?: 'RegionBounds';
      north: number;
      west: number;
      south: number;
      east: number;
    } | null;
    parents: Array<{ __typename?: 'Region'; id: string; name: string }>;
    information: Array<{
      __typename?: 'RegionInformation';
      id: string;
      title: string;
      description: string;
      usageCategories: Array<UsageCategory>;
      image?: {
        __typename?: 'File';
        id: string;
        name: string;
        size: number;
        url: string;
        mimetype: string;
        uid: string;
      } | null;
    }>;
  };
};

export type DeleteRegionMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteRegionMutation = {
  __typename?: 'Mutation';
  deleteRegion: { __typename?: 'Region'; id: string; name: string };
};

export type DeleteRegionInformationMutationVariables = Exact<{
  informationId: Scalars['String'];
}>;

export type DeleteRegionInformationMutation = {
  __typename?: 'Mutation';
  deleteRegionInformation: {
    __typename?: 'Region';
    name: string;
    level?: number | null;
    geometry?: any | null;
    country?: CountryEnum | null;
    id: string;
    regionType?: RegionTypeEnum | null;
    prioritized?: boolean | null;
    filter?: boolean | null;
    bounds?: {
      __typename?: 'RegionBounds';
      north: number;
      west: number;
      south: number;
      east: number;
    } | null;
    parents: Array<{ __typename?: 'Region'; id: string; name: string }>;
    information: Array<{
      __typename?: 'RegionInformation';
      id: string;
      title: string;
      description: string;
      usageCategories: Array<UsageCategory>;
      image?: {
        __typename?: 'File';
        id: string;
        name: string;
        size: number;
        url: string;
        mimetype: string;
        uid: string;
      } | null;
    }>;
  };
};

export type EditRegionMutationVariables = Exact<{
  id: Scalars['String'];
  input: RegionsInput;
}>;

export type EditRegionMutation = {
  __typename?: 'Mutation';
  editRegion: {
    __typename?: 'Region';
    name: string;
    level?: number | null;
    geometry?: any | null;
    country?: CountryEnum | null;
    id: string;
    regionType?: RegionTypeEnum | null;
    prioritized?: boolean | null;
    filter?: boolean | null;
    bounds?: {
      __typename?: 'RegionBounds';
      north: number;
      west: number;
      south: number;
      east: number;
    } | null;
    parents: Array<{ __typename?: 'Region'; id: string; name: string }>;
    information: Array<{
      __typename?: 'RegionInformation';
      id: string;
      title: string;
      description: string;
      usageCategories: Array<UsageCategory>;
      image?: {
        __typename?: 'File';
        id: string;
        name: string;
        size: number;
        url: string;
        mimetype: string;
        uid: string;
      } | null;
    }>;
  };
};

export type EditRegionInformationMutationVariables = Exact<{
  informationId: Scalars['String'];
  information: RegionInformationInput;
}>;

export type EditRegionInformationMutation = {
  __typename?: 'Mutation';
  editRegionInformation: {
    __typename?: 'Region';
    name: string;
    level?: number | null;
    geometry?: any | null;
    country?: CountryEnum | null;
    id: string;
    regionType?: RegionTypeEnum | null;
    prioritized?: boolean | null;
    filter?: boolean | null;
    bounds?: {
      __typename?: 'RegionBounds';
      north: number;
      west: number;
      south: number;
      east: number;
    } | null;
    parents: Array<{ __typename?: 'Region'; id: string; name: string }>;
    information: Array<{
      __typename?: 'RegionInformation';
      id: string;
      title: string;
      description: string;
      usageCategories: Array<UsageCategory>;
      image?: {
        __typename?: 'File';
        id: string;
        name: string;
        size: number;
        url: string;
        mimetype: string;
        uid: string;
      } | null;
    }>;
  };
};

export type GetRegionQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetRegionQuery = {
  __typename?: 'Query';
  getRegion: {
    __typename?: 'Region';
    name: string;
    level?: number | null;
    geometry?: any | null;
    country?: CountryEnum | null;
    id: string;
    regionType?: RegionTypeEnum | null;
    prioritized?: boolean | null;
    filter?: boolean | null;
    bounds?: {
      __typename?: 'RegionBounds';
      north: number;
      west: number;
      south: number;
      east: number;
    } | null;
    parents: Array<{ __typename?: 'Region'; id: string; name: string }>;
    information: Array<{
      __typename?: 'RegionInformation';
      id: string;
      title: string;
      description: string;
      usageCategories: Array<UsageCategory>;
      image?: {
        __typename?: 'File';
        id: string;
        name: string;
        size: number;
        url: string;
        mimetype: string;
        uid: string;
      } | null;
    }>;
  };
};

export type GetRegionsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  filter: RegionFilter;
}>;

export type GetRegionsQuery = {
  __typename?: 'Query';
  getRegions: {
    __typename?: 'RegionData';
    count: number;
    regions: Array<{
      __typename?: 'Region';
      name: string;
      level?: number | null;
      geometry?: any | null;
      country?: CountryEnum | null;
      id: string;
      regionType?: RegionTypeEnum | null;
      prioritized?: boolean | null;
      filter?: boolean | null;
      bounds?: {
        __typename?: 'RegionBounds';
        north: number;
        west: number;
        south: number;
        east: number;
      } | null;
      parents: Array<{ __typename?: 'Region'; id: string; name: string }>;
      information: Array<{
        __typename?: 'RegionInformation';
        id: string;
        title: string;
        description: string;
        usageCategories: Array<UsageCategory>;
        image?: {
          __typename?: 'File';
          id: string;
          name: string;
          size: number;
          url: string;
          mimetype: string;
          uid: string;
        } | null;
      }>;
    }>;
  };
};

export type RegionValuesFragment = {
  __typename?: 'Region';
  name: string;
  level?: number | null;
  geometry?: any | null;
  country?: CountryEnum | null;
  id: string;
  regionType?: RegionTypeEnum | null;
  prioritized?: boolean | null;
  filter?: boolean | null;
  bounds?: {
    __typename?: 'RegionBounds';
    north: number;
    west: number;
    south: number;
    east: number;
  } | null;
  parents: Array<{ __typename?: 'Region'; id: string; name: string }>;
  information: Array<{
    __typename?: 'RegionInformation';
    id: string;
    title: string;
    description: string;
    usageCategories: Array<UsageCategory>;
    image?: {
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    } | null;
  }>;
};

export type AddSaleClientMutationVariables = Exact<{
  clientId: Scalars['String'];
}>;

export type AddSaleClientMutation = {
  __typename?: 'Mutation';
  addSaleClient: { __typename?: 'SaleClient'; client?: string | null };
};

export type DeleteSaleClientMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteSaleClientMutation = {
  __typename?: 'Mutation';
  deleteSaleClient: {
    __typename?: 'SaleClient';
    clientId?: string | null;
    client?: string | null;
  };
};

export type EditSaleClientMutationVariables = Exact<{
  id: Scalars['String'];
  input?: InputMaybe<SaleClientInput>;
}>;

export type EditSaleClientMutation = {
  __typename?: 'Mutation';
  editSaleClient: { __typename?: 'SaleClient'; clientId?: string | null };
};

export type GetSaleClientGoalsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSaleClientGoalsQuery = {
  __typename?: 'Query';
  goals: {
    __typename?: 'SubscriptionGoals';
    subscribersCount?: number | null;
    december?: {
      __typename?: 'Forecast';
      averagePerDay?: number | null;
      willReachGoal?: boolean | null;
      averagePerDayNeeded?: number | null;
      goal?: number | null;
    } | null;
    october?: {
      __typename?: 'Forecast';
      willReachGoal?: boolean | null;
      averagePerDay?: number | null;
      averagePerDayNeeded?: number | null;
      goal?: number | null;
    } | null;
  };
};

export type GetSaleClientsQueryVariables = Exact<{
  clientManagerId?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
  stair?: InputMaybe<Scalars['String']>;
  subscription?: InputMaybe<Scalars['String']>;
  oneOfHundred?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  fetchAwaiting?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetSaleClientsQuery = {
  __typename?: 'Query';
  saleClients: {
    __typename?: 'SaleClients';
    count: number;
    dollarCount: number;
    totalIncome?: number | null;
    clients: Array<{
      __typename?: 'SaleClient';
      id: string;
      clientManager?: string | null;
      clientManagerId?: string | null;
      client?: string | null;
      income?: number | null;
      tag?: string | null;
      clientId?: string | null;
      stair?: string | null;
      comment?: string | null;
      created?: any | null;
      inStairSince?: any | null;
      subscription?: string | null;
      oneOfHundred?: boolean | null;
      nextPayment?: any | null;
      subscriptionStart?: any | null;
      subscriptionEnd?: any | null;
      daysToNextPayment?: number | null;
      daysLeftSubscription?: number | null;
      files?: Array<{
        __typename?: 'File';
        id: string;
        name: string;
        url: string;
        size: number;
      } | null> | null;
    }>;
  };
};

export type GetMySearchRequestsLandlordQueryVariables = Exact<{
  usageCategories: Array<Scalars['String']> | Scalars['String'];
  unansweredLimit?: InputMaybe<Scalars['Int']>;
  unansweredSkip?: InputMaybe<Scalars['Int']>;
  regionIds: Array<Scalars['String']> | Scalars['String'];
  size: Array<SizeFilter> | SizeFilter;
  sort?: InputMaybe<Sort>;
  search?: InputMaybe<Scalars['String']>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetMySearchRequestsLandlordQuery = {
  __typename?: 'Query';
  mySearchRequestsLandlord?: {
    __typename?: 'SearchRequestLandlordData';
    unanswered: {
      __typename?: 'SearchRequestReferenceData';
      count: number;
      searchRequests: Array<{
        __typename?: 'SearchRequestReference';
        id: string;
        title?: string | null;
        myStatus: StatusEnum;
        disabledDate?: any | null;
        status: SearchRequestStatusEnum;
        budget?: number | null;
        employees?: number | null;
        created: any;
        searchRequestReferenceId: string;
        client?: { __typename?: 'Client'; id: string; name: string } | null;
        disabledReason?: {
          __typename?: 'DisableSearchRequestReason';
          reason: DisableSearchRequestReasonEnum;
        } | null;
        searchRequests: {
          __typename?: 'SearchRequestData';
          searchRequests: Array<{
            __typename?: 'SearchRequest';
            status: StatusEnum;
            conversationId?: string | null;
            responsibleUser?: {
              __typename?: 'User';
              id: string;
              name: string;
              image?: { __typename?: 'File'; id: string; url: string } | null;
            } | null;
          }>;
        };
        searchParameters: {
          __typename?: 'SearchParameters';
          usageCategories: Array<string>;
          regionIds?: Array<string> | null;
          regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
          size?: {
            __typename?: 'Range';
            min?: number | null;
            max?: number | null;
          } | null;
        };
        budgetSpan?: {
          __typename?: 'Range';
          min?: number | null;
          max?: number | null;
        } | null;
        employeesSpan?: {
          __typename?: 'Range';
          min?: number | null;
          max?: number | null;
        } | null;
      }>;
    };
    declined: {
      __typename?: 'SearchRequestData';
      count?: number | null;
      searchRequests: Array<{
        __typename?: 'SearchRequest';
        id: string;
        created: any;
        clientId: string;
        conversationId?: string | null;
        status: StatusEnum;
        answeredDate?: any | null;
        step: SearchRequestStep;
        stepWeight: number;
        comments: number;
        searchRequestReference: {
          __typename?: 'SearchRequestReference';
          id: string;
          title?: string | null;
          myStatus: StatusEnum;
          disabledDate?: any | null;
          status: SearchRequestStatusEnum;
          budget?: number | null;
          employees?: number | null;
          created: any;
          searchRequestReferenceId: string;
          client?: { __typename?: 'Client'; id: string; name: string } | null;
          disabledReason?: {
            __typename?: 'DisableSearchRequestReason';
            reason: DisableSearchRequestReasonEnum;
          } | null;
          searchRequests: {
            __typename?: 'SearchRequestData';
            searchRequests: Array<{
              __typename?: 'SearchRequest';
              status: StatusEnum;
              conversationId?: string | null;
              responsibleUser?: {
                __typename?: 'User';
                id: string;
                name: string;
                image?: { __typename?: 'File'; id: string; url: string } | null;
              } | null;
            }>;
          };
          searchParameters: {
            __typename?: 'SearchParameters';
            usageCategories: Array<string>;
            regionIds?: Array<string> | null;
            regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
            size?: {
              __typename?: 'Range';
              min?: number | null;
              max?: number | null;
            } | null;
          };
          budgetSpan?: {
            __typename?: 'Range';
            min?: number | null;
            max?: number | null;
          } | null;
          employeesSpan?: {
            __typename?: 'Range';
            min?: number | null;
            max?: number | null;
          } | null;
        };
      }>;
    };
    accepted: {
      __typename?: 'SearchRequestData';
      count?: number | null;
      searchRequests: Array<{
        __typename?: 'SearchRequest';
        id: string;
        created: any;
        clientId: string;
        conversationId?: string | null;
        status: StatusEnum;
        answeredDate?: any | null;
        step: SearchRequestStep;
        stepWeight: number;
        comments: number;
        searchRequestReference: {
          __typename?: 'SearchRequestReference';
          id: string;
          title?: string | null;
          myStatus: StatusEnum;
          disabledDate?: any | null;
          status: SearchRequestStatusEnum;
          budget?: number | null;
          employees?: number | null;
          created: any;
          searchRequestReferenceId: string;
          client?: { __typename?: 'Client'; id: string; name: string } | null;
          disabledReason?: {
            __typename?: 'DisableSearchRequestReason';
            reason: DisableSearchRequestReasonEnum;
          } | null;
          searchRequests: {
            __typename?: 'SearchRequestData';
            searchRequests: Array<{
              __typename?: 'SearchRequest';
              status: StatusEnum;
              conversationId?: string | null;
              responsibleUser?: {
                __typename?: 'User';
                id: string;
                name: string;
                image?: { __typename?: 'File'; id: string; url: string } | null;
              } | null;
            }>;
          };
          searchParameters: {
            __typename?: 'SearchParameters';
            usageCategories: Array<string>;
            regionIds?: Array<string> | null;
            regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
            size?: {
              __typename?: 'Range';
              min?: number | null;
              max?: number | null;
            } | null;
          };
          budgetSpan?: {
            __typename?: 'Range';
            min?: number | null;
            max?: number | null;
          } | null;
          employeesSpan?: {
            __typename?: 'Range';
            min?: number | null;
            max?: number | null;
          } | null;
        };
      }>;
    };
    disabled: {
      __typename?: 'SearchRequestReferenceData';
      count: number;
      searchRequests: Array<{
        __typename?: 'SearchRequestReference';
        id: string;
        title?: string | null;
        myStatus: StatusEnum;
        disabledDate?: any | null;
        status: SearchRequestStatusEnum;
        budget?: number | null;
        employees?: number | null;
        created: any;
        searchRequestReferenceId: string;
        client?: { __typename?: 'Client'; id: string; name: string } | null;
        disabledReason?: {
          __typename?: 'DisableSearchRequestReason';
          reason: DisableSearchRequestReasonEnum;
        } | null;
        searchRequests: {
          __typename?: 'SearchRequestData';
          searchRequests: Array<{
            __typename?: 'SearchRequest';
            status: StatusEnum;
            conversationId?: string | null;
            responsibleUser?: {
              __typename?: 'User';
              id: string;
              name: string;
              image?: { __typename?: 'File'; id: string; url: string } | null;
            } | null;
          }>;
        };
        searchParameters: {
          __typename?: 'SearchParameters';
          usageCategories: Array<string>;
          regionIds?: Array<string> | null;
          regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
          size?: {
            __typename?: 'Range';
            min?: number | null;
            max?: number | null;
          } | null;
        };
        budgetSpan?: {
          __typename?: 'Range';
          min?: number | null;
          max?: number | null;
        } | null;
        employeesSpan?: {
          __typename?: 'Range';
          min?: number | null;
          max?: number | null;
        } | null;
      }>;
    };
  } | null;
};

export type SearchRequestReferenceListValuesFragment = {
  __typename?: 'SearchRequestReference';
  id: string;
  title?: string | null;
  myStatus: StatusEnum;
  disabledDate?: any | null;
  status: SearchRequestStatusEnum;
  budget?: number | null;
  employees?: number | null;
  created: any;
  searchRequestReferenceId: string;
  client?: { __typename?: 'Client'; id: string; name: string } | null;
  disabledReason?: {
    __typename?: 'DisableSearchRequestReason';
    reason: DisableSearchRequestReasonEnum;
  } | null;
  searchRequests: {
    __typename?: 'SearchRequestData';
    searchRequests: Array<{
      __typename?: 'SearchRequest';
      status: StatusEnum;
      conversationId?: string | null;
      responsibleUser?: {
        __typename?: 'User';
        id: string;
        name: string;
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
    }>;
  };
  searchParameters: {
    __typename?: 'SearchParameters';
    usageCategories: Array<string>;
    regionIds?: Array<string> | null;
    regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
    size?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
  };
  budgetSpan?: {
    __typename?: 'Range';
    min?: number | null;
    max?: number | null;
  } | null;
  employeesSpan?: {
    __typename?: 'Range';
    min?: number | null;
    max?: number | null;
  } | null;
};

export type SearchRequestLandlordValuesFragment = {
  __typename?: 'SearchRequest';
  id: string;
  created: any;
  clientId: string;
  conversationId?: string | null;
  status: StatusEnum;
  answeredDate?: any | null;
  step: SearchRequestStep;
  stepWeight: number;
  comments: number;
  searchRequestReference: {
    __typename?: 'SearchRequestReference';
    id: string;
    title?: string | null;
    myStatus: StatusEnum;
    disabledDate?: any | null;
    status: SearchRequestStatusEnum;
    budget?: number | null;
    employees?: number | null;
    created: any;
    searchRequestReferenceId: string;
    client?: { __typename?: 'Client'; id: string; name: string } | null;
    disabledReason?: {
      __typename?: 'DisableSearchRequestReason';
      reason: DisableSearchRequestReasonEnum;
    } | null;
    searchRequests: {
      __typename?: 'SearchRequestData';
      searchRequests: Array<{
        __typename?: 'SearchRequest';
        status: StatusEnum;
        conversationId?: string | null;
        responsibleUser?: {
          __typename?: 'User';
          id: string;
          name: string;
          image?: { __typename?: 'File'; id: string; url: string } | null;
        } | null;
      }>;
    };
    searchParameters: {
      __typename?: 'SearchParameters';
      usageCategories: Array<string>;
      regionIds?: Array<string> | null;
      regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
      size?: {
        __typename?: 'Range';
        min?: number | null;
        max?: number | null;
      } | null;
    };
    budgetSpan?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
    employeesSpan?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
  };
};

export type GetSearchResultQueryVariables = Exact<{
  search?: InputMaybe<SearchProfileInput>;
  bounds?: InputMaybe<Bounds>;
  zoom?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetSearchResultQuery = {
  __typename?: 'Query';
  leaseAgreements: {
    __typename?: 'LeaseAgreementsResult';
    count: number;
    regionPolygons: Array<any | null>;
    showResults?: boolean | null;
    items: Array<{
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
      title?: string | null;
      usageCategory?: Array<UsageCategory> | null;
      size?: number | null;
      myStatus?: string | null;
      location?: any | null;
      address: { __typename?: 'Address'; city?: string | null };
      sizeSpan?: { __typename?: 'SizeSpan'; from: number; to: number } | null;
      image?: {
        __typename?: 'File';
        url: string;
        name: string;
        id: string;
      } | null;
      landlordContactPersons: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        email: string;
        phone?: string | null;
        image?: {
          __typename?: 'File';
          url: string;
          id: string;
          name: string;
        } | null;
      }>;
      landlord?: {
        __typename?: 'Client';
        id: string;
        name: string;
        image?: { __typename?: 'File'; url: string; name: string } | null;
      } | null;
      integrationProvider?: {
        __typename?: 'IntegrationProvider';
        id: string;
        name: string;
        image?: { __typename?: 'File'; id: string; url: string } | null;
      } | null;
      matchingSearchRequests?: {
        __typename?: 'MatchingSearchRequests';
        count: number;
      } | null;
    }>;
    cluster: Array<{
      __typename?: 'Cluster';
      count?: number | null;
      id: string;
      location: any;
    }>;
    regionBounds?: {
      __typename?: 'ResultBounds';
      south: number;
      north: number;
      east: number;
      west: number;
    } | null;
    commerce?: Array<{
      __typename?: 'Region';
      id: string;
      name: string;
      center?: any | null;
    } | null> | null;
  };
};

export type EditContactPersonMutationVariables = Exact<{
  searchRequestReferenceId: Scalars['String'];
  userId: Scalars['String'];
}>;

export type EditContactPersonMutation = {
  __typename?: 'Mutation';
  editContactPerson: {
    __typename?: 'SearchRequestReference';
    id: string;
    contactPerson?: { __typename?: 'User'; id: string; name: string } | null;
  };
};

export type GetReceiversQueryVariables = Exact<{
  id: Scalars['String'];
  status: Array<StatusEnum> | StatusEnum;
}>;

export type GetReceiversQuery = {
  __typename?: 'Query';
  searchRequestReference: {
    __typename?: 'SearchRequestReference';
    searchRequests: {
      __typename?: 'SearchRequestData';
      count?: number | null;
      searchRequests: Array<{
        __typename?: 'SearchRequest';
        id: string;
        status: StatusEnum;
        responsibleUser?: {
          __typename?: 'User';
          id: string;
          name: string;
          image?: { __typename?: 'File'; id: string; url: string } | null;
        } | null;
        receiver: {
          __typename?: 'Client';
          id: string;
          name: string;
          image?: { __typename?: 'File'; id: string; url: string } | null;
        };
        conversation?: {
          __typename?: 'Conversation';
          id: string;
          opponents: Array<{
            __typename?: 'Client';
            id: string;
            name: string;
            image?: { __typename?: 'File'; id: string; url: string } | null;
          }>;
          unread?: { __typename?: 'Unread'; count?: number | null } | null;
          searchRequest?: {
            __typename?: 'SearchRequest';
            id: string;
            responsibleUser?: {
              __typename?: 'User';
              id: string;
              name: string;
            } | null;
          } | null;
          leaseAgreement?: {
            __typename?: 'LeaseAgreement';
            id: string;
            name: string;
          } | null;
          messages: Array<{
            __typename?: 'Message';
            created?: any | null;
            message?: string | null;
            senderUser?: {
              __typename?: 'User';
              id: string;
              name: string;
              image?: { __typename?: 'File'; id: string; url: string } | null;
            } | null;
          }>;
        } | null;
      }>;
    };
  };
};

export type GetSearchRequestReferenceQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetSearchRequestReferenceQuery = {
  __typename?: 'Query';
  searchRequestReference: {
    __typename?: 'SearchRequestReference';
    id: string;
    sentDate: any;
    improveSearchMailSent?: boolean | null;
    title?: string | null;
    description?: string | null;
    areaInformation?: string | null;
    specificNeedsInformation?: string | null;
    businessInformation?: string | null;
    budget?: number | null;
    employees?: number | null;
    accessV2?: AccessEnumV2 | null;
    clientId?: string | null;
    status: SearchRequestStatusEnum;
    key: string;
    stats: {
      __typename?: 'StatusCount';
      answer_requested: number;
      opened: number;
      accepted: number;
      declined: number;
    };
    budgetSpan?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
    employeesSpan?: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    } | null;
    access?: { __typename?: 'Access'; quarter: Quarter; year: string } | null;
    contactPerson?: { __typename?: 'User'; id: string; name: string } | null;
    searchParameters: {
      __typename?: 'SearchParameters';
      geometry?: any | null;
      regionIds?: Array<string> | null;
      usageCategories: Array<string>;
      bounds?: {
        __typename?: 'RegionBounds';
        north: number;
        south: number;
        east: number;
        west: number;
      } | null;
      regions: Array<{
        __typename?: 'Region';
        id: string;
        name: string;
        geometry?: any | null;
        regionType?: RegionTypeEnum | null;
        center?: any | null;
      }>;
      size?: {
        __typename?: 'Range';
        max?: number | null;
        min?: number | null;
      } | null;
    };
    client?: {
      __typename?: 'Client';
      id: string;
      name: string;
      ebieScore?: {
        __typename?: 'EbieScore';
        rating: number;
        tier: string;
      } | null;
    } | null;
    documents: Array<{
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      url: string;
      mimetype: string;
      uid: string;
    }>;
    disabledReason?: {
      __typename?: 'DisableSearchRequestReason';
      reason: DisableSearchRequestReasonEnum;
    } | null;
  };
};

export type SearchRequestConversationDataValuesFragment = {
  __typename?: 'ConversationData';
  count?: number | null;
  unread?: number | null;
  conversations: Array<{
    __typename?: 'Message';
    id: string;
    conversationId: string;
    created?: any | null;
    conversation: {
      __typename?: 'Conversation';
      id: string;
      opponents: Array<{
        __typename?: 'Client';
        id: string;
        name: string;
        image?: { __typename?: 'File'; id: string; url: string } | null;
      }>;
      unread?: { __typename?: 'Unread'; count?: number | null } | null;
      searchRequest?: {
        __typename?: 'SearchRequest';
        id: string;
        responsibleUser?: {
          __typename?: 'User';
          id: string;
          name: string;
        } | null;
      } | null;
      leaseAgreement?: {
        __typename?: 'LeaseAgreement';
        id: string;
        name: string;
      } | null;
      messages: Array<{
        __typename?: 'Message';
        created?: any | null;
        message?: string | null;
        senderUser?: {
          __typename?: 'User';
          id: string;
          name: string;
          image?: { __typename?: 'File'; id: string; url: string } | null;
        } | null;
      }>;
    };
  } | null>;
};

export type SearchRequestConversationValuesFragment = {
  __typename?: 'Conversation';
  id: string;
  opponents: Array<{
    __typename?: 'Client';
    id: string;
    name: string;
    image?: { __typename?: 'File'; id: string; url: string } | null;
  }>;
  unread?: { __typename?: 'Unread'; count?: number | null } | null;
  searchRequest?: {
    __typename?: 'SearchRequest';
    id: string;
    responsibleUser?: { __typename?: 'User'; id: string; name: string } | null;
  } | null;
  leaseAgreement?: {
    __typename?: 'LeaseAgreement';
    id: string;
    name: string;
  } | null;
  messages: Array<{
    __typename?: 'Message';
    created?: any | null;
    message?: string | null;
    senderUser?: {
      __typename?: 'User';
      id: string;
      name: string;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
  }>;
};

export type SearchRequestReferenceInformationValuesFragment = {
  __typename?: 'SearchRequestReference';
  id: string;
  title?: string | null;
  description?: string | null;
  areaInformation?: string | null;
  specificNeedsInformation?: string | null;
  businessInformation?: string | null;
  budget?: number | null;
  employees?: number | null;
  accessV2?: AccessEnumV2 | null;
  sentDate: any;
  clientId?: string | null;
  status: SearchRequestStatusEnum;
  key: string;
  budgetSpan?: {
    __typename?: 'Range';
    min?: number | null;
    max?: number | null;
  } | null;
  employeesSpan?: {
    __typename?: 'Range';
    min?: number | null;
    max?: number | null;
  } | null;
  access?: { __typename?: 'Access'; quarter: Quarter; year: string } | null;
  contactPerson?: { __typename?: 'User'; id: string; name: string } | null;
  searchParameters: {
    __typename?: 'SearchParameters';
    geometry?: any | null;
    regionIds?: Array<string> | null;
    usageCategories: Array<string>;
    bounds?: {
      __typename?: 'RegionBounds';
      north: number;
      south: number;
      east: number;
      west: number;
    } | null;
    regions: Array<{
      __typename?: 'Region';
      id: string;
      name: string;
      geometry?: any | null;
      regionType?: RegionTypeEnum | null;
      center?: any | null;
    }>;
    size?: {
      __typename?: 'Range';
      max?: number | null;
      min?: number | null;
    } | null;
  };
  client?: {
    __typename?: 'Client';
    id: string;
    name: string;
    ebieScore?: {
      __typename?: 'EbieScore';
      rating: number;
      tier: string;
    } | null;
  } | null;
  documents: Array<{
    __typename?: 'File';
    id: string;
    name: string;
    size: number;
    url: string;
    mimetype: string;
    uid: string;
  }>;
  disabledReason?: {
    __typename?: 'DisableSearchRequestReason';
    reason: DisableSearchRequestReasonEnum;
  } | null;
};

export type EditSearchRequestAdminMutationVariables = Exact<{
  id: Scalars['String'];
  input: SearchRequestAdminInput;
}>;

export type EditSearchRequestAdminMutation = {
  __typename?: 'Mutation';
  editSearchRequestAdmin: {
    __typename?: 'SearchRequestReference';
    id: string;
    favourite?: boolean | null;
  };
};

export type GetClientConversationsQueryVariables = Exact<{
  id: Scalars['String'];
  limit: Scalars['Int'];
  includeDeclined?: InputMaybe<Scalars['Boolean']>;
  searchRequestReferenceId?: InputMaybe<Scalars['String']>;
}>;

export type GetClientConversationsQuery = {
  __typename?: 'Query';
  client: {
    __typename?: 'Client';
    id: string;
    conversationStatistics: {
      __typename?: 'ConversationStatistics';
      count: number;
      unanswered: number;
      conversations: Array<{
        __typename?: 'Conversation';
        id: string;
        searchRequest?: {
          __typename?: 'SearchRequest';
          id: string;
          title: string;
          status: StatusEnum;
        } | null;
        leaseAgreement?: {
          __typename?: 'LeaseAgreement';
          id: string;
          name: string;
        } | null;
        participants: Array<{
          __typename?: 'Client';
          id: string;
          name: string;
        }>;
        messages: Array<{
          __typename?: 'Message';
          id: string;
          created?: any | null;
          readTimestamp?: any | null;
          sender: { __typename?: 'Client'; id: string; name: string };
          senderUser?: { __typename?: 'User'; id: string; name: string } | null;
          reactions?: Array<{
            __typename?: 'Reaction';
            userId: string;
            reaction: ReactionEnum;
            user: { __typename?: 'User'; name: string };
          } | null> | null;
          leaseAgreements: Array<{
            __typename?: 'LeaseAgreement';
            id: string;
            name: string;
            size?: number | null;
            usageCategory?: Array<UsageCategory> | null;
            image?: { __typename?: 'File'; id: string; url: string } | null;
          }>;
        }>;
      }>;
    };
  };
};

export type MessageConversationPreviewValuesFragment = {
  __typename?: 'Message';
  id: string;
  created?: any | null;
  readTimestamp?: any | null;
  sender: { __typename?: 'Client'; id: string; name: string };
  senderUser?: { __typename?: 'User'; id: string; name: string } | null;
  reactions?: Array<{
    __typename?: 'Reaction';
    userId: string;
    reaction: ReactionEnum;
    user: { __typename?: 'User'; name: string };
  } | null> | null;
  leaseAgreements: Array<{
    __typename?: 'LeaseAgreement';
    id: string;
    name: string;
    size?: number | null;
    usageCategory?: Array<UsageCategory> | null;
    image?: { __typename?: 'File'; id: string; url: string } | null;
  }>;
};

export type GetCurrentSearchRequestsQueryVariables = Exact<{
  filter: SearchRequestAdminFilter;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  sort?: InputMaybe<Sort>;
}>;

export type GetCurrentSearchRequestsQuery = {
  __typename?: 'Query';
  searchRequestsAdmin: {
    __typename?: 'SearchRequestAdminData';
    count: number;
    searchRequests: Array<{
      __typename?: 'SearchRequestReference';
      id: string;
      title?: string | null;
      sentDate: any;
      renewedDate?: any | null;
      status: SearchRequestStatusEnum;
      disabledDate?: any | null;
      hasLoggedIn?: boolean | null;
      favourite?: boolean | null;
      hasComment?: boolean | null;
      adminStatus?: AdminSearchRequestReferenceStatus | null;
      key: string;
      regions: Array<{
        __typename?: 'Region';
        id: string;
        name: string;
        regionType?: RegionTypeEnum | null;
      }>;
      searchParameters: {
        __typename?: 'SearchParameters';
        usageCategories: Array<string>;
        size?: {
          __typename?: 'Range';
          min?: number | null;
          max?: number | null;
        } | null;
      };
      client?: {
        __typename?: 'Client';
        id: string;
        name: string;
        conversationStatistics: {
          __typename?: 'ConversationStatistics';
          count: number;
          unanswered: number;
        };
      } | null;
      disabledReason?: {
        __typename?: 'DisableSearchRequestReason';
        reason: DisableSearchRequestReasonEnum;
        comment?: string | null;
        contactViaPortally?: boolean | null;
        providedClient?: string | null;
        client?: { __typename?: 'Client'; id: string; name: string } | null;
      } | null;
      stats: {
        __typename?: 'StatusCount';
        accepted: number;
        declined: number;
        answer_requested: number;
        opened: number;
      };
      contactPerson?: { __typename?: 'User'; id: string; name: string } | null;
      adminResponsibleUser?: {
        __typename?: 'User';
        id: string;
        name: string;
      } | null;
    }>;
  };
};

export type GetSearchRequestAdminStatisticsQueryVariables = Exact<{
  filter: SearchRequestAdminFilter;
}>;

export type GetSearchRequestAdminStatisticsQuery = {
  __typename?: 'Query';
  searchRequestStatistics: {
    __typename?: 'SearchRequestAdminStatistics';
    count: number;
    sizes: Array<{
      __typename?: 'CountBySize';
      size: SizeFilter;
      count: number;
    }>;
    regions: Array<{
      __typename?: 'CountByRegion';
      count: number;
      region: {
        __typename?: 'Region';
        id: string;
        name: string;
        geometry?: any | null;
        regionType?: RegionTypeEnum | null;
        center?: any | null;
        bounds?: {
          __typename?: 'RegionBounds';
          north: number;
          south: number;
          west: number;
          east: number;
        } | null;
      };
    }>;
    usageCategories: Array<{
      __typename?: 'CountByUsageCategory';
      count: number;
      usageCategory: string;
    }>;
    potentialSize: {
      __typename?: 'Range';
      min?: number | null;
      max?: number | null;
    };
  };
};

export type SearchRequestClientAutocompleteQueryVariables = Exact<{
  key: Scalars['String'];
  clientType?: InputMaybe<Scalars['String']>;
}>;

export type SearchRequestClientAutocompleteQuery = {
  __typename?: 'Query';
  clientAutocomplete?: Array<{
    __typename?: 'AutocompleteResult';
    id: string;
    value: string;
  }> | null;
};

export type GetCohortAnalysisQueryVariables = Exact<{
  clientType?: InputMaybe<ClientType>;
}>;

export type GetCohortAnalysisQuery = {
  __typename?: 'Query';
  analysis?: {
    __typename?: 'Analysis';
    cohort: Array<{
      __typename?: 'CohortAnalysis';
      converted?: number | null;
      registered?: number | null;
      date?: any | null;
    }>;
  } | null;
};

export type GetSearchRequestAnalysisQueryVariables = Exact<{
  filter: SearchRequestFilter;
}>;

export type GetSearchRequestAnalysisQuery = {
  __typename?: 'Query';
  analysis?: {
    __typename?: 'Analysis';
    searchRequests?: {
      __typename?: 'SearchRequestAnalysis';
      opened?: number | null;
      unopened?: number | null;
      declined?: number | null;
      recipients?: number | null;
      accepted?: number | null;
      active?: number | null;
      disabled: {
        __typename?: 'DisabledSearchRequestAnalysis';
        dislike: number;
        stationary: number;
        notrelevant: number;
        adminNeverLoggedIn: number;
        adminNoActivity: number;
        success: number;
        count: number;
        elsewhere: {
          __typename?: 'ElsewhereReasons';
          elsewhere: number;
          elsewhereotherplatform: number;
          elsewhererealestateagent: number;
          elsewhereothercontact: number;
          elsewhereportallycontact: number;
          elsewhereother: number;
        };
      };
    } | null;
  } | null;
};

export type GetDailyReportQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;

export type GetDailyReportQuery = {
  __typename?: 'Query';
  dailyReports: Array<{
    __typename?: 'Report';
    id: string;
    type: string;
    year: any;
    date: any;
    statistics: {
      __typename?: 'Statistics';
      nrOfActiveUsers?: number | null;
      nrOfActiveLandlordUsers?: number | null;
      nrOfActiveTenantUsers?: number | null;
      nrOfNewSearchRequests?: number | null;
      nrOfEmailSent?: number | null;
      nrOfSignups?: number | null;
      nrOfTenantUserSignups?: number | null;
      nrOfLandlordUserSignups?: number | null;
      nrOfPromotions?: number | null;
      nrOfMessages?: number | null;
      nrOfInterests?: number | null;
      nrOfLeaseAgreements?: number | null;
    };
  }>;
};

export type GetLeaseAgreementStatsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetLeaseAgreementStatsQuery = {
  __typename?: 'Query';
  viewStats: {
    __typename?: 'LeaseAgreementViewStats';
    mostViewedLeaseAgreements: Array<{
      __typename?: 'MostViewedStats';
      count: number;
      _id: string;
      address: {
        __typename?: 'Address';
        street?: string | null;
        streetNumber?: string | null;
      };
    }>;
    overTime: Array<{
      __typename?: 'OverTimeStats';
      date: string;
      count: number;
    }>;
    numbers: {
      __typename?: 'LeaseAgreementNumbers';
      total: number;
      app: number;
      website: number;
      websitePercentage: number;
    };
  };
};

export type GetLeaseAgreementViewsQueryVariables = Exact<{
  leaseAgreementId?: InputMaybe<Scalars['ID']>;
  fromWebsite?: InputMaybe<Scalars['Boolean']>;
  from?: InputMaybe<Scalars['Int']>;
}>;

export type GetLeaseAgreementViewsQuery = {
  __typename?: 'Query';
  viewStats: {
    __typename?: 'LeaseAgreementViewStats';
    numbers: {
      __typename?: 'LeaseAgreementNumbers';
      website: number;
      app: number;
      websitePercentage: number;
      total: number;
    };
    overTime: Array<{
      __typename?: 'OverTimeStats';
      date: string;
      count: number;
    }>;
  };
};

export type GetLostAndActiveQueryVariables = Exact<{
  activeLimit: Scalars['Int'];
  lostLimit: Scalars['Int'];
  clientType?: InputMaybe<ClientType>;
}>;

export type GetLostAndActiveQuery = {
  __typename?: 'Query';
  systemStats: {
    __typename?: 'SystemStatistics';
    users: {
      __typename?: 'UserStats';
      activeUserStats?: {
        __typename?: 'ActiveUserStats';
        active?: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          activeClient?: {
            __typename?: 'Client';
            id: string;
            name: string;
          } | null;
        } | null> | null;
        lost?: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          activeClient?: {
            __typename?: 'Client';
            id: string;
            name: string;
          } | null;
        } | null> | null;
      } | null;
    };
  };
};

export type GetUserStatsQueryVariables = Exact<{
  usersFrom?: InputMaybe<Scalars['Date']>;
  messagesFrom?: InputMaybe<Scalars['Date']>;
  searchRequestsFrom?: InputMaybe<Scalars['Date']>;
  preliminaryFrom?: InputMaybe<Scalars['Date']>;
  promotedFrom?: InputMaybe<Scalars['Date']>;
  leaseAgreementFrom?: InputMaybe<Scalars['Date']>;
  clientType?: InputMaybe<ClientType>;
}>;

export type GetUserStatsQuery = {
  __typename?: 'Query';
  systemStats: {
    __typename?: 'SystemStatistics';
    leaseAgreements: {
      __typename?: 'LeaseAgreementStats';
      count: number;
      interestsOverTime: Array<{
        __typename?: 'DateStat';
        date: any;
        count: number;
      }>;
    };
    messages: {
      __typename?: 'MessageStats';
      count: number;
      messagesOverTime: Array<{
        __typename?: 'DateStat';
        date: any;
        count: number;
      }>;
    };
    searchRequests: {
      __typename?: 'SearchRequestStats';
      count: number;
      searchRequestsOverTime: Array<{
        __typename?: 'DateStat';
        date: any;
        count: number;
      }>;
    };
    preliminarySearchRequests: {
      __typename?: 'PreliminarySearchRequestStats';
      count: number;
      searchRequestsOverTime: Array<{
        __typename?: 'DateStat';
        date: any;
        count: number;
      }>;
    };
    promotions: {
      __typename?: 'PromotedStats';
      count: number;
      promotionsOverTime: Array<{
        __typename?: 'DateStat';
        date: any;
        count: number;
      }>;
    };
    users: {
      __typename?: 'UserStats';
      count: number;
      thisWeekTot: number;
      lastWeekTot: number;
      thisMonthTot: number;
      lastMonthTot: number;
      forecast: {
        __typename?: 'Forecast';
        willReachGoal?: boolean | null;
        averagePerDay?: number | null;
        averagePerDayNeeded?: number | null;
        goal?: number | null;
      };
      registeredPerDay: Array<{
        __typename?: 'DateStat';
        date: any;
        count: number;
      }>;
      loginStats: Array<{ __typename?: 'DateStat'; date: any; count: number }>;
      totalLoginStats: Array<{
        __typename?: 'DateStat';
        date: any;
        count: number;
      }>;
      activeUserStats?: {
        __typename?: 'ActiveUserStats';
        activeOverTime: Array<{
          __typename?: 'DateStat';
          date: any;
          count: number;
        }>;
      } | null;
    };
  };
};

export type GetWeeklyReportQueryVariables = Exact<{ [key: string]: never }>;

export type GetWeeklyReportQuery = {
  __typename?: 'Query';
  weeklyReports: Array<{
    __typename?: 'Report';
    id: string;
    type: string;
    date: any;
    year: any;
    week: string;
    statistics: {
      __typename?: 'Statistics';
      nrOfActiveUsers?: number | null;
      nrOfActiveLandlordUsers?: number | null;
      nrOfActiveTenantUsers?: number | null;
      nrOfNewSearchRequests?: number | null;
      nrOfEmailSent?: number | null;
      nrOfSignups?: number | null;
      nrOfLandlordUserSignups?: number | null;
      nrOfTenantUserSignups?: number | null;
      nrOfPromotions?: number | null;
      nrOfMessages?: number | null;
      nrOfInterests?: number | null;
      nrOfLeaseAgreements?: number | null;
    };
  } | null>;
};

export type AddSubscriptionRequestMutationVariables = Exact<{
  input: AddSubscriptionRequestInput;
}>;

export type AddSubscriptionRequestMutation = {
  __typename?: 'Mutation';
  addSubscriptionRequest?: {
    __typename?: 'SubscriptionRequest';
    id: string;
    status: string;
    client: { __typename?: 'Client'; id: string; name: string };
  } | null;
};

export type DeleteSubscriptionRequestMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteSubscriptionRequestMutation = {
  __typename?: 'Mutation';
  deleteSubscriptionRequest: { __typename?: 'SubscriptionRequest'; id: string };
};

export type GetSubscriptionClientsQueryVariables = Exact<{
  clientName?: InputMaybe<Scalars['String']>;
}>;

export type GetSubscriptionClientsQuery = {
  __typename?: 'Query';
  clients: {
    __typename?: 'ClientData';
    count: number;
    clients: Array<{
      __typename?: 'Client';
      id: string;
      name: string;
      key: string;
      subscriptions: Array<{
        __typename?: 'SubscriptionRequest';
        type?: SubscriptionType | null;
        id: string;
        version: SubscriptionVersion;
        acceptedDate?: any | null;
        status: string;
        created: any;
        updated: any;
        acceptedByUser?: {
          __typename?: 'User';
          id: string;
          name: string;
        } | null;
        readByUsers: Array<{ __typename?: 'User'; id: string; name: string }>;
        openedByUsers: Array<{ __typename?: 'User'; id: string; name: string }>;
      }>;
    }>;
  };
};

export type GetSubscriptionRequestsQueryVariables = Exact<{
  filter: SubscriptionFilter;
}>;

export type GetSubscriptionRequestsQuery = {
  __typename?: 'Query';
  subscriptionRequests: Array<{
    __typename?: 'SubscriptionRequest';
    id: string;
    acceptedDate?: any | null;
    status: string;
    created: any;
    updated: any;
    client: { __typename?: 'Client'; id: string; name: string };
    acceptedByUser?: { __typename?: 'User'; id: string; name: string } | null;
    readByUsers: Array<{ __typename?: 'User'; id: string; name: string }>;
    openedByUsers: Array<{ __typename?: 'User'; id: string; name: string }>;
  }>;
};

export type AddTermsOfServiceMutationVariables = Exact<{
  input?: InputMaybe<TermsOfServiceInput>;
}>;

export type AddTermsOfServiceMutation = {
  __typename?: 'Mutation';
  addTermsOfService?: {
    __typename?: 'TermsOfService';
    id: string;
    version?: string | null;
    published?: boolean | null;
    publishedDate?: any | null;
    content?: {
      __typename?: 'TranslatedContent';
      sv?: string | null;
      en?: string | null;
    } | null;
    privacy?: {
      __typename?: 'TranslatedContent';
      sv?: string | null;
      en?: string | null;
    } | null;
    cookiePolicy?: {
      __typename?: 'TranslatedContent';
      sv?: string | null;
      en?: string | null;
    } | null;
  } | null;
};

export type DeleteTermsOfServiceMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteTermsOfServiceMutation = {
  __typename?: 'Mutation';
  deleteTermsOfService?: { __typename?: 'TermsOfService'; id: string } | null;
};

export type EditTermsOfServiceMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<TermsOfServiceInput>;
}>;

export type EditTermsOfServiceMutation = {
  __typename?: 'Mutation';
  editTermsOfService?: {
    __typename?: 'TermsOfService';
    id: string;
    version?: string | null;
    published?: boolean | null;
    publishedDate?: any | null;
    content?: {
      __typename?: 'TranslatedContent';
      sv?: string | null;
      en?: string | null;
    } | null;
    privacy?: {
      __typename?: 'TranslatedContent';
      sv?: string | null;
      en?: string | null;
    } | null;
    cookiePolicy?: {
      __typename?: 'TranslatedContent';
      sv?: string | null;
      en?: string | null;
    } | null;
  } | null;
};

export type GetTermsOfServiceQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type GetTermsOfServiceQuery = {
  __typename?: 'Query';
  termsOfService?: {
    __typename?: 'TermsOfService';
    id: string;
    version?: string | null;
    published?: boolean | null;
    publishedDate?: any | null;
    content?: {
      __typename?: 'TranslatedContent';
      sv?: string | null;
      en?: string | null;
    } | null;
    privacy?: {
      __typename?: 'TranslatedContent';
      sv?: string | null;
      en?: string | null;
    } | null;
    cookiePolicy?: {
      __typename?: 'TranslatedContent';
      sv?: string | null;
      en?: string | null;
    } | null;
  } | null;
};

export type GetTermsOfServicesQueryVariables = Exact<{
  filter?: InputMaybe<TermsOfServiceFilter>;
}>;

export type GetTermsOfServicesQuery = {
  __typename?: 'Query';
  termsOfServices?: {
    __typename?: 'TermsOfServiceData';
    count?: number | null;
    termsOfServices?: Array<{
      __typename?: 'TermsOfService';
      id: string;
      version?: string | null;
      published?: boolean | null;
      publishedDate?: any | null;
      content?: {
        __typename?: 'TranslatedContent';
        sv?: string | null;
        en?: string | null;
      } | null;
      privacy?: {
        __typename?: 'TranslatedContent';
        sv?: string | null;
        en?: string | null;
      } | null;
      cookiePolicy?: {
        __typename?: 'TranslatedContent';
        sv?: string | null;
        en?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TermsOfServiceValuesFragment = {
  __typename?: 'TermsOfService';
  id: string;
  version?: string | null;
  published?: boolean | null;
  publishedDate?: any | null;
  content?: {
    __typename?: 'TranslatedContent';
    sv?: string | null;
    en?: string | null;
  } | null;
  privacy?: {
    __typename?: 'TranslatedContent';
    sv?: string | null;
    en?: string | null;
  } | null;
  cookiePolicy?: {
    __typename?: 'TranslatedContent';
    sv?: string | null;
    en?: string | null;
  } | null;
};

export type GetEndSearchRequestInformationQueryVariables = Exact<{
  searchRequestReferenceId: Scalars['String'];
  token: Scalars['String'];
}>;

export type GetEndSearchRequestInformationQuery = {
  __typename?: 'Query';
  searchRequestReferenceByToken: {
    __typename?: 'SearchRequestReference';
    title?: string | null;
    disabled?: boolean | null;
    clientId?: string | null;
    status: SearchRequestStatusEnum;
    disabledReason?: {
      __typename?: 'DisableSearchRequestReason';
      comment?: string | null;
      reason: DisableSearchRequestReasonEnum;
      providedClient?: string | null;
      client?: { __typename?: 'Client'; name: string } | null;
    } | null;
  };
};

export type LoginUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginUserMutation = { __typename?: 'Mutation'; loginUser: string };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
  acceptTerms?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: string;
};

export type ResetPasswordLinkMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ResetPasswordLinkMutation = {
  __typename?: 'Mutation';
  resetPasswordLink: string;
};

export type ShareSearchRequestMutationVariables = Exact<{
  input: ShareSearchRequestInput;
  sendEmail: Scalars['Boolean'];
}>;

export type ShareSearchRequestMutation = {
  __typename?: 'Mutation';
  shareSearchRequest: string;
};

export type CheckTokenQueryVariables = Exact<{
  token: Scalars['String'];
  type: AccountCreationType;
}>;

export type CheckTokenQuery = {
  __typename?: 'Query';
  checkToken: {
    __typename?: 'TokenInfo';
    valid: boolean;
    exists: boolean;
    createAccountInfo?: {
      __typename?: 'CreateAccountInfo';
      email: string;
      name: string;
      client: string;
    } | null;
  };
};

export type GetNewLinkMutationVariables = Exact<{
  token: Scalars['String'];
  type: AccountCreationType;
  searchRequestReferenceId?: InputMaybe<Scalars['String']>;
}>;

export type GetNewLinkMutation = {
  __typename?: 'Mutation';
  getNewLink: boolean;
};

export type SignUpMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type SignUpMutation = { __typename?: 'Mutation'; signUp: boolean };

export type RegisterMutationVariables = Exact<{
  input: UserInput;
}>;

export type RegisterMutation = {
  __typename?: 'Mutation';
  register: { __typename?: 'User'; id: string; name: string };
};

export type UnsubscribeFromEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type UnsubscribeFromEmailMutation = {
  __typename?: 'Mutation';
  unsubscribeEmail?: string | null;
};

export type ActivityLogValuesFragment = {
  __typename?: 'ActivityLog';
  id: string;
  created: any;
  type: ActivityLogEnum;
  searchRequest?: {
    __typename?: 'SearchRequest';
    id: string;
    searchRequestReference: {
      __typename?: 'SearchRequestReference';
      id: string;
      title?: string | null;
    };
  } | null;
  leaseAgreement?: {
    __typename?: 'LeaseAgreement';
    id: string;
    name: string;
  } | null;
  statusUpdate?: {
    __typename?: 'StatusUpdate';
    direction: Direction;
    previousStatus: ActivityStatusEnum;
    nextStatus: ActivityStatusEnum;
  } | null;
  conversation?: {
    __typename?: 'Conversation';
    id: string;
    participants: Array<{ __typename?: 'Client'; id: string; name: string }>;
    searchRequestReference?: {
      __typename?: 'SearchRequestReference';
      id: string;
      title?: string | null;
    } | null;
    leaseAgreement?: {
      __typename?: 'LeaseAgreement';
      id: string;
      name: string;
    } | null;
    messages: Array<{
      __typename?: 'Message';
      id: string;
      created?: any | null;
      readTimestamp?: any | null;
      sender: { __typename?: 'Client'; id: string; name: string };
      senderUser?: { __typename?: 'User'; id: string; name: string } | null;
      reactions?: Array<{
        __typename?: 'Reaction';
        userId: string;
        reaction: ReactionEnum;
        user: { __typename?: 'User'; name: string };
      } | null> | null;
      leaseAgreements: Array<{
        __typename?: 'LeaseAgreement';
        id: string;
        name: string;
        size?: number | null;
        usageCategory?: Array<UsageCategory> | null;
        image?: { __typename?: 'File'; id: string; url: string } | null;
      }>;
    }>;
  } | null;
  client?: { __typename?: 'Client'; id: string; name: string } | null;
};

export type AddUserMutationVariables = Exact<{
  input: UserInput;
}>;

export type AddUserMutation = {
  __typename?: 'Mutation';
  addUser: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName?: string | null;
    activeClientId?: string | null;
    email: string;
    role?: string | null;
    imageId?: string | null;
    limited?: boolean | null;
    showMine?: boolean | null;
    prospect?: boolean | null;
    companyName?: string | null;
    orgNumber?: string | null;
    phone?: string | null;
    disabled?: boolean | null;
    failedLogins?: number | null;
    onBoarded?: boolean | null;
    activeModal?: ModalEnum | null;
    jobTitle?: string | null;
    userCategory: Array<UserCategoryEnum>;
    activeClient?: {
      __typename?: 'Client';
      name: string;
      id: string;
      type?: ClientType | null;
    } | null;
    clients?: Array<{ __typename?: 'Client'; name: string; id: string }> | null;
    image?: {
      __typename?: 'File';
      id: string;
      name: string;
      url: string;
      size: number;
    } | null;
    trial?: {
      __typename?: 'Trial';
      startDate?: any | null;
      endDate?: any | null;
    } | null;
    emailSettings: {
      __typename?: 'EmailSettings';
      enable: boolean;
      onMessage: boolean;
      onMatch: boolean;
      onSearchHit: boolean;
      onIntresting: boolean;
      onSearchRequest: boolean;
      reminders: boolean;
    };
    searchRequestSettings?: {
      __typename?: 'SearchRequestSettings';
      regionIds: Array<string>;
      usageCategories: Array<string>;
      filterEmails?: boolean | null;
      size: Array<SizeFilter>;
    } | null;
  };
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser?: { __typename?: 'User'; id: string } | null;
};

export type EditUserMutationVariables = Exact<{
  id: Scalars['String'];
  input: UserInput;
}>;

export type EditUserMutation = {
  __typename?: 'Mutation';
  editUser?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName?: string | null;
    activeClientId?: string | null;
    email: string;
    role?: string | null;
    imageId?: string | null;
    limited?: boolean | null;
    showMine?: boolean | null;
    prospect?: boolean | null;
    companyName?: string | null;
    orgNumber?: string | null;
    phone?: string | null;
    disabled?: boolean | null;
    failedLogins?: number | null;
    onBoarded?: boolean | null;
    activeModal?: ModalEnum | null;
    jobTitle?: string | null;
    userCategory: Array<UserCategoryEnum>;
    activeClient?: {
      __typename?: 'Client';
      name: string;
      id: string;
      type?: ClientType | null;
    } | null;
    clients?: Array<{ __typename?: 'Client'; name: string; id: string }> | null;
    image?: {
      __typename?: 'File';
      id: string;
      name: string;
      url: string;
      size: number;
    } | null;
    trial?: {
      __typename?: 'Trial';
      startDate?: any | null;
      endDate?: any | null;
    } | null;
    emailSettings: {
      __typename?: 'EmailSettings';
      enable: boolean;
      onMessage: boolean;
      onMatch: boolean;
      onSearchHit: boolean;
      onIntresting: boolean;
      onSearchRequest: boolean;
      reminders: boolean;
    };
    searchRequestSettings?: {
      __typename?: 'SearchRequestSettings';
      regionIds: Array<string>;
      usageCategories: Array<string>;
      filterEmails?: boolean | null;
      size: Array<SizeFilter>;
    } | null;
  } | null;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName?: string | null;
    email: string;
    role?: string | null;
    activeClientId?: string | null;
    limited?: boolean | null;
    showMine?: boolean | null;
    activeModal?: ModalEnum | null;
    jobTitle?: string | null;
    userCategory: Array<UserCategoryEnum>;
    verified: boolean;
    activeClient?: {
      __typename?: 'Client';
      id: string;
      name: string;
      type?: ClientType | null;
    } | null;
    clients?: Array<{ __typename?: 'Client'; id: string; name: string }> | null;
    image?: {
      __typename?: 'File';
      url: string;
      id: string;
      size: number;
      name: string;
    } | null;
    emailSettings: {
      __typename?: 'EmailSettings';
      enable: boolean;
      onMessage: boolean;
      onMatch: boolean;
      onSearchHit: boolean;
      onIntresting: boolean;
      onSearchRequest: boolean;
      reminders: boolean;
    };
    activeSubscription?: {
      __typename?: 'SubscriptionRequest';
      id: string;
      type?: SubscriptionType | null;
    } | null;
  } | null;
};

export type GetUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    receivedWelcomeEmail?: boolean | null;
    id: string;
    firstName: string;
    lastName?: string | null;
    activeClientId?: string | null;
    email: string;
    role?: string | null;
    imageId?: string | null;
    limited?: boolean | null;
    showMine?: boolean | null;
    prospect?: boolean | null;
    companyName?: string | null;
    orgNumber?: string | null;
    phone?: string | null;
    disabled?: boolean | null;
    failedLogins?: number | null;
    onBoarded?: boolean | null;
    activeModal?: ModalEnum | null;
    jobTitle?: string | null;
    userCategory: Array<UserCategoryEnum>;
    activeClient?: {
      __typename?: 'Client';
      name: string;
      id: string;
      type?: ClientType | null;
    } | null;
    clients?: Array<{ __typename?: 'Client'; name: string; id: string }> | null;
    image?: {
      __typename?: 'File';
      id: string;
      name: string;
      url: string;
      size: number;
    } | null;
    trial?: {
      __typename?: 'Trial';
      startDate?: any | null;
      endDate?: any | null;
    } | null;
    emailSettings: {
      __typename?: 'EmailSettings';
      enable: boolean;
      onMessage: boolean;
      onMatch: boolean;
      onSearchHit: boolean;
      onIntresting: boolean;
      onSearchRequest: boolean;
      reminders: boolean;
    };
    searchRequestSettings?: {
      __typename?: 'SearchRequestSettings';
      regionIds: Array<string>;
      usageCategories: Array<string>;
      filterEmails?: boolean | null;
      size: Array<SizeFilter>;
    } | null;
  } | null;
};

export type GetUserViewQueryVariables = Exact<{
  id: Scalars['String'];
  filter: ActivityLogFilterInput;
}>;

export type GetUserViewQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    name: string;
    role?: string | null;
    email: string;
    updated?: any | null;
    created?: any | null;
    receivedWelcomeEmail?: boolean | null;
    failedLogins?: number | null;
    access?: any | null;
    logins?: number | null;
    phone?: string | null;
    activityStatus?: ActivityStatusEnum | null;
    image?: { __typename?: 'File'; id: string; url: string } | null;
    activeClient?: { __typename?: 'Client'; id: string; name: string } | null;
    clients?: Array<{
      __typename?: 'Client';
      id: string;
      name: string;
      hasCommission?: boolean | null;
      image?: { __typename?: 'File'; id: string; url: string } | null;
    }> | null;
    searchRequestSettings?: {
      __typename?: 'SearchRequestSettings';
      regionIds: Array<string>;
      usageCategories: Array<string>;
      size: Array<SizeFilter>;
      filterEmails?: boolean | null;
      regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
    } | null;
    activity: {
      __typename?: 'ActivityData';
      count: number;
      activities: Array<{
        __typename?: 'ActivityLog';
        id: string;
        created: any;
        type: ActivityLogEnum;
        searchRequest?: {
          __typename?: 'SearchRequest';
          id: string;
          searchRequestReference: {
            __typename?: 'SearchRequestReference';
            id: string;
            title?: string | null;
          };
        } | null;
        leaseAgreement?: {
          __typename?: 'LeaseAgreement';
          id: string;
          name: string;
        } | null;
        statusUpdate?: {
          __typename?: 'StatusUpdate';
          direction: Direction;
          previousStatus: ActivityStatusEnum;
          nextStatus: ActivityStatusEnum;
        } | null;
        conversation?: {
          __typename?: 'Conversation';
          id: string;
          participants: Array<{
            __typename?: 'Client';
            id: string;
            name: string;
          }>;
          searchRequestReference?: {
            __typename?: 'SearchRequestReference';
            id: string;
            title?: string | null;
          } | null;
          leaseAgreement?: {
            __typename?: 'LeaseAgreement';
            id: string;
            name: string;
          } | null;
          messages: Array<{
            __typename?: 'Message';
            id: string;
            created?: any | null;
            readTimestamp?: any | null;
            sender: { __typename?: 'Client'; id: string; name: string };
            senderUser?: {
              __typename?: 'User';
              id: string;
              name: string;
            } | null;
            reactions?: Array<{
              __typename?: 'Reaction';
              userId: string;
              reaction: ReactionEnum;
              user: { __typename?: 'User'; name: string };
            } | null> | null;
            leaseAgreements: Array<{
              __typename?: 'LeaseAgreement';
              id: string;
              name: string;
              size?: number | null;
              usageCategory?: Array<UsageCategory> | null;
              image?: { __typename?: 'File'; id: string; url: string } | null;
            }>;
          }>;
        } | null;
        client?: { __typename?: 'Client'; id: string; name: string } | null;
      } | null>;
    };
  } | null;
};

export type GetUsersQueryVariables = Exact<{
  filter: UserFilterInput;
  sort?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserData';
    count: number;
    users: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      role?: string | null;
      email: string;
      lastName?: string | null;
      firstName: string;
      updated?: any | null;
      created?: any | null;
      access?: any | null;
      logins?: number | null;
      activityStatus?: ActivityStatusEnum | null;
      image?: { __typename?: 'File'; id: string; url: string } | null;
      searchRequestSettings?: {
        __typename?: 'SearchRequestSettings';
        size: Array<SizeFilter>;
        usageCategories: Array<string>;
        regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
      } | null;
      activeClient?: {
        __typename?: 'Client';
        id: string;
        hasCommission?: boolean | null;
      } | null;
    }>;
  };
};

export type SendNewsMessageMutationVariables = Exact<{
  input: SendNewsMessageInput;
}>;

export type SendNewsMessageMutation = {
  __typename?: 'Mutation';
  sendNewsMessage: boolean;
};

export type SendWelcomeEmailMutationVariables = Exact<{
  userId: Scalars['String'];
}>;

export type SendWelcomeEmailMutation = {
  __typename?: 'Mutation';
  sendWelcomeEmail: {
    __typename?: 'User';
    id: string;
    prospect?: boolean | null;
  };
};

export type TestNewsMessageMutationVariables = Exact<{
  email: EmailContent;
  recipient: Scalars['String'];
}>;

export type TestNewsMessageMutation = {
  __typename?: 'Mutation';
  testNewsMessage: boolean;
};

export type UserValuesFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  lastName?: string | null;
  activeClientId?: string | null;
  email: string;
  role?: string | null;
  imageId?: string | null;
  limited?: boolean | null;
  showMine?: boolean | null;
  prospect?: boolean | null;
  companyName?: string | null;
  orgNumber?: string | null;
  phone?: string | null;
  disabled?: boolean | null;
  failedLogins?: number | null;
  onBoarded?: boolean | null;
  activeModal?: ModalEnum | null;
  jobTitle?: string | null;
  userCategory: Array<UserCategoryEnum>;
  activeClient?: {
    __typename?: 'Client';
    name: string;
    id: string;
    type?: ClientType | null;
  } | null;
  clients?: Array<{ __typename?: 'Client'; name: string; id: string }> | null;
  image?: {
    __typename?: 'File';
    id: string;
    name: string;
    url: string;
    size: number;
  } | null;
  trial?: {
    __typename?: 'Trial';
    startDate?: any | null;
    endDate?: any | null;
  } | null;
  emailSettings: {
    __typename?: 'EmailSettings';
    enable: boolean;
    onMessage: boolean;
    onMatch: boolean;
    onSearchHit: boolean;
    onIntresting: boolean;
    onSearchRequest: boolean;
    reminders: boolean;
  };
  searchRequestSettings?: {
    __typename?: 'SearchRequestSettings';
    regionIds: Array<string>;
    usageCategories: Array<string>;
    filterEmails?: boolean | null;
    size: Array<SizeFilter>;
  } | null;
};

export const ContactValuesFragmentDoc = gql`
  fragment ContactValues on Contact {
    id
    name
    firstName
    lastName
    email
    phone
    title
    clientId
    emailsEnabled
    userExists
  }
`;
export const DeleteLeaseAgreementIntegrationProviderValuesFragmentDoc = gql`
  fragment DeleteLeaseAgreementIntegrationProviderValues on IntegrationProvider {
    id
    name
    image {
      id
      url(processing: { quality: auto, height: 200 })
    }
  }
`;
export const LeaseAgreementListValuesFragmentDoc = gql`
  fragment LeaseAgreementListValues on LeaseAgreement {
    id
    name
    title
    usageCategory
    address {
      city
    }
    size
    sizeSpan {
      from
      to
    }
    image {
      url(processing: { quality: auto, height: 300 })
      name
      id
    }
    myStatus
    landlordContactPersons {
      id
      name
      image {
        url(processing: { quality: auto, height: 400 })
        id
        name
      }
      email
      phone
    }
    landlord {
      id
      image {
        url(processing: { quality: auto, height: 400 })
        name
      }
      name
    }
    integrationProvider {
      ...DeleteLeaseAgreementIntegrationProviderValues
    }
    location
    matchingSearchRequests {
      count
    }
  }
  ${DeleteLeaseAgreementIntegrationProviderValuesFragmentDoc}
`;
export const NotificationValuesFragmentDoc = gql`
  fragment NotificationValues on Notification {
    id
    message {
      en
      sv
    }
    searchRequestReferenceId
    leaseAgreementId
    timestamp
    read
    link
    type
  }
`;
export const UserReceiverValuesFragmentDoc = gql`
  fragment UserReceiverValues on User {
    id
    name
    firstName
    emailSettings {
      enable
      onSearchRequest
    }
    searchRequestSettings {
      size
      usageCategories
      regions {
        id
        name
      }
    }
    access
    receivedEmails(filter: { onlySearchRequest: true }) {
      searchRequest {
        id
        searchRequestReference {
          id
          title
        }
      }
      date
      template
    }
  }
`;
export const AnsweredByValuesFragmentDoc = gql`
  fragment AnsweredByValues on SearchRequestData {
    searchRequests {
      id
      responsibleUser {
        id
        name
        image {
          id
          url(processing: { quality: auto, width: 200, height: 200 })
        }
      }
      conversationId
      status
    }
  }
`;
export const PromotedValuesFragmentDoc = gql`
  fragment PromotedValues on Promoted {
    id
    key: id
    status
    owner {
      id
      name
    }
    leaseAgreement {
      id
      name
    }
  }
`;
export const SearchRequestCommentValuesFragmentDoc = gql`
  fragment SearchRequestCommentValues on SearchRequestComment {
    id
    comment
    user {
      id
      name
      image {
        id
        url
      }
    }
    created
  }
`;
export const SearchRequestMatchValuesFragmentDoc = gql`
  fragment SearchRequestMatchValues on SearchRequest {
    searchParameters {
      size {
        min
        max
      }
      regionIds
      usageCategories
    }
  }
`;
export const SearchRequestReferenceMatchValuesFragmentDoc = gql`
  fragment SearchRequestReferenceMatchValues on SearchRequestReference {
    searchParameters {
      size {
        min
        max
      }
      regionIds
      usageCategories
    }
  }
`;
export const SearchRequestCardValuesFragmentDoc = gql`
  fragment SearchRequestCardValues on SearchRequest {
    id
    created
    conversation {
      id
      unread {
        count
      }
    }
    disabled
    step
    answeredDate
    stepWeight
    conversationId
    searchRequestReference {
      id
      title
      status
      disabledReason {
        reason
      }
      disabledDate
      client {
        id
        name
      }
      searchParameters {
        size {
          min
          max
        }
        usageCategories
      }
    }
    county {
      id
      name
    }
    responsibleUser {
      id
      name
    }
    comments
  }
`;
export const EmailLogValuesFragmentDoc = gql`
  fragment EmailLogValues on EmailLog {
    id
    subject
    body
    recipient
    template
    date
    mailWaiting
    client {
      id
      name
    }
  }
`;
export const EstateCustomerValuesFragmentDoc = gql`
  fragment EstateCustomerValues on Estate {
    id
    estateDesignation
    name
    address {
      street
      streetNumber
      zipCode
      city
    }
    location
    landlordId
    contactPersonIds
    contactPersons {
      name
    }
    leaseAgreements {
      key: id
      id
      name
      usageCategory
      size
      tenant {
        id
        name
      }
    }
  }
`;
export const EstateValuesFragmentDoc = gql`
  fragment EstateValues on Estate {
    key: id
    id
    name
    address {
      street
      streetNumber
      zipCode
      city
      address
    }
    estateDesignation
    landlord {
      name
    }
  }
`;
export const DocumentValuesFragmentDoc = gql`
  fragment DocumentValues on File {
    id
    name
    size
    url(processing: { quality: auto, height: 400 })
    uid: id
    mimetype
  }
`;
export const IntegrationProviderValuesFragmentDoc = gql`
  fragment IntegrationProviderValues on IntegrationProvider {
    id
    name
    clients {
      id
      name
    }
    image {
      id
      ...DocumentValues
    }
    contactPersons
  }
  ${DocumentValuesFragmentDoc}
`;
export const ContactPersonValuesFragmentDoc = gql`
  fragment ContactPersonValues on User {
    id
    name
    image {
      id
      url(
        processing: {
          quality: auto
          width: 400
          height: 400
          gravity: auto
          crop: fill
        }
      )
    }
    activeClient {
      image {
        id
        url
      }
    }
  }
`;
export const LeaseAgreementFieldsFragmentDoc = gql`
  fragment LeaseAgreementFields on LeaseAgreement {
    id
    name
    myStatus
    address {
      street
      streetNumber
      city
      zipCode
    }
    location
    size
    sizeSpan {
      from
      to
    }
    title
    description
    usageCategory
    landlord {
      name
      id
      image {
        id
        url(processing: { quality: auto, height: 400 })
      }
    }
    landlordId
    tenant {
      name
      id
    }
    tenantId
    estateId
    estate {
      id
      name
    }
    landlordId
    imageIds
    video {
      videoId
      video {
        id
        url
        name
        uid: id
        id
        size
      }
      thumbnailId
      thumbnail {
        id
        url
        name
        uid: id
        size
      }
    }
    images {
      url(
        processing: {
          height: 600
          width: 900
          quality: auto
          crop: lpad
          background: auto
        }
      )
      name
      uid: id
      id
      size
    }
    documentIds
    documents {
      ...DocumentValues
    }
    landlordContactPersonIds
    landlordContactPersons {
      ...ContactPersonValues
    }
    tenantContactPersonIds
    tenantContactPersons {
      ...ContactPersonValues
    }
    vitecGuid
    areaDescription
    rent
    access
    links {
      title
      url
      category
    }
    nearbyServices {
      bus_station {
        name
        distance
      }
      train_station {
        name
        distance
      }
      subway_station {
        name
        distance
      }
      parking {
        name
        distance
      }
      gym {
        name
        distance
      }
      supermarket {
        name
        distance
      }
    }
    fee {
      feeType
      amount
    }
    projectId
    integrationProvider {
      ...DeleteLeaseAgreementIntegrationProviderValues
    }
    prioritized
  }
  ${DocumentValuesFragmentDoc}
  ${ContactPersonValuesFragmentDoc}
  ${DeleteLeaseAgreementIntegrationProviderValuesFragmentDoc}
`;
export const LeaseAgreementAdminProspectValuesFragmentDoc = gql`
  fragment LeaseAgreementAdminProspectValues on LeaseAgreementProspect {
    id
    status
    adminStatus
    landlord {
      id
      name
    }
    leaseAgreement {
      id
      name
      landlordContactPersons {
        id
        name
      }
      image {
        id
        url(processing: { quality: auto, height: 500 })
      }
      disabled
    }
    email
    phone
    company
    message
    created
    readBy {
      name
      id
    }
  }
`;
export const AdminLeaseAgreementListValuesFragmentDoc = gql`
  fragment AdminLeaseAgreementListValues on LeaseAgreement {
    fromVitec
    created
    landlord {
      id
      name
    }
    interestedUsers {
      name
      activeClient {
        id
        name
      }
    }
    views {
      count
      views {
        id
        user {
          id
          name
          activeClient {
            id
            name
          }
        }
      }
    }
    matchingSearchRequests {
      count
    }
  }
`;
export const LeaseAgreementManagementValuesFragmentDoc = gql`
  fragment LeaseAgreementManagementValues on LeaseAgreement {
    id
    name
    title
    location
    landlord {
      id
    }
    landlordContactPersons {
      id
      name
    }
    address {
      city
      zipCode
    }
    usageCategory
    estateId
    size
    sizeSpan {
      from
      to
    }
    image {
      id
      url(processing: { quality: auto, height: 400 })
    }
    integrationProvider {
      ...DeleteLeaseAgreementIntegrationProviderValues
    }
    matchingSearchRequests {
      count
    }
  }
  ${DeleteLeaseAgreementIntegrationProviderValuesFragmentDoc}
`;
export const AttachmentValuesFragmentDoc = gql`
  fragment AttachmentValues on Attachment {
    id
    attachment {
      ...DocumentValues
    }
    attachmentId
  }
  ${DocumentValuesFragmentDoc}
`;
export const ConversationTitleValuesFragmentDoc = gql`
  fragment ConversationTitleValues on Conversation {
    leaseAgreement {
      id
      name
      image {
        id
        url
      }
    }
    searchRequestReference {
      id
      title
      status
      clientId
    }
    searchRequest {
      responsibleUser {
        id
        name
      }
    }
    opponents {
      id
      name
      image {
        id
        url
      }
    }
  }
`;
export const MessageValuesFragmentDoc = gql`
  fragment MessageValues on Message {
    id
    sender {
      id
      name
    }
    senderUser {
      id
      name
      image {
        id
        url
      }
    }
    message
    reactions {
      userId
      user {
        name
      }
      reaction
    }
    created
    read
    readTimestamp
    conversationId
    attachments {
      ...DocumentValues
    }
    leaseAgreements {
      id
      name
      image {
        id
        url(processing: { quality: auto })
      }
      description
      title
      size
      sizeSpan {
        from
        to
      }
      usageCategory
      fee {
        amount
        feeType
      }
    }
  }
  ${DocumentValuesFragmentDoc}
`;
export const EpisodeValuesFragmentDoc = gql`
  fragment EpisodeValues on Episode {
    id
    title
    description
    published
    link
    image
    duration
  }
`;
export const PodcastValuesFragmentDoc = gql`
  fragment PodcastValues on Podcast {
    id
    name
    image
    author
    numberOfEpisodes
  }
`;
export const EventFieldsFragmentDoc = gql`
  fragment EventFields on Event {
    id
    description
    client {
      id
      name
    }
    responsibleConsultantId
    responsibleConsultant {
      id
      name
    }
    date
    done
    type
  }
`;
export const AdValuesFragmentDoc = gql`
  fragment AdValues on PortallyAd {
    id
    image {
      ...DocumentValues
    }
    active
    placement
    link
    usageCategories
    regionIds
    regions {
      id
      name
    }
    mobileImage {
      ...DocumentValues
    }
  }
  ${DocumentValuesFragmentDoc}
`;
export const ClientValuesFragmentDoc = gql`
  fragment ClientValues on Client {
    id
    name
    type
    group
    email
    telephone
    orgNumber
    imageId
    image {
      id
      name
      url
      size
    }
    companyName
    postAddress {
      address
      street
      streetNumber
      zipCode
      city
    }
    visitingAddress {
      address
      street
      streetNumber
      zipCode
      city
    }
    address
    location
    website
    parentCompany
    parentCompanyOrgNumber
    responsibleConsultantId
    responsibleConsultant {
      id
    }
    estimatedSize {
      min
      max
    }
    comment
    description
    numberOfEmployees
    numberOfUnits
    industryCategory
    industryCode
    typeOfBusiness
    showOnWebsite
    notifySupport
    supportStatus
    vitecLicenceIds
    invoiceDetails {
      billing
      company
      marking
      reference
      email
    }
    objektvisionId
    objektvisionIds
    includeObjektvisionEmails
    lokalguidenId
    websiteImage {
      id
      name
      url
      size
    }
    showPageOnWebsite
    slug
    websiteName
    limeApiKey
    isPartner
    websiteDescription
  }
`;
export const LeaseAgreementListPreviewValuesFragmentDoc = gql`
  fragment LeaseAgreementListPreviewValues on LeaseAgreement {
    id
    name
    address {
      street
      streetNumber
      city
      zipCode
    }
    size
    sizeSpan {
      from
      to
    }
    title
    usageCategory
    image {
      id
      url(processing: { quality: auto })
      uid: id
      size
    }
  }
`;
export const LeaseAgreementViewValuesFragmentDoc = gql`
  fragment LeaseAgreementViewValues on LeaseAgreementView {
    id
    created
    user {
      id
      name
      activeClient {
        id
        name
      }
    }
    leaseAgreement {
      ...LeaseAgreementListPreviewValues
      landlord {
        id
        name
      }
    }
    fromWebsite
  }
  ${LeaseAgreementListPreviewValuesFragmentDoc}
`;
export const SearchRequestReferenceInformationValuesFragmentDoc = gql`
  fragment SearchRequestReferenceInformationValues on SearchRequestReference {
    id
    key: id
    title
    description
    areaInformation
    specificNeedsInformation
    businessInformation
    budget
    budgetSpan {
      min
      max
    }
    employees
    employeesSpan {
      min
      max
    }
    access {
      quarter
      year
    }
    accessV2
    contactPerson {
      id
      name
    }
    sentDate
    clientId
    searchParameters {
      geometry
      regionIds
      bounds {
        north
        south
        east
        west
      }
      regions {
        id
        name
        geometry
        regionType
        center
      }
      size {
        max
        min
      }
      usageCategories
    }
    client {
      id
      name
      ebieScore {
        rating
        tier
      }
    }
    documents {
      ...DocumentValues
    }
    disabledReason {
      reason
    }
    status
  }
  ${DocumentValuesFragmentDoc}
`;
export const SearchRequestReferencePreviewValuesFragmentDoc = gql`
  fragment SearchRequestReferencePreviewValues on SearchRequestReference {
    id
    title
    description
    areaInformation
    businessInformation
    specificNeedsInformation
    searchParameters {
      usageCategories
      size {
        min
        max
      }
      geometry
    }
    sentDate
    client {
      ...ClientValues
    }
    contactPerson {
      id
      email
      verified
      access
      leaseAgreementViews {
        count
        views {
          ...LeaseAgreementViewValues
        }
      }
    }
    budget
    budgetSpan {
      min
      max
    }
    employees
    employeesSpan {
      min
      max
    }
    regions {
      id
      name
    }
    access {
      quarter
      year
    }
    accessV2
    status
    ...SearchRequestReferenceInformationValues
    improveSearchMailSent
    fromWebsite
    contactInformation {
      email
      phone
      company
    }
  }
  ${ClientValuesFragmentDoc}
  ${LeaseAgreementViewValuesFragmentDoc}
  ${SearchRequestReferenceInformationValuesFragmentDoc}
`;
export const ProspectValuesFragmentDoc = gql`
  fragment ProspectValues on Prospect {
    id
    name
    created
    clientId
    source
    sourceComment
    contact {
      id
      name
    }
    contactId
    key: id
    client {
      name
      id
    }
    clientManager {
      name
      id
    }
    potentialValue
    startDate
    service
    division
    step
    comment
    weightedValue
    crossBorder
    daysInCurrentStep
    orderData {
      reason
      comment
      blikkNumber
      orderValue
    }
    lostOrderData {
      reason
      comment
    }
  }
`;
export const RegionValuesFragmentDoc = gql`
  fragment RegionValues on Region {
    name
    level
    geometry
    country
    id
    regionType
    bounds {
      north
      west
      south
      east
    }
    prioritized
    filter
    parents {
      id
      name
    }
    information {
      id
      image {
        ...DocumentValues
      }
      title
      description
      usageCategories
    }
  }
  ${DocumentValuesFragmentDoc}
`;
export const SearchRequestReferenceListValuesFragmentDoc = gql`
  fragment SearchRequestReferenceListValues on SearchRequestReference {
    id
    title
    myStatus
    created: sentDate
    client {
      id
      name
    }
    disabledDate
    disabledReason {
      reason
    }
    searchRequestReferenceId: id
    status
    searchRequests(limit: 100, status: [accepted, declined]) {
      searchRequests {
        responsibleUser {
          id
          name
          image {
            id
            url(processing: { quality: auto, height: 200, width: 200 })
          }
        }
        status
        conversationId
      }
    }
    searchParameters {
      usageCategories
      regionIds
      regions {
        id
        name
      }
      size {
        min
        max
      }
    }
    budget
    budgetSpan {
      min
      max
    }
    employees
    employeesSpan {
      min
      max
    }
  }
`;
export const SearchRequestLandlordValuesFragmentDoc = gql`
  fragment SearchRequestLandlordValues on SearchRequest {
    id
    searchRequestReference {
      ...SearchRequestReferenceListValues
    }
    created
    clientId
    conversationId
    status
    answeredDate
    step
    stepWeight
    comments
  }
  ${SearchRequestReferenceListValuesFragmentDoc}
`;
export const SearchRequestConversationValuesFragmentDoc = gql`
  fragment SearchRequestConversationValues on Conversation {
    id
    opponents {
      id
      name
      image {
        id
        url(processing: { quality: auto })
      }
    }
    id
    unread {
      count
    }
    searchRequest {
      id
      responsibleUser {
        id
        name
      }
    }
    leaseAgreement {
      id
      name
    }
    messages {
      created
      senderUser {
        id
        name
        image {
          id
          url(
            processing: {
              gravity: auto
              quality: auto
              width: 100
              height: 100
            }
          )
        }
      }
      message
    }
  }
`;
export const SearchRequestConversationDataValuesFragmentDoc = gql`
  fragment SearchRequestConversationDataValues on ConversationData {
    count
    unread
    conversations {
      id
      conversationId
      conversation {
        ...SearchRequestConversationValues
      }
      created
    }
  }
  ${SearchRequestConversationValuesFragmentDoc}
`;
export const TermsOfServiceValuesFragmentDoc = gql`
  fragment TermsOfServiceValues on TermsOfService {
    id
    version
    content {
      sv
      en
    }
    privacy {
      sv
      en
    }
    cookiePolicy {
      sv
      en
    }
    published
    publishedDate
  }
`;
export const MessageConversationPreviewValuesFragmentDoc = gql`
  fragment MessageConversationPreviewValues on Message {
    id
    sender {
      id
      name
    }
    senderUser {
      id
      name
    }
    reactions {
      userId
      user {
        name
      }
      reaction
    }
    created
    readTimestamp
    leaseAgreements {
      id
      name
      image {
        id
        url(processing: { quality: auto })
      }
      size
      usageCategory
    }
  }
`;
export const ActivityLogValuesFragmentDoc = gql`
  fragment ActivityLogValues on ActivityLog {
    id
    searchRequest {
      id
      searchRequestReference {
        id
        title
      }
    }
    leaseAgreement {
      id
      name
    }
    statusUpdate {
      direction
      previousStatus
      nextStatus
    }
    conversation {
      id
      participants {
        id
        name
      }
      searchRequestReference {
        id
        title
      }
      leaseAgreement {
        id
        name
      }
      messages {
        ...MessageConversationPreviewValues
      }
    }
    client {
      id
      name
    }
    created
    type
  }
  ${MessageConversationPreviewValuesFragmentDoc}
`;
export const UserValuesFragmentDoc = gql`
  fragment UserValues on User {
    id
    firstName
    lastName
    activeClientId
    activeClient {
      name
      id
      type
    }
    clients {
      name
      id
    }
    email
    role
    imageId
    image {
      id
      name
      url
      size
    }
    limited
    showMine
    prospect
    companyName
    orgNumber
    phone
    disabled
    trial {
      startDate
      endDate
    }
    emailSettings {
      enable
      onMessage
      onMatch
      onSearchHit
      onIntresting
      onSearchRequest
      reminders
    }
    searchRequestSettings {
      regionIds
      usageCategories
      filterEmails
      size
    }
    failedLogins
    onBoarded
    activeModal
    jobTitle
    userCategory
  }
`;
export const UpdateActiveClientDocument = gql`
  mutation UpdateActiveClient($id: String!, $input: UserInput!) {
    editUser(id: $id, input: $input) {
      id
    }
  }
`;
export type UpdateActiveClientMutationFn = Apollo.MutationFunction<
  UpdateActiveClientMutation,
  UpdateActiveClientMutationVariables
>;

/**
 * __useUpdateActiveClientMutation__
 *
 * To run a mutation, you first call `useUpdateActiveClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveClientMutation, { data, loading, error }] = useUpdateActiveClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActiveClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateActiveClientMutation,
    UpdateActiveClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateActiveClientMutation,
    UpdateActiveClientMutationVariables
  >(UpdateActiveClientDocument, options);
}
export type UpdateActiveClientMutationHookResult = ReturnType<
  typeof useUpdateActiveClientMutation
>;
export type UpdateActiveClientMutationResult =
  Apollo.MutationResult<UpdateActiveClientMutation>;
export type UpdateActiveClientMutationOptions = Apollo.BaseMutationOptions<
  UpdateActiveClientMutation,
  UpdateActiveClientMutationVariables
>;
export const MyEstatesDocument = gql`
  query MyEstates {
    myEstates {
      name
      estateDesignation
      address {
        city
      }
      id
    }
  }
`;

/**
 * __useMyEstatesQuery__
 *
 * To run a query within a React component, call `useMyEstatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyEstatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyEstatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyEstatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyEstatesQuery,
    MyEstatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyEstatesQuery, MyEstatesQueryVariables>(
    MyEstatesDocument,
    options,
  );
}
export function useMyEstatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyEstatesQuery,
    MyEstatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyEstatesQuery, MyEstatesQueryVariables>(
    MyEstatesDocument,
    options,
  );
}
export type MyEstatesQueryHookResult = ReturnType<typeof useMyEstatesQuery>;
export type MyEstatesLazyQueryHookResult = ReturnType<
  typeof useMyEstatesLazyQuery
>;
export type MyEstatesQueryResult = Apollo.QueryResult<
  MyEstatesQuery,
  MyEstatesQueryVariables
>;
export const AddAttachmentDocument = gql`
  mutation AddAttachment($conversationId: String!, $attachmentIds: [String!]!) {
    addAttachments(
      conversationId: $conversationId
      attachmentIds: $attachmentIds
    ) {
      id
      attachmentData {
        attachments {
          ...AttachmentValues
        }
        count
      }
    }
  }
  ${AttachmentValuesFragmentDoc}
`;
export type AddAttachmentMutationFn = Apollo.MutationFunction<
  AddAttachmentMutation,
  AddAttachmentMutationVariables
>;

/**
 * __useAddAttachmentMutation__
 *
 * To run a mutation, you first call `useAddAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAttachmentMutation, { data, loading, error }] = useAddAttachmentMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      attachmentIds: // value for 'attachmentIds'
 *   },
 * });
 */
export function useAddAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAttachmentMutation,
    AddAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddAttachmentMutation,
    AddAttachmentMutationVariables
  >(AddAttachmentDocument, options);
}
export type AddAttachmentMutationHookResult = ReturnType<
  typeof useAddAttachmentMutation
>;
export type AddAttachmentMutationResult =
  Apollo.MutationResult<AddAttachmentMutation>;
export type AddAttachmentMutationOptions = Apollo.BaseMutationOptions<
  AddAttachmentMutation,
  AddAttachmentMutationVariables
>;
export const AddMessageDocument = gql`
  mutation AddMessage($input: MessageInput!) {
    addMessage(input: $input) {
      ...MessageValues
      conversation {
        id
        pending
      }
    }
  }
  ${MessageValuesFragmentDoc}
`;
export type AddMessageMutationFn = Apollo.MutationFunction<
  AddMessageMutation,
  AddMessageMutationVariables
>;

/**
 * __useAddMessageMutation__
 *
 * To run a mutation, you first call `useAddMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMessageMutation, { data, loading, error }] = useAddMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMessageMutation,
    AddMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMessageMutation, AddMessageMutationVariables>(
    AddMessageDocument,
    options,
  );
}
export type AddMessageMutationHookResult = ReturnType<
  typeof useAddMessageMutation
>;
export type AddMessageMutationResult =
  Apollo.MutationResult<AddMessageMutation>;
export type AddMessageMutationOptions = Apollo.BaseMutationOptions<
  AddMessageMutation,
  AddMessageMutationVariables
>;
export const ArchiveConversationDocument = gql`
  mutation ArchiveConversation($id: String!, $archived: Boolean!) {
    setArchived(id: $id, archived: $archived) {
      id
      archived
    }
  }
`;
export type ArchiveConversationMutationFn = Apollo.MutationFunction<
  ArchiveConversationMutation,
  ArchiveConversationMutationVariables
>;

/**
 * __useArchiveConversationMutation__
 *
 * To run a mutation, you first call `useArchiveConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveConversationMutation, { data, loading, error }] = useArchiveConversationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useArchiveConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveConversationMutation,
    ArchiveConversationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveConversationMutation,
    ArchiveConversationMutationVariables
  >(ArchiveConversationDocument, options);
}
export type ArchiveConversationMutationHookResult = ReturnType<
  typeof useArchiveConversationMutation
>;
export type ArchiveConversationMutationResult =
  Apollo.MutationResult<ArchiveConversationMutation>;
export type ArchiveConversationMutationOptions = Apollo.BaseMutationOptions<
  ArchiveConversationMutation,
  ArchiveConversationMutationVariables
>;
export const GetConversationDocument = gql`
  query GetConversation($id: String!) {
    conversation(id: $id) {
      id
      archived
      opponents {
        id
        name
        rating
        image {
          id
          url(processing: { quality: auto })
        }
      }
      leaseAgreement {
        id
        name
        image {
          id
          url(processing: { quality: auto })
        }
      }
      searchRequestReference {
        id
        title
        status
        disabledReason {
          reason
          comment
        }
        clientId
        ...SearchRequestReferenceMatchValues
      }
      unread {
        messages
        attachments
      }
      unanswered
      pending
      messages {
        ...MessageValues
      }
    }
  }
  ${SearchRequestReferenceMatchValuesFragmentDoc}
  ${MessageValuesFragmentDoc}
`;

/**
 * __useGetConversationQuery__
 *
 * To run a query within a React component, call `useGetConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConversationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConversationQuery,
    GetConversationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConversationQuery, GetConversationQueryVariables>(
    GetConversationDocument,
    options,
  );
}
export function useGetConversationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConversationQuery,
    GetConversationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConversationQuery,
    GetConversationQueryVariables
  >(GetConversationDocument, options);
}
export type GetConversationQueryHookResult = ReturnType<
  typeof useGetConversationQuery
>;
export type GetConversationLazyQueryHookResult = ReturnType<
  typeof useGetConversationLazyQuery
>;
export type GetConversationQueryResult = Apollo.QueryResult<
  GetConversationQuery,
  GetConversationQueryVariables
>;
export const MarkConversationAsAnsweredDocument = gql`
  mutation MarkConversationAsAnswered($id: String!) {
    markAsAnswered(id: $id) {
      id
      unanswered
    }
  }
`;
export type MarkConversationAsAnsweredMutationFn = Apollo.MutationFunction<
  MarkConversationAsAnsweredMutation,
  MarkConversationAsAnsweredMutationVariables
>;

/**
 * __useMarkConversationAsAnsweredMutation__
 *
 * To run a mutation, you first call `useMarkConversationAsAnsweredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkConversationAsAnsweredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markConversationAsAnsweredMutation, { data, loading, error }] = useMarkConversationAsAnsweredMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkConversationAsAnsweredMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkConversationAsAnsweredMutation,
    MarkConversationAsAnsweredMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkConversationAsAnsweredMutation,
    MarkConversationAsAnsweredMutationVariables
  >(MarkConversationAsAnsweredDocument, options);
}
export type MarkConversationAsAnsweredMutationHookResult = ReturnType<
  typeof useMarkConversationAsAnsweredMutation
>;
export type MarkConversationAsAnsweredMutationResult =
  Apollo.MutationResult<MarkConversationAsAnsweredMutation>;
export type MarkConversationAsAnsweredMutationOptions =
  Apollo.BaseMutationOptions<
    MarkConversationAsAnsweredMutation,
    MarkConversationAsAnsweredMutationVariables
  >;
export const SubscribeToConversationDocument = gql`
  subscription SubscribeToConversation($conversationId: String!) {
    conversation(conversationId: $conversationId) {
      ...MessageValues
      conversation {
        id
      }
    }
  }
  ${MessageValuesFragmentDoc}
`;

/**
 * __useSubscribeToConversationSubscription__
 *
 * To run a query within a React component, call `useSubscribeToConversationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToConversationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToConversationSubscription({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useSubscribeToConversationSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToConversationSubscription,
    SubscribeToConversationSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToConversationSubscription,
    SubscribeToConversationSubscriptionVariables
  >(SubscribeToConversationDocument, options);
}
export type SubscribeToConversationSubscriptionHookResult = ReturnType<
  typeof useSubscribeToConversationSubscription
>;
export type SubscribeToConversationSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToConversationSubscription>;
export const SubscribeToConversationAttachmentsDocument = gql`
  subscription SubscribeToConversationAttachments($conversationId: String!) {
    conversationAttachment(conversationId: $conversationId) {
      ...AttachmentValues
      conversation {
        id
        unread {
          attachments
          messages
          count
        }
      }
    }
  }
  ${AttachmentValuesFragmentDoc}
`;

/**
 * __useSubscribeToConversationAttachmentsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToConversationAttachmentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToConversationAttachmentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToConversationAttachmentsSubscription({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useSubscribeToConversationAttachmentsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToConversationAttachmentsSubscription,
    SubscribeToConversationAttachmentsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToConversationAttachmentsSubscription,
    SubscribeToConversationAttachmentsSubscriptionVariables
  >(SubscribeToConversationAttachmentsDocument, options);
}
export type SubscribeToConversationAttachmentsSubscriptionHookResult =
  ReturnType<typeof useSubscribeToConversationAttachmentsSubscription>;
export type SubscribeToConversationAttachmentsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToConversationAttachmentsSubscription>;
export const SubscribeToReactionsDocument = gql`
  subscription SubscribeToReactions {
    newReaction {
      id
      reactions {
        userId
        reaction
      }
    }
  }
`;

/**
 * __useSubscribeToReactionsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToReactionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToReactionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToReactionsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeToReactionsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToReactionsSubscription,
    SubscribeToReactionsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToReactionsSubscription,
    SubscribeToReactionsSubscriptionVariables
  >(SubscribeToReactionsDocument, options);
}
export type SubscribeToReactionsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToReactionsSubscription
>;
export type SubscribeToReactionsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToReactionsSubscription>;
export const ViewAttachmentsDocument = gql`
  mutation ViewAttachments($conversationId: String!) {
    viewAttachments(conversationId: $conversationId) {
      id
      unread {
        count
        attachments
        messages
      }
    }
  }
`;
export type ViewAttachmentsMutationFn = Apollo.MutationFunction<
  ViewAttachmentsMutation,
  ViewAttachmentsMutationVariables
>;

/**
 * __useViewAttachmentsMutation__
 *
 * To run a mutation, you first call `useViewAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewAttachmentsMutation, { data, loading, error }] = useViewAttachmentsMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useViewAttachmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ViewAttachmentsMutation,
    ViewAttachmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ViewAttachmentsMutation,
    ViewAttachmentsMutationVariables
  >(ViewAttachmentsDocument, options);
}
export type ViewAttachmentsMutationHookResult = ReturnType<
  typeof useViewAttachmentsMutation
>;
export type ViewAttachmentsMutationResult =
  Apollo.MutationResult<ViewAttachmentsMutation>;
export type ViewAttachmentsMutationOptions = Apollo.BaseMutationOptions<
  ViewAttachmentsMutation,
  ViewAttachmentsMutationVariables
>;
export const ViewConversationDocument = gql`
  mutation ViewConversation($conversationId: String!) {
    viewConversation(conversationId: $conversationId) {
      id
      unread {
        count
        attachments
        messages
      }
    }
  }
`;
export type ViewConversationMutationFn = Apollo.MutationFunction<
  ViewConversationMutation,
  ViewConversationMutationVariables
>;

/**
 * __useViewConversationMutation__
 *
 * To run a mutation, you first call `useViewConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewConversationMutation, { data, loading, error }] = useViewConversationMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useViewConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ViewConversationMutation,
    ViewConversationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ViewConversationMutation,
    ViewConversationMutationVariables
  >(ViewConversationDocument, options);
}
export type ViewConversationMutationHookResult = ReturnType<
  typeof useViewConversationMutation
>;
export type ViewConversationMutationResult =
  Apollo.MutationResult<ViewConversationMutation>;
export type ViewConversationMutationOptions = Apollo.BaseMutationOptions<
  ViewConversationMutation,
  ViewConversationMutationVariables
>;
export const ReactOnMessageDocument = gql`
  mutation ReactOnMessage($input: ReactOnMessageInput!) {
    reactOnMessage(input: $input) {
      id
      reactions {
        userId
        user {
          name
        }
        reaction
      }
    }
  }
`;
export type ReactOnMessageMutationFn = Apollo.MutationFunction<
  ReactOnMessageMutation,
  ReactOnMessageMutationVariables
>;

/**
 * __useReactOnMessageMutation__
 *
 * To run a mutation, you first call `useReactOnMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactOnMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactOnMessageMutation, { data, loading, error }] = useReactOnMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReactOnMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReactOnMessageMutation,
    ReactOnMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReactOnMessageMutation,
    ReactOnMessageMutationVariables
  >(ReactOnMessageDocument, options);
}
export type ReactOnMessageMutationHookResult = ReturnType<
  typeof useReactOnMessageMutation
>;
export type ReactOnMessageMutationResult =
  Apollo.MutationResult<ReactOnMessageMutation>;
export type ReactOnMessageMutationOptions = Apollo.BaseMutationOptions<
  ReactOnMessageMutation,
  ReactOnMessageMutationVariables
>;
export const DisableInterestingPopconfirmDocument = gql`
  mutation DisableInterestingPopconfirm {
    disableInterestingPopconfirm {
      id
      interestingPopconfirm
    }
  }
`;
export type DisableInterestingPopconfirmMutationFn = Apollo.MutationFunction<
  DisableInterestingPopconfirmMutation,
  DisableInterestingPopconfirmMutationVariables
>;

/**
 * __useDisableInterestingPopconfirmMutation__
 *
 * To run a mutation, you first call `useDisableInterestingPopconfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableInterestingPopconfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableInterestingPopconfirmMutation, { data, loading, error }] = useDisableInterestingPopconfirmMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisableInterestingPopconfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableInterestingPopconfirmMutation,
    DisableInterestingPopconfirmMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisableInterestingPopconfirmMutation,
    DisableInterestingPopconfirmMutationVariables
  >(DisableInterestingPopconfirmDocument, options);
}
export type DisableInterestingPopconfirmMutationHookResult = ReturnType<
  typeof useDisableInterestingPopconfirmMutation
>;
export type DisableInterestingPopconfirmMutationResult =
  Apollo.MutationResult<DisableInterestingPopconfirmMutation>;
export type DisableInterestingPopconfirmMutationOptions =
  Apollo.BaseMutationOptions<
    DisableInterestingPopconfirmMutation,
    DisableInterestingPopconfirmMutationVariables
  >;
export const AddContactDocument = gql`
  mutation AddContact($input: ContactInput!) {
    addContact(input: $input) {
      ...ContactValues
    }
  }
  ${ContactValuesFragmentDoc}
`;
export type AddContactMutationFn = Apollo.MutationFunction<
  AddContactMutation,
  AddContactMutationVariables
>;

/**
 * __useAddContactMutation__
 *
 * To run a mutation, you first call `useAddContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactMutation, { data, loading, error }] = useAddContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddContactMutation,
    AddContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddContactMutation, AddContactMutationVariables>(
    AddContactDocument,
    options,
  );
}
export type AddContactMutationHookResult = ReturnType<
  typeof useAddContactMutation
>;
export type AddContactMutationResult =
  Apollo.MutationResult<AddContactMutation>;
export type AddContactMutationOptions = Apollo.BaseMutationOptions<
  AddContactMutation,
  AddContactMutationVariables
>;
export const CreateUserFromContactDocument = gql`
  mutation CreateUserFromContact($id: String!) {
    createUserFromContact(id: $id) {
      id
    }
  }
`;
export type CreateUserFromContactMutationFn = Apollo.MutationFunction<
  CreateUserFromContactMutation,
  CreateUserFromContactMutationVariables
>;

/**
 * __useCreateUserFromContactMutation__
 *
 * To run a mutation, you first call `useCreateUserFromContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserFromContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserFromContactMutation, { data, loading, error }] = useCreateUserFromContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateUserFromContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserFromContactMutation,
    CreateUserFromContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserFromContactMutation,
    CreateUserFromContactMutationVariables
  >(CreateUserFromContactDocument, options);
}
export type CreateUserFromContactMutationHookResult = ReturnType<
  typeof useCreateUserFromContactMutation
>;
export type CreateUserFromContactMutationResult =
  Apollo.MutationResult<CreateUserFromContactMutation>;
export type CreateUserFromContactMutationOptions = Apollo.BaseMutationOptions<
  CreateUserFromContactMutation,
  CreateUserFromContactMutationVariables
>;
export const DeleteContactDocument = gql`
  mutation DeleteContact($id: String!) {
    deleteContact(id: $id) {
      ...ContactValues
    }
  }
  ${ContactValuesFragmentDoc}
`;
export type DeleteContactMutationFn = Apollo.MutationFunction<
  DeleteContactMutation,
  DeleteContactMutationVariables
>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteContactMutation,
    DeleteContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteContactMutation,
    DeleteContactMutationVariables
  >(DeleteContactDocument, options);
}
export type DeleteContactMutationHookResult = ReturnType<
  typeof useDeleteContactMutation
>;
export type DeleteContactMutationResult =
  Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<
  DeleteContactMutation,
  DeleteContactMutationVariables
>;
export const EditContactDocument = gql`
  mutation EditContact($id: String!, $input: ContactInput!) {
    editContact(id: $id, input: $input) {
      ...ContactValues
    }
  }
  ${ContactValuesFragmentDoc}
`;
export type EditContactMutationFn = Apollo.MutationFunction<
  EditContactMutation,
  EditContactMutationVariables
>;

/**
 * __useEditContactMutation__
 *
 * To run a mutation, you first call `useEditContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editContactMutation, { data, loading, error }] = useEditContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditContactMutation,
    EditContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditContactMutation, EditContactMutationVariables>(
    EditContactDocument,
    options,
  );
}
export type EditContactMutationHookResult = ReturnType<
  typeof useEditContactMutation
>;
export type EditContactMutationResult =
  Apollo.MutationResult<EditContactMutation>;
export type EditContactMutationOptions = Apollo.BaseMutationOptions<
  EditContactMutation,
  EditContactMutationVariables
>;
export const GetContactsDocument = gql`
  query GetContacts($clientId: String!) {
    contacts(filter: { clientId: $clientId }) {
      ...ContactValues
    }
  }
  ${ContactValuesFragmentDoc}
`;

/**
 * __useGetContactsQuery__
 *
 * To run a query within a React component, call `useGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContactsQuery,
    GetContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContactsQuery, GetContactsQueryVariables>(
    GetContactsDocument,
    options,
  );
}
export function useGetContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactsQuery,
    GetContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContactsQuery, GetContactsQueryVariables>(
    GetContactsDocument,
    options,
  );
}
export type GetContactsQueryHookResult = ReturnType<typeof useGetContactsQuery>;
export type GetContactsLazyQueryHookResult = ReturnType<
  typeof useGetContactsLazyQuery
>;
export type GetContactsQueryResult = Apollo.QueryResult<
  GetContactsQuery,
  GetContactsQueryVariables
>;
export const GetMessagesByConversationDocument = gql`
  query GetMessagesByConversation($conversationId: String!) {
    conversation(id: $conversationId) {
      id
      messages {
        ...MessageValues
      }
    }
  }
  ${MessageValuesFragmentDoc}
`;

/**
 * __useGetMessagesByConversationQuery__
 *
 * To run a query within a React component, call `useGetMessagesByConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesByConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesByConversationQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useGetMessagesByConversationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMessagesByConversationQuery,
    GetMessagesByConversationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMessagesByConversationQuery,
    GetMessagesByConversationQueryVariables
  >(GetMessagesByConversationDocument, options);
}
export function useGetMessagesByConversationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMessagesByConversationQuery,
    GetMessagesByConversationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMessagesByConversationQuery,
    GetMessagesByConversationQueryVariables
  >(GetMessagesByConversationDocument, options);
}
export type GetMessagesByConversationQueryHookResult = ReturnType<
  typeof useGetMessagesByConversationQuery
>;
export type GetMessagesByConversationLazyQueryHookResult = ReturnType<
  typeof useGetMessagesByConversationLazyQuery
>;
export type GetMessagesByConversationQueryResult = Apollo.QueryResult<
  GetMessagesByConversationQuery,
  GetMessagesByConversationQueryVariables
>;
export const GetRatingDocument = gql`
  query GetRating {
    myRating
  }
`;

/**
 * __useGetRatingQuery__
 *
 * To run a query within a React component, call `useGetRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRatingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRatingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRatingQuery,
    GetRatingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRatingQuery, GetRatingQueryVariables>(
    GetRatingDocument,
    options,
  );
}
export function useGetRatingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRatingQuery,
    GetRatingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRatingQuery, GetRatingQueryVariables>(
    GetRatingDocument,
    options,
  );
}
export type GetRatingQueryHookResult = ReturnType<typeof useGetRatingQuery>;
export type GetRatingLazyQueryHookResult = ReturnType<
  typeof useGetRatingLazyQuery
>;
export type GetRatingQueryResult = Apollo.QueryResult<
  GetRatingQuery,
  GetRatingQueryVariables
>;
export const AddDealDocument = gql`
  mutation AddDeal($input: DealInput!) {
    addDeal(input: $input) {
      id
      user {
        name
      }
      userId
      clientId
      madeWith
      annualRent
      created
      client {
        name
      }
    }
  }
`;
export type AddDealMutationFn = Apollo.MutationFunction<
  AddDealMutation,
  AddDealMutationVariables
>;

/**
 * __useAddDealMutation__
 *
 * To run a mutation, you first call `useAddDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDealMutation, { data, loading, error }] = useAddDealMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDealMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddDealMutation,
    AddDealMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddDealMutation, AddDealMutationVariables>(
    AddDealDocument,
    options,
  );
}
export type AddDealMutationHookResult = ReturnType<typeof useAddDealMutation>;
export type AddDealMutationResult = Apollo.MutationResult<AddDealMutation>;
export type AddDealMutationOptions = Apollo.BaseMutationOptions<
  AddDealMutation,
  AddDealMutationVariables
>;
export const GetInvoiceDetailsDocument = gql`
  query GetInvoiceDetails($id: String!) {
    client(id: $id) {
      invoiceDetails {
        billing
        company
        email
        marking
        reference
      }
    }
  }
`;

/**
 * __useGetInvoiceDetailsQuery__
 *
 * To run a query within a React component, call `useGetInvoiceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvoiceDetailsQuery,
    GetInvoiceDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInvoiceDetailsQuery,
    GetInvoiceDetailsQueryVariables
  >(GetInvoiceDetailsDocument, options);
}
export function useGetInvoiceDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoiceDetailsQuery,
    GetInvoiceDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInvoiceDetailsQuery,
    GetInvoiceDetailsQueryVariables
  >(GetInvoiceDetailsDocument, options);
}
export type GetInvoiceDetailsQueryHookResult = ReturnType<
  typeof useGetInvoiceDetailsQuery
>;
export type GetInvoiceDetailsLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceDetailsLazyQuery
>;
export type GetInvoiceDetailsQueryResult = Apollo.QueryResult<
  GetInvoiceDetailsQuery,
  GetInvoiceDetailsQueryVariables
>;
export const GetMyDealDocument = gql`
  query GetMyDeal($id: String!) {
    deal(id: $id) {
      id
      billing
      madeWith
      annualRent
      portallyFee
      email
      reference
      marking
      company
      comment
    }
  }
`;

/**
 * __useGetMyDealQuery__
 *
 * To run a query within a React component, call `useGetMyDealQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyDealQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyDealQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMyDealQuery(
  baseOptions: Apollo.QueryHookOptions<GetMyDealQuery, GetMyDealQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyDealQuery, GetMyDealQueryVariables>(
    GetMyDealDocument,
    options,
  );
}
export function useGetMyDealLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyDealQuery,
    GetMyDealQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyDealQuery, GetMyDealQueryVariables>(
    GetMyDealDocument,
    options,
  );
}
export type GetMyDealQueryHookResult = ReturnType<typeof useGetMyDealQuery>;
export type GetMyDealLazyQueryHookResult = ReturnType<
  typeof useGetMyDealLazyQuery
>;
export type GetMyDealQueryResult = Apollo.QueryResult<
  GetMyDealQuery,
  GetMyDealQueryVariables
>;
export const GetMyDealsDocument = gql`
  query GetMyDeals {
    myDeals {
      id
      user {
        name
      }
      madeWith
      created
    }
  }
`;

/**
 * __useGetMyDealsQuery__
 *
 * To run a query within a React component, call `useGetMyDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyDealsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyDealsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyDealsQuery,
    GetMyDealsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyDealsQuery, GetMyDealsQueryVariables>(
    GetMyDealsDocument,
    options,
  );
}
export function useGetMyDealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyDealsQuery,
    GetMyDealsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyDealsQuery, GetMyDealsQueryVariables>(
    GetMyDealsDocument,
    options,
  );
}
export type GetMyDealsQueryHookResult = ReturnType<typeof useGetMyDealsQuery>;
export type GetMyDealsLazyQueryHookResult = ReturnType<
  typeof useGetMyDealsLazyQuery
>;
export type GetMyDealsQueryResult = Apollo.QueryResult<
  GetMyDealsQuery,
  GetMyDealsQueryVariables
>;
export const RemoveDealDocument = gql`
  mutation RemoveDeal {
    removeDeal {
      id
      activeClientId
      clients {
        id
        name
      }
      hasDealRequest
    }
  }
`;
export type RemoveDealMutationFn = Apollo.MutationFunction<
  RemoveDealMutation,
  RemoveDealMutationVariables
>;

/**
 * __useRemoveDealMutation__
 *
 * To run a mutation, you first call `useRemoveDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDealMutation, { data, loading, error }] = useRemoveDealMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveDealMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveDealMutation,
    RemoveDealMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveDealMutation, RemoveDealMutationVariables>(
    RemoveDealDocument,
    options,
  );
}
export type RemoveDealMutationHookResult = ReturnType<
  typeof useRemoveDealMutation
>;
export type RemoveDealMutationResult =
  Apollo.MutationResult<RemoveDealMutation>;
export type RemoveDealMutationOptions = Apollo.BaseMutationOptions<
  RemoveDealMutation,
  RemoveDealMutationVariables
>;
export const AddEventDocument = gql`
  mutation AddEvent($event: EventInput!) {
    addEvent(event: $event) {
      ...EventFields
    }
  }
  ${EventFieldsFragmentDoc}
`;
export type AddEventMutationFn = Apollo.MutationFunction<
  AddEventMutation,
  AddEventMutationVariables
>;

/**
 * __useAddEventMutation__
 *
 * To run a mutation, you first call `useAddEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventMutation, { data, loading, error }] = useAddEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useAddEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEventMutation,
    AddEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddEventMutation, AddEventMutationVariables>(
    AddEventDocument,
    options,
  );
}
export type AddEventMutationHookResult = ReturnType<typeof useAddEventMutation>;
export type AddEventMutationResult = Apollo.MutationResult<AddEventMutation>;
export type AddEventMutationOptions = Apollo.BaseMutationOptions<
  AddEventMutation,
  AddEventMutationVariables
>;
export const DeleteEventDocument = gql`
  mutation DeleteEvent($id: String!) {
    deleteEvent(id: $id) {
      ...EventFields
    }
  }
  ${EventFieldsFragmentDoc}
`;
export type DeleteEventMutationFn = Apollo.MutationFunction<
  DeleteEventMutation,
  DeleteEventMutationVariables
>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEventMutation,
    DeleteEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(
    DeleteEventDocument,
    options,
  );
}
export type DeleteEventMutationHookResult = ReturnType<
  typeof useDeleteEventMutation
>;
export type DeleteEventMutationResult =
  Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventMutation,
  DeleteEventMutationVariables
>;
export const EditEventDocument = gql`
  mutation EditEvent($id: String!, $event: EventInput!) {
    editEvent(id: $id, event: $event) {
      ...EventFields
    }
  }
  ${EventFieldsFragmentDoc}
`;
export type EditEventMutationFn = Apollo.MutationFunction<
  EditEventMutation,
  EditEventMutationVariables
>;

/**
 * __useEditEventMutation__
 *
 * To run a mutation, you first call `useEditEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEventMutation, { data, loading, error }] = useEditEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useEditEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditEventMutation,
    EditEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditEventMutation, EditEventMutationVariables>(
    EditEventDocument,
    options,
  );
}
export type EditEventMutationHookResult = ReturnType<
  typeof useEditEventMutation
>;
export type EditEventMutationResult = Apollo.MutationResult<EditEventMutation>;
export type EditEventMutationOptions = Apollo.BaseMutationOptions<
  EditEventMutation,
  EditEventMutationVariables
>;
export const AddStatusDocument = gql`
  mutation AddStatus($input: StatusInput!) {
    changeStatus(input: $input) {
      id: leaseAgreementId
      status
      leaseAgreement {
        name
        id
        myStatus
      }
    }
  }
`;
export type AddStatusMutationFn = Apollo.MutationFunction<
  AddStatusMutation,
  AddStatusMutationVariables
>;

/**
 * __useAddStatusMutation__
 *
 * To run a mutation, you first call `useAddStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStatusMutation, { data, loading, error }] = useAddStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddStatusMutation,
    AddStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddStatusMutation, AddStatusMutationVariables>(
    AddStatusDocument,
    options,
  );
}
export type AddStatusMutationHookResult = ReturnType<
  typeof useAddStatusMutation
>;
export type AddStatusMutationResult = Apollo.MutationResult<AddStatusMutation>;
export type AddStatusMutationOptions = Apollo.BaseMutationOptions<
  AddStatusMutation,
  AddStatusMutationVariables
>;
export const SingleStatusDocument = gql`
  query SingleStatus($leaseAgreementId: String!) {
    singleStatus(leaseAgreementId: $leaseAgreementId) {
      id: leaseAgreementId
      status
      leaseAgreement {
        name
        id
      }
    }
  }
`;

/**
 * __useSingleStatusQuery__
 *
 * To run a query within a React component, call `useSingleStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleStatusQuery({
 *   variables: {
 *      leaseAgreementId: // value for 'leaseAgreementId'
 *   },
 * });
 */
export function useSingleStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    SingleStatusQuery,
    SingleStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SingleStatusQuery, SingleStatusQueryVariables>(
    SingleStatusDocument,
    options,
  );
}
export function useSingleStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SingleStatusQuery,
    SingleStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SingleStatusQuery, SingleStatusQueryVariables>(
    SingleStatusDocument,
    options,
  );
}
export type SingleStatusQueryHookResult = ReturnType<
  typeof useSingleStatusQuery
>;
export type SingleStatusLazyQueryHookResult = ReturnType<
  typeof useSingleStatusLazyQuery
>;
export type SingleStatusQueryResult = Apollo.QueryResult<
  SingleStatusQuery,
  SingleStatusQueryVariables
>;
export const GetMatchingSearchRequestsDocument = gql`
  query GetMatchingSearchRequests($leaseAgreementId: String!) {
    leaseAgreement(id: $leaseAgreementId) {
      matchingSearchRequests {
        searchRequests(leaseAgreementId: $leaseAgreementId) {
          ...SearchRequestReferenceListValues
        }
      }
    }
  }
  ${SearchRequestReferenceListValuesFragmentDoc}
`;

/**
 * __useGetMatchingSearchRequestsQuery__
 *
 * To run a query within a React component, call `useGetMatchingSearchRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchingSearchRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchingSearchRequestsQuery({
 *   variables: {
 *      leaseAgreementId: // value for 'leaseAgreementId'
 *   },
 * });
 */
export function useGetMatchingSearchRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMatchingSearchRequestsQuery,
    GetMatchingSearchRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMatchingSearchRequestsQuery,
    GetMatchingSearchRequestsQueryVariables
  >(GetMatchingSearchRequestsDocument, options);
}
export function useGetMatchingSearchRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMatchingSearchRequestsQuery,
    GetMatchingSearchRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMatchingSearchRequestsQuery,
    GetMatchingSearchRequestsQueryVariables
  >(GetMatchingSearchRequestsDocument, options);
}
export type GetMatchingSearchRequestsQueryHookResult = ReturnType<
  typeof useGetMatchingSearchRequestsQuery
>;
export type GetMatchingSearchRequestsLazyQueryHookResult = ReturnType<
  typeof useGetMatchingSearchRequestsLazyQuery
>;
export type GetMatchingSearchRequestsQueryResult = Apollo.QueryResult<
  GetMatchingSearchRequestsQuery,
  GetMatchingSearchRequestsQueryVariables
>;
export const GetLinkPreviewDocument = gql`
  query GetLinkPreview($url: String!) {
    linkPreview(url: $url) {
      title
      description
      image
    }
  }
`;

/**
 * __useGetLinkPreviewQuery__
 *
 * To run a query within a React component, call `useGetLinkPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLinkPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLinkPreviewQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGetLinkPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLinkPreviewQuery,
    GetLinkPreviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLinkPreviewQuery, GetLinkPreviewQueryVariables>(
    GetLinkPreviewDocument,
    options,
  );
}
export function useGetLinkPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLinkPreviewQuery,
    GetLinkPreviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLinkPreviewQuery, GetLinkPreviewQueryVariables>(
    GetLinkPreviewDocument,
    options,
  );
}
export type GetLinkPreviewQueryHookResult = ReturnType<
  typeof useGetLinkPreviewQuery
>;
export type GetLinkPreviewLazyQueryHookResult = ReturnType<
  typeof useGetLinkPreviewLazyQuery
>;
export type GetLinkPreviewQueryResult = Apollo.QueryResult<
  GetLinkPreviewQuery,
  GetLinkPreviewQueryVariables
>;
export const RefreshUserTokenDocument = gql`
  mutation RefreshUserToken {
    refresh
  }
`;
export type RefreshUserTokenMutationFn = Apollo.MutationFunction<
  RefreshUserTokenMutation,
  RefreshUserTokenMutationVariables
>;

/**
 * __useRefreshUserTokenMutation__
 *
 * To run a mutation, you first call `useRefreshUserTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshUserTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshUserTokenMutation, { data, loading, error }] = useRefreshUserTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshUserTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshUserTokenMutation,
    RefreshUserTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshUserTokenMutation,
    RefreshUserTokenMutationVariables
  >(RefreshUserTokenDocument, options);
}
export type RefreshUserTokenMutationHookResult = ReturnType<
  typeof useRefreshUserTokenMutation
>;
export type RefreshUserTokenMutationResult =
  Apollo.MutationResult<RefreshUserTokenMutation>;
export type RefreshUserTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshUserTokenMutation,
  RefreshUserTokenMutationVariables
>;
export const GetLeaseAgreementViewByIdDocument = gql`
  query GetLeaseAgreementViewById($id: String!) {
    leaseAgreement(id: $id) {
      ...LeaseAgreementListValues
    }
  }
  ${LeaseAgreementListValuesFragmentDoc}
`;

/**
 * __useGetLeaseAgreementViewByIdQuery__
 *
 * To run a query within a React component, call `useGetLeaseAgreementViewByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaseAgreementViewByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaseAgreementViewByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLeaseAgreementViewByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLeaseAgreementViewByIdQuery,
    GetLeaseAgreementViewByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLeaseAgreementViewByIdQuery,
    GetLeaseAgreementViewByIdQueryVariables
  >(GetLeaseAgreementViewByIdDocument, options);
}
export function useGetLeaseAgreementViewByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeaseAgreementViewByIdQuery,
    GetLeaseAgreementViewByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLeaseAgreementViewByIdQuery,
    GetLeaseAgreementViewByIdQueryVariables
  >(GetLeaseAgreementViewByIdDocument, options);
}
export type GetLeaseAgreementViewByIdQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementViewByIdQuery
>;
export type GetLeaseAgreementViewByIdLazyQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementViewByIdLazyQuery
>;
export type GetLeaseAgreementViewByIdQueryResult = Apollo.QueryResult<
  GetLeaseAgreementViewByIdQuery,
  GetLeaseAgreementViewByIdQueryVariables
>;
export const GetLeaseAgreementDetailsDocument = gql`
  query GetLeaseAgreementDetails($id: String!) {
    leaseAgreement(id: $id) {
      id
      name
      size
      usageCategory
      title
      image {
        id
        url(processing: { quality: auto, height: 400 })
      }
    }
  }
`;

/**
 * __useGetLeaseAgreementDetailsQuery__
 *
 * To run a query within a React component, call `useGetLeaseAgreementDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaseAgreementDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaseAgreementDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLeaseAgreementDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLeaseAgreementDetailsQuery,
    GetLeaseAgreementDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLeaseAgreementDetailsQuery,
    GetLeaseAgreementDetailsQueryVariables
  >(GetLeaseAgreementDetailsDocument, options);
}
export function useGetLeaseAgreementDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeaseAgreementDetailsQuery,
    GetLeaseAgreementDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLeaseAgreementDetailsQuery,
    GetLeaseAgreementDetailsQueryVariables
  >(GetLeaseAgreementDetailsDocument, options);
}
export type GetLeaseAgreementDetailsQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementDetailsQuery
>;
export type GetLeaseAgreementDetailsLazyQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementDetailsLazyQuery
>;
export type GetLeaseAgreementDetailsQueryResult = Apollo.QueryResult<
  GetLeaseAgreementDetailsQuery,
  GetLeaseAgreementDetailsQueryVariables
>;
export const GetLeaseAgreementsByIdsDocument = gql`
  query GetLeaseAgreementsByIds($ids: [String!]!) {
    leaseAgreementsByIds(ids: $ids) {
      ...LeaseAgreementListValues
    }
  }
  ${LeaseAgreementListValuesFragmentDoc}
`;

/**
 * __useGetLeaseAgreementsByIdsQuery__
 *
 * To run a query within a React component, call `useGetLeaseAgreementsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaseAgreementsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaseAgreementsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetLeaseAgreementsByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLeaseAgreementsByIdsQuery,
    GetLeaseAgreementsByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLeaseAgreementsByIdsQuery,
    GetLeaseAgreementsByIdsQueryVariables
  >(GetLeaseAgreementsByIdsDocument, options);
}
export function useGetLeaseAgreementsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeaseAgreementsByIdsQuery,
    GetLeaseAgreementsByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLeaseAgreementsByIdsQuery,
    GetLeaseAgreementsByIdsQueryVariables
  >(GetLeaseAgreementsByIdsDocument, options);
}
export type GetLeaseAgreementsByIdsQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementsByIdsQuery
>;
export type GetLeaseAgreementsByIdsLazyQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementsByIdsLazyQuery
>;
export type GetLeaseAgreementsByIdsQueryResult = Apollo.QueryResult<
  GetLeaseAgreementsByIdsQuery,
  GetLeaseAgreementsByIdsQueryVariables
>;
export const GetConversationsMessageLinkDocument = gql`
  query GetConversationsMessageLink($filter: ConversationFilter) {
    conversations(filter: $filter) {
      unread
      count
      conversations {
        conversation {
          isPartner
          id
          unread {
            count
            attachments
            messages
          }
        }
      }
    }
  }
`;

/**
 * __useGetConversationsMessageLinkQuery__
 *
 * To run a query within a React component, call `useGetConversationsMessageLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationsMessageLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationsMessageLinkQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetConversationsMessageLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetConversationsMessageLinkQuery,
    GetConversationsMessageLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetConversationsMessageLinkQuery,
    GetConversationsMessageLinkQueryVariables
  >(GetConversationsMessageLinkDocument, options);
}
export function useGetConversationsMessageLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConversationsMessageLinkQuery,
    GetConversationsMessageLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConversationsMessageLinkQuery,
    GetConversationsMessageLinkQueryVariables
  >(GetConversationsMessageLinkDocument, options);
}
export type GetConversationsMessageLinkQueryHookResult = ReturnType<
  typeof useGetConversationsMessageLinkQuery
>;
export type GetConversationsMessageLinkLazyQueryHookResult = ReturnType<
  typeof useGetConversationsMessageLinkLazyQuery
>;
export type GetConversationsMessageLinkQueryResult = Apollo.QueryResult<
  GetConversationsMessageLinkQuery,
  GetConversationsMessageLinkQueryVariables
>;
export const GetMySearchRequestByReferenceIdDocument = gql`
  query GetMySearchRequestByReferenceId($id: String!) {
    mySearchRequestByReferenceId(id: $id) {
      status
      ...SearchRequestCardValues
    }
  }
  ${SearchRequestCardValuesFragmentDoc}
`;

/**
 * __useGetMySearchRequestByReferenceIdQuery__
 *
 * To run a query within a React component, call `useGetMySearchRequestByReferenceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySearchRequestByReferenceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySearchRequestByReferenceIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMySearchRequestByReferenceIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMySearchRequestByReferenceIdQuery,
    GetMySearchRequestByReferenceIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMySearchRequestByReferenceIdQuery,
    GetMySearchRequestByReferenceIdQueryVariables
  >(GetMySearchRequestByReferenceIdDocument, options);
}
export function useGetMySearchRequestByReferenceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMySearchRequestByReferenceIdQuery,
    GetMySearchRequestByReferenceIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMySearchRequestByReferenceIdQuery,
    GetMySearchRequestByReferenceIdQueryVariables
  >(GetMySearchRequestByReferenceIdDocument, options);
}
export type GetMySearchRequestByReferenceIdQueryHookResult = ReturnType<
  typeof useGetMySearchRequestByReferenceIdQuery
>;
export type GetMySearchRequestByReferenceIdLazyQueryHookResult = ReturnType<
  typeof useGetMySearchRequestByReferenceIdLazyQuery
>;
export type GetMySearchRequestByReferenceIdQueryResult = Apollo.QueryResult<
  GetMySearchRequestByReferenceIdQuery,
  GetMySearchRequestByReferenceIdQueryVariables
>;
export const GetNotificationsDocument = gql`
  query GetNotifications($limit: Int!, $skip: Int!) {
    notifications(filter: { limit: $limit, skip: $skip }) {
      notifications {
        ...NotificationValues
      }
      count
    }
  }
  ${NotificationValuesFragmentDoc}
`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    options,
  );
}
export function useGetNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >(GetNotificationsDocument, options);
}
export type GetNotificationsQueryHookResult = ReturnType<
  typeof useGetNotificationsQuery
>;
export type GetNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetNotificationsLazyQuery
>;
export type GetNotificationsQueryResult = Apollo.QueryResult<
  GetNotificationsQuery,
  GetNotificationsQueryVariables
>;
export const SubscribeToNotificationsDocument = gql`
  subscription SubscribeToNotifications {
    newNotification {
      ...NotificationValues
    }
  }
  ${NotificationValuesFragmentDoc}
`;

/**
 * __useSubscribeToNotificationsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToNotificationsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeToNotificationsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToNotificationsSubscription,
    SubscribeToNotificationsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToNotificationsSubscription,
    SubscribeToNotificationsSubscriptionVariables
  >(SubscribeToNotificationsDocument, options);
}
export type SubscribeToNotificationsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToNotificationsSubscription
>;
export type SubscribeToNotificationsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToNotificationsSubscription>;
export const ReadNotificationDocument = gql`
  mutation readNotification($id: String) {
    readNotification(id: $id) {
      ...NotificationValues
    }
  }
  ${NotificationValuesFragmentDoc}
`;
export type ReadNotificationMutationFn = Apollo.MutationFunction<
  ReadNotificationMutation,
  ReadNotificationMutationVariables
>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReadNotificationMutation,
    ReadNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReadNotificationMutation,
    ReadNotificationMutationVariables
  >(ReadNotificationDocument, options);
}
export type ReadNotificationMutationHookResult = ReturnType<
  typeof useReadNotificationMutation
>;
export type ReadNotificationMutationResult =
  Apollo.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = Apollo.BaseMutationOptions<
  ReadNotificationMutation,
  ReadNotificationMutationVariables
>;
export const GetPartnersDocument = gql`
  query GetPartners {
    partners {
      id
      slug
      name
      image {
        url
        id
      }
    }
  }
`;

/**
 * __useGetPartnersQuery__
 *
 * To run a query within a React component, call `useGetPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPartnersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPartnersQuery,
    GetPartnersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartnersQuery, GetPartnersQueryVariables>(
    GetPartnersDocument,
    options,
  );
}
export function useGetPartnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartnersQuery,
    GetPartnersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartnersQuery, GetPartnersQueryVariables>(
    GetPartnersDocument,
    options,
  );
}
export type GetPartnersQueryHookResult = ReturnType<typeof useGetPartnersQuery>;
export type GetPartnersLazyQueryHookResult = ReturnType<
  typeof useGetPartnersLazyQuery
>;
export type GetPartnersQueryResult = Apollo.QueryResult<
  GetPartnersQuery,
  GetPartnersQueryVariables
>;
export const ClickAdDocument = gql`
  mutation ClickAd($id: ID!) {
    clickAd(id: $id)
  }
`;
export type ClickAdMutationFn = Apollo.MutationFunction<
  ClickAdMutation,
  ClickAdMutationVariables
>;

/**
 * __useClickAdMutation__
 *
 * To run a mutation, you first call `useClickAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClickAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clickAdMutation, { data, loading, error }] = useClickAdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClickAdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClickAdMutation,
    ClickAdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClickAdMutation, ClickAdMutationVariables>(
    ClickAdDocument,
    options,
  );
}
export type ClickAdMutationHookResult = ReturnType<typeof useClickAdMutation>;
export type ClickAdMutationResult = Apollo.MutationResult<ClickAdMutation>;
export type ClickAdMutationOptions = Apollo.BaseMutationOptions<
  ClickAdMutation,
  ClickAdMutationVariables
>;
export const GetAdDocument = gql`
  query GetAd($width: Int!, $height: Int!, $filter: AdFilter!) {
    portallyAd(filter: $filter) {
      id
      image {
        url(processing: { width: $width, height: $height })
      }
      placement
      partner {
        slug
      }
      link
    }
  }
`;

/**
 * __useGetAdQuery__
 *
 * To run a query within a React component, call `useGetAdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdQuery({
 *   variables: {
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAdQuery(
  baseOptions: Apollo.QueryHookOptions<GetAdQuery, GetAdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdQuery, GetAdQueryVariables>(
    GetAdDocument,
    options,
  );
}
export function useGetAdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAdQuery, GetAdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdQuery, GetAdQueryVariables>(
    GetAdDocument,
    options,
  );
}
export type GetAdQueryHookResult = ReturnType<typeof useGetAdQuery>;
export type GetAdLazyQueryHookResult = ReturnType<typeof useGetAdLazyQuery>;
export type GetAdQueryResult = Apollo.QueryResult<
  GetAdQuery,
  GetAdQueryVariables
>;
export const ViewAdDocument = gql`
  mutation ViewAd($id: ID!) {
    viewAd(id: $id)
  }
`;
export type ViewAdMutationFn = Apollo.MutationFunction<
  ViewAdMutation,
  ViewAdMutationVariables
>;

/**
 * __useViewAdMutation__
 *
 * To run a mutation, you first call `useViewAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewAdMutation, { data, loading, error }] = useViewAdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewAdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ViewAdMutation,
    ViewAdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ViewAdMutation, ViewAdMutationVariables>(
    ViewAdDocument,
    options,
  );
}
export type ViewAdMutationHookResult = ReturnType<typeof useViewAdMutation>;
export type ViewAdMutationResult = Apollo.MutationResult<ViewAdMutation>;
export type ViewAdMutationOptions = Apollo.BaseMutationOptions<
  ViewAdMutation,
  ViewAdMutationVariables
>;
export const ChangeStatusPromotionDocument = gql`
  mutation ChangeStatusPromotion($input: PromotionStatus!) {
    changeStatusPromotion(input: $input) {
      id
      client {
        id
        name
      }
      leaseAgreement {
        id
        name
        address {
          city
        }
      }
      opened
      status
    }
  }
`;
export type ChangeStatusPromotionMutationFn = Apollo.MutationFunction<
  ChangeStatusPromotionMutation,
  ChangeStatusPromotionMutationVariables
>;

/**
 * __useChangeStatusPromotionMutation__
 *
 * To run a mutation, you first call `useChangeStatusPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeStatusPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeStatusPromotionMutation, { data, loading, error }] = useChangeStatusPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeStatusPromotionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeStatusPromotionMutation,
    ChangeStatusPromotionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeStatusPromotionMutation,
    ChangeStatusPromotionMutationVariables
  >(ChangeStatusPromotionDocument, options);
}
export type ChangeStatusPromotionMutationHookResult = ReturnType<
  typeof useChangeStatusPromotionMutation
>;
export type ChangeStatusPromotionMutationResult =
  Apollo.MutationResult<ChangeStatusPromotionMutation>;
export type ChangeStatusPromotionMutationOptions = Apollo.BaseMutationOptions<
  ChangeStatusPromotionMutation,
  ChangeStatusPromotionMutationVariables
>;
export const GetOfferDocument = gql`
  query GetOffer($clientId: String!, $leaseAgreementId: String!) {
    singlePromoted(clientId: $clientId, leaseAgreementId: $leaseAgreementId) {
      id
      client {
        id
        name
      }
      owner {
        id
        name
      }
      leaseAgreement {
        id
        name
        address {
          city
        }
      }
      opened
      status
    }
  }
`;

/**
 * __useGetOfferQuery__
 *
 * To run a query within a React component, call `useGetOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      leaseAgreementId: // value for 'leaseAgreementId'
 *   },
 * });
 */
export function useGetOfferQuery(
  baseOptions: Apollo.QueryHookOptions<GetOfferQuery, GetOfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfferQuery, GetOfferQueryVariables>(
    GetOfferDocument,
    options,
  );
}
export function useGetOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOfferQuery,
    GetOfferQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOfferQuery, GetOfferQueryVariables>(
    GetOfferDocument,
    options,
  );
}
export type GetOfferQueryHookResult = ReturnType<typeof useGetOfferQuery>;
export type GetOfferLazyQueryHookResult = ReturnType<
  typeof useGetOfferLazyQuery
>;
export type GetOfferQueryResult = Apollo.QueryResult<
  GetOfferQuery,
  GetOfferQueryVariables
>;
export const AddProspectDocument = gql`
  mutation AddProspect($input: ProspectInput!) {
    addProspect(input: $input) {
      id
      name
      clientId
      contactId
      clientManager {
        name
        id
      }
      potentialValue
      startDate
      service
      division
      created
      step
      comment
      sourceComment
      source
    }
  }
`;
export type AddProspectMutationFn = Apollo.MutationFunction<
  AddProspectMutation,
  AddProspectMutationVariables
>;

/**
 * __useAddProspectMutation__
 *
 * To run a mutation, you first call `useAddProspectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProspectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProspectMutation, { data, loading, error }] = useAddProspectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProspectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProspectMutation,
    AddProspectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddProspectMutation, AddProspectMutationVariables>(
    AddProspectDocument,
    options,
  );
}
export type AddProspectMutationHookResult = ReturnType<
  typeof useAddProspectMutation
>;
export type AddProspectMutationResult =
  Apollo.MutationResult<AddProspectMutation>;
export type AddProspectMutationOptions = Apollo.BaseMutationOptions<
  AddProspectMutation,
  AddProspectMutationVariables
>;
export const DeleteProspectDocument = gql`
  mutation DeleteProspect($id: String!) {
    deleteProspect(id: $id) {
      id
    }
  }
`;
export type DeleteProspectMutationFn = Apollo.MutationFunction<
  DeleteProspectMutation,
  DeleteProspectMutationVariables
>;

/**
 * __useDeleteProspectMutation__
 *
 * To run a mutation, you first call `useDeleteProspectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProspectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProspectMutation, { data, loading, error }] = useDeleteProspectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProspectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProspectMutation,
    DeleteProspectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProspectMutation,
    DeleteProspectMutationVariables
  >(DeleteProspectDocument, options);
}
export type DeleteProspectMutationHookResult = ReturnType<
  typeof useDeleteProspectMutation
>;
export type DeleteProspectMutationResult =
  Apollo.MutationResult<DeleteProspectMutation>;
export type DeleteProspectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProspectMutation,
  DeleteProspectMutationVariables
>;
export const EditProspectDocument = gql`
  mutation EditProspect($id: String!, $input: ProspectInput) {
    editProspect(id: $id, input: $input) {
      id
      name
      clientId
      contactId
      clientManager {
        name
        id
      }
      division
      potentialValue
      startDate
      service
      step
      comment
      source
      sourceComment
    }
  }
`;
export type EditProspectMutationFn = Apollo.MutationFunction<
  EditProspectMutation,
  EditProspectMutationVariables
>;

/**
 * __useEditProspectMutation__
 *
 * To run a mutation, you first call `useEditProspectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProspectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProspectMutation, { data, loading, error }] = useEditProspectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditProspectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditProspectMutation,
    EditProspectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditProspectMutation,
    EditProspectMutationVariables
  >(EditProspectDocument, options);
}
export type EditProspectMutationHookResult = ReturnType<
  typeof useEditProspectMutation
>;
export type EditProspectMutationResult =
  Apollo.MutationResult<EditProspectMutation>;
export type EditProspectMutationOptions = Apollo.BaseMutationOptions<
  EditProspectMutation,
  EditProspectMutationVariables
>;
export const RefetchStatusDocument = gql`
  query RefetchStatus {
    status {
      status
      leaseAgreement {
        id
        myStatus
      }
      id: leaseAgreementId
    }
  }
`;

/**
 * __useRefetchStatusQuery__
 *
 * To run a query within a React component, call `useRefetchStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefetchStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefetchStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useRefetchStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RefetchStatusQuery,
    RefetchStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RefetchStatusQuery, RefetchStatusQueryVariables>(
    RefetchStatusDocument,
    options,
  );
}
export function useRefetchStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RefetchStatusQuery,
    RefetchStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RefetchStatusQuery, RefetchStatusQueryVariables>(
    RefetchStatusDocument,
    options,
  );
}
export type RefetchStatusQueryHookResult = ReturnType<
  typeof useRefetchStatusQuery
>;
export type RefetchStatusLazyQueryHookResult = ReturnType<
  typeof useRefetchStatusLazyQuery
>;
export type RefetchStatusQueryResult = Apollo.QueryResult<
  RefetchStatusQuery,
  RefetchStatusQueryVariables
>;
export const GetSearchProfilesClientDocument = gql`
  query getSearchProfilesClient($filter: SearchProfileFilterInput!) {
    searchProfiles(filter: $filter) {
      searchProfiles {
        id
        name
        clientId
        size {
          min
          max
        }
      }
      count
    }
  }
`;

/**
 * __useGetSearchProfilesClientQuery__
 *
 * To run a query within a React component, call `useGetSearchProfilesClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchProfilesClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchProfilesClientQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSearchProfilesClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSearchProfilesClientQuery,
    GetSearchProfilesClientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSearchProfilesClientQuery,
    GetSearchProfilesClientQueryVariables
  >(GetSearchProfilesClientDocument, options);
}
export function useGetSearchProfilesClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchProfilesClientQuery,
    GetSearchProfilesClientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchProfilesClientQuery,
    GetSearchProfilesClientQueryVariables
  >(GetSearchProfilesClientDocument, options);
}
export type GetSearchProfilesClientQueryHookResult = ReturnType<
  typeof useGetSearchProfilesClientQuery
>;
export type GetSearchProfilesClientLazyQueryHookResult = ReturnType<
  typeof useGetSearchProfilesClientLazyQuery
>;
export type GetSearchProfilesClientQueryResult = Apollo.QueryResult<
  GetSearchProfilesClientQuery,
  GetSearchProfilesClientQueryVariables
>;
export const GetPotentialSearchRequestReceiversDocument = gql`
  query GetPotentialSearchRequestReceivers($id: String!, $showAll: Boolean!) {
    searchRequestReference(id: $id) {
      receivers(limit: 1000, showAll: $showAll) {
        users {
          ...UserReceiverValues
          activeClient {
            id
            name
          }
        }
        count
      }
    }
  }
  ${UserReceiverValuesFragmentDoc}
`;

/**
 * __useGetPotentialSearchRequestReceiversQuery__
 *
 * To run a query within a React component, call `useGetPotentialSearchRequestReceiversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPotentialSearchRequestReceiversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPotentialSearchRequestReceiversQuery({
 *   variables: {
 *      id: // value for 'id'
 *      showAll: // value for 'showAll'
 *   },
 * });
 */
export function useGetPotentialSearchRequestReceiversQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPotentialSearchRequestReceiversQuery,
    GetPotentialSearchRequestReceiversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPotentialSearchRequestReceiversQuery,
    GetPotentialSearchRequestReceiversQueryVariables
  >(GetPotentialSearchRequestReceiversDocument, options);
}
export function useGetPotentialSearchRequestReceiversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPotentialSearchRequestReceiversQuery,
    GetPotentialSearchRequestReceiversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPotentialSearchRequestReceiversQuery,
    GetPotentialSearchRequestReceiversQueryVariables
  >(GetPotentialSearchRequestReceiversDocument, options);
}
export type GetPotentialSearchRequestReceiversQueryHookResult = ReturnType<
  typeof useGetPotentialSearchRequestReceiversQuery
>;
export type GetPotentialSearchRequestReceiversLazyQueryHookResult = ReturnType<
  typeof useGetPotentialSearchRequestReceiversLazyQuery
>;
export type GetPotentialSearchRequestReceiversQueryResult = Apollo.QueryResult<
  GetPotentialSearchRequestReceiversQuery,
  GetPotentialSearchRequestReceiversQueryVariables
>;
export const GetSearchRequestReceiversAdminDocument = gql`
  query GetSearchRequestReceiversAdmin($id: String!) {
    searchRequestReference(id: $id) {
      searchRequests(
        limit: 1000
        status: [accepted, opened, answer_requested, declined, unopened]
      ) {
        searchRequests {
          id
          searchRequestReferenceId
          receiver {
            id
            name
          }
          responsibleUser {
            ...UserReceiverValues
          }
          status
        }
        count
      }
    }
  }
  ${UserReceiverValuesFragmentDoc}
`;

/**
 * __useGetSearchRequestReceiversAdminQuery__
 *
 * To run a query within a React component, call `useGetSearchRequestReceiversAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchRequestReceiversAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchRequestReceiversAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSearchRequestReceiversAdminQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSearchRequestReceiversAdminQuery,
    GetSearchRequestReceiversAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSearchRequestReceiversAdminQuery,
    GetSearchRequestReceiversAdminQueryVariables
  >(GetSearchRequestReceiversAdminDocument, options);
}
export function useGetSearchRequestReceiversAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchRequestReceiversAdminQuery,
    GetSearchRequestReceiversAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchRequestReceiversAdminQuery,
    GetSearchRequestReceiversAdminQueryVariables
  >(GetSearchRequestReceiversAdminDocument, options);
}
export type GetSearchRequestReceiversAdminQueryHookResult = ReturnType<
  typeof useGetSearchRequestReceiversAdminQuery
>;
export type GetSearchRequestReceiversAdminLazyQueryHookResult = ReturnType<
  typeof useGetSearchRequestReceiversAdminLazyQuery
>;
export type GetSearchRequestReceiversAdminQueryResult = Apollo.QueryResult<
  GetSearchRequestReceiversAdminQuery,
  GetSearchRequestReceiversAdminQueryVariables
>;
export const GetSearchRequestReferenceAdminDocument = gql`
  query GetSearchRequestReferenceAdmin($id: String!) {
    searchRequestReference(id: $id) {
      id
      ...SearchRequestReferenceInformationValues
      sentDate
      adminResponsibleUser {
        id
        name
      }
      adminStatus
    }
  }
  ${SearchRequestReferenceInformationValuesFragmentDoc}
`;

/**
 * __useGetSearchRequestReferenceAdminQuery__
 *
 * To run a query within a React component, call `useGetSearchRequestReferenceAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchRequestReferenceAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchRequestReferenceAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSearchRequestReferenceAdminQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSearchRequestReferenceAdminQuery,
    GetSearchRequestReferenceAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSearchRequestReferenceAdminQuery,
    GetSearchRequestReferenceAdminQueryVariables
  >(GetSearchRequestReferenceAdminDocument, options);
}
export function useGetSearchRequestReferenceAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchRequestReferenceAdminQuery,
    GetSearchRequestReferenceAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchRequestReferenceAdminQuery,
    GetSearchRequestReferenceAdminQueryVariables
  >(GetSearchRequestReferenceAdminDocument, options);
}
export type GetSearchRequestReferenceAdminQueryHookResult = ReturnType<
  typeof useGetSearchRequestReferenceAdminQuery
>;
export type GetSearchRequestReferenceAdminLazyQueryHookResult = ReturnType<
  typeof useGetSearchRequestReferenceAdminLazyQuery
>;
export type GetSearchRequestReferenceAdminQueryResult = Apollo.QueryResult<
  GetSearchRequestReferenceAdminQuery,
  GetSearchRequestReferenceAdminQueryVariables
>;
export const SendSearchRequestToUserDocument = gql`
  mutation SendSearchRequestToUser($input: SendSearchRequestToUserInput!) {
    sendSearchRequestToUser(input: $input)
  }
`;
export type SendSearchRequestToUserMutationFn = Apollo.MutationFunction<
  SendSearchRequestToUserMutation,
  SendSearchRequestToUserMutationVariables
>;

/**
 * __useSendSearchRequestToUserMutation__
 *
 * To run a mutation, you first call `useSendSearchRequestToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSearchRequestToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSearchRequestToUserMutation, { data, loading, error }] = useSendSearchRequestToUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendSearchRequestToUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendSearchRequestToUserMutation,
    SendSearchRequestToUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendSearchRequestToUserMutation,
    SendSearchRequestToUserMutationVariables
  >(SendSearchRequestToUserDocument, options);
}
export type SendSearchRequestToUserMutationHookResult = ReturnType<
  typeof useSendSearchRequestToUserMutation
>;
export type SendSearchRequestToUserMutationResult =
  Apollo.MutationResult<SendSearchRequestToUserMutation>;
export type SendSearchRequestToUserMutationOptions = Apollo.BaseMutationOptions<
  SendSearchRequestToUserMutation,
  SendSearchRequestToUserMutationVariables
>;
export const UpdateAdminResponsibleUserIdDocument = gql`
  mutation UpdateAdminResponsibleUserId($id: String!, $userId: String) {
    updateAdminResponsibleUser(id: $id, userId: $userId) {
      id
      adminResponsibleUser {
        id
        name
      }
    }
  }
`;
export type UpdateAdminResponsibleUserIdMutationFn = Apollo.MutationFunction<
  UpdateAdminResponsibleUserIdMutation,
  UpdateAdminResponsibleUserIdMutationVariables
>;

/**
 * __useUpdateAdminResponsibleUserIdMutation__
 *
 * To run a mutation, you first call `useUpdateAdminResponsibleUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminResponsibleUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminResponsibleUserIdMutation, { data, loading, error }] = useUpdateAdminResponsibleUserIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateAdminResponsibleUserIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAdminResponsibleUserIdMutation,
    UpdateAdminResponsibleUserIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAdminResponsibleUserIdMutation,
    UpdateAdminResponsibleUserIdMutationVariables
  >(UpdateAdminResponsibleUserIdDocument, options);
}
export type UpdateAdminResponsibleUserIdMutationHookResult = ReturnType<
  typeof useUpdateAdminResponsibleUserIdMutation
>;
export type UpdateAdminResponsibleUserIdMutationResult =
  Apollo.MutationResult<UpdateAdminResponsibleUserIdMutation>;
export type UpdateAdminResponsibleUserIdMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAdminResponsibleUserIdMutation,
    UpdateAdminResponsibleUserIdMutationVariables
  >;
export const UpdateAdminStatusDocument = gql`
  mutation UpdateAdminStatus(
    $id: String!
    $status: AdminSearchRequestReferenceStatus
  ) {
    updateAdminStatus(id: $id, status: $status) {
      id
      adminStatus
    }
  }
`;
export type UpdateAdminStatusMutationFn = Apollo.MutationFunction<
  UpdateAdminStatusMutation,
  UpdateAdminStatusMutationVariables
>;

/**
 * __useUpdateAdminStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAdminStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminStatusMutation, { data, loading, error }] = useUpdateAdminStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateAdminStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAdminStatusMutation,
    UpdateAdminStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAdminStatusMutation,
    UpdateAdminStatusMutationVariables
  >(UpdateAdminStatusDocument, options);
}
export type UpdateAdminStatusMutationHookResult = ReturnType<
  typeof useUpdateAdminStatusMutation
>;
export type UpdateAdminStatusMutationResult =
  Apollo.MutationResult<UpdateAdminStatusMutation>;
export type UpdateAdminStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdminStatusMutation,
  UpdateAdminStatusMutationVariables
>;
export const AnswerSearchRequestDocument = gql`
  mutation AnswerSearchRequest(
    $searchRequestReferenceId: String!
    $message: String!
    $status: StatusEnum!
    $leaseAgreementIds: [String!]
    $attachmentIds: [String!]
  ) {
    answerSearchRequest(
      message: $message
      searchRequestReferenceId: $searchRequestReferenceId
      status: $status
      leaseAgreementIds: $leaseAgreementIds
      attachmentIds: $attachmentIds
    ) {
      id
      status
      conversationId
    }
  }
`;
export type AnswerSearchRequestMutationFn = Apollo.MutationFunction<
  AnswerSearchRequestMutation,
  AnswerSearchRequestMutationVariables
>;

/**
 * __useAnswerSearchRequestMutation__
 *
 * To run a mutation, you first call `useAnswerSearchRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerSearchRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerSearchRequestMutation, { data, loading, error }] = useAnswerSearchRequestMutation({
 *   variables: {
 *      searchRequestReferenceId: // value for 'searchRequestReferenceId'
 *      message: // value for 'message'
 *      status: // value for 'status'
 *      leaseAgreementIds: // value for 'leaseAgreementIds'
 *      attachmentIds: // value for 'attachmentIds'
 *   },
 * });
 */
export function useAnswerSearchRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnswerSearchRequestMutation,
    AnswerSearchRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AnswerSearchRequestMutation,
    AnswerSearchRequestMutationVariables
  >(AnswerSearchRequestDocument, options);
}
export type AnswerSearchRequestMutationHookResult = ReturnType<
  typeof useAnswerSearchRequestMutation
>;
export type AnswerSearchRequestMutationResult =
  Apollo.MutationResult<AnswerSearchRequestMutation>;
export type AnswerSearchRequestMutationOptions = Apollo.BaseMutationOptions<
  AnswerSearchRequestMutation,
  AnswerSearchRequestMutationVariables
>;
export const GetSearchRequestLandlordDocument = gql`
  query GetSearchRequestLandlord($id: String!) {
    searchRequestReference(id: $id, triggerView: true) {
      ...SearchRequestReferenceInformationValues
      searchRequests(limit: 100, status: [accepted]) {
        ...AnsweredByValues
      }
      myStatus
    }
  }
  ${SearchRequestReferenceInformationValuesFragmentDoc}
  ${AnsweredByValuesFragmentDoc}
`;

/**
 * __useGetSearchRequestLandlordQuery__
 *
 * To run a query within a React component, call `useGetSearchRequestLandlordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchRequestLandlordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchRequestLandlordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSearchRequestLandlordQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSearchRequestLandlordQuery,
    GetSearchRequestLandlordQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSearchRequestLandlordQuery,
    GetSearchRequestLandlordQueryVariables
  >(GetSearchRequestLandlordDocument, options);
}
export function useGetSearchRequestLandlordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchRequestLandlordQuery,
    GetSearchRequestLandlordQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchRequestLandlordQuery,
    GetSearchRequestLandlordQueryVariables
  >(GetSearchRequestLandlordDocument, options);
}
export type GetSearchRequestLandlordQueryHookResult = ReturnType<
  typeof useGetSearchRequestLandlordQuery
>;
export type GetSearchRequestLandlordLazyQueryHookResult = ReturnType<
  typeof useGetSearchRequestLandlordLazyQuery
>;
export type GetSearchRequestLandlordQueryResult = Apollo.QueryResult<
  GetSearchRequestLandlordQuery,
  GetSearchRequestLandlordQueryVariables
>;
export const AddSearchRequestDocument = gql`
  mutation AddSearchRequest(
    $information: SearchRequestInformationInput!
    $area: SearchRequestAreaInput!
  ) {
    searchRequestReference(information: $information, area: $area) {
      searchRequestReference {
        id
      }
    }
  }
`;
export type AddSearchRequestMutationFn = Apollo.MutationFunction<
  AddSearchRequestMutation,
  AddSearchRequestMutationVariables
>;

/**
 * __useAddSearchRequestMutation__
 *
 * To run a mutation, you first call `useAddSearchRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSearchRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSearchRequestMutation, { data, loading, error }] = useAddSearchRequestMutation({
 *   variables: {
 *      information: // value for 'information'
 *      area: // value for 'area'
 *   },
 * });
 */
export function useAddSearchRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSearchRequestMutation,
    AddSearchRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSearchRequestMutation,
    AddSearchRequestMutationVariables
  >(AddSearchRequestDocument, options);
}
export type AddSearchRequestMutationHookResult = ReturnType<
  typeof useAddSearchRequestMutation
>;
export type AddSearchRequestMutationResult =
  Apollo.MutationResult<AddSearchRequestMutation>;
export type AddSearchRequestMutationOptions = Apollo.BaseMutationOptions<
  AddSearchRequestMutation,
  AddSearchRequestMutationVariables
>;
export const DeleteSearchRequestDocument = gql`
  mutation DeleteSearchRequest($id: String!) {
    deleteSearchRequestReference(id: $id) {
      id
    }
  }
`;
export type DeleteSearchRequestMutationFn = Apollo.MutationFunction<
  DeleteSearchRequestMutation,
  DeleteSearchRequestMutationVariables
>;

/**
 * __useDeleteSearchRequestMutation__
 *
 * To run a mutation, you first call `useDeleteSearchRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSearchRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSearchRequestMutation, { data, loading, error }] = useDeleteSearchRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSearchRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSearchRequestMutation,
    DeleteSearchRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSearchRequestMutation,
    DeleteSearchRequestMutationVariables
  >(DeleteSearchRequestDocument, options);
}
export type DeleteSearchRequestMutationHookResult = ReturnType<
  typeof useDeleteSearchRequestMutation
>;
export type DeleteSearchRequestMutationResult =
  Apollo.MutationResult<DeleteSearchRequestMutation>;
export type DeleteSearchRequestMutationOptions = Apollo.BaseMutationOptions<
  DeleteSearchRequestMutation,
  DeleteSearchRequestMutationVariables
>;
export const EditSearchRequestReferenceDocument = gql`
  mutation EditSearchRequestReference(
    $id: String!
    $information: SearchRequestInformationInput!
    $contactInformation: ContactInformationInput
  ) {
    editSearchRequestReference(
      id: $id
      information: $information
      contactInformation: $contactInformation
    ) {
      id
      ...SearchRequestReferencePreviewValues
    }
  }
  ${SearchRequestReferencePreviewValuesFragmentDoc}
`;
export type EditSearchRequestReferenceMutationFn = Apollo.MutationFunction<
  EditSearchRequestReferenceMutation,
  EditSearchRequestReferenceMutationVariables
>;

/**
 * __useEditSearchRequestReferenceMutation__
 *
 * To run a mutation, you first call `useEditSearchRequestReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSearchRequestReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSearchRequestReferenceMutation, { data, loading, error }] = useEditSearchRequestReferenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      information: // value for 'information'
 *      contactInformation: // value for 'contactInformation'
 *   },
 * });
 */
export function useEditSearchRequestReferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditSearchRequestReferenceMutation,
    EditSearchRequestReferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditSearchRequestReferenceMutation,
    EditSearchRequestReferenceMutationVariables
  >(EditSearchRequestReferenceDocument, options);
}
export type EditSearchRequestReferenceMutationHookResult = ReturnType<
  typeof useEditSearchRequestReferenceMutation
>;
export type EditSearchRequestReferenceMutationResult =
  Apollo.MutationResult<EditSearchRequestReferenceMutation>;
export type EditSearchRequestReferenceMutationOptions =
  Apollo.BaseMutationOptions<
    EditSearchRequestReferenceMutation,
    EditSearchRequestReferenceMutationVariables
  >;
export const GetBoundsAndPolygonsDocument = gql`
  query GetBoundsAndPolygons(
    $search: SearchProfileInput
    $bounds: Bounds
    $zoom: Int
  ) {
    leaseAgreements(
      search: $search
      input: { onlyAvailable: true }
      zoom: $zoom
      bounds: $bounds
    ) {
      count
      regionBounds {
        south
        north
        east
        west
      }
      regionPolygons
    }
  }
`;

/**
 * __useGetBoundsAndPolygonsQuery__
 *
 * To run a query within a React component, call `useGetBoundsAndPolygonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBoundsAndPolygonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBoundsAndPolygonsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      bounds: // value for 'bounds'
 *      zoom: // value for 'zoom'
 *   },
 * });
 */
export function useGetBoundsAndPolygonsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBoundsAndPolygonsQuery,
    GetBoundsAndPolygonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBoundsAndPolygonsQuery,
    GetBoundsAndPolygonsQueryVariables
  >(GetBoundsAndPolygonsDocument, options);
}
export function useGetBoundsAndPolygonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBoundsAndPolygonsQuery,
    GetBoundsAndPolygonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBoundsAndPolygonsQuery,
    GetBoundsAndPolygonsQueryVariables
  >(GetBoundsAndPolygonsDocument, options);
}
export type GetBoundsAndPolygonsQueryHookResult = ReturnType<
  typeof useGetBoundsAndPolygonsQuery
>;
export type GetBoundsAndPolygonsLazyQueryHookResult = ReturnType<
  typeof useGetBoundsAndPolygonsLazyQuery
>;
export type GetBoundsAndPolygonsQueryResult = Apollo.QueryResult<
  GetBoundsAndPolygonsQuery,
  GetBoundsAndPolygonsQueryVariables
>;
export const GetMyLeaseAgreementsDocument = gql`
  query GetMyLeaseAgreements(
    $input: LeaseAgreementFilter
    $search: SearchProfileInput
    $zoom: Int!
    $limit: Int!
    $offset: Int!
    $bounds: Bounds
  ) {
    leaseAgreements(
      search: $search
      input: $input
      zoom: $zoom
      bounds: $bounds
      limit: $limit
    ) {
      count
      items(limit: $limit, offset: $offset) {
        ...LeaseAgreementFields
      }
    }
  }
  ${LeaseAgreementFieldsFragmentDoc}
`;

/**
 * __useGetMyLeaseAgreementsQuery__
 *
 * To run a query within a React component, call `useGetMyLeaseAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyLeaseAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyLeaseAgreementsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      search: // value for 'search'
 *      zoom: // value for 'zoom'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      bounds: // value for 'bounds'
 *   },
 * });
 */
export function useGetMyLeaseAgreementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyLeaseAgreementsQuery,
    GetMyLeaseAgreementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyLeaseAgreementsQuery,
    GetMyLeaseAgreementsQueryVariables
  >(GetMyLeaseAgreementsDocument, options);
}
export function useGetMyLeaseAgreementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyLeaseAgreementsQuery,
    GetMyLeaseAgreementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyLeaseAgreementsQuery,
    GetMyLeaseAgreementsQueryVariables
  >(GetMyLeaseAgreementsDocument, options);
}
export type GetMyLeaseAgreementsQueryHookResult = ReturnType<
  typeof useGetMyLeaseAgreementsQuery
>;
export type GetMyLeaseAgreementsLazyQueryHookResult = ReturnType<
  typeof useGetMyLeaseAgreementsLazyQuery
>;
export type GetMyLeaseAgreementsQueryResult = Apollo.QueryResult<
  GetMyLeaseAgreementsQuery,
  GetMyLeaseAgreementsQueryVariables
>;
export const AddPresetAnswerDocument = gql`
  mutation AddPresetAnswer($name: String!, $message: String!) {
    addPresetAnswer(name: $name, message: $message) {
      id
      message
      name
    }
  }
`;
export type AddPresetAnswerMutationFn = Apollo.MutationFunction<
  AddPresetAnswerMutation,
  AddPresetAnswerMutationVariables
>;

/**
 * __useAddPresetAnswerMutation__
 *
 * To run a mutation, you first call `useAddPresetAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPresetAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPresetAnswerMutation, { data, loading, error }] = useAddPresetAnswerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useAddPresetAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPresetAnswerMutation,
    AddPresetAnswerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPresetAnswerMutation,
    AddPresetAnswerMutationVariables
  >(AddPresetAnswerDocument, options);
}
export type AddPresetAnswerMutationHookResult = ReturnType<
  typeof useAddPresetAnswerMutation
>;
export type AddPresetAnswerMutationResult =
  Apollo.MutationResult<AddPresetAnswerMutation>;
export type AddPresetAnswerMutationOptions = Apollo.BaseMutationOptions<
  AddPresetAnswerMutation,
  AddPresetAnswerMutationVariables
>;
export const DeletePresetAnswerDocument = gql`
  mutation DeletePresetAnswer($id: String!) {
    deletePresetAnswer(id: $id)
  }
`;
export type DeletePresetAnswerMutationFn = Apollo.MutationFunction<
  DeletePresetAnswerMutation,
  DeletePresetAnswerMutationVariables
>;

/**
 * __useDeletePresetAnswerMutation__
 *
 * To run a mutation, you first call `useDeletePresetAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePresetAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePresetAnswerMutation, { data, loading, error }] = useDeletePresetAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePresetAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePresetAnswerMutation,
    DeletePresetAnswerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePresetAnswerMutation,
    DeletePresetAnswerMutationVariables
  >(DeletePresetAnswerDocument, options);
}
export type DeletePresetAnswerMutationHookResult = ReturnType<
  typeof useDeletePresetAnswerMutation
>;
export type DeletePresetAnswerMutationResult =
  Apollo.MutationResult<DeletePresetAnswerMutation>;
export type DeletePresetAnswerMutationOptions = Apollo.BaseMutationOptions<
  DeletePresetAnswerMutation,
  DeletePresetAnswerMutationVariables
>;
export const GetMyPresetAnswersDocument = gql`
  query GetMyPresetAnswers {
    myPresetAnswers {
      id
      message
      name
    }
  }
`;

/**
 * __useGetMyPresetAnswersQuery__
 *
 * To run a query within a React component, call `useGetMyPresetAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPresetAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPresetAnswersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyPresetAnswersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyPresetAnswersQuery,
    GetMyPresetAnswersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyPresetAnswersQuery,
    GetMyPresetAnswersQueryVariables
  >(GetMyPresetAnswersDocument, options);
}
export function useGetMyPresetAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyPresetAnswersQuery,
    GetMyPresetAnswersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyPresetAnswersQuery,
    GetMyPresetAnswersQueryVariables
  >(GetMyPresetAnswersDocument, options);
}
export type GetMyPresetAnswersQueryHookResult = ReturnType<
  typeof useGetMyPresetAnswersQuery
>;
export type GetMyPresetAnswersLazyQueryHookResult = ReturnType<
  typeof useGetMyPresetAnswersLazyQuery
>;
export type GetMyPresetAnswersQueryResult = Apollo.QueryResult<
  GetMyPresetAnswersQuery,
  GetMyPresetAnswersQueryVariables
>;
export const DisableSearchRequestDocument = gql`
  mutation DisableSearchRequest(
    $searchRequestReferenceId: String!
    $reason: DisableSearchRequestInput!
    $token: String
  ) {
    disableSearchRequest(
      searchRequestReferenceId: $searchRequestReferenceId
      reason: $reason
      token: $token
    )
  }
`;
export type DisableSearchRequestMutationFn = Apollo.MutationFunction<
  DisableSearchRequestMutation,
  DisableSearchRequestMutationVariables
>;

/**
 * __useDisableSearchRequestMutation__
 *
 * To run a mutation, you first call `useDisableSearchRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableSearchRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableSearchRequestMutation, { data, loading, error }] = useDisableSearchRequestMutation({
 *   variables: {
 *      searchRequestReferenceId: // value for 'searchRequestReferenceId'
 *      reason: // value for 'reason'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useDisableSearchRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableSearchRequestMutation,
    DisableSearchRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisableSearchRequestMutation,
    DisableSearchRequestMutationVariables
  >(DisableSearchRequestDocument, options);
}
export type DisableSearchRequestMutationHookResult = ReturnType<
  typeof useDisableSearchRequestMutation
>;
export type DisableSearchRequestMutationResult =
  Apollo.MutationResult<DisableSearchRequestMutation>;
export type DisableSearchRequestMutationOptions = Apollo.BaseMutationOptions<
  DisableSearchRequestMutation,
  DisableSearchRequestMutationVariables
>;
export const GetPotentialClientsDocument = gql`
  query GetPotentialClients(
    $clientId: String!
    $searchRequestReferenceId: String
    $token: String
  ) {
    potentialClients(
      clientId: $clientId
      searchRequestReferenceId: $searchRequestReferenceId
      token: $token
    ) {
      count
      clients {
        id
        name
      }
    }
  }
`;

/**
 * __useGetPotentialClientsQuery__
 *
 * To run a query within a React component, call `useGetPotentialClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPotentialClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPotentialClientsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      searchRequestReferenceId: // value for 'searchRequestReferenceId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetPotentialClientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPotentialClientsQuery,
    GetPotentialClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPotentialClientsQuery,
    GetPotentialClientsQueryVariables
  >(GetPotentialClientsDocument, options);
}
export function useGetPotentialClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPotentialClientsQuery,
    GetPotentialClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPotentialClientsQuery,
    GetPotentialClientsQueryVariables
  >(GetPotentialClientsDocument, options);
}
export type GetPotentialClientsQueryHookResult = ReturnType<
  typeof useGetPotentialClientsQuery
>;
export type GetPotentialClientsLazyQueryHookResult = ReturnType<
  typeof useGetPotentialClientsLazyQuery
>;
export type GetPotentialClientsQueryResult = Apollo.QueryResult<
  GetPotentialClientsQuery,
  GetPotentialClientsQueryVariables
>;
export const AddSearchRequestCommentDocument = gql`
  mutation AddSearchRequestComment(
    $id: String!
    $input: SearchRequestCommentInput!
  ) {
    addSearchRequestComment(id: $id, input: $input) {
      ...SearchRequestCommentValues
    }
  }
  ${SearchRequestCommentValuesFragmentDoc}
`;
export type AddSearchRequestCommentMutationFn = Apollo.MutationFunction<
  AddSearchRequestCommentMutation,
  AddSearchRequestCommentMutationVariables
>;

/**
 * __useAddSearchRequestCommentMutation__
 *
 * To run a mutation, you first call `useAddSearchRequestCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSearchRequestCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSearchRequestCommentMutation, { data, loading, error }] = useAddSearchRequestCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSearchRequestCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSearchRequestCommentMutation,
    AddSearchRequestCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSearchRequestCommentMutation,
    AddSearchRequestCommentMutationVariables
  >(AddSearchRequestCommentDocument, options);
}
export type AddSearchRequestCommentMutationHookResult = ReturnType<
  typeof useAddSearchRequestCommentMutation
>;
export type AddSearchRequestCommentMutationResult =
  Apollo.MutationResult<AddSearchRequestCommentMutation>;
export type AddSearchRequestCommentMutationOptions = Apollo.BaseMutationOptions<
  AddSearchRequestCommentMutation,
  AddSearchRequestCommentMutationVariables
>;
export const DeleteSearchRequestCommentDocument = gql`
  mutation DeleteSearchRequestComment($id: String!) {
    deleteSearchRequestComment(id: $id) {
      ...SearchRequestCommentValues
    }
  }
  ${SearchRequestCommentValuesFragmentDoc}
`;
export type DeleteSearchRequestCommentMutationFn = Apollo.MutationFunction<
  DeleteSearchRequestCommentMutation,
  DeleteSearchRequestCommentMutationVariables
>;

/**
 * __useDeleteSearchRequestCommentMutation__
 *
 * To run a mutation, you first call `useDeleteSearchRequestCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSearchRequestCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSearchRequestCommentMutation, { data, loading, error }] = useDeleteSearchRequestCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSearchRequestCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSearchRequestCommentMutation,
    DeleteSearchRequestCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSearchRequestCommentMutation,
    DeleteSearchRequestCommentMutationVariables
  >(DeleteSearchRequestCommentDocument, options);
}
export type DeleteSearchRequestCommentMutationHookResult = ReturnType<
  typeof useDeleteSearchRequestCommentMutation
>;
export type DeleteSearchRequestCommentMutationResult =
  Apollo.MutationResult<DeleteSearchRequestCommentMutation>;
export type DeleteSearchRequestCommentMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteSearchRequestCommentMutation,
    DeleteSearchRequestCommentMutationVariables
  >;
export const GetSearchRequestCommentsDocument = gql`
  query GetSearchRequestComments($id: String!) {
    searchRequestComments(id: $id) {
      ...SearchRequestCommentValues
    }
  }
  ${SearchRequestCommentValuesFragmentDoc}
`;

/**
 * __useGetSearchRequestCommentsQuery__
 *
 * To run a query within a React component, call `useGetSearchRequestCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchRequestCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchRequestCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSearchRequestCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSearchRequestCommentsQuery,
    GetSearchRequestCommentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSearchRequestCommentsQuery,
    GetSearchRequestCommentsQueryVariables
  >(GetSearchRequestCommentsDocument, options);
}
export function useGetSearchRequestCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchRequestCommentsQuery,
    GetSearchRequestCommentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchRequestCommentsQuery,
    GetSearchRequestCommentsQueryVariables
  >(GetSearchRequestCommentsDocument, options);
}
export type GetSearchRequestCommentsQueryHookResult = ReturnType<
  typeof useGetSearchRequestCommentsQuery
>;
export type GetSearchRequestCommentsLazyQueryHookResult = ReturnType<
  typeof useGetSearchRequestCommentsLazyQuery
>;
export type GetSearchRequestCommentsQueryResult = Apollo.QueryResult<
  GetSearchRequestCommentsQuery,
  GetSearchRequestCommentsQueryVariables
>;
export const GetFilterCountiesDocument = gql`
  query GetFilterCounties {
    getRegions(filter: { regionType: county }) {
      regions {
        id
        name
        geometry
        children {
          id
          regionType
          name
          geometry
        }
      }
      count
    }
  }
`;

/**
 * __useGetFilterCountiesQuery__
 *
 * To run a query within a React component, call `useGetFilterCountiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterCountiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterCountiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFilterCountiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFilterCountiesQuery,
    GetFilterCountiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFilterCountiesQuery,
    GetFilterCountiesQueryVariables
  >(GetFilterCountiesDocument, options);
}
export function useGetFilterCountiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFilterCountiesQuery,
    GetFilterCountiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFilterCountiesQuery,
    GetFilterCountiesQueryVariables
  >(GetFilterCountiesDocument, options);
}
export type GetFilterCountiesQueryHookResult = ReturnType<
  typeof useGetFilterCountiesQuery
>;
export type GetFilterCountiesLazyQueryHookResult = ReturnType<
  typeof useGetFilterCountiesLazyQuery
>;
export type GetFilterCountiesQueryResult = Apollo.QueryResult<
  GetFilterCountiesQuery,
  GetFilterCountiesQueryVariables
>;
export const GetSearchRequestInformationDocument = gql`
  query GetSearchRequestInformation($id: String!) {
    searchRequestReference(id: $id) {
      ...SearchRequestReferenceInformationValues
    }
  }
  ${SearchRequestReferenceInformationValuesFragmentDoc}
`;

/**
 * __useGetSearchRequestInformationQuery__
 *
 * To run a query within a React component, call `useGetSearchRequestInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchRequestInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchRequestInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSearchRequestInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSearchRequestInformationQuery,
    GetSearchRequestInformationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSearchRequestInformationQuery,
    GetSearchRequestInformationQueryVariables
  >(GetSearchRequestInformationDocument, options);
}
export function useGetSearchRequestInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchRequestInformationQuery,
    GetSearchRequestInformationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchRequestInformationQuery,
    GetSearchRequestInformationQueryVariables
  >(GetSearchRequestInformationDocument, options);
}
export type GetSearchRequestInformationQueryHookResult = ReturnType<
  typeof useGetSearchRequestInformationQuery
>;
export type GetSearchRequestInformationLazyQueryHookResult = ReturnType<
  typeof useGetSearchRequestInformationLazyQuery
>;
export type GetSearchRequestInformationQueryResult = Apollo.QueryResult<
  GetSearchRequestInformationQuery,
  GetSearchRequestInformationQueryVariables
>;
export const GetCountiesDocument = gql`
  query GetCounties {
    getRegions(filter: { regionType: county }) {
      regions {
        id
        name
        geometry
      }
      count
    }
  }
`;

/**
 * __useGetCountiesQuery__
 *
 * To run a query within a React component, call `useGetCountiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCountiesQuery,
    GetCountiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCountiesQuery, GetCountiesQueryVariables>(
    GetCountiesDocument,
    options,
  );
}
export function useGetCountiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountiesQuery,
    GetCountiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCountiesQuery, GetCountiesQueryVariables>(
    GetCountiesDocument,
    options,
  );
}
export type GetCountiesQueryHookResult = ReturnType<typeof useGetCountiesQuery>;
export type GetCountiesLazyQueryHookResult = ReturnType<
  typeof useGetCountiesLazyQuery
>;
export type GetCountiesQueryResult = Apollo.QueryResult<
  GetCountiesQuery,
  GetCountiesQueryVariables
>;
export const GetSearchRequestStatsDocument = gql`
  query GetSearchRequestStats(
    $usageCategories: [String!]!
    $regionIds: [String!]!
    $size: [SizeFilter!]!
  ) {
    mySearchRequestsLandlord(
      usageCategories: $usageCategories
      regionIds: $regionIds
      size: $size
    ) {
      accepted {
        count
      }
      declined {
        count
      }
      unanswered {
        count
      }
    }
  }
`;

/**
 * __useGetSearchRequestStatsQuery__
 *
 * To run a query within a React component, call `useGetSearchRequestStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchRequestStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchRequestStatsQuery({
 *   variables: {
 *      usageCategories: // value for 'usageCategories'
 *      regionIds: // value for 'regionIds'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useGetSearchRequestStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSearchRequestStatsQuery,
    GetSearchRequestStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSearchRequestStatsQuery,
    GetSearchRequestStatsQueryVariables
  >(GetSearchRequestStatsDocument, options);
}
export function useGetSearchRequestStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchRequestStatsQuery,
    GetSearchRequestStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchRequestStatsQuery,
    GetSearchRequestStatsQueryVariables
  >(GetSearchRequestStatsDocument, options);
}
export type GetSearchRequestStatsQueryHookResult = ReturnType<
  typeof useGetSearchRequestStatsQuery
>;
export type GetSearchRequestStatsLazyQueryHookResult = ReturnType<
  typeof useGetSearchRequestStatsLazyQuery
>;
export type GetSearchRequestStatsQueryResult = Apollo.QueryResult<
  GetSearchRequestStatsQuery,
  GetSearchRequestStatsQueryVariables
>;
export const GetConsultantsDocument = gql`
  query GetConsultants(
    $roles: [String!]
    $isPortally: Boolean
    $isSalesTeam: Boolean
  ) {
    users(
      filter: {
        roles: $roles
        isPortally: $isPortally
        isSalesTeam: $isSalesTeam
        limit: 100
      }
      order: "ascend"
      sort: "firstName"
    ) {
      users {
        id
        name
      }
    }
  }
`;

/**
 * __useGetConsultantsQuery__
 *
 * To run a query within a React component, call `useGetConsultantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsultantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsultantsQuery({
 *   variables: {
 *      roles: // value for 'roles'
 *      isPortally: // value for 'isPortally'
 *      isSalesTeam: // value for 'isSalesTeam'
 *   },
 * });
 */
export function useGetConsultantsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetConsultantsQuery,
    GetConsultantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConsultantsQuery, GetConsultantsQueryVariables>(
    GetConsultantsDocument,
    options,
  );
}
export function useGetConsultantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConsultantsQuery,
    GetConsultantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetConsultantsQuery, GetConsultantsQueryVariables>(
    GetConsultantsDocument,
    options,
  );
}
export type GetConsultantsQueryHookResult = ReturnType<
  typeof useGetConsultantsQuery
>;
export type GetConsultantsLazyQueryHookResult = ReturnType<
  typeof useGetConsultantsLazyQuery
>;
export type GetConsultantsQueryResult = Apollo.QueryResult<
  GetConsultantsQuery,
  GetConsultantsQueryVariables
>;
export const GetUsersByClientDocument = gql`
  query getUsersByClient($clientId: String) {
    users(filter: { clientId: $clientId, limit: 100 }) {
      users {
        id
        name
      }
    }
  }
`;

/**
 * __useGetUsersByClientQuery__
 *
 * To run a query within a React component, call `useGetUsersByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByClientQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetUsersByClientQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUsersByClientQuery,
    GetUsersByClientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersByClientQuery, GetUsersByClientQueryVariables>(
    GetUsersByClientDocument,
    options,
  );
}
export function useGetUsersByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersByClientQuery,
    GetUsersByClientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersByClientQuery,
    GetUsersByClientQueryVariables
  >(GetUsersByClientDocument, options);
}
export type GetUsersByClientQueryHookResult = ReturnType<
  typeof useGetUsersByClientQuery
>;
export type GetUsersByClientLazyQueryHookResult = ReturnType<
  typeof useGetUsersByClientLazyQuery
>;
export type GetUsersByClientQueryResult = Apollo.QueryResult<
  GetUsersByClientQuery,
  GetUsersByClientQueryVariables
>;
export const GetMySelectLeaseAgreementsDocument = gql`
  query GetMySelectLeaseAgreements(
    $input: LeaseAgreementFilter
    $zoom: Int!
    $limit: Int!
    $offset: Int!
    $bounds: Bounds
  ) {
    leaseAgreements(
      input: $input
      zoom: $zoom
      bounds: $bounds
      limit: $limit
    ) {
      count
      items(limit: $limit, offset: $offset) {
        ...LeaseAgreementListPreviewValues
      }
    }
  }
  ${LeaseAgreementListPreviewValuesFragmentDoc}
`;

/**
 * __useGetMySelectLeaseAgreementsQuery__
 *
 * To run a query within a React component, call `useGetMySelectLeaseAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySelectLeaseAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySelectLeaseAgreementsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      zoom: // value for 'zoom'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      bounds: // value for 'bounds'
 *   },
 * });
 */
export function useGetMySelectLeaseAgreementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMySelectLeaseAgreementsQuery,
    GetMySelectLeaseAgreementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMySelectLeaseAgreementsQuery,
    GetMySelectLeaseAgreementsQueryVariables
  >(GetMySelectLeaseAgreementsDocument, options);
}
export function useGetMySelectLeaseAgreementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMySelectLeaseAgreementsQuery,
    GetMySelectLeaseAgreementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMySelectLeaseAgreementsQuery,
    GetMySelectLeaseAgreementsQueryVariables
  >(GetMySelectLeaseAgreementsDocument, options);
}
export type GetMySelectLeaseAgreementsQueryHookResult = ReturnType<
  typeof useGetMySelectLeaseAgreementsQuery
>;
export type GetMySelectLeaseAgreementsLazyQueryHookResult = ReturnType<
  typeof useGetMySelectLeaseAgreementsLazyQuery
>;
export type GetMySelectLeaseAgreementsQueryResult = Apollo.QueryResult<
  GetMySelectLeaseAgreementsQuery,
  GetMySelectLeaseAgreementsQueryVariables
>;
export const StreetAutocompleteDocument = gql`
  query StreetAutocomplete($key: String!, $clientId: String) {
    streets(key: $key, clientId: $clientId) {
      id
      value
      type
    }
  }
`;

/**
 * __useStreetAutocompleteQuery__
 *
 * To run a query within a React component, call `useStreetAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useStreetAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreetAutocompleteQuery({
 *   variables: {
 *      key: // value for 'key'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useStreetAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    StreetAutocompleteQuery,
    StreetAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StreetAutocompleteQuery,
    StreetAutocompleteQueryVariables
  >(StreetAutocompleteDocument, options);
}
export function useStreetAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StreetAutocompleteQuery,
    StreetAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StreetAutocompleteQuery,
    StreetAutocompleteQueryVariables
  >(StreetAutocompleteDocument, options);
}
export type StreetAutocompleteQueryHookResult = ReturnType<
  typeof useStreetAutocompleteQuery
>;
export type StreetAutocompleteLazyQueryHookResult = ReturnType<
  typeof useStreetAutocompleteLazyQuery
>;
export type StreetAutocompleteQueryResult = Apollo.QueryResult<
  StreetAutocompleteQuery,
  StreetAutocompleteQueryVariables
>;
export const ToggleShowMineDocument = gql`
  mutation ToggleShowMine($id: String!, $showMine: Boolean!) {
    editUser(id: $id, input: { showMine: $showMine }) {
      ...UserValues
    }
  }
  ${UserValuesFragmentDoc}
`;
export type ToggleShowMineMutationFn = Apollo.MutationFunction<
  ToggleShowMineMutation,
  ToggleShowMineMutationVariables
>;

/**
 * __useToggleShowMineMutation__
 *
 * To run a mutation, you first call `useToggleShowMineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleShowMineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleShowMineMutation, { data, loading, error }] = useToggleShowMineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      showMine: // value for 'showMine'
 *   },
 * });
 */
export function useToggleShowMineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleShowMineMutation,
    ToggleShowMineMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleShowMineMutation,
    ToggleShowMineMutationVariables
  >(ToggleShowMineDocument, options);
}
export type ToggleShowMineMutationHookResult = ReturnType<
  typeof useToggleShowMineMutation
>;
export type ToggleShowMineMutationResult =
  Apollo.MutationResult<ToggleShowMineMutation>;
export type ToggleShowMineMutationOptions = Apollo.BaseMutationOptions<
  ToggleShowMineMutation,
  ToggleShowMineMutationVariables
>;
export const AcceptSubscriptionRequestDocument = gql`
  mutation AcceptSubscriptionRequest($id: String!, $type: SubscriptionType) {
    acceptSubscriptionRequest(id: $id, type: $type) {
      id
      status
      readByUserIds
    }
  }
`;
export type AcceptSubscriptionRequestMutationFn = Apollo.MutationFunction<
  AcceptSubscriptionRequestMutation,
  AcceptSubscriptionRequestMutationVariables
>;

/**
 * __useAcceptSubscriptionRequestMutation__
 *
 * To run a mutation, you first call `useAcceptSubscriptionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptSubscriptionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptSubscriptionRequestMutation, { data, loading, error }] = useAcceptSubscriptionRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAcceptSubscriptionRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptSubscriptionRequestMutation,
    AcceptSubscriptionRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptSubscriptionRequestMutation,
    AcceptSubscriptionRequestMutationVariables
  >(AcceptSubscriptionRequestDocument, options);
}
export type AcceptSubscriptionRequestMutationHookResult = ReturnType<
  typeof useAcceptSubscriptionRequestMutation
>;
export type AcceptSubscriptionRequestMutationResult =
  Apollo.MutationResult<AcceptSubscriptionRequestMutation>;
export type AcceptSubscriptionRequestMutationOptions =
  Apollo.BaseMutationOptions<
    AcceptSubscriptionRequestMutation,
    AcceptSubscriptionRequestMutationVariables
  >;
export const GetMySubscriptionRequestDocument = gql`
  query GetMySubscriptionRequest {
    mySubscriptionRequest {
      id
      status
      type
      readByUserIds
      openedByUserIds
      acceptedByUser {
        id
        name
      }
      acceptedDate
      version
    }
  }
`;

/**
 * __useGetMySubscriptionRequestQuery__
 *
 * To run a query within a React component, call `useGetMySubscriptionRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySubscriptionRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySubscriptionRequestQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMySubscriptionRequestQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMySubscriptionRequestQuery,
    GetMySubscriptionRequestQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMySubscriptionRequestQuery,
    GetMySubscriptionRequestQueryVariables
  >(GetMySubscriptionRequestDocument, options);
}
export function useGetMySubscriptionRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMySubscriptionRequestQuery,
    GetMySubscriptionRequestQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMySubscriptionRequestQuery,
    GetMySubscriptionRequestQueryVariables
  >(GetMySubscriptionRequestDocument, options);
}
export type GetMySubscriptionRequestQueryHookResult = ReturnType<
  typeof useGetMySubscriptionRequestQuery
>;
export type GetMySubscriptionRequestLazyQueryHookResult = ReturnType<
  typeof useGetMySubscriptionRequestLazyQuery
>;
export type GetMySubscriptionRequestQueryResult = Apollo.QueryResult<
  GetMySubscriptionRequestQuery,
  GetMySubscriptionRequestQueryVariables
>;
export const ViewSubscriptionRequestDocument = gql`
  mutation ViewSubscriptionRequest($id: String!) {
    viewSubscriptionRequest(id: $id) {
      id
      status
    }
  }
`;
export type ViewSubscriptionRequestMutationFn = Apollo.MutationFunction<
  ViewSubscriptionRequestMutation,
  ViewSubscriptionRequestMutationVariables
>;

/**
 * __useViewSubscriptionRequestMutation__
 *
 * To run a mutation, you first call `useViewSubscriptionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewSubscriptionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewSubscriptionRequestMutation, { data, loading, error }] = useViewSubscriptionRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewSubscriptionRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ViewSubscriptionRequestMutation,
    ViewSubscriptionRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ViewSubscriptionRequestMutation,
    ViewSubscriptionRequestMutationVariables
  >(ViewSubscriptionRequestDocument, options);
}
export type ViewSubscriptionRequestMutationHookResult = ReturnType<
  typeof useViewSubscriptionRequestMutation
>;
export type ViewSubscriptionRequestMutationResult =
  Apollo.MutationResult<ViewSubscriptionRequestMutation>;
export type ViewSubscriptionRequestMutationOptions = Apollo.BaseMutationOptions<
  ViewSubscriptionRequestMutation,
  ViewSubscriptionRequestMutationVariables
>;
export const AcceptTermsDocument = gql`
  mutation AcceptTerms {
    acceptTermsOfService
  }
`;
export type AcceptTermsMutationFn = Apollo.MutationFunction<
  AcceptTermsMutation,
  AcceptTermsMutationVariables
>;

/**
 * __useAcceptTermsMutation__
 *
 * To run a mutation, you first call `useAcceptTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTermsMutation, { data, loading, error }] = useAcceptTermsMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptTermsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptTermsMutation,
    AcceptTermsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptTermsMutation, AcceptTermsMutationVariables>(
    AcceptTermsDocument,
    options,
  );
}
export type AcceptTermsMutationHookResult = ReturnType<
  typeof useAcceptTermsMutation
>;
export type AcceptTermsMutationResult =
  Apollo.MutationResult<AcceptTermsMutation>;
export type AcceptTermsMutationOptions = Apollo.BaseMutationOptions<
  AcceptTermsMutation,
  AcceptTermsMutationVariables
>;
export const GetCurrentTermsOfServiceDocument = gql`
  query GetCurrentTermsOfService {
    getCurrentTermsOfService {
      ...TermsOfServiceValues
    }
  }
  ${TermsOfServiceValuesFragmentDoc}
`;

/**
 * __useGetCurrentTermsOfServiceQuery__
 *
 * To run a query within a React component, call `useGetCurrentTermsOfServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentTermsOfServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentTermsOfServiceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentTermsOfServiceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentTermsOfServiceQuery,
    GetCurrentTermsOfServiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentTermsOfServiceQuery,
    GetCurrentTermsOfServiceQueryVariables
  >(GetCurrentTermsOfServiceDocument, options);
}
export function useGetCurrentTermsOfServiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentTermsOfServiceQuery,
    GetCurrentTermsOfServiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentTermsOfServiceQuery,
    GetCurrentTermsOfServiceQueryVariables
  >(GetCurrentTermsOfServiceDocument, options);
}
export type GetCurrentTermsOfServiceQueryHookResult = ReturnType<
  typeof useGetCurrentTermsOfServiceQuery
>;
export type GetCurrentTermsOfServiceLazyQueryHookResult = ReturnType<
  typeof useGetCurrentTermsOfServiceLazyQuery
>;
export type GetCurrentTermsOfServiceQueryResult = Apollo.QueryResult<
  GetCurrentTermsOfServiceQuery,
  GetCurrentTermsOfServiceQueryVariables
>;
export const CompleteTourDocument = gql`
  mutation CompleteTour($tour: String!) {
    completeTour(tour: $tour) {
      id
      completedTours
    }
  }
`;
export type CompleteTourMutationFn = Apollo.MutationFunction<
  CompleteTourMutation,
  CompleteTourMutationVariables
>;

/**
 * __useCompleteTourMutation__
 *
 * To run a mutation, you first call `useCompleteTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTourMutation, { data, loading, error }] = useCompleteTourMutation({
 *   variables: {
 *      tour: // value for 'tour'
 *   },
 * });
 */
export function useCompleteTourMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteTourMutation,
    CompleteTourMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteTourMutation,
    CompleteTourMutationVariables
  >(CompleteTourDocument, options);
}
export type CompleteTourMutationHookResult = ReturnType<
  typeof useCompleteTourMutation
>;
export type CompleteTourMutationResult =
  Apollo.MutationResult<CompleteTourMutation>;
export type CompleteTourMutationOptions = Apollo.BaseMutationOptions<
  CompleteTourMutation,
  CompleteTourMutationVariables
>;
export const UploadFileDocument = gql`
  mutation UploadFile($file: AddFileInput!) {
    file(input: $file) {
      id
      name
      path
      url
      size
    }
  }
`;
export type UploadFileMutationFn = Apollo.MutationFunction<
  UploadFileMutation,
  UploadFileMutationVariables
>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadFileMutation,
    UploadFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(
    UploadFileDocument,
    options,
  );
}
export type UploadFileMutationHookResult = ReturnType<
  typeof useUploadFileMutation
>;
export type UploadFileMutationResult =
  Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<
  UploadFileMutation,
  UploadFileMutationVariables
>;
export const VerifyAccountDocument = gql`
  mutation VerifyAccount($email: String!, $company: String!) {
    verifyAccount(email: $email, company: $company) {
      id
      name
      email
      activeClient {
        id
        name
      }
      verified
    }
  }
`;
export type VerifyAccountMutationFn = Apollo.MutationFunction<
  VerifyAccountMutation,
  VerifyAccountMutationVariables
>;

/**
 * __useVerifyAccountMutation__
 *
 * To run a mutation, you first call `useVerifyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAccountMutation, { data, loading, error }] = useVerifyAccountMutation({
 *   variables: {
 *      email: // value for 'email'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useVerifyAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyAccountMutation,
    VerifyAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyAccountMutation,
    VerifyAccountMutationVariables
  >(VerifyAccountDocument, options);
}
export type VerifyAccountMutationHookResult = ReturnType<
  typeof useVerifyAccountMutation
>;
export type VerifyAccountMutationResult =
  Apollo.MutationResult<VerifyAccountMutation>;
export type VerifyAccountMutationOptions = Apollo.BaseMutationOptions<
  VerifyAccountMutation,
  VerifyAccountMutationVariables
>;
export const GetLoggedInUserDocument = gql`
  query GetLoggedInUser {
    me {
      id
      firstName
      lastName
      name
      email
      isPartner
      activeClient {
        id
        hasCommission
        name
        type
        termsOfService {
          confirmed
          confirmedDate
        }
      }
      activeClientId
      clientIds
      clients {
        id
        name
      }
      image {
        id
        url(processing: { crop: fill, width: 400, height: 400, gravity: face })
      }
      role
      city
      estates {
        id
        name
      }
      showMine
      trial {
        startDate
        endDate
      }
      fromWebsite
      interestingPopconfirm
      completedTours
      searchRequestSettings {
        regionIds
        usageCategories
        filterEmails
        size
      }
      activeSubscription {
        id
        version
        type
      }
      hasDealRequest
      onBoarded
      activeModal
      verified
    }
  }
`;

/**
 * __useGetLoggedInUserQuery__
 *
 * To run a query within a React component, call `useGetLoggedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoggedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoggedInUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoggedInUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLoggedInUserQuery,
    GetLoggedInUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(
    GetLoggedInUserDocument,
    options,
  );
}
export function useGetLoggedInUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoggedInUserQuery,
    GetLoggedInUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLoggedInUserQuery,
    GetLoggedInUserQueryVariables
  >(GetLoggedInUserDocument, options);
}
export type GetLoggedInUserQueryHookResult = ReturnType<
  typeof useGetLoggedInUserQuery
>;
export type GetLoggedInUserLazyQueryHookResult = ReturnType<
  typeof useGetLoggedInUserLazyQuery
>;
export type GetLoggedInUserQueryResult = Apollo.QueryResult<
  GetLoggedInUserQuery,
  GetLoggedInUserQueryVariables
>;
export const GetActiveSearchRequestNotificationsDocument = gql`
  query GetActiveSearchRequestNotifications(
    $filter: ActiveSearchRequestFilter!
  ) {
    myActiveSearchRequests(filter: $filter) {
      notifications
    }
  }
`;

/**
 * __useGetActiveSearchRequestNotificationsQuery__
 *
 * To run a query within a React component, call `useGetActiveSearchRequestNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveSearchRequestNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveSearchRequestNotificationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetActiveSearchRequestNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActiveSearchRequestNotificationsQuery,
    GetActiveSearchRequestNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveSearchRequestNotificationsQuery,
    GetActiveSearchRequestNotificationsQueryVariables
  >(GetActiveSearchRequestNotificationsDocument, options);
}
export function useGetActiveSearchRequestNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveSearchRequestNotificationsQuery,
    GetActiveSearchRequestNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveSearchRequestNotificationsQuery,
    GetActiveSearchRequestNotificationsQueryVariables
  >(GetActiveSearchRequestNotificationsDocument, options);
}
export type GetActiveSearchRequestNotificationsQueryHookResult = ReturnType<
  typeof useGetActiveSearchRequestNotificationsQuery
>;
export type GetActiveSearchRequestNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetActiveSearchRequestNotificationsLazyQuery
>;
export type GetActiveSearchRequestNotificationsQueryResult = Apollo.QueryResult<
  GetActiveSearchRequestNotificationsQuery,
  GetActiveSearchRequestNotificationsQueryVariables
>;
export const GetUnreadProspectsDocument = gql`
  query GetUnreadProspects {
    leaseAgreementProspects(
      filter: { personalized: true, adminStatus: [accepted] }
    ) {
      unread
    }
  }
`;

/**
 * __useGetUnreadProspectsQuery__
 *
 * To run a query within a React component, call `useGetUnreadProspectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadProspectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadProspectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnreadProspectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUnreadProspectsQuery,
    GetUnreadProspectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUnreadProspectsQuery,
    GetUnreadProspectsQueryVariables
  >(GetUnreadProspectsDocument, options);
}
export function useGetUnreadProspectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnreadProspectsQuery,
    GetUnreadProspectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUnreadProspectsQuery,
    GetUnreadProspectsQueryVariables
  >(GetUnreadProspectsDocument, options);
}
export type GetUnreadProspectsQueryHookResult = ReturnType<
  typeof useGetUnreadProspectsQuery
>;
export type GetUnreadProspectsLazyQueryHookResult = ReturnType<
  typeof useGetUnreadProspectsLazyQuery
>;
export type GetUnreadProspectsQueryResult = Apollo.QueryResult<
  GetUnreadProspectsQuery,
  GetUnreadProspectsQueryVariables
>;
export const AddSearchRequestCommentLandlordDocument = gql`
  mutation AddSearchRequestCommentLandlord(
    $searchRequestId: String!
    $input: SearchRequestCommentInput!
  ) {
    addSearchRequestCommentLandlord(
      searchRequestId: $searchRequestId
      input: $input
    ) {
      ...SearchRequestCommentValues
    }
  }
  ${SearchRequestCommentValuesFragmentDoc}
`;
export type AddSearchRequestCommentLandlordMutationFn = Apollo.MutationFunction<
  AddSearchRequestCommentLandlordMutation,
  AddSearchRequestCommentLandlordMutationVariables
>;

/**
 * __useAddSearchRequestCommentLandlordMutation__
 *
 * To run a mutation, you first call `useAddSearchRequestCommentLandlordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSearchRequestCommentLandlordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSearchRequestCommentLandlordMutation, { data, loading, error }] = useAddSearchRequestCommentLandlordMutation({
 *   variables: {
 *      searchRequestId: // value for 'searchRequestId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSearchRequestCommentLandlordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSearchRequestCommentLandlordMutation,
    AddSearchRequestCommentLandlordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSearchRequestCommentLandlordMutation,
    AddSearchRequestCommentLandlordMutationVariables
  >(AddSearchRequestCommentLandlordDocument, options);
}
export type AddSearchRequestCommentLandlordMutationHookResult = ReturnType<
  typeof useAddSearchRequestCommentLandlordMutation
>;
export type AddSearchRequestCommentLandlordMutationResult =
  Apollo.MutationResult<AddSearchRequestCommentLandlordMutation>;
export type AddSearchRequestCommentLandlordMutationOptions =
  Apollo.BaseMutationOptions<
    AddSearchRequestCommentLandlordMutation,
    AddSearchRequestCommentLandlordMutationVariables
  >;
export const ArchiveSearchRequestDocument = gql`
  mutation ArchiveSearchRequest($id: String!) {
    archiveSearchRequest(id: $id) {
      id
    }
  }
`;
export type ArchiveSearchRequestMutationFn = Apollo.MutationFunction<
  ArchiveSearchRequestMutation,
  ArchiveSearchRequestMutationVariables
>;

/**
 * __useArchiveSearchRequestMutation__
 *
 * To run a mutation, you first call `useArchiveSearchRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveSearchRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveSearchRequestMutation, { data, loading, error }] = useArchiveSearchRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveSearchRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveSearchRequestMutation,
    ArchiveSearchRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveSearchRequestMutation,
    ArchiveSearchRequestMutationVariables
  >(ArchiveSearchRequestDocument, options);
}
export type ArchiveSearchRequestMutationHookResult = ReturnType<
  typeof useArchiveSearchRequestMutation
>;
export type ArchiveSearchRequestMutationResult =
  Apollo.MutationResult<ArchiveSearchRequestMutation>;
export type ArchiveSearchRequestMutationOptions = Apollo.BaseMutationOptions<
  ArchiveSearchRequestMutation,
  ArchiveSearchRequestMutationVariables
>;
export const DeleteSearchRequestCommentLandlordDocument = gql`
  mutation DeleteSearchRequestCommentLandlord($id: String!) {
    deleteSearchRequestCommentLandlord(id: $id) {
      ...SearchRequestCommentValues
    }
  }
  ${SearchRequestCommentValuesFragmentDoc}
`;
export type DeleteSearchRequestCommentLandlordMutationFn =
  Apollo.MutationFunction<
    DeleteSearchRequestCommentLandlordMutation,
    DeleteSearchRequestCommentLandlordMutationVariables
  >;

/**
 * __useDeleteSearchRequestCommentLandlordMutation__
 *
 * To run a mutation, you first call `useDeleteSearchRequestCommentLandlordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSearchRequestCommentLandlordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSearchRequestCommentLandlordMutation, { data, loading, error }] = useDeleteSearchRequestCommentLandlordMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSearchRequestCommentLandlordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSearchRequestCommentLandlordMutation,
    DeleteSearchRequestCommentLandlordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSearchRequestCommentLandlordMutation,
    DeleteSearchRequestCommentLandlordMutationVariables
  >(DeleteSearchRequestCommentLandlordDocument, options);
}
export type DeleteSearchRequestCommentLandlordMutationHookResult = ReturnType<
  typeof useDeleteSearchRequestCommentLandlordMutation
>;
export type DeleteSearchRequestCommentLandlordMutationResult =
  Apollo.MutationResult<DeleteSearchRequestCommentLandlordMutation>;
export type DeleteSearchRequestCommentLandlordMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteSearchRequestCommentLandlordMutation,
    DeleteSearchRequestCommentLandlordMutationVariables
  >;
export const GetMyActiveSearchRequestsDocument = gql`
  query GetMyActiveSearchRequests(
    $showArchived: Boolean!
    $filter: ActiveSearchRequestFilter!
    $todoLimit: Int!
    $interestingLimit: Int
  ) {
    myActiveSearchRequests(filter: $filter) {
      todo(limit: $todoLimit) {
        searchRequests {
          ...SearchRequestCardValues
        }
        count
      }
      interesting(limit: $interestingLimit) {
        searchRequests {
          ...SearchRequestCardValues
        }
        count
      }
      inProgress {
        searchRequests {
          ...SearchRequestCardValues
        }
      }
      negotiation {
        searchRequests {
          ...SearchRequestCardValues
        }
      }
      success {
        searchRequests {
          ...SearchRequestCardValues
        }
      }
      archived @include(if: $showArchived) {
        searchRequests {
          ...SearchRequestCardValues
        }
      }
    }
  }
  ${SearchRequestCardValuesFragmentDoc}
`;

/**
 * __useGetMyActiveSearchRequestsQuery__
 *
 * To run a query within a React component, call `useGetMyActiveSearchRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyActiveSearchRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyActiveSearchRequestsQuery({
 *   variables: {
 *      showArchived: // value for 'showArchived'
 *      filter: // value for 'filter'
 *      todoLimit: // value for 'todoLimit'
 *      interestingLimit: // value for 'interestingLimit'
 *   },
 * });
 */
export function useGetMyActiveSearchRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyActiveSearchRequestsQuery,
    GetMyActiveSearchRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyActiveSearchRequestsQuery,
    GetMyActiveSearchRequestsQueryVariables
  >(GetMyActiveSearchRequestsDocument, options);
}
export function useGetMyActiveSearchRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyActiveSearchRequestsQuery,
    GetMyActiveSearchRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyActiveSearchRequestsQuery,
    GetMyActiveSearchRequestsQueryVariables
  >(GetMyActiveSearchRequestsDocument, options);
}
export type GetMyActiveSearchRequestsQueryHookResult = ReturnType<
  typeof useGetMyActiveSearchRequestsQuery
>;
export type GetMyActiveSearchRequestsLazyQueryHookResult = ReturnType<
  typeof useGetMyActiveSearchRequestsLazyQuery
>;
export type GetMyActiveSearchRequestsQueryResult = Apollo.QueryResult<
  GetMyActiveSearchRequestsQuery,
  GetMyActiveSearchRequestsQueryVariables
>;
export const GetSearchRequestCommentsLandlordDocument = gql`
  query GetSearchRequestCommentsLandlord($searchRequestId: String!) {
    searchRequestCommentsLandlord(searchRequestId: $searchRequestId) {
      ...SearchRequestCommentValues
    }
  }
  ${SearchRequestCommentValuesFragmentDoc}
`;

/**
 * __useGetSearchRequestCommentsLandlordQuery__
 *
 * To run a query within a React component, call `useGetSearchRequestCommentsLandlordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchRequestCommentsLandlordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchRequestCommentsLandlordQuery({
 *   variables: {
 *      searchRequestId: // value for 'searchRequestId'
 *   },
 * });
 */
export function useGetSearchRequestCommentsLandlordQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSearchRequestCommentsLandlordQuery,
    GetSearchRequestCommentsLandlordQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSearchRequestCommentsLandlordQuery,
    GetSearchRequestCommentsLandlordQueryVariables
  >(GetSearchRequestCommentsLandlordDocument, options);
}
export function useGetSearchRequestCommentsLandlordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchRequestCommentsLandlordQuery,
    GetSearchRequestCommentsLandlordQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchRequestCommentsLandlordQuery,
    GetSearchRequestCommentsLandlordQueryVariables
  >(GetSearchRequestCommentsLandlordDocument, options);
}
export type GetSearchRequestCommentsLandlordQueryHookResult = ReturnType<
  typeof useGetSearchRequestCommentsLandlordQuery
>;
export type GetSearchRequestCommentsLandlordLazyQueryHookResult = ReturnType<
  typeof useGetSearchRequestCommentsLandlordLazyQuery
>;
export type GetSearchRequestCommentsLandlordQueryResult = Apollo.QueryResult<
  GetSearchRequestCommentsLandlordQuery,
  GetSearchRequestCommentsLandlordQueryVariables
>;
export const UpdateSearchRequestStepDocument = gql`
  mutation UpdateSearchRequestStep(
    $id: String!
    $update: UpdateSearchRequestStepInput!
  ) {
    updateSearchRequestStep(id: $id, update: $update) {
      id
      step
    }
  }
`;
export type UpdateSearchRequestStepMutationFn = Apollo.MutationFunction<
  UpdateSearchRequestStepMutation,
  UpdateSearchRequestStepMutationVariables
>;

/**
 * __useUpdateSearchRequestStepMutation__
 *
 * To run a mutation, you first call `useUpdateSearchRequestStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSearchRequestStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSearchRequestStepMutation, { data, loading, error }] = useUpdateSearchRequestStepMutation({
 *   variables: {
 *      id: // value for 'id'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateSearchRequestStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSearchRequestStepMutation,
    UpdateSearchRequestStepMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSearchRequestStepMutation,
    UpdateSearchRequestStepMutationVariables
  >(UpdateSearchRequestStepDocument, options);
}
export type UpdateSearchRequestStepMutationHookResult = ReturnType<
  typeof useUpdateSearchRequestStepMutation
>;
export type UpdateSearchRequestStepMutationResult =
  Apollo.MutationResult<UpdateSearchRequestStepMutation>;
export type UpdateSearchRequestStepMutationOptions = Apollo.BaseMutationOptions<
  UpdateSearchRequestStepMutation,
  UpdateSearchRequestStepMutationVariables
>;
export const GetActivityNumbersDocument = gql`
  query GetActivityNumbers($limit: Int!, $type: [ActivityLogEnum!]) {
    activityData(filter: { limit: $limit, type: $type }) {
      statistics {
        today
        yesterday
        ATH
        average
      }
    }
  }
`;

/**
 * __useGetActivityNumbersQuery__
 *
 * To run a query within a React component, call `useGetActivityNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityNumbersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetActivityNumbersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivityNumbersQuery,
    GetActivityNumbersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActivityNumbersQuery,
    GetActivityNumbersQueryVariables
  >(GetActivityNumbersDocument, options);
}
export function useGetActivityNumbersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivityNumbersQuery,
    GetActivityNumbersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivityNumbersQuery,
    GetActivityNumbersQueryVariables
  >(GetActivityNumbersDocument, options);
}
export type GetActivityNumbersQueryHookResult = ReturnType<
  typeof useGetActivityNumbersQuery
>;
export type GetActivityNumbersLazyQueryHookResult = ReturnType<
  typeof useGetActivityNumbersLazyQuery
>;
export type GetActivityNumbersQueryResult = Apollo.QueryResult<
  GetActivityNumbersQuery,
  GetActivityNumbersQueryVariables
>;
export const GetSystemActivityDocument = gql`
  query GetSystemActivity(
    $type: [ActivityLogEnum!]
    $from: Date
    $to: Date
    $limit: Int!
  ) {
    activityData(filter: { type: $type, from: $from, to: $to, limit: $limit }) {
      count
      activities {
        id
        user {
          id
          name
          searchRequestSettings {
            regions {
              name
            }
          }
        }
        ...ActivityLogValues
      }
    }
  }
  ${ActivityLogValuesFragmentDoc}
`;

/**
 * __useGetSystemActivityQuery__
 *
 * To run a query within a React component, call `useGetSystemActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemActivityQuery({
 *   variables: {
 *      type: // value for 'type'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSystemActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSystemActivityQuery,
    GetSystemActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSystemActivityQuery,
    GetSystemActivityQueryVariables
  >(GetSystemActivityDocument, options);
}
export function useGetSystemActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSystemActivityQuery,
    GetSystemActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSystemActivityQuery,
    GetSystemActivityQueryVariables
  >(GetSystemActivityDocument, options);
}
export type GetSystemActivityQueryHookResult = ReturnType<
  typeof useGetSystemActivityQuery
>;
export type GetSystemActivityLazyQueryHookResult = ReturnType<
  typeof useGetSystemActivityLazyQuery
>;
export type GetSystemActivityQueryResult = Apollo.QueryResult<
  GetSystemActivityQuery,
  GetSystemActivityQueryVariables
>;
export const GetInactiveUsersDocument = gql`
  query GetInactiveUsers($clientType: [String], $createdFrom: Date) {
    userData: users(
      filter: {
        clientType: $clientType
        createdFrom: $createdFrom
        hasNotAccessed: true
      }
      sort: "created"
      order: "descend"
    ) {
      users {
        id
        name
        activeClient {
          id
          name
        }
        searchRequests {
          id
          title
          stats {
            accepted
            declined
            opened
          }
        }
        created
      }
      count
    }
  }
`;

/**
 * __useGetInactiveUsersQuery__
 *
 * To run a query within a React component, call `useGetInactiveUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInactiveUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInactiveUsersQuery({
 *   variables: {
 *      clientType: // value for 'clientType'
 *      createdFrom: // value for 'createdFrom'
 *   },
 * });
 */
export function useGetInactiveUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInactiveUsersQuery,
    GetInactiveUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInactiveUsersQuery, GetInactiveUsersQueryVariables>(
    GetInactiveUsersDocument,
    options,
  );
}
export function useGetInactiveUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInactiveUsersQuery,
    GetInactiveUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInactiveUsersQuery,
    GetInactiveUsersQueryVariables
  >(GetInactiveUsersDocument, options);
}
export type GetInactiveUsersQueryHookResult = ReturnType<
  typeof useGetInactiveUsersQuery
>;
export type GetInactiveUsersLazyQueryHookResult = ReturnType<
  typeof useGetInactiveUsersLazyQuery
>;
export type GetInactiveUsersQueryResult = Apollo.QueryResult<
  GetInactiveUsersQuery,
  GetInactiveUsersQueryVariables
>;
export const GetStatusUpdatesDocument = gql`
  query GetStatusUpdates($limit: Int!, $direction: Direction) {
    statusUpdateData: statusUpdates(direction: $direction, limit: $limit) {
      statusUpdates {
        id
        user {
          id
          name
          searchRequestSettings {
            regions {
              name
            }
          }
        }
        client {
          id
          name
          type
        }
        created
        statusUpdate {
          direction
          nextStatus
          previousStatus
        }
      }
      count
    }
  }
`;

/**
 * __useGetStatusUpdatesQuery__
 *
 * To run a query within a React component, call `useGetStatusUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusUpdatesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      direction: // value for 'direction'
 *   },
 * });
 */
export function useGetStatusUpdatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStatusUpdatesQuery,
    GetStatusUpdatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStatusUpdatesQuery, GetStatusUpdatesQueryVariables>(
    GetStatusUpdatesDocument,
    options,
  );
}
export function useGetStatusUpdatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStatusUpdatesQuery,
    GetStatusUpdatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStatusUpdatesQuery,
    GetStatusUpdatesQueryVariables
  >(GetStatusUpdatesDocument, options);
}
export type GetStatusUpdatesQueryHookResult = ReturnType<
  typeof useGetStatusUpdatesQuery
>;
export type GetStatusUpdatesLazyQueryHookResult = ReturnType<
  typeof useGetStatusUpdatesLazyQuery
>;
export type GetStatusUpdatesQueryResult = Apollo.QueryResult<
  GetStatusUpdatesQuery,
  GetStatusUpdatesQueryVariables
>;
export const LoginWithTokenDocument = gql`
  mutation LoginWithToken($token: String!) {
    loginWithToken(token: $token) {
      token
      searchRequestReferenceId
    }
  }
`;
export type LoginWithTokenMutationFn = Apollo.MutationFunction<
  LoginWithTokenMutation,
  LoginWithTokenMutationVariables
>;

/**
 * __useLoginWithTokenMutation__
 *
 * To run a mutation, you first call `useLoginWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithTokenMutation, { data, loading, error }] = useLoginWithTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoginWithTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginWithTokenMutation,
    LoginWithTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoginWithTokenMutation,
    LoginWithTokenMutationVariables
  >(LoginWithTokenDocument, options);
}
export type LoginWithTokenMutationHookResult = ReturnType<
  typeof useLoginWithTokenMutation
>;
export type LoginWithTokenMutationResult =
  Apollo.MutationResult<LoginWithTokenMutation>;
export type LoginWithTokenMutationOptions = Apollo.BaseMutationOptions<
  LoginWithTokenMutation,
  LoginWithTokenMutationVariables
>;
export const GetMyColleaguesDocument = gql`
  query GetMyColleagues($clientId: String!) {
    myColleagues(clientId: $clientId) {
      count
      colleagues {
        id
        name
        email
        phone
        hasPassword
        image {
          id
          url(
            processing: {
              quality: auto
              width: 450
              height: 450
              gravity: face
              crop: fill
            }
          )
        }
        logins
      }
    }
  }
`;

/**
 * __useGetMyColleaguesQuery__
 *
 * To run a query within a React component, call `useGetMyColleaguesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyColleaguesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyColleaguesQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetMyColleaguesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyColleaguesQuery,
    GetMyColleaguesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyColleaguesQuery, GetMyColleaguesQueryVariables>(
    GetMyColleaguesDocument,
    options,
  );
}
export function useGetMyColleaguesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyColleaguesQuery,
    GetMyColleaguesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyColleaguesQuery,
    GetMyColleaguesQueryVariables
  >(GetMyColleaguesDocument, options);
}
export type GetMyColleaguesQueryHookResult = ReturnType<
  typeof useGetMyColleaguesQuery
>;
export type GetMyColleaguesLazyQueryHookResult = ReturnType<
  typeof useGetMyColleaguesLazyQuery
>;
export type GetMyColleaguesQueryResult = Apollo.QueryResult<
  GetMyColleaguesQuery,
  GetMyColleaguesQueryVariables
>;
export const AddUserMyColleaguesDocument = gql`
  mutation addUserMyColleagues($input: UserInput!) {
    addUserMyColleagues(input: $input) {
      ...UserValues
    }
  }
  ${UserValuesFragmentDoc}
`;
export type AddUserMyColleaguesMutationFn = Apollo.MutationFunction<
  AddUserMyColleaguesMutation,
  AddUserMyColleaguesMutationVariables
>;

/**
 * __useAddUserMyColleaguesMutation__
 *
 * To run a mutation, you first call `useAddUserMyColleaguesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMyColleaguesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMyColleaguesMutation, { data, loading, error }] = useAddUserMyColleaguesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserMyColleaguesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserMyColleaguesMutation,
    AddUserMyColleaguesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddUserMyColleaguesMutation,
    AddUserMyColleaguesMutationVariables
  >(AddUserMyColleaguesDocument, options);
}
export type AddUserMyColleaguesMutationHookResult = ReturnType<
  typeof useAddUserMyColleaguesMutation
>;
export type AddUserMyColleaguesMutationResult =
  Apollo.MutationResult<AddUserMyColleaguesMutation>;
export type AddUserMyColleaguesMutationOptions = Apollo.BaseMutationOptions<
  AddUserMyColleaguesMutation,
  AddUserMyColleaguesMutationVariables
>;
export const AddClientDocument = gql`
  mutation AddClient($input: ClientInput!) {
    addClient(input: $input) {
      ...ClientValues
    }
  }
  ${ClientValuesFragmentDoc}
`;
export type AddClientMutationFn = Apollo.MutationFunction<
  AddClientMutation,
  AddClientMutationVariables
>;

/**
 * __useAddClientMutation__
 *
 * To run a mutation, you first call `useAddClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientMutation, { data, loading, error }] = useAddClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddClientMutation,
    AddClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddClientMutation, AddClientMutationVariables>(
    AddClientDocument,
    options,
  );
}
export type AddClientMutationHookResult = ReturnType<
  typeof useAddClientMutation
>;
export type AddClientMutationResult = Apollo.MutationResult<AddClientMutation>;
export type AddClientMutationOptions = Apollo.BaseMutationOptions<
  AddClientMutation,
  AddClientMutationVariables
>;
export const ClientLeaseAgreementsDocument = gql`
  query ClientLeaseAgreements($input: LeaseAgreementFilter, $offset: Int!) {
    leaseAgreements(input: $input) {
      items(limit: 10, offset: $offset) {
        id
        name
        address {
          city
        }
        key: id
      }
      count
    }
  }
`;

/**
 * __useClientLeaseAgreementsQuery__
 *
 * To run a query within a React component, call `useClientLeaseAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientLeaseAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientLeaseAgreementsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useClientLeaseAgreementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientLeaseAgreementsQuery,
    ClientLeaseAgreementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClientLeaseAgreementsQuery,
    ClientLeaseAgreementsQueryVariables
  >(ClientLeaseAgreementsDocument, options);
}
export function useClientLeaseAgreementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientLeaseAgreementsQuery,
    ClientLeaseAgreementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientLeaseAgreementsQuery,
    ClientLeaseAgreementsQueryVariables
  >(ClientLeaseAgreementsDocument, options);
}
export type ClientLeaseAgreementsQueryHookResult = ReturnType<
  typeof useClientLeaseAgreementsQuery
>;
export type ClientLeaseAgreementsLazyQueryHookResult = ReturnType<
  typeof useClientLeaseAgreementsLazyQuery
>;
export type ClientLeaseAgreementsQueryResult = Apollo.QueryResult<
  ClientLeaseAgreementsQuery,
  ClientLeaseAgreementsQueryVariables
>;
export const DeleteClientDocument = gql`
  mutation DeleteClient($id: String!) {
    deleteClient(id: $id) {
      id
    }
  }
`;
export type DeleteClientMutationFn = Apollo.MutationFunction<
  DeleteClientMutation,
  DeleteClientMutationVariables
>;

/**
 * __useDeleteClientMutation__
 *
 * To run a mutation, you first call `useDeleteClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientMutation, { data, loading, error }] = useDeleteClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientMutation,
    DeleteClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientMutation,
    DeleteClientMutationVariables
  >(DeleteClientDocument, options);
}
export type DeleteClientMutationHookResult = ReturnType<
  typeof useDeleteClientMutation
>;
export type DeleteClientMutationResult =
  Apollo.MutationResult<DeleteClientMutation>;
export type DeleteClientMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientMutation,
  DeleteClientMutationVariables
>;
export const EditClientDocument = gql`
  mutation EditClient($id: String!, $input: ClientInput!) {
    editClient(id: $id, input: $input) {
      ...ClientValues
    }
  }
  ${ClientValuesFragmentDoc}
`;
export type EditClientMutationFn = Apollo.MutationFunction<
  EditClientMutation,
  EditClientMutationVariables
>;

/**
 * __useEditClientMutation__
 *
 * To run a mutation, you first call `useEditClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editClientMutation, { data, loading, error }] = useEditClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditClientMutation,
    EditClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditClientMutation, EditClientMutationVariables>(
    EditClientDocument,
    options,
  );
}
export type EditClientMutationHookResult = ReturnType<
  typeof useEditClientMutation
>;
export type EditClientMutationResult =
  Apollo.MutationResult<EditClientMutation>;
export type EditClientMutationOptions = Apollo.BaseMutationOptions<
  EditClientMutation,
  EditClientMutationVariables
>;
export const GetClientDocument = gql`
  query GetClient($id: String!) {
    client(id: $id) {
      ...ClientValues
    }
  }
  ${ClientValuesFragmentDoc}
`;

/**
 * __useGetClientQuery__
 *
 * To run a query within a React component, call `useGetClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientQuery(
  baseOptions: Apollo.QueryHookOptions<GetClientQuery, GetClientQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientQuery, GetClientQueryVariables>(
    GetClientDocument,
    options,
  );
}
export function useGetClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientQuery,
    GetClientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientQuery, GetClientQueryVariables>(
    GetClientDocument,
    options,
  );
}
export type GetClientQueryHookResult = ReturnType<typeof useGetClientQuery>;
export type GetClientLazyQueryHookResult = ReturnType<
  typeof useGetClientLazyQuery
>;
export type GetClientQueryResult = Apollo.QueryResult<
  GetClientQuery,
  GetClientQueryVariables
>;
export const GetClientAdminDocument = gql`
  query GetClientAdmin($id: String!) {
    client(id: $id) {
      ...ClientValues
      gateCustomer
      users {
        id
        name
        email
        phone
      }
    }
  }
  ${ClientValuesFragmentDoc}
`;

/**
 * __useGetClientAdminQuery__
 *
 * To run a query within a React component, call `useGetClientAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientAdminQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientAdminQuery,
    GetClientAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientAdminQuery, GetClientAdminQueryVariables>(
    GetClientAdminDocument,
    options,
  );
}
export function useGetClientAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientAdminQuery,
    GetClientAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientAdminQuery, GetClientAdminQueryVariables>(
    GetClientAdminDocument,
    options,
  );
}
export type GetClientAdminQueryHookResult = ReturnType<
  typeof useGetClientAdminQuery
>;
export type GetClientAdminLazyQueryHookResult = ReturnType<
  typeof useGetClientAdminLazyQuery
>;
export type GetClientAdminQueryResult = Apollo.QueryResult<
  GetClientAdminQuery,
  GetClientAdminQueryVariables
>;
export const GetClientCsvDocument = gql`
  query GetClientCsv(
    $order: String
    $sort: String
    $searchValues: ClientSearchFilters
  ) {
    getClientCsv(
      filter: { searchValues: $searchValues }
      order: $order
      sort: $sort
    )
  }
`;

/**
 * __useGetClientCsvQuery__
 *
 * To run a query within a React component, call `useGetClientCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientCsvQuery({
 *   variables: {
 *      order: // value for 'order'
 *      sort: // value for 'sort'
 *      searchValues: // value for 'searchValues'
 *   },
 * });
 */
export function useGetClientCsvQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClientCsvQuery,
    GetClientCsvQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientCsvQuery, GetClientCsvQueryVariables>(
    GetClientCsvDocument,
    options,
  );
}
export function useGetClientCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientCsvQuery,
    GetClientCsvQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientCsvQuery, GetClientCsvQueryVariables>(
    GetClientCsvDocument,
    options,
  );
}
export type GetClientCsvQueryHookResult = ReturnType<
  typeof useGetClientCsvQuery
>;
export type GetClientCsvLazyQueryHookResult = ReturnType<
  typeof useGetClientCsvLazyQuery
>;
export type GetClientCsvQueryResult = Apollo.QueryResult<
  GetClientCsvQuery,
  GetClientCsvQueryVariables
>;
export const GetClientsDocument = gql`
  query GetClients(
    $limit: Int!
    $skip: Int!
    $order: String
    $sort: String
    $searchValues: ClientSearchFilters
  ) {
    clients(
      filter: { limit: $limit, skip: $skip, searchValues: $searchValues }
      order: $order
      sort: $sort
    ) {
      clients {
        id
        name
        type
        leaseAgreementCount
        estateCount
        hasDescription
        notifySupport
        supportStatus
        brandCategory
        currentSaleStair
        gateCustomer
        currentRequests {
          total
          unanswered
        }
        currentPromotions {
          total
          unanswered
        }
      }
      count
    }
  }
`;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      sort: // value for 'sort'
 *      searchValues: // value for 'searchValues'
 *   },
 * });
 */
export function useGetClientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientsQuery,
    GetClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    options,
  );
}
export function useGetClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientsQuery,
    GetClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    options,
  );
}
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<
  typeof useGetClientsLazyQuery
>;
export type GetClientsQueryResult = Apollo.QueryResult<
  GetClientsQuery,
  GetClientsQueryVariables
>;
export const GetDealClientsDocument = gql`
  query GetDealClients(
    $client: String
    $status: String!
    $skip: Int
    $limit: Int
  ) {
    subscriptionRequests(
      filter: { client: $client, status: $status, skip: $skip, limit: $limit }
    ) {
      id
      status
      clientId
      client {
        id
        name
        deals {
          count
          value
          portallysFee
        }
      }
    }
  }
`;

/**
 * __useGetDealClientsQuery__
 *
 * To run a query within a React component, call `useGetDealClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealClientsQuery({
 *   variables: {
 *      client: // value for 'client'
 *      status: // value for 'status'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDealClientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDealClientsQuery,
    GetDealClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDealClientsQuery, GetDealClientsQueryVariables>(
    GetDealClientsDocument,
    options,
  );
}
export function useGetDealClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDealClientsQuery,
    GetDealClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDealClientsQuery, GetDealClientsQueryVariables>(
    GetDealClientsDocument,
    options,
  );
}
export type GetDealClientsQueryHookResult = ReturnType<
  typeof useGetDealClientsQuery
>;
export type GetDealClientsLazyQueryHookResult = ReturnType<
  typeof useGetDealClientsLazyQuery
>;
export type GetDealClientsQueryResult = Apollo.QueryResult<
  GetDealClientsQuery,
  GetDealClientsQueryVariables
>;
export const GetDealUsersDocument = gql`
  query GetDealUsers($clientId: String!, $limit: Int!) {
    users(filter: { clientId: $clientId, limit: $limit }) {
      users {
        firstName
        lastName
        id
        hasDealRequest(clientId: $clientId)
        deals(clientId: $clientId) {
          count
          value
          portallysFee
        }
      }
      count
    }
  }
`;

/**
 * __useGetDealUsersQuery__
 *
 * To run a query within a React component, call `useGetDealUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealUsersQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDealUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDealUsersQuery,
    GetDealUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDealUsersQuery, GetDealUsersQueryVariables>(
    GetDealUsersDocument,
    options,
  );
}
export function useGetDealUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDealUsersQuery,
    GetDealUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDealUsersQuery, GetDealUsersQueryVariables>(
    GetDealUsersDocument,
    options,
  );
}
export type GetDealUsersQueryHookResult = ReturnType<
  typeof useGetDealUsersQuery
>;
export type GetDealUsersLazyQueryHookResult = ReturnType<
  typeof useGetDealUsersLazyQuery
>;
export type GetDealUsersQueryResult = Apollo.QueryResult<
  GetDealUsersQuery,
  GetDealUsersQueryVariables
>;
export const GetDealsDocument = gql`
  query GetDeals($userId: String, $clientId: String, $treated: Boolean) {
    deals(filter: { userId: $userId, clientId: $clientId, treated: $treated }) {
      deals {
        id
        user {
          name
        }
        subscription {
          version
          type
        }
        userId
        clientId
        madeWith
        annualRent
        portallyFee
        created
        treated
        billing
        email
        reference
        marking
        company
        comment
        client {
          name
        }
      }
      count
    }
  }
`;

/**
 * __useGetDealsQuery__
 *
 * To run a query within a React component, call `useGetDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      clientId: // value for 'clientId'
 *      treated: // value for 'treated'
 *   },
 * });
 */
export function useGetDealsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDealsQuery, GetDealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDealsQuery, GetDealsQueryVariables>(
    GetDealsDocument,
    options,
  );
}
export function useGetDealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDealsQuery,
    GetDealsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDealsQuery, GetDealsQueryVariables>(
    GetDealsDocument,
    options,
  );
}
export type GetDealsQueryHookResult = ReturnType<typeof useGetDealsQuery>;
export type GetDealsLazyQueryHookResult = ReturnType<
  typeof useGetDealsLazyQuery
>;
export type GetDealsQueryResult = Apollo.QueryResult<
  GetDealsQuery,
  GetDealsQueryVariables
>;
export const RemoveDealFromUserDocument = gql`
  mutation RemoveDealFromUser($userId: String!, $clientId: String!) {
    removeDealFromUser(userId: $userId, clientId: $clientId) {
      id
      activeClientId
      clients {
        id
        name
      }
      hasDealRequest
    }
  }
`;
export type RemoveDealFromUserMutationFn = Apollo.MutationFunction<
  RemoveDealFromUserMutation,
  RemoveDealFromUserMutationVariables
>;

/**
 * __useRemoveDealFromUserMutation__
 *
 * To run a mutation, you first call `useRemoveDealFromUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDealFromUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDealFromUserMutation, { data, loading, error }] = useRemoveDealFromUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useRemoveDealFromUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveDealFromUserMutation,
    RemoveDealFromUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveDealFromUserMutation,
    RemoveDealFromUserMutationVariables
  >(RemoveDealFromUserDocument, options);
}
export type RemoveDealFromUserMutationHookResult = ReturnType<
  typeof useRemoveDealFromUserMutation
>;
export type RemoveDealFromUserMutationResult =
  Apollo.MutationResult<RemoveDealFromUserMutation>;
export type RemoveDealFromUserMutationOptions = Apollo.BaseMutationOptions<
  RemoveDealFromUserMutation,
  RemoveDealFromUserMutationVariables
>;
export const RemoveDealsFromClientDocument = gql`
  mutation RemoveDealsFromClient($clientId: String!) {
    removeDealsFromClient(clientId: $clientId) {
      id
      activeClientId
      clients {
        id
        name
      }
      hasDealRequest
    }
  }
`;
export type RemoveDealsFromClientMutationFn = Apollo.MutationFunction<
  RemoveDealsFromClientMutation,
  RemoveDealsFromClientMutationVariables
>;

/**
 * __useRemoveDealsFromClientMutation__
 *
 * To run a mutation, you first call `useRemoveDealsFromClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDealsFromClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDealsFromClientMutation, { data, loading, error }] = useRemoveDealsFromClientMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useRemoveDealsFromClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveDealsFromClientMutation,
    RemoveDealsFromClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveDealsFromClientMutation,
    RemoveDealsFromClientMutationVariables
  >(RemoveDealsFromClientDocument, options);
}
export type RemoveDealsFromClientMutationHookResult = ReturnType<
  typeof useRemoveDealsFromClientMutation
>;
export type RemoveDealsFromClientMutationResult =
  Apollo.MutationResult<RemoveDealsFromClientMutation>;
export type RemoveDealsFromClientMutationOptions = Apollo.BaseMutationOptions<
  RemoveDealsFromClientMutation,
  RemoveDealsFromClientMutationVariables
>;
export const RemoveFromAllDocument = gql`
  mutation RemoveFromAll {
    removeFromAll {
      id
      activeClientId
      clients {
        id
        name
      }
      hasDealRequest
    }
  }
`;
export type RemoveFromAllMutationFn = Apollo.MutationFunction<
  RemoveFromAllMutation,
  RemoveFromAllMutationVariables
>;

/**
 * __useRemoveFromAllMutation__
 *
 * To run a mutation, you first call `useRemoveFromAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromAllMutation, { data, loading, error }] = useRemoveFromAllMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveFromAllMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFromAllMutation,
    RemoveFromAllMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveFromAllMutation,
    RemoveFromAllMutationVariables
  >(RemoveFromAllDocument, options);
}
export type RemoveFromAllMutationHookResult = ReturnType<
  typeof useRemoveFromAllMutation
>;
export type RemoveFromAllMutationResult =
  Apollo.MutationResult<RemoveFromAllMutation>;
export type RemoveFromAllMutationOptions = Apollo.BaseMutationOptions<
  RemoveFromAllMutation,
  RemoveFromAllMutationVariables
>;
export const SetDealsToClientDocument = gql`
  mutation SetDealsToClient($clientId: String!) {
    setDealsToClient(clientId: $clientId) {
      id
      activeClient {
        name
      }
      hasDealRequest
    }
  }
`;
export type SetDealsToClientMutationFn = Apollo.MutationFunction<
  SetDealsToClientMutation,
  SetDealsToClientMutationVariables
>;

/**
 * __useSetDealsToClientMutation__
 *
 * To run a mutation, you first call `useSetDealsToClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDealsToClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDealsToClientMutation, { data, loading, error }] = useSetDealsToClientMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useSetDealsToClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDealsToClientMutation,
    SetDealsToClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetDealsToClientMutation,
    SetDealsToClientMutationVariables
  >(SetDealsToClientDocument, options);
}
export type SetDealsToClientMutationHookResult = ReturnType<
  typeof useSetDealsToClientMutation
>;
export type SetDealsToClientMutationResult =
  Apollo.MutationResult<SetDealsToClientMutation>;
export type SetDealsToClientMutationOptions = Apollo.BaseMutationOptions<
  SetDealsToClientMutation,
  SetDealsToClientMutationVariables
>;
export const SetDealToUserDocument = gql`
  mutation SetDealToUser($userId: String!, $clientId: String!) {
    setDealToUser(userId: $userId, clientId: $clientId) {
      id
      activeClientId
      clients {
        id
        name
      }
      hasDealRequest
    }
  }
`;
export type SetDealToUserMutationFn = Apollo.MutationFunction<
  SetDealToUserMutation,
  SetDealToUserMutationVariables
>;

/**
 * __useSetDealToUserMutation__
 *
 * To run a mutation, you first call `useSetDealToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDealToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDealToUserMutation, { data, loading, error }] = useSetDealToUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useSetDealToUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDealToUserMutation,
    SetDealToUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetDealToUserMutation,
    SetDealToUserMutationVariables
  >(SetDealToUserDocument, options);
}
export type SetDealToUserMutationHookResult = ReturnType<
  typeof useSetDealToUserMutation
>;
export type SetDealToUserMutationResult =
  Apollo.MutationResult<SetDealToUserMutation>;
export type SetDealToUserMutationOptions = Apollo.BaseMutationOptions<
  SetDealToUserMutation,
  SetDealToUserMutationVariables
>;
export const SetToAllDocument = gql`
  mutation SetToAll($filter: String!) {
    setToAll(filter: $filter) {
      id
      activeClientId
      clients {
        id
        name
      }
      hasDealRequest
    }
  }
`;
export type SetToAllMutationFn = Apollo.MutationFunction<
  SetToAllMutation,
  SetToAllMutationVariables
>;

/**
 * __useSetToAllMutation__
 *
 * To run a mutation, you first call `useSetToAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetToAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setToAllMutation, { data, loading, error }] = useSetToAllMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSetToAllMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetToAllMutation,
    SetToAllMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetToAllMutation, SetToAllMutationVariables>(
    SetToAllDocument,
    options,
  );
}
export type SetToAllMutationHookResult = ReturnType<typeof useSetToAllMutation>;
export type SetToAllMutationResult = Apollo.MutationResult<SetToAllMutation>;
export type SetToAllMutationOptions = Apollo.BaseMutationOptions<
  SetToAllMutation,
  SetToAllMutationVariables
>;
export const TreatDealDocument = gql`
  mutation TreatDeal($id: String) {
    treatDeal(id: $id) {
      id
      user {
        id
        name
      }
      userId
      client {
        name
        id
      }
      clientId
      madeWith
      annualRent
      portallyFee
      created
      treated
    }
  }
`;
export type TreatDealMutationFn = Apollo.MutationFunction<
  TreatDealMutation,
  TreatDealMutationVariables
>;

/**
 * __useTreatDealMutation__
 *
 * To run a mutation, you first call `useTreatDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTreatDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [treatDealMutation, { data, loading, error }] = useTreatDealMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTreatDealMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TreatDealMutation,
    TreatDealMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TreatDealMutation, TreatDealMutationVariables>(
    TreatDealDocument,
    options,
  );
}
export type TreatDealMutationHookResult = ReturnType<
  typeof useTreatDealMutation
>;
export type TreatDealMutationResult = Apollo.MutationResult<TreatDealMutation>;
export type TreatDealMutationOptions = Apollo.BaseMutationOptions<
  TreatDealMutation,
  TreatDealMutationVariables
>;
export const DeleteEmailLogDocument = gql`
  mutation DeleteEmailLog($id: String!) {
    deleteEmailLog(id: $id) {
      ...EmailLogValues
    }
  }
  ${EmailLogValuesFragmentDoc}
`;
export type DeleteEmailLogMutationFn = Apollo.MutationFunction<
  DeleteEmailLogMutation,
  DeleteEmailLogMutationVariables
>;

/**
 * __useDeleteEmailLogMutation__
 *
 * To run a mutation, you first call `useDeleteEmailLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailLogMutation, { data, loading, error }] = useDeleteEmailLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmailLogMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEmailLogMutation,
    DeleteEmailLogMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteEmailLogMutation,
    DeleteEmailLogMutationVariables
  >(DeleteEmailLogDocument, options);
}
export type DeleteEmailLogMutationHookResult = ReturnType<
  typeof useDeleteEmailLogMutation
>;
export type DeleteEmailLogMutationResult =
  Apollo.MutationResult<DeleteEmailLogMutation>;
export type DeleteEmailLogMutationOptions = Apollo.BaseMutationOptions<
  DeleteEmailLogMutation,
  DeleteEmailLogMutationVariables
>;
export const GetEmailLogDocument = gql`
  query GetEmailLog($id: String!) {
    emailLog(id: $id) {
      ...EmailLogValues
    }
  }
  ${EmailLogValuesFragmentDoc}
`;

/**
 * __useGetEmailLogQuery__
 *
 * To run a query within a React component, call `useGetEmailLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmailLogQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmailLogQuery,
    GetEmailLogQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEmailLogQuery, GetEmailLogQueryVariables>(
    GetEmailLogDocument,
    options,
  );
}
export function useGetEmailLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmailLogQuery,
    GetEmailLogQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEmailLogQuery, GetEmailLogQueryVariables>(
    GetEmailLogDocument,
    options,
  );
}
export type GetEmailLogQueryHookResult = ReturnType<typeof useGetEmailLogQuery>;
export type GetEmailLogLazyQueryHookResult = ReturnType<
  typeof useGetEmailLogLazyQuery
>;
export type GetEmailLogQueryResult = Apollo.QueryResult<
  GetEmailLogQuery,
  GetEmailLogQueryVariables
>;
export const GetEmailLogsDocument = gql`
  query GetEmailLogs($filter: EmailLogFilter!) {
    emailLogs(filter: $filter) {
      emailLogs {
        ...EmailLogValues
      }
      count
    }
  }
  ${EmailLogValuesFragmentDoc}
`;

/**
 * __useGetEmailLogsQuery__
 *
 * To run a query within a React component, call `useGetEmailLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailLogsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetEmailLogsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmailLogsQuery,
    GetEmailLogsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEmailLogsQuery, GetEmailLogsQueryVariables>(
    GetEmailLogsDocument,
    options,
  );
}
export function useGetEmailLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmailLogsQuery,
    GetEmailLogsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEmailLogsQuery, GetEmailLogsQueryVariables>(
    GetEmailLogsDocument,
    options,
  );
}
export type GetEmailLogsQueryHookResult = ReturnType<
  typeof useGetEmailLogsQuery
>;
export type GetEmailLogsLazyQueryHookResult = ReturnType<
  typeof useGetEmailLogsLazyQuery
>;
export type GetEmailLogsQueryResult = Apollo.QueryResult<
  GetEmailLogsQuery,
  GetEmailLogsQueryVariables
>;
export const SendWaitingEmailDocument = gql`
  mutation SendWaitingEmail($id: String!, $recipients: [ContactInput!]!) {
    sendWaitingEmail(id: $id, recipients: $recipients)
  }
`;
export type SendWaitingEmailMutationFn = Apollo.MutationFunction<
  SendWaitingEmailMutation,
  SendWaitingEmailMutationVariables
>;

/**
 * __useSendWaitingEmailMutation__
 *
 * To run a mutation, you first call `useSendWaitingEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendWaitingEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendWaitingEmailMutation, { data, loading, error }] = useSendWaitingEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      recipients: // value for 'recipients'
 *   },
 * });
 */
export function useSendWaitingEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendWaitingEmailMutation,
    SendWaitingEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendWaitingEmailMutation,
    SendWaitingEmailMutationVariables
  >(SendWaitingEmailDocument, options);
}
export type SendWaitingEmailMutationHookResult = ReturnType<
  typeof useSendWaitingEmailMutation
>;
export type SendWaitingEmailMutationResult =
  Apollo.MutationResult<SendWaitingEmailMutation>;
export type SendWaitingEmailMutationOptions = Apollo.BaseMutationOptions<
  SendWaitingEmailMutation,
  SendWaitingEmailMutationVariables
>;
export const GetEstablishmentDataDocument = gql`
  query GetEstablishmentData {
    status {
      status
      id: leaseAgreementId
    }
  }
`;

/**
 * __useGetEstablishmentDataQuery__
 *
 * To run a query within a React component, call `useGetEstablishmentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstablishmentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstablishmentDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEstablishmentDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEstablishmentDataQuery,
    GetEstablishmentDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEstablishmentDataQuery,
    GetEstablishmentDataQueryVariables
  >(GetEstablishmentDataDocument, options);
}
export function useGetEstablishmentDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEstablishmentDataQuery,
    GetEstablishmentDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEstablishmentDataQuery,
    GetEstablishmentDataQueryVariables
  >(GetEstablishmentDataDocument, options);
}
export type GetEstablishmentDataQueryHookResult = ReturnType<
  typeof useGetEstablishmentDataQuery
>;
export type GetEstablishmentDataLazyQueryHookResult = ReturnType<
  typeof useGetEstablishmentDataLazyQuery
>;
export type GetEstablishmentDataQueryResult = Apollo.QueryResult<
  GetEstablishmentDataQuery,
  GetEstablishmentDataQueryVariables
>;
export const GetEstablishmentListDataDocument = gql`
  query GetEstablishmentListData {
    status {
      status
      leaseAgreement {
        ...LeaseAgreementListValues
        location
      }
      id: leaseAgreementId
    }
  }
  ${LeaseAgreementListValuesFragmentDoc}
`;

/**
 * __useGetEstablishmentListDataQuery__
 *
 * To run a query within a React component, call `useGetEstablishmentListDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstablishmentListDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstablishmentListDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEstablishmentListDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEstablishmentListDataQuery,
    GetEstablishmentListDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEstablishmentListDataQuery,
    GetEstablishmentListDataQueryVariables
  >(GetEstablishmentListDataDocument, options);
}
export function useGetEstablishmentListDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEstablishmentListDataQuery,
    GetEstablishmentListDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEstablishmentListDataQuery,
    GetEstablishmentListDataQueryVariables
  >(GetEstablishmentListDataDocument, options);
}
export type GetEstablishmentListDataQueryHookResult = ReturnType<
  typeof useGetEstablishmentListDataQuery
>;
export type GetEstablishmentListDataLazyQueryHookResult = ReturnType<
  typeof useGetEstablishmentListDataLazyQuery
>;
export type GetEstablishmentListDataQueryResult = Apollo.QueryResult<
  GetEstablishmentListDataQuery,
  GetEstablishmentListDataQueryVariables
>;
export const AddEstateDocument = gql`
  mutation AddEstate($input: EstateInput!) {
    addEstate(input: $input) {
      ...EstateCustomerValues
    }
  }
  ${EstateCustomerValuesFragmentDoc}
`;
export type AddEstateMutationFn = Apollo.MutationFunction<
  AddEstateMutation,
  AddEstateMutationVariables
>;

/**
 * __useAddEstateMutation__
 *
 * To run a mutation, you first call `useAddEstateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEstateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEstateMutation, { data, loading, error }] = useAddEstateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEstateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEstateMutation,
    AddEstateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddEstateMutation, AddEstateMutationVariables>(
    AddEstateDocument,
    options,
  );
}
export type AddEstateMutationHookResult = ReturnType<
  typeof useAddEstateMutation
>;
export type AddEstateMutationResult = Apollo.MutationResult<AddEstateMutation>;
export type AddEstateMutationOptions = Apollo.BaseMutationOptions<
  AddEstateMutation,
  AddEstateMutationVariables
>;
export const EditEstateCustomerDocument = gql`
  mutation EditEstateCustomer($id: String!, $input: EstateInput!) {
    editEstate(id: $id, input: $input) {
      ...EstateCustomerValues
    }
  }
  ${EstateCustomerValuesFragmentDoc}
`;
export type EditEstateCustomerMutationFn = Apollo.MutationFunction<
  EditEstateCustomerMutation,
  EditEstateCustomerMutationVariables
>;

/**
 * __useEditEstateCustomerMutation__
 *
 * To run a mutation, you first call `useEditEstateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEstateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEstateCustomerMutation, { data, loading, error }] = useEditEstateCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditEstateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditEstateCustomerMutation,
    EditEstateCustomerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditEstateCustomerMutation,
    EditEstateCustomerMutationVariables
  >(EditEstateCustomerDocument, options);
}
export type EditEstateCustomerMutationHookResult = ReturnType<
  typeof useEditEstateCustomerMutation
>;
export type EditEstateCustomerMutationResult =
  Apollo.MutationResult<EditEstateCustomerMutation>;
export type EditEstateCustomerMutationOptions = Apollo.BaseMutationOptions<
  EditEstateCustomerMutation,
  EditEstateCustomerMutationVariables
>;
export const GetEstateViewDocument = gql`
  query GetEstateView($id: String!) {
    estate(id: $id) {
      ...EstateCustomerValues
    }
  }
  ${EstateCustomerValuesFragmentDoc}
`;

/**
 * __useGetEstateViewQuery__
 *
 * To run a query within a React component, call `useGetEstateViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstateViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstateViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEstateViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEstateViewQuery,
    GetEstateViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEstateViewQuery, GetEstateViewQueryVariables>(
    GetEstateViewDocument,
    options,
  );
}
export function useGetEstateViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEstateViewQuery,
    GetEstateViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEstateViewQuery, GetEstateViewQueryVariables>(
    GetEstateViewDocument,
    options,
  );
}
export type GetEstateViewQueryHookResult = ReturnType<
  typeof useGetEstateViewQuery
>;
export type GetEstateViewLazyQueryHookResult = ReturnType<
  typeof useGetEstateViewLazyQuery
>;
export type GetEstateViewQueryResult = Apollo.QueryResult<
  GetEstateViewQuery,
  GetEstateViewQueryVariables
>;
export const DeleteEstateDocument = gql`
  mutation DeleteEstate($id: String!) {
    deleteEstate(id: $id) {
      id
    }
  }
`;
export type DeleteEstateMutationFn = Apollo.MutationFunction<
  DeleteEstateMutation,
  DeleteEstateMutationVariables
>;

/**
 * __useDeleteEstateMutation__
 *
 * To run a mutation, you first call `useDeleteEstateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEstateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEstateMutation, { data, loading, error }] = useDeleteEstateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEstateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEstateMutation,
    DeleteEstateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteEstateMutation,
    DeleteEstateMutationVariables
  >(DeleteEstateDocument, options);
}
export type DeleteEstateMutationHookResult = ReturnType<
  typeof useDeleteEstateMutation
>;
export type DeleteEstateMutationResult =
  Apollo.MutationResult<DeleteEstateMutation>;
export type DeleteEstateMutationOptions = Apollo.BaseMutationOptions<
  DeleteEstateMutation,
  DeleteEstateMutationVariables
>;
export const EditEstateDocument = gql`
  mutation EditEstate($id: String!, $input: EstateInput!) {
    editEstate(id: $id, input: $input) {
      ...EstateValues
    }
  }
  ${EstateValuesFragmentDoc}
`;
export type EditEstateMutationFn = Apollo.MutationFunction<
  EditEstateMutation,
  EditEstateMutationVariables
>;

/**
 * __useEditEstateMutation__
 *
 * To run a mutation, you first call `useEditEstateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEstateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEstateMutation, { data, loading, error }] = useEditEstateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditEstateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditEstateMutation,
    EditEstateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditEstateMutation, EditEstateMutationVariables>(
    EditEstateDocument,
    options,
  );
}
export type EditEstateMutationHookResult = ReturnType<
  typeof useEditEstateMutation
>;
export type EditEstateMutationResult =
  Apollo.MutationResult<EditEstateMutation>;
export type EditEstateMutationOptions = Apollo.BaseMutationOptions<
  EditEstateMutation,
  EditEstateMutationVariables
>;
export const GetEstatesDocument = gql`
  query GetEstates($filter: EstateFilter!) {
    estates(filter: $filter) {
      estates {
        ...EstateValues
      }
      count
    }
  }
  ${EstateValuesFragmentDoc}
`;

/**
 * __useGetEstatesQuery__
 *
 * To run a query within a React component, call `useGetEstatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetEstatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEstatesQuery,
    GetEstatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEstatesQuery, GetEstatesQueryVariables>(
    GetEstatesDocument,
    options,
  );
}
export function useGetEstatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEstatesQuery,
    GetEstatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEstatesQuery, GetEstatesQueryVariables>(
    GetEstatesDocument,
    options,
  );
}
export type GetEstatesQueryHookResult = ReturnType<typeof useGetEstatesQuery>;
export type GetEstatesLazyQueryHookResult = ReturnType<
  typeof useGetEstatesLazyQuery
>;
export type GetEstatesQueryResult = Apollo.QueryResult<
  GetEstatesQuery,
  GetEstatesQueryVariables
>;
export const GetGateGoalsDocument = gql`
  query GetGateGoals {
    gateGoals {
      monthly {
        expectedIncome
        producedIncome
        numberOfMonth
        month
        fromPreviousMonth
      }
      yearly {
        expectedIncome
        producedIncome
      }
    }
  }
`;

/**
 * __useGetGateGoalsQuery__
 *
 * To run a query within a React component, call `useGetGateGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGateGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGateGoalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGateGoalsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGateGoalsQuery,
    GetGateGoalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGateGoalsQuery, GetGateGoalsQueryVariables>(
    GetGateGoalsDocument,
    options,
  );
}
export function useGetGateGoalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGateGoalsQuery,
    GetGateGoalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGateGoalsQuery, GetGateGoalsQueryVariables>(
    GetGateGoalsDocument,
    options,
  );
}
export type GetGateGoalsQueryHookResult = ReturnType<
  typeof useGetGateGoalsQuery
>;
export type GetGateGoalsLazyQueryHookResult = ReturnType<
  typeof useGetGateGoalsLazyQuery
>;
export type GetGateGoalsQueryResult = Apollo.QueryResult<
  GetGateGoalsQuery,
  GetGateGoalsQueryVariables
>;
export const GetActiveSearchRequestStatsDocument = gql`
  query GetActiveSearchRequestStats($filter: ActiveSearchRequestFilter!) {
    myActiveSearchRequests(filter: $filter) {
      todo(limit: 10) {
        count
      }
      interesting {
        count
      }
      inProgress {
        count
      }
      negotiation {
        count
      }
      success {
        count
      }
    }
  }
`;

/**
 * __useGetActiveSearchRequestStatsQuery__
 *
 * To run a query within a React component, call `useGetActiveSearchRequestStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveSearchRequestStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveSearchRequestStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetActiveSearchRequestStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActiveSearchRequestStatsQuery,
    GetActiveSearchRequestStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveSearchRequestStatsQuery,
    GetActiveSearchRequestStatsQueryVariables
  >(GetActiveSearchRequestStatsDocument, options);
}
export function useGetActiveSearchRequestStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveSearchRequestStatsQuery,
    GetActiveSearchRequestStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveSearchRequestStatsQuery,
    GetActiveSearchRequestStatsQueryVariables
  >(GetActiveSearchRequestStatsDocument, options);
}
export type GetActiveSearchRequestStatsQueryHookResult = ReturnType<
  typeof useGetActiveSearchRequestStatsQuery
>;
export type GetActiveSearchRequestStatsLazyQueryHookResult = ReturnType<
  typeof useGetActiveSearchRequestStatsLazyQuery
>;
export type GetActiveSearchRequestStatsQueryResult = Apollo.QueryResult<
  GetActiveSearchRequestStatsQuery,
  GetActiveSearchRequestStatsQueryVariables
>;
export const GetMyActiveSearchRequestsDashboardDocument = gql`
  query GetMyActiveSearchRequestsDashboard(
    $limit: Int!
    $filter: ActiveSearchRequestFilter!
  ) {
    myActiveSearchRequests(filter: $filter) {
      all(limit: $limit) {
        searchRequests {
          ...SearchRequestCardValues
        }
        count
      }
    }
  }
  ${SearchRequestCardValuesFragmentDoc}
`;

/**
 * __useGetMyActiveSearchRequestsDashboardQuery__
 *
 * To run a query within a React component, call `useGetMyActiveSearchRequestsDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyActiveSearchRequestsDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyActiveSearchRequestsDashboardQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetMyActiveSearchRequestsDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyActiveSearchRequestsDashboardQuery,
    GetMyActiveSearchRequestsDashboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyActiveSearchRequestsDashboardQuery,
    GetMyActiveSearchRequestsDashboardQueryVariables
  >(GetMyActiveSearchRequestsDashboardDocument, options);
}
export function useGetMyActiveSearchRequestsDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyActiveSearchRequestsDashboardQuery,
    GetMyActiveSearchRequestsDashboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyActiveSearchRequestsDashboardQuery,
    GetMyActiveSearchRequestsDashboardQueryVariables
  >(GetMyActiveSearchRequestsDashboardDocument, options);
}
export type GetMyActiveSearchRequestsDashboardQueryHookResult = ReturnType<
  typeof useGetMyActiveSearchRequestsDashboardQuery
>;
export type GetMyActiveSearchRequestsDashboardLazyQueryHookResult = ReturnType<
  typeof useGetMyActiveSearchRequestsDashboardLazyQuery
>;
export type GetMyActiveSearchRequestsDashboardQueryResult = Apollo.QueryResult<
  GetMyActiveSearchRequestsDashboardQuery,
  GetMyActiveSearchRequestsDashboardQueryVariables
>;
export const GetMySearchRequestsDocument = gql`
  query GetMySearchRequests($limit: Int!) {
    mySearchRequestReferences(limit: $limit) {
      searchRequests {
        id
        budget
        status
        unreadMessages
        title
        searchParameters {
          usageCategories
          geometry
          regionIds
          bounds {
            north
            south
            east
            west
          }
          regions {
            id
            name
            geometry
          }
          size {
            min
            max
          }
        }
        access {
          quarter
          year
        }
        contactPerson {
          name
        }
      }
      count
    }
  }
`;

/**
 * __useGetMySearchRequestsQuery__
 *
 * To run a query within a React component, call `useGetMySearchRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySearchRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySearchRequestsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMySearchRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMySearchRequestsQuery,
    GetMySearchRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMySearchRequestsQuery,
    GetMySearchRequestsQueryVariables
  >(GetMySearchRequestsDocument, options);
}
export function useGetMySearchRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMySearchRequestsQuery,
    GetMySearchRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMySearchRequestsQuery,
    GetMySearchRequestsQueryVariables
  >(GetMySearchRequestsDocument, options);
}
export type GetMySearchRequestsQueryHookResult = ReturnType<
  typeof useGetMySearchRequestsQuery
>;
export type GetMySearchRequestsLazyQueryHookResult = ReturnType<
  typeof useGetMySearchRequestsLazyQuery
>;
export type GetMySearchRequestsQueryResult = Apollo.QueryResult<
  GetMySearchRequestsQuery,
  GetMySearchRequestsQueryVariables
>;
export const GetActiveSearchProfileDocument = gql`
  query GetActiveSearchProfile(
    $id: String!
    $skipStatus: Boolean
    $zoom: Int!
    $bounds: Bounds
    $offset: Int
  ) {
    searchProfile(id: $id) {
      id
      name
      tooManyHits
      matches(skipStatus: $skipStatus, bounds: $bounds, offset: $offset) {
        count
        items {
          id
          name
          location
          myStatus
        }
      }
      cluster(skipStatus: $skipStatus, zoom: $zoom) {
        id
        location
        count
      }
    }
  }
`;

/**
 * __useGetActiveSearchProfileQuery__
 *
 * To run a query within a React component, call `useGetActiveSearchProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveSearchProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveSearchProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skipStatus: // value for 'skipStatus'
 *      zoom: // value for 'zoom'
 *      bounds: // value for 'bounds'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetActiveSearchProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActiveSearchProfileQuery,
    GetActiveSearchProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveSearchProfileQuery,
    GetActiveSearchProfileQueryVariables
  >(GetActiveSearchProfileDocument, options);
}
export function useGetActiveSearchProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveSearchProfileQuery,
    GetActiveSearchProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveSearchProfileQuery,
    GetActiveSearchProfileQueryVariables
  >(GetActiveSearchProfileDocument, options);
}
export type GetActiveSearchProfileQueryHookResult = ReturnType<
  typeof useGetActiveSearchProfileQuery
>;
export type GetActiveSearchProfileLazyQueryHookResult = ReturnType<
  typeof useGetActiveSearchProfileLazyQuery
>;
export type GetActiveSearchProfileQueryResult = Apollo.QueryResult<
  GetActiveSearchProfileQuery,
  GetActiveSearchProfileQueryVariables
>;
export const GetMySearchProfilesDocument = gql`
  query GetMySearchProfiles($clientId: String) {
    mySearchProfiles(clientId: $clientId) {
      matches {
        count
      }
      id
      name
    }
  }
`;

/**
 * __useGetMySearchProfilesQuery__
 *
 * To run a query within a React component, call `useGetMySearchProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySearchProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySearchProfilesQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetMySearchProfilesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMySearchProfilesQuery,
    GetMySearchProfilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMySearchProfilesQuery,
    GetMySearchProfilesQueryVariables
  >(GetMySearchProfilesDocument, options);
}
export function useGetMySearchProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMySearchProfilesQuery,
    GetMySearchProfilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMySearchProfilesQuery,
    GetMySearchProfilesQueryVariables
  >(GetMySearchProfilesDocument, options);
}
export type GetMySearchProfilesQueryHookResult = ReturnType<
  typeof useGetMySearchProfilesQuery
>;
export type GetMySearchProfilesLazyQueryHookResult = ReturnType<
  typeof useGetMySearchProfilesLazyQuery
>;
export type GetMySearchProfilesQueryResult = Apollo.QueryResult<
  GetMySearchProfilesQuery,
  GetMySearchProfilesQueryVariables
>;
export const GetSearchProfilesCountDocument = gql`
  query GetSearchProfilesCount($limit: Int!, $skip: Int!, $clientId: String!) {
    searchProfiles(
      filter: { limit: $limit, skip: $skip, clientId: $clientId }
    ) {
      count
    }
  }
`;

/**
 * __useGetSearchProfilesCountQuery__
 *
 * To run a query within a React component, call `useGetSearchProfilesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchProfilesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchProfilesCountQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetSearchProfilesCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSearchProfilesCountQuery,
    GetSearchProfilesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSearchProfilesCountQuery,
    GetSearchProfilesCountQueryVariables
  >(GetSearchProfilesCountDocument, options);
}
export function useGetSearchProfilesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchProfilesCountQuery,
    GetSearchProfilesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchProfilesCountQuery,
    GetSearchProfilesCountQueryVariables
  >(GetSearchProfilesCountDocument, options);
}
export type GetSearchProfilesCountQueryHookResult = ReturnType<
  typeof useGetSearchProfilesCountQuery
>;
export type GetSearchProfilesCountLazyQueryHookResult = ReturnType<
  typeof useGetSearchProfilesCountLazyQuery
>;
export type GetSearchProfilesCountQueryResult = Apollo.QueryResult<
  GetSearchProfilesCountQuery,
  GetSearchProfilesCountQueryVariables
>;
export const InviteFriendEmailDocument = gql`
  mutation InviteFriendEmail($email: String!, $clientId: String!) {
    inviteFriendEmail(email: $email, clientId: $clientId)
  }
`;
export type InviteFriendEmailMutationFn = Apollo.MutationFunction<
  InviteFriendEmailMutation,
  InviteFriendEmailMutationVariables
>;

/**
 * __useInviteFriendEmailMutation__
 *
 * To run a mutation, you first call `useInviteFriendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteFriendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteFriendEmailMutation, { data, loading, error }] = useInviteFriendEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useInviteFriendEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteFriendEmailMutation,
    InviteFriendEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteFriendEmailMutation,
    InviteFriendEmailMutationVariables
  >(InviteFriendEmailDocument, options);
}
export type InviteFriendEmailMutationHookResult = ReturnType<
  typeof useInviteFriendEmailMutation
>;
export type InviteFriendEmailMutationResult =
  Apollo.MutationResult<InviteFriendEmailMutation>;
export type InviteFriendEmailMutationOptions = Apollo.BaseMutationOptions<
  InviteFriendEmailMutation,
  InviteFriendEmailMutationVariables
>;
export const MyCancelationsDocument = gql`
  query MyCancelations($input: LeaseAgreementFilter) {
    leaseAgreements(input: $input, personalized: true) {
      items {
        id
        name
        location
        conversations {
          id
          participants {
            id
            name
          }
        }
        address {
          city
        }
        image {
          id
          url(processing: { quality: auto })
        }
      }
    }
  }
`;

/**
 * __useMyCancelationsQuery__
 *
 * To run a query within a React component, call `useMyCancelationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCancelationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCancelationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMyCancelationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyCancelationsQuery,
    MyCancelationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyCancelationsQuery, MyCancelationsQueryVariables>(
    MyCancelationsDocument,
    options,
  );
}
export function useMyCancelationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyCancelationsQuery,
    MyCancelationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyCancelationsQuery, MyCancelationsQueryVariables>(
    MyCancelationsDocument,
    options,
  );
}
export type MyCancelationsQueryHookResult = ReturnType<
  typeof useMyCancelationsQuery
>;
export type MyCancelationsLazyQueryHookResult = ReturnType<
  typeof useMyCancelationsLazyQuery
>;
export type MyCancelationsQueryResult = Apollo.QueryResult<
  MyCancelationsQuery,
  MyCancelationsQueryVariables
>;
export const MyRentalsDocument = gql`
  query MyRentals(
    $input: LeaseAgreementFilter
    $sort: LeaseAgreementSort
    $personalized: Boolean
  ) {
    leaseAgreements(input: $input, personalized: $personalized) {
      items(sort: $sort) {
        id
        name
        location
        conversations {
          id
          participants {
            id
            name
          }
        }
        address {
          city
        }
        image {
          id
          url(processing: { quality: auto, height: 100 })
        }
        views {
          count
        }
        interestedUsers {
          id
        }
        exposures {
          count
        }
      }
    }
  }
`;

/**
 * __useMyRentalsQuery__
 *
 * To run a query within a React component, call `useMyRentalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRentalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRentalsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      sort: // value for 'sort'
 *      personalized: // value for 'personalized'
 *   },
 * });
 */
export function useMyRentalsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyRentalsQuery,
    MyRentalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyRentalsQuery, MyRentalsQueryVariables>(
    MyRentalsDocument,
    options,
  );
}
export function useMyRentalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyRentalsQuery,
    MyRentalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyRentalsQuery, MyRentalsQueryVariables>(
    MyRentalsDocument,
    options,
  );
}
export type MyRentalsQueryHookResult = ReturnType<typeof useMyRentalsQuery>;
export type MyRentalsLazyQueryHookResult = ReturnType<
  typeof useMyRentalsLazyQuery
>;
export type MyRentalsQueryResult = Apollo.QueryResult<
  MyRentalsQuery,
  MyRentalsQueryVariables
>;
export const AddIntegrationProviderDocument = gql`
  mutation AddIntegrationProvider(
    $name: String!
    $provider: IntegrationProviderInput!
  ) {
    integrationProvider(name: $name, provider: $provider) {
      ...IntegrationProviderValues
    }
  }
  ${IntegrationProviderValuesFragmentDoc}
`;
export type AddIntegrationProviderMutationFn = Apollo.MutationFunction<
  AddIntegrationProviderMutation,
  AddIntegrationProviderMutationVariables
>;

/**
 * __useAddIntegrationProviderMutation__
 *
 * To run a mutation, you first call `useAddIntegrationProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIntegrationProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIntegrationProviderMutation, { data, loading, error }] = useAddIntegrationProviderMutation({
 *   variables: {
 *      name: // value for 'name'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useAddIntegrationProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddIntegrationProviderMutation,
    AddIntegrationProviderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddIntegrationProviderMutation,
    AddIntegrationProviderMutationVariables
  >(AddIntegrationProviderDocument, options);
}
export type AddIntegrationProviderMutationHookResult = ReturnType<
  typeof useAddIntegrationProviderMutation
>;
export type AddIntegrationProviderMutationResult =
  Apollo.MutationResult<AddIntegrationProviderMutation>;
export type AddIntegrationProviderMutationOptions = Apollo.BaseMutationOptions<
  AddIntegrationProviderMutation,
  AddIntegrationProviderMutationVariables
>;
export const DeleteIntegrationProviderDocument = gql`
  mutation DeleteIntegrationProvider($id: ID!) {
    deleteIntegrationProvider(id: $id) {
      id
    }
  }
`;
export type DeleteIntegrationProviderMutationFn = Apollo.MutationFunction<
  DeleteIntegrationProviderMutation,
  DeleteIntegrationProviderMutationVariables
>;

/**
 * __useDeleteIntegrationProviderMutation__
 *
 * To run a mutation, you first call `useDeleteIntegrationProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntegrationProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntegrationProviderMutation, { data, loading, error }] = useDeleteIntegrationProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIntegrationProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteIntegrationProviderMutation,
    DeleteIntegrationProviderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteIntegrationProviderMutation,
    DeleteIntegrationProviderMutationVariables
  >(DeleteIntegrationProviderDocument, options);
}
export type DeleteIntegrationProviderMutationHookResult = ReturnType<
  typeof useDeleteIntegrationProviderMutation
>;
export type DeleteIntegrationProviderMutationResult =
  Apollo.MutationResult<DeleteIntegrationProviderMutation>;
export type DeleteIntegrationProviderMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteIntegrationProviderMutation,
    DeleteIntegrationProviderMutationVariables
  >;
export const EditIntegrationProviderDocument = gql`
  mutation EditIntegrationProvider(
    $id: ID!
    $provider: IntegrationProviderInput!
  ) {
    editIntegrationProvider(id: $id, provider: $provider) {
      ...IntegrationProviderValues
    }
  }
  ${IntegrationProviderValuesFragmentDoc}
`;
export type EditIntegrationProviderMutationFn = Apollo.MutationFunction<
  EditIntegrationProviderMutation,
  EditIntegrationProviderMutationVariables
>;

/**
 * __useEditIntegrationProviderMutation__
 *
 * To run a mutation, you first call `useEditIntegrationProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditIntegrationProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editIntegrationProviderMutation, { data, loading, error }] = useEditIntegrationProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useEditIntegrationProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditIntegrationProviderMutation,
    EditIntegrationProviderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditIntegrationProviderMutation,
    EditIntegrationProviderMutationVariables
  >(EditIntegrationProviderDocument, options);
}
export type EditIntegrationProviderMutationHookResult = ReturnType<
  typeof useEditIntegrationProviderMutation
>;
export type EditIntegrationProviderMutationResult =
  Apollo.MutationResult<EditIntegrationProviderMutation>;
export type EditIntegrationProviderMutationOptions = Apollo.BaseMutationOptions<
  EditIntegrationProviderMutation,
  EditIntegrationProviderMutationVariables
>;
export const GetApiKeyDocument = gql`
  mutation GetApiKey($token: String!) {
    apiKey(token: $token) {
      key
      providerId
    }
  }
`;
export type GetApiKeyMutationFn = Apollo.MutationFunction<
  GetApiKeyMutation,
  GetApiKeyMutationVariables
>;

/**
 * __useGetApiKeyMutation__
 *
 * To run a mutation, you first call `useGetApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getApiKeyMutation, { data, loading, error }] = useGetApiKeyMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetApiKeyMutation,
    GetApiKeyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetApiKeyMutation, GetApiKeyMutationVariables>(
    GetApiKeyDocument,
    options,
  );
}
export type GetApiKeyMutationHookResult = ReturnType<
  typeof useGetApiKeyMutation
>;
export type GetApiKeyMutationResult = Apollo.MutationResult<GetApiKeyMutation>;
export type GetApiKeyMutationOptions = Apollo.BaseMutationOptions<
  GetApiKeyMutation,
  GetApiKeyMutationVariables
>;
export const GetIntegrationProviderByTokenDocument = gql`
  query GetIntegrationProviderByToken($token: String!) {
    integrationProviderByToken(token: $token)
  }
`;

/**
 * __useGetIntegrationProviderByTokenQuery__
 *
 * To run a query within a React component, call `useGetIntegrationProviderByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationProviderByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationProviderByTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetIntegrationProviderByTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIntegrationProviderByTokenQuery,
    GetIntegrationProviderByTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIntegrationProviderByTokenQuery,
    GetIntegrationProviderByTokenQueryVariables
  >(GetIntegrationProviderByTokenDocument, options);
}
export function useGetIntegrationProviderByTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIntegrationProviderByTokenQuery,
    GetIntegrationProviderByTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIntegrationProviderByTokenQuery,
    GetIntegrationProviderByTokenQueryVariables
  >(GetIntegrationProviderByTokenDocument, options);
}
export type GetIntegrationProviderByTokenQueryHookResult = ReturnType<
  typeof useGetIntegrationProviderByTokenQuery
>;
export type GetIntegrationProviderByTokenLazyQueryHookResult = ReturnType<
  typeof useGetIntegrationProviderByTokenLazyQuery
>;
export type GetIntegrationProviderByTokenQueryResult = Apollo.QueryResult<
  GetIntegrationProviderByTokenQuery,
  GetIntegrationProviderByTokenQueryVariables
>;
export const GetIntegrationProvidersDocument = gql`
  query GetIntegrationProviders {
    integrationProviders {
      integrationProviders {
        ...IntegrationProviderValues
      }
      count
    }
  }
  ${IntegrationProviderValuesFragmentDoc}
`;

/**
 * __useGetIntegrationProvidersQuery__
 *
 * To run a query within a React component, call `useGetIntegrationProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIntegrationProvidersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetIntegrationProvidersQuery,
    GetIntegrationProvidersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIntegrationProvidersQuery,
    GetIntegrationProvidersQueryVariables
  >(GetIntegrationProvidersDocument, options);
}
export function useGetIntegrationProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIntegrationProvidersQuery,
    GetIntegrationProvidersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIntegrationProvidersQuery,
    GetIntegrationProvidersQueryVariables
  >(GetIntegrationProvidersDocument, options);
}
export type GetIntegrationProvidersQueryHookResult = ReturnType<
  typeof useGetIntegrationProvidersQuery
>;
export type GetIntegrationProvidersLazyQueryHookResult = ReturnType<
  typeof useGetIntegrationProvidersLazyQuery
>;
export type GetIntegrationProvidersQueryResult = Apollo.QueryResult<
  GetIntegrationProvidersQuery,
  GetIntegrationProvidersQueryVariables
>;
export const SendApiKeyLinkDocument = gql`
  mutation SendApiKeyLink($email: String!) {
    sendApiKeyLink(email: $email)
  }
`;
export type SendApiKeyLinkMutationFn = Apollo.MutationFunction<
  SendApiKeyLinkMutation,
  SendApiKeyLinkMutationVariables
>;

/**
 * __useSendApiKeyLinkMutation__
 *
 * To run a mutation, you first call `useSendApiKeyLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendApiKeyLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendApiKeyLinkMutation, { data, loading, error }] = useSendApiKeyLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendApiKeyLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendApiKeyLinkMutation,
    SendApiKeyLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendApiKeyLinkMutation,
    SendApiKeyLinkMutationVariables
  >(SendApiKeyLinkDocument, options);
}
export type SendApiKeyLinkMutationHookResult = ReturnType<
  typeof useSendApiKeyLinkMutation
>;
export type SendApiKeyLinkMutationResult =
  Apollo.MutationResult<SendApiKeyLinkMutation>;
export type SendApiKeyLinkMutationOptions = Apollo.BaseMutationOptions<
  SendApiKeyLinkMutation,
  SendApiKeyLinkMutationVariables
>;
export const AddLeaseAgreementDocument = gql`
  mutation AddLeaseAgreement($input: AddLeaseAgreementInput!) {
    addLeaseAgreement(input: $input) {
      ...LeaseAgreementFields
    }
  }
  ${LeaseAgreementFieldsFragmentDoc}
`;
export type AddLeaseAgreementMutationFn = Apollo.MutationFunction<
  AddLeaseAgreementMutation,
  AddLeaseAgreementMutationVariables
>;

/**
 * __useAddLeaseAgreementMutation__
 *
 * To run a mutation, you first call `useAddLeaseAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLeaseAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLeaseAgreementMutation, { data, loading, error }] = useAddLeaseAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLeaseAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddLeaseAgreementMutation,
    AddLeaseAgreementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddLeaseAgreementMutation,
    AddLeaseAgreementMutationVariables
  >(AddLeaseAgreementDocument, options);
}
export type AddLeaseAgreementMutationHookResult = ReturnType<
  typeof useAddLeaseAgreementMutation
>;
export type AddLeaseAgreementMutationResult =
  Apollo.MutationResult<AddLeaseAgreementMutation>;
export type AddLeaseAgreementMutationOptions = Apollo.BaseMutationOptions<
  AddLeaseAgreementMutation,
  AddLeaseAgreementMutationVariables
>;
export const ClientAutocompleteDocument = gql`
  query ClientAutocomplete($key: String!, $clientType: String) {
    clientAutocomplete(key: $key, clientType: $clientType) {
      id
      value
    }
  }
`;

/**
 * __useClientAutocompleteQuery__
 *
 * To run a query within a React component, call `useClientAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientAutocompleteQuery({
 *   variables: {
 *      key: // value for 'key'
 *      clientType: // value for 'clientType'
 *   },
 * });
 */
export function useClientAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientAutocompleteQuery,
    ClientAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClientAutocompleteQuery,
    ClientAutocompleteQueryVariables
  >(ClientAutocompleteDocument, options);
}
export function useClientAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientAutocompleteQuery,
    ClientAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientAutocompleteQuery,
    ClientAutocompleteQueryVariables
  >(ClientAutocompleteDocument, options);
}
export type ClientAutocompleteQueryHookResult = ReturnType<
  typeof useClientAutocompleteQuery
>;
export type ClientAutocompleteLazyQueryHookResult = ReturnType<
  typeof useClientAutocompleteLazyQuery
>;
export type ClientAutocompleteQueryResult = Apollo.QueryResult<
  ClientAutocompleteQuery,
  ClientAutocompleteQueryVariables
>;
export const CreateEstateDocument = gql`
  mutation CreateEstate($input: EstateInput!) {
    addEstate(input: $input) {
      id
      estateDesignation
      name
    }
  }
`;
export type CreateEstateMutationFn = Apollo.MutationFunction<
  CreateEstateMutation,
  CreateEstateMutationVariables
>;

/**
 * __useCreateEstateMutation__
 *
 * To run a mutation, you first call `useCreateEstateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEstateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEstateMutation, { data, loading, error }] = useCreateEstateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEstateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEstateMutation,
    CreateEstateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEstateMutation,
    CreateEstateMutationVariables
  >(CreateEstateDocument, options);
}
export type CreateEstateMutationHookResult = ReturnType<
  typeof useCreateEstateMutation
>;
export type CreateEstateMutationResult =
  Apollo.MutationResult<CreateEstateMutation>;
export type CreateEstateMutationOptions = Apollo.BaseMutationOptions<
  CreateEstateMutation,
  CreateEstateMutationVariables
>;
export const DeleteLeaseAgreementDocument = gql`
  mutation DeleteLeaseAgreement(
    $id: String!
    $blockFromIntegrationProvider: Boolean
  ) {
    disableLeaseAgreement(
      id: $id
      blockFromIntegrationProvider: $blockFromIntegrationProvider
    ) {
      id
    }
  }
`;
export type DeleteLeaseAgreementMutationFn = Apollo.MutationFunction<
  DeleteLeaseAgreementMutation,
  DeleteLeaseAgreementMutationVariables
>;

/**
 * __useDeleteLeaseAgreementMutation__
 *
 * To run a mutation, you first call `useDeleteLeaseAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeaseAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeaseAgreementMutation, { data, loading, error }] = useDeleteLeaseAgreementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      blockFromIntegrationProvider: // value for 'blockFromIntegrationProvider'
 *   },
 * });
 */
export function useDeleteLeaseAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLeaseAgreementMutation,
    DeleteLeaseAgreementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLeaseAgreementMutation,
    DeleteLeaseAgreementMutationVariables
  >(DeleteLeaseAgreementDocument, options);
}
export type DeleteLeaseAgreementMutationHookResult = ReturnType<
  typeof useDeleteLeaseAgreementMutation
>;
export type DeleteLeaseAgreementMutationResult =
  Apollo.MutationResult<DeleteLeaseAgreementMutation>;
export type DeleteLeaseAgreementMutationOptions = Apollo.BaseMutationOptions<
  DeleteLeaseAgreementMutation,
  DeleteLeaseAgreementMutationVariables
>;
export const DisableLeaseAgreementDocument = gql`
  mutation DisableLeaseAgreement($id: String!) {
    disableLeaseAgreement(id: $id) {
      ...LeaseAgreementFields
    }
  }
  ${LeaseAgreementFieldsFragmentDoc}
`;
export type DisableLeaseAgreementMutationFn = Apollo.MutationFunction<
  DisableLeaseAgreementMutation,
  DisableLeaseAgreementMutationVariables
>;

/**
 * __useDisableLeaseAgreementMutation__
 *
 * To run a mutation, you first call `useDisableLeaseAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableLeaseAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableLeaseAgreementMutation, { data, loading, error }] = useDisableLeaseAgreementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableLeaseAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableLeaseAgreementMutation,
    DisableLeaseAgreementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisableLeaseAgreementMutation,
    DisableLeaseAgreementMutationVariables
  >(DisableLeaseAgreementDocument, options);
}
export type DisableLeaseAgreementMutationHookResult = ReturnType<
  typeof useDisableLeaseAgreementMutation
>;
export type DisableLeaseAgreementMutationResult =
  Apollo.MutationResult<DisableLeaseAgreementMutation>;
export type DisableLeaseAgreementMutationOptions = Apollo.BaseMutationOptions<
  DisableLeaseAgreementMutation,
  DisableLeaseAgreementMutationVariables
>;
export const EditLeaseAgreementDocument = gql`
  mutation EditLeaseAgreement($id: String!, $input: LeaseAgreementInput!) {
    editLeaseAgreement(id: $id, input: $input) {
      ...LeaseAgreementFields
    }
  }
  ${LeaseAgreementFieldsFragmentDoc}
`;
export type EditLeaseAgreementMutationFn = Apollo.MutationFunction<
  EditLeaseAgreementMutation,
  EditLeaseAgreementMutationVariables
>;

/**
 * __useEditLeaseAgreementMutation__
 *
 * To run a mutation, you first call `useEditLeaseAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLeaseAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLeaseAgreementMutation, { data, loading, error }] = useEditLeaseAgreementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditLeaseAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditLeaseAgreementMutation,
    EditLeaseAgreementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditLeaseAgreementMutation,
    EditLeaseAgreementMutationVariables
  >(EditLeaseAgreementDocument, options);
}
export type EditLeaseAgreementMutationHookResult = ReturnType<
  typeof useEditLeaseAgreementMutation
>;
export type EditLeaseAgreementMutationResult =
  Apollo.MutationResult<EditLeaseAgreementMutation>;
export type EditLeaseAgreementMutationOptions = Apollo.BaseMutationOptions<
  EditLeaseAgreementMutation,
  EditLeaseAgreementMutationVariables
>;
export const GenerateNearbyServicesDocument = gql`
  query GenerateNearbyServices($address: AddressInput!) {
    generateNearbyServices(address: $address) {
      bus_station {
        name
        distance
      }
      train_station {
        name
        distance
      }
      subway_station {
        name
        distance
      }
      gym {
        name
        distance
      }
      supermarket {
        name
        distance
      }
      parking {
        name
        distance
      }
    }
  }
`;

/**
 * __useGenerateNearbyServicesQuery__
 *
 * To run a query within a React component, call `useGenerateNearbyServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateNearbyServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateNearbyServicesQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useGenerateNearbyServicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GenerateNearbyServicesQuery,
    GenerateNearbyServicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GenerateNearbyServicesQuery,
    GenerateNearbyServicesQueryVariables
  >(GenerateNearbyServicesDocument, options);
}
export function useGenerateNearbyServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateNearbyServicesQuery,
    GenerateNearbyServicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GenerateNearbyServicesQuery,
    GenerateNearbyServicesQueryVariables
  >(GenerateNearbyServicesDocument, options);
}
export type GenerateNearbyServicesQueryHookResult = ReturnType<
  typeof useGenerateNearbyServicesQuery
>;
export type GenerateNearbyServicesLazyQueryHookResult = ReturnType<
  typeof useGenerateNearbyServicesLazyQuery
>;
export type GenerateNearbyServicesQueryResult = Apollo.QueryResult<
  GenerateNearbyServicesQuery,
  GenerateNearbyServicesQueryVariables
>;
export const GetLeaseAgreementEditDocument = gql`
  query GetLeaseAgreementEdit($id: String!) {
    leaseAgreement(id: $id) {
      ...LeaseAgreementFields
    }
  }
  ${LeaseAgreementFieldsFragmentDoc}
`;

/**
 * __useGetLeaseAgreementEditQuery__
 *
 * To run a query within a React component, call `useGetLeaseAgreementEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaseAgreementEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaseAgreementEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLeaseAgreementEditQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLeaseAgreementEditQuery,
    GetLeaseAgreementEditQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLeaseAgreementEditQuery,
    GetLeaseAgreementEditQueryVariables
  >(GetLeaseAgreementEditDocument, options);
}
export function useGetLeaseAgreementEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeaseAgreementEditQuery,
    GetLeaseAgreementEditQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLeaseAgreementEditQuery,
    GetLeaseAgreementEditQueryVariables
  >(GetLeaseAgreementEditDocument, options);
}
export type GetLeaseAgreementEditQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementEditQuery
>;
export type GetLeaseAgreementEditLazyQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementEditLazyQuery
>;
export type GetLeaseAgreementEditQueryResult = Apollo.QueryResult<
  GetLeaseAgreementEditQuery,
  GetLeaseAgreementEditQueryVariables
>;
export const GetLeaseAgreementViewDocument = gql`
  query GetLeaseAgreementView($id: String!) {
    leaseAgreement(id: $id) {
      ...LeaseAgreementFields
      matchingSearchRequests {
        count
        searchRequests(leaseAgreementId: $id) {
          id
        }
      }
      conversations {
        id
        participants {
          id
          name
          image {
            id
            url(
              processing: { height: 400, width: 400, crop: fill, quality: auto }
            )
          }
        }
      }
    }
  }
  ${LeaseAgreementFieldsFragmentDoc}
`;

/**
 * __useGetLeaseAgreementViewQuery__
 *
 * To run a query within a React component, call `useGetLeaseAgreementViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaseAgreementViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaseAgreementViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLeaseAgreementViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLeaseAgreementViewQuery,
    GetLeaseAgreementViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLeaseAgreementViewQuery,
    GetLeaseAgreementViewQueryVariables
  >(GetLeaseAgreementViewDocument, options);
}
export function useGetLeaseAgreementViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeaseAgreementViewQuery,
    GetLeaseAgreementViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLeaseAgreementViewQuery,
    GetLeaseAgreementViewQueryVariables
  >(GetLeaseAgreementViewDocument, options);
}
export type GetLeaseAgreementViewQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementViewQuery
>;
export type GetLeaseAgreementViewLazyQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementViewLazyQuery
>;
export type GetLeaseAgreementViewQueryResult = Apollo.QueryResult<
  GetLeaseAgreementViewQuery,
  GetLeaseAgreementViewQueryVariables
>;
export const GetProjectsByClientDocument = gql`
  query GetProjectsByClient($clientId: String!) {
    projects(filter: { clientIds: [$clientId] }) {
      id
      name
    }
  }
`;

/**
 * __useGetProjectsByClientQuery__
 *
 * To run a query within a React component, call `useGetProjectsByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsByClientQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetProjectsByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectsByClientQuery,
    GetProjectsByClientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectsByClientQuery,
    GetProjectsByClientQueryVariables
  >(GetProjectsByClientDocument, options);
}
export function useGetProjectsByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsByClientQuery,
    GetProjectsByClientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectsByClientQuery,
    GetProjectsByClientQueryVariables
  >(GetProjectsByClientDocument, options);
}
export type GetProjectsByClientQueryHookResult = ReturnType<
  typeof useGetProjectsByClientQuery
>;
export type GetProjectsByClientLazyQueryHookResult = ReturnType<
  typeof useGetProjectsByClientLazyQuery
>;
export type GetProjectsByClientQueryResult = Apollo.QueryResult<
  GetProjectsByClientQuery,
  GetProjectsByClientQueryVariables
>;
export const LandlordEstatesDocument = gql`
  query LandlordEstates($landlordId: String!, $city: String) {
    estates(filter: { landlordId: $landlordId, city: $city }) {
      estates {
        name
        id
        address {
          city
        }
      }
    }
  }
`;

/**
 * __useLandlordEstatesQuery__
 *
 * To run a query within a React component, call `useLandlordEstatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandlordEstatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandlordEstatesQuery({
 *   variables: {
 *      landlordId: // value for 'landlordId'
 *      city: // value for 'city'
 *   },
 * });
 */
export function useLandlordEstatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LandlordEstatesQuery,
    LandlordEstatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LandlordEstatesQuery, LandlordEstatesQueryVariables>(
    LandlordEstatesDocument,
    options,
  );
}
export function useLandlordEstatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LandlordEstatesQuery,
    LandlordEstatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LandlordEstatesQuery,
    LandlordEstatesQueryVariables
  >(LandlordEstatesDocument, options);
}
export type LandlordEstatesQueryHookResult = ReturnType<
  typeof useLandlordEstatesQuery
>;
export type LandlordEstatesLazyQueryHookResult = ReturnType<
  typeof useLandlordEstatesLazyQuery
>;
export type LandlordEstatesQueryResult = Apollo.QueryResult<
  LandlordEstatesQuery,
  LandlordEstatesQueryVariables
>;
export const RegionAndStreetAutocompleteDocument = gql`
  query RegionAndStreetAutocomplete($key: String!, $country: CountryEnum) {
    regionsAndStreets(key: $key, country: $country) {
      value
      id
      type
    }
  }
`;

/**
 * __useRegionAndStreetAutocompleteQuery__
 *
 * To run a query within a React component, call `useRegionAndStreetAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionAndStreetAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionAndStreetAutocompleteQuery({
 *   variables: {
 *      key: // value for 'key'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useRegionAndStreetAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegionAndStreetAutocompleteQuery,
    RegionAndStreetAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegionAndStreetAutocompleteQuery,
    RegionAndStreetAutocompleteQueryVariables
  >(RegionAndStreetAutocompleteDocument, options);
}
export function useRegionAndStreetAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegionAndStreetAutocompleteQuery,
    RegionAndStreetAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegionAndStreetAutocompleteQuery,
    RegionAndStreetAutocompleteQueryVariables
  >(RegionAndStreetAutocompleteDocument, options);
}
export type RegionAndStreetAutocompleteQueryHookResult = ReturnType<
  typeof useRegionAndStreetAutocompleteQuery
>;
export type RegionAndStreetAutocompleteLazyQueryHookResult = ReturnType<
  typeof useRegionAndStreetAutocompleteLazyQuery
>;
export type RegionAndStreetAutocompleteQueryResult = Apollo.QueryResult<
  RegionAndStreetAutocompleteQuery,
  RegionAndStreetAutocompleteQueryVariables
>;
export const RegionAutocompleteDocument = gql`
  query RegionAutocomplete($key: String!, $country: CountryEnum) {
    regions(key: $key, country: $country) {
      value
      id
    }
  }
`;

/**
 * __useRegionAutocompleteQuery__
 *
 * To run a query within a React component, call `useRegionAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionAutocompleteQuery({
 *   variables: {
 *      key: // value for 'key'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useRegionAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegionAutocompleteQuery,
    RegionAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegionAutocompleteQuery,
    RegionAutocompleteQueryVariables
  >(RegionAutocompleteDocument, options);
}
export function useRegionAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegionAutocompleteQuery,
    RegionAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegionAutocompleteQuery,
    RegionAutocompleteQueryVariables
  >(RegionAutocompleteDocument, options);
}
export type RegionAutocompleteQueryHookResult = ReturnType<
  typeof useRegionAutocompleteQuery
>;
export type RegionAutocompleteLazyQueryHookResult = ReturnType<
  typeof useRegionAutocompleteLazyQuery
>;
export type RegionAutocompleteQueryResult = Apollo.QueryResult<
  RegionAutocompleteQuery,
  RegionAutocompleteQueryVariables
>;
export const UserAutocompleteDocument = gql`
  query UserAutocomplete($key: String!) {
    userAutocomplete(key: $key) {
      id
      value
    }
  }
`;

/**
 * __useUserAutocompleteQuery__
 *
 * To run a query within a React component, call `useUserAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAutocompleteQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useUserAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAutocompleteQuery,
    UserAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserAutocompleteQuery, UserAutocompleteQueryVariables>(
    UserAutocompleteDocument,
    options,
  );
}
export function useUserAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAutocompleteQuery,
    UserAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserAutocompleteQuery,
    UserAutocompleteQueryVariables
  >(UserAutocompleteDocument, options);
}
export type UserAutocompleteQueryHookResult = ReturnType<
  typeof useUserAutocompleteQuery
>;
export type UserAutocompleteLazyQueryHookResult = ReturnType<
  typeof useUserAutocompleteLazyQuery
>;
export type UserAutocompleteQueryResult = Apollo.QueryResult<
  UserAutocompleteQuery,
  UserAutocompleteQueryVariables
>;
export const ViewLeaseAgreementDocument = gql`
  mutation ViewLeaseAgreement($leaseAgreementId: String!) {
    viewLeaseAgreement(leaseAgreementId: $leaseAgreementId)
  }
`;
export type ViewLeaseAgreementMutationFn = Apollo.MutationFunction<
  ViewLeaseAgreementMutation,
  ViewLeaseAgreementMutationVariables
>;

/**
 * __useViewLeaseAgreementMutation__
 *
 * To run a mutation, you first call `useViewLeaseAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewLeaseAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewLeaseAgreementMutation, { data, loading, error }] = useViewLeaseAgreementMutation({
 *   variables: {
 *      leaseAgreementId: // value for 'leaseAgreementId'
 *   },
 * });
 */
export function useViewLeaseAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ViewLeaseAgreementMutation,
    ViewLeaseAgreementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ViewLeaseAgreementMutation,
    ViewLeaseAgreementMutationVariables
  >(ViewLeaseAgreementDocument, options);
}
export type ViewLeaseAgreementMutationHookResult = ReturnType<
  typeof useViewLeaseAgreementMutation
>;
export type ViewLeaseAgreementMutationResult =
  Apollo.MutationResult<ViewLeaseAgreementMutation>;
export type ViewLeaseAgreementMutationOptions = Apollo.BaseMutationOptions<
  ViewLeaseAgreementMutation,
  ViewLeaseAgreementMutationVariables
>;
export const EditLeaseAgreementProspectStatusDocument = gql`
  mutation editLeaseAgreementProspectStatus(
    $id: ID!
    $status: LeaseAgreementProspectStatus!
  ) {
    editLeaseAgreementProspectStatus(id: $id, status: $status) {
      id
      status
      showContactInfo
    }
  }
`;
export type EditLeaseAgreementProspectStatusMutationFn =
  Apollo.MutationFunction<
    EditLeaseAgreementProspectStatusMutation,
    EditLeaseAgreementProspectStatusMutationVariables
  >;

/**
 * __useEditLeaseAgreementProspectStatusMutation__
 *
 * To run a mutation, you first call `useEditLeaseAgreementProspectStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLeaseAgreementProspectStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLeaseAgreementProspectStatusMutation, { data, loading, error }] = useEditLeaseAgreementProspectStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useEditLeaseAgreementProspectStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditLeaseAgreementProspectStatusMutation,
    EditLeaseAgreementProspectStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditLeaseAgreementProspectStatusMutation,
    EditLeaseAgreementProspectStatusMutationVariables
  >(EditLeaseAgreementProspectStatusDocument, options);
}
export type EditLeaseAgreementProspectStatusMutationHookResult = ReturnType<
  typeof useEditLeaseAgreementProspectStatusMutation
>;
export type EditLeaseAgreementProspectStatusMutationResult =
  Apollo.MutationResult<EditLeaseAgreementProspectStatusMutation>;
export type EditLeaseAgreementProspectStatusMutationOptions =
  Apollo.BaseMutationOptions<
    EditLeaseAgreementProspectStatusMutation,
    EditLeaseAgreementProspectStatusMutationVariables
  >;
export const GetMyLeaseAgreementProspectDocument = gql`
  query GetMyLeaseAgreementProspect($id: ID!) {
    leaseAgreementProspect(id: $id) {
      company
      created
      email
      leaseAgreement {
        ...LeaseAgreementListPreviewValues
      }
      id
      message
      showContactInfo
      phone
      status
      updated
    }
  }
  ${LeaseAgreementListPreviewValuesFragmentDoc}
`;

/**
 * __useGetMyLeaseAgreementProspectQuery__
 *
 * To run a query within a React component, call `useGetMyLeaseAgreementProspectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyLeaseAgreementProspectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyLeaseAgreementProspectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMyLeaseAgreementProspectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyLeaseAgreementProspectQuery,
    GetMyLeaseAgreementProspectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyLeaseAgreementProspectQuery,
    GetMyLeaseAgreementProspectQueryVariables
  >(GetMyLeaseAgreementProspectDocument, options);
}
export function useGetMyLeaseAgreementProspectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyLeaseAgreementProspectQuery,
    GetMyLeaseAgreementProspectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyLeaseAgreementProspectQuery,
    GetMyLeaseAgreementProspectQueryVariables
  >(GetMyLeaseAgreementProspectDocument, options);
}
export type GetMyLeaseAgreementProspectQueryHookResult = ReturnType<
  typeof useGetMyLeaseAgreementProspectQuery
>;
export type GetMyLeaseAgreementProspectLazyQueryHookResult = ReturnType<
  typeof useGetMyLeaseAgreementProspectLazyQuery
>;
export type GetMyLeaseAgreementProspectQueryResult = Apollo.QueryResult<
  GetMyLeaseAgreementProspectQuery,
  GetMyLeaseAgreementProspectQueryVariables
>;
export const GetMyLeaseAgreementProspectsDocument = gql`
  query GetMyLeaseAgreementProspects($filter: LeaseAgreementProspectFilter) {
    leaseAgreementProspects(filter: $filter) {
      count
      prospects {
        company
        created
        email
        leaseAgreement {
          ...LeaseAgreementListPreviewValues
          landlordContactPersons {
            id
            name
          }
        }
        id
        message
        showContactInfo
        phone
        status
        updated
      }
    }
  }
  ${LeaseAgreementListPreviewValuesFragmentDoc}
`;

/**
 * __useGetMyLeaseAgreementProspectsQuery__
 *
 * To run a query within a React component, call `useGetMyLeaseAgreementProspectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyLeaseAgreementProspectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyLeaseAgreementProspectsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetMyLeaseAgreementProspectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyLeaseAgreementProspectsQuery,
    GetMyLeaseAgreementProspectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyLeaseAgreementProspectsQuery,
    GetMyLeaseAgreementProspectsQueryVariables
  >(GetMyLeaseAgreementProspectsDocument, options);
}
export function useGetMyLeaseAgreementProspectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyLeaseAgreementProspectsQuery,
    GetMyLeaseAgreementProspectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyLeaseAgreementProspectsQuery,
    GetMyLeaseAgreementProspectsQueryVariables
  >(GetMyLeaseAgreementProspectsDocument, options);
}
export type GetMyLeaseAgreementProspectsQueryHookResult = ReturnType<
  typeof useGetMyLeaseAgreementProspectsQuery
>;
export type GetMyLeaseAgreementProspectsLazyQueryHookResult = ReturnType<
  typeof useGetMyLeaseAgreementProspectsLazyQuery
>;
export type GetMyLeaseAgreementProspectsQueryResult = Apollo.QueryResult<
  GetMyLeaseAgreementProspectsQuery,
  GetMyLeaseAgreementProspectsQueryVariables
>;
export const EditLeaseAgreementProspectAdminStatusDocument = gql`
  mutation EditLeaseAgreementProspectAdminStatus(
    $id: String!
    $status: LeaseAgreementProspectStatus!
  ) {
    editLeaseAgreementProspectAdminStatus(id: $id, status: $status) {
      id
      adminStatus
    }
  }
`;
export type EditLeaseAgreementProspectAdminStatusMutationFn =
  Apollo.MutationFunction<
    EditLeaseAgreementProspectAdminStatusMutation,
    EditLeaseAgreementProspectAdminStatusMutationVariables
  >;

/**
 * __useEditLeaseAgreementProspectAdminStatusMutation__
 *
 * To run a mutation, you first call `useEditLeaseAgreementProspectAdminStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLeaseAgreementProspectAdminStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLeaseAgreementProspectAdminStatusMutation, { data, loading, error }] = useEditLeaseAgreementProspectAdminStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useEditLeaseAgreementProspectAdminStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditLeaseAgreementProspectAdminStatusMutation,
    EditLeaseAgreementProspectAdminStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditLeaseAgreementProspectAdminStatusMutation,
    EditLeaseAgreementProspectAdminStatusMutationVariables
  >(EditLeaseAgreementProspectAdminStatusDocument, options);
}
export type EditLeaseAgreementProspectAdminStatusMutationHookResult =
  ReturnType<typeof useEditLeaseAgreementProspectAdminStatusMutation>;
export type EditLeaseAgreementProspectAdminStatusMutationResult =
  Apollo.MutationResult<EditLeaseAgreementProspectAdminStatusMutation>;
export type EditLeaseAgreementProspectAdminStatusMutationOptions =
  Apollo.BaseMutationOptions<
    EditLeaseAgreementProspectAdminStatusMutation,
    EditLeaseAgreementProspectAdminStatusMutationVariables
  >;
export const EditLeaseAgreementProspectInformationDocument = gql`
  mutation EditLeaseAgreementProspectInformation(
    $id: ID!
    $input: EditLeaseAgreementProspectInformationInput!
  ) {
    editLeaseAgreementProspectInformation(id: $id, input: $input) {
      id
      email
      message
    }
  }
`;
export type EditLeaseAgreementProspectInformationMutationFn =
  Apollo.MutationFunction<
    EditLeaseAgreementProspectInformationMutation,
    EditLeaseAgreementProspectInformationMutationVariables
  >;

/**
 * __useEditLeaseAgreementProspectInformationMutation__
 *
 * To run a mutation, you first call `useEditLeaseAgreementProspectInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLeaseAgreementProspectInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLeaseAgreementProspectInformationMutation, { data, loading, error }] = useEditLeaseAgreementProspectInformationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditLeaseAgreementProspectInformationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditLeaseAgreementProspectInformationMutation,
    EditLeaseAgreementProspectInformationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditLeaseAgreementProspectInformationMutation,
    EditLeaseAgreementProspectInformationMutationVariables
  >(EditLeaseAgreementProspectInformationDocument, options);
}
export type EditLeaseAgreementProspectInformationMutationHookResult =
  ReturnType<typeof useEditLeaseAgreementProspectInformationMutation>;
export type EditLeaseAgreementProspectInformationMutationResult =
  Apollo.MutationResult<EditLeaseAgreementProspectInformationMutation>;
export type EditLeaseAgreementProspectInformationMutationOptions =
  Apollo.BaseMutationOptions<
    EditLeaseAgreementProspectInformationMutation,
    EditLeaseAgreementProspectInformationMutationVariables
  >;
export const GetLeaseAgreementProspectsAdminDocument = gql`
  query GetLeaseAgreementProspectsAdmin($limit: Int) {
    unopened: leaseAgreementProspects(
      limit: $limit
      filter: { adminStatus: [unopened, opened] }
    ) {
      prospects {
        ...LeaseAgreementAdminProspectValues
      }
      count
    }
    declined: leaseAgreementProspects(
      limit: $limit
      filter: { adminStatus: [declined] }
    ) {
      prospects {
        ...LeaseAgreementAdminProspectValues
      }
      count
    }
    accepted: leaseAgreementProspects(
      limit: $limit
      filter: { adminStatus: [accepted] }
    ) {
      prospects {
        ...LeaseAgreementAdminProspectValues
      }
      count
    }
  }
  ${LeaseAgreementAdminProspectValuesFragmentDoc}
`;

/**
 * __useGetLeaseAgreementProspectsAdminQuery__
 *
 * To run a query within a React component, call `useGetLeaseAgreementProspectsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaseAgreementProspectsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaseAgreementProspectsAdminQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLeaseAgreementProspectsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLeaseAgreementProspectsAdminQuery,
    GetLeaseAgreementProspectsAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLeaseAgreementProspectsAdminQuery,
    GetLeaseAgreementProspectsAdminQueryVariables
  >(GetLeaseAgreementProspectsAdminDocument, options);
}
export function useGetLeaseAgreementProspectsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeaseAgreementProspectsAdminQuery,
    GetLeaseAgreementProspectsAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLeaseAgreementProspectsAdminQuery,
    GetLeaseAgreementProspectsAdminQueryVariables
  >(GetLeaseAgreementProspectsAdminDocument, options);
}
export type GetLeaseAgreementProspectsAdminQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementProspectsAdminQuery
>;
export type GetLeaseAgreementProspectsAdminLazyQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementProspectsAdminLazyQuery
>;
export type GetLeaseAgreementProspectsAdminQueryResult = Apollo.QueryResult<
  GetLeaseAgreementProspectsAdminQuery,
  GetLeaseAgreementProspectsAdminQueryVariables
>;
export const GetViewsDocument = gql`
  query GetViews($limit: Int!) {
    views(limit: $limit) {
      views {
        ...LeaseAgreementViewValues
      }
      count
    }
  }
  ${LeaseAgreementViewValuesFragmentDoc}
`;

/**
 * __useGetViewsQuery__
 *
 * To run a query within a React component, call `useGetViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetViewsQuery(
  baseOptions: Apollo.QueryHookOptions<GetViewsQuery, GetViewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetViewsQuery, GetViewsQueryVariables>(
    GetViewsDocument,
    options,
  );
}
export function useGetViewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetViewsQuery,
    GetViewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetViewsQuery, GetViewsQueryVariables>(
    GetViewsDocument,
    options,
  );
}
export type GetViewsQueryHookResult = ReturnType<typeof useGetViewsQuery>;
export type GetViewsLazyQueryHookResult = ReturnType<
  typeof useGetViewsLazyQuery
>;
export type GetViewsQueryResult = Apollo.QueryResult<
  GetViewsQuery,
  GetViewsQueryVariables
>;
export const GetLeaseAgreementsDocument = gql`
  query GetLeaseAgreements(
    $input: LeaseAgreementFilter
    $search: SearchProfileInput
    $limit: Int!
    $offset: Int!
  ) {
    leaseAgreements(search: $search, input: $input, limit: $limit) {
      count
      items(limit: $limit, offset: $offset) {
        ...LeaseAgreementListValues
        ...AdminLeaseAgreementListValues
      }
    }
  }
  ${LeaseAgreementListValuesFragmentDoc}
  ${AdminLeaseAgreementListValuesFragmentDoc}
`;

/**
 * __useGetLeaseAgreementsQuery__
 *
 * To run a query within a React component, call `useGetLeaseAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaseAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaseAgreementsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetLeaseAgreementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLeaseAgreementsQuery,
    GetLeaseAgreementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLeaseAgreementsQuery,
    GetLeaseAgreementsQueryVariables
  >(GetLeaseAgreementsDocument, options);
}
export function useGetLeaseAgreementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeaseAgreementsQuery,
    GetLeaseAgreementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLeaseAgreementsQuery,
    GetLeaseAgreementsQueryVariables
  >(GetLeaseAgreementsDocument, options);
}
export type GetLeaseAgreementsQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementsQuery
>;
export type GetLeaseAgreementsLazyQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementsLazyQuery
>;
export type GetLeaseAgreementsQueryResult = Apollo.QueryResult<
  GetLeaseAgreementsQuery,
  GetLeaseAgreementsQueryVariables
>;
export const GetManagementLeaseAgreementsDocument = gql`
  query GetManagementLeaseAgreements(
    $input: LeaseAgreementFilter
    $zoom: Int!
    $limit: Int!
    $offset: Int!
    $bounds: Bounds
    $personalized: Boolean
  ) {
    leaseAgreements(
      input: $input
      personalized: $personalized
      zoom: $zoom
      bounds: $bounds
    ) {
      count
      cluster {
        count
        id
        location
      }
      items(limit: $limit, offset: $offset) {
        ...LeaseAgreementManagementValues
      }
    }
  }
  ${LeaseAgreementManagementValuesFragmentDoc}
`;

/**
 * __useGetManagementLeaseAgreementsQuery__
 *
 * To run a query within a React component, call `useGetManagementLeaseAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementLeaseAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementLeaseAgreementsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      zoom: // value for 'zoom'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      bounds: // value for 'bounds'
 *      personalized: // value for 'personalized'
 *   },
 * });
 */
export function useGetManagementLeaseAgreementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementLeaseAgreementsQuery,
    GetManagementLeaseAgreementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManagementLeaseAgreementsQuery,
    GetManagementLeaseAgreementsQueryVariables
  >(GetManagementLeaseAgreementsDocument, options);
}
export function useGetManagementLeaseAgreementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementLeaseAgreementsQuery,
    GetManagementLeaseAgreementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManagementLeaseAgreementsQuery,
    GetManagementLeaseAgreementsQueryVariables
  >(GetManagementLeaseAgreementsDocument, options);
}
export type GetManagementLeaseAgreementsQueryHookResult = ReturnType<
  typeof useGetManagementLeaseAgreementsQuery
>;
export type GetManagementLeaseAgreementsLazyQueryHookResult = ReturnType<
  typeof useGetManagementLeaseAgreementsLazyQuery
>;
export type GetManagementLeaseAgreementsQueryResult = Apollo.QueryResult<
  GetManagementLeaseAgreementsQuery,
  GetManagementLeaseAgreementsQueryVariables
>;
export const GetManagementPromotedDocument = gql`
  query GetManagementPromoted {
    myPromotions {
      leaseAgreement {
        ...LeaseAgreementManagementValues
        estateId
      }
      reach
      opened
    }
  }
  ${LeaseAgreementManagementValuesFragmentDoc}
`;

/**
 * __useGetManagementPromotedQuery__
 *
 * To run a query within a React component, call `useGetManagementPromotedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementPromotedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementPromotedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManagementPromotedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManagementPromotedQuery,
    GetManagementPromotedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManagementPromotedQuery,
    GetManagementPromotedQueryVariables
  >(GetManagementPromotedDocument, options);
}
export function useGetManagementPromotedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementPromotedQuery,
    GetManagementPromotedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManagementPromotedQuery,
    GetManagementPromotedQueryVariables
  >(GetManagementPromotedDocument, options);
}
export type GetManagementPromotedQueryHookResult = ReturnType<
  typeof useGetManagementPromotedQuery
>;
export type GetManagementPromotedLazyQueryHookResult = ReturnType<
  typeof useGetManagementPromotedLazyQuery
>;
export type GetManagementPromotedQueryResult = Apollo.QueryResult<
  GetManagementPromotedQuery,
  GetManagementPromotedQueryVariables
>;
export const GetConversationFilterAutocompleteDocument = gql`
  query GetConversationFilterAutocomplete($key: String!) {
    conversationFilterAutocomplete(key: $key) {
      clients {
        label
        value
      }
      users {
        label
        value
        client
      }
    }
  }
`;

/**
 * __useGetConversationFilterAutocompleteQuery__
 *
 * To run a query within a React component, call `useGetConversationFilterAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationFilterAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationFilterAutocompleteQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetConversationFilterAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConversationFilterAutocompleteQuery,
    GetConversationFilterAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetConversationFilterAutocompleteQuery,
    GetConversationFilterAutocompleteQueryVariables
  >(GetConversationFilterAutocompleteDocument, options);
}
export function useGetConversationFilterAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConversationFilterAutocompleteQuery,
    GetConversationFilterAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConversationFilterAutocompleteQuery,
    GetConversationFilterAutocompleteQueryVariables
  >(GetConversationFilterAutocompleteDocument, options);
}
export type GetConversationFilterAutocompleteQueryHookResult = ReturnType<
  typeof useGetConversationFilterAutocompleteQuery
>;
export type GetConversationFilterAutocompleteLazyQueryHookResult = ReturnType<
  typeof useGetConversationFilterAutocompleteLazyQuery
>;
export type GetConversationFilterAutocompleteQueryResult = Apollo.QueryResult<
  GetConversationFilterAutocompleteQuery,
  GetConversationFilterAutocompleteQueryVariables
>;
export const AddNewMessageDocument = gql`
  mutation AddNewMessage($input: NewMessageInput!, $messageType: MessageType) {
    newMessage(input: $input, messageType: $messageType) {
      id
      conversationId
      conversation {
        id
        messages {
          id
          message
        }
      }
    }
  }
`;
export type AddNewMessageMutationFn = Apollo.MutationFunction<
  AddNewMessageMutation,
  AddNewMessageMutationVariables
>;

/**
 * __useAddNewMessageMutation__
 *
 * To run a mutation, you first call `useAddNewMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewMessageMutation, { data, loading, error }] = useAddNewMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *      messageType: // value for 'messageType'
 *   },
 * });
 */
export function useAddNewMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddNewMessageMutation,
    AddNewMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddNewMessageMutation,
    AddNewMessageMutationVariables
  >(AddNewMessageDocument, options);
}
export type AddNewMessageMutationHookResult = ReturnType<
  typeof useAddNewMessageMutation
>;
export type AddNewMessageMutationResult =
  Apollo.MutationResult<AddNewMessageMutation>;
export type AddNewMessageMutationOptions = Apollo.BaseMutationOptions<
  AddNewMessageMutation,
  AddNewMessageMutationVariables
>;
export const GetConversationsDocument = gql`
  query GetConversations($filter: ConversationFilter) {
    conversations(filter: $filter) {
      conversations {
        conversation {
          id
          archived
          participants {
            id
            name
            image {
              id
              url
            }
          }
          unread {
            count
            attachments
            messages
          }
          unanswered
          ...ConversationTitleValues
          leaseAgreement {
            id
            name
            image {
              id
              url
            }
            landlordContactPersons {
              id
              name
              image {
                id
                url
              }
            }
          }
        }
        senderUser {
          id
          name
          image {
            id
            url
          }
        }
        sender {
          id
          name
        }
        created
        message
      }
      count
      unread
    }
  }
  ${ConversationTitleValuesFragmentDoc}
`;

/**
 * __useGetConversationsQuery__
 *
 * To run a query within a React component, call `useGetConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetConversationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetConversationsQuery,
    GetConversationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConversationsQuery, GetConversationsQueryVariables>(
    GetConversationsDocument,
    options,
  );
}
export function useGetConversationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConversationsQuery,
    GetConversationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConversationsQuery,
    GetConversationsQueryVariables
  >(GetConversationsDocument, options);
}
export type GetConversationsQueryHookResult = ReturnType<
  typeof useGetConversationsQuery
>;
export type GetConversationsLazyQueryHookResult = ReturnType<
  typeof useGetConversationsLazyQuery
>;
export type GetConversationsQueryResult = Apollo.QueryResult<
  GetConversationsQuery,
  GetConversationsQueryVariables
>;
export const SubscribeToMessagesDocument = gql`
  subscription SubscribeToMessages {
    newMessage {
      ...MessageValues
      conversation {
        id
        unread {
          count
          messages
          attachments
        }
      }
    }
  }
  ${MessageValuesFragmentDoc}
`;

/**
 * __useSubscribeToMessagesSubscription__
 *
 * To run a query within a React component, call `useSubscribeToMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToMessagesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeToMessagesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToMessagesSubscription,
    SubscribeToMessagesSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToMessagesSubscription,
    SubscribeToMessagesSubscriptionVariables
  >(SubscribeToMessagesDocument, options);
}
export type SubscribeToMessagesSubscriptionHookResult = ReturnType<
  typeof useSubscribeToMessagesSubscription
>;
export type SubscribeToMessagesSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToMessagesSubscription>;
export const GetNewsDocument = gql`
  query GetNews($limit: Int, $skip: Int) {
    news {
      news(limit: $limit, skip: $skip) {
        id
        id
        title
        link
        published
        description
        avatar
        image
      }
      count
    }
  }
`;

/**
 * __useGetNewsQuery__
 *
 * To run a query within a React component, call `useGetNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetNewsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNewsQuery, GetNewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNewsQuery, GetNewsQueryVariables>(
    GetNewsDocument,
    options,
  );
}
export function useGetNewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNewsQuery,
    GetNewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNewsQuery, GetNewsQueryVariables>(
    GetNewsDocument,
    options,
  );
}
export type GetNewsQueryHookResult = ReturnType<typeof useGetNewsQuery>;
export type GetNewsLazyQueryHookResult = ReturnType<typeof useGetNewsLazyQuery>;
export type GetNewsQueryResult = Apollo.QueryResult<
  GetNewsQuery,
  GetNewsQueryVariables
>;
export const GetEpisodesDocument = gql`
  query GetEpisodes($id: String!, $limit: Int) {
    podcast(id: $id) {
      name
      description
      image
      author
      episodes(limit: $limit) {
        ...EpisodeValues
      }
      numberOfEpisodes
    }
  }
  ${EpisodeValuesFragmentDoc}
`;

/**
 * __useGetEpisodesQuery__
 *
 * To run a query within a React component, call `useGetEpisodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEpisodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEpisodesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetEpisodesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEpisodesQuery,
    GetEpisodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEpisodesQuery, GetEpisodesQueryVariables>(
    GetEpisodesDocument,
    options,
  );
}
export function useGetEpisodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEpisodesQuery,
    GetEpisodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEpisodesQuery, GetEpisodesQueryVariables>(
    GetEpisodesDocument,
    options,
  );
}
export type GetEpisodesQueryHookResult = ReturnType<typeof useGetEpisodesQuery>;
export type GetEpisodesLazyQueryHookResult = ReturnType<
  typeof useGetEpisodesLazyQuery
>;
export type GetEpisodesQueryResult = Apollo.QueryResult<
  GetEpisodesQuery,
  GetEpisodesQueryVariables
>;
export const GetPodcastsDocument = gql`
  query GetPodcasts {
    podcasts {
      ...PodcastValues
    }
  }
  ${PodcastValuesFragmentDoc}
`;

/**
 * __useGetPodcastsQuery__
 *
 * To run a query within a React component, call `useGetPodcastsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPodcastsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPodcastsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPodcastsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPodcastsQuery,
    GetPodcastsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPodcastsQuery, GetPodcastsQueryVariables>(
    GetPodcastsDocument,
    options,
  );
}
export function useGetPodcastsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPodcastsQuery,
    GetPodcastsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPodcastsQuery, GetPodcastsQueryVariables>(
    GetPodcastsDocument,
    options,
  );
}
export type GetPodcastsQueryHookResult = ReturnType<typeof useGetPodcastsQuery>;
export type GetPodcastsLazyQueryHookResult = ReturnType<
  typeof useGetPodcastsLazyQuery
>;
export type GetPodcastsQueryResult = Apollo.QueryResult<
  GetPodcastsQuery,
  GetPodcastsQueryVariables
>;
export const GetEventsDocument = gql`
  query GetEvents($filter: EventFilter) {
    events(filter: $filter) {
      ...EventFields
    }
  }
  ${EventFieldsFragmentDoc}
`;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEventsQuery,
    GetEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(
    GetEventsDocument,
    options,
  );
}
export function useGetEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventsQuery,
    GetEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(
    GetEventsDocument,
    options,
  );
}
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<
  typeof useGetEventsLazyQuery
>;
export type GetEventsQueryResult = Apollo.QueryResult<
  GetEventsQuery,
  GetEventsQueryVariables
>;
export const SetEventDoneDocument = gql`
  mutation SetEventDone($id: String!, $done: Boolean!) {
    setEventDone(id: $id, done: $done) {
      ...EventFields
    }
  }
  ${EventFieldsFragmentDoc}
`;
export type SetEventDoneMutationFn = Apollo.MutationFunction<
  SetEventDoneMutation,
  SetEventDoneMutationVariables
>;

/**
 * __useSetEventDoneMutation__
 *
 * To run a mutation, you first call `useSetEventDoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEventDoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEventDoneMutation, { data, loading, error }] = useSetEventDoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *      done: // value for 'done'
 *   },
 * });
 */
export function useSetEventDoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetEventDoneMutation,
    SetEventDoneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetEventDoneMutation,
    SetEventDoneMutationVariables
  >(SetEventDoneDocument, options);
}
export type SetEventDoneMutationHookResult = ReturnType<
  typeof useSetEventDoneMutation
>;
export type SetEventDoneMutationResult =
  Apollo.MutationResult<SetEventDoneMutation>;
export type SetEventDoneMutationOptions = Apollo.BaseMutationOptions<
  SetEventDoneMutation,
  SetEventDoneMutationVariables
>;
export const AddAdDocument = gql`
  mutation AddAd($input: AdInput!) {
    addAd(input: $input) {
      ...AdValues
    }
  }
  ${AdValuesFragmentDoc}
`;
export type AddAdMutationFn = Apollo.MutationFunction<
  AddAdMutation,
  AddAdMutationVariables
>;

/**
 * __useAddAdMutation__
 *
 * To run a mutation, you first call `useAddAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdMutation, { data, loading, error }] = useAddAdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAdMutation,
    AddAdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAdMutation, AddAdMutationVariables>(
    AddAdDocument,
    options,
  );
}
export type AddAdMutationHookResult = ReturnType<typeof useAddAdMutation>;
export type AddAdMutationResult = Apollo.MutationResult<AddAdMutation>;
export type AddAdMutationOptions = Apollo.BaseMutationOptions<
  AddAdMutation,
  AddAdMutationVariables
>;
export const AddPartnerDocument = gql`
  mutation addPartner($input: AddPortallyPartnerInput!) {
    addPartner(input: $input) {
      id
      slug
    }
  }
`;
export type AddPartnerMutationFn = Apollo.MutationFunction<
  AddPartnerMutation,
  AddPartnerMutationVariables
>;

/**
 * __useAddPartnerMutation__
 *
 * To run a mutation, you first call `useAddPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPartnerMutation, { data, loading, error }] = useAddPartnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPartnerMutation,
    AddPartnerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPartnerMutation, AddPartnerMutationVariables>(
    AddPartnerDocument,
    options,
  );
}
export type AddPartnerMutationHookResult = ReturnType<
  typeof useAddPartnerMutation
>;
export type AddPartnerMutationResult =
  Apollo.MutationResult<AddPartnerMutation>;
export type AddPartnerMutationOptions = Apollo.BaseMutationOptions<
  AddPartnerMutation,
  AddPartnerMutationVariables
>;
export const AddPartnerSectionDocument = gql`
  mutation AddPartnerSection(
    $slug: String!
    $sectionInput: SectionInput!
    $width: Int
    $height: Int
  ) {
    addPartnerSection(sectionInput: $sectionInput, slug: $slug) {
      id
      sections {
        title
        text
        displayResource {
          id
          size
          path
          name
          mimetype
          url(processing: { quality: max, width: $width, height: $height })
        }
      }
    }
  }
`;
export type AddPartnerSectionMutationFn = Apollo.MutationFunction<
  AddPartnerSectionMutation,
  AddPartnerSectionMutationVariables
>;

/**
 * __useAddPartnerSectionMutation__
 *
 * To run a mutation, you first call `useAddPartnerSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPartnerSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPartnerSectionMutation, { data, loading, error }] = useAddPartnerSectionMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      sectionInput: // value for 'sectionInput'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *   },
 * });
 */
export function useAddPartnerSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPartnerSectionMutation,
    AddPartnerSectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPartnerSectionMutation,
    AddPartnerSectionMutationVariables
  >(AddPartnerSectionDocument, options);
}
export type AddPartnerSectionMutationHookResult = ReturnType<
  typeof useAddPartnerSectionMutation
>;
export type AddPartnerSectionMutationResult =
  Apollo.MutationResult<AddPartnerSectionMutation>;
export type AddPartnerSectionMutationOptions = Apollo.BaseMutationOptions<
  AddPartnerSectionMutation,
  AddPartnerSectionMutationVariables
>;
export const DeleteAdDocument = gql`
  mutation DeleteAd($id: String!) {
    deleteAd(id: $id) {
      id
    }
  }
`;
export type DeleteAdMutationFn = Apollo.MutationFunction<
  DeleteAdMutation,
  DeleteAdMutationVariables
>;

/**
 * __useDeleteAdMutation__
 *
 * To run a mutation, you first call `useDeleteAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdMutation, { data, loading, error }] = useDeleteAdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAdMutation,
    DeleteAdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAdMutation, DeleteAdMutationVariables>(
    DeleteAdDocument,
    options,
  );
}
export type DeleteAdMutationHookResult = ReturnType<typeof useDeleteAdMutation>;
export type DeleteAdMutationResult = Apollo.MutationResult<DeleteAdMutation>;
export type DeleteAdMutationOptions = Apollo.BaseMutationOptions<
  DeleteAdMutation,
  DeleteAdMutationVariables
>;
export const EditAdDocument = gql`
  mutation EditAd($id: ID!, $input: AdInput!) {
    editAd(id: $id, input: $input) {
      ...AdValues
    }
  }
  ${AdValuesFragmentDoc}
`;
export type EditAdMutationFn = Apollo.MutationFunction<
  EditAdMutation,
  EditAdMutationVariables
>;

/**
 * __useEditAdMutation__
 *
 * To run a mutation, you first call `useEditAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAdMutation, { data, loading, error }] = useEditAdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditAdMutation,
    EditAdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditAdMutation, EditAdMutationVariables>(
    EditAdDocument,
    options,
  );
}
export type EditAdMutationHookResult = ReturnType<typeof useEditAdMutation>;
export type EditAdMutationResult = Apollo.MutationResult<EditAdMutation>;
export type EditAdMutationOptions = Apollo.BaseMutationOptions<
  EditAdMutation,
  EditAdMutationVariables
>;
export const EditDisplayResourceDocument = gql`
  mutation EditDisplayResource($slug: String!, $displayResourceId: ID) {
    editDisplayResource(displayResourceId: $displayResourceId, slug: $slug) {
      displayResource {
        id
        size
        path
        name
        mimetype
        url(processing: { quality: max })
      }
    }
  }
`;
export type EditDisplayResourceMutationFn = Apollo.MutationFunction<
  EditDisplayResourceMutation,
  EditDisplayResourceMutationVariables
>;

/**
 * __useEditDisplayResourceMutation__
 *
 * To run a mutation, you first call `useEditDisplayResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDisplayResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDisplayResourceMutation, { data, loading, error }] = useEditDisplayResourceMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      displayResourceId: // value for 'displayResourceId'
 *   },
 * });
 */
export function useEditDisplayResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditDisplayResourceMutation,
    EditDisplayResourceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditDisplayResourceMutation,
    EditDisplayResourceMutationVariables
  >(EditDisplayResourceDocument, options);
}
export type EditDisplayResourceMutationHookResult = ReturnType<
  typeof useEditDisplayResourceMutation
>;
export type EditDisplayResourceMutationResult =
  Apollo.MutationResult<EditDisplayResourceMutation>;
export type EditDisplayResourceMutationOptions = Apollo.BaseMutationOptions<
  EditDisplayResourceMutation,
  EditDisplayResourceMutationVariables
>;
export const GetPartnerStatisticsDocument = gql`
  query GetPartnerStatistics($slug: String!) {
    portallyPartner(slug: $slug) {
      views {
        count
        date
      }
      ads {
        placement
        websiteSearchQueryView {
          regionIds
          city
          min
          max
          usageCategories
        }
        websiteSearchQueryClick {
          regionIds
          city
          min
          max
          usageCategories
        }
        viewedBy {
          firstName
          lastName
          activeClient {
            type
          }
        }
        clickedBy {
          firstName
          lastName
          activeClient {
            type
          }
        }
        views {
          count
          date
        }
        clicks {
          count
          date
        }
      }
    }
  }
`;

/**
 * __useGetPartnerStatisticsQuery__
 *
 * To run a query within a React component, call `useGetPartnerStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerStatisticsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetPartnerStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPartnerStatisticsQuery,
    GetPartnerStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPartnerStatisticsQuery,
    GetPartnerStatisticsQueryVariables
  >(GetPartnerStatisticsDocument, options);
}
export function useGetPartnerStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartnerStatisticsQuery,
    GetPartnerStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPartnerStatisticsQuery,
    GetPartnerStatisticsQueryVariables
  >(GetPartnerStatisticsDocument, options);
}
export type GetPartnerStatisticsQueryHookResult = ReturnType<
  typeof useGetPartnerStatisticsQuery
>;
export type GetPartnerStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetPartnerStatisticsLazyQuery
>;
export type GetPartnerStatisticsQueryResult = Apollo.QueryResult<
  GetPartnerStatisticsQuery,
  GetPartnerStatisticsQueryVariables
>;
export const GetPortallyPartnerDocument = gql`
  query GetPortallyPartner($slug: String!, $width: Int!, $height: Int!) {
    portallyPartner(slug: $slug) {
      id
      active
      name
      contactPerson {
        firstName
        lastName
        professionalTitle
        email
        image {
          id
          name
          path
          url(
            processing: {
              quality: auto
              gravity: face
              width: 400
              height: 400
              crop: fill
            }
          )
          mimetype
          size
        }
      }
      client {
        id
        name
        image {
          id
          size
          path
          name
          mimetype
          url
        }
      }
      displayResource {
        id
        size
        path
        name
        mimetype
        url(processing: { width: $width, height: $height })
      }
      sections {
        id
        title
        text
        targetGroup
        displayResource {
          id
          size
          path
          name
          mimetype
          url(processing: { width: $width, height: $height })
        }
      }
      slug
      ads {
        ...AdValues
      }
    }
  }
  ${AdValuesFragmentDoc}
`;

/**
 * __useGetPortallyPartnerQuery__
 *
 * To run a query within a React component, call `useGetPortallyPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortallyPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortallyPartnerQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *   },
 * });
 */
export function useGetPortallyPartnerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPortallyPartnerQuery,
    GetPortallyPartnerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPortallyPartnerQuery,
    GetPortallyPartnerQueryVariables
  >(GetPortallyPartnerDocument, options);
}
export function useGetPortallyPartnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPortallyPartnerQuery,
    GetPortallyPartnerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPortallyPartnerQuery,
    GetPortallyPartnerQueryVariables
  >(GetPortallyPartnerDocument, options);
}
export type GetPortallyPartnerQueryHookResult = ReturnType<
  typeof useGetPortallyPartnerQuery
>;
export type GetPortallyPartnerLazyQueryHookResult = ReturnType<
  typeof useGetPortallyPartnerLazyQuery
>;
export type GetPortallyPartnerQueryResult = Apollo.QueryResult<
  GetPortallyPartnerQuery,
  GetPortallyPartnerQueryVariables
>;
export const RemovePartnerSectionDocument = gql`
  mutation RemovePartnerSection($slug: String!, $sectionId: ID!) {
    removePartnerSection(slug: $slug, sectionId: $sectionId) {
      id
    }
  }
`;
export type RemovePartnerSectionMutationFn = Apollo.MutationFunction<
  RemovePartnerSectionMutation,
  RemovePartnerSectionMutationVariables
>;

/**
 * __useRemovePartnerSectionMutation__
 *
 * To run a mutation, you first call `useRemovePartnerSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePartnerSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePartnerSectionMutation, { data, loading, error }] = useRemovePartnerSectionMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useRemovePartnerSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePartnerSectionMutation,
    RemovePartnerSectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemovePartnerSectionMutation,
    RemovePartnerSectionMutationVariables
  >(RemovePartnerSectionDocument, options);
}
export type RemovePartnerSectionMutationHookResult = ReturnType<
  typeof useRemovePartnerSectionMutation
>;
export type RemovePartnerSectionMutationResult =
  Apollo.MutationResult<RemovePartnerSectionMutation>;
export type RemovePartnerSectionMutationOptions = Apollo.BaseMutationOptions<
  RemovePartnerSectionMutation,
  RemovePartnerSectionMutationVariables
>;
export const SendMessageToPartnerDocument = gql`
  mutation SendMessageToPartner(
    $messageToPartnerInput: MessageToPartnerInput!
  ) {
    sendMessageToPartner(messageToPartnerInput: $messageToPartnerInput)
  }
`;
export type SendMessageToPartnerMutationFn = Apollo.MutationFunction<
  SendMessageToPartnerMutation,
  SendMessageToPartnerMutationVariables
>;

/**
 * __useSendMessageToPartnerMutation__
 *
 * To run a mutation, you first call `useSendMessageToPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageToPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageToPartnerMutation, { data, loading, error }] = useSendMessageToPartnerMutation({
 *   variables: {
 *      messageToPartnerInput: // value for 'messageToPartnerInput'
 *   },
 * });
 */
export function useSendMessageToPartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendMessageToPartnerMutation,
    SendMessageToPartnerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendMessageToPartnerMutation,
    SendMessageToPartnerMutationVariables
  >(SendMessageToPartnerDocument, options);
}
export type SendMessageToPartnerMutationHookResult = ReturnType<
  typeof useSendMessageToPartnerMutation
>;
export type SendMessageToPartnerMutationResult =
  Apollo.MutationResult<SendMessageToPartnerMutation>;
export type SendMessageToPartnerMutationOptions = Apollo.BaseMutationOptions<
  SendMessageToPartnerMutation,
  SendMessageToPartnerMutationVariables
>;
export const ToggleActiveAdDocument = gql`
  mutation ToggleActiveAd($id: ID!, $active: Boolean!) {
    editAd(id: $id, input: { active: $active }) {
      id
      active
    }
  }
`;
export type ToggleActiveAdMutationFn = Apollo.MutationFunction<
  ToggleActiveAdMutation,
  ToggleActiveAdMutationVariables
>;

/**
 * __useToggleActiveAdMutation__
 *
 * To run a mutation, you first call `useToggleActiveAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleActiveAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleActiveAdMutation, { data, loading, error }] = useToggleActiveAdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useToggleActiveAdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleActiveAdMutation,
    ToggleActiveAdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleActiveAdMutation,
    ToggleActiveAdMutationVariables
  >(ToggleActiveAdDocument, options);
}
export type ToggleActiveAdMutationHookResult = ReturnType<
  typeof useToggleActiveAdMutation
>;
export type ToggleActiveAdMutationResult =
  Apollo.MutationResult<ToggleActiveAdMutation>;
export type ToggleActiveAdMutationOptions = Apollo.BaseMutationOptions<
  ToggleActiveAdMutation,
  ToggleActiveAdMutationVariables
>;
export const ToggleActivePartnerDocument = gql`
  mutation toggleActivePartner($active: Boolean!, $slug: String!) {
    editPortallyPartner(slug: $slug, input: { active: $active }) {
      id
      active
    }
  }
`;
export type ToggleActivePartnerMutationFn = Apollo.MutationFunction<
  ToggleActivePartnerMutation,
  ToggleActivePartnerMutationVariables
>;

/**
 * __useToggleActivePartnerMutation__
 *
 * To run a mutation, you first call `useToggleActivePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleActivePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleActivePartnerMutation, { data, loading, error }] = useToggleActivePartnerMutation({
 *   variables: {
 *      active: // value for 'active'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useToggleActivePartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleActivePartnerMutation,
    ToggleActivePartnerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleActivePartnerMutation,
    ToggleActivePartnerMutationVariables
  >(ToggleActivePartnerDocument, options);
}
export type ToggleActivePartnerMutationHookResult = ReturnType<
  typeof useToggleActivePartnerMutation
>;
export type ToggleActivePartnerMutationResult =
  Apollo.MutationResult<ToggleActivePartnerMutation>;
export type ToggleActivePartnerMutationOptions = Apollo.BaseMutationOptions<
  ToggleActivePartnerMutation,
  ToggleActivePartnerMutationVariables
>;
export const ViewPartnerDocument = gql`
  mutation ViewPartner($slug: String!) {
    viewPartner(slug: $slug)
  }
`;
export type ViewPartnerMutationFn = Apollo.MutationFunction<
  ViewPartnerMutation,
  ViewPartnerMutationVariables
>;

/**
 * __useViewPartnerMutation__
 *
 * To run a mutation, you first call `useViewPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewPartnerMutation, { data, loading, error }] = useViewPartnerMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useViewPartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ViewPartnerMutation,
    ViewPartnerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ViewPartnerMutation, ViewPartnerMutationVariables>(
    ViewPartnerDocument,
    options,
  );
}
export type ViewPartnerMutationHookResult = ReturnType<
  typeof useViewPartnerMutation
>;
export type ViewPartnerMutationResult =
  Apollo.MutationResult<ViewPartnerMutation>;
export type ViewPartnerMutationOptions = Apollo.BaseMutationOptions<
  ViewPartnerMutation,
  ViewPartnerMutationVariables
>;
export const EditPartnerDocument = gql`
  mutation EditPartner($slug: String!, $input: EditPortallyPartnerInput!) {
    editPortallyPartner(slug: $slug, input: $input) {
      id
    }
  }
`;
export type EditPartnerMutationFn = Apollo.MutationFunction<
  EditPartnerMutation,
  EditPartnerMutationVariables
>;

/**
 * __useEditPartnerMutation__
 *
 * To run a mutation, you first call `useEditPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPartnerMutation, { data, loading, error }] = useEditPartnerMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditPartnerMutation,
    EditPartnerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditPartnerMutation, EditPartnerMutationVariables>(
    EditPartnerDocument,
    options,
  );
}
export type EditPartnerMutationHookResult = ReturnType<
  typeof useEditPartnerMutation
>;
export type EditPartnerMutationResult =
  Apollo.MutationResult<EditPartnerMutation>;
export type EditPartnerMutationOptions = Apollo.BaseMutationOptions<
  EditPartnerMutation,
  EditPartnerMutationVariables
>;
export const GetAdsDocument = gql`
  query GetAds {
    portallyAds {
      ...AdValues
    }
  }
  ${AdValuesFragmentDoc}
`;

/**
 * __useGetAdsQuery__
 *
 * To run a query within a React component, call `useGetAdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAdsQuery, GetAdsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdsQuery, GetAdsQueryVariables>(
    GetAdsDocument,
    options,
  );
}
export function useGetAdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAdsQuery, GetAdsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdsQuery, GetAdsQueryVariables>(
    GetAdsDocument,
    options,
  );
}
export type GetAdsQueryHookResult = ReturnType<typeof useGetAdsQuery>;
export type GetAdsLazyQueryHookResult = ReturnType<typeof useGetAdsLazyQuery>;
export type GetAdsQueryResult = Apollo.QueryResult<
  GetAdsQuery,
  GetAdsQueryVariables
>;
export const GetPartnerStatsDocument = gql`
  query GetPartnerStats(
    $partnerSlug: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    getPartnerStats(
      partnerSlug: $partnerSlug
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

/**
 * __useGetPartnerStatsQuery__
 *
 * To run a query within a React component, call `useGetPartnerStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerStatsQuery({
 *   variables: {
 *      partnerSlug: // value for 'partnerSlug'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetPartnerStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPartnerStatsQuery,
    GetPartnerStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartnerStatsQuery, GetPartnerStatsQueryVariables>(
    GetPartnerStatsDocument,
    options,
  );
}
export function useGetPartnerStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartnerStatsQuery,
    GetPartnerStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPartnerStatsQuery,
    GetPartnerStatsQueryVariables
  >(GetPartnerStatsDocument, options);
}
export type GetPartnerStatsQueryHookResult = ReturnType<
  typeof useGetPartnerStatsQuery
>;
export type GetPartnerStatsLazyQueryHookResult = ReturnType<
  typeof useGetPartnerStatsLazyQuery
>;
export type GetPartnerStatsQueryResult = Apollo.QueryResult<
  GetPartnerStatsQuery,
  GetPartnerStatsQueryVariables
>;
export const GetPortallyPartnersDocument = gql`
  query GetPortallyPartners {
    portallyPartners {
      id
      clientId
      name
      slug
      active
      contactPerson {
        firstName
        lastName
        email
        image {
          id
          name
          path
          url
          mimetype
          size
        }
        professionalTitle
      }
      client {
        name
      }
    }
  }
`;

/**
 * __useGetPortallyPartnersQuery__
 *
 * To run a query within a React component, call `useGetPortallyPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortallyPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortallyPartnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPortallyPartnersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPortallyPartnersQuery,
    GetPortallyPartnersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPortallyPartnersQuery,
    GetPortallyPartnersQueryVariables
  >(GetPortallyPartnersDocument, options);
}
export function useGetPortallyPartnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPortallyPartnersQuery,
    GetPortallyPartnersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPortallyPartnersQuery,
    GetPortallyPartnersQueryVariables
  >(GetPortallyPartnersDocument, options);
}
export type GetPortallyPartnersQueryHookResult = ReturnType<
  typeof useGetPortallyPartnersQuery
>;
export type GetPortallyPartnersLazyQueryHookResult = ReturnType<
  typeof useGetPortallyPartnersLazyQuery
>;
export type GetPortallyPartnersQueryResult = Apollo.QueryResult<
  GetPortallyPartnersQuery,
  GetPortallyPartnersQueryVariables
>;
export const DeletePartnerDocument = gql`
  mutation DeletePartner($id: ID!) {
    deletePartner(id: $id) {
      id
    }
  }
`;
export type DeletePartnerMutationFn = Apollo.MutationFunction<
  DeletePartnerMutation,
  DeletePartnerMutationVariables
>;

/**
 * __useDeletePartnerMutation__
 *
 * To run a mutation, you first call `useDeletePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartnerMutation, { data, loading, error }] = useDeletePartnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePartnerMutation,
    DeletePartnerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePartnerMutation,
    DeletePartnerMutationVariables
  >(DeletePartnerDocument, options);
}
export type DeletePartnerMutationHookResult = ReturnType<
  typeof useDeletePartnerMutation
>;
export type DeletePartnerMutationResult =
  Apollo.MutationResult<DeletePartnerMutation>;
export type DeletePartnerMutationOptions = Apollo.BaseMutationOptions<
  DeletePartnerMutation,
  DeletePartnerMutationVariables
>;
export const GetConversionRateDocument = gql`
  query GetConversionRate($from: Date, $to: Date) {
    conversionRate(from: $from, to: $to) {
      averageSent
      incoming
      outgoing
      removed
    }
  }
`;

/**
 * __useGetConversionRateQuery__
 *
 * To run a query within a React component, call `useGetConversionRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversionRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversionRateQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetConversionRateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetConversionRateQuery,
    GetConversionRateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetConversionRateQuery,
    GetConversionRateQueryVariables
  >(GetConversionRateDocument, options);
}
export function useGetConversionRateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConversionRateQuery,
    GetConversionRateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConversionRateQuery,
    GetConversionRateQueryVariables
  >(GetConversionRateDocument, options);
}
export type GetConversionRateQueryHookResult = ReturnType<
  typeof useGetConversionRateQuery
>;
export type GetConversionRateLazyQueryHookResult = ReturnType<
  typeof useGetConversionRateLazyQuery
>;
export type GetConversionRateQueryResult = Apollo.QueryResult<
  GetConversionRateQuery,
  GetConversionRateQueryVariables
>;
export const GetPreliminarySearchRequestsDocument = gql`
  query GetPreliminarySearchRequests(
    $unhandledLimit: Int!
    $unhandledSkip: Int!
    $removedLimit: Int!
    $removedSkip: Int!
    $unhandledFilter: SearchRequestAdminFilter!
    $removedFilter: SearchRequestAdminFilter!
  ) {
    unhandled: searchRequestsAdmin(
      limit: $unhandledLimit
      skip: $unhandledSkip
      filter: $unhandledFilter
      sort: { field: "sentDate", direction: "descend" }
    ) {
      searchRequests {
        ...SearchRequestReferencePreviewValues
      }
      count
    }
    removed: searchRequestsAdmin(
      limit: $removedLimit
      skip: $removedSkip
      filter: $removedFilter
      sort: { field: "sentDate", direction: "descend" }
    ) {
      searchRequests {
        ...SearchRequestReferencePreviewValues
      }
      count
    }
  }
  ${SearchRequestReferencePreviewValuesFragmentDoc}
`;

/**
 * __useGetPreliminarySearchRequestsQuery__
 *
 * To run a query within a React component, call `useGetPreliminarySearchRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreliminarySearchRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreliminarySearchRequestsQuery({
 *   variables: {
 *      unhandledLimit: // value for 'unhandledLimit'
 *      unhandledSkip: // value for 'unhandledSkip'
 *      removedLimit: // value for 'removedLimit'
 *      removedSkip: // value for 'removedSkip'
 *      unhandledFilter: // value for 'unhandledFilter'
 *      removedFilter: // value for 'removedFilter'
 *   },
 * });
 */
export function useGetPreliminarySearchRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPreliminarySearchRequestsQuery,
    GetPreliminarySearchRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPreliminarySearchRequestsQuery,
    GetPreliminarySearchRequestsQueryVariables
  >(GetPreliminarySearchRequestsDocument, options);
}
export function useGetPreliminarySearchRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPreliminarySearchRequestsQuery,
    GetPreliminarySearchRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPreliminarySearchRequestsQuery,
    GetPreliminarySearchRequestsQueryVariables
  >(GetPreliminarySearchRequestsDocument, options);
}
export type GetPreliminarySearchRequestsQueryHookResult = ReturnType<
  typeof useGetPreliminarySearchRequestsQuery
>;
export type GetPreliminarySearchRequestsLazyQueryHookResult = ReturnType<
  typeof useGetPreliminarySearchRequestsLazyQuery
>;
export type GetPreliminarySearchRequestsQueryResult = Apollo.QueryResult<
  GetPreliminarySearchRequestsQuery,
  GetPreliminarySearchRequestsQueryVariables
>;
export const RemoveSearchRequestReferenceDocument = gql`
  mutation RemoveSearchRequestReference($id: String!) {
    changeSearchRequestStatus(id: $id, status: removed) {
      id
      status
    }
  }
`;
export type RemoveSearchRequestReferenceMutationFn = Apollo.MutationFunction<
  RemoveSearchRequestReferenceMutation,
  RemoveSearchRequestReferenceMutationVariables
>;

/**
 * __useRemoveSearchRequestReferenceMutation__
 *
 * To run a mutation, you first call `useRemoveSearchRequestReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSearchRequestReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSearchRequestReferenceMutation, { data, loading, error }] = useRemoveSearchRequestReferenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSearchRequestReferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSearchRequestReferenceMutation,
    RemoveSearchRequestReferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveSearchRequestReferenceMutation,
    RemoveSearchRequestReferenceMutationVariables
  >(RemoveSearchRequestReferenceDocument, options);
}
export type RemoveSearchRequestReferenceMutationHookResult = ReturnType<
  typeof useRemoveSearchRequestReferenceMutation
>;
export type RemoveSearchRequestReferenceMutationResult =
  Apollo.MutationResult<RemoveSearchRequestReferenceMutation>;
export type RemoveSearchRequestReferenceMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveSearchRequestReferenceMutation,
    RemoveSearchRequestReferenceMutationVariables
  >;
export const ResetSearchRequestReferenceDocument = gql`
  mutation ResetSearchRequestReference($id: String!) {
    changeSearchRequestStatus(id: $id, status: pending) {
      id
      status
    }
  }
`;
export type ResetSearchRequestReferenceMutationFn = Apollo.MutationFunction<
  ResetSearchRequestReferenceMutation,
  ResetSearchRequestReferenceMutationVariables
>;

/**
 * __useResetSearchRequestReferenceMutation__
 *
 * To run a mutation, you first call `useResetSearchRequestReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetSearchRequestReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetSearchRequestReferenceMutation, { data, loading, error }] = useResetSearchRequestReferenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResetSearchRequestReferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetSearchRequestReferenceMutation,
    ResetSearchRequestReferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetSearchRequestReferenceMutation,
    ResetSearchRequestReferenceMutationVariables
  >(ResetSearchRequestReferenceDocument, options);
}
export type ResetSearchRequestReferenceMutationHookResult = ReturnType<
  typeof useResetSearchRequestReferenceMutation
>;
export type ResetSearchRequestReferenceMutationResult =
  Apollo.MutationResult<ResetSearchRequestReferenceMutation>;
export type ResetSearchRequestReferenceMutationOptions =
  Apollo.BaseMutationOptions<
    ResetSearchRequestReferenceMutation,
    ResetSearchRequestReferenceMutationVariables
  >;
export const SendImproveSearchMailDocument = gql`
  mutation SendImproveSearchMail($input: MoreInformationMail!) {
    sendImproveSearchMail(input: $input) {
      id
      improveSearchMailSent
    }
  }
`;
export type SendImproveSearchMailMutationFn = Apollo.MutationFunction<
  SendImproveSearchMailMutation,
  SendImproveSearchMailMutationVariables
>;

/**
 * __useSendImproveSearchMailMutation__
 *
 * To run a mutation, you first call `useSendImproveSearchMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendImproveSearchMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendImproveSearchMailMutation, { data, loading, error }] = useSendImproveSearchMailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendImproveSearchMailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendImproveSearchMailMutation,
    SendImproveSearchMailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendImproveSearchMailMutation,
    SendImproveSearchMailMutationVariables
  >(SendImproveSearchMailDocument, options);
}
export type SendImproveSearchMailMutationHookResult = ReturnType<
  typeof useSendImproveSearchMailMutation
>;
export type SendImproveSearchMailMutationResult =
  Apollo.MutationResult<SendImproveSearchMailMutation>;
export type SendImproveSearchMailMutationOptions = Apollo.BaseMutationOptions<
  SendImproveSearchMailMutation,
  SendImproveSearchMailMutationVariables
>;
export const SendPassiveSearchRequestDocument = gql`
  mutation SendPassiveSearchRequest($id: String!) {
    changeSearchRequestStatus(id: $id, status: passive) {
      id
      status
    }
  }
`;
export type SendPassiveSearchRequestMutationFn = Apollo.MutationFunction<
  SendPassiveSearchRequestMutation,
  SendPassiveSearchRequestMutationVariables
>;

/**
 * __useSendPassiveSearchRequestMutation__
 *
 * To run a mutation, you first call `useSendPassiveSearchRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPassiveSearchRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPassiveSearchRequestMutation, { data, loading, error }] = useSendPassiveSearchRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendPassiveSearchRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPassiveSearchRequestMutation,
    SendPassiveSearchRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendPassiveSearchRequestMutation,
    SendPassiveSearchRequestMutationVariables
  >(SendPassiveSearchRequestDocument, options);
}
export type SendPassiveSearchRequestMutationHookResult = ReturnType<
  typeof useSendPassiveSearchRequestMutation
>;
export type SendPassiveSearchRequestMutationResult =
  Apollo.MutationResult<SendPassiveSearchRequestMutation>;
export type SendPassiveSearchRequestMutationOptions =
  Apollo.BaseMutationOptions<
    SendPassiveSearchRequestMutation,
    SendPassiveSearchRequestMutationVariables
  >;
export const SendSearchRequestDocument = gql`
  mutation SendSearchRequest(
    $id: String!
    $sendInput: SendSearchRequestInput!
  ) {
    sendSearchRequest(id: $id, sendInput: $sendInput) {
      id
    }
  }
`;
export type SendSearchRequestMutationFn = Apollo.MutationFunction<
  SendSearchRequestMutation,
  SendSearchRequestMutationVariables
>;

/**
 * __useSendSearchRequestMutation__
 *
 * To run a mutation, you first call `useSendSearchRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSearchRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSearchRequestMutation, { data, loading, error }] = useSendSearchRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sendInput: // value for 'sendInput'
 *   },
 * });
 */
export function useSendSearchRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendSearchRequestMutation,
    SendSearchRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendSearchRequestMutation,
    SendSearchRequestMutationVariables
  >(SendSearchRequestDocument, options);
}
export type SendSearchRequestMutationHookResult = ReturnType<
  typeof useSendSearchRequestMutation
>;
export type SendSearchRequestMutationResult =
  Apollo.MutationResult<SendSearchRequestMutation>;
export type SendSearchRequestMutationOptions = Apollo.BaseMutationOptions<
  SendSearchRequestMutation,
  SendSearchRequestMutationVariables
>;
export const GetProjectsDocument = gql`
  query GetProjects {
    projects {
      id
      name
      landlords {
        id
        name
      }
      leaseAgreements {
        id
      }
    }
  }
`;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectsQuery,
    GetProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
    options,
  );
}
export function useGetProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsQuery,
    GetProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
    options,
  );
}
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<
  typeof useGetProjectsLazyQuery
>;
export type GetProjectsQueryResult = Apollo.QueryResult<
  GetProjectsQuery,
  GetProjectsQueryVariables
>;
export const AddProjectDocument = gql`
  mutation AddProject($input: ProjectInput!) {
    addProject(input: $input) {
      id
      landlordIds
      leaseAgreements {
        id
      }
    }
  }
`;
export type AddProjectMutationFn = Apollo.MutationFunction<
  AddProjectMutation,
  AddProjectMutationVariables
>;

/**
 * __useAddProjectMutation__
 *
 * To run a mutation, you first call `useAddProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectMutation, { data, loading, error }] = useAddProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProjectMutation,
    AddProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddProjectMutation, AddProjectMutationVariables>(
    AddProjectDocument,
    options,
  );
}
export type AddProjectMutationHookResult = ReturnType<
  typeof useAddProjectMutation
>;
export type AddProjectMutationResult =
  Apollo.MutationResult<AddProjectMutation>;
export type AddProjectMutationOptions = Apollo.BaseMutationOptions<
  AddProjectMutation,
  AddProjectMutationVariables
>;
export const EditProjectDocument = gql`
  mutation EditProject($id: ID!, $input: ProjectInput!) {
    editProject(id: $id, input: $input) {
      id
      landlordIds
      leaseAgreements {
        id
      }
    }
  }
`;
export type EditProjectMutationFn = Apollo.MutationFunction<
  EditProjectMutation,
  EditProjectMutationVariables
>;

/**
 * __useEditProjectMutation__
 *
 * To run a mutation, you first call `useEditProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectMutation, { data, loading, error }] = useEditProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditProjectMutation,
    EditProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditProjectMutation, EditProjectMutationVariables>(
    EditProjectDocument,
    options,
  );
}
export type EditProjectMutationHookResult = ReturnType<
  typeof useEditProjectMutation
>;
export type EditProjectMutationResult =
  Apollo.MutationResult<EditProjectMutation>;
export type EditProjectMutationOptions = Apollo.BaseMutationOptions<
  EditProjectMutation,
  EditProjectMutationVariables
>;
export const GetProspectsDocument = gql`
  query GetProspects(
    $clientId: String
    $offset: Int
    $limit: Int
    $startDate: Date
    $endDate: Date
    $clientManagerId: [String!]
    $division: [String!]
    $step: [Step!]
  ) {
    prospects(
      clientId: $clientId
      clientManagerId: $clientManagerId
      offset: $offset
      limit: $limit
      startDate: $startDate
      endDate: $endDate
      division: $division
      step: $step
    ) {
      prospectData {
        prospects {
          ...ProspectValues
        }
      }
      overviewData {
        label
        value
      }
    }
  }
  ${ProspectValuesFragmentDoc}
`;

/**
 * __useGetProspectsQuery__
 *
 * To run a query within a React component, call `useGetProspectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProspectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProspectsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      clientManagerId: // value for 'clientManagerId'
 *      division: // value for 'division'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useGetProspectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProspectsQuery,
    GetProspectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProspectsQuery, GetProspectsQueryVariables>(
    GetProspectsDocument,
    options,
  );
}
export function useGetProspectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProspectsQuery,
    GetProspectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProspectsQuery, GetProspectsQueryVariables>(
    GetProspectsDocument,
    options,
  );
}
export type GetProspectsQueryHookResult = ReturnType<
  typeof useGetProspectsQuery
>;
export type GetProspectsLazyQueryHookResult = ReturnType<
  typeof useGetProspectsLazyQuery
>;
export type GetProspectsQueryResult = Apollo.QueryResult<
  GetProspectsQuery,
  GetProspectsQueryVariables
>;
export const GetAveragesTimeInStepDataDocument = gql`
  query GetAveragesTimeInStepData(
    $period: String
    $limit: Int
    $startDate: Date
    $endDate: Date
  ) {
    prospects {
      averagesData(period: $period, limit: $limit) {
        title
        labels
        values
      }
      timeAverageInStepData(startDate: $startDate, endDate: $endDate) {
        label
        value
      }
      timeAverageToOrderData(startDate: $startDate, endDate: $endDate) {
        leadToOrder
        bookedMeetingToOrder
      }
    }
  }
`;

/**
 * __useGetAveragesTimeInStepDataQuery__
 *
 * To run a query within a React component, call `useGetAveragesTimeInStepDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAveragesTimeInStepDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAveragesTimeInStepDataQuery({
 *   variables: {
 *      period: // value for 'period'
 *      limit: // value for 'limit'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetAveragesTimeInStepDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAveragesTimeInStepDataQuery,
    GetAveragesTimeInStepDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAveragesTimeInStepDataQuery,
    GetAveragesTimeInStepDataQueryVariables
  >(GetAveragesTimeInStepDataDocument, options);
}
export function useGetAveragesTimeInStepDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAveragesTimeInStepDataQuery,
    GetAveragesTimeInStepDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAveragesTimeInStepDataQuery,
    GetAveragesTimeInStepDataQueryVariables
  >(GetAveragesTimeInStepDataDocument, options);
}
export type GetAveragesTimeInStepDataQueryHookResult = ReturnType<
  typeof useGetAveragesTimeInStepDataQuery
>;
export type GetAveragesTimeInStepDataLazyQueryHookResult = ReturnType<
  typeof useGetAveragesTimeInStepDataLazyQuery
>;
export type GetAveragesTimeInStepDataQueryResult = Apollo.QueryResult<
  GetAveragesTimeInStepDataQuery,
  GetAveragesTimeInStepDataQueryVariables
>;
export const GetDivisionBarDocument = gql`
  query GetDivisionBar(
    $clientId: String
    $offset: Int
    $limit: Int
    $startDate: Date
    $endDate: Date
    $clientManagerId: [String!]
    $division: [String!]
    $step: [Step!]
  ) {
    prospects(
      clientId: $clientId
      clientManagerId: $clientManagerId
      offset: $offset
      limit: $limit
      startDate: $startDate
      endDate: $endDate
      division: $division
      step: $step
    ) {
      prospectData {
        prospects {
          ...ProspectValues
        }
      }
      divisionData {
        items {
          name
          potentialValue
          weightedValue
        }
        title
      }
    }
  }
  ${ProspectValuesFragmentDoc}
`;

/**
 * __useGetDivisionBarQuery__
 *
 * To run a query within a React component, call `useGetDivisionBarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDivisionBarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDivisionBarQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      clientManagerId: // value for 'clientManagerId'
 *      division: // value for 'division'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useGetDivisionBarQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDivisionBarQuery,
    GetDivisionBarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDivisionBarQuery, GetDivisionBarQueryVariables>(
    GetDivisionBarDocument,
    options,
  );
}
export function useGetDivisionBarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDivisionBarQuery,
    GetDivisionBarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDivisionBarQuery, GetDivisionBarQueryVariables>(
    GetDivisionBarDocument,
    options,
  );
}
export type GetDivisionBarQueryHookResult = ReturnType<
  typeof useGetDivisionBarQuery
>;
export type GetDivisionBarLazyQueryHookResult = ReturnType<
  typeof useGetDivisionBarLazyQuery
>;
export type GetDivisionBarQueryResult = Apollo.QueryResult<
  GetDivisionBarQuery,
  GetDivisionBarQueryVariables
>;
export const GetProcessBarDocument = gql`
  query GetProcessBar(
    $clientId: String
    $offset: Int
    $limit: Int
    $startDate: Date
    $endDate: Date
    $clientManagerId: [String!]
    $division: [String!]
    $step: [Step!]
    $weightedValues: Boolean
    $withCrossBorder: Boolean
    $onlyCrossBorder: Boolean
  ) {
    prospects(
      clientId: $clientId
      clientManagerId: $clientManagerId
      offset: $offset
      limit: $limit
      startDate: $startDate
      endDate: $endDate
      division: $division
      step: $step
    ) {
      prospectData {
        prospects {
          ...ProspectValues
        }
      }
      processValuesData(
        weightedValues: $weightedValues
        withCrossBorder: $withCrossBorder
        onlyCrossBorder: $onlyCrossBorder
      ) {
        processValuesData {
          label
          value
          percentageOfTotal
        }
        totalValue
      }
    }
  }
  ${ProspectValuesFragmentDoc}
`;

/**
 * __useGetProcessBarQuery__
 *
 * To run a query within a React component, call `useGetProcessBarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProcessBarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProcessBarQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      clientManagerId: // value for 'clientManagerId'
 *      division: // value for 'division'
 *      step: // value for 'step'
 *      weightedValues: // value for 'weightedValues'
 *      withCrossBorder: // value for 'withCrossBorder'
 *      onlyCrossBorder: // value for 'onlyCrossBorder'
 *   },
 * });
 */
export function useGetProcessBarQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProcessBarQuery,
    GetProcessBarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProcessBarQuery, GetProcessBarQueryVariables>(
    GetProcessBarDocument,
    options,
  );
}
export function useGetProcessBarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProcessBarQuery,
    GetProcessBarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProcessBarQuery, GetProcessBarQueryVariables>(
    GetProcessBarDocument,
    options,
  );
}
export type GetProcessBarQueryHookResult = ReturnType<
  typeof useGetProcessBarQuery
>;
export type GetProcessBarLazyQueryHookResult = ReturnType<
  typeof useGetProcessBarLazyQuery
>;
export type GetProcessBarQueryResult = Apollo.QueryResult<
  GetProcessBarQuery,
  GetProcessBarQueryVariables
>;
export const GetProcessOverTimeDocument = gql`
  query GetProcessOverTime($period: String, $limit: Int) {
    prospects {
      processData(period: $period, limit: $limit) {
        labels
        values
        title
      }
    }
  }
`;

/**
 * __useGetProcessOverTimeQuery__
 *
 * To run a query within a React component, call `useGetProcessOverTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProcessOverTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProcessOverTimeQuery({
 *   variables: {
 *      period: // value for 'period'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetProcessOverTimeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProcessOverTimeQuery,
    GetProcessOverTimeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProcessOverTimeQuery,
    GetProcessOverTimeQueryVariables
  >(GetProcessOverTimeDocument, options);
}
export function useGetProcessOverTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProcessOverTimeQuery,
    GetProcessOverTimeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProcessOverTimeQuery,
    GetProcessOverTimeQueryVariables
  >(GetProcessOverTimeDocument, options);
}
export type GetProcessOverTimeQueryHookResult = ReturnType<
  typeof useGetProcessOverTimeQuery
>;
export type GetProcessOverTimeLazyQueryHookResult = ReturnType<
  typeof useGetProcessOverTimeLazyQuery
>;
export type GetProcessOverTimeQueryResult = Apollo.QueryResult<
  GetProcessOverTimeQuery,
  GetProcessOverTimeQueryVariables
>;
export const GetProspectCsvDocument = gql`
  query GetProspectCSV(
    $prospects: [ProspectCSVInput!]!
    $probability: Probability!
  ) {
    getProspectCsv(prospects: $prospects, probability: $probability)
  }
`;

/**
 * __useGetProspectCsvQuery__
 *
 * To run a query within a React component, call `useGetProspectCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProspectCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProspectCsvQuery({
 *   variables: {
 *      prospects: // value for 'prospects'
 *      probability: // value for 'probability'
 *   },
 * });
 */
export function useGetProspectCsvQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProspectCsvQuery,
    GetProspectCsvQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProspectCsvQuery, GetProspectCsvQueryVariables>(
    GetProspectCsvDocument,
    options,
  );
}
export function useGetProspectCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProspectCsvQuery,
    GetProspectCsvQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProspectCsvQuery, GetProspectCsvQueryVariables>(
    GetProspectCsvDocument,
    options,
  );
}
export type GetProspectCsvQueryHookResult = ReturnType<
  typeof useGetProspectCsvQuery
>;
export type GetProspectCsvLazyQueryHookResult = ReturnType<
  typeof useGetProspectCsvLazyQuery
>;
export type GetProspectCsvQueryResult = Apollo.QueryResult<
  GetProspectCsvQuery,
  GetProspectCsvQueryVariables
>;
export const GetProspectListDocument = gql`
  query GetProspectList(
    $clientId: String
    $clientManagerId: [String!]
    $offset: Int
    $limit: Int
    $startDate: Date
    $endDate: Date
    $division: [String!]
    $step: [Step!]
    $source: [String!]
  ) {
    prospects(
      clientId: $clientId
      clientManagerId: $clientManagerId
      offset: $offset
      limit: $limit
      startDate: $startDate
      endDate: $endDate
      division: $division
      source: $source
      step: $step
    ) {
      prospectData {
        prospects {
          ...ProspectValues
        }
      }
    }
  }
  ${ProspectValuesFragmentDoc}
`;

/**
 * __useGetProspectListQuery__
 *
 * To run a query within a React component, call `useGetProspectListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProspectListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProspectListQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      clientManagerId: // value for 'clientManagerId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      division: // value for 'division'
 *      step: // value for 'step'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useGetProspectListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProspectListQuery,
    GetProspectListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProspectListQuery, GetProspectListQueryVariables>(
    GetProspectListDocument,
    options,
  );
}
export function useGetProspectListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProspectListQuery,
    GetProspectListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProspectListQuery,
    GetProspectListQueryVariables
  >(GetProspectListDocument, options);
}
export type GetProspectListQueryHookResult = ReturnType<
  typeof useGetProspectListQuery
>;
export type GetProspectListLazyQueryHookResult = ReturnType<
  typeof useGetProspectListLazyQuery
>;
export type GetProspectListQueryResult = Apollo.QueryResult<
  GetProspectListQuery,
  GetProspectListQueryVariables
>;
export const GetProspectStockCountDataDocument = gql`
  query GetProspectStockCountData($period: String, $limit: Int) {
    prospects {
      prospectStockCountData(period: $period, limit: $limit) {
        title
        labels
        values
      }
    }
  }
`;

/**
 * __useGetProspectStockCountDataQuery__
 *
 * To run a query within a React component, call `useGetProspectStockCountDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProspectStockCountDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProspectStockCountDataQuery({
 *   variables: {
 *      period: // value for 'period'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetProspectStockCountDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProspectStockCountDataQuery,
    GetProspectStockCountDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProspectStockCountDataQuery,
    GetProspectStockCountDataQueryVariables
  >(GetProspectStockCountDataDocument, options);
}
export function useGetProspectStockCountDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProspectStockCountDataQuery,
    GetProspectStockCountDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProspectStockCountDataQuery,
    GetProspectStockCountDataQueryVariables
  >(GetProspectStockCountDataDocument, options);
}
export type GetProspectStockCountDataQueryHookResult = ReturnType<
  typeof useGetProspectStockCountDataQuery
>;
export type GetProspectStockCountDataLazyQueryHookResult = ReturnType<
  typeof useGetProspectStockCountDataLazyQuery
>;
export type GetProspectStockCountDataQueryResult = Apollo.QueryResult<
  GetProspectStockCountDataQuery,
  GetProspectStockCountDataQueryVariables
>;
export const GetProspectStockDataDocument = gql`
  query GetProspectStockData(
    $period: String
    $weightedValues: Boolean
    $withCrossBorder: Boolean
    $limit: Int
  ) {
    prospects {
      prospectStockData(
        period: $period
        limit: $limit
        weightedValues: $weightedValues
        withCrossBorder: $withCrossBorder
      ) {
        title
        labels
        values
      }
    }
  }
`;

/**
 * __useGetProspectStockDataQuery__
 *
 * To run a query within a React component, call `useGetProspectStockDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProspectStockDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProspectStockDataQuery({
 *   variables: {
 *      period: // value for 'period'
 *      weightedValues: // value for 'weightedValues'
 *      withCrossBorder: // value for 'withCrossBorder'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetProspectStockDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProspectStockDataQuery,
    GetProspectStockDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProspectStockDataQuery,
    GetProspectStockDataQueryVariables
  >(GetProspectStockDataDocument, options);
}
export function useGetProspectStockDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProspectStockDataQuery,
    GetProspectStockDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProspectStockDataQuery,
    GetProspectStockDataQueryVariables
  >(GetProspectStockDataDocument, options);
}
export type GetProspectStockDataQueryHookResult = ReturnType<
  typeof useGetProspectStockDataQuery
>;
export type GetProspectStockDataLazyQueryHookResult = ReturnType<
  typeof useGetProspectStockDataLazyQuery
>;
export type GetProspectStockDataQueryResult = Apollo.QueryResult<
  GetProspectStockDataQuery,
  GetProspectStockDataQueryVariables
>;
export const GetProspectStockDataOldDocument = gql`
  query GetProspectStockDataOld(
    $period: String
    $weightedValues: Boolean
    $limit: Int
  ) {
    prospects {
      prospectStockDataOld(
        limit: $limit
        weightedValues: $weightedValues
        period: $period
      ) {
        title
        labels
        values
      }
    }
  }
`;

/**
 * __useGetProspectStockDataOldQuery__
 *
 * To run a query within a React component, call `useGetProspectStockDataOldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProspectStockDataOldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProspectStockDataOldQuery({
 *   variables: {
 *      period: // value for 'period'
 *      weightedValues: // value for 'weightedValues'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetProspectStockDataOldQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProspectStockDataOldQuery,
    GetProspectStockDataOldQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProspectStockDataOldQuery,
    GetProspectStockDataOldQueryVariables
  >(GetProspectStockDataOldDocument, options);
}
export function useGetProspectStockDataOldLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProspectStockDataOldQuery,
    GetProspectStockDataOldQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProspectStockDataOldQuery,
    GetProspectStockDataOldQueryVariables
  >(GetProspectStockDataOldDocument, options);
}
export type GetProspectStockDataOldQueryHookResult = ReturnType<
  typeof useGetProspectStockDataOldQuery
>;
export type GetProspectStockDataOldLazyQueryHookResult = ReturnType<
  typeof useGetProspectStockDataOldLazyQuery
>;
export type GetProspectStockDataOldQueryResult = Apollo.QueryResult<
  GetProspectStockDataOldQuery,
  GetProspectStockDataOldQueryVariables
>;
export const GetReasonForLostOrderDataDocument = gql`
  query GetReasonForLostOrderData {
    reasonForLostOrderData {
      label
      value
      percentageOfTotal
    }
  }
`;

/**
 * __useGetReasonForLostOrderDataQuery__
 *
 * To run a query within a React component, call `useGetReasonForLostOrderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReasonForLostOrderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReasonForLostOrderDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReasonForLostOrderDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetReasonForLostOrderDataQuery,
    GetReasonForLostOrderDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetReasonForLostOrderDataQuery,
    GetReasonForLostOrderDataQueryVariables
  >(GetReasonForLostOrderDataDocument, options);
}
export function useGetReasonForLostOrderDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReasonForLostOrderDataQuery,
    GetReasonForLostOrderDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetReasonForLostOrderDataQuery,
    GetReasonForLostOrderDataQueryVariables
  >(GetReasonForLostOrderDataDocument, options);
}
export type GetReasonForLostOrderDataQueryHookResult = ReturnType<
  typeof useGetReasonForLostOrderDataQuery
>;
export type GetReasonForLostOrderDataLazyQueryHookResult = ReturnType<
  typeof useGetReasonForLostOrderDataLazyQuery
>;
export type GetReasonForLostOrderDataQueryResult = Apollo.QueryResult<
  GetReasonForLostOrderDataQuery,
  GetReasonForLostOrderDataQueryVariables
>;
export const GetReasonForOrderDataDocument = gql`
  query GetReasonForOrderData {
    reasonForOrderData {
      label
      value
      percentageOfTotal
    }
  }
`;

/**
 * __useGetReasonForOrderDataQuery__
 *
 * To run a query within a React component, call `useGetReasonForOrderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReasonForOrderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReasonForOrderDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReasonForOrderDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetReasonForOrderDataQuery,
    GetReasonForOrderDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetReasonForOrderDataQuery,
    GetReasonForOrderDataQueryVariables
  >(GetReasonForOrderDataDocument, options);
}
export function useGetReasonForOrderDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReasonForOrderDataQuery,
    GetReasonForOrderDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetReasonForOrderDataQuery,
    GetReasonForOrderDataQueryVariables
  >(GetReasonForOrderDataDocument, options);
}
export type GetReasonForOrderDataQueryHookResult = ReturnType<
  typeof useGetReasonForOrderDataQuery
>;
export type GetReasonForOrderDataLazyQueryHookResult = ReturnType<
  typeof useGetReasonForOrderDataLazyQuery
>;
export type GetReasonForOrderDataQueryResult = Apollo.QueryResult<
  GetReasonForOrderDataQuery,
  GetReasonForOrderDataQueryVariables
>;
export const AddRegionDocument = gql`
  mutation AddRegion($input: RegionsInput!) {
    addRegion(input: $input) {
      ...RegionValues
    }
  }
  ${RegionValuesFragmentDoc}
`;
export type AddRegionMutationFn = Apollo.MutationFunction<
  AddRegionMutation,
  AddRegionMutationVariables
>;

/**
 * __useAddRegionMutation__
 *
 * To run a mutation, you first call `useAddRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRegionMutation, { data, loading, error }] = useAddRegionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRegionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRegionMutation,
    AddRegionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddRegionMutation, AddRegionMutationVariables>(
    AddRegionDocument,
    options,
  );
}
export type AddRegionMutationHookResult = ReturnType<
  typeof useAddRegionMutation
>;
export type AddRegionMutationResult = Apollo.MutationResult<AddRegionMutation>;
export type AddRegionMutationOptions = Apollo.BaseMutationOptions<
  AddRegionMutation,
  AddRegionMutationVariables
>;
export const AddRegionInformationDocument = gql`
  mutation AddRegionInformation(
    $regionId: String!
    $information: RegionInformationInput!
  ) {
    addRegionInformation(regionId: $regionId, information: $information) {
      ...RegionValues
    }
  }
  ${RegionValuesFragmentDoc}
`;
export type AddRegionInformationMutationFn = Apollo.MutationFunction<
  AddRegionInformationMutation,
  AddRegionInformationMutationVariables
>;

/**
 * __useAddRegionInformationMutation__
 *
 * To run a mutation, you first call `useAddRegionInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRegionInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRegionInformationMutation, { data, loading, error }] = useAddRegionInformationMutation({
 *   variables: {
 *      regionId: // value for 'regionId'
 *      information: // value for 'information'
 *   },
 * });
 */
export function useAddRegionInformationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRegionInformationMutation,
    AddRegionInformationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddRegionInformationMutation,
    AddRegionInformationMutationVariables
  >(AddRegionInformationDocument, options);
}
export type AddRegionInformationMutationHookResult = ReturnType<
  typeof useAddRegionInformationMutation
>;
export type AddRegionInformationMutationResult =
  Apollo.MutationResult<AddRegionInformationMutation>;
export type AddRegionInformationMutationOptions = Apollo.BaseMutationOptions<
  AddRegionInformationMutation,
  AddRegionInformationMutationVariables
>;
export const DeleteRegionDocument = gql`
  mutation DeleteRegion($id: String!) {
    deleteRegion(id: $id) {
      id
      name
    }
  }
`;
export type DeleteRegionMutationFn = Apollo.MutationFunction<
  DeleteRegionMutation,
  DeleteRegionMutationVariables
>;

/**
 * __useDeleteRegionMutation__
 *
 * To run a mutation, you first call `useDeleteRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegionMutation, { data, loading, error }] = useDeleteRegionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRegionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRegionMutation,
    DeleteRegionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRegionMutation,
    DeleteRegionMutationVariables
  >(DeleteRegionDocument, options);
}
export type DeleteRegionMutationHookResult = ReturnType<
  typeof useDeleteRegionMutation
>;
export type DeleteRegionMutationResult =
  Apollo.MutationResult<DeleteRegionMutation>;
export type DeleteRegionMutationOptions = Apollo.BaseMutationOptions<
  DeleteRegionMutation,
  DeleteRegionMutationVariables
>;
export const DeleteRegionInformationDocument = gql`
  mutation DeleteRegionInformation($informationId: String!) {
    deleteRegionInformation(informationId: $informationId) {
      ...RegionValues
    }
  }
  ${RegionValuesFragmentDoc}
`;
export type DeleteRegionInformationMutationFn = Apollo.MutationFunction<
  DeleteRegionInformationMutation,
  DeleteRegionInformationMutationVariables
>;

/**
 * __useDeleteRegionInformationMutation__
 *
 * To run a mutation, you first call `useDeleteRegionInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegionInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegionInformationMutation, { data, loading, error }] = useDeleteRegionInformationMutation({
 *   variables: {
 *      informationId: // value for 'informationId'
 *   },
 * });
 */
export function useDeleteRegionInformationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRegionInformationMutation,
    DeleteRegionInformationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRegionInformationMutation,
    DeleteRegionInformationMutationVariables
  >(DeleteRegionInformationDocument, options);
}
export type DeleteRegionInformationMutationHookResult = ReturnType<
  typeof useDeleteRegionInformationMutation
>;
export type DeleteRegionInformationMutationResult =
  Apollo.MutationResult<DeleteRegionInformationMutation>;
export type DeleteRegionInformationMutationOptions = Apollo.BaseMutationOptions<
  DeleteRegionInformationMutation,
  DeleteRegionInformationMutationVariables
>;
export const EditRegionDocument = gql`
  mutation EditRegion($id: String!, $input: RegionsInput!) {
    editRegion(id: $id, input: $input) {
      ...RegionValues
    }
  }
  ${RegionValuesFragmentDoc}
`;
export type EditRegionMutationFn = Apollo.MutationFunction<
  EditRegionMutation,
  EditRegionMutationVariables
>;

/**
 * __useEditRegionMutation__
 *
 * To run a mutation, you first call `useEditRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRegionMutation, { data, loading, error }] = useEditRegionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRegionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditRegionMutation,
    EditRegionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditRegionMutation, EditRegionMutationVariables>(
    EditRegionDocument,
    options,
  );
}
export type EditRegionMutationHookResult = ReturnType<
  typeof useEditRegionMutation
>;
export type EditRegionMutationResult =
  Apollo.MutationResult<EditRegionMutation>;
export type EditRegionMutationOptions = Apollo.BaseMutationOptions<
  EditRegionMutation,
  EditRegionMutationVariables
>;
export const EditRegionInformationDocument = gql`
  mutation EditRegionInformation(
    $informationId: String!
    $information: RegionInformationInput!
  ) {
    editRegionInformation(
      informationId: $informationId
      information: $information
    ) {
      ...RegionValues
    }
  }
  ${RegionValuesFragmentDoc}
`;
export type EditRegionInformationMutationFn = Apollo.MutationFunction<
  EditRegionInformationMutation,
  EditRegionInformationMutationVariables
>;

/**
 * __useEditRegionInformationMutation__
 *
 * To run a mutation, you first call `useEditRegionInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRegionInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRegionInformationMutation, { data, loading, error }] = useEditRegionInformationMutation({
 *   variables: {
 *      informationId: // value for 'informationId'
 *      information: // value for 'information'
 *   },
 * });
 */
export function useEditRegionInformationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditRegionInformationMutation,
    EditRegionInformationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditRegionInformationMutation,
    EditRegionInformationMutationVariables
  >(EditRegionInformationDocument, options);
}
export type EditRegionInformationMutationHookResult = ReturnType<
  typeof useEditRegionInformationMutation
>;
export type EditRegionInformationMutationResult =
  Apollo.MutationResult<EditRegionInformationMutation>;
export type EditRegionInformationMutationOptions = Apollo.BaseMutationOptions<
  EditRegionInformationMutation,
  EditRegionInformationMutationVariables
>;
export const GetRegionDocument = gql`
  query GetRegion($id: String!) {
    getRegion(id: $id) {
      ...RegionValues
    }
  }
  ${RegionValuesFragmentDoc}
`;

/**
 * __useGetRegionQuery__
 *
 * To run a query within a React component, call `useGetRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRegionQuery(
  baseOptions: Apollo.QueryHookOptions<GetRegionQuery, GetRegionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegionQuery, GetRegionQueryVariables>(
    GetRegionDocument,
    options,
  );
}
export function useGetRegionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegionQuery,
    GetRegionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegionQuery, GetRegionQueryVariables>(
    GetRegionDocument,
    options,
  );
}
export type GetRegionQueryHookResult = ReturnType<typeof useGetRegionQuery>;
export type GetRegionLazyQueryHookResult = ReturnType<
  typeof useGetRegionLazyQuery
>;
export type GetRegionQueryResult = Apollo.QueryResult<
  GetRegionQuery,
  GetRegionQueryVariables
>;
export const GetRegionsDocument = gql`
  query GetRegions($skip: Int, $limit: Int, $filter: RegionFilter!) {
    getRegions(skip: $skip, limit: $limit, filter: $filter) {
      count
      regions {
        ...RegionValues
      }
    }
  }
  ${RegionValuesFragmentDoc}
`;

/**
 * __useGetRegionsQuery__
 *
 * To run a query within a React component, call `useGetRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetRegionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRegionsQuery,
    GetRegionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegionsQuery, GetRegionsQueryVariables>(
    GetRegionsDocument,
    options,
  );
}
export function useGetRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegionsQuery,
    GetRegionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegionsQuery, GetRegionsQueryVariables>(
    GetRegionsDocument,
    options,
  );
}
export type GetRegionsQueryHookResult = ReturnType<typeof useGetRegionsQuery>;
export type GetRegionsLazyQueryHookResult = ReturnType<
  typeof useGetRegionsLazyQuery
>;
export type GetRegionsQueryResult = Apollo.QueryResult<
  GetRegionsQuery,
  GetRegionsQueryVariables
>;
export const AddSaleClientDocument = gql`
  mutation AddSaleClient($clientId: String!) {
    addSaleClient(clientId: $clientId) {
      client
    }
  }
`;
export type AddSaleClientMutationFn = Apollo.MutationFunction<
  AddSaleClientMutation,
  AddSaleClientMutationVariables
>;

/**
 * __useAddSaleClientMutation__
 *
 * To run a mutation, you first call `useAddSaleClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSaleClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSaleClientMutation, { data, loading, error }] = useAddSaleClientMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useAddSaleClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSaleClientMutation,
    AddSaleClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSaleClientMutation,
    AddSaleClientMutationVariables
  >(AddSaleClientDocument, options);
}
export type AddSaleClientMutationHookResult = ReturnType<
  typeof useAddSaleClientMutation
>;
export type AddSaleClientMutationResult =
  Apollo.MutationResult<AddSaleClientMutation>;
export type AddSaleClientMutationOptions = Apollo.BaseMutationOptions<
  AddSaleClientMutation,
  AddSaleClientMutationVariables
>;
export const DeleteSaleClientDocument = gql`
  mutation deleteSaleClient($id: String!) {
    deleteSaleClient(id: $id) {
      clientId
      client
    }
  }
`;
export type DeleteSaleClientMutationFn = Apollo.MutationFunction<
  DeleteSaleClientMutation,
  DeleteSaleClientMutationVariables
>;

/**
 * __useDeleteSaleClientMutation__
 *
 * To run a mutation, you first call `useDeleteSaleClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSaleClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSaleClientMutation, { data, loading, error }] = useDeleteSaleClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSaleClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSaleClientMutation,
    DeleteSaleClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSaleClientMutation,
    DeleteSaleClientMutationVariables
  >(DeleteSaleClientDocument, options);
}
export type DeleteSaleClientMutationHookResult = ReturnType<
  typeof useDeleteSaleClientMutation
>;
export type DeleteSaleClientMutationResult =
  Apollo.MutationResult<DeleteSaleClientMutation>;
export type DeleteSaleClientMutationOptions = Apollo.BaseMutationOptions<
  DeleteSaleClientMutation,
  DeleteSaleClientMutationVariables
>;
export const EditSaleClientDocument = gql`
  mutation editSaleClient($id: String!, $input: SaleClientInput) {
    editSaleClient(id: $id, input: $input) {
      clientId
    }
  }
`;
export type EditSaleClientMutationFn = Apollo.MutationFunction<
  EditSaleClientMutation,
  EditSaleClientMutationVariables
>;

/**
 * __useEditSaleClientMutation__
 *
 * To run a mutation, you first call `useEditSaleClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSaleClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSaleClientMutation, { data, loading, error }] = useEditSaleClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSaleClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditSaleClientMutation,
    EditSaleClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditSaleClientMutation,
    EditSaleClientMutationVariables
  >(EditSaleClientDocument, options);
}
export type EditSaleClientMutationHookResult = ReturnType<
  typeof useEditSaleClientMutation
>;
export type EditSaleClientMutationResult =
  Apollo.MutationResult<EditSaleClientMutation>;
export type EditSaleClientMutationOptions = Apollo.BaseMutationOptions<
  EditSaleClientMutation,
  EditSaleClientMutationVariables
>;
export const GetSaleClientGoalsDocument = gql`
  query GetSaleClientGoals {
    goals {
      december {
        averagePerDay
        willReachGoal
        averagePerDayNeeded
        goal
      }
      october {
        willReachGoal
        averagePerDay
        averagePerDayNeeded
        goal
      }
      subscribersCount
    }
  }
`;

/**
 * __useGetSaleClientGoalsQuery__
 *
 * To run a query within a React component, call `useGetSaleClientGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaleClientGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSaleClientGoalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSaleClientGoalsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSaleClientGoalsQuery,
    GetSaleClientGoalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSaleClientGoalsQuery,
    GetSaleClientGoalsQueryVariables
  >(GetSaleClientGoalsDocument, options);
}
export function useGetSaleClientGoalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSaleClientGoalsQuery,
    GetSaleClientGoalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSaleClientGoalsQuery,
    GetSaleClientGoalsQueryVariables
  >(GetSaleClientGoalsDocument, options);
}
export type GetSaleClientGoalsQueryHookResult = ReturnType<
  typeof useGetSaleClientGoalsQuery
>;
export type GetSaleClientGoalsLazyQueryHookResult = ReturnType<
  typeof useGetSaleClientGoalsLazyQuery
>;
export type GetSaleClientGoalsQueryResult = Apollo.QueryResult<
  GetSaleClientGoalsQuery,
  GetSaleClientGoalsQueryVariables
>;
export const GetSaleClientsDocument = gql`
  query getSaleClients(
    $clientManagerId: String
    $skip: Int
    $limit: Int
    $sortBy: String
    $sortOrder: String
    $tag: String
    $stair: String
    $subscription: String
    $oneOfHundred: Boolean
    $search: String
    $fetchAwaiting: Boolean
  ) {
    saleClients(
      clientManagerId: $clientManagerId
      skip: $skip
      limit: $limit
      sortBy: $sortBy
      sortOrder: $sortOrder
      tag: $tag
      stair: $stair
      subscription: $subscription
      oneOfHundred: $oneOfHundred
      search: $search
      fetchAwaiting: $fetchAwaiting
    ) {
      count
      dollarCount
      clients {
        id
        clientManager
        clientManagerId
        client
        income
        tag
        clientId
        stair
        comment
        created
        inStairSince
        subscription
        oneOfHundred
        nextPayment
        subscriptionStart
        subscriptionEnd
        daysToNextPayment
        daysLeftSubscription
        files {
          id
          name
          url
          size
        }
      }
      totalIncome
    }
  }
`;

/**
 * __useGetSaleClientsQuery__
 *
 * To run a query within a React component, call `useGetSaleClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaleClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSaleClientsQuery({
 *   variables: {
 *      clientManagerId: // value for 'clientManagerId'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      tag: // value for 'tag'
 *      stair: // value for 'stair'
 *      subscription: // value for 'subscription'
 *      oneOfHundred: // value for 'oneOfHundred'
 *      search: // value for 'search'
 *      fetchAwaiting: // value for 'fetchAwaiting'
 *   },
 * });
 */
export function useGetSaleClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSaleClientsQuery,
    GetSaleClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSaleClientsQuery, GetSaleClientsQueryVariables>(
    GetSaleClientsDocument,
    options,
  );
}
export function useGetSaleClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSaleClientsQuery,
    GetSaleClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSaleClientsQuery, GetSaleClientsQueryVariables>(
    GetSaleClientsDocument,
    options,
  );
}
export type GetSaleClientsQueryHookResult = ReturnType<
  typeof useGetSaleClientsQuery
>;
export type GetSaleClientsLazyQueryHookResult = ReturnType<
  typeof useGetSaleClientsLazyQuery
>;
export type GetSaleClientsQueryResult = Apollo.QueryResult<
  GetSaleClientsQuery,
  GetSaleClientsQueryVariables
>;
export const GetMySearchRequestsLandlordDocument = gql`
  query GetMySearchRequestsLandlord(
    $usageCategories: [String!]!
    $unansweredLimit: Int
    $unansweredSkip: Int
    $regionIds: [String!]!
    $size: [SizeFilter!]!
    $sort: Sort
    $search: String
    $showArchived: Boolean
  ) {
    mySearchRequestsLandlord(
      usageCategories: $usageCategories
      regionIds: $regionIds
      size: $size
      sort: $sort
      search: $search
      showArchived: $showArchived
    ) {
      unanswered(limit: $unansweredLimit, skip: $unansweredSkip) {
        searchRequests {
          ...SearchRequestReferenceListValues
        }
        count
      }
      declined {
        searchRequests {
          ...SearchRequestLandlordValues
        }
        count
      }
      accepted {
        searchRequests {
          ...SearchRequestLandlordValues
        }
        count
      }
      disabled {
        count
        searchRequests {
          ...SearchRequestReferenceListValues
        }
      }
    }
  }
  ${SearchRequestReferenceListValuesFragmentDoc}
  ${SearchRequestLandlordValuesFragmentDoc}
`;

/**
 * __useGetMySearchRequestsLandlordQuery__
 *
 * To run a query within a React component, call `useGetMySearchRequestsLandlordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySearchRequestsLandlordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySearchRequestsLandlordQuery({
 *   variables: {
 *      usageCategories: // value for 'usageCategories'
 *      unansweredLimit: // value for 'unansweredLimit'
 *      unansweredSkip: // value for 'unansweredSkip'
 *      regionIds: // value for 'regionIds'
 *      size: // value for 'size'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *      showArchived: // value for 'showArchived'
 *   },
 * });
 */
export function useGetMySearchRequestsLandlordQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMySearchRequestsLandlordQuery,
    GetMySearchRequestsLandlordQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMySearchRequestsLandlordQuery,
    GetMySearchRequestsLandlordQueryVariables
  >(GetMySearchRequestsLandlordDocument, options);
}
export function useGetMySearchRequestsLandlordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMySearchRequestsLandlordQuery,
    GetMySearchRequestsLandlordQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMySearchRequestsLandlordQuery,
    GetMySearchRequestsLandlordQueryVariables
  >(GetMySearchRequestsLandlordDocument, options);
}
export type GetMySearchRequestsLandlordQueryHookResult = ReturnType<
  typeof useGetMySearchRequestsLandlordQuery
>;
export type GetMySearchRequestsLandlordLazyQueryHookResult = ReturnType<
  typeof useGetMySearchRequestsLandlordLazyQuery
>;
export type GetMySearchRequestsLandlordQueryResult = Apollo.QueryResult<
  GetMySearchRequestsLandlordQuery,
  GetMySearchRequestsLandlordQueryVariables
>;
export const GetSearchResultDocument = gql`
  query GetSearchResult(
    $search: SearchProfileInput
    $bounds: Bounds
    $zoom: Int
    $limit: Int
    $offset: Int
  ) {
    leaseAgreements(
      search: $search
      zoom: $zoom
      cluster: true
      bounds: $bounds
    ) {
      items(
        limit: $limit
        offset: $offset
        sort: { field: avgSize, direction: ascend }
      ) {
        ...LeaseAgreementListValues
      }
      cluster {
        count
        id
        location
      }
      count
      regionBounds {
        south
        north
        east
        west
      }
      regionPolygons
      commerce {
        id
        name
        center
      }
      showResults
    }
  }
  ${LeaseAgreementListValuesFragmentDoc}
`;

/**
 * __useGetSearchResultQuery__
 *
 * To run a query within a React component, call `useGetSearchResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchResultQuery({
 *   variables: {
 *      search: // value for 'search'
 *      bounds: // value for 'bounds'
 *      zoom: // value for 'zoom'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetSearchResultQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSearchResultQuery,
    GetSearchResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSearchResultQuery, GetSearchResultQueryVariables>(
    GetSearchResultDocument,
    options,
  );
}
export function useGetSearchResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchResultQuery,
    GetSearchResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchResultQuery,
    GetSearchResultQueryVariables
  >(GetSearchResultDocument, options);
}
export type GetSearchResultQueryHookResult = ReturnType<
  typeof useGetSearchResultQuery
>;
export type GetSearchResultLazyQueryHookResult = ReturnType<
  typeof useGetSearchResultLazyQuery
>;
export type GetSearchResultQueryResult = Apollo.QueryResult<
  GetSearchResultQuery,
  GetSearchResultQueryVariables
>;
export const EditContactPersonDocument = gql`
  mutation EditContactPerson(
    $searchRequestReferenceId: String!
    $userId: String!
  ) {
    editContactPerson(
      searchRequestReferenceId: $searchRequestReferenceId
      userId: $userId
    ) {
      id
      contactPerson {
        id
        name
      }
    }
  }
`;
export type EditContactPersonMutationFn = Apollo.MutationFunction<
  EditContactPersonMutation,
  EditContactPersonMutationVariables
>;

/**
 * __useEditContactPersonMutation__
 *
 * To run a mutation, you first call `useEditContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editContactPersonMutation, { data, loading, error }] = useEditContactPersonMutation({
 *   variables: {
 *      searchRequestReferenceId: // value for 'searchRequestReferenceId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEditContactPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditContactPersonMutation,
    EditContactPersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditContactPersonMutation,
    EditContactPersonMutationVariables
  >(EditContactPersonDocument, options);
}
export type EditContactPersonMutationHookResult = ReturnType<
  typeof useEditContactPersonMutation
>;
export type EditContactPersonMutationResult =
  Apollo.MutationResult<EditContactPersonMutation>;
export type EditContactPersonMutationOptions = Apollo.BaseMutationOptions<
  EditContactPersonMutation,
  EditContactPersonMutationVariables
>;
export const GetReceiversDocument = gql`
  query GetReceivers($id: String!, $status: [StatusEnum!]!) {
    searchRequestReference(id: $id) {
      searchRequests(limit: 150, status: $status) {
        searchRequests {
          id
          status
          responsibleUser {
            id
            name
            image {
              id
              url(
                processing: {
                  width: 400
                  height: 400
                  quality: auto
                  crop: fill
                  gravity: face
                }
              )
            }
          }
          receiver {
            id
            name
            image {
              id
              url(
                processing: {
                  width: 400
                  height: 400
                  quality: auto
                  crop: fill
                  gravity: face
                }
              )
            }
          }
          conversation {
            ...SearchRequestConversationValues
          }
        }
        count
      }
    }
  }
  ${SearchRequestConversationValuesFragmentDoc}
`;

/**
 * __useGetReceiversQuery__
 *
 * To run a query within a React component, call `useGetReceiversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceiversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceiversQuery({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetReceiversQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReceiversQuery,
    GetReceiversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReceiversQuery, GetReceiversQueryVariables>(
    GetReceiversDocument,
    options,
  );
}
export function useGetReceiversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReceiversQuery,
    GetReceiversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReceiversQuery, GetReceiversQueryVariables>(
    GetReceiversDocument,
    options,
  );
}
export type GetReceiversQueryHookResult = ReturnType<
  typeof useGetReceiversQuery
>;
export type GetReceiversLazyQueryHookResult = ReturnType<
  typeof useGetReceiversLazyQuery
>;
export type GetReceiversQueryResult = Apollo.QueryResult<
  GetReceiversQuery,
  GetReceiversQueryVariables
>;
export const GetSearchRequestReferenceDocument = gql`
  query GetSearchRequestReference($id: String!) {
    searchRequestReference(id: $id) {
      id
      ...SearchRequestReferenceInformationValues
      sentDate
      stats {
        answer_requested
        opened
        accepted
        declined
      }
      improveSearchMailSent
    }
  }
  ${SearchRequestReferenceInformationValuesFragmentDoc}
`;

/**
 * __useGetSearchRequestReferenceQuery__
 *
 * To run a query within a React component, call `useGetSearchRequestReferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchRequestReferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchRequestReferenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSearchRequestReferenceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSearchRequestReferenceQuery,
    GetSearchRequestReferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSearchRequestReferenceQuery,
    GetSearchRequestReferenceQueryVariables
  >(GetSearchRequestReferenceDocument, options);
}
export function useGetSearchRequestReferenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchRequestReferenceQuery,
    GetSearchRequestReferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchRequestReferenceQuery,
    GetSearchRequestReferenceQueryVariables
  >(GetSearchRequestReferenceDocument, options);
}
export type GetSearchRequestReferenceQueryHookResult = ReturnType<
  typeof useGetSearchRequestReferenceQuery
>;
export type GetSearchRequestReferenceLazyQueryHookResult = ReturnType<
  typeof useGetSearchRequestReferenceLazyQuery
>;
export type GetSearchRequestReferenceQueryResult = Apollo.QueryResult<
  GetSearchRequestReferenceQuery,
  GetSearchRequestReferenceQueryVariables
>;
export const EditSearchRequestAdminDocument = gql`
  mutation EditSearchRequestAdmin(
    $id: String!
    $input: SearchRequestAdminInput!
  ) {
    editSearchRequestAdmin(id: $id, input: $input) {
      id
      favourite
    }
  }
`;
export type EditSearchRequestAdminMutationFn = Apollo.MutationFunction<
  EditSearchRequestAdminMutation,
  EditSearchRequestAdminMutationVariables
>;

/**
 * __useEditSearchRequestAdminMutation__
 *
 * To run a mutation, you first call `useEditSearchRequestAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSearchRequestAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSearchRequestAdminMutation, { data, loading, error }] = useEditSearchRequestAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSearchRequestAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditSearchRequestAdminMutation,
    EditSearchRequestAdminMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditSearchRequestAdminMutation,
    EditSearchRequestAdminMutationVariables
  >(EditSearchRequestAdminDocument, options);
}
export type EditSearchRequestAdminMutationHookResult = ReturnType<
  typeof useEditSearchRequestAdminMutation
>;
export type EditSearchRequestAdminMutationResult =
  Apollo.MutationResult<EditSearchRequestAdminMutation>;
export type EditSearchRequestAdminMutationOptions = Apollo.BaseMutationOptions<
  EditSearchRequestAdminMutation,
  EditSearchRequestAdminMutationVariables
>;
export const GetClientConversationsDocument = gql`
  query GetClientConversations(
    $id: String!
    $limit: Int!
    $includeDeclined: Boolean
    $searchRequestReferenceId: String
  ) {
    client(id: $id) {
      id
      conversationStatistics(
        includeDeclined: $includeDeclined
        searchRequestReferenceId: $searchRequestReferenceId
      ) {
        conversations(limit: $limit) {
          id
          searchRequest {
            id
            title
            status
          }
          leaseAgreement {
            id
            name
          }
          participants {
            id
            name
          }
          messages {
            ...MessageConversationPreviewValues
          }
        }
        count
        unanswered
      }
    }
  }
  ${MessageConversationPreviewValuesFragmentDoc}
`;

/**
 * __useGetClientConversationsQuery__
 *
 * To run a query within a React component, call `useGetClientConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientConversationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      includeDeclined: // value for 'includeDeclined'
 *      searchRequestReferenceId: // value for 'searchRequestReferenceId'
 *   },
 * });
 */
export function useGetClientConversationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientConversationsQuery,
    GetClientConversationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientConversationsQuery,
    GetClientConversationsQueryVariables
  >(GetClientConversationsDocument, options);
}
export function useGetClientConversationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientConversationsQuery,
    GetClientConversationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientConversationsQuery,
    GetClientConversationsQueryVariables
  >(GetClientConversationsDocument, options);
}
export type GetClientConversationsQueryHookResult = ReturnType<
  typeof useGetClientConversationsQuery
>;
export type GetClientConversationsLazyQueryHookResult = ReturnType<
  typeof useGetClientConversationsLazyQuery
>;
export type GetClientConversationsQueryResult = Apollo.QueryResult<
  GetClientConversationsQuery,
  GetClientConversationsQueryVariables
>;
export const GetCurrentSearchRequestsDocument = gql`
  query GetCurrentSearchRequests(
    $filter: SearchRequestAdminFilter!
    $limit: Int!
    $skip: Int!
    $sort: Sort
  ) {
    searchRequestsAdmin(
      filter: $filter
      limit: $limit
      skip: $skip
      sort: $sort
    ) {
      searchRequests {
        key: id
        id
        title
        sentDate
        renewedDate
        regions {
          id
          name
          regionType
        }
        searchParameters {
          usageCategories
          size {
            min
            max
          }
        }
        client {
          id
          name
          conversationStatistics {
            count
            unanswered
          }
        }
        status
        disabledDate
        disabledReason {
          reason
          comment
          contactViaPortally
          client {
            id
            name
          }
          providedClient
        }
        stats {
          accepted
          declined
          answer_requested
          opened
        }
        hasLoggedIn
        favourite
        contactPerson {
          id
          name
        }
        hasComment
        adminStatus
        adminResponsibleUser {
          id
          name
        }
      }
      count
    }
  }
`;

/**
 * __useGetCurrentSearchRequestsQuery__
 *
 * To run a query within a React component, call `useGetCurrentSearchRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentSearchRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentSearchRequestsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetCurrentSearchRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentSearchRequestsQuery,
    GetCurrentSearchRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentSearchRequestsQuery,
    GetCurrentSearchRequestsQueryVariables
  >(GetCurrentSearchRequestsDocument, options);
}
export function useGetCurrentSearchRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentSearchRequestsQuery,
    GetCurrentSearchRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentSearchRequestsQuery,
    GetCurrentSearchRequestsQueryVariables
  >(GetCurrentSearchRequestsDocument, options);
}
export type GetCurrentSearchRequestsQueryHookResult = ReturnType<
  typeof useGetCurrentSearchRequestsQuery
>;
export type GetCurrentSearchRequestsLazyQueryHookResult = ReturnType<
  typeof useGetCurrentSearchRequestsLazyQuery
>;
export type GetCurrentSearchRequestsQueryResult = Apollo.QueryResult<
  GetCurrentSearchRequestsQuery,
  GetCurrentSearchRequestsQueryVariables
>;
export const GetSearchRequestAdminStatisticsDocument = gql`
  query GetSearchRequestAdminStatistics($filter: SearchRequestAdminFilter!) {
    searchRequestStatistics(filter: $filter) {
      count
      sizes {
        size
        count
      }
      regions {
        region {
          id
          name
          geometry
          regionType
          bounds {
            north
            south
            west
            east
          }
          center
        }
        count
      }
      usageCategories {
        count
        usageCategory
      }
      potentialSize {
        min
        max
      }
    }
  }
`;

/**
 * __useGetSearchRequestAdminStatisticsQuery__
 *
 * To run a query within a React component, call `useGetSearchRequestAdminStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchRequestAdminStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchRequestAdminStatisticsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSearchRequestAdminStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSearchRequestAdminStatisticsQuery,
    GetSearchRequestAdminStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSearchRequestAdminStatisticsQuery,
    GetSearchRequestAdminStatisticsQueryVariables
  >(GetSearchRequestAdminStatisticsDocument, options);
}
export function useGetSearchRequestAdminStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchRequestAdminStatisticsQuery,
    GetSearchRequestAdminStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchRequestAdminStatisticsQuery,
    GetSearchRequestAdminStatisticsQueryVariables
  >(GetSearchRequestAdminStatisticsDocument, options);
}
export type GetSearchRequestAdminStatisticsQueryHookResult = ReturnType<
  typeof useGetSearchRequestAdminStatisticsQuery
>;
export type GetSearchRequestAdminStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetSearchRequestAdminStatisticsLazyQuery
>;
export type GetSearchRequestAdminStatisticsQueryResult = Apollo.QueryResult<
  GetSearchRequestAdminStatisticsQuery,
  GetSearchRequestAdminStatisticsQueryVariables
>;
export const SearchRequestClientAutocompleteDocument = gql`
  query SearchRequestClientAutocomplete($key: String!, $clientType: String) {
    clientAutocomplete(
      key: $key
      clientType: $clientType
      searchRequest: true
    ) {
      id
      value
    }
  }
`;

/**
 * __useSearchRequestClientAutocompleteQuery__
 *
 * To run a query within a React component, call `useSearchRequestClientAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchRequestClientAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchRequestClientAutocompleteQuery({
 *   variables: {
 *      key: // value for 'key'
 *      clientType: // value for 'clientType'
 *   },
 * });
 */
export function useSearchRequestClientAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchRequestClientAutocompleteQuery,
    SearchRequestClientAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchRequestClientAutocompleteQuery,
    SearchRequestClientAutocompleteQueryVariables
  >(SearchRequestClientAutocompleteDocument, options);
}
export function useSearchRequestClientAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchRequestClientAutocompleteQuery,
    SearchRequestClientAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchRequestClientAutocompleteQuery,
    SearchRequestClientAutocompleteQueryVariables
  >(SearchRequestClientAutocompleteDocument, options);
}
export type SearchRequestClientAutocompleteQueryHookResult = ReturnType<
  typeof useSearchRequestClientAutocompleteQuery
>;
export type SearchRequestClientAutocompleteLazyQueryHookResult = ReturnType<
  typeof useSearchRequestClientAutocompleteLazyQuery
>;
export type SearchRequestClientAutocompleteQueryResult = Apollo.QueryResult<
  SearchRequestClientAutocompleteQuery,
  SearchRequestClientAutocompleteQueryVariables
>;
export const GetCohortAnalysisDocument = gql`
  query GetCohortAnalysis($clientType: ClientType) {
    analysis {
      cohort(clientType: $clientType) {
        converted
        registered
        date
      }
    }
  }
`;

/**
 * __useGetCohortAnalysisQuery__
 *
 * To run a query within a React component, call `useGetCohortAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortAnalysisQuery({
 *   variables: {
 *      clientType: // value for 'clientType'
 *   },
 * });
 */
export function useGetCohortAnalysisQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCohortAnalysisQuery,
    GetCohortAnalysisQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCohortAnalysisQuery,
    GetCohortAnalysisQueryVariables
  >(GetCohortAnalysisDocument, options);
}
export function useGetCohortAnalysisLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCohortAnalysisQuery,
    GetCohortAnalysisQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCohortAnalysisQuery,
    GetCohortAnalysisQueryVariables
  >(GetCohortAnalysisDocument, options);
}
export type GetCohortAnalysisQueryHookResult = ReturnType<
  typeof useGetCohortAnalysisQuery
>;
export type GetCohortAnalysisLazyQueryHookResult = ReturnType<
  typeof useGetCohortAnalysisLazyQuery
>;
export type GetCohortAnalysisQueryResult = Apollo.QueryResult<
  GetCohortAnalysisQuery,
  GetCohortAnalysisQueryVariables
>;
export const GetSearchRequestAnalysisDocument = gql`
  query GetSearchRequestAnalysis($filter: SearchRequestFilter!) {
    analysis {
      searchRequests(filter: $filter) {
        opened
        unopened
        declined
        recipients
        accepted
        active
        disabled {
          dislike
          elsewhere {
            elsewhere
            elsewhereotherplatform
            elsewhererealestateagent
            elsewhereothercontact
            elsewhereportallycontact
            elsewhereother
          }
          stationary
          notrelevant
          adminNeverLoggedIn
          adminNoActivity
          success
          count
        }
      }
    }
  }
`;

/**
 * __useGetSearchRequestAnalysisQuery__
 *
 * To run a query within a React component, call `useGetSearchRequestAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchRequestAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchRequestAnalysisQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSearchRequestAnalysisQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSearchRequestAnalysisQuery,
    GetSearchRequestAnalysisQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSearchRequestAnalysisQuery,
    GetSearchRequestAnalysisQueryVariables
  >(GetSearchRequestAnalysisDocument, options);
}
export function useGetSearchRequestAnalysisLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchRequestAnalysisQuery,
    GetSearchRequestAnalysisQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchRequestAnalysisQuery,
    GetSearchRequestAnalysisQueryVariables
  >(GetSearchRequestAnalysisDocument, options);
}
export type GetSearchRequestAnalysisQueryHookResult = ReturnType<
  typeof useGetSearchRequestAnalysisQuery
>;
export type GetSearchRequestAnalysisLazyQueryHookResult = ReturnType<
  typeof useGetSearchRequestAnalysisLazyQuery
>;
export type GetSearchRequestAnalysisQueryResult = Apollo.QueryResult<
  GetSearchRequestAnalysisQuery,
  GetSearchRequestAnalysisQueryVariables
>;
export const GetDailyReportDocument = gql`
  query GetDailyReport($limit: Int!) {
    dailyReports(limit: $limit) {
      id
      type
      year
      date
      statistics {
        nrOfActiveUsers
        nrOfActiveLandlordUsers
        nrOfActiveTenantUsers
        nrOfNewSearchRequests
        nrOfEmailSent
        nrOfSignups
        nrOfTenantUserSignups
        nrOfLandlordUserSignups
        nrOfPromotions
        nrOfMessages
        nrOfInterests
        nrOfLeaseAgreements
      }
    }
  }
`;

/**
 * __useGetDailyReportQuery__
 *
 * To run a query within a React component, call `useGetDailyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyReportQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDailyReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDailyReportQuery,
    GetDailyReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDailyReportQuery, GetDailyReportQueryVariables>(
    GetDailyReportDocument,
    options,
  );
}
export function useGetDailyReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDailyReportQuery,
    GetDailyReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDailyReportQuery, GetDailyReportQueryVariables>(
    GetDailyReportDocument,
    options,
  );
}
export type GetDailyReportQueryHookResult = ReturnType<
  typeof useGetDailyReportQuery
>;
export type GetDailyReportLazyQueryHookResult = ReturnType<
  typeof useGetDailyReportLazyQuery
>;
export type GetDailyReportQueryResult = Apollo.QueryResult<
  GetDailyReportQuery,
  GetDailyReportQueryVariables
>;
export const GetLeaseAgreementStatsDocument = gql`
  query GetLeaseAgreementStats {
    viewStats {
      mostViewedLeaseAgreements {
        count
        address {
          street
          streetNumber
        }
        _id
      }
      overTime {
        date
        count
      }
      numbers {
        total
        app
        website
        websitePercentage
      }
    }
  }
`;

/**
 * __useGetLeaseAgreementStatsQuery__
 *
 * To run a query within a React component, call `useGetLeaseAgreementStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaseAgreementStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaseAgreementStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLeaseAgreementStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLeaseAgreementStatsQuery,
    GetLeaseAgreementStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLeaseAgreementStatsQuery,
    GetLeaseAgreementStatsQueryVariables
  >(GetLeaseAgreementStatsDocument, options);
}
export function useGetLeaseAgreementStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeaseAgreementStatsQuery,
    GetLeaseAgreementStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLeaseAgreementStatsQuery,
    GetLeaseAgreementStatsQueryVariables
  >(GetLeaseAgreementStatsDocument, options);
}
export type GetLeaseAgreementStatsQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementStatsQuery
>;
export type GetLeaseAgreementStatsLazyQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementStatsLazyQuery
>;
export type GetLeaseAgreementStatsQueryResult = Apollo.QueryResult<
  GetLeaseAgreementStatsQuery,
  GetLeaseAgreementStatsQueryVariables
>;
export const GetLeaseAgreementViewsDocument = gql`
  query GetLeaseAgreementViews(
    $leaseAgreementId: ID
    $fromWebsite: Boolean
    $from: Int
  ) {
    viewStats {
      numbers(leaseAgreementId: $leaseAgreementId, from: $from) {
        website
        app
        websitePercentage
        total
      }
      overTime(
        leaseAgreementId: $leaseAgreementId
        fromWebsite: $fromWebsite
        from: $from
      ) {
        date
        count
      }
    }
  }
`;

/**
 * __useGetLeaseAgreementViewsQuery__
 *
 * To run a query within a React component, call `useGetLeaseAgreementViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaseAgreementViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaseAgreementViewsQuery({
 *   variables: {
 *      leaseAgreementId: // value for 'leaseAgreementId'
 *      fromWebsite: // value for 'fromWebsite'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useGetLeaseAgreementViewsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLeaseAgreementViewsQuery,
    GetLeaseAgreementViewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLeaseAgreementViewsQuery,
    GetLeaseAgreementViewsQueryVariables
  >(GetLeaseAgreementViewsDocument, options);
}
export function useGetLeaseAgreementViewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeaseAgreementViewsQuery,
    GetLeaseAgreementViewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLeaseAgreementViewsQuery,
    GetLeaseAgreementViewsQueryVariables
  >(GetLeaseAgreementViewsDocument, options);
}
export type GetLeaseAgreementViewsQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementViewsQuery
>;
export type GetLeaseAgreementViewsLazyQueryHookResult = ReturnType<
  typeof useGetLeaseAgreementViewsLazyQuery
>;
export type GetLeaseAgreementViewsQueryResult = Apollo.QueryResult<
  GetLeaseAgreementViewsQuery,
  GetLeaseAgreementViewsQueryVariables
>;
export const GetLostAndActiveDocument = gql`
  query GetLostAndActive(
    $activeLimit: Int!
    $lostLimit: Int!
    $clientType: ClientType
  ) {
    systemStats {
      users {
        activeUserStats(clientType: $clientType) {
          active(activeLimit: $activeLimit) {
            id
            name
            activeClient {
              id
              name
            }
          }
          lost(lostLimit: $lostLimit) {
            id
            name
            activeClient {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetLostAndActiveQuery__
 *
 * To run a query within a React component, call `useGetLostAndActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLostAndActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLostAndActiveQuery({
 *   variables: {
 *      activeLimit: // value for 'activeLimit'
 *      lostLimit: // value for 'lostLimit'
 *      clientType: // value for 'clientType'
 *   },
 * });
 */
export function useGetLostAndActiveQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLostAndActiveQuery,
    GetLostAndActiveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLostAndActiveQuery, GetLostAndActiveQueryVariables>(
    GetLostAndActiveDocument,
    options,
  );
}
export function useGetLostAndActiveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLostAndActiveQuery,
    GetLostAndActiveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLostAndActiveQuery,
    GetLostAndActiveQueryVariables
  >(GetLostAndActiveDocument, options);
}
export type GetLostAndActiveQueryHookResult = ReturnType<
  typeof useGetLostAndActiveQuery
>;
export type GetLostAndActiveLazyQueryHookResult = ReturnType<
  typeof useGetLostAndActiveLazyQuery
>;
export type GetLostAndActiveQueryResult = Apollo.QueryResult<
  GetLostAndActiveQuery,
  GetLostAndActiveQueryVariables
>;
export const GetUserStatsDocument = gql`
  query GetUserStats(
    $usersFrom: Date
    $messagesFrom: Date
    $searchRequestsFrom: Date
    $preliminaryFrom: Date
    $promotedFrom: Date
    $leaseAgreementFrom: Date
    $clientType: ClientType
  ) {
    systemStats {
      leaseAgreements {
        count
        interestsOverTime(from: $leaseAgreementFrom) {
          date
          count
        }
      }
      messages {
        messagesOverTime(from: $messagesFrom) {
          date
          count
        }
        count
      }
      searchRequests {
        count
        searchRequestsOverTime(from: $searchRequestsFrom) {
          date
          count
        }
      }
      preliminarySearchRequests {
        count
        searchRequestsOverTime(from: $preliminaryFrom) {
          date
          count
        }
      }
      promotions {
        count
        promotionsOverTime(from: $promotedFrom) {
          date
          count
        }
      }
      users {
        forecast {
          willReachGoal
          averagePerDay
          averagePerDayNeeded
          goal
        }
        count
        thisWeekTot
        lastWeekTot
        thisMonthTot
        lastMonthTot
        registeredPerDay {
          date
          count
        }
        loginStats(clientType: $clientType) {
          date
          count
        }
        totalLoginStats(clientType: $clientType) {
          date
          count
        }
        activeUserStats(clientType: $clientType) {
          activeOverTime(from: $usersFrom) {
            date
            count
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserStatsQuery__
 *
 * To run a query within a React component, call `useGetUserStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserStatsQuery({
 *   variables: {
 *      usersFrom: // value for 'usersFrom'
 *      messagesFrom: // value for 'messagesFrom'
 *      searchRequestsFrom: // value for 'searchRequestsFrom'
 *      preliminaryFrom: // value for 'preliminaryFrom'
 *      promotedFrom: // value for 'promotedFrom'
 *      leaseAgreementFrom: // value for 'leaseAgreementFrom'
 *      clientType: // value for 'clientType'
 *   },
 * });
 */
export function useGetUserStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserStatsQuery,
    GetUserStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserStatsQuery, GetUserStatsQueryVariables>(
    GetUserStatsDocument,
    options,
  );
}
export function useGetUserStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserStatsQuery,
    GetUserStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserStatsQuery, GetUserStatsQueryVariables>(
    GetUserStatsDocument,
    options,
  );
}
export type GetUserStatsQueryHookResult = ReturnType<
  typeof useGetUserStatsQuery
>;
export type GetUserStatsLazyQueryHookResult = ReturnType<
  typeof useGetUserStatsLazyQuery
>;
export type GetUserStatsQueryResult = Apollo.QueryResult<
  GetUserStatsQuery,
  GetUserStatsQueryVariables
>;
export const GetWeeklyReportDocument = gql`
  query GetWeeklyReport {
    weeklyReports {
      id
      type
      date
      year
      week
      statistics {
        nrOfActiveUsers
        nrOfActiveLandlordUsers
        nrOfActiveTenantUsers
        nrOfNewSearchRequests
        nrOfEmailSent
        nrOfSignups
        nrOfLandlordUserSignups
        nrOfTenantUserSignups
        nrOfPromotions
        nrOfMessages
        nrOfInterests
        nrOfLeaseAgreements
      }
    }
  }
`;

/**
 * __useGetWeeklyReportQuery__
 *
 * To run a query within a React component, call `useGetWeeklyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeeklyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeeklyReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWeeklyReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWeeklyReportQuery,
    GetWeeklyReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWeeklyReportQuery, GetWeeklyReportQueryVariables>(
    GetWeeklyReportDocument,
    options,
  );
}
export function useGetWeeklyReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWeeklyReportQuery,
    GetWeeklyReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWeeklyReportQuery,
    GetWeeklyReportQueryVariables
  >(GetWeeklyReportDocument, options);
}
export type GetWeeklyReportQueryHookResult = ReturnType<
  typeof useGetWeeklyReportQuery
>;
export type GetWeeklyReportLazyQueryHookResult = ReturnType<
  typeof useGetWeeklyReportLazyQuery
>;
export type GetWeeklyReportQueryResult = Apollo.QueryResult<
  GetWeeklyReportQuery,
  GetWeeklyReportQueryVariables
>;
export const AddSubscriptionRequestDocument = gql`
  mutation AddSubscriptionRequest($input: AddSubscriptionRequestInput!) {
    addSubscriptionRequest(input: $input) {
      id
      client {
        id
        name
      }
      status
    }
  }
`;
export type AddSubscriptionRequestMutationFn = Apollo.MutationFunction<
  AddSubscriptionRequestMutation,
  AddSubscriptionRequestMutationVariables
>;

/**
 * __useAddSubscriptionRequestMutation__
 *
 * To run a mutation, you first call `useAddSubscriptionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubscriptionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubscriptionRequestMutation, { data, loading, error }] = useAddSubscriptionRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSubscriptionRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSubscriptionRequestMutation,
    AddSubscriptionRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSubscriptionRequestMutation,
    AddSubscriptionRequestMutationVariables
  >(AddSubscriptionRequestDocument, options);
}
export type AddSubscriptionRequestMutationHookResult = ReturnType<
  typeof useAddSubscriptionRequestMutation
>;
export type AddSubscriptionRequestMutationResult =
  Apollo.MutationResult<AddSubscriptionRequestMutation>;
export type AddSubscriptionRequestMutationOptions = Apollo.BaseMutationOptions<
  AddSubscriptionRequestMutation,
  AddSubscriptionRequestMutationVariables
>;
export const DeleteSubscriptionRequestDocument = gql`
  mutation DeleteSubscriptionRequest($id: String!) {
    deleteSubscriptionRequest(id: $id) {
      id
    }
  }
`;
export type DeleteSubscriptionRequestMutationFn = Apollo.MutationFunction<
  DeleteSubscriptionRequestMutation,
  DeleteSubscriptionRequestMutationVariables
>;

/**
 * __useDeleteSubscriptionRequestMutation__
 *
 * To run a mutation, you first call `useDeleteSubscriptionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubscriptionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubscriptionRequestMutation, { data, loading, error }] = useDeleteSubscriptionRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSubscriptionRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSubscriptionRequestMutation,
    DeleteSubscriptionRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSubscriptionRequestMutation,
    DeleteSubscriptionRequestMutationVariables
  >(DeleteSubscriptionRequestDocument, options);
}
export type DeleteSubscriptionRequestMutationHookResult = ReturnType<
  typeof useDeleteSubscriptionRequestMutation
>;
export type DeleteSubscriptionRequestMutationResult =
  Apollo.MutationResult<DeleteSubscriptionRequestMutation>;
export type DeleteSubscriptionRequestMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteSubscriptionRequestMutation,
    DeleteSubscriptionRequestMutationVariables
  >;
export const GetSubscriptionClientsDocument = gql`
  query GetSubscriptionClients($clientName: String) {
    clients(
      filter: {
        searchValues: { hasCommission: true, clientName: $clientName }
        limit: 500
      }
    ) {
      clients {
        id
        key: id
        name
        subscriptions {
          type
          id
          version
          acceptedByUser {
            id
            name
          }
          readByUsers {
            id
            name
          }
          openedByUsers {
            id
            name
          }
          acceptedDate
          status
          created
          updated
        }
      }
      count
    }
  }
`;

/**
 * __useGetSubscriptionClientsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionClientsQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useGetSubscriptionClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubscriptionClientsQuery,
    GetSubscriptionClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubscriptionClientsQuery,
    GetSubscriptionClientsQueryVariables
  >(GetSubscriptionClientsDocument, options);
}
export function useGetSubscriptionClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionClientsQuery,
    GetSubscriptionClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionClientsQuery,
    GetSubscriptionClientsQueryVariables
  >(GetSubscriptionClientsDocument, options);
}
export type GetSubscriptionClientsQueryHookResult = ReturnType<
  typeof useGetSubscriptionClientsQuery
>;
export type GetSubscriptionClientsLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionClientsLazyQuery
>;
export type GetSubscriptionClientsQueryResult = Apollo.QueryResult<
  GetSubscriptionClientsQuery,
  GetSubscriptionClientsQueryVariables
>;
export const GetSubscriptionRequestsDocument = gql`
  query GetSubscriptionRequests($filter: SubscriptionFilter!) {
    subscriptionRequests(filter: $filter) {
      id
      client {
        id
        name
      }
      acceptedByUser {
        id
        name
      }
      readByUsers {
        id
        name
      }
      openedByUsers {
        id
        name
      }
      acceptedDate
      status
      created
      updated
    }
  }
`;

/**
 * __useGetSubscriptionRequestsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionRequestsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSubscriptionRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubscriptionRequestsQuery,
    GetSubscriptionRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubscriptionRequestsQuery,
    GetSubscriptionRequestsQueryVariables
  >(GetSubscriptionRequestsDocument, options);
}
export function useGetSubscriptionRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionRequestsQuery,
    GetSubscriptionRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionRequestsQuery,
    GetSubscriptionRequestsQueryVariables
  >(GetSubscriptionRequestsDocument, options);
}
export type GetSubscriptionRequestsQueryHookResult = ReturnType<
  typeof useGetSubscriptionRequestsQuery
>;
export type GetSubscriptionRequestsLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionRequestsLazyQuery
>;
export type GetSubscriptionRequestsQueryResult = Apollo.QueryResult<
  GetSubscriptionRequestsQuery,
  GetSubscriptionRequestsQueryVariables
>;
export const AddTermsOfServiceDocument = gql`
  mutation AddTermsOfService($input: TermsOfServiceInput) {
    addTermsOfService(input: $input) {
      ...TermsOfServiceValues
    }
  }
  ${TermsOfServiceValuesFragmentDoc}
`;
export type AddTermsOfServiceMutationFn = Apollo.MutationFunction<
  AddTermsOfServiceMutation,
  AddTermsOfServiceMutationVariables
>;

/**
 * __useAddTermsOfServiceMutation__
 *
 * To run a mutation, you first call `useAddTermsOfServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTermsOfServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTermsOfServiceMutation, { data, loading, error }] = useAddTermsOfServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTermsOfServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTermsOfServiceMutation,
    AddTermsOfServiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTermsOfServiceMutation,
    AddTermsOfServiceMutationVariables
  >(AddTermsOfServiceDocument, options);
}
export type AddTermsOfServiceMutationHookResult = ReturnType<
  typeof useAddTermsOfServiceMutation
>;
export type AddTermsOfServiceMutationResult =
  Apollo.MutationResult<AddTermsOfServiceMutation>;
export type AddTermsOfServiceMutationOptions = Apollo.BaseMutationOptions<
  AddTermsOfServiceMutation,
  AddTermsOfServiceMutationVariables
>;
export const DeleteTermsOfServiceDocument = gql`
  mutation DeleteTermsOfService($id: String!) {
    deleteTermsOfService(id: $id) {
      id
    }
  }
`;
export type DeleteTermsOfServiceMutationFn = Apollo.MutationFunction<
  DeleteTermsOfServiceMutation,
  DeleteTermsOfServiceMutationVariables
>;

/**
 * __useDeleteTermsOfServiceMutation__
 *
 * To run a mutation, you first call `useDeleteTermsOfServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTermsOfServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTermsOfServiceMutation, { data, loading, error }] = useDeleteTermsOfServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTermsOfServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTermsOfServiceMutation,
    DeleteTermsOfServiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTermsOfServiceMutation,
    DeleteTermsOfServiceMutationVariables
  >(DeleteTermsOfServiceDocument, options);
}
export type DeleteTermsOfServiceMutationHookResult = ReturnType<
  typeof useDeleteTermsOfServiceMutation
>;
export type DeleteTermsOfServiceMutationResult =
  Apollo.MutationResult<DeleteTermsOfServiceMutation>;
export type DeleteTermsOfServiceMutationOptions = Apollo.BaseMutationOptions<
  DeleteTermsOfServiceMutation,
  DeleteTermsOfServiceMutationVariables
>;
export const EditTermsOfServiceDocument = gql`
  mutation EditTermsOfService($id: String, $input: TermsOfServiceInput) {
    editTermsOfService(id: $id, input: $input) {
      ...TermsOfServiceValues
    }
  }
  ${TermsOfServiceValuesFragmentDoc}
`;
export type EditTermsOfServiceMutationFn = Apollo.MutationFunction<
  EditTermsOfServiceMutation,
  EditTermsOfServiceMutationVariables
>;

/**
 * __useEditTermsOfServiceMutation__
 *
 * To run a mutation, you first call `useEditTermsOfServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTermsOfServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTermsOfServiceMutation, { data, loading, error }] = useEditTermsOfServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTermsOfServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditTermsOfServiceMutation,
    EditTermsOfServiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditTermsOfServiceMutation,
    EditTermsOfServiceMutationVariables
  >(EditTermsOfServiceDocument, options);
}
export type EditTermsOfServiceMutationHookResult = ReturnType<
  typeof useEditTermsOfServiceMutation
>;
export type EditTermsOfServiceMutationResult =
  Apollo.MutationResult<EditTermsOfServiceMutation>;
export type EditTermsOfServiceMutationOptions = Apollo.BaseMutationOptions<
  EditTermsOfServiceMutation,
  EditTermsOfServiceMutationVariables
>;
export const GetTermsOfServiceDocument = gql`
  query GetTermsOfService($id: String) {
    termsOfService(id: $id) {
      ...TermsOfServiceValues
    }
  }
  ${TermsOfServiceValuesFragmentDoc}
`;

/**
 * __useGetTermsOfServiceQuery__
 *
 * To run a query within a React component, call `useGetTermsOfServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTermsOfServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTermsOfServiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTermsOfServiceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTermsOfServiceQuery,
    GetTermsOfServiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTermsOfServiceQuery,
    GetTermsOfServiceQueryVariables
  >(GetTermsOfServiceDocument, options);
}
export function useGetTermsOfServiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTermsOfServiceQuery,
    GetTermsOfServiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTermsOfServiceQuery,
    GetTermsOfServiceQueryVariables
  >(GetTermsOfServiceDocument, options);
}
export type GetTermsOfServiceQueryHookResult = ReturnType<
  typeof useGetTermsOfServiceQuery
>;
export type GetTermsOfServiceLazyQueryHookResult = ReturnType<
  typeof useGetTermsOfServiceLazyQuery
>;
export type GetTermsOfServiceQueryResult = Apollo.QueryResult<
  GetTermsOfServiceQuery,
  GetTermsOfServiceQueryVariables
>;
export const GetTermsOfServicesDocument = gql`
  query GetTermsOfServices($filter: TermsOfServiceFilter) {
    termsOfServices {
      termsOfServices(filter: $filter) {
        ...TermsOfServiceValues
      }
      count
    }
  }
  ${TermsOfServiceValuesFragmentDoc}
`;

/**
 * __useGetTermsOfServicesQuery__
 *
 * To run a query within a React component, call `useGetTermsOfServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTermsOfServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTermsOfServicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTermsOfServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTermsOfServicesQuery,
    GetTermsOfServicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTermsOfServicesQuery,
    GetTermsOfServicesQueryVariables
  >(GetTermsOfServicesDocument, options);
}
export function useGetTermsOfServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTermsOfServicesQuery,
    GetTermsOfServicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTermsOfServicesQuery,
    GetTermsOfServicesQueryVariables
  >(GetTermsOfServicesDocument, options);
}
export type GetTermsOfServicesQueryHookResult = ReturnType<
  typeof useGetTermsOfServicesQuery
>;
export type GetTermsOfServicesLazyQueryHookResult = ReturnType<
  typeof useGetTermsOfServicesLazyQuery
>;
export type GetTermsOfServicesQueryResult = Apollo.QueryResult<
  GetTermsOfServicesQuery,
  GetTermsOfServicesQueryVariables
>;
export const GetEndSearchRequestInformationDocument = gql`
  query GetEndSearchRequestInformation(
    $searchRequestReferenceId: String!
    $token: String!
  ) {
    searchRequestReferenceByToken(
      searchRequestReferenceId: $searchRequestReferenceId
      token: $token
    ) {
      title
      disabled
      clientId
      status
      disabledReason {
        comment
        reason
        client {
          name
        }
        providedClient
      }
    }
  }
`;

/**
 * __useGetEndSearchRequestInformationQuery__
 *
 * To run a query within a React component, call `useGetEndSearchRequestInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndSearchRequestInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndSearchRequestInformationQuery({
 *   variables: {
 *      searchRequestReferenceId: // value for 'searchRequestReferenceId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetEndSearchRequestInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEndSearchRequestInformationQuery,
    GetEndSearchRequestInformationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEndSearchRequestInformationQuery,
    GetEndSearchRequestInformationQueryVariables
  >(GetEndSearchRequestInformationDocument, options);
}
export function useGetEndSearchRequestInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEndSearchRequestInformationQuery,
    GetEndSearchRequestInformationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEndSearchRequestInformationQuery,
    GetEndSearchRequestInformationQueryVariables
  >(GetEndSearchRequestInformationDocument, options);
}
export type GetEndSearchRequestInformationQueryHookResult = ReturnType<
  typeof useGetEndSearchRequestInformationQuery
>;
export type GetEndSearchRequestInformationLazyQueryHookResult = ReturnType<
  typeof useGetEndSearchRequestInformationLazyQuery
>;
export type GetEndSearchRequestInformationQueryResult = Apollo.QueryResult<
  GetEndSearchRequestInformationQuery,
  GetEndSearchRequestInformationQueryVariables
>;
export const LoginUserDocument = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password)
  }
`;
export type LoginUserMutationFn = Apollo.MutationFunction<
  LoginUserMutation,
  LoginUserMutationVariables
>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginUserMutation,
    LoginUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(
    LoginUserDocument,
    options,
  );
}
export type LoginUserMutationHookResult = ReturnType<
  typeof useLoginUserMutation
>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<
  LoginUserMutation,
  LoginUserMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword(
    $token: String!
    $password: String!
    $acceptTerms: Boolean
    $firstName: String
    $lastName: String
  ) {
    resetPassword(
      token: $token
      password: $password
      acceptTerms: $acceptTerms
      firstName: $firstName
      lastName: $lastName
    )
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      acceptTerms: // value for 'acceptTerms'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const ResetPasswordLinkDocument = gql`
  mutation ResetPasswordLink($email: String!) {
    resetPasswordLink(email: $email)
  }
`;
export type ResetPasswordLinkMutationFn = Apollo.MutationFunction<
  ResetPasswordLinkMutation,
  ResetPasswordLinkMutationVariables
>;

/**
 * __useResetPasswordLinkMutation__
 *
 * To run a mutation, you first call `useResetPasswordLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordLinkMutation, { data, loading, error }] = useResetPasswordLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordLinkMutation,
    ResetPasswordLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordLinkMutation,
    ResetPasswordLinkMutationVariables
  >(ResetPasswordLinkDocument, options);
}
export type ResetPasswordLinkMutationHookResult = ReturnType<
  typeof useResetPasswordLinkMutation
>;
export type ResetPasswordLinkMutationResult =
  Apollo.MutationResult<ResetPasswordLinkMutation>;
export type ResetPasswordLinkMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordLinkMutation,
  ResetPasswordLinkMutationVariables
>;
export const ShareSearchRequestDocument = gql`
  mutation ShareSearchRequest(
    $input: ShareSearchRequestInput!
    $sendEmail: Boolean!
  ) {
    shareSearchRequest(input: $input, sendEmail: $sendEmail)
  }
`;
export type ShareSearchRequestMutationFn = Apollo.MutationFunction<
  ShareSearchRequestMutation,
  ShareSearchRequestMutationVariables
>;

/**
 * __useShareSearchRequestMutation__
 *
 * To run a mutation, you first call `useShareSearchRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareSearchRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareSearchRequestMutation, { data, loading, error }] = useShareSearchRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *      sendEmail: // value for 'sendEmail'
 *   },
 * });
 */
export function useShareSearchRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareSearchRequestMutation,
    ShareSearchRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ShareSearchRequestMutation,
    ShareSearchRequestMutationVariables
  >(ShareSearchRequestDocument, options);
}
export type ShareSearchRequestMutationHookResult = ReturnType<
  typeof useShareSearchRequestMutation
>;
export type ShareSearchRequestMutationResult =
  Apollo.MutationResult<ShareSearchRequestMutation>;
export type ShareSearchRequestMutationOptions = Apollo.BaseMutationOptions<
  ShareSearchRequestMutation,
  ShareSearchRequestMutationVariables
>;
export const CheckTokenDocument = gql`
  query CheckToken($token: String!, $type: AccountCreationType!) {
    checkToken(token: $token, type: $type) {
      valid
      exists
      createAccountInfo {
        email
        name
        client
      }
    }
  }
`;

/**
 * __useCheckTokenQuery__
 *
 * To run a query within a React component, call `useCheckTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCheckTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckTokenQuery,
    CheckTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckTokenQuery, CheckTokenQueryVariables>(
    CheckTokenDocument,
    options,
  );
}
export function useCheckTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckTokenQuery,
    CheckTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckTokenQuery, CheckTokenQueryVariables>(
    CheckTokenDocument,
    options,
  );
}
export type CheckTokenQueryHookResult = ReturnType<typeof useCheckTokenQuery>;
export type CheckTokenLazyQueryHookResult = ReturnType<
  typeof useCheckTokenLazyQuery
>;
export type CheckTokenQueryResult = Apollo.QueryResult<
  CheckTokenQuery,
  CheckTokenQueryVariables
>;
export const GetNewLinkDocument = gql`
  mutation GetNewLink(
    $token: String!
    $type: AccountCreationType!
    $searchRequestReferenceId: String
  ) {
    getNewLink(
      token: $token
      type: $type
      searchRequestReferenceId: $searchRequestReferenceId
    )
  }
`;
export type GetNewLinkMutationFn = Apollo.MutationFunction<
  GetNewLinkMutation,
  GetNewLinkMutationVariables
>;

/**
 * __useGetNewLinkMutation__
 *
 * To run a mutation, you first call `useGetNewLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetNewLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getNewLinkMutation, { data, loading, error }] = useGetNewLinkMutation({
 *   variables: {
 *      token: // value for 'token'
 *      type: // value for 'type'
 *      searchRequestReferenceId: // value for 'searchRequestReferenceId'
 *   },
 * });
 */
export function useGetNewLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetNewLinkMutation,
    GetNewLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetNewLinkMutation, GetNewLinkMutationVariables>(
    GetNewLinkDocument,
    options,
  );
}
export type GetNewLinkMutationHookResult = ReturnType<
  typeof useGetNewLinkMutation
>;
export type GetNewLinkMutationResult =
  Apollo.MutationResult<GetNewLinkMutation>;
export type GetNewLinkMutationOptions = Apollo.BaseMutationOptions<
  GetNewLinkMutation,
  GetNewLinkMutationVariables
>;
export const SignUpDocument = gql`
  mutation SignUp($email: String!) {
    signUp(email: $email)
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpMutation,
    SignUpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    options,
  );
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const RegisterDocument = gql`
  mutation Register($input: UserInput!) {
    register(input: $input) {
      id
      name
    }
  }
`;
export type RegisterMutationFn = Apollo.MutationFunction<
  RegisterMutation,
  RegisterMutationVariables
>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterMutation,
    RegisterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(
    RegisterDocument,
    options,
  );
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<
  RegisterMutation,
  RegisterMutationVariables
>;
export const UnsubscribeFromEmailDocument = gql`
  mutation UnsubscribeFromEmail($email: String!) {
    unsubscribeEmail(email: $email)
  }
`;
export type UnsubscribeFromEmailMutationFn = Apollo.MutationFunction<
  UnsubscribeFromEmailMutation,
  UnsubscribeFromEmailMutationVariables
>;

/**
 * __useUnsubscribeFromEmailMutation__
 *
 * To run a mutation, you first call `useUnsubscribeFromEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeFromEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeFromEmailMutation, { data, loading, error }] = useUnsubscribeFromEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUnsubscribeFromEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsubscribeFromEmailMutation,
    UnsubscribeFromEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnsubscribeFromEmailMutation,
    UnsubscribeFromEmailMutationVariables
  >(UnsubscribeFromEmailDocument, options);
}
export type UnsubscribeFromEmailMutationHookResult = ReturnType<
  typeof useUnsubscribeFromEmailMutation
>;
export type UnsubscribeFromEmailMutationResult =
  Apollo.MutationResult<UnsubscribeFromEmailMutation>;
export type UnsubscribeFromEmailMutationOptions = Apollo.BaseMutationOptions<
  UnsubscribeFromEmailMutation,
  UnsubscribeFromEmailMutationVariables
>;
export const AddUserDocument = gql`
  mutation AddUser($input: UserInput!) {
    addUser(input: $input) {
      ...UserValues
    }
  }
  ${UserValuesFragmentDoc}
`;
export type AddUserMutationFn = Apollo.MutationFunction<
  AddUserMutation,
  AddUserMutationVariables
>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserMutation,
    AddUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(
    AddUserDocument,
    options,
  );
}
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<
  AddUserMutation,
  AddUserMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id) {
      id
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options,
  );
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
  Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const EditUserDocument = gql`
  mutation EditUser($id: String!, $input: UserInput!) {
    editUser(id: $id, input: $input) {
      ...UserValues
    }
  }
  ${UserValuesFragmentDoc}
`;
export type EditUserMutationFn = Apollo.MutationFunction<
  EditUserMutation,
  EditUserMutationVariables
>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditUserMutation,
    EditUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(
    EditUserDocument,
    options,
  );
}
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<
  EditUserMutation,
  EditUserMutationVariables
>;
export const GetMeDocument = gql`
  query GetMe {
    me {
      id
      firstName
      lastName
      email
      role
      activeClientId
      activeClient {
        id
        name
        type
      }
      clients {
        id
        name
      }
      image {
        url
        id
        size
        name
      }
      limited
      showMine
      emailSettings {
        enable
        onMessage
        onMatch
        onSearchHit
        onIntresting
        onSearchRequest
        reminders
      }
      activeModal
      activeSubscription {
        id
        type
      }
      jobTitle
      userCategory
      verified
    }
  }
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options,
  );
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options,
  );
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<
  GetMeQuery,
  GetMeQueryVariables
>;
export const GetUserDocument = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      ...UserValues
      receivedWelcomeEmail
    }
  }
  ${UserValuesFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options,
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserQuery,
    GetUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options,
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const GetUserViewDocument = gql`
  query GetUserView($id: String!, $filter: ActivityLogFilterInput!) {
    user(id: $id) {
      id
      name
      role
      email
      updated
      created
      image {
        id
        url
      }
      receivedWelcomeEmail
      failedLogins
      access
      logins
      phone
      activeClient {
        id
        name
      }
      clients {
        id
        name
        hasCommission
        image {
          id
          url
        }
      }
      searchRequestSettings {
        regions {
          id
          name
        }
        regionIds
        usageCategories
        size
        filterEmails
      }
      activityStatus
      activity(filter: $filter) {
        count
        activities {
          ...ActivityLogValues
        }
      }
    }
  }
  ${ActivityLogValuesFragmentDoc}
`;

/**
 * __useGetUserViewQuery__
 *
 * To run a query within a React component, call `useGetUserViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetUserViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserViewQuery,
    GetUserViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserViewQuery, GetUserViewQueryVariables>(
    GetUserViewDocument,
    options,
  );
}
export function useGetUserViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserViewQuery,
    GetUserViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserViewQuery, GetUserViewQueryVariables>(
    GetUserViewDocument,
    options,
  );
}
export type GetUserViewQueryHookResult = ReturnType<typeof useGetUserViewQuery>;
export type GetUserViewLazyQueryHookResult = ReturnType<
  typeof useGetUserViewLazyQuery
>;
export type GetUserViewQueryResult = Apollo.QueryResult<
  GetUserViewQuery,
  GetUserViewQueryVariables
>;
export const GetUsersDocument = gql`
  query GetUsers($filter: UserFilterInput!, $sort: String, $order: String) {
    users(filter: $filter, sort: $sort, order: $order) {
      users {
        id
        name
        role
        email
        lastName
        firstName
        updated
        created
        access
        logins
        image {
          id
          url(
            processing: {
              quality: auto
              height: 100
              width: 100
              gravity: auto
              crop: fill
            }
          )
        }
        searchRequestSettings {
          regions {
            id
            name
          }
          size
          usageCategories
        }
        activeClient {
          id
          hasCommission
        }
        activityStatus
      }
      count
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options,
  );
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options,
  );
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<
  typeof useGetUsersLazyQuery
>;
export type GetUsersQueryResult = Apollo.QueryResult<
  GetUsersQuery,
  GetUsersQueryVariables
>;
export const SendNewsMessageDocument = gql`
  mutation SendNewsMessage($input: SendNewsMessageInput!) {
    sendNewsMessage(input: $input)
  }
`;
export type SendNewsMessageMutationFn = Apollo.MutationFunction<
  SendNewsMessageMutation,
  SendNewsMessageMutationVariables
>;

/**
 * __useSendNewsMessageMutation__
 *
 * To run a mutation, you first call `useSendNewsMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNewsMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNewsMessageMutation, { data, loading, error }] = useSendNewsMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendNewsMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendNewsMessageMutation,
    SendNewsMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendNewsMessageMutation,
    SendNewsMessageMutationVariables
  >(SendNewsMessageDocument, options);
}
export type SendNewsMessageMutationHookResult = ReturnType<
  typeof useSendNewsMessageMutation
>;
export type SendNewsMessageMutationResult =
  Apollo.MutationResult<SendNewsMessageMutation>;
export type SendNewsMessageMutationOptions = Apollo.BaseMutationOptions<
  SendNewsMessageMutation,
  SendNewsMessageMutationVariables
>;
export const SendWelcomeEmailDocument = gql`
  mutation SendWelcomeEmail($userId: String!) {
    sendWelcomeEmail(userId: $userId) {
      id
      prospect
    }
  }
`;
export type SendWelcomeEmailMutationFn = Apollo.MutationFunction<
  SendWelcomeEmailMutation,
  SendWelcomeEmailMutationVariables
>;

/**
 * __useSendWelcomeEmailMutation__
 *
 * To run a mutation, you first call `useSendWelcomeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendWelcomeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendWelcomeEmailMutation, { data, loading, error }] = useSendWelcomeEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSendWelcomeEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendWelcomeEmailMutation,
    SendWelcomeEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendWelcomeEmailMutation,
    SendWelcomeEmailMutationVariables
  >(SendWelcomeEmailDocument, options);
}
export type SendWelcomeEmailMutationHookResult = ReturnType<
  typeof useSendWelcomeEmailMutation
>;
export type SendWelcomeEmailMutationResult =
  Apollo.MutationResult<SendWelcomeEmailMutation>;
export type SendWelcomeEmailMutationOptions = Apollo.BaseMutationOptions<
  SendWelcomeEmailMutation,
  SendWelcomeEmailMutationVariables
>;
export const TestNewsMessageDocument = gql`
  mutation TestNewsMessage($email: EmailContent!, $recipient: String!) {
    testNewsMessage(email: $email, recipient: $recipient)
  }
`;
export type TestNewsMessageMutationFn = Apollo.MutationFunction<
  TestNewsMessageMutation,
  TestNewsMessageMutationVariables
>;

/**
 * __useTestNewsMessageMutation__
 *
 * To run a mutation, you first call `useTestNewsMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestNewsMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testNewsMessageMutation, { data, loading, error }] = useTestNewsMessageMutation({
 *   variables: {
 *      email: // value for 'email'
 *      recipient: // value for 'recipient'
 *   },
 * });
 */
export function useTestNewsMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TestNewsMessageMutation,
    TestNewsMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TestNewsMessageMutation,
    TestNewsMessageMutationVariables
  >(TestNewsMessageDocument, options);
}
export type TestNewsMessageMutationHookResult = ReturnType<
  typeof useTestNewsMessageMutation
>;
export type TestNewsMessageMutationResult =
  Apollo.MutationResult<TestNewsMessageMutation>;
export type TestNewsMessageMutationOptions = Apollo.BaseMutationOptions<
  TestNewsMessageMutation,
  TestNewsMessageMutationVariables
>;
export type AccessKeySpecifier = ('quarter' | 'year' | AccessKeySpecifier)[];
export type AccessFieldPolicy = {
  quarter?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ActionCountKeySpecifier = (
  | 'count'
  | 'date'
  | ActionCountKeySpecifier
)[];
export type ActionCountFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ActionStatsKeySpecifier = (
  | 'total'
  | 'unanswered'
  | ActionStatsKeySpecifier
)[];
export type ActionStatsFieldPolicy = {
  total?: FieldPolicy<any> | FieldReadFunction<any>;
  unanswered?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ActiveSearchRequestDataKeySpecifier = (
  | 'all'
  | 'archived'
  | 'inProgress'
  | 'interesting'
  | 'negotiation'
  | 'notifications'
  | 'success'
  | 'todo'
  | ActiveSearchRequestDataKeySpecifier
)[];
export type ActiveSearchRequestDataFieldPolicy = {
  all?: FieldPolicy<any> | FieldReadFunction<any>;
  archived?: FieldPolicy<any> | FieldReadFunction<any>;
  inProgress?: FieldPolicy<any> | FieldReadFunction<any>;
  interesting?: FieldPolicy<any> | FieldReadFunction<any>;
  negotiation?: FieldPolicy<any> | FieldReadFunction<any>;
  notifications?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  todo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ActiveUserStatsKeySpecifier = (
  | 'active'
  | 'activeOverTime'
  | 'lost'
  | ActiveUserStatsKeySpecifier
)[];
export type ActiveUserStatsFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  activeOverTime?: FieldPolicy<any> | FieldReadFunction<any>;
  lost?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ActivityDataKeySpecifier = (
  | 'activities'
  | 'count'
  | 'statistics'
  | ActivityDataKeySpecifier
)[];
export type ActivityDataFieldPolicy = {
  activities?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  statistics?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ActivityLogKeySpecifier = (
  | 'client'
  | 'conversation'
  | 'created'
  | 'id'
  | 'leaseAgreement'
  | 'searchRequest'
  | 'statusUpdate'
  | 'type'
  | 'user'
  | ActivityLogKeySpecifier
)[];
export type ActivityLogFieldPolicy = {
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  conversation?: FieldPolicy<any> | FieldReadFunction<any>;
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  statusUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ActivityLogStatisticsKeySpecifier = (
  | 'ATH'
  | 'average'
  | 'today'
  | 'yesterday'
  | ActivityLogStatisticsKeySpecifier
)[];
export type ActivityLogStatisticsFieldPolicy = {
  ATH?: FieldPolicy<any> | FieldReadFunction<any>;
  average?: FieldPolicy<any> | FieldReadFunction<any>;
  today?: FieldPolicy<any> | FieldReadFunction<any>;
  yesterday?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AddressKeySpecifier = (
  | 'address'
  | 'city'
  | 'street'
  | 'streetNumber'
  | 'zipCode'
  | AddressKeySpecifier
)[];
export type AddressFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  street?: FieldPolicy<any> | FieldReadFunction<any>;
  streetNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  zipCode?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AdminStatusCountKeySpecifier = (
  | 'accepted'
  | 'answer_requested'
  | 'declined'
  | 'opened'
  | AdminStatusCountKeySpecifier
)[];
export type AdminStatusCountFieldPolicy = {
  accepted?: FieldPolicy<any> | FieldReadFunction<any>;
  answer_requested?: FieldPolicy<any> | FieldReadFunction<any>;
  declined?: FieldPolicy<any> | FieldReadFunction<any>;
  opened?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AllProspectDataKeySpecifier = (
  | 'averagesData'
  | 'divisionData'
  | 'overviewData'
  | 'processData'
  | 'processValuesData'
  | 'prospectData'
  | 'prospectStockCountData'
  | 'prospectStockData'
  | 'prospectStockDataOld'
  | 'timeAverageInStepData'
  | 'timeAverageToOrderData'
  | AllProspectDataKeySpecifier
)[];
export type AllProspectDataFieldPolicy = {
  averagesData?: FieldPolicy<any> | FieldReadFunction<any>;
  divisionData?: FieldPolicy<any> | FieldReadFunction<any>;
  overviewData?: FieldPolicy<any> | FieldReadFunction<any>;
  processData?: FieldPolicy<any> | FieldReadFunction<any>;
  processValuesData?: FieldPolicy<any> | FieldReadFunction<any>;
  prospectData?: FieldPolicy<any> | FieldReadFunction<any>;
  prospectStockCountData?: FieldPolicy<any> | FieldReadFunction<any>;
  prospectStockData?: FieldPolicy<any> | FieldReadFunction<any>;
  prospectStockDataOld?: FieldPolicy<any> | FieldReadFunction<any>;
  timeAverageInStepData?: FieldPolicy<any> | FieldReadFunction<any>;
  timeAverageToOrderData?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AnalysisKeySpecifier = (
  | 'cohort'
  | 'searchRequests'
  | AnalysisKeySpecifier
)[];
export type AnalysisFieldPolicy = {
  cohort?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequests?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ApiCredentialsKeySpecifier = (
  | 'key'
  | 'providerId'
  | ApiCredentialsKeySpecifier
)[];
export type ApiCredentialsFieldPolicy = {
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  providerId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AttachmentKeySpecifier = (
  | 'attachment'
  | 'attachmentId'
  | 'conversation'
  | 'conversationId'
  | 'created'
  | 'deleted'
  | 'id'
  | AttachmentKeySpecifier
)[];
export type AttachmentFieldPolicy = {
  attachment?: FieldPolicy<any> | FieldReadFunction<any>;
  attachmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  conversation?: FieldPolicy<any> | FieldReadFunction<any>;
  conversationId?: FieldPolicy<any> | FieldReadFunction<any>;
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  deleted?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AttachmentDataKeySpecifier = (
  | 'attachments'
  | 'count'
  | AttachmentDataKeySpecifier
)[];
export type AttachmentDataFieldPolicy = {
  attachments?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AutoCompleteClientKeySpecifier = (
  | 'label'
  | 'value'
  | AutoCompleteClientKeySpecifier
)[];
export type AutoCompleteClientFieldPolicy = {
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AutoCompleteUserKeySpecifier = (
  | 'client'
  | 'label'
  | 'value'
  | AutoCompleteUserKeySpecifier
)[];
export type AutoCompleteUserFieldPolicy = {
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AutocompleteRegionsKeySpecifier = (
  | 'id'
  | 'type'
  | 'urlSlug'
  | 'value'
  | AutocompleteRegionsKeySpecifier
)[];
export type AutocompleteRegionsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  urlSlug?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AutocompleteResultKeySpecifier = (
  | 'id'
  | 'value'
  | AutocompleteResultKeySpecifier
)[];
export type AutocompleteResultFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BillingAddressKeySpecifier = (
  | 'city'
  | 'street'
  | 'streetNumber'
  | 'zipCode'
  | BillingAddressKeySpecifier
)[];
export type BillingAddressFieldPolicy = {
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  street?: FieldPolicy<any> | FieldReadFunction<any>;
  streetNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  zipCode?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BillingAdressKeySpecifier = (
  | 'city'
  | 'street'
  | 'streetNumber'
  | 'zipCode'
  | BillingAdressKeySpecifier
)[];
export type BillingAdressFieldPolicy = {
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  street?: FieldPolicy<any> | FieldReadFunction<any>;
  streetNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  zipCode?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CategorizedPromotedDataKeySpecifier = (
  | 'accepted'
  | 'declined'
  | 'unanswered'
  | CategorizedPromotedDataKeySpecifier
)[];
export type CategorizedPromotedDataFieldPolicy = {
  accepted?: FieldPolicy<any> | FieldReadFunction<any>;
  declined?: FieldPolicy<any> | FieldReadFunction<any>;
  unanswered?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChangelogKeySpecifier = (
  | 'clientId'
  | 'id'
  | 'leaseAgreementId'
  | 'message'
  | 'timestamp'
  | 'type'
  | 'userId'
  | ChangelogKeySpecifier
)[];
export type ChangelogFieldPolicy = {
  clientId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreementId?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  timestamp?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClientKeySpecifier = (
  | 'address'
  | 'ageGroup'
  | 'brandCategory'
  | 'brandSubCategory'
  | 'cancelableTime'
  | 'clientManager'
  | 'collaborationPartners'
  | 'comment'
  | 'companyName'
  | 'contacts'
  | 'conversationStatistics'
  | 'created'
  | 'currentPromotions'
  | 'currentRequests'
  | 'currentSaleStair'
  | 'deals'
  | 'description'
  | 'eCommerce'
  | 'eCommerceTurnover'
  | 'ebieScore'
  | 'email'
  | 'estateCount'
  | 'estimatedSize'
  | 'facebook'
  | 'facebookFollowers'
  | 'gateCustomer'
  | 'group'
  | 'hasComment'
  | 'hasCommission'
  | 'hasContacts'
  | 'hasDescription'
  | 'hasSearchProfile'
  | 'id'
  | 'image'
  | 'imageId'
  | 'includeObjektvisionEmails'
  | 'incomeGroup'
  | 'industryCategory'
  | 'industryCode'
  | 'instagram'
  | 'instagramFollowers'
  | 'invoiceDetails'
  | 'isCustomer'
  | 'isPartner'
  | 'keyValues'
  | 'leaseAgreementCount'
  | 'levelOfEducation'
  | 'lifeStyle'
  | 'limeApiKey'
  | 'location'
  | 'lokalguidenId'
  | 'name'
  | 'neighbourIds'
  | 'neighbours'
  | 'notifySupport'
  | 'numberOfEmployees'
  | 'numberOfUnits'
  | 'objektvisionId'
  | 'objektvisionIds'
  | 'operatingProfit'
  | 'orgNumber'
  | 'parentCompany'
  | 'parentCompanyOrgNumber'
  | 'physicalStore'
  | 'postAddress'
  | 'priceRange'
  | 'prospects'
  | 'rating'
  | 'responsibleConsultant'
  | 'responsibleConsultantId'
  | 'searchProfileDescription'
  | 'searchRequestReferences'
  | 'showOnWebsite'
  | 'showPageOnWebsite'
  | 'slug'
  | 'stores'
  | 'subscriptions'
  | 'supportStatus'
  | 'targetGroupChild'
  | 'targetGroupChildAge'
  | 'targetGroupDescription'
  | 'targetGroupMan'
  | 'targetGroupManAge'
  | 'targetGroupWoman'
  | 'targetGroupWomanAge'
  | 'telephone'
  | 'termsOfService'
  | 'turnover'
  | 'type'
  | 'typeOfBusiness'
  | 'typesOfPhysicalStores'
  | 'typesOfProperty'
  | 'updated'
  | 'users'
  | 'visitingAddress'
  | 'vitecLicenceIds'
  | 'website'
  | 'websiteDescription'
  | 'websiteImage'
  | 'websiteName'
  | ClientKeySpecifier
)[];
export type ClientFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  ageGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  brandCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  brandSubCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelableTime?: FieldPolicy<any> | FieldReadFunction<any>;
  clientManager?: FieldPolicy<any> | FieldReadFunction<any>;
  collaborationPartners?: FieldPolicy<any> | FieldReadFunction<any>;
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  companyName?: FieldPolicy<any> | FieldReadFunction<any>;
  contacts?: FieldPolicy<any> | FieldReadFunction<any>;
  conversationStatistics?: FieldPolicy<any> | FieldReadFunction<any>;
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  currentPromotions?: FieldPolicy<any> | FieldReadFunction<any>;
  currentRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  currentSaleStair?: FieldPolicy<any> | FieldReadFunction<any>;
  deals?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  eCommerce?: FieldPolicy<any> | FieldReadFunction<any>;
  eCommerceTurnover?: FieldPolicy<any> | FieldReadFunction<any>;
  ebieScore?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  estateCount?: FieldPolicy<any> | FieldReadFunction<any>;
  estimatedSize?: FieldPolicy<any> | FieldReadFunction<any>;
  facebook?: FieldPolicy<any> | FieldReadFunction<any>;
  facebookFollowers?: FieldPolicy<any> | FieldReadFunction<any>;
  gateCustomer?: FieldPolicy<any> | FieldReadFunction<any>;
  group?: FieldPolicy<any> | FieldReadFunction<any>;
  hasComment?: FieldPolicy<any> | FieldReadFunction<any>;
  hasCommission?: FieldPolicy<any> | FieldReadFunction<any>;
  hasContacts?: FieldPolicy<any> | FieldReadFunction<any>;
  hasDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  hasSearchProfile?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  imageId?: FieldPolicy<any> | FieldReadFunction<any>;
  includeObjektvisionEmails?: FieldPolicy<any> | FieldReadFunction<any>;
  incomeGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  industryCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  industryCode?: FieldPolicy<any> | FieldReadFunction<any>;
  instagram?: FieldPolicy<any> | FieldReadFunction<any>;
  instagramFollowers?: FieldPolicy<any> | FieldReadFunction<any>;
  invoiceDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  isCustomer?: FieldPolicy<any> | FieldReadFunction<any>;
  isPartner?: FieldPolicy<any> | FieldReadFunction<any>;
  keyValues?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreementCount?: FieldPolicy<any> | FieldReadFunction<any>;
  levelOfEducation?: FieldPolicy<any> | FieldReadFunction<any>;
  lifeStyle?: FieldPolicy<any> | FieldReadFunction<any>;
  limeApiKey?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  lokalguidenId?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  neighbourIds?: FieldPolicy<any> | FieldReadFunction<any>;
  neighbours?: FieldPolicy<any> | FieldReadFunction<any>;
  notifySupport?: FieldPolicy<any> | FieldReadFunction<any>;
  numberOfEmployees?: FieldPolicy<any> | FieldReadFunction<any>;
  numberOfUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  objektvisionId?: FieldPolicy<any> | FieldReadFunction<any>;
  objektvisionIds?: FieldPolicy<any> | FieldReadFunction<any>;
  operatingProfit?: FieldPolicy<any> | FieldReadFunction<any>;
  orgNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  parentCompany?: FieldPolicy<any> | FieldReadFunction<any>;
  parentCompanyOrgNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  physicalStore?: FieldPolicy<any> | FieldReadFunction<any>;
  postAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  priceRange?: FieldPolicy<any> | FieldReadFunction<any>;
  prospects?: FieldPolicy<any> | FieldReadFunction<any>;
  rating?: FieldPolicy<any> | FieldReadFunction<any>;
  responsibleConsultant?: FieldPolicy<any> | FieldReadFunction<any>;
  responsibleConsultantId?: FieldPolicy<any> | FieldReadFunction<any>;
  searchProfileDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestReferences?: FieldPolicy<any> | FieldReadFunction<any>;
  showOnWebsite?: FieldPolicy<any> | FieldReadFunction<any>;
  showPageOnWebsite?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  stores?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptions?: FieldPolicy<any> | FieldReadFunction<any>;
  supportStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  targetGroupChild?: FieldPolicy<any> | FieldReadFunction<any>;
  targetGroupChildAge?: FieldPolicy<any> | FieldReadFunction<any>;
  targetGroupDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  targetGroupMan?: FieldPolicy<any> | FieldReadFunction<any>;
  targetGroupManAge?: FieldPolicy<any> | FieldReadFunction<any>;
  targetGroupWoman?: FieldPolicy<any> | FieldReadFunction<any>;
  targetGroupWomanAge?: FieldPolicy<any> | FieldReadFunction<any>;
  telephone?: FieldPolicy<any> | FieldReadFunction<any>;
  termsOfService?: FieldPolicy<any> | FieldReadFunction<any>;
  turnover?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  typeOfBusiness?: FieldPolicy<any> | FieldReadFunction<any>;
  typesOfPhysicalStores?: FieldPolicy<any> | FieldReadFunction<any>;
  typesOfProperty?: FieldPolicy<any> | FieldReadFunction<any>;
  updated?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
  visitingAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  vitecLicenceIds?: FieldPolicy<any> | FieldReadFunction<any>;
  website?: FieldPolicy<any> | FieldReadFunction<any>;
  websiteDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  websiteImage?: FieldPolicy<any> | FieldReadFunction<any>;
  websiteName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClientDataKeySpecifier = (
  | 'clients'
  | 'count'
  | ClientDataKeySpecifier
)[];
export type ClientDataFieldPolicy = {
  clients?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClientDealsKeySpecifier = (
  | 'count'
  | 'portallysFee'
  | 'value'
  | ClientDealsKeySpecifier
)[];
export type ClientDealsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  portallysFee?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClusterKeySpecifier = (
  | 'count'
  | 'id'
  | 'location'
  | ClusterKeySpecifier
)[];
export type ClusterFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CohortAnalysisKeySpecifier = (
  | 'converted'
  | 'date'
  | 'registered'
  | CohortAnalysisKeySpecifier
)[];
export type CohortAnalysisFieldPolicy = {
  converted?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  registered?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ColleaguesDataKeySpecifier = (
  | 'colleagues'
  | 'count'
  | ColleaguesDataKeySpecifier
)[];
export type ColleaguesDataFieldPolicy = {
  colleagues?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContactKeySpecifier = (
  | 'client'
  | 'clientId'
  | 'email'
  | 'emailsEnabled'
  | 'firstName'
  | 'id'
  | 'lastName'
  | 'name'
  | 'phone'
  | 'title'
  | 'userExists'
  | ContactKeySpecifier
)[];
export type ContactFieldPolicy = {
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  clientId?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  emailsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  userExists?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContactInformationKeySpecifier = (
  | 'company'
  | 'email'
  | 'phone'
  | ContactInformationKeySpecifier
)[];
export type ContactInformationFieldPolicy = {
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContactPersonKeySpecifier = (
  | 'email'
  | 'firstName'
  | 'image'
  | 'lastName'
  | 'professionalTitle'
  | ContactPersonKeySpecifier
)[];
export type ContactPersonFieldPolicy = {
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  professionalTitle?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ConversationKeySpecifier = (
  | 'archived'
  | 'attachmentData'
  | 'fromPortally'
  | 'id'
  | 'isPartner'
  | 'leaseAgreement'
  | 'leaseAgreementId'
  | 'messages'
  | 'opponents'
  | 'participants'
  | 'pending'
  | 'searchRequest'
  | 'searchRequestId'
  | 'searchRequestReference'
  | 'searchRequestReferenceId'
  | 'unanswered'
  | 'unread'
  | ConversationKeySpecifier
)[];
export type ConversationFieldPolicy = {
  archived?: FieldPolicy<any> | FieldReadFunction<any>;
  attachmentData?: FieldPolicy<any> | FieldReadFunction<any>;
  fromPortally?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isPartner?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreementId?: FieldPolicy<any> | FieldReadFunction<any>;
  messages?: FieldPolicy<any> | FieldReadFunction<any>;
  opponents?: FieldPolicy<any> | FieldReadFunction<any>;
  participants?: FieldPolicy<any> | FieldReadFunction<any>;
  pending?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestId?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestReference?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestReferenceId?: FieldPolicy<any> | FieldReadFunction<any>;
  unanswered?: FieldPolicy<any> | FieldReadFunction<any>;
  unread?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ConversationDataKeySpecifier = (
  | 'conversations'
  | 'count'
  | 'unread'
  | ConversationDataKeySpecifier
)[];
export type ConversationDataFieldPolicy = {
  conversations?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  unread?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ConversationFilterAutocompleteKeySpecifier = (
  | 'clients'
  | 'users'
  | ConversationFilterAutocompleteKeySpecifier
)[];
export type ConversationFilterAutocompleteFieldPolicy = {
  clients?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ConversationStatisticsKeySpecifier = (
  | 'conversations'
  | 'count'
  | 'unanswered'
  | ConversationStatisticsKeySpecifier
)[];
export type ConversationStatisticsFieldPolicy = {
  conversations?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  unanswered?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ConversionRateDataKeySpecifier = (
  | 'averageSent'
  | 'incoming'
  | 'outgoing'
  | 'removed'
  | ConversionRateDataKeySpecifier
)[];
export type ConversionRateDataFieldPolicy = {
  averageSent?: FieldPolicy<any> | FieldReadFunction<any>;
  incoming?: FieldPolicy<any> | FieldReadFunction<any>;
  outgoing?: FieldPolicy<any> | FieldReadFunction<any>;
  removed?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountByRegionKeySpecifier = (
  | 'count'
  | 'region'
  | 'regionId'
  | CountByRegionKeySpecifier
)[];
export type CountByRegionFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  region?: FieldPolicy<any> | FieldReadFunction<any>;
  regionId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountBySizeKeySpecifier = (
  | 'count'
  | 'size'
  | CountBySizeKeySpecifier
)[];
export type CountBySizeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  size?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountByUsageCategoryKeySpecifier = (
  | 'count'
  | 'usageCategory'
  | CountByUsageCategoryKeySpecifier
)[];
export type CountByUsageCategoryFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  usageCategory?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateAccountInfoKeySpecifier = (
  | 'client'
  | 'email'
  | 'name'
  | CreateAccountInfoKeySpecifier
)[];
export type CreateAccountInfoFieldPolicy = {
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DateStatKeySpecifier = ('count' | 'date' | DateStatKeySpecifier)[];
export type DateStatFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DaysInStepKeySpecifier = (
  | 'bookedMeeting'
  | 'finalNegotiation'
  | 'heldMeeting'
  | 'lead'
  | 'order'
  | 'sentOffer'
  | DaysInStepKeySpecifier
)[];
export type DaysInStepFieldPolicy = {
  bookedMeeting?: FieldPolicy<any> | FieldReadFunction<any>;
  finalNegotiation?: FieldPolicy<any> | FieldReadFunction<any>;
  heldMeeting?: FieldPolicy<any> | FieldReadFunction<any>;
  lead?: FieldPolicy<any> | FieldReadFunction<any>;
  order?: FieldPolicy<any> | FieldReadFunction<any>;
  sentOffer?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DealKeySpecifier = (
  | 'annualRent'
  | 'billing'
  | 'billingAdress'
  | 'client'
  | 'clientId'
  | 'comment'
  | 'company'
  | 'created'
  | 'email'
  | 'id'
  | 'madeWith'
  | 'marking'
  | 'portallyFee'
  | 'reference'
  | 'subscription'
  | 'treated'
  | 'user'
  | 'userId'
  | DealKeySpecifier
)[];
export type DealFieldPolicy = {
  annualRent?: FieldPolicy<any> | FieldReadFunction<any>;
  billing?: FieldPolicy<any> | FieldReadFunction<any>;
  billingAdress?: FieldPolicy<any> | FieldReadFunction<any>;
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  clientId?: FieldPolicy<any> | FieldReadFunction<any>;
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  madeWith?: FieldPolicy<any> | FieldReadFunction<any>;
  marking?: FieldPolicy<any> | FieldReadFunction<any>;
  portallyFee?: FieldPolicy<any> | FieldReadFunction<any>;
  reference?: FieldPolicy<any> | FieldReadFunction<any>;
  subscription?: FieldPolicy<any> | FieldReadFunction<any>;
  treated?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DealClientKeySpecifier = (
  | 'client'
  | 'dealsCount'
  | 'dealsValue'
  | 'userCount'
  | DealClientKeySpecifier
)[];
export type DealClientFieldPolicy = {
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  dealsCount?: FieldPolicy<any> | FieldReadFunction<any>;
  dealsValue?: FieldPolicy<any> | FieldReadFunction<any>;
  userCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DealClientDataKeySpecifier = (
  | 'clients'
  | 'count'
  | DealClientDataKeySpecifier
)[];
export type DealClientDataFieldPolicy = {
  clients?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DealDataKeySpecifier = (
  | 'count'
  | 'deals'
  | 'value'
  | DealDataKeySpecifier
)[];
export type DealDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  deals?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DealSubscriptionKeySpecifier = (
  | 'type'
  | 'version'
  | DealSubscriptionKeySpecifier
)[];
export type DealSubscriptionFieldPolicy = {
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  version?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DisableSearchRequestReasonKeySpecifier = (
  | 'client'
  | 'comment'
  | 'contactViaPortally'
  | 'providedClient'
  | 'reason'
  | DisableSearchRequestReasonKeySpecifier
)[];
export type DisableSearchRequestReasonFieldPolicy = {
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  contactViaPortally?: FieldPolicy<any> | FieldReadFunction<any>;
  providedClient?: FieldPolicy<any> | FieldReadFunction<any>;
  reason?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DisabledSearchRequestAnalysisKeySpecifier = (
  | 'adminNeverLoggedIn'
  | 'adminNoActivity'
  | 'count'
  | 'dislike'
  | 'elsewhere'
  | 'notrelevant'
  | 'stationary'
  | 'success'
  | DisabledSearchRequestAnalysisKeySpecifier
)[];
export type DisabledSearchRequestAnalysisFieldPolicy = {
  adminNeverLoggedIn?: FieldPolicy<any> | FieldReadFunction<any>;
  adminNoActivity?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  dislike?: FieldPolicy<any> | FieldReadFunction<any>;
  elsewhere?: FieldPolicy<any> | FieldReadFunction<any>;
  notrelevant?: FieldPolicy<any> | FieldReadFunction<any>;
  stationary?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DivisionValuesKeySpecifier = (
  | 'items'
  | 'title'
  | DivisionValuesKeySpecifier
)[];
export type DivisionValuesFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EbieScoreKeySpecifier = (
  | 'rating'
  | 'tier'
  | EbieScoreKeySpecifier
)[];
export type EbieScoreFieldPolicy = {
  rating?: FieldPolicy<any> | FieldReadFunction<any>;
  tier?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ElsewhereReasonsKeySpecifier = (
  | 'elsewhere'
  | 'elsewhereother'
  | 'elsewhereothercontact'
  | 'elsewhereotherplatform'
  | 'elsewhereportallycontact'
  | 'elsewhererealestateagent'
  | ElsewhereReasonsKeySpecifier
)[];
export type ElsewhereReasonsFieldPolicy = {
  elsewhere?: FieldPolicy<any> | FieldReadFunction<any>;
  elsewhereother?: FieldPolicy<any> | FieldReadFunction<any>;
  elsewhereothercontact?: FieldPolicy<any> | FieldReadFunction<any>;
  elsewhereotherplatform?: FieldPolicy<any> | FieldReadFunction<any>;
  elsewhereportallycontact?: FieldPolicy<any> | FieldReadFunction<any>;
  elsewhererealestateagent?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EmailLogKeySpecifier = (
  | 'body'
  | 'client'
  | 'clientId'
  | 'date'
  | 'id'
  | 'mailWaiting'
  | 'recipient'
  | 'searchRequest'
  | 'subject'
  | 'template'
  | EmailLogKeySpecifier
)[];
export type EmailLogFieldPolicy = {
  body?: FieldPolicy<any> | FieldReadFunction<any>;
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  clientId?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  mailWaiting?: FieldPolicy<any> | FieldReadFunction<any>;
  recipient?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  subject?: FieldPolicy<any> | FieldReadFunction<any>;
  template?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EmailLogDataKeySpecifier = (
  | 'count'
  | 'emailLogs'
  | EmailLogDataKeySpecifier
)[];
export type EmailLogDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  emailLogs?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EmailSettingsKeySpecifier = (
  | 'enable'
  | 'onIntresting'
  | 'onMatch'
  | 'onMessage'
  | 'onPromoted'
  | 'onSearchHit'
  | 'onSearchRequest'
  | 'reminders'
  | EmailSettingsKeySpecifier
)[];
export type EmailSettingsFieldPolicy = {
  enable?: FieldPolicy<any> | FieldReadFunction<any>;
  onIntresting?: FieldPolicy<any> | FieldReadFunction<any>;
  onMatch?: FieldPolicy<any> | FieldReadFunction<any>;
  onMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  onPromoted?: FieldPolicy<any> | FieldReadFunction<any>;
  onSearchHit?: FieldPolicy<any> | FieldReadFunction<any>;
  onSearchRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  reminders?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EpisodeKeySpecifier = (
  | 'description'
  | 'duration'
  | 'id'
  | 'image'
  | 'link'
  | 'published'
  | 'title'
  | EpisodeKeySpecifier
)[];
export type EpisodeFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  duration?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  link?: FieldPolicy<any> | FieldReadFunction<any>;
  published?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EstateKeySpecifier = (
  | 'address'
  | 'contactPersonIds'
  | 'contactPersons'
  | 'estateDesignation'
  | 'id'
  | 'landlord'
  | 'landlordId'
  | 'leaseAgreements'
  | 'location'
  | 'name'
  | EstateKeySpecifier
)[];
export type EstateFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  contactPersonIds?: FieldPolicy<any> | FieldReadFunction<any>;
  contactPersons?: FieldPolicy<any> | FieldReadFunction<any>;
  estateDesignation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  landlord?: FieldPolicy<any> | FieldReadFunction<any>;
  landlordId?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreements?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EstateDataKeySpecifier = (
  | 'count'
  | 'estates'
  | EstateDataKeySpecifier
)[];
export type EstateDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  estates?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventKeySpecifier = (
  | 'client'
  | 'clientId'
  | 'date'
  | 'description'
  | 'done'
  | 'id'
  | 'responsibleConsultant'
  | 'responsibleConsultantId'
  | 'type'
  | EventKeySpecifier
)[];
export type EventFieldPolicy = {
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  clientId?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  done?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  responsibleConsultant?: FieldPolicy<any> | FieldReadFunction<any>;
  responsibleConsultantId?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FeeKeySpecifier = ('amount' | 'feeType' | FeeKeySpecifier)[];
export type FeeFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  feeType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FileKeySpecifier = (
  | 'created'
  | 'encoding'
  | 'id'
  | 'mimetype'
  | 'name'
  | 'path'
  | 'size'
  | 'url'
  | FileKeySpecifier
)[];
export type FileFieldPolicy = {
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  encoding?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  mimetype?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  path?: FieldPolicy<any> | FieldReadFunction<any>;
  size?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FileDataKeySpecifier = ('files' | FileDataKeySpecifier)[];
export type FileDataFieldPolicy = {
  files?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ForecastKeySpecifier = (
  | 'averagePerDay'
  | 'averagePerDayNeeded'
  | 'goal'
  | 'willReachGoal'
  | ForecastKeySpecifier
)[];
export type ForecastFieldPolicy = {
  averagePerDay?: FieldPolicy<any> | FieldReadFunction<any>;
  averagePerDayNeeded?: FieldPolicy<any> | FieldReadFunction<any>;
  goal?: FieldPolicy<any> | FieldReadFunction<any>;
  willReachGoal?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GateGoalKeySpecifier = (
  | 'monthly'
  | 'yearly'
  | GateGoalKeySpecifier
)[];
export type GateGoalFieldPolicy = {
  monthly?: FieldPolicy<any> | FieldReadFunction<any>;
  yearly?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GraphDataKeySpecifier = (
  | 'labels'
  | 'title'
  | 'values'
  | GraphDataKeySpecifier
)[];
export type GraphDataFieldPolicy = {
  labels?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  values?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IntegrationProviderKeySpecifier = (
  | 'clients'
  | 'contactPersons'
  | 'id'
  | 'image'
  | 'name'
  | IntegrationProviderKeySpecifier
)[];
export type IntegrationProviderFieldPolicy = {
  clients?: FieldPolicy<any> | FieldReadFunction<any>;
  contactPersons?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IntegrationProviderDataKeySpecifier = (
  | 'count'
  | 'integrationProviders'
  | IntegrationProviderDataKeySpecifier
)[];
export type IntegrationProviderDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  integrationProviders?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InvoiceDetailsKeySpecifier = (
  | 'billing'
  | 'company'
  | 'email'
  | 'marking'
  | 'reference'
  | InvoiceDetailsKeySpecifier
)[];
export type InvoiceDetailsFieldPolicy = {
  billing?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  marking?: FieldPolicy<any> | FieldReadFunction<any>;
  reference?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseAgreementKeySpecifier = (
  | 'access'
  | 'address'
  | 'areaDescription'
  | 'clientId'
  | 'conversations'
  | 'created'
  | 'description'
  | 'disabled'
  | 'documentIds'
  | 'documents'
  | 'estate'
  | 'estateId'
  | 'exposures'
  | 'fee'
  | 'fromVitec'
  | 'hasInterestedClients'
  | 'id'
  | 'image'
  | 'imageIds'
  | 'images'
  | 'initiatedRentalStatus'
  | 'integrationProvider'
  | 'interestedCount'
  | 'interestedUsers'
  | 'isPromoted'
  | 'landlord'
  | 'landlordContactPersonIds'
  | 'landlordContactPersons'
  | 'landlordId'
  | 'links'
  | 'location'
  | 'matchingSearchRequests'
  | 'myStatus'
  | 'name'
  | 'nearbyPlaces'
  | 'nearbyServices'
  | 'prioritized'
  | 'projectId'
  | 'rent'
  | 'similarLeaseAgreements'
  | 'size'
  | 'sizeSpan'
  | 'slug'
  | 'stats'
  | 'statusList'
  | 'tenant'
  | 'tenantContactPersonIds'
  | 'tenantContactPersons'
  | 'tenantId'
  | 'title'
  | 'usageCategory'
  | 'video'
  | 'views'
  | 'vitecGuid'
  | LeaseAgreementKeySpecifier
)[];
export type LeaseAgreementFieldPolicy = {
  access?: FieldPolicy<any> | FieldReadFunction<any>;
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  areaDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  clientId?: FieldPolicy<any> | FieldReadFunction<any>;
  conversations?: FieldPolicy<any> | FieldReadFunction<any>;
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  disabled?: FieldPolicy<any> | FieldReadFunction<any>;
  documentIds?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  estate?: FieldPolicy<any> | FieldReadFunction<any>;
  estateId?: FieldPolicy<any> | FieldReadFunction<any>;
  exposures?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  fromVitec?: FieldPolicy<any> | FieldReadFunction<any>;
  hasInterestedClients?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  imageIds?: FieldPolicy<any> | FieldReadFunction<any>;
  images?: FieldPolicy<any> | FieldReadFunction<any>;
  initiatedRentalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  integrationProvider?: FieldPolicy<any> | FieldReadFunction<any>;
  interestedCount?: FieldPolicy<any> | FieldReadFunction<any>;
  interestedUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  isPromoted?: FieldPolicy<any> | FieldReadFunction<any>;
  landlord?: FieldPolicy<any> | FieldReadFunction<any>;
  landlordContactPersonIds?: FieldPolicy<any> | FieldReadFunction<any>;
  landlordContactPersons?: FieldPolicy<any> | FieldReadFunction<any>;
  landlordId?: FieldPolicy<any> | FieldReadFunction<any>;
  links?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  matchingSearchRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  myStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  nearbyPlaces?: FieldPolicy<any> | FieldReadFunction<any>;
  nearbyServices?: FieldPolicy<any> | FieldReadFunction<any>;
  prioritized?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  rent?: FieldPolicy<any> | FieldReadFunction<any>;
  similarLeaseAgreements?: FieldPolicy<any> | FieldReadFunction<any>;
  size?: FieldPolicy<any> | FieldReadFunction<any>;
  sizeSpan?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  stats?: FieldPolicy<any> | FieldReadFunction<any>;
  statusList?: FieldPolicy<any> | FieldReadFunction<any>;
  tenant?: FieldPolicy<any> | FieldReadFunction<any>;
  tenantContactPersonIds?: FieldPolicy<any> | FieldReadFunction<any>;
  tenantContactPersons?: FieldPolicy<any> | FieldReadFunction<any>;
  tenantId?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  usageCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  video?: FieldPolicy<any> | FieldReadFunction<any>;
  views?: FieldPolicy<any> | FieldReadFunction<any>;
  vitecGuid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseAgreementExposureKeySpecifier = (
  | 'created'
  | 'fromWebsite'
  | 'id'
  | 'leaseAgreement'
  | 'updated'
  | LeaseAgreementExposureKeySpecifier
)[];
export type LeaseAgreementExposureFieldPolicy = {
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  fromWebsite?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  updated?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseAgreementExposureDataKeySpecifier = (
  | 'count'
  | 'exposures'
  | LeaseAgreementExposureDataKeySpecifier
)[];
export type LeaseAgreementExposureDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  exposures?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseAgreementLinkKeySpecifier = (
  | 'category'
  | 'title'
  | 'url'
  | LeaseAgreementLinkKeySpecifier
)[];
export type LeaseAgreementLinkFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseAgreementNumbersKeySpecifier = (
  | 'app'
  | 'total'
  | 'website'
  | 'websitePercentage'
  | LeaseAgreementNumbersKeySpecifier
)[];
export type LeaseAgreementNumbersFieldPolicy = {
  app?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
  website?: FieldPolicy<any> | FieldReadFunction<any>;
  websitePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseAgreementProspectKeySpecifier = (
  | 'adminStatus'
  | 'company'
  | 'created'
  | 'email'
  | 'id'
  | 'landlord'
  | 'leaseAgreement'
  | 'message'
  | 'phone'
  | 'readBy'
  | 'showContactInfo'
  | 'status'
  | 'updated'
  | LeaseAgreementProspectKeySpecifier
)[];
export type LeaseAgreementProspectFieldPolicy = {
  adminStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  landlord?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  readBy?: FieldPolicy<any> | FieldReadFunction<any>;
  showContactInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  updated?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseAgreementProspectDataKeySpecifier = (
  | 'count'
  | 'prospects'
  | 'unread'
  | LeaseAgreementProspectDataKeySpecifier
)[];
export type LeaseAgreementProspectDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  prospects?: FieldPolicy<any> | FieldReadFunction<any>;
  unread?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseAgreementSiteMapKeySpecifier = (
  | 'id'
  | 'lastModified'
  | 'name'
  | 'priority'
  | 'slug'
  | LeaseAgreementSiteMapKeySpecifier
)[];
export type LeaseAgreementSiteMapFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastModified?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  priority?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseAgreementSiteMapResultKeySpecifier = (
  | 'count'
  | 'items'
  | LeaseAgreementSiteMapResultKeySpecifier
)[];
export type LeaseAgreementSiteMapResultFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseAgreementStatsKeySpecifier = (
  | 'count'
  | 'interestsOverTime'
  | LeaseAgreementStatsKeySpecifier
)[];
export type LeaseAgreementStatsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  interestsOverTime?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseAgreementViewKeySpecifier = (
  | 'created'
  | 'fromWebsite'
  | 'id'
  | 'leaseAgreement'
  | 'user'
  | LeaseAgreementViewKeySpecifier
)[];
export type LeaseAgreementViewFieldPolicy = {
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  fromWebsite?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseAgreementViewDataKeySpecifier = (
  | 'count'
  | 'views'
  | LeaseAgreementViewDataKeySpecifier
)[];
export type LeaseAgreementViewDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  views?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseAgreementViewStatsKeySpecifier = (
  | 'mostViewedLeaseAgreements'
  | 'numbers'
  | 'overTime'
  | LeaseAgreementViewStatsKeySpecifier
)[];
export type LeaseAgreementViewStatsFieldPolicy = {
  mostViewedLeaseAgreements?: FieldPolicy<any> | FieldReadFunction<any>;
  numbers?: FieldPolicy<any> | FieldReadFunction<any>;
  overTime?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseAgreementsResultKeySpecifier = (
  | 'clients'
  | 'cluster'
  | 'commerce'
  | 'count'
  | 'countClients'
  | 'items'
  | 'regionBounds'
  | 'regionPolygons'
  | 'showResults'
  | 'summary'
  | LeaseAgreementsResultKeySpecifier
)[];
export type LeaseAgreementsResultFieldPolicy = {
  clients?: FieldPolicy<any> | FieldReadFunction<any>;
  cluster?: FieldPolicy<any> | FieldReadFunction<any>;
  commerce?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  countClients?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  regionBounds?: FieldPolicy<any> | FieldReadFunction<any>;
  regionPolygons?: FieldPolicy<any> | FieldReadFunction<any>;
  showResults?: FieldPolicy<any> | FieldReadFunction<any>;
  summary?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LinkPreviewKeySpecifier = (
  | 'description'
  | 'image'
  | 'title'
  | LinkPreviewKeySpecifier
)[];
export type LinkPreviewFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LoginWithTokenResultKeySpecifier = (
  | 'searchRequestReferenceId'
  | 'token'
  | LoginWithTokenResultKeySpecifier
)[];
export type LoginWithTokenResultFieldPolicy = {
  searchRequestReferenceId?: FieldPolicy<any> | FieldReadFunction<any>;
  token?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LostOrderDataKeySpecifier = (
  | 'comment'
  | 'reason'
  | LostOrderDataKeySpecifier
)[];
export type LostOrderDataFieldPolicy = {
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  reason?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MatchResultsKeySpecifier = (
  | 'count'
  | 'items'
  | MatchResultsKeySpecifier
)[];
export type MatchResultsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MatchingSearchRequestsKeySpecifier = (
  | 'count'
  | 'searchRequests'
  | MatchingSearchRequestsKeySpecifier
)[];
export type MatchingSearchRequestsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequests?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageKeySpecifier = (
  | 'attachments'
  | 'conversation'
  | 'conversationId'
  | 'created'
  | 'deleted'
  | 'id'
  | 'leaseAgreements'
  | 'message'
  | 'reactions'
  | 'read'
  | 'readTimestamp'
  | 'sender'
  | 'senderId'
  | 'senderUser'
  | 'senderUserId'
  | MessageKeySpecifier
)[];
export type MessageFieldPolicy = {
  attachments?: FieldPolicy<any> | FieldReadFunction<any>;
  conversation?: FieldPolicy<any> | FieldReadFunction<any>;
  conversationId?: FieldPolicy<any> | FieldReadFunction<any>;
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  deleted?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreements?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  reactions?: FieldPolicy<any> | FieldReadFunction<any>;
  read?: FieldPolicy<any> | FieldReadFunction<any>;
  readTimestamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sender?: FieldPolicy<any> | FieldReadFunction<any>;
  senderId?: FieldPolicy<any> | FieldReadFunction<any>;
  senderUser?: FieldPolicy<any> | FieldReadFunction<any>;
  senderUserId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageStatsKeySpecifier = (
  | 'count'
  | 'messagesOverTime'
  | MessageStatsKeySpecifier
)[];
export type MessageStatsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  messagesOverTime?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MonthKeySpecifier = ('name' | 'order' | MonthKeySpecifier)[];
export type MonthFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  order?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MonthGoalKeySpecifier = (
  | 'expectedIncome'
  | 'fromPreviousMonth'
  | 'month'
  | 'numberOfMonth'
  | 'producedIncome'
  | 'year'
  | MonthGoalKeySpecifier
)[];
export type MonthGoalFieldPolicy = {
  expectedIncome?: FieldPolicy<any> | FieldReadFunction<any>;
  fromPreviousMonth?: FieldPolicy<any> | FieldReadFunction<any>;
  month?: FieldPolicy<any> | FieldReadFunction<any>;
  numberOfMonth?: FieldPolicy<any> | FieldReadFunction<any>;
  producedIncome?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MostViewedStatsKeySpecifier = (
  | '_id'
  | 'address'
  | 'count'
  | MostViewedStatsKeySpecifier
)[];
export type MostViewedStatsFieldPolicy = {
  _id?: FieldPolicy<any> | FieldReadFunction<any>;
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutationKeySpecifier = (
  | 'acceptSubscriptionRequest'
  | 'acceptTermsOfService'
  | 'addAd'
  | 'addAttachments'
  | 'addClient'
  | 'addContact'
  | 'addDeal'
  | 'addEstate'
  | 'addEvent'
  | 'addExternalLeaseAgreement'
  | 'addGateGoal'
  | 'addLeaseAgreement'
  | 'addMessage'
  | 'addPartner'
  | 'addPartnerSection'
  | 'addPresetAnswer'
  | 'addProject'
  | 'addProspect'
  | 'addRegion'
  | 'addRegionInformation'
  | 'addSaleClient'
  | 'addSearchProfile'
  | 'addSearchRequestComment'
  | 'addSearchRequestCommentLandlord'
  | 'addSubscriptionRequest'
  | 'addTermsOfService'
  | 'addUser'
  | 'addUserMyColleagues'
  | 'answerSearchRequest'
  | 'apiKey'
  | 'archiveSearchRequest'
  | 'authenticateIntegrationProvider'
  | 'changeSearchRequestStatus'
  | 'changeStatus'
  | 'changeStatusPromotion'
  | 'clickAd'
  | 'completeTour'
  | 'contactLeaseAgreement'
  | 'createUserFromContact'
  | 'deleteAd'
  | 'deleteAll'
  | 'deleteAllPromoted'
  | 'deleteClient'
  | 'deleteContact'
  | 'deleteEmailLog'
  | 'deleteEstate'
  | 'deleteEvent'
  | 'deleteExternalLeaseAgreement'
  | 'deleteIntegrationProvider'
  | 'deleteLeaseAgreement'
  | 'deletePartner'
  | 'deletePresetAnswer'
  | 'deletePromoted'
  | 'deleteProspect'
  | 'deleteRegion'
  | 'deleteRegionInformation'
  | 'deleteSaleClient'
  | 'deleteSearchProfile'
  | 'deleteSearchRequest'
  | 'deleteSearchRequestComment'
  | 'deleteSearchRequestCommentLandlord'
  | 'deleteSearchRequestReference'
  | 'deleteSubscriptionRequest'
  | 'deleteTermsOfService'
  | 'deleteUser'
  | 'disableInterestingPopconfirm'
  | 'disableLeaseAgreement'
  | 'disableSearchRequest'
  | 'editAd'
  | 'editClient'
  | 'editContact'
  | 'editContactPerson'
  | 'editDisplayResource'
  | 'editEstate'
  | 'editEvent'
  | 'editGateGoal'
  | 'editIntegrationProvider'
  | 'editLeaseAgreement'
  | 'editLeaseAgreementProspectAdminStatus'
  | 'editLeaseAgreementProspectInformation'
  | 'editLeaseAgreementProspectStatus'
  | 'editPortallyPartner'
  | 'editProject'
  | 'editProspect'
  | 'editRegion'
  | 'editRegionInformation'
  | 'editSaleClient'
  | 'editSearchProfile'
  | 'editSearchRequestAdmin'
  | 'editSearchRequestCommentLandlord'
  | 'editSearchRequestReference'
  | 'editTermsOfService'
  | 'editUser'
  | 'file'
  | 'getNewLink'
  | 'integrationProvider'
  | 'inviteFriendEmail'
  | 'loginUser'
  | 'loginWithToken'
  | 'markAsAnswered'
  | 'moveConversation'
  | 'newMessage'
  | 'promote'
  | 'reactOnMessage'
  | 'readNotification'
  | 'refresh'
  | 'register'
  | 'removeDeal'
  | 'removeDealFromUser'
  | 'removeDealsFromClient'
  | 'removeFromAll'
  | 'removePartnerSection'
  | 'resetPassword'
  | 'resetPasswordLink'
  | 'searchRequestReference'
  | 'sendApiKeyLink'
  | 'sendImproveSearchMail'
  | 'sendMessageToPartner'
  | 'sendNewsMessage'
  | 'sendSearchRequest'
  | 'sendSearchRequestToUser'
  | 'sendWaitingEmail'
  | 'sendWelcomeEmail'
  | 'setArchived'
  | 'setDealToUser'
  | 'setDealsToClient'
  | 'setEventDone'
  | 'setToAll'
  | 'shareSearchRequest'
  | 'signUp'
  | 'testNewsMessage'
  | 'toggleActivePartner'
  | 'treatDeal'
  | 'unsubscribeEmail'
  | 'updateAdminResponsibleUser'
  | 'updateAdminStatus'
  | 'updateResponsibleUser'
  | 'updateSearchRequestStep'
  | 'verifyAccount'
  | 'viewAd'
  | 'viewAttachments'
  | 'viewConversation'
  | 'viewLeaseAgreement'
  | 'viewPartner'
  | 'viewSubscriptionRequest'
  | MutationKeySpecifier
)[];
export type MutationFieldPolicy = {
  acceptSubscriptionRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  acceptTermsOfService?: FieldPolicy<any> | FieldReadFunction<any>;
  addAd?: FieldPolicy<any> | FieldReadFunction<any>;
  addAttachments?: FieldPolicy<any> | FieldReadFunction<any>;
  addClient?: FieldPolicy<any> | FieldReadFunction<any>;
  addContact?: FieldPolicy<any> | FieldReadFunction<any>;
  addDeal?: FieldPolicy<any> | FieldReadFunction<any>;
  addEstate?: FieldPolicy<any> | FieldReadFunction<any>;
  addEvent?: FieldPolicy<any> | FieldReadFunction<any>;
  addExternalLeaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  addGateGoal?: FieldPolicy<any> | FieldReadFunction<any>;
  addLeaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  addMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  addPartner?: FieldPolicy<any> | FieldReadFunction<any>;
  addPartnerSection?: FieldPolicy<any> | FieldReadFunction<any>;
  addPresetAnswer?: FieldPolicy<any> | FieldReadFunction<any>;
  addProject?: FieldPolicy<any> | FieldReadFunction<any>;
  addProspect?: FieldPolicy<any> | FieldReadFunction<any>;
  addRegion?: FieldPolicy<any> | FieldReadFunction<any>;
  addRegionInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  addSaleClient?: FieldPolicy<any> | FieldReadFunction<any>;
  addSearchProfile?: FieldPolicy<any> | FieldReadFunction<any>;
  addSearchRequestComment?: FieldPolicy<any> | FieldReadFunction<any>;
  addSearchRequestCommentLandlord?: FieldPolicy<any> | FieldReadFunction<any>;
  addSubscriptionRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  addTermsOfService?: FieldPolicy<any> | FieldReadFunction<any>;
  addUser?: FieldPolicy<any> | FieldReadFunction<any>;
  addUserMyColleagues?: FieldPolicy<any> | FieldReadFunction<any>;
  answerSearchRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  apiKey?: FieldPolicy<any> | FieldReadFunction<any>;
  archiveSearchRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  authenticateIntegrationProvider?: FieldPolicy<any> | FieldReadFunction<any>;
  changeSearchRequestStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  changeStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  changeStatusPromotion?: FieldPolicy<any> | FieldReadFunction<any>;
  clickAd?: FieldPolicy<any> | FieldReadFunction<any>;
  completeTour?: FieldPolicy<any> | FieldReadFunction<any>;
  contactLeaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  createUserFromContact?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteAd?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteAll?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteAllPromoted?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteClient?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteContact?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteEmailLog?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteEstate?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteEvent?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteExternalLeaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteIntegrationProvider?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteLeaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePartner?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePresetAnswer?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePromoted?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteProspect?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRegion?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRegionInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteSaleClient?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteSearchProfile?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteSearchRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteSearchRequestComment?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteSearchRequestCommentLandlord?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  deleteSearchRequestReference?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteSubscriptionRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTermsOfService?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteUser?: FieldPolicy<any> | FieldReadFunction<any>;
  disableInterestingPopconfirm?: FieldPolicy<any> | FieldReadFunction<any>;
  disableLeaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  disableSearchRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  editAd?: FieldPolicy<any> | FieldReadFunction<any>;
  editClient?: FieldPolicy<any> | FieldReadFunction<any>;
  editContact?: FieldPolicy<any> | FieldReadFunction<any>;
  editContactPerson?: FieldPolicy<any> | FieldReadFunction<any>;
  editDisplayResource?: FieldPolicy<any> | FieldReadFunction<any>;
  editEstate?: FieldPolicy<any> | FieldReadFunction<any>;
  editEvent?: FieldPolicy<any> | FieldReadFunction<any>;
  editGateGoal?: FieldPolicy<any> | FieldReadFunction<any>;
  editIntegrationProvider?: FieldPolicy<any> | FieldReadFunction<any>;
  editLeaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  editLeaseAgreementProspectAdminStatus?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  editLeaseAgreementProspectInformation?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  editLeaseAgreementProspectStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  editPortallyPartner?: FieldPolicy<any> | FieldReadFunction<any>;
  editProject?: FieldPolicy<any> | FieldReadFunction<any>;
  editProspect?: FieldPolicy<any> | FieldReadFunction<any>;
  editRegion?: FieldPolicy<any> | FieldReadFunction<any>;
  editRegionInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  editSaleClient?: FieldPolicy<any> | FieldReadFunction<any>;
  editSearchProfile?: FieldPolicy<any> | FieldReadFunction<any>;
  editSearchRequestAdmin?: FieldPolicy<any> | FieldReadFunction<any>;
  editSearchRequestCommentLandlord?: FieldPolicy<any> | FieldReadFunction<any>;
  editSearchRequestReference?: FieldPolicy<any> | FieldReadFunction<any>;
  editTermsOfService?: FieldPolicy<any> | FieldReadFunction<any>;
  editUser?: FieldPolicy<any> | FieldReadFunction<any>;
  file?: FieldPolicy<any> | FieldReadFunction<any>;
  getNewLink?: FieldPolicy<any> | FieldReadFunction<any>;
  integrationProvider?: FieldPolicy<any> | FieldReadFunction<any>;
  inviteFriendEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  loginUser?: FieldPolicy<any> | FieldReadFunction<any>;
  loginWithToken?: FieldPolicy<any> | FieldReadFunction<any>;
  markAsAnswered?: FieldPolicy<any> | FieldReadFunction<any>;
  moveConversation?: FieldPolicy<any> | FieldReadFunction<any>;
  newMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  promote?: FieldPolicy<any> | FieldReadFunction<any>;
  reactOnMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  readNotification?: FieldPolicy<any> | FieldReadFunction<any>;
  refresh?: FieldPolicy<any> | FieldReadFunction<any>;
  register?: FieldPolicy<any> | FieldReadFunction<any>;
  removeDeal?: FieldPolicy<any> | FieldReadFunction<any>;
  removeDealFromUser?: FieldPolicy<any> | FieldReadFunction<any>;
  removeDealsFromClient?: FieldPolicy<any> | FieldReadFunction<any>;
  removeFromAll?: FieldPolicy<any> | FieldReadFunction<any>;
  removePartnerSection?: FieldPolicy<any> | FieldReadFunction<any>;
  resetPassword?: FieldPolicy<any> | FieldReadFunction<any>;
  resetPasswordLink?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestReference?: FieldPolicy<any> | FieldReadFunction<any>;
  sendApiKeyLink?: FieldPolicy<any> | FieldReadFunction<any>;
  sendImproveSearchMail?: FieldPolicy<any> | FieldReadFunction<any>;
  sendMessageToPartner?: FieldPolicy<any> | FieldReadFunction<any>;
  sendNewsMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  sendSearchRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  sendSearchRequestToUser?: FieldPolicy<any> | FieldReadFunction<any>;
  sendWaitingEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  sendWelcomeEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  setArchived?: FieldPolicy<any> | FieldReadFunction<any>;
  setDealToUser?: FieldPolicy<any> | FieldReadFunction<any>;
  setDealsToClient?: FieldPolicy<any> | FieldReadFunction<any>;
  setEventDone?: FieldPolicy<any> | FieldReadFunction<any>;
  setToAll?: FieldPolicy<any> | FieldReadFunction<any>;
  shareSearchRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  signUp?: FieldPolicy<any> | FieldReadFunction<any>;
  testNewsMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  toggleActivePartner?: FieldPolicy<any> | FieldReadFunction<any>;
  treatDeal?: FieldPolicy<any> | FieldReadFunction<any>;
  unsubscribeEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAdminResponsibleUser?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAdminStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  updateResponsibleUser?: FieldPolicy<any> | FieldReadFunction<any>;
  updateSearchRequestStep?: FieldPolicy<any> | FieldReadFunction<any>;
  verifyAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  viewAd?: FieldPolicy<any> | FieldReadFunction<any>;
  viewAttachments?: FieldPolicy<any> | FieldReadFunction<any>;
  viewConversation?: FieldPolicy<any> | FieldReadFunction<any>;
  viewLeaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  viewPartner?: FieldPolicy<any> | FieldReadFunction<any>;
  viewSubscriptionRequest?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NearbyPlacesKeySpecifier = (
  | 'coordinates'
  | 'distance'
  | 'name'
  | 'place'
  | 'vicinity'
  | NearbyPlacesKeySpecifier
)[];
export type NearbyPlacesFieldPolicy = {
  coordinates?: FieldPolicy<any> | FieldReadFunction<any>;
  distance?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  vicinity?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NearbyServiceKeySpecifier = (
  | 'distance'
  | 'name'
  | NearbyServiceKeySpecifier
)[];
export type NearbyServiceFieldPolicy = {
  distance?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NearbyServicesKeySpecifier = (
  | 'bus_station'
  | 'gym'
  | 'parking'
  | 'subway_station'
  | 'supermarket'
  | 'train_station'
  | NearbyServicesKeySpecifier
)[];
export type NearbyServicesFieldPolicy = {
  bus_station?: FieldPolicy<any> | FieldReadFunction<any>;
  gym?: FieldPolicy<any> | FieldReadFunction<any>;
  parking?: FieldPolicy<any> | FieldReadFunction<any>;
  subway_station?: FieldPolicy<any> | FieldReadFunction<any>;
  supermarket?: FieldPolicy<any> | FieldReadFunction<any>;
  train_station?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NewsKeySpecifier = (
  | 'avatar'
  | 'description'
  | 'id'
  | 'image'
  | 'link'
  | 'published'
  | 'title'
  | NewsKeySpecifier
)[];
export type NewsFieldPolicy = {
  avatar?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  link?: FieldPolicy<any> | FieldReadFunction<any>;
  published?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NewsDataKeySpecifier = ('count' | 'news' | NewsDataKeySpecifier)[];
export type NewsDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  news?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NonNullRangeKeySpecifier = (
  | 'max'
  | 'min'
  | NonNullRangeKeySpecifier
)[];
export type NonNullRangeFieldPolicy = {
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NotificationKeySpecifier = (
  | 'id'
  | 'leaseAgreementId'
  | 'link'
  | 'message'
  | 'read'
  | 'searchRequestReferenceId'
  | 'timestamp'
  | 'type'
  | 'user'
  | 'userId'
  | NotificationKeySpecifier
)[];
export type NotificationFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreementId?: FieldPolicy<any> | FieldReadFunction<any>;
  link?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  read?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestReferenceId?: FieldPolicy<any> | FieldReadFunction<any>;
  timestamp?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NotificationDataKeySpecifier = (
  | 'count'
  | 'notifications'
  | NotificationDataKeySpecifier
)[];
export type NotificationDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  notifications?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NotificationMessageKeySpecifier = (
  | 'en'
  | 'sv'
  | NotificationMessageKeySpecifier
)[];
export type NotificationMessageFieldPolicy = {
  en?: FieldPolicy<any> | FieldReadFunction<any>;
  sv?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OnTopSearchResultKeySpecifier = (
  | 'bounds'
  | 'landlords'
  | 'regionName'
  | 'regionPolygons'
  | 'regionType'
  | OnTopSearchResultKeySpecifier
)[];
export type OnTopSearchResultFieldPolicy = {
  bounds?: FieldPolicy<any> | FieldReadFunction<any>;
  landlords?: FieldPolicy<any> | FieldReadFunction<any>;
  regionName?: FieldPolicy<any> | FieldReadFunction<any>;
  regionPolygons?: FieldPolicy<any> | FieldReadFunction<any>;
  regionType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrderDataKeySpecifier = (
  | 'blikkNumber'
  | 'comment'
  | 'orderValue'
  | 'reason'
  | OrderDataKeySpecifier
)[];
export type OrderDataFieldPolicy = {
  blikkNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  orderValue?: FieldPolicy<any> | FieldReadFunction<any>;
  reason?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OverTimeStatsKeySpecifier = (
  | 'count'
  | 'date'
  | OverTimeStatsKeySpecifier
)[];
export type OverTimeStatsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OverviewDataKeySpecifier = (
  | 'label'
  | 'value'
  | OverviewDataKeySpecifier
)[];
export type OverviewDataFieldPolicy = {
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PeriodKeySpecifier = ('max' | 'min' | PeriodKeySpecifier)[];
export type PeriodFieldPolicy = {
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PodcastKeySpecifier = (
  | 'author'
  | 'description'
  | 'episodes'
  | 'id'
  | 'image'
  | 'link'
  | 'name'
  | 'numberOfEpisodes'
  | PodcastKeySpecifier
)[];
export type PodcastFieldPolicy = {
  author?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  episodes?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  link?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  numberOfEpisodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PortallyAdKeySpecifier = (
  | 'active'
  | 'clickedBy'
  | 'clicks'
  | 'id'
  | 'image'
  | 'link'
  | 'mobileImage'
  | 'partner'
  | 'placement'
  | 'regionIds'
  | 'regions'
  | 'usageCategories'
  | 'viewedBy'
  | 'views'
  | 'websiteSearchQueryClick'
  | 'websiteSearchQueryView'
  | PortallyAdKeySpecifier
)[];
export type PortallyAdFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  clickedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  clicks?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  link?: FieldPolicy<any> | FieldReadFunction<any>;
  mobileImage?: FieldPolicy<any> | FieldReadFunction<any>;
  partner?: FieldPolicy<any> | FieldReadFunction<any>;
  placement?: FieldPolicy<any> | FieldReadFunction<any>;
  regionIds?: FieldPolicy<any> | FieldReadFunction<any>;
  regions?: FieldPolicy<any> | FieldReadFunction<any>;
  usageCategories?: FieldPolicy<any> | FieldReadFunction<any>;
  viewedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  views?: FieldPolicy<any> | FieldReadFunction<any>;
  websiteSearchQueryClick?: FieldPolicy<any> | FieldReadFunction<any>;
  websiteSearchQueryView?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PortallyPartnerKeySpecifier = (
  | 'active'
  | 'ads'
  | 'client'
  | 'clientId'
  | 'contactPerson'
  | 'displayResource'
  | 'id'
  | 'landlordViews'
  | 'name'
  | 'sections'
  | 'slug'
  | 'tenantViews'
  | 'viewedBy'
  | 'views'
  | PortallyPartnerKeySpecifier
)[];
export type PortallyPartnerFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  ads?: FieldPolicy<any> | FieldReadFunction<any>;
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  clientId?: FieldPolicy<any> | FieldReadFunction<any>;
  contactPerson?: FieldPolicy<any> | FieldReadFunction<any>;
  displayResource?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  landlordViews?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  sections?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  tenantViews?: FieldPolicy<any> | FieldReadFunction<any>;
  viewedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  views?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PortallySubscriptionKeySpecifier = (
  | 'type'
  | 'version'
  | PortallySubscriptionKeySpecifier
)[];
export type PortallySubscriptionFieldPolicy = {
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  version?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PreliminarySearchRequestStatsKeySpecifier = (
  | 'count'
  | 'searchRequestsOverTime'
  | PreliminarySearchRequestStatsKeySpecifier
)[];
export type PreliminarySearchRequestStatsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestsOverTime?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PresetAnswerKeySpecifier = (
  | 'id'
  | 'message'
  | 'name'
  | PresetAnswerKeySpecifier
)[];
export type PresetAnswerFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProcessValuesDataKeySpecifier = (
  | 'label'
  | 'percentageOfTotal'
  | 'value'
  | ProcessValuesDataKeySpecifier
)[];
export type ProcessValuesDataFieldPolicy = {
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  percentageOfTotal?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectKeySpecifier = (
  | 'id'
  | 'landlordIds'
  | 'landlords'
  | 'leaseAgreements'
  | 'name'
  | ProjectKeySpecifier
)[];
export type ProjectFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  landlordIds?: FieldPolicy<any> | FieldReadFunction<any>;
  landlords?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreements?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PromotedKeySpecifier = (
  | 'client'
  | 'clientId'
  | 'conversation'
  | 'created'
  | 'dueDate'
  | 'id'
  | 'image'
  | 'leaseAgreement'
  | 'leaseAgreementId'
  | 'opened'
  | 'owner'
  | 'ownerId'
  | 'status'
  | PromotedKeySpecifier
)[];
export type PromotedFieldPolicy = {
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  clientId?: FieldPolicy<any> | FieldReadFunction<any>;
  conversation?: FieldPolicy<any> | FieldReadFunction<any>;
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  dueDate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreementId?: FieldPolicy<any> | FieldReadFunction<any>;
  opened?: FieldPolicy<any> | FieldReadFunction<any>;
  owner?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PromotedDataKeySpecifier = (
  | 'count'
  | 'promoted'
  | PromotedDataKeySpecifier
)[];
export type PromotedDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  promoted?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PromotedStatKeySpecifier = (
  | 'accepted'
  | 'declined'
  | 'opened'
  | PromotedStatKeySpecifier
)[];
export type PromotedStatFieldPolicy = {
  accepted?: FieldPolicy<any> | FieldReadFunction<any>;
  declined?: FieldPolicy<any> | FieldReadFunction<any>;
  opened?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PromotedStatsKeySpecifier = (
  | 'count'
  | 'promotionsOverTime'
  | PromotedStatsKeySpecifier
)[];
export type PromotedStatsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  promotionsOverTime?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PromotionKeySpecifier = (
  | 'leaseAgreement'
  | 'leaseAgreementId'
  | 'opened'
  | 'reach'
  | 'status'
  | PromotionKeySpecifier
)[];
export type PromotionFieldPolicy = {
  leaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreementId?: FieldPolicy<any> | FieldReadFunction<any>;
  opened?: FieldPolicy<any> | FieldReadFunction<any>;
  reach?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProspectKeySpecifier = (
  | 'client'
  | 'clientId'
  | 'clientManager'
  | 'clientManagerId'
  | 'comment'
  | 'contact'
  | 'contactId'
  | 'created'
  | 'crossBorder'
  | 'daysInCurrentStep'
  | 'division'
  | 'id'
  | 'lostOrderData'
  | 'name'
  | 'orderData'
  | 'potentialValue'
  | 'service'
  | 'source'
  | 'sourceComment'
  | 'startDate'
  | 'step'
  | 'updated'
  | 'weightedValue'
  | ProspectKeySpecifier
)[];
export type ProspectFieldPolicy = {
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  clientId?: FieldPolicy<any> | FieldReadFunction<any>;
  clientManager?: FieldPolicy<any> | FieldReadFunction<any>;
  clientManagerId?: FieldPolicy<any> | FieldReadFunction<any>;
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  contact?: FieldPolicy<any> | FieldReadFunction<any>;
  contactId?: FieldPolicy<any> | FieldReadFunction<any>;
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  crossBorder?: FieldPolicy<any> | FieldReadFunction<any>;
  daysInCurrentStep?: FieldPolicy<any> | FieldReadFunction<any>;
  division?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lostOrderData?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  orderData?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialValue?: FieldPolicy<any> | FieldReadFunction<any>;
  service?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
  sourceComment?: FieldPolicy<any> | FieldReadFunction<any>;
  startDate?: FieldPolicy<any> | FieldReadFunction<any>;
  step?: FieldPolicy<any> | FieldReadFunction<any>;
  updated?: FieldPolicy<any> | FieldReadFunction<any>;
  weightedValue?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProspectsKeySpecifier = (
  | 'count'
  | 'prospects'
  | ProspectsKeySpecifier
)[];
export type ProspectsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  prospects?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProviderAuthenticationKeySpecifier = (
  | 'clients'
  | 'token'
  | ProviderAuthenticationKeySpecifier
)[];
export type ProviderAuthenticationFieldPolicy = {
  clients?: FieldPolicy<any> | FieldReadFunction<any>;
  token?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProviderClientKeySpecifier = (
  | 'id'
  | 'name'
  | ProviderClientKeySpecifier
)[];
export type ProviderClientFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QueryKeySpecifier = (
  | 'activityData'
  | 'analysis'
  | 'changelog'
  | 'changelogs'
  | 'checkToken'
  | 'client'
  | 'clientAutocomplete'
  | 'clientBySlug'
  | 'clients'
  | 'contact'
  | 'contacts'
  | 'conversation'
  | 'conversationFilterAutocomplete'
  | 'conversations'
  | 'conversionRate'
  | 'dailyReports'
  | 'deal'
  | 'dealClients'
  | 'deals'
  | 'emailLog'
  | 'emailLogs'
  | 'estate'
  | 'estateAutocomplete'
  | 'estates'
  | 'events'
  | 'fetchPlaces'
  | 'files'
  | 'gateGoals'
  | 'generateNearbyServices'
  | 'getClientCsv'
  | 'getCsv'
  | 'getCurrentTermsOfService'
  | 'getPartnerStats'
  | 'getProspectCsv'
  | 'getRegion'
  | 'getRegions'
  | 'getSearchResultWebsite'
  | 'getSearchSiteMapWebsite'
  | 'getSiteMapWebsite'
  | 'getSpaceSiteMapWebsite'
  | 'goals'
  | 'integrationProviderByToken'
  | 'integrationProviders'
  | 'leaseAgreement'
  | 'leaseAgreementBySlug'
  | 'leaseAgreementProspect'
  | 'leaseAgreementProspects'
  | 'leaseAgreements'
  | 'leaseAgreementsByIds'
  | 'leaseAgreementsSitemap'
  | 'linkPreview'
  | 'me'
  | 'mostPopular'
  | 'myActiveSearchRequests'
  | 'myColleagues'
  | 'myDeals'
  | 'myEstates'
  | 'myOffers'
  | 'myPresetAnswers'
  | 'myProjects'
  | 'myPromotions'
  | 'myRating'
  | 'mySearchProfiles'
  | 'mySearchRequestByReferenceId'
  | 'mySearchRequestReferences'
  | 'mySearchRequestsLandlord'
  | 'mySubscriptionRequest'
  | 'news'
  | 'notifications'
  | 'onDisplay'
  | 'partners'
  | 'podcast'
  | 'podcasts'
  | 'portallyAd'
  | 'portallyAds'
  | 'portallyPartner'
  | 'portallyPartners'
  | 'potentialClients'
  | 'project'
  | 'projects'
  | 'promoted'
  | 'promotionClients'
  | 'prospect'
  | 'prospects'
  | 'reasonForLostOrderData'
  | 'reasonForOrderData'
  | 'receivers'
  | 'regionByUrl'
  | 'regions'
  | 'regionsAndStreets'
  | 'saleClient'
  | 'saleClients'
  | 'searchProfile'
  | 'searchProfileCount'
  | 'searchProfiles'
  | 'searchRequestComments'
  | 'searchRequestCommentsLandlord'
  | 'searchRequestReference'
  | 'searchRequestReferenceByToken'
  | 'searchRequestStatistics'
  | 'searchRequestsAdmin'
  | 'showcase'
  | 'singlePromoted'
  | 'singleSearchRequest'
  | 'singleStatus'
  | 'status'
  | 'statusUpdates'
  | 'streets'
  | 'subscriptionRequests'
  | 'systemStats'
  | 'termsOfService'
  | 'termsOfServices'
  | 'user'
  | 'userAutocomplete'
  | 'users'
  | 'viewStats'
  | 'views'
  | 'websiteClients'
  | 'weeklyReports'
  | QueryKeySpecifier
)[];
export type QueryFieldPolicy = {
  activityData?: FieldPolicy<any> | FieldReadFunction<any>;
  analysis?: FieldPolicy<any> | FieldReadFunction<any>;
  changelog?: FieldPolicy<any> | FieldReadFunction<any>;
  changelogs?: FieldPolicy<any> | FieldReadFunction<any>;
  checkToken?: FieldPolicy<any> | FieldReadFunction<any>;
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  clientAutocomplete?: FieldPolicy<any> | FieldReadFunction<any>;
  clientBySlug?: FieldPolicy<any> | FieldReadFunction<any>;
  clients?: FieldPolicy<any> | FieldReadFunction<any>;
  contact?: FieldPolicy<any> | FieldReadFunction<any>;
  contacts?: FieldPolicy<any> | FieldReadFunction<any>;
  conversation?: FieldPolicy<any> | FieldReadFunction<any>;
  conversationFilterAutocomplete?: FieldPolicy<any> | FieldReadFunction<any>;
  conversations?: FieldPolicy<any> | FieldReadFunction<any>;
  conversionRate?: FieldPolicy<any> | FieldReadFunction<any>;
  dailyReports?: FieldPolicy<any> | FieldReadFunction<any>;
  deal?: FieldPolicy<any> | FieldReadFunction<any>;
  dealClients?: FieldPolicy<any> | FieldReadFunction<any>;
  deals?: FieldPolicy<any> | FieldReadFunction<any>;
  emailLog?: FieldPolicy<any> | FieldReadFunction<any>;
  emailLogs?: FieldPolicy<any> | FieldReadFunction<any>;
  estate?: FieldPolicy<any> | FieldReadFunction<any>;
  estateAutocomplete?: FieldPolicy<any> | FieldReadFunction<any>;
  estates?: FieldPolicy<any> | FieldReadFunction<any>;
  events?: FieldPolicy<any> | FieldReadFunction<any>;
  fetchPlaces?: FieldPolicy<any> | FieldReadFunction<any>;
  files?: FieldPolicy<any> | FieldReadFunction<any>;
  gateGoals?: FieldPolicy<any> | FieldReadFunction<any>;
  generateNearbyServices?: FieldPolicy<any> | FieldReadFunction<any>;
  getClientCsv?: FieldPolicy<any> | FieldReadFunction<any>;
  getCsv?: FieldPolicy<any> | FieldReadFunction<any>;
  getCurrentTermsOfService?: FieldPolicy<any> | FieldReadFunction<any>;
  getPartnerStats?: FieldPolicy<any> | FieldReadFunction<any>;
  getProspectCsv?: FieldPolicy<any> | FieldReadFunction<any>;
  getRegion?: FieldPolicy<any> | FieldReadFunction<any>;
  getRegions?: FieldPolicy<any> | FieldReadFunction<any>;
  getSearchResultWebsite?: FieldPolicy<any> | FieldReadFunction<any>;
  getSearchSiteMapWebsite?: FieldPolicy<any> | FieldReadFunction<any>;
  getSiteMapWebsite?: FieldPolicy<any> | FieldReadFunction<any>;
  getSpaceSiteMapWebsite?: FieldPolicy<any> | FieldReadFunction<any>;
  goals?: FieldPolicy<any> | FieldReadFunction<any>;
  integrationProviderByToken?: FieldPolicy<any> | FieldReadFunction<any>;
  integrationProviders?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreementBySlug?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreementProspect?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreementProspects?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreements?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreementsByIds?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreementsSitemap?: FieldPolicy<any> | FieldReadFunction<any>;
  linkPreview?: FieldPolicy<any> | FieldReadFunction<any>;
  me?: FieldPolicy<any> | FieldReadFunction<any>;
  mostPopular?: FieldPolicy<any> | FieldReadFunction<any>;
  myActiveSearchRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  myColleagues?: FieldPolicy<any> | FieldReadFunction<any>;
  myDeals?: FieldPolicy<any> | FieldReadFunction<any>;
  myEstates?: FieldPolicy<any> | FieldReadFunction<any>;
  myOffers?: FieldPolicy<any> | FieldReadFunction<any>;
  myPresetAnswers?: FieldPolicy<any> | FieldReadFunction<any>;
  myProjects?: FieldPolicy<any> | FieldReadFunction<any>;
  myPromotions?: FieldPolicy<any> | FieldReadFunction<any>;
  myRating?: FieldPolicy<any> | FieldReadFunction<any>;
  mySearchProfiles?: FieldPolicy<any> | FieldReadFunction<any>;
  mySearchRequestByReferenceId?: FieldPolicy<any> | FieldReadFunction<any>;
  mySearchRequestReferences?: FieldPolicy<any> | FieldReadFunction<any>;
  mySearchRequestsLandlord?: FieldPolicy<any> | FieldReadFunction<any>;
  mySubscriptionRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  news?: FieldPolicy<any> | FieldReadFunction<any>;
  notifications?: FieldPolicy<any> | FieldReadFunction<any>;
  onDisplay?: FieldPolicy<any> | FieldReadFunction<any>;
  partners?: FieldPolicy<any> | FieldReadFunction<any>;
  podcast?: FieldPolicy<any> | FieldReadFunction<any>;
  podcasts?: FieldPolicy<any> | FieldReadFunction<any>;
  portallyAd?: FieldPolicy<any> | FieldReadFunction<any>;
  portallyAds?: FieldPolicy<any> | FieldReadFunction<any>;
  portallyPartner?: FieldPolicy<any> | FieldReadFunction<any>;
  portallyPartners?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialClients?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projects?: FieldPolicy<any> | FieldReadFunction<any>;
  promoted?: FieldPolicy<any> | FieldReadFunction<any>;
  promotionClients?: FieldPolicy<any> | FieldReadFunction<any>;
  prospect?: FieldPolicy<any> | FieldReadFunction<any>;
  prospects?: FieldPolicy<any> | FieldReadFunction<any>;
  reasonForLostOrderData?: FieldPolicy<any> | FieldReadFunction<any>;
  reasonForOrderData?: FieldPolicy<any> | FieldReadFunction<any>;
  receivers?: FieldPolicy<any> | FieldReadFunction<any>;
  regionByUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  regions?: FieldPolicy<any> | FieldReadFunction<any>;
  regionsAndStreets?: FieldPolicy<any> | FieldReadFunction<any>;
  saleClient?: FieldPolicy<any> | FieldReadFunction<any>;
  saleClients?: FieldPolicy<any> | FieldReadFunction<any>;
  searchProfile?: FieldPolicy<any> | FieldReadFunction<any>;
  searchProfileCount?: FieldPolicy<any> | FieldReadFunction<any>;
  searchProfiles?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestComments?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestCommentsLandlord?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestReference?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestReferenceByToken?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestStatistics?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestsAdmin?: FieldPolicy<any> | FieldReadFunction<any>;
  showcase?: FieldPolicy<any> | FieldReadFunction<any>;
  singlePromoted?: FieldPolicy<any> | FieldReadFunction<any>;
  singleSearchRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  singleStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  statusUpdates?: FieldPolicy<any> | FieldReadFunction<any>;
  streets?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  systemStats?: FieldPolicy<any> | FieldReadFunction<any>;
  termsOfService?: FieldPolicy<any> | FieldReadFunction<any>;
  termsOfServices?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  userAutocomplete?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
  viewStats?: FieldPolicy<any> | FieldReadFunction<any>;
  views?: FieldPolicy<any> | FieldReadFunction<any>;
  websiteClients?: FieldPolicy<any> | FieldReadFunction<any>;
  weeklyReports?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RangeKeySpecifier = ('max' | 'min' | RangeKeySpecifier)[];
export type RangeFieldPolicy = {
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReactionKeySpecifier = (
  | 'reaction'
  | 'user'
  | 'userId'
  | ReactionKeySpecifier
)[];
export type ReactionFieldPolicy = {
  reaction?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReasonsKeySpecifier = (
  | 'label'
  | 'percentageOfTotal'
  | 'value'
  | ReasonsKeySpecifier
)[];
export type ReasonsFieldPolicy = {
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  percentageOfTotal?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RegionKeySpecifier = (
  | 'bounds'
  | 'center'
  | 'children'
  | 'country'
  | 'filter'
  | 'geometry'
  | 'id'
  | 'information'
  | 'lastModified'
  | 'level'
  | 'name'
  | 'parents'
  | 'prioritized'
  | 'regionType'
  | 'urlSlug'
  | RegionKeySpecifier
)[];
export type RegionFieldPolicy = {
  bounds?: FieldPolicy<any> | FieldReadFunction<any>;
  center?: FieldPolicy<any> | FieldReadFunction<any>;
  children?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  filter?: FieldPolicy<any> | FieldReadFunction<any>;
  geometry?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  information?: FieldPolicy<any> | FieldReadFunction<any>;
  lastModified?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  parents?: FieldPolicy<any> | FieldReadFunction<any>;
  prioritized?: FieldPolicy<any> | FieldReadFunction<any>;
  regionType?: FieldPolicy<any> | FieldReadFunction<any>;
  urlSlug?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RegionBoundsKeySpecifier = (
  | 'east'
  | 'north'
  | 'south'
  | 'west'
  | RegionBoundsKeySpecifier
)[];
export type RegionBoundsFieldPolicy = {
  east?: FieldPolicy<any> | FieldReadFunction<any>;
  north?: FieldPolicy<any> | FieldReadFunction<any>;
  south?: FieldPolicy<any> | FieldReadFunction<any>;
  west?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RegionDataKeySpecifier = (
  | 'bounds'
  | 'count'
  | 'regions'
  | RegionDataKeySpecifier
)[];
export type RegionDataFieldPolicy = {
  bounds?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  regions?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RegionInformationKeySpecifier = (
  | 'description'
  | 'id'
  | 'image'
  | 'imageId'
  | 'title'
  | 'usageCategories'
  | RegionInformationKeySpecifier
)[];
export type RegionInformationFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  imageId?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  usageCategories?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReportKeySpecifier = (
  | 'date'
  | 'id'
  | 'statistics'
  | 'type'
  | 'week'
  | 'year'
  | ReportKeySpecifier
)[];
export type ReportFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  statistics?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  week?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResultBoundsKeySpecifier = (
  | 'east'
  | 'north'
  | 'south'
  | 'west'
  | ResultBoundsKeySpecifier
)[];
export type ResultBoundsFieldPolicy = {
  east?: FieldPolicy<any> | FieldReadFunction<any>;
  north?: FieldPolicy<any> | FieldReadFunction<any>;
  south?: FieldPolicy<any> | FieldReadFunction<any>;
  west?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SaleClientKeySpecifier = (
  | 'client'
  | 'clientId'
  | 'clientManager'
  | 'clientManagerId'
  | 'comment'
  | 'created'
  | 'daysLeftSubscription'
  | 'daysToNextPayment'
  | 'files'
  | 'generatedIncome'
  | 'id'
  | 'inStairSince'
  | 'income'
  | 'nextPayment'
  | 'oneOfHundred'
  | 'stair'
  | 'subscription'
  | 'subscriptionEnd'
  | 'subscriptionStart'
  | 'tag'
  | SaleClientKeySpecifier
)[];
export type SaleClientFieldPolicy = {
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  clientId?: FieldPolicy<any> | FieldReadFunction<any>;
  clientManager?: FieldPolicy<any> | FieldReadFunction<any>;
  clientManagerId?: FieldPolicy<any> | FieldReadFunction<any>;
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  daysLeftSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  daysToNextPayment?: FieldPolicy<any> | FieldReadFunction<any>;
  files?: FieldPolicy<any> | FieldReadFunction<any>;
  generatedIncome?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inStairSince?: FieldPolicy<any> | FieldReadFunction<any>;
  income?: FieldPolicy<any> | FieldReadFunction<any>;
  nextPayment?: FieldPolicy<any> | FieldReadFunction<any>;
  oneOfHundred?: FieldPolicy<any> | FieldReadFunction<any>;
  stair?: FieldPolicy<any> | FieldReadFunction<any>;
  subscription?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionEnd?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionStart?: FieldPolicy<any> | FieldReadFunction<any>;
  tag?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SaleClientsKeySpecifier = (
  | 'clients'
  | 'count'
  | 'dollarCount'
  | 'totalIncome'
  | SaleClientsKeySpecifier
)[];
export type SaleClientsFieldPolicy = {
  clients?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  dollarCount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalIncome?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchFloorKeySpecifier = (
  | 'floorNumber'
  | 'size'
  | 'usageCategory'
  | SearchFloorKeySpecifier
)[];
export type SearchFloorFieldPolicy = {
  floorNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  size?: FieldPolicy<any> | FieldReadFunction<any>;
  usageCategory?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchParametersKeySpecifier = (
  | 'bounds'
  | 'geometry'
  | 'regionIds'
  | 'regions'
  | 'size'
  | 'usageCategories'
  | SearchParametersKeySpecifier
)[];
export type SearchParametersFieldPolicy = {
  bounds?: FieldPolicy<any> | FieldReadFunction<any>;
  geometry?: FieldPolicy<any> | FieldReadFunction<any>;
  regionIds?: FieldPolicy<any> | FieldReadFunction<any>;
  regions?: FieldPolicy<any> | FieldReadFunction<any>;
  size?: FieldPolicy<any> | FieldReadFunction<any>;
  usageCategories?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchProfileKeySpecifier = (
  | 'client'
  | 'clientId'
  | 'cluster'
  | 'concealed'
  | 'floors'
  | 'geometry'
  | 'id'
  | 'matches'
  | 'name'
  | 'period'
  | 'regionIds'
  | 'regions'
  | 'rentType'
  | 'sendToLandlords'
  | 'size'
  | 'standardAgreement'
  | 'status'
  | 'tooManyHits'
  | 'usageCategories'
  | SearchProfileKeySpecifier
)[];
export type SearchProfileFieldPolicy = {
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  clientId?: FieldPolicy<any> | FieldReadFunction<any>;
  cluster?: FieldPolicy<any> | FieldReadFunction<any>;
  concealed?: FieldPolicy<any> | FieldReadFunction<any>;
  floors?: FieldPolicy<any> | FieldReadFunction<any>;
  geometry?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  matches?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  period?: FieldPolicy<any> | FieldReadFunction<any>;
  regionIds?: FieldPolicy<any> | FieldReadFunction<any>;
  regions?: FieldPolicy<any> | FieldReadFunction<any>;
  rentType?: FieldPolicy<any> | FieldReadFunction<any>;
  sendToLandlords?: FieldPolicy<any> | FieldReadFunction<any>;
  size?: FieldPolicy<any> | FieldReadFunction<any>;
  standardAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tooManyHits?: FieldPolicy<any> | FieldReadFunction<any>;
  usageCategories?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchProfileCountKeySpecifier = (
  | 'count'
  | SearchProfileCountKeySpecifier
)[];
export type SearchProfileCountFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchProfileDataKeySpecifier = (
  | 'count'
  | 'searchProfiles'
  | SearchProfileDataKeySpecifier
)[];
export type SearchProfileDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  searchProfiles?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchRequestKeySpecifier = (
  | 'access'
  | 'accessEarliest'
  | 'accessLatest'
  | 'accessV2'
  | 'additionalInformation'
  | 'answeredDate'
  | 'areaInformation'
  | 'budget'
  | 'businessInformation'
  | 'client'
  | 'clientId'
  | 'comments'
  | 'contactPerson'
  | 'contactPersonId'
  | 'conversation'
  | 'conversationId'
  | 'county'
  | 'created'
  | 'description'
  | 'disabled'
  | 'documentIds'
  | 'documents'
  | 'employees'
  | 'id'
  | 'receiver'
  | 'receiverId'
  | 'regionBounds'
  | 'regions'
  | 'renewable'
  | 'renewedDate'
  | 'requestGroupId'
  | 'responsibleUser'
  | 'searchParameters'
  | 'searchRequestReference'
  | 'searchRequestReferenceId'
  | 'sentDate'
  | 'specificNeedsInformation'
  | 'status'
  | 'step'
  | 'stepWeight'
  | 'title'
  | SearchRequestKeySpecifier
)[];
export type SearchRequestFieldPolicy = {
  access?: FieldPolicy<any> | FieldReadFunction<any>;
  accessEarliest?: FieldPolicy<any> | FieldReadFunction<any>;
  accessLatest?: FieldPolicy<any> | FieldReadFunction<any>;
  accessV2?: FieldPolicy<any> | FieldReadFunction<any>;
  additionalInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  answeredDate?: FieldPolicy<any> | FieldReadFunction<any>;
  areaInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  budget?: FieldPolicy<any> | FieldReadFunction<any>;
  businessInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  clientId?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  contactPerson?: FieldPolicy<any> | FieldReadFunction<any>;
  contactPersonId?: FieldPolicy<any> | FieldReadFunction<any>;
  conversation?: FieldPolicy<any> | FieldReadFunction<any>;
  conversationId?: FieldPolicy<any> | FieldReadFunction<any>;
  county?: FieldPolicy<any> | FieldReadFunction<any>;
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  disabled?: FieldPolicy<any> | FieldReadFunction<any>;
  documentIds?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  employees?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  receiver?: FieldPolicy<any> | FieldReadFunction<any>;
  receiverId?: FieldPolicy<any> | FieldReadFunction<any>;
  regionBounds?: FieldPolicy<any> | FieldReadFunction<any>;
  regions?: FieldPolicy<any> | FieldReadFunction<any>;
  renewable?: FieldPolicy<any> | FieldReadFunction<any>;
  renewedDate?: FieldPolicy<any> | FieldReadFunction<any>;
  requestGroupId?: FieldPolicy<any> | FieldReadFunction<any>;
  responsibleUser?: FieldPolicy<any> | FieldReadFunction<any>;
  searchParameters?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestReference?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestReferenceId?: FieldPolicy<any> | FieldReadFunction<any>;
  sentDate?: FieldPolicy<any> | FieldReadFunction<any>;
  specificNeedsInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  step?: FieldPolicy<any> | FieldReadFunction<any>;
  stepWeight?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchRequestAdminDataKeySpecifier = (
  | 'count'
  | 'searchRequests'
  | SearchRequestAdminDataKeySpecifier
)[];
export type SearchRequestAdminDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequests?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchRequestAdminStatisticsKeySpecifier = (
  | 'count'
  | 'potentialSize'
  | 'regions'
  | 'sizes'
  | 'usageCategories'
  | SearchRequestAdminStatisticsKeySpecifier
)[];
export type SearchRequestAdminStatisticsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialSize?: FieldPolicy<any> | FieldReadFunction<any>;
  regions?: FieldPolicy<any> | FieldReadFunction<any>;
  sizes?: FieldPolicy<any> | FieldReadFunction<any>;
  usageCategories?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchRequestAnalysisKeySpecifier = (
  | 'accepted'
  | 'active'
  | 'declined'
  | 'disabled'
  | 'opened'
  | 'recipients'
  | 'unopened'
  | SearchRequestAnalysisKeySpecifier
)[];
export type SearchRequestAnalysisFieldPolicy = {
  accepted?: FieldPolicy<any> | FieldReadFunction<any>;
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  declined?: FieldPolicy<any> | FieldReadFunction<any>;
  disabled?: FieldPolicy<any> | FieldReadFunction<any>;
  opened?: FieldPolicy<any> | FieldReadFunction<any>;
  recipients?: FieldPolicy<any> | FieldReadFunction<any>;
  unopened?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchRequestCommentKeySpecifier = (
  | 'comment'
  | 'created'
  | 'id'
  | 'updated'
  | 'user'
  | SearchRequestCommentKeySpecifier
)[];
export type SearchRequestCommentFieldPolicy = {
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  updated?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchRequestDataKeySpecifier = (
  | 'count'
  | 'searchRequests'
  | SearchRequestDataKeySpecifier
)[];
export type SearchRequestDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequests?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchRequestInformationKeySpecifier = (
  | 'access'
  | 'accessV2'
  | 'areaInformation'
  | 'budget'
  | 'businessInformation'
  | 'description'
  | 'employees'
  | 'id'
  | 'specificNeedsInformation'
  | 'title'
  | SearchRequestInformationKeySpecifier
)[];
export type SearchRequestInformationFieldPolicy = {
  access?: FieldPolicy<any> | FieldReadFunction<any>;
  accessV2?: FieldPolicy<any> | FieldReadFunction<any>;
  areaInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  budget?: FieldPolicy<any> | FieldReadFunction<any>;
  businessInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  employees?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  specificNeedsInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchRequestLandlordDataKeySpecifier = (
  | 'accepted'
  | 'declined'
  | 'disabled'
  | 'unanswered'
  | SearchRequestLandlordDataKeySpecifier
)[];
export type SearchRequestLandlordDataFieldPolicy = {
  accepted?: FieldPolicy<any> | FieldReadFunction<any>;
  declined?: FieldPolicy<any> | FieldReadFunction<any>;
  disabled?: FieldPolicy<any> | FieldReadFunction<any>;
  unanswered?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchRequestReceiverKeySpecifier = (
  | 'client'
  | 'conversation'
  | 'conversationId'
  | 'status'
  | 'user'
  | SearchRequestReceiverKeySpecifier
)[];
export type SearchRequestReceiverFieldPolicy = {
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  conversation?: FieldPolicy<any> | FieldReadFunction<any>;
  conversationId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchRequestReceiverDataKeySpecifier = (
  | 'count'
  | 'receivers'
  | SearchRequestReceiverDataKeySpecifier
)[];
export type SearchRequestReceiverDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  receivers?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchRequestReferenceKeySpecifier = (
  | 'access'
  | 'accessV2'
  | 'adminResponsibleUser'
  | 'adminStatus'
  | 'areaInformation'
  | 'budget'
  | 'budgetSpan'
  | 'businessInformation'
  | 'client'
  | 'clientId'
  | 'contactInformation'
  | 'contactPerson'
  | 'description'
  | 'disabled'
  | 'disabledDate'
  | 'disabledReason'
  | 'documents'
  | 'employees'
  | 'employeesSpan'
  | 'favourite'
  | 'fromWebsite'
  | 'hasComment'
  | 'hasLoggedIn'
  | 'id'
  | 'improveSearchMailSent'
  | 'myStatus'
  | 'receivers'
  | 'regions'
  | 'renewedDate'
  | 'requestGroupId'
  | 'searchParameters'
  | 'searchRequests'
  | 'sentDate'
  | 'specificNeedsInformation'
  | 'stats'
  | 'status'
  | 'title'
  | 'unreadMessages'
  | SearchRequestReferenceKeySpecifier
)[];
export type SearchRequestReferenceFieldPolicy = {
  access?: FieldPolicy<any> | FieldReadFunction<any>;
  accessV2?: FieldPolicy<any> | FieldReadFunction<any>;
  adminResponsibleUser?: FieldPolicy<any> | FieldReadFunction<any>;
  adminStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  areaInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  budget?: FieldPolicy<any> | FieldReadFunction<any>;
  budgetSpan?: FieldPolicy<any> | FieldReadFunction<any>;
  businessInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  clientId?: FieldPolicy<any> | FieldReadFunction<any>;
  contactInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  contactPerson?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  disabled?: FieldPolicy<any> | FieldReadFunction<any>;
  disabledDate?: FieldPolicy<any> | FieldReadFunction<any>;
  disabledReason?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  employees?: FieldPolicy<any> | FieldReadFunction<any>;
  employeesSpan?: FieldPolicy<any> | FieldReadFunction<any>;
  favourite?: FieldPolicy<any> | FieldReadFunction<any>;
  fromWebsite?: FieldPolicy<any> | FieldReadFunction<any>;
  hasComment?: FieldPolicy<any> | FieldReadFunction<any>;
  hasLoggedIn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  improveSearchMailSent?: FieldPolicy<any> | FieldReadFunction<any>;
  myStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  receivers?: FieldPolicy<any> | FieldReadFunction<any>;
  regions?: FieldPolicy<any> | FieldReadFunction<any>;
  renewedDate?: FieldPolicy<any> | FieldReadFunction<any>;
  requestGroupId?: FieldPolicy<any> | FieldReadFunction<any>;
  searchParameters?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  sentDate?: FieldPolicy<any> | FieldReadFunction<any>;
  specificNeedsInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  stats?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  unreadMessages?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchRequestReferenceDataKeySpecifier = (
  | 'count'
  | 'searchRequests'
  | SearchRequestReferenceDataKeySpecifier
)[];
export type SearchRequestReferenceDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequests?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchRequestSettingsKeySpecifier = (
  | 'filterEmails'
  | 'regionIds'
  | 'regions'
  | 'size'
  | 'usageCategories'
  | SearchRequestSettingsKeySpecifier
)[];
export type SearchRequestSettingsFieldPolicy = {
  filterEmails?: FieldPolicy<any> | FieldReadFunction<any>;
  regionIds?: FieldPolicy<any> | FieldReadFunction<any>;
  regions?: FieldPolicy<any> | FieldReadFunction<any>;
  size?: FieldPolicy<any> | FieldReadFunction<any>;
  usageCategories?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchRequestStatsKeySpecifier = (
  | 'count'
  | 'searchRequestsOverTime'
  | SearchRequestStatsKeySpecifier
)[];
export type SearchRequestStatsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestsOverTime?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchRequestsInfoKeySpecifier = (
  | 'id'
  | 'regions'
  | 'searchRequestId'
  | 'size'
  | 'usageCategories'
  | SearchRequestsInfoKeySpecifier
)[];
export type SearchRequestsInfoFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  regions?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestId?: FieldPolicy<any> | FieldReadFunction<any>;
  size?: FieldPolicy<any> | FieldReadFunction<any>;
  usageCategories?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchResultKeySpecifier = (
  | 'loginToken'
  | 'searchRequestReference'
  | SearchResultKeySpecifier
)[];
export type SearchResultFieldPolicy = {
  loginToken?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestReference?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SectionsKeySpecifier = (
  | 'displayResource'
  | 'id'
  | 'targetGroup'
  | 'text'
  | 'title'
  | SectionsKeySpecifier
)[];
export type SectionsFieldPolicy = {
  displayResource?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  targetGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  text?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SimilarLeaseAgreementsKeySpecifier = (
  | 'moreFromClient'
  | 'similar'
  | SimilarLeaseAgreementsKeySpecifier
)[];
export type SimilarLeaseAgreementsFieldPolicy = {
  moreFromClient?: FieldPolicy<any> | FieldReadFunction<any>;
  similar?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SiteMapLinksKeySpecifier = (
  | 'priority'
  | 'url'
  | SiteMapLinksKeySpecifier
)[];
export type SiteMapLinksFieldPolicy = {
  priority?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SiteMapResultKeySpecifier = (
  | 'count'
  | 'name'
  | 'siteMapLinks'
  | 'urlSlug'
  | SiteMapResultKeySpecifier
)[];
export type SiteMapResultFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  siteMapLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  urlSlug?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SizeSpanKeySpecifier = ('from' | 'to' | SizeSpanKeySpecifier)[];
export type SizeSpanFieldPolicy = {
  from?: FieldPolicy<any> | FieldReadFunction<any>;
  to?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatisticsKeySpecifier = (
  | 'nrOfActiveLandlordUsers'
  | 'nrOfActiveTenantUsers'
  | 'nrOfActiveUsers'
  | 'nrOfEmailSent'
  | 'nrOfInterests'
  | 'nrOfLandlordUserSignups'
  | 'nrOfLeaseAgreements'
  | 'nrOfMessages'
  | 'nrOfNewSearchRequests'
  | 'nrOfPromotions'
  | 'nrOfSignups'
  | 'nrOfTenantUserSignups'
  | StatisticsKeySpecifier
)[];
export type StatisticsFieldPolicy = {
  nrOfActiveLandlordUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  nrOfActiveTenantUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  nrOfActiveUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  nrOfEmailSent?: FieldPolicy<any> | FieldReadFunction<any>;
  nrOfInterests?: FieldPolicy<any> | FieldReadFunction<any>;
  nrOfLandlordUserSignups?: FieldPolicy<any> | FieldReadFunction<any>;
  nrOfLeaseAgreements?: FieldPolicy<any> | FieldReadFunction<any>;
  nrOfMessages?: FieldPolicy<any> | FieldReadFunction<any>;
  nrOfNewSearchRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  nrOfPromotions?: FieldPolicy<any> | FieldReadFunction<any>;
  nrOfSignups?: FieldPolicy<any> | FieldReadFunction<any>;
  nrOfTenantUserSignups?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatsKeySpecifier = (
  | 'interested'
  | 'matches'
  | 'matchesNP'
  | 'promoted'
  | StatsKeySpecifier
)[];
export type StatsFieldPolicy = {
  interested?: FieldPolicy<any> | FieldReadFunction<any>;
  matches?: FieldPolicy<any> | FieldReadFunction<any>;
  matchesNP?: FieldPolicy<any> | FieldReadFunction<any>;
  promoted?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusKeySpecifier = (
  | 'client'
  | 'clientId'
  | 'id'
  | 'leaseAgreement'
  | 'leaseAgreementId'
  | 'status'
  | StatusKeySpecifier
)[];
export type StatusFieldPolicy = {
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  clientId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreementId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusCountKeySpecifier = (
  | 'accepted'
  | 'answer_requested'
  | 'declined'
  | 'opened'
  | StatusCountKeySpecifier
)[];
export type StatusCountFieldPolicy = {
  accepted?: FieldPolicy<any> | FieldReadFunction<any>;
  answer_requested?: FieldPolicy<any> | FieldReadFunction<any>;
  declined?: FieldPolicy<any> | FieldReadFunction<any>;
  opened?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusUpdateKeySpecifier = (
  | 'direction'
  | 'nextStatus'
  | 'previousStatus'
  | StatusUpdateKeySpecifier
)[];
export type StatusUpdateFieldPolicy = {
  direction?: FieldPolicy<any> | FieldReadFunction<any>;
  nextStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  previousStatus?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusUpdateDataKeySpecifier = (
  | 'count'
  | 'statusUpdates'
  | StatusUpdateDataKeySpecifier
)[];
export type StatusUpdateDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  statusUpdates?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StoreQuantityKeySpecifier = (
  | 'amount'
  | 'country'
  | StoreQuantityKeySpecifier
)[];
export type StoreQuantityFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionKeySpecifier = (
  | 'conversation'
  | 'conversationAttachment'
  | 'newAttachment'
  | 'newMessage'
  | 'newNotification'
  | 'newReaction'
  | SubscriptionKeySpecifier
)[];
export type SubscriptionFieldPolicy = {
  conversation?: FieldPolicy<any> | FieldReadFunction<any>;
  conversationAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
  newAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
  newMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  newNotification?: FieldPolicy<any> | FieldReadFunction<any>;
  newReaction?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionGoalsKeySpecifier = (
  | 'december'
  | 'october'
  | 'subscribersCount'
  | SubscriptionGoalsKeySpecifier
)[];
export type SubscriptionGoalsFieldPolicy = {
  december?: FieldPolicy<any> | FieldReadFunction<any>;
  october?: FieldPolicy<any> | FieldReadFunction<any>;
  subscribersCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionRequestKeySpecifier = (
  | 'acceptedByUser'
  | 'acceptedByUserId'
  | 'acceptedDate'
  | 'client'
  | 'clientId'
  | 'created'
  | 'id'
  | 'openedByUserIds'
  | 'openedByUsers'
  | 'readByUserIds'
  | 'readByUsers'
  | 'status'
  | 'type'
  | 'updated'
  | 'version'
  | SubscriptionRequestKeySpecifier
)[];
export type SubscriptionRequestFieldPolicy = {
  acceptedByUser?: FieldPolicy<any> | FieldReadFunction<any>;
  acceptedByUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  acceptedDate?: FieldPolicy<any> | FieldReadFunction<any>;
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  clientId?: FieldPolicy<any> | FieldReadFunction<any>;
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  openedByUserIds?: FieldPolicy<any> | FieldReadFunction<any>;
  openedByUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  readByUserIds?: FieldPolicy<any> | FieldReadFunction<any>;
  readByUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updated?: FieldPolicy<any> | FieldReadFunction<any>;
  version?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SummaryKeySpecifier = (
  | 'promoted'
  | 'rental'
  | 'settlement'
  | 'total'
  | SummaryKeySpecifier
)[];
export type SummaryFieldPolicy = {
  promoted?: FieldPolicy<any> | FieldReadFunction<any>;
  rental?: FieldPolicy<any> | FieldReadFunction<any>;
  settlement?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SystemStatisticsKeySpecifier = (
  | 'clients'
  | 'contacts'
  | 'conversations'
  | 'estates'
  | 'interesting'
  | 'landlords'
  | 'leaseAgreements'
  | 'matches'
  | 'messages'
  | 'notifications'
  | 'preliminarySearchRequests'
  | 'promotions'
  | 'searchProfiles'
  | 'searchRequests'
  | 'tenants'
  | 'users'
  | 'verified'
  | SystemStatisticsKeySpecifier
)[];
export type SystemStatisticsFieldPolicy = {
  clients?: FieldPolicy<any> | FieldReadFunction<any>;
  contacts?: FieldPolicy<any> | FieldReadFunction<any>;
  conversations?: FieldPolicy<any> | FieldReadFunction<any>;
  estates?: FieldPolicy<any> | FieldReadFunction<any>;
  interesting?: FieldPolicy<any> | FieldReadFunction<any>;
  landlords?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreements?: FieldPolicy<any> | FieldReadFunction<any>;
  matches?: FieldPolicy<any> | FieldReadFunction<any>;
  messages?: FieldPolicy<any> | FieldReadFunction<any>;
  notifications?: FieldPolicy<any> | FieldReadFunction<any>;
  preliminarySearchRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  promotions?: FieldPolicy<any> | FieldReadFunction<any>;
  searchProfiles?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  tenants?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
  verified?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TermsKeySpecifier = (
  | 'confirmed'
  | 'confirmedDate'
  | 'confirmedUser'
  | 'confirmedUserId'
  | TermsKeySpecifier
)[];
export type TermsFieldPolicy = {
  confirmed?: FieldPolicy<any> | FieldReadFunction<any>;
  confirmedDate?: FieldPolicy<any> | FieldReadFunction<any>;
  confirmedUser?: FieldPolicy<any> | FieldReadFunction<any>;
  confirmedUserId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TermsOfServiceKeySpecifier = (
  | 'content'
  | 'cookiePolicy'
  | 'id'
  | 'privacy'
  | 'published'
  | 'publishedDate'
  | 'version'
  | TermsOfServiceKeySpecifier
)[];
export type TermsOfServiceFieldPolicy = {
  content?: FieldPolicy<any> | FieldReadFunction<any>;
  cookiePolicy?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  privacy?: FieldPolicy<any> | FieldReadFunction<any>;
  published?: FieldPolicy<any> | FieldReadFunction<any>;
  publishedDate?: FieldPolicy<any> | FieldReadFunction<any>;
  version?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TermsOfServiceDataKeySpecifier = (
  | 'count'
  | 'termsOfServices'
  | TermsOfServiceDataKeySpecifier
)[];
export type TermsOfServiceDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  termsOfServices?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TimeAverageInStepDataKeySpecifier = (
  | 'label'
  | 'value'
  | TimeAverageInStepDataKeySpecifier
)[];
export type TimeAverageInStepDataFieldPolicy = {
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TimeAverageToOrderDataKeySpecifier = (
  | 'bookedMeetingToOrder'
  | 'leadToOrder'
  | TimeAverageToOrderDataKeySpecifier
)[];
export type TimeAverageToOrderDataFieldPolicy = {
  bookedMeetingToOrder?: FieldPolicy<any> | FieldReadFunction<any>;
  leadToOrder?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TokenInfoKeySpecifier = (
  | 'createAccountInfo'
  | 'exists'
  | 'valid'
  | TokenInfoKeySpecifier
)[];
export type TokenInfoFieldPolicy = {
  createAccountInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  exists?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TotalProcessValuesKeySpecifier = (
  | 'processValuesData'
  | 'totalValue'
  | TotalProcessValuesKeySpecifier
)[];
export type TotalProcessValuesFieldPolicy = {
  processValuesData?: FieldPolicy<any> | FieldReadFunction<any>;
  totalValue?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TranslatedContentKeySpecifier = (
  | 'en'
  | 'sv'
  | TranslatedContentKeySpecifier
)[];
export type TranslatedContentFieldPolicy = {
  en?: FieldPolicy<any> | FieldReadFunction<any>;
  sv?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TrialKeySpecifier = ('endDate' | 'startDate' | TrialKeySpecifier)[];
export type TrialFieldPolicy = {
  endDate?: FieldPolicy<any> | FieldReadFunction<any>;
  startDate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UnreadKeySpecifier = (
  | 'attachments'
  | 'count'
  | 'messages'
  | UnreadKeySpecifier
)[];
export type UnreadFieldPolicy = {
  attachments?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  messages?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserKeySpecifier = (
  | 'access'
  | 'activeClient'
  | 'activeClientId'
  | 'activeModal'
  | 'activeSubscription'
  | 'activity'
  | 'activityStatus'
  | 'city'
  | 'clientIds'
  | 'clients'
  | 'companyName'
  | 'completedTours'
  | 'created'
  | 'deals'
  | 'disabled'
  | 'email'
  | 'emailSettings'
  | 'estateIds'
  | 'estates'
  | 'failedLogins'
  | 'firstName'
  | 'fromWebsite'
  | 'hasDealRequest'
  | 'hasPassword'
  | 'id'
  | 'image'
  | 'imageId'
  | 'interestingPopconfirm'
  | 'isPartner'
  | 'jobTitle'
  | 'lastName'
  | 'leaseAgreementViews'
  | 'limited'
  | 'logins'
  | 'name'
  | 'onBoarded'
  | 'orgNumber'
  | 'password'
  | 'phone'
  | 'prospect'
  | 'receivedEmails'
  | 'receivedWelcomeEmail'
  | 'role'
  | 'searchRequestSettings'
  | 'searchRequests'
  | 'showMine'
  | 'subscription'
  | 'trial'
  | 'updated'
  | 'userCategory'
  | 'verified'
  | UserKeySpecifier
)[];
export type UserFieldPolicy = {
  access?: FieldPolicy<any> | FieldReadFunction<any>;
  activeClient?: FieldPolicy<any> | FieldReadFunction<any>;
  activeClientId?: FieldPolicy<any> | FieldReadFunction<any>;
  activeModal?: FieldPolicy<any> | FieldReadFunction<any>;
  activeSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  activity?: FieldPolicy<any> | FieldReadFunction<any>;
  activityStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  clientIds?: FieldPolicy<any> | FieldReadFunction<any>;
  clients?: FieldPolicy<any> | FieldReadFunction<any>;
  companyName?: FieldPolicy<any> | FieldReadFunction<any>;
  completedTours?: FieldPolicy<any> | FieldReadFunction<any>;
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  deals?: FieldPolicy<any> | FieldReadFunction<any>;
  disabled?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  emailSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  estateIds?: FieldPolicy<any> | FieldReadFunction<any>;
  estates?: FieldPolicy<any> | FieldReadFunction<any>;
  failedLogins?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  fromWebsite?: FieldPolicy<any> | FieldReadFunction<any>;
  hasDealRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  hasPassword?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  imageId?: FieldPolicy<any> | FieldReadFunction<any>;
  interestingPopconfirm?: FieldPolicy<any> | FieldReadFunction<any>;
  isPartner?: FieldPolicy<any> | FieldReadFunction<any>;
  jobTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreementViews?: FieldPolicy<any> | FieldReadFunction<any>;
  limited?: FieldPolicy<any> | FieldReadFunction<any>;
  logins?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  onBoarded?: FieldPolicy<any> | FieldReadFunction<any>;
  orgNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  password?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  prospect?: FieldPolicy<any> | FieldReadFunction<any>;
  receivedEmails?: FieldPolicy<any> | FieldReadFunction<any>;
  receivedWelcomeEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  role?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequestSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  showMine?: FieldPolicy<any> | FieldReadFunction<any>;
  subscription?: FieldPolicy<any> | FieldReadFunction<any>;
  trial?: FieldPolicy<any> | FieldReadFunction<any>;
  updated?: FieldPolicy<any> | FieldReadFunction<any>;
  userCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  verified?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserDataKeySpecifier = ('count' | 'users' | UserDataKeySpecifier)[];
export type UserDataFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserDealsKeySpecifier = (
  | 'count'
  | 'portallysFee'
  | 'value'
  | UserDealsKeySpecifier
)[];
export type UserDealsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  portallysFee?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserStatsKeySpecifier = (
  | 'activeUserStats'
  | 'count'
  | 'forecast'
  | 'lastMonthTot'
  | 'lastWeekTot'
  | 'loginStats'
  | 'registeredPerDay'
  | 'thisMonthTot'
  | 'thisWeekTot'
  | 'totalLoginStats'
  | UserStatsKeySpecifier
)[];
export type UserStatsFieldPolicy = {
  activeUserStats?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  forecast?: FieldPolicy<any> | FieldReadFunction<any>;
  lastMonthTot?: FieldPolicy<any> | FieldReadFunction<any>;
  lastWeekTot?: FieldPolicy<any> | FieldReadFunction<any>;
  loginStats?: FieldPolicy<any> | FieldReadFunction<any>;
  registeredPerDay?: FieldPolicy<any> | FieldReadFunction<any>;
  thisMonthTot?: FieldPolicy<any> | FieldReadFunction<any>;
  thisWeekTot?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLoginStats?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VideoKeySpecifier = (
  | 'thumbnail'
  | 'thumbnailId'
  | 'video'
  | 'videoId'
  | VideoKeySpecifier
)[];
export type VideoFieldPolicy = {
  thumbnail?: FieldPolicy<any> | FieldReadFunction<any>;
  thumbnailId?: FieldPolicy<any> | FieldReadFunction<any>;
  video?: FieldPolicy<any> | FieldReadFunction<any>;
  videoId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WaitingEmailKeySpecifier = (
  | 'globalMergeVars'
  | 'subject'
  | 'template'
  | WaitingEmailKeySpecifier
)[];
export type WaitingEmailFieldPolicy = {
  globalMergeVars?: FieldPolicy<any> | FieldReadFunction<any>;
  subject?: FieldPolicy<any> | FieldReadFunction<any>;
  template?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WebsiteSearchQueryKeySpecifier = (
  | 'city'
  | 'max'
  | 'min'
  | 'regionIds'
  | 'usageCategories'
  | WebsiteSearchQueryKeySpecifier
)[];
export type WebsiteSearchQueryFieldPolicy = {
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  regionIds?: FieldPolicy<any> | FieldReadFunction<any>;
  usageCategories?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type YearlyKeySpecifier = (
  | 'expectedIncome'
  | 'producedIncome'
  | YearlyKeySpecifier
)[];
export type YearlyFieldPolicy = {
  expectedIncome?: FieldPolicy<any> | FieldReadFunction<any>;
  producedIncome?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StrictTypedTypePolicies = {
  Access?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AccessKeySpecifier
      | (() => undefined | AccessKeySpecifier);
    fields?: AccessFieldPolicy;
  };
  ActionCount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ActionCountKeySpecifier
      | (() => undefined | ActionCountKeySpecifier);
    fields?: ActionCountFieldPolicy;
  };
  ActionStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ActionStatsKeySpecifier
      | (() => undefined | ActionStatsKeySpecifier);
    fields?: ActionStatsFieldPolicy;
  };
  ActiveSearchRequestData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ActiveSearchRequestDataKeySpecifier
      | (() => undefined | ActiveSearchRequestDataKeySpecifier);
    fields?: ActiveSearchRequestDataFieldPolicy;
  };
  ActiveUserStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ActiveUserStatsKeySpecifier
      | (() => undefined | ActiveUserStatsKeySpecifier);
    fields?: ActiveUserStatsFieldPolicy;
  };
  ActivityData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ActivityDataKeySpecifier
      | (() => undefined | ActivityDataKeySpecifier);
    fields?: ActivityDataFieldPolicy;
  };
  ActivityLog?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ActivityLogKeySpecifier
      | (() => undefined | ActivityLogKeySpecifier);
    fields?: ActivityLogFieldPolicy;
  };
  ActivityLogStatistics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ActivityLogStatisticsKeySpecifier
      | (() => undefined | ActivityLogStatisticsKeySpecifier);
    fields?: ActivityLogStatisticsFieldPolicy;
  };
  Address?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AddressKeySpecifier
      | (() => undefined | AddressKeySpecifier);
    fields?: AddressFieldPolicy;
  };
  AdminStatusCount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminStatusCountKeySpecifier
      | (() => undefined | AdminStatusCountKeySpecifier);
    fields?: AdminStatusCountFieldPolicy;
  };
  AllProspectData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AllProspectDataKeySpecifier
      | (() => undefined | AllProspectDataKeySpecifier);
    fields?: AllProspectDataFieldPolicy;
  };
  Analysis?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AnalysisKeySpecifier
      | (() => undefined | AnalysisKeySpecifier);
    fields?: AnalysisFieldPolicy;
  };
  ApiCredentials?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ApiCredentialsKeySpecifier
      | (() => undefined | ApiCredentialsKeySpecifier);
    fields?: ApiCredentialsFieldPolicy;
  };
  Attachment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AttachmentKeySpecifier
      | (() => undefined | AttachmentKeySpecifier);
    fields?: AttachmentFieldPolicy;
  };
  AttachmentData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AttachmentDataKeySpecifier
      | (() => undefined | AttachmentDataKeySpecifier);
    fields?: AttachmentDataFieldPolicy;
  };
  AutoCompleteClient?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AutoCompleteClientKeySpecifier
      | (() => undefined | AutoCompleteClientKeySpecifier);
    fields?: AutoCompleteClientFieldPolicy;
  };
  AutoCompleteUser?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AutoCompleteUserKeySpecifier
      | (() => undefined | AutoCompleteUserKeySpecifier);
    fields?: AutoCompleteUserFieldPolicy;
  };
  AutocompleteRegions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AutocompleteRegionsKeySpecifier
      | (() => undefined | AutocompleteRegionsKeySpecifier);
    fields?: AutocompleteRegionsFieldPolicy;
  };
  AutocompleteResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AutocompleteResultKeySpecifier
      | (() => undefined | AutocompleteResultKeySpecifier);
    fields?: AutocompleteResultFieldPolicy;
  };
  BillingAddress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BillingAddressKeySpecifier
      | (() => undefined | BillingAddressKeySpecifier);
    fields?: BillingAddressFieldPolicy;
  };
  BillingAdress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BillingAdressKeySpecifier
      | (() => undefined | BillingAdressKeySpecifier);
    fields?: BillingAdressFieldPolicy;
  };
  CategorizedPromotedData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CategorizedPromotedDataKeySpecifier
      | (() => undefined | CategorizedPromotedDataKeySpecifier);
    fields?: CategorizedPromotedDataFieldPolicy;
  };
  Changelog?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ChangelogKeySpecifier
      | (() => undefined | ChangelogKeySpecifier);
    fields?: ChangelogFieldPolicy;
  };
  Client?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ClientKeySpecifier
      | (() => undefined | ClientKeySpecifier);
    fields?: ClientFieldPolicy;
  };
  ClientData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ClientDataKeySpecifier
      | (() => undefined | ClientDataKeySpecifier);
    fields?: ClientDataFieldPolicy;
  };
  ClientDeals?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ClientDealsKeySpecifier
      | (() => undefined | ClientDealsKeySpecifier);
    fields?: ClientDealsFieldPolicy;
  };
  Cluster?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ClusterKeySpecifier
      | (() => undefined | ClusterKeySpecifier);
    fields?: ClusterFieldPolicy;
  };
  CohortAnalysis?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CohortAnalysisKeySpecifier
      | (() => undefined | CohortAnalysisKeySpecifier);
    fields?: CohortAnalysisFieldPolicy;
  };
  ColleaguesData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ColleaguesDataKeySpecifier
      | (() => undefined | ColleaguesDataKeySpecifier);
    fields?: ColleaguesDataFieldPolicy;
  };
  Contact?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ContactKeySpecifier
      | (() => undefined | ContactKeySpecifier);
    fields?: ContactFieldPolicy;
  };
  ContactInformation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ContactInformationKeySpecifier
      | (() => undefined | ContactInformationKeySpecifier);
    fields?: ContactInformationFieldPolicy;
  };
  ContactPerson?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ContactPersonKeySpecifier
      | (() => undefined | ContactPersonKeySpecifier);
    fields?: ContactPersonFieldPolicy;
  };
  Conversation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ConversationKeySpecifier
      | (() => undefined | ConversationKeySpecifier);
    fields?: ConversationFieldPolicy;
  };
  ConversationData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ConversationDataKeySpecifier
      | (() => undefined | ConversationDataKeySpecifier);
    fields?: ConversationDataFieldPolicy;
  };
  ConversationFilterAutocomplete?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ConversationFilterAutocompleteKeySpecifier
      | (() => undefined | ConversationFilterAutocompleteKeySpecifier);
    fields?: ConversationFilterAutocompleteFieldPolicy;
  };
  ConversationStatistics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ConversationStatisticsKeySpecifier
      | (() => undefined | ConversationStatisticsKeySpecifier);
    fields?: ConversationStatisticsFieldPolicy;
  };
  ConversionRateData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ConversionRateDataKeySpecifier
      | (() => undefined | ConversionRateDataKeySpecifier);
    fields?: ConversionRateDataFieldPolicy;
  };
  CountByRegion?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountByRegionKeySpecifier
      | (() => undefined | CountByRegionKeySpecifier);
    fields?: CountByRegionFieldPolicy;
  };
  CountBySize?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountBySizeKeySpecifier
      | (() => undefined | CountBySizeKeySpecifier);
    fields?: CountBySizeFieldPolicy;
  };
  CountByUsageCategory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountByUsageCategoryKeySpecifier
      | (() => undefined | CountByUsageCategoryKeySpecifier);
    fields?: CountByUsageCategoryFieldPolicy;
  };
  CreateAccountInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreateAccountInfoKeySpecifier
      | (() => undefined | CreateAccountInfoKeySpecifier);
    fields?: CreateAccountInfoFieldPolicy;
  };
  DateStat?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DateStatKeySpecifier
      | (() => undefined | DateStatKeySpecifier);
    fields?: DateStatFieldPolicy;
  };
  DaysInStep?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DaysInStepKeySpecifier
      | (() => undefined | DaysInStepKeySpecifier);
    fields?: DaysInStepFieldPolicy;
  };
  Deal?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DealKeySpecifier | (() => undefined | DealKeySpecifier);
    fields?: DealFieldPolicy;
  };
  DealClient?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DealClientKeySpecifier
      | (() => undefined | DealClientKeySpecifier);
    fields?: DealClientFieldPolicy;
  };
  DealClientData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DealClientDataKeySpecifier
      | (() => undefined | DealClientDataKeySpecifier);
    fields?: DealClientDataFieldPolicy;
  };
  DealData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DealDataKeySpecifier
      | (() => undefined | DealDataKeySpecifier);
    fields?: DealDataFieldPolicy;
  };
  DealSubscription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DealSubscriptionKeySpecifier
      | (() => undefined | DealSubscriptionKeySpecifier);
    fields?: DealSubscriptionFieldPolicy;
  };
  DisableSearchRequestReason?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DisableSearchRequestReasonKeySpecifier
      | (() => undefined | DisableSearchRequestReasonKeySpecifier);
    fields?: DisableSearchRequestReasonFieldPolicy;
  };
  DisabledSearchRequestAnalysis?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DisabledSearchRequestAnalysisKeySpecifier
      | (() => undefined | DisabledSearchRequestAnalysisKeySpecifier);
    fields?: DisabledSearchRequestAnalysisFieldPolicy;
  };
  DivisionValues?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DivisionValuesKeySpecifier
      | (() => undefined | DivisionValuesKeySpecifier);
    fields?: DivisionValuesFieldPolicy;
  };
  EbieScore?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EbieScoreKeySpecifier
      | (() => undefined | EbieScoreKeySpecifier);
    fields?: EbieScoreFieldPolicy;
  };
  ElsewhereReasons?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ElsewhereReasonsKeySpecifier
      | (() => undefined | ElsewhereReasonsKeySpecifier);
    fields?: ElsewhereReasonsFieldPolicy;
  };
  EmailLog?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EmailLogKeySpecifier
      | (() => undefined | EmailLogKeySpecifier);
    fields?: EmailLogFieldPolicy;
  };
  EmailLogData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EmailLogDataKeySpecifier
      | (() => undefined | EmailLogDataKeySpecifier);
    fields?: EmailLogDataFieldPolicy;
  };
  EmailSettings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EmailSettingsKeySpecifier
      | (() => undefined | EmailSettingsKeySpecifier);
    fields?: EmailSettingsFieldPolicy;
  };
  Episode?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EpisodeKeySpecifier
      | (() => undefined | EpisodeKeySpecifier);
    fields?: EpisodeFieldPolicy;
  };
  Estate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EstateKeySpecifier
      | (() => undefined | EstateKeySpecifier);
    fields?: EstateFieldPolicy;
  };
  EstateData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EstateDataKeySpecifier
      | (() => undefined | EstateDataKeySpecifier);
    fields?: EstateDataFieldPolicy;
  };
  Event?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EventKeySpecifier
      | (() => undefined | EventKeySpecifier);
    fields?: EventFieldPolicy;
  };
  Fee?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FeeKeySpecifier | (() => undefined | FeeKeySpecifier);
    fields?: FeeFieldPolicy;
  };
  File?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FileKeySpecifier | (() => undefined | FileKeySpecifier);
    fields?: FileFieldPolicy;
  };
  FileData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FileDataKeySpecifier
      | (() => undefined | FileDataKeySpecifier);
    fields?: FileDataFieldPolicy;
  };
  Forecast?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ForecastKeySpecifier
      | (() => undefined | ForecastKeySpecifier);
    fields?: ForecastFieldPolicy;
  };
  GateGoal?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GateGoalKeySpecifier
      | (() => undefined | GateGoalKeySpecifier);
    fields?: GateGoalFieldPolicy;
  };
  GraphData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GraphDataKeySpecifier
      | (() => undefined | GraphDataKeySpecifier);
    fields?: GraphDataFieldPolicy;
  };
  IntegrationProvider?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | IntegrationProviderKeySpecifier
      | (() => undefined | IntegrationProviderKeySpecifier);
    fields?: IntegrationProviderFieldPolicy;
  };
  IntegrationProviderData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | IntegrationProviderDataKeySpecifier
      | (() => undefined | IntegrationProviderDataKeySpecifier);
    fields?: IntegrationProviderDataFieldPolicy;
  };
  InvoiceDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InvoiceDetailsKeySpecifier
      | (() => undefined | InvoiceDetailsKeySpecifier);
    fields?: InvoiceDetailsFieldPolicy;
  };
  LeaseAgreement?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseAgreementKeySpecifier
      | (() => undefined | LeaseAgreementKeySpecifier);
    fields?: LeaseAgreementFieldPolicy;
  };
  LeaseAgreementExposure?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseAgreementExposureKeySpecifier
      | (() => undefined | LeaseAgreementExposureKeySpecifier);
    fields?: LeaseAgreementExposureFieldPolicy;
  };
  LeaseAgreementExposureData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseAgreementExposureDataKeySpecifier
      | (() => undefined | LeaseAgreementExposureDataKeySpecifier);
    fields?: LeaseAgreementExposureDataFieldPolicy;
  };
  LeaseAgreementLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseAgreementLinkKeySpecifier
      | (() => undefined | LeaseAgreementLinkKeySpecifier);
    fields?: LeaseAgreementLinkFieldPolicy;
  };
  LeaseAgreementNumbers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseAgreementNumbersKeySpecifier
      | (() => undefined | LeaseAgreementNumbersKeySpecifier);
    fields?: LeaseAgreementNumbersFieldPolicy;
  };
  LeaseAgreementProspect?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseAgreementProspectKeySpecifier
      | (() => undefined | LeaseAgreementProspectKeySpecifier);
    fields?: LeaseAgreementProspectFieldPolicy;
  };
  LeaseAgreementProspectData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseAgreementProspectDataKeySpecifier
      | (() => undefined | LeaseAgreementProspectDataKeySpecifier);
    fields?: LeaseAgreementProspectDataFieldPolicy;
  };
  LeaseAgreementSiteMap?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseAgreementSiteMapKeySpecifier
      | (() => undefined | LeaseAgreementSiteMapKeySpecifier);
    fields?: LeaseAgreementSiteMapFieldPolicy;
  };
  LeaseAgreementSiteMapResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseAgreementSiteMapResultKeySpecifier
      | (() => undefined | LeaseAgreementSiteMapResultKeySpecifier);
    fields?: LeaseAgreementSiteMapResultFieldPolicy;
  };
  LeaseAgreementStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseAgreementStatsKeySpecifier
      | (() => undefined | LeaseAgreementStatsKeySpecifier);
    fields?: LeaseAgreementStatsFieldPolicy;
  };
  LeaseAgreementView?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseAgreementViewKeySpecifier
      | (() => undefined | LeaseAgreementViewKeySpecifier);
    fields?: LeaseAgreementViewFieldPolicy;
  };
  LeaseAgreementViewData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseAgreementViewDataKeySpecifier
      | (() => undefined | LeaseAgreementViewDataKeySpecifier);
    fields?: LeaseAgreementViewDataFieldPolicy;
  };
  LeaseAgreementViewStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseAgreementViewStatsKeySpecifier
      | (() => undefined | LeaseAgreementViewStatsKeySpecifier);
    fields?: LeaseAgreementViewStatsFieldPolicy;
  };
  LeaseAgreementsResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseAgreementsResultKeySpecifier
      | (() => undefined | LeaseAgreementsResultKeySpecifier);
    fields?: LeaseAgreementsResultFieldPolicy;
  };
  LinkPreview?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LinkPreviewKeySpecifier
      | (() => undefined | LinkPreviewKeySpecifier);
    fields?: LinkPreviewFieldPolicy;
  };
  LoginWithTokenResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LoginWithTokenResultKeySpecifier
      | (() => undefined | LoginWithTokenResultKeySpecifier);
    fields?: LoginWithTokenResultFieldPolicy;
  };
  LostOrderData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LostOrderDataKeySpecifier
      | (() => undefined | LostOrderDataKeySpecifier);
    fields?: LostOrderDataFieldPolicy;
  };
  MatchResults?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MatchResultsKeySpecifier
      | (() => undefined | MatchResultsKeySpecifier);
    fields?: MatchResultsFieldPolicy;
  };
  MatchingSearchRequests?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MatchingSearchRequestsKeySpecifier
      | (() => undefined | MatchingSearchRequestsKeySpecifier);
    fields?: MatchingSearchRequestsFieldPolicy;
  };
  Message?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageKeySpecifier
      | (() => undefined | MessageKeySpecifier);
    fields?: MessageFieldPolicy;
  };
  MessageStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageStatsKeySpecifier
      | (() => undefined | MessageStatsKeySpecifier);
    fields?: MessageStatsFieldPolicy;
  };
  Month?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MonthKeySpecifier
      | (() => undefined | MonthKeySpecifier);
    fields?: MonthFieldPolicy;
  };
  MonthGoal?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MonthGoalKeySpecifier
      | (() => undefined | MonthGoalKeySpecifier);
    fields?: MonthGoalFieldPolicy;
  };
  MostViewedStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MostViewedStatsKeySpecifier
      | (() => undefined | MostViewedStatsKeySpecifier);
    fields?: MostViewedStatsFieldPolicy;
  };
  Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MutationKeySpecifier
      | (() => undefined | MutationKeySpecifier);
    fields?: MutationFieldPolicy;
  };
  NearbyPlaces?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NearbyPlacesKeySpecifier
      | (() => undefined | NearbyPlacesKeySpecifier);
    fields?: NearbyPlacesFieldPolicy;
  };
  NearbyService?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NearbyServiceKeySpecifier
      | (() => undefined | NearbyServiceKeySpecifier);
    fields?: NearbyServiceFieldPolicy;
  };
  NearbyServices?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NearbyServicesKeySpecifier
      | (() => undefined | NearbyServicesKeySpecifier);
    fields?: NearbyServicesFieldPolicy;
  };
  News?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | NewsKeySpecifier | (() => undefined | NewsKeySpecifier);
    fields?: NewsFieldPolicy;
  };
  NewsData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NewsDataKeySpecifier
      | (() => undefined | NewsDataKeySpecifier);
    fields?: NewsDataFieldPolicy;
  };
  NonNullRange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NonNullRangeKeySpecifier
      | (() => undefined | NonNullRangeKeySpecifier);
    fields?: NonNullRangeFieldPolicy;
  };
  Notification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NotificationKeySpecifier
      | (() => undefined | NotificationKeySpecifier);
    fields?: NotificationFieldPolicy;
  };
  NotificationData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NotificationDataKeySpecifier
      | (() => undefined | NotificationDataKeySpecifier);
    fields?: NotificationDataFieldPolicy;
  };
  NotificationMessage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NotificationMessageKeySpecifier
      | (() => undefined | NotificationMessageKeySpecifier);
    fields?: NotificationMessageFieldPolicy;
  };
  OnTopSearchResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OnTopSearchResultKeySpecifier
      | (() => undefined | OnTopSearchResultKeySpecifier);
    fields?: OnTopSearchResultFieldPolicy;
  };
  OrderData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrderDataKeySpecifier
      | (() => undefined | OrderDataKeySpecifier);
    fields?: OrderDataFieldPolicy;
  };
  OverTimeStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OverTimeStatsKeySpecifier
      | (() => undefined | OverTimeStatsKeySpecifier);
    fields?: OverTimeStatsFieldPolicy;
  };
  OverviewData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OverviewDataKeySpecifier
      | (() => undefined | OverviewDataKeySpecifier);
    fields?: OverviewDataFieldPolicy;
  };
  Period?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PeriodKeySpecifier
      | (() => undefined | PeriodKeySpecifier);
    fields?: PeriodFieldPolicy;
  };
  Podcast?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PodcastKeySpecifier
      | (() => undefined | PodcastKeySpecifier);
    fields?: PodcastFieldPolicy;
  };
  PortallyAd?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PortallyAdKeySpecifier
      | (() => undefined | PortallyAdKeySpecifier);
    fields?: PortallyAdFieldPolicy;
  };
  PortallyPartner?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PortallyPartnerKeySpecifier
      | (() => undefined | PortallyPartnerKeySpecifier);
    fields?: PortallyPartnerFieldPolicy;
  };
  PortallySubscription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PortallySubscriptionKeySpecifier
      | (() => undefined | PortallySubscriptionKeySpecifier);
    fields?: PortallySubscriptionFieldPolicy;
  };
  PreliminarySearchRequestStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PreliminarySearchRequestStatsKeySpecifier
      | (() => undefined | PreliminarySearchRequestStatsKeySpecifier);
    fields?: PreliminarySearchRequestStatsFieldPolicy;
  };
  PresetAnswer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PresetAnswerKeySpecifier
      | (() => undefined | PresetAnswerKeySpecifier);
    fields?: PresetAnswerFieldPolicy;
  };
  ProcessValuesData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProcessValuesDataKeySpecifier
      | (() => undefined | ProcessValuesDataKeySpecifier);
    fields?: ProcessValuesDataFieldPolicy;
  };
  Project?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProjectKeySpecifier
      | (() => undefined | ProjectKeySpecifier);
    fields?: ProjectFieldPolicy;
  };
  Promoted?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PromotedKeySpecifier
      | (() => undefined | PromotedKeySpecifier);
    fields?: PromotedFieldPolicy;
  };
  PromotedData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PromotedDataKeySpecifier
      | (() => undefined | PromotedDataKeySpecifier);
    fields?: PromotedDataFieldPolicy;
  };
  PromotedStat?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PromotedStatKeySpecifier
      | (() => undefined | PromotedStatKeySpecifier);
    fields?: PromotedStatFieldPolicy;
  };
  PromotedStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PromotedStatsKeySpecifier
      | (() => undefined | PromotedStatsKeySpecifier);
    fields?: PromotedStatsFieldPolicy;
  };
  Promotion?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PromotionKeySpecifier
      | (() => undefined | PromotionKeySpecifier);
    fields?: PromotionFieldPolicy;
  };
  Prospect?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProspectKeySpecifier
      | (() => undefined | ProspectKeySpecifier);
    fields?: ProspectFieldPolicy;
  };
  Prospects?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProspectsKeySpecifier
      | (() => undefined | ProspectsKeySpecifier);
    fields?: ProspectsFieldPolicy;
  };
  ProviderAuthentication?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProviderAuthenticationKeySpecifier
      | (() => undefined | ProviderAuthenticationKeySpecifier);
    fields?: ProviderAuthenticationFieldPolicy;
  };
  ProviderClient?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProviderClientKeySpecifier
      | (() => undefined | ProviderClientKeySpecifier);
    fields?: ProviderClientFieldPolicy;
  };
  Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | QueryKeySpecifier
      | (() => undefined | QueryKeySpecifier);
    fields?: QueryFieldPolicy;
  };
  Range?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RangeKeySpecifier
      | (() => undefined | RangeKeySpecifier);
    fields?: RangeFieldPolicy;
  };
  Reaction?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReactionKeySpecifier
      | (() => undefined | ReactionKeySpecifier);
    fields?: ReactionFieldPolicy;
  };
  Reasons?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReasonsKeySpecifier
      | (() => undefined | ReasonsKeySpecifier);
    fields?: ReasonsFieldPolicy;
  };
  Region?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RegionKeySpecifier
      | (() => undefined | RegionKeySpecifier);
    fields?: RegionFieldPolicy;
  };
  RegionBounds?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RegionBoundsKeySpecifier
      | (() => undefined | RegionBoundsKeySpecifier);
    fields?: RegionBoundsFieldPolicy;
  };
  RegionData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RegionDataKeySpecifier
      | (() => undefined | RegionDataKeySpecifier);
    fields?: RegionDataFieldPolicy;
  };
  RegionInformation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RegionInformationKeySpecifier
      | (() => undefined | RegionInformationKeySpecifier);
    fields?: RegionInformationFieldPolicy;
  };
  Report?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReportKeySpecifier
      | (() => undefined | ReportKeySpecifier);
    fields?: ReportFieldPolicy;
  };
  ResultBounds?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ResultBoundsKeySpecifier
      | (() => undefined | ResultBoundsKeySpecifier);
    fields?: ResultBoundsFieldPolicy;
  };
  SaleClient?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SaleClientKeySpecifier
      | (() => undefined | SaleClientKeySpecifier);
    fields?: SaleClientFieldPolicy;
  };
  SaleClients?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SaleClientsKeySpecifier
      | (() => undefined | SaleClientsKeySpecifier);
    fields?: SaleClientsFieldPolicy;
  };
  SearchFloor?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchFloorKeySpecifier
      | (() => undefined | SearchFloorKeySpecifier);
    fields?: SearchFloorFieldPolicy;
  };
  SearchParameters?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchParametersKeySpecifier
      | (() => undefined | SearchParametersKeySpecifier);
    fields?: SearchParametersFieldPolicy;
  };
  SearchProfile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchProfileKeySpecifier
      | (() => undefined | SearchProfileKeySpecifier);
    fields?: SearchProfileFieldPolicy;
  };
  SearchProfileCount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchProfileCountKeySpecifier
      | (() => undefined | SearchProfileCountKeySpecifier);
    fields?: SearchProfileCountFieldPolicy;
  };
  SearchProfileData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchProfileDataKeySpecifier
      | (() => undefined | SearchProfileDataKeySpecifier);
    fields?: SearchProfileDataFieldPolicy;
  };
  SearchRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchRequestKeySpecifier
      | (() => undefined | SearchRequestKeySpecifier);
    fields?: SearchRequestFieldPolicy;
  };
  SearchRequestAdminData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchRequestAdminDataKeySpecifier
      | (() => undefined | SearchRequestAdminDataKeySpecifier);
    fields?: SearchRequestAdminDataFieldPolicy;
  };
  SearchRequestAdminStatistics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchRequestAdminStatisticsKeySpecifier
      | (() => undefined | SearchRequestAdminStatisticsKeySpecifier);
    fields?: SearchRequestAdminStatisticsFieldPolicy;
  };
  SearchRequestAnalysis?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchRequestAnalysisKeySpecifier
      | (() => undefined | SearchRequestAnalysisKeySpecifier);
    fields?: SearchRequestAnalysisFieldPolicy;
  };
  SearchRequestComment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchRequestCommentKeySpecifier
      | (() => undefined | SearchRequestCommentKeySpecifier);
    fields?: SearchRequestCommentFieldPolicy;
  };
  SearchRequestData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchRequestDataKeySpecifier
      | (() => undefined | SearchRequestDataKeySpecifier);
    fields?: SearchRequestDataFieldPolicy;
  };
  SearchRequestInformation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchRequestInformationKeySpecifier
      | (() => undefined | SearchRequestInformationKeySpecifier);
    fields?: SearchRequestInformationFieldPolicy;
  };
  SearchRequestLandlordData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchRequestLandlordDataKeySpecifier
      | (() => undefined | SearchRequestLandlordDataKeySpecifier);
    fields?: SearchRequestLandlordDataFieldPolicy;
  };
  SearchRequestReceiver?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchRequestReceiverKeySpecifier
      | (() => undefined | SearchRequestReceiverKeySpecifier);
    fields?: SearchRequestReceiverFieldPolicy;
  };
  SearchRequestReceiverData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchRequestReceiverDataKeySpecifier
      | (() => undefined | SearchRequestReceiverDataKeySpecifier);
    fields?: SearchRequestReceiverDataFieldPolicy;
  };
  SearchRequestReference?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchRequestReferenceKeySpecifier
      | (() => undefined | SearchRequestReferenceKeySpecifier);
    fields?: SearchRequestReferenceFieldPolicy;
  };
  SearchRequestReferenceData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchRequestReferenceDataKeySpecifier
      | (() => undefined | SearchRequestReferenceDataKeySpecifier);
    fields?: SearchRequestReferenceDataFieldPolicy;
  };
  SearchRequestSettings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchRequestSettingsKeySpecifier
      | (() => undefined | SearchRequestSettingsKeySpecifier);
    fields?: SearchRequestSettingsFieldPolicy;
  };
  SearchRequestStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchRequestStatsKeySpecifier
      | (() => undefined | SearchRequestStatsKeySpecifier);
    fields?: SearchRequestStatsFieldPolicy;
  };
  SearchRequestsInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchRequestsInfoKeySpecifier
      | (() => undefined | SearchRequestsInfoKeySpecifier);
    fields?: SearchRequestsInfoFieldPolicy;
  };
  SearchResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchResultKeySpecifier
      | (() => undefined | SearchResultKeySpecifier);
    fields?: SearchResultFieldPolicy;
  };
  Sections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsKeySpecifier
      | (() => undefined | SectionsKeySpecifier);
    fields?: SectionsFieldPolicy;
  };
  SimilarLeaseAgreements?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SimilarLeaseAgreementsKeySpecifier
      | (() => undefined | SimilarLeaseAgreementsKeySpecifier);
    fields?: SimilarLeaseAgreementsFieldPolicy;
  };
  SiteMapLinks?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SiteMapLinksKeySpecifier
      | (() => undefined | SiteMapLinksKeySpecifier);
    fields?: SiteMapLinksFieldPolicy;
  };
  SiteMapResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SiteMapResultKeySpecifier
      | (() => undefined | SiteMapResultKeySpecifier);
    fields?: SiteMapResultFieldPolicy;
  };
  SizeSpan?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SizeSpanKeySpecifier
      | (() => undefined | SizeSpanKeySpecifier);
    fields?: SizeSpanFieldPolicy;
  };
  Statistics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatisticsKeySpecifier
      | (() => undefined | StatisticsKeySpecifier);
    fields?: StatisticsFieldPolicy;
  };
  Stats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatsKeySpecifier
      | (() => undefined | StatsKeySpecifier);
    fields?: StatsFieldPolicy;
  };
  Status?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusKeySpecifier
      | (() => undefined | StatusKeySpecifier);
    fields?: StatusFieldPolicy;
  };
  StatusCount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusCountKeySpecifier
      | (() => undefined | StatusCountKeySpecifier);
    fields?: StatusCountFieldPolicy;
  };
  StatusUpdate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusUpdateKeySpecifier
      | (() => undefined | StatusUpdateKeySpecifier);
    fields?: StatusUpdateFieldPolicy;
  };
  StatusUpdateData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusUpdateDataKeySpecifier
      | (() => undefined | StatusUpdateDataKeySpecifier);
    fields?: StatusUpdateDataFieldPolicy;
  };
  StoreQuantity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StoreQuantityKeySpecifier
      | (() => undefined | StoreQuantityKeySpecifier);
    fields?: StoreQuantityFieldPolicy;
  };
  Subscription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionKeySpecifier
      | (() => undefined | SubscriptionKeySpecifier);
    fields?: SubscriptionFieldPolicy;
  };
  SubscriptionGoals?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionGoalsKeySpecifier
      | (() => undefined | SubscriptionGoalsKeySpecifier);
    fields?: SubscriptionGoalsFieldPolicy;
  };
  SubscriptionRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionRequestKeySpecifier
      | (() => undefined | SubscriptionRequestKeySpecifier);
    fields?: SubscriptionRequestFieldPolicy;
  };
  Summary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SummaryKeySpecifier
      | (() => undefined | SummaryKeySpecifier);
    fields?: SummaryFieldPolicy;
  };
  SystemStatistics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SystemStatisticsKeySpecifier
      | (() => undefined | SystemStatisticsKeySpecifier);
    fields?: SystemStatisticsFieldPolicy;
  };
  Terms?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TermsKeySpecifier
      | (() => undefined | TermsKeySpecifier);
    fields?: TermsFieldPolicy;
  };
  TermsOfService?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TermsOfServiceKeySpecifier
      | (() => undefined | TermsOfServiceKeySpecifier);
    fields?: TermsOfServiceFieldPolicy;
  };
  TermsOfServiceData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TermsOfServiceDataKeySpecifier
      | (() => undefined | TermsOfServiceDataKeySpecifier);
    fields?: TermsOfServiceDataFieldPolicy;
  };
  TimeAverageInStepData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TimeAverageInStepDataKeySpecifier
      | (() => undefined | TimeAverageInStepDataKeySpecifier);
    fields?: TimeAverageInStepDataFieldPolicy;
  };
  TimeAverageToOrderData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TimeAverageToOrderDataKeySpecifier
      | (() => undefined | TimeAverageToOrderDataKeySpecifier);
    fields?: TimeAverageToOrderDataFieldPolicy;
  };
  TokenInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TokenInfoKeySpecifier
      | (() => undefined | TokenInfoKeySpecifier);
    fields?: TokenInfoFieldPolicy;
  };
  TotalProcessValues?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TotalProcessValuesKeySpecifier
      | (() => undefined | TotalProcessValuesKeySpecifier);
    fields?: TotalProcessValuesFieldPolicy;
  };
  TranslatedContent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TranslatedContentKeySpecifier
      | (() => undefined | TranslatedContentKeySpecifier);
    fields?: TranslatedContentFieldPolicy;
  };
  Trial?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TrialKeySpecifier
      | (() => undefined | TrialKeySpecifier);
    fields?: TrialFieldPolicy;
  };
  Unread?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UnreadKeySpecifier
      | (() => undefined | UnreadKeySpecifier);
    fields?: UnreadFieldPolicy;
  };
  User?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier);
    fields?: UserFieldPolicy;
  };
  UserData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserDataKeySpecifier
      | (() => undefined | UserDataKeySpecifier);
    fields?: UserDataFieldPolicy;
  };
  UserDeals?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserDealsKeySpecifier
      | (() => undefined | UserDealsKeySpecifier);
    fields?: UserDealsFieldPolicy;
  };
  UserStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserStatsKeySpecifier
      | (() => undefined | UserStatsKeySpecifier);
    fields?: UserStatsFieldPolicy;
  };
  Video?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VideoKeySpecifier
      | (() => undefined | VideoKeySpecifier);
    fields?: VideoFieldPolicy;
  };
  WaitingEmail?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | WaitingEmailKeySpecifier
      | (() => undefined | WaitingEmailKeySpecifier);
    fields?: WaitingEmailFieldPolicy;
  };
  WebsiteSearchQuery?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | WebsiteSearchQueryKeySpecifier
      | (() => undefined | WebsiteSearchQueryKeySpecifier);
    fields?: WebsiteSearchQueryFieldPolicy;
  };
  Yearly?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | YearlyKeySpecifier
      | (() => undefined | YearlyKeySpecifier);
    fields?: YearlyFieldPolicy;
  };
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;
